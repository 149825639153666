import React, { Component } from "react";
import "../../App.css";
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";
import mainImg from "../../Images/Full Set.png";
import mainImg2 from "../../Images/Full Set.png";
import question from "../../Images/question.png";
import harold1 from "../../Images/harold1.png";
import dollar from "../../Images/100dollars.png";
import group1 from "../../Images/Box with Mobile 4 Sets.png";
import levelsImg from '../../Images/IPHONEBOX.jpg';
import lapEbook from '../../Images/LAP AND EBOOK.png';
import pic1 from "../../Images/pic1.JPG";
import pic2 from "../../Images/pic2.png";
import level1 from "../../Images/ebook.png";
import headerBook from '../../Images/header-book.PNG'
import interview from "../../Images/ORANGE BOX.png";
import travel from "../../Images/EBOOK 3.png";
import civic from "../../Images/EBOOK 4.png";
import diploma from '../../Images/samplediploma.jpg';
import pic3 from "../../Images/pic3.JPG";
import harold2 from "../../Images/harold2.jpeg";
import logobg from "../../Images/logobg.jpg";
import logoblack from "../../Images/nuparla em1 .1 w.png";
import logogold from "../../Images/nuparla em1 .1.png";
import heading from "../../Images/Untitled-1.png";
import prices from "../../Images/prices.png";
import paypal from "../../Images/paypal.png";
import stripe from "../../Images/stripe.png";
import bitcoin from "../../Images/bitcoin.png";
import bonus5 from '../../Images/bonus5.jpeg';
import { fire } from "../../config";
import {Helmet} from "react-helmet";
import ScriptTag from 'react-script-tag';
import "../../config";
import * as firebase from "firebase";
import { TextField, Button } from "@material-ui/core";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import ConvertKit from "convertkit-react";
import ReactPlayer from "react-player";
import ConvertKitComponent from './convertKit'
import Checkbox from '@material-ui/core/Checkbox';
import GetAppIcon from '@material-ui/icons/GetApp';
import Audible from '../../Images/audible.png'
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));





export default class landingPage extends Component {
  constructor() {
    super();
    this.state = {
      package: "",
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      address: "",
      password: "",
      verifyPassword: "",
      registered: false,
      payment: '',
      country: '',
      region: '',
      backdrop: false,
      intro: '',
      plan: ''
    };
  }

  componentDidMount() {


  }
  

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 selectCountry (val) {
  this.setState({ country: val });
}

selectRegion (val) {
  this.setState({ region: val });
}
 


  verify() {
    if (this.state.firstName == '' || this.state.email == '' || this.state.password == ''){
      this.setState({ registered: false, backdrop: false });
      alert('Please fill all the fields')

    } else if (this.state.payment == ''){
      this.setState({ registered: false, backdrop: false });
      alert('Please select a payment plan')

    }else if (this.state.password === this.state.verifyPassword) {
      // console.log(this.state.firstName);
      // console.log(this.state.address);
      // console.log(this.state.email);
      // console.log(this.state.password);
      this.setState({backdrop: true})

      fire
        .auth()
        .createUserWithEmailAndPassword(this.state.email.toLowerCase(), this.state.password)
        .then((u) => {
          console.log(u);
          var uid = u.user.uid;
          // firebase
          //   .database()
          //   .ref("Users")
          //   .child()
          //   .set({
          //     firstName: this.state.firstName,
          //     lastName: this.state.lastName,
          //     name: this.state.firstName + " " + this.state.lastName,
          //     email: this.state.email,
          //     address: this.state.address,
          //     userName: this.state.userName,
          //     uid: uid,
          //     package: this.state.package,
          //     paid: false,
          //   })
          //   .then(this.setState({ registered: true }));

          firebase.firestore().collection('Users').doc(this.state.email).set({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            name: this.state.firstName + " " + this.state.lastName,
            email: this.state.email.toLowerCase(),
            address: {
              country: this.state.country,
              region: this.state.region
            },
            userName: this.state.email.split('@')[0],
            uid: uid,
            paid: false,
            referral: this.makeid(12),
            paymentMethod: this.state.payment,
            rank: 'hero',
            date: Date.now(),
            intro: false,
            affiliate: false,
            
            learningProgress: {
              level: 0
            }
          }).then(res => {
            
            this.setState({registered: true})

          })
        })
        .catch((error) => {
          
          this.setState({ registered: false, backdrop: false });
          alert(error.message)

        });
    } else {
      this.setState({ registered: false, backdrop: false });
      alert('passwords do not match')
      
    }
  }

  render() {
    if (this.state.registered == true) {
      return (
        <Redirect
          to={{
            pathname: "/payment",
            state: {
              payment: this.state.payment,
              userName: this.state.userName,
              email: this.state.email.toLowerCase(),
              plan: this.state.plan,
            },
          }}
        />
      );
    }
    return (
      <div>
        <div className="top-bg-div">
          <div
            className="header-sales"
            style={{
              justifyContent: "space-between",
              borderBottom: "2px dashed rgba(240,240,240,0.2)",
            }}
          >
            <img src={logogold} className="logo-top" />
            <div
              className="header-menu"
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "max-content",
              }}
            >
              <h4 className="p10 pointer phover " style={{color: 'white', marginTop: 15}}>Únete</h4>
              <h4 className="p10 pointer phover " style={{color: 'white', marginTop: 15}}>Testimonios</h4>
              <a href="/affiliate"><h4 className="p10 pointer phover " style={{color: 'white', marginTop: 15}}>Acceso</h4></a>
            </div>
          </div>

          <div className="center" style={{ marginTop: 10 }}>
            <img src={heading} style={{ width: "100%" }} />
          </div>

          <Grid>
            <div>
              {window.innerWidth >= 900 && <div
                // style={{
                //   width: "90%",
                //   height: 500,
                //   margin: "auto",
                //   boxShadow: "0px 2px 30px rgba(0,0,0,0.6)",
                // }}
              >
                <ReactPlayer
                                        onContextMenu ={(e) => e.preventDefault()}
                                            style={{ margin: "0px auto 20px auto"}}
                                            controls={true}
                                            width={450}
                                            height={window.innerwidth >= 1000 ? 200 : 300}
                                            playing={false}
                                            config={{ file: { 
                                            attributes: {
                                                controlsList: 'nodownload'
                                            }
                                            }}}
                                            url="https://youtu.be/NlC2_1qK_TA"
                                        />
              </div>}
              {window.innerWidth < 899 && <div
                // style={{
                //   width: "90%",
                //   height: 500,
                //   margin: "auto",
                //   boxShadow: "0px 2px 30px rgba(0,0,0,0.6)",
                // }}
              >
                <ReactPlayer
                                        onContextMenu ={(e) => e.preventDefault()}
                                            style={{ margin: "0px auto 20px auto"}}
                                            controls={true}
                                            width={340}
                                            height={window.innerwidth >= 1000 ? 200 : 300}
                                            playing={false}
                                            config={{ file: { 
                                            attributes: {
                                                controlsList: 'nodownload'
                                            }
                                            }}}
                                            url="https://youtu.be/NlC2_1qK_TA"
                                        />
              </div>}
              <P className="p" style={{ margin: "40px", textAlign: "center" }}>
              NuParla Online FREE English Academy te permite hablar inglés con clases 100% GRATIS online. No pagues $500, $1,000 o $2,500 dólares por otros cursos online, cuando TODO lo que necesitas para dominar el inglés en menos de 90 días, lo tienes con NuParla GRATIS!
              </P>
              
            </div>

            <div>
            
            
                                          
            <a  href="/free"><div className='freeBtn' style={{backgroundColor: '#009900', color: 'white', border: '1px solid #006600', padding: 20, borderRadius: 4, height: 120,marginTop: 30, textAlign: 'center'}}>
                <p style={{fontSize: '40px', fontWeight: 700, padding: 0, margin: '0px auto', marginTop: -10, textShadow: '0px 2px 4px rgba(0,0,0,0.3)'}}>VE A LOS 90 VÍDEOS</p>
                <p style={{fontSize: 20, textShadow: '0px 1px 2px rgba(0,0,0,0.3)' }}>!Aprende inglés 100% GRATIS!</p>

            </div></a>

            <div
              style={{
                width: "85%",
                backgroundColor: "rgba(255,255,255, 0.8)",
                height: "max-content",
                padding: 10,
                margin: "40px auto",
                marginTop: 70,
                textAlign: 'center'
              }}
            >
              
              <img src={diploma} style={{ width: "100%" }} /> 

            </div>
            </div>

          </Grid>
        </div>

        <div className="main-page">
        
          <div
            style={{
              display: "flex",
              width: "90%",
              flexWrap: "wrap",
              textAlign: "center",
              justifyContent: "space-around",
              margin: "30px 20px",
            }}
          >
            <img className="question" style={{margin: 'auto'}} src={question} />
            {window.innerWidth >= 450 &&  <div
              className="center"
              style={{
                width: '70%',

                borderTop: "2px solid #bebebe",
                borderBottom: "2px solid #bebebe",
                padding: "10px 0px",
              }}
            >
              <h2>¿Qué significa...</h2>
              <h1 style={{ display: "flex", flexWrap: "wrap" }}>
              NuParla Online FREE English Academy
              </h1>
              <h2>para ti...?</h2>
            </div>}

            {window.innerWidth < 450 && <div
              className="center"
              style={{
                width: '90%',

                borderTop: "2px solid #bebebe",
                borderBottom: "2px solid #bebebe",
                padding: "10px 0px",
              }}
            >
              <h2>¿Qué significa...</h2>
              <h1 style={{fontSize: 'xx-large', display: "flex", flexWrap: "wrap" }}>
              NuParla Online FREE English Academy
              </h1>
              <h2>para ti...?</h2>
            </div>}
          </div>

          <Grid2>
            <div style={{ width: "90%", margin: "auto" }}>
              <P className="no-m-p ">
                <span style={{ fontWeight: "bold", color: "#2874A6" }}>
                  De:
                </span>{" "}
                Harold Zapata
              </P>
              <P className="no-m-p ">
                <span style={{ fontWeight: "bold", color: "#2874A6" }}>
                  Desde:
                </span>{" "}
                Los Ángeles, California{" "}
              </P>
              <P>
                ¿Qué significa{" "}
                <span style={{ fontWeight: "bold" }}>NuParla Online FREE English Academy</span>{" "}
                para ti...?
              </P>
              <P>Cada persona tiene un distinto "por qué" y "para qué"...</P>
              <P>
                Algunos tienen que manejar el idioma para obtener ese mejor
                trabajo, o para poder graduarse, o pasar el TOEFL o el examen
                oral y escrito para la ciudadanía americana.{" "}
              </P>
              <P>
                Para otros, el inglés les es útil para viajar por el mundo con
                confianza, conocer nuevas culturas, amistades, y aun el amor...
              </P>
              {/* <P className="p">
                Mi "por qué" fue el amor...Nací en New York, pero me criaron en
                Cali. Hablé inglés por primera vez a los 5 años cuando ingresé
                al kínder cerca de Philadelphia. Me enamoré de Bonnie después de
                que ella me besó, y eso me motivó a aprender el idioma. Luego a
                los 9 años me enamoré de Mónica y Antonella en Cali; y puro
                ping-pong de Colombia a USA toda mi niñez y juventud, y siempre
                un loco enamorado, lo que me motivó a aprender dos lenguas
                maternas. :-)
              </P> */}
              <P className="p">
              Por eso estamos ofreciendo las video-clases de la Academia de inglés 100% gratis online aquí y en el canal de YouTube de NuParla. Ahora no es necesario pagar cientos o miles de dólares para aprender inglés y hablarlo fluidamente en menos de 12 semanas. 
              </P>
            </div>
            <img src={harold1} style={{ margin: "auto", width: "90%" }} />
          </Grid2>
          <div style={{ width: "100%", textAlign: "center" }}>
            <img src={levelsImg} style={{ width: "70%", margin: "auto" }} />
          </div>

          <Grid50 style={{width: '90%', margin: 'auto'}}>
            <div>
              <h3 style={{fontSize: 'large', fontWeight: 'bold'}}>PRÁCTICO:</h3>
              <p>3 a 4 lecciones por curso (90 video-lecciones…45 horas de enseñanzas en total)</p>
              <ul>
                <li style={{fontSize: 20}}>4 niveles (principiante, elemental, intermedio, intermedio avanzado) </li>
                <li style={{fontSize: 20}}>7 cursos por nivel </li>
                <li style={{fontSize: 20}}>3 a 4 lecciones por curso</li>
                <li style={{fontSize: 20}}>Introducción 100% en español</li>

                <li style={{fontSize: 20}}>Primeras lecciones 50% inglés y 50% español, luego 100% en inglés</li>

              </ul>

            </div>
            <div>
            <h3 style={{fontSize: 'large', fontWeight: 'bold'}}>FÁCIL: </h3>
              <ul>
                <li style={{fontSize: 20}}>De no saber ni una palabra a hablarlo fluidamente </li>
                <li style={{fontSize: 20}}>30 minutos diarios por 90 días</li>
                <li style={{fontSize: 20}}>Utilizamos videos y tráiler de películas divertidos</li>
                <li style={{fontSize: 20}}>Sin frustraciones ni gramática complicada</li>

                <li style={{fontSize: 20}}>Video-lecciones 100% GRATIS online</li>

              </ul>

            </div>
          </Grid50>



          

          

          
          
          
          
          
          
          <div
          style={{marginTop: 30}}
            
          >
            {window.innerWidth >= 900 && <div
                // style={{
                //   width: "90%",
                //   height: 500,
                //   margin: "auto",
                //   boxShadow: "0px 2px 30px rgba(0,0,0,0.6)",
                // }}
              >
                <ReactPlayer
                                        onContextMenu ={(e) => e.preventDefault()}
                                            style={{ margin: "0px auto 20px auto"}}
                                            controls={true}
                                            width={650}
                                            height={360}
                                            playing={false}
                                            config={{ file: { 
                                            attributes: {
                                                controlsList: 'nodownload'
                                            }
                                            }}}
                                            url="https://youtu.be/INPT2D2gGcI"
                                        />
              </div>}
              {window.innerWidth <= 899 && <div
                // style={{
                //   width: "90%",
                //   height: 500,
                //   margin: "auto",
                //   boxShadow: "0px 2px 30px rgba(0,0,0,0.6)",
                // }}
              >
                <ReactPlayer
                                        onContextMenu ={(e) => e.preventDefault()}
                                            style={{ margin: "0px auto 20px auto"}}
                                            controls={true}
                                            width={340}
                                            height={200}
                                            playing={false}
                                            config={{ file: { 
                                            attributes: {
                                                controlsList: 'nodownload'
                                            }
                                            }}}
                                            url="https://youtu.be/INPT2D2gGcI"
                                        />
              </div>}

          </div>

          <div
            style={{
              width: "90%",
              margin: "50px auto",
              border: "5px solid rgb(0, 36, 98)",
            }}
          >
            <div style={{ width: "100%", margin: "auto" }}>
              <h1
                className="mmedium"
                style={{
                  backgroundColor: "rgb(0, 36, 98)",
                  color: "white",
                  width: "100%",
                  padding: "20px 0px",
                  textAlign: "center",
                }}
              >
                Las 90 clases son gratis. La membresía completa se compone de 12 beneficios adicionales... 
              </h1>
              <div style={{ width: "100%", textAlign: "center" }}>
                <img src={mainImg2} style={{ width: "90%", margin: "auto" }} />
              </div>
              <div style={{ width: "90%", margin: "auto", color: 'black', fontSize: 'large' }}>
                <img src={prices} style={{ width: "100%", margin: "auto" }} />

                {/* <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >El método neurocientífico exclusivo de NuParla</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ Inestimable)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >La plataforma avanzada de aprendizaje cibernético: </li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 400)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Más de 100+ videos (50+ horas) de coaching con Harold</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 1,700)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Más de 100+ grabaciones descargables (30+ horas) con Harold</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 700)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Sistema bi-direccional de mensajes exclusivo a NuParla</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 400)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li > Programa de Afiliados NuParleros para ganar dinero</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ Inestimable)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Oficina virtual y sitio de web replicable para gestionar tus ventas</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 750)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Ingreso a NuParla Village</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 300)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Acceso de por vida a NuParla University</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ Inestimable)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >BONUS e-book: Inglés Subconsciente (libro y audio en inglés y español)</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 35)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >BONUS curso exclusivo: Prepared For The Job Interview</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 250)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li  >BONUS curso exclusivo: Prepared For Traveling Abroad</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 250)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li  >BONUS curso exclusivo: Prepared For The USCIS Civics Test</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 250)</p>
                        </div> */}
              </div>
            </div>

            <h1
              style={{ color: "red", margin: "30px 10px", textAlign: "center" }}
            >
                Valorado en: US $<s>4,995.00</s>

            </h1>
            <P style={{ width: "80%", textAlign: "center", margin: "auto" }}>
            Ahora, 100% de Latinos pueden aprender inglés GRATIS con nuestros vídeos online, y si desean la experiencia COMPLETA de NuParla por US$49.95, pueden aprenderlo más rápido con un ser amado, obtener adiestramiento y asistencia personalizada antes de una entrevista de empleo o cita para la ciudadanía americana, y cambiar la vida de un adolecente huérfano en su país.

            </P>

            

            <h1
              style={{
                backgroundColor: "rgb(0, 36, 98)",
                color: "white",
                width: "100%",
                padding: "20px 0px",
                textAlign: "center",
              }}
            >
              ¿VES VALOR POR SOLO US$49.95…?
            </h1>
          </div>

          <a href="#register"><div
            style={{
              margin: "auto",
              width: "70%",
              padding: "10px 20px",
              textAlign: "center",
              color: "white",
              backgroundColor: "red",
              borderRadius: 20,
            }}
          >
            <h1 style={{ color: "white" }}>¡Sí, veo valor!</h1>
            <P>
            Quiero la experiencia completa de NuParla por solo US$49.95. <br/>
            (Haz clic para ir directamente a la sección de pagos.)
            </P>
          </div></a>
          <div style={{ width: "90%", margin: "auto" }}>
            

            

            

            
          </div>
          

          

          <Grid50>
            <div style={{ padding: 20, textAlign: "center" }}>
            <ReactPlayer
                                        onContextMenu ={(e) => e.preventDefault()}
                                            style={{ margin: "50px auto 20px auto"}}
                                            controls={true}
                                            width={380}
                                            height={240}
                                            playing={false}
                                            config={{ file: { 
                                            attributes: {
                                                controlsList: 'nodownload'
                                            }
                                            }}}
                                            url="https://youtu.be/L2q6aonDoSQ"
                                        />

<div>
            <img src={Audible} style={{width: '50%', marginBottom: 20}} />
            <P>Haz clic AQUÍ para descargar el audiolibro 100% GRATIS por medio de Audible a tu dispositivo móvil. </P>

            </div>
              

              
            </div>
            {/* <img src={pic1} style={{ width: "90%", margin: "auto" }} /> */}
            <div style={{textAlign: 'center', width: '100%'}}>
              <img src={headerBook} style={{ width: "90%", marginTop: '5%', marginBottom: '10%' }} />
              <h2 style={{fontSize: 'x-large', marginBottom: '20px'}}>Descarga el capítulo 1 del libro (pdf) y audiolibro (mp3)</h2>
              <div style={{display: 'flex',width: 350, position: 'relative', justifyContent: 'space-around', margin: 'auto' }}><p style={{marginTop: 7}}>Subconscious English:</p> 
                <a href="https://firebasestorage.googleapis.com/v0/b/nuparla-bbc0a.appspot.com/o/Docs%2FSubconscious%20English%20Dedication%20Introduction%20and%20Chapter%201.pdf?alt=media&token=2be48f03-18e1-464d-8226-b3c72c7197c7" download target="_blank"><Button variant="contained" style={{backgroundColor: 'green', color: 'white'}}><GetAppIcon />PDF</Button></a> 
                <a href="https://firebasestorage.googleapis.com/v0/b/nuparla-bbc0a.appspot.com/o/Docs%2FSubconscious%20English%20Dedication%20Intro%20and%20Chapter%201.mp3?alt=media&token=4d44053f-2835-40bd-b4de-a356b1f00d4c" download target="_blank"><Button variant="contained" style={{ backgroundColor: 'green', color: 'white'}}><GetAppIcon />MP3</Button></a>
              

              </div>

              <div style={{display: 'flex',width: 350,margin: '10px 0', position: 'relative', justifyContent: 'space-around', margin: 'auto' }}><p style={{marginTop: 7}}>Inglés Subconsciente:</p> 
                <a href="https://firebasestorage.googleapis.com/v0/b/nuparla-bbc0a.appspot.com/o/Docs%2Fchap1%2FChap%201.pdf?alt=media&token=b7b62088-8085-4610-8374-9d35d072bfdf" download target="_blank"><Button variant="contained" style={{backgroundColor: 'green', color: 'white'}}><GetAppIcon />PDF</Button></a> 
                <a href="https://firebasestorage.googleapis.com/v0/b/nuparla-bbc0a.appspot.com/o/Docs%2FIngles%20Subconsciente%20Intro%20y%20Capitulo%201.mp3?alt=media&token=ecedaaf3-c752-4e99-bb9e-71b18bc612a0" download target="_blank"><Button variant="contained" style={{ backgroundColor: 'green', color: 'white'}}><GetAppIcon />MP3</Button></a>
              

              </div>

              
              
            </div>
          </Grid50>
          {/* <Grid50>
            <img src={pic2} style={{ width: "60%", margin: "auto" }} />
            <div>
              <h2
                style={{
                  fontWeight: "normal",
                  width: "80%",
                  color: "#38448a",
                  margin: "20px auto",
                  textAlign: "left",
                }}
              >
                Más de 30 horas de audio-conversaciones y afirmaciones
                descargables a tu dispositivo móvil para interactuar:
                <li>en el gimnasio</li>
                <li>en la ducha</li>
                <li>cuando viajes</li>
                <li>al dormir</li>
                <li>donde sea...</li>
              </h2>
            </div>
          </Grid50> */}
          {/* <Grid50>
            <h1
              className="mmedium"
              style={{
                fontWeight: "normal",
                width: "80%",
                margin: "20px auto",
                textAlign: "center",
              }}
            >
              Aprende inglés. Gana dinero. Todo el sistema para afiliados{" "}
              <i>
                <b>
                  <u>NuParleros</u>
                </b>
              </i>{" "}
              está a tu alance.
            </h1>
            <img src={diploma} style={{width: '80%', margin: 'auto'}} />
          </Grid50> */}

          

          {/* <a href="#register"><div
            style={{
              margin: "auto",
              width: "70%",
              color: "white",
              padding: "10px 20px",
              textAlign: "center",
              backgroundColor: "red",
              borderRadius: 20,
            }}
          >
            <h2 style={{ color: "white" }}>
            ¡Sí, veo valor!
            </h2>
            <P>Quiero la experiencia completa de NuParla por solo US$49.95. 
(Haz clic para ir directamente a la sección de pagos.)</P>
          </div></a> */}

          <Grid50>
            <div
              style={{
                color: "#38448a",
                width: "90%",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <h1 className="no-m-p">Bonus #1</h1>
              <h2 className="no-m-p msmall">Inglés Subconsciente:</h2>
              <P className="no-m-p">
                La guía definitiva para que tu héroe interior emerja hablando inglés
              </P>
              <h3 className="no-m-p" style={{ color: "black" }}>
                e-book y audio libro
              </h3>

              <img src={level1} style={{ width: "50%" }} />

              <a href="#register"><div
                style={{
                  margin: "20px auto",
                  width: "90%",
                  padding: 10,
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "red",
                  borderRadius: 20,
                }}
              >
                <h2 className="msmall" style={{ color: "white" }}>
                ¡Sí, veo valor!
                </h2>
                <P className="p">
                Quiero la experiencia completa de NuParla por solo US$49.95. <br/>
                (Haz clic para ir directamente a la sección de pagos.) 
                </P>
              </div></a>
            </div>

            <P style={{ width: "90%", color: "#38448a", margin: "auto" }}>
              El método único a NuParla se descubre en este asombroso libro
              cibernético (e-Book) en español, escrito por Harold Zapata, donde
              se adquiere el inglés por medio de:
              <br />
              1. Historias (endulzando el oído)
              <br />
              2. Internalización (suministrando al subconsciente)
              <br />
              3. Repetición (activando la mente)
              <br />
              4. Postura (calmando el cuerpo)
              <br />
              5. Decisión (fortaleciendo la voluntad)
              <br />
              6. Emociones (involucrando el corazón)
              <br />
              7. Conexión social (caminado por La Aldea [the Village])
            </P>
          </Grid50>

          {window.innerWidth <= 600 && (
            <Grid50>
              <div
                style={{
                  color: "#38448a",
                  width: "90%",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <h1 className="no-m-p">Bonus #2</h1>
                <h2 className="no-m-p msmall">
                  Prepared for the Job Interview:
                </h2>
                <h3 className="no-m-p" style={{ color: "black" }}>
                  (valuado en US $250)
                </h3>

                <img src={interview} style={{ width: "50%" }} />

                <a href="#register"><div
                  style={{
                    margin: "20px auto",
                    width: "90%",
                    padding: 10,
                    textAlign: "center",
                    color: "white",
                    backgroundColor: "red",
                    borderRadius: 20,
                  }}
                >
                  <h2 className="msmall" style={{ color: "white" }}>
                ¡Sí, veo valor!
                </h2>
                <P className="p">
                Quiero la experiencia completa de NuParla por solo US$49.95. <br/>
                (Haz clic para ir directamente a la sección de pagos.) 
                </P>
                </div></a>
              </div>

              <P style={{ width: "90%", color: "#38448a", margin: "auto" }}>
                Ingresaste a NuParla, y desde cero
                creciste. Pasaste por los 4 niveles, de Principiante a
                Intermediario Avanzado: ¡Felicidades por tu logro! Ahora tienes
                tu primera cita para una entrevista de trabajo, y todo es en
                inglés. Don't worry! Con este curso estarás listo y confiado en
                2 semanas. Repasaremos los principios de una entrevista exitosa
                y cómo contestar preguntas difíciles.{" "}
                <b>
                  Tendrás repaso con nuestra Directora de Entrevistas de NuParla
                  antes de tu entrevista.
                </b>{" "}
                ¡Estamos juntos!
              </P>
            </Grid50>
          )}

          {window.innerWidth > 600 && (
            <Grid50>
              <P style={{ width: "90%", color: "#38448a", margin: "auto" }}>
                Ingresaste a NuParla, y desde cero
                creciste. Pasaste por los 4 niveles, de Principiante a
                Intermediario Avanzado: ¡Felicidades por tu logro! Ahora tienes
                tu primera cita para una entrevista de trabajo, y todo es en
                inglés. Don't worry! Con este curso estarás listo y confiado en
                2 semanas. Repasaremos los principios de una entrevista exitosa
                y cómo contestar preguntas difíciles.{" "}
                <b>
                  Tendrás repaso con nuestra Directora de Entrevistas de NuParla
                  antes de tu entrevista.
                </b>{" "}
                ¡Estamos juntos!
              </P>

              <div
                style={{
                  color: "#38448a",
                  width: "90%",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <h1 className="no-m-p">Bonus #2</h1>
                <h2 className="no-m-p msmall">
                  Prepared for the Job Interview:
                </h2>
                <h3 className="no-m-p" style={{ color: "black" }}>
                  (valuado en US $250)
                </h3>

                <img src={interview} style={{ width: "50%" }} />

                <a href="#register"><div
                  style={{
                    margin: "20px auto",
                    width: "90%",
                    padding: 10,
                    textAlign: "center",
                    color: "white",
                    backgroundColor: "red",
                    borderRadius: 20,
                  }}
                >
                  <h2 className="msmall" style={{ color: "white" }}>
                ¡Sí, veo valor!
                </h2>
                <P className="p">
                Quiero la experiencia completa de NuParla por solo US$49.95. <br/>
                (Haz clic para ir directamente a la sección de pagos.) 
                </P>
                </div></a>
              </div>
            </Grid50>
          )}

          <Grid50>
            <div
              style={{
                color: "#38448a",
                width: "90%",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <h1 className="no-m-p">Bonus #3</h1>
              <h2 className="no-m-p msmall">Prepared For The USCIS Civics Test:</h2>
              <h3 className="no-m-p" style={{ color: "black" }}>
                {" "}
                (valuado en US $250)
              </h3>

              <img src={civic} style={{ width: "50%" }} />

              <a href="#register"><div
                style={{
                  margin: "20px auto",
                  width: "90%",
                  padding: 10,
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "red",
                  borderRadius: 20,
                }}
              >
                <h2 className="msmall" style={{ color: "white" }}>
                ¡Sí, veo valor!
                </h2>
                <P className="p">
                Quiero la experiencia completa de NuParla por solo US$49.95. <br/>
                (Haz clic para ir directamente a la sección de pagos.) 
                </P>
              </div></a>
            </div>

            <P style={{ width: "90%", color: "#38448a", margin: "auto" }}>
                Solo tú sabes TODO por lo que pasaste para llegar a Estados
                Unidos. Lograste que te dieran la residencia permanente.
                ¡Felicidades! Sabemos muy personalmente cuán difícil eso es.
                Pero ahora, se cumplen tus cinco años, y es tu turno para
                obtener la ciudadanía americana. Y la entrevista oral y examen
                por escrito son in English. Are you ready?
                <br />
                <br />
                Con nuestra academia, lo estarás y {" "}
                <b>
                   tendrás repaso con nuestra Directora de Entrevistas de NuParla
                  antes de ese día.
                </b>{" "}
                ¡Estamos juntos!
              </P>
          </Grid50>

          {window.innerWidth <= 600 && (
            <Grid50>
              <div
                style={{
                  color: "#38448a",
                  width: "90%",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <h1 className="no-m-p">Bonus #4</h1>
                <h2 className="no-m-p msmall">
                  2 x 1:
                </h2>
                <h3 className="no-m-p" style={{ color: "black" }}>
                  (valuado en US $2,500)
                </h3>

                <img src={mainImg} style={{ width: "70%" }} />

                <a href="#register"><div
                  style={{
                    margin: "20px auto",
                    width: "90%",
                    padding: 10,
                    textAlign: "center",
                    color: "white",
                    backgroundColor: "red",
                    borderRadius: 20,
                  }}
                >
                  <h2 className="msmall" style={{ color: "white" }}>
                ¡Sí, veo valor!
                </h2>
                <P className="p">
                Quiero la experiencia completa de NuParla por solo US$49.95. <br/>
                (Haz clic para ir directamente a la sección de pagos.) 
                </P>
                </div></a>
              </div>

              <P style={{ width: "90%", color: "#38448a", margin: "auto" }}>
              Sabemos lo importante que es practicar inglés no solo con nosotros, sino también con alguien que quieres o admiras. Es por eso que le estamos obsequiando una entrada a nuestra Academia de inglés a UNA (1) persona que escojas. Esta persona tendrá su propia posición y acceso a todo lo que tú tendrás al unirte a NuParla. Así que piensa bien a quién le quieres regalar este obsequio para que juntos aprendan inglés, y si desean, ganen dinero. 
              </P>
            </Grid50>
          )}

          {window.innerWidth > 600 && (
            <Grid50>
              <P style={{ width: "90%", color: "#38448a", margin: "auto" }}>
              Sabemos lo importante que es practicar inglés no solo con nosotros, sino también con alguien que quieres o admiras. Es por eso que le estamos obsequiando una entrada a nuestra Academia de inglés a UNA (1) persona que escojas. Esta persona tendrá su propia posición y acceso a todo lo que tú tendrás al unirte a NuParla. Así que piensa bien a quién le quieres regalar este obsequio para que juntos aprendan inglés, y si desean, ganen dinero. 
              </P>

              <div
                style={{
                  color: "#38448a",
                  width: "90%",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <h1 className="no-m-p">Bonus #4</h1>
                <h2 className="no-m-p msmall">
                  2 x 1:
                </h2>
                <h3 className="no-m-p" style={{ color: "black" }}>
                  (valuado en US $2,500)
                </h3>

                <img src={mainImg} style={{ width: "70%" }} />

                <a href="#register"><div
                  style={{
                    margin: "20px auto",
                    width: "90%",
                    padding: 10,
                    textAlign: "center",
                    color: "white",
                    backgroundColor: "red",
                    borderRadius: 20,
                  }}
                >
                  <h2 className="msmall" style={{ color: "white" }}>
                ¡Sí, veo valor!
                </h2>
                <P className="p">
                Quiero la experiencia completa de NuParla por solo US$49.95. <br/>
                (Haz clic para ir directamente a la sección de pagos.) 
                </P>
                </div></a>
              </div>
            </Grid50>
          )}

<Grid50>
            <div
              style={{
                color: "#38448a",
                width: "90%",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <h1 className="no-m-p">Bonus #5</h1>
              <h2 className="no-m-p msmall">Bless An Orphan Teen (Bendice a un adolescente huérfano):</h2>
              <h3 className="no-m-p" style={{ color: "black" }}>
                {" "}
                (inestimable)
              </h3>

              <img src={bonus5} style={{ width: "50%" }} />

              <a href="#register"><div
                style={{
                  margin: "20px auto",
                  width: "90%",
                  padding: 10,
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "red",
                  borderRadius: 20,
                }}
              >
                <h2 className="msmall" style={{ color: "white" }}>
                ¡Sí, veo valor!
                </h2>
                <P className="p">
                Quiero la experiencia completa de NuParla por solo US$49.95. <br/>
                (Haz clic para ir directamente a la sección de pagos.) 
                </P>
              </div></a>
            </div>

            <P style={{ width: "90%", color: "#38448a", margin: "auto" }}>
            Ahora puedes ser una fuente de bendición para un adolecente Latino que quiera, como tú, aprender inglés para salir adelante en la vida. Él o ella recibirá, en nombre tuyo, una completa experiencia NuParla de $49.95, pero completamente GRATIS. Es nuestra forma de contribuir junto contigo para ayudar a más personas, cuando tú te unes con US$49.95 para tu experiencia completa de NuParla. Con NuParla, ¡todos ganamos!
              </P>
          </Grid50>





          {/* <h1
            style={{
              color: "red",
              fontWeight: "normal",
              textAlign: "center",
              marginTop: 50,
            }}
          >
            Lo SEGUNDO a lo que TENDRÁS ACCESO es a...
          </h1> */}

          <Grid3>
            <div style={{ width: "80%", margin: "auto", textAlign: "center" }}>
              <h1 className="mmedium" style={{ width: "80%", margin: "auto" }}>
                Harold Zapata
              </h1>
              {/* <div style={{width: '100%', margin: 'auto', height: 200, backgroundColor: 'black', opacity: 0.2}}>
              </div> */}
              <img src={lapEbook} style={{margin: '30px auto', width: '90%'}} />
            </div>
            <div style={{ width: "80%", margin: "auto" }}>
              <P>
                Raro es encontrar a un nativo americano y nativo latinoamericano
                en el mismo Coach - alguien bilingüe y bicultural - que inspire
                confianza, transparencia y honestidad...De esos tipos que se
                quitan la camisa para dártela si te falta, y que en el bus se
                pone de pie para ceder su asiento a una dama. Pero sobre todo,
                alguien que entienda lo importante que el inglés es para ti, y
                que sepa enseñarte intuitivamente... Con más de 25 años de
                experiencia en comunicación bilingüe y negocios, NuParla sale de
                Harold en tiempos del Coronavirus para ayudar a LATINOS a
                aprender inglés y ganar dinero desde sus casas. Más que un
                curso, NuParla es un MOVIMIENTO con una noble misión:{" "}
                <b>100% de latinos pueden aprender inglés 100% gratis.</b>
              </P>
            </div>
          </Grid3>

          <a href="#register"><div
            style={{
              margin: "60px auto",
              width: "70%",
              padding: 10,
              textAlign: "center",
              color: "white",
              backgroundColor: "red",
              borderRadius: 20,
            }}
          >
            <h2 style={{ color: "white" }}>¡ME UNO AL MOVIMIENTO NUPARLA!</h2>
            <P className="p">Gracias Harold por esta oportunidad.</P>
          </div></a>

          
          

          

          

          <Grid3
            style={{
              textAlign: "center",
              color: "#38448a",
              margin: "auto",
            }}
          >
            <div style={{margin: '40px auto 0px auto'}}>
            {window.innerWidth >= 900 && <div>
                <ReactPlayer
                                        onContextMenu ={(e) => e.preventDefault()}
                                            style={{ margin: "0px auto 20px auto"}}
                                            controls={true}
                                            width={240}
                                            height={window.innerwidth >= 1000 ? 420 : 420}
                                            playing={false}
                                            config={{ file: { 
                                            attributes: {
                                                controlsList: 'nodownload'
                                            }
                                            }}}
                                            url="https://firebasestorage.googleapis.com/v0/b/nuparla-bbc0a.appspot.com/o/videos%2FWhatsApp%20Video%202020-10-14%20at%203.48.51%20PM.mp4?alt=media&token=9a1e8d24-2bbc-4e44-b9f4-82b9f9357fc4"
                                        />
              </div>}
              {window.innerWidth < 899 && <div
                // style={{
                //   width: "90%",
                //   height: 500,
                //   margin: "auto",
                //   boxShadow: "0px 2px 30px rgba(0,0,0,0.6)",
                // }}
              >
                <ReactPlayer
                                        onContextMenu ={(e) => e.preventDefault()}
                                            style={{ margin: "0px auto 20px auto"}}
                                            controls={true}
                                            width={240}
                                            height={window.innerwidth >= 1000 ? 420 : 420}
                                            playing={false}
                                            config={{ file: { 
                                            attributes: {
                                                controlsList: 'nodownload'
                                            }
                                            }}}
                                            url="https://firebasestorage.googleapis.com/v0/b/nuparla-bbc0a.appspot.com/o/videos%2FWhatsApp%20Video%202020-10-14%20at%203.48.51%20PM.mp4?alt=media&token=9a1e8d24-2bbc-4e44-b9f4-82b9f9357fc4"
                                        />
              </div>}
                                          
              


              <p>Juan from Mérida, Mexico</p>
            </div>
            <h1>La decisión final la tienes TÚ...</h1>
          </Grid3>

          <Grid3 style={{ textAlign: "center", color: "#38448a", marginTop: window.innerWidth >= 900 ? -250 : 0 }}>
              
            <div style={{margin: '10px'}}>
            
          
            </div>
            <P style={{ width: "80%", margin: "auto" }}>
            ¿Por qué aprender inglés con NuParla? Porque NuParla fue hecho con mucho corazón y cabeza. Te asegura hablar inglés en unos pocos días, y dominarlo en menos de 12 semanas. Nuestros vídeos online son 100% GRATIS, y si deseas vivir y compartir la experiencia completa de NuParla con un ser amado y un joven huérfano en tu país, es solo US$49.95. Nadie, ningún curso online, gratuito ni pagado, ofrece tanto valor por tan poco costo. 
            </P>
          </Grid3>

          <Grid3 style={{ textAlign: "center", color: "#38448a" }}>
            <div style={{margin: 'auto'}}>
            {window.innerWidth >= 900 && <div>
                <ReactPlayer
                                        onContextMenu ={(e) => e.preventDefault()}
                                            style={{ margin: "0px auto 20px auto"}}
                                            controls={true}
                                            width={240}
                                            height={window.innerwidth >= 1000 ? 420 : 420}
                                            playing={false}
                                            config={{ file: { 
                                            attributes: {
                                                controlsList: 'nodownload'
                                            }
                                            }}}
                                            url="https://firebasestorage.googleapis.com/v0/b/nuparla-bbc0a.appspot.com/o/videos%2FWhatsApp%20Video%202021-04-21%20at%205.05.02%20PM.mp4?alt=media&token=927cafa5-78b4-4963-a767-e32d46680008"
                                        />
              </div>}
              {window.innerWidth < 899 && <div
                // style={{
                //   width: "90%",
                //   height: 500,
                //   margin: "auto",
                //   boxShadow: "0px 2px 30px rgba(0,0,0,0.6)",
                // }}
              >
                <ReactPlayer
                                        onContextMenu ={(e) => e.preventDefault()}
                                            style={{ margin: "0px auto 20px auto"}}
                                            controls={true}
                                            width={240}
                                            height={window.innerwidth >= 1000 ? 420 : 420}
                                            playing={false}
                                            config={{ file: { 
                                            attributes: {
                                                controlsList: 'nodownload'
                                            }
                                            }}}
                                            url="https://firebasestorage.googleapis.com/v0/b/nuparla-bbc0a.appspot.com/o/videos%2FWhatsApp%20Video%202021-04-21%20at%205.05.02%20PM.mp4?alt=media&token=927cafa5-78b4-4963-a767-e32d46680008"
                                        />
              </div>}
            
              <p>Neiza, Santa Cruz, Bolivia</p>
            </div>
            <P style={{ width: "80%", margin: "0px auto" }}>
              Tú y yo sabemos que ningún curso que valga la pena tiene sentido a
              menos que su creador te entienda como LATINO, pero que sea tan
              Estadounidense que pueda plantar el inglés muy dentro de tu
              corazón y mente para que fluya de ti naturalmente. Con NuParla,
              estamos comprometidos a luchar a tu lado más que cualquier otro
              curso, y no nos rendiremos hasta que hables el inglés para que
              logres tus metas.{" "}<br/><br/>
              Así que escoge la plataforma que tantos están prefiriendo. No
              dejes que pase más tiempo, o que otro tome tu lugar. Si actúas
              ahora tendrás potencialmente doble las oportunidades con tu nuevo
              conocimiento del inglés en 90 días.{" "}
              <u>
                Eso es lo que yo haría, y he estado haciendo esto por dos
                décadas.
              </u>{" "}
              Pero, la verdad es que no puedo decirte qué es lo que tienes que
              hacer. Tú harás lo que es mejor para ti y tus metas.{" "}
            </P>
          </Grid3>

          {/* <Grid3 style={{ textAlign: "center", color: "#38448a" }}>
            <div>
            <img src={pic2} style={{width: '60%'}} />
            

            </div>
            <P style={{ width: "80%", margin: "auto" }}>
              Así que escoge la plataforma que tantos están prefiriendo. No
              dejes que pase más tiempo, o que otro tome tu lugar. Si actúas
              ahora tendrás potencialmente doble las oportunidades con tu nuevo
              conocimiento del inglés en 90 días.{" "}
              <u>
                Eso es lo que yo haría, y he estado haciendo esto por dos
                décadas.
              </u>{" "}
              Pero, la verdad es que no puedo decirte qué es lo que tienes que
              hacer. Tú harás lo que es mejor para ti y tus metas.{" "}
            </P>
          </Grid3> */}

          <Grid3 style={{ textAlign: "center", color: "#38448a" }}>
            <div>
            {/* <img src={Audible} style={{width: '50%', marginBottom: 20}} />
            <P>Haz clic AQUÍ para descargar el audiolibro 100% GRATIS por medio de Audible a tu dispositivo móvil. </P> */}

            </div>
            <P style={{ width: "80%", margin: "auto" }}>
            Si vas a decirle “Sí” a NuParla Online English Academy, entonces no dilates. Un proverbio oriental dice: “El mejor momento para plantar un árbol fue hace 20 años…mas el segundo mejor momento es AHORA MISMO…”
            </P>
          </Grid3>

          <a href="#register"><div
            style={{
              margin: "60px auto",
              width: "70%",
              padding: 10,
              textAlign: "center",
              color: "white",
              backgroundColor: "red",
              borderRadius: 20,
            }}
          >
            <h2 style={{ color: "white" }}>
            ¡Sí, veo valor!
            </h2>
            <P>Quiero la experiencia completa de NuParla por solo US$49.95. 
            (Haz clic para ir directamente a la sección de pagos.)</P>
          </div></a>

          <div style={{ color: "#38448a", width: "90%", margin: "auto" }}>
            <h1 style={{ textAlign: "center" }}>¿Existe alguna garantía?</h1>
            <P>
            Por supuesto… <br/>
            <br/>
            Te garantizo que viendo y practicando con los 90 videos gratuitos hablarás inglés en cuestión de días, y lo dominarás por completo rápidamente. Esto es 100% gratis.  
<br/><br/>
            Te garantizo que si ingresas a la experiencia completa de NuParla, solo pagarás un pago único de US$49.95 para ti y tú escoges a quién le quieres regalar una completamente gratis, y si refieres a alguien para que haga lo mismo, te comisiono el 20%, que equivale a US$10. 
<br/><br/>
            Te garantizo que cambiarás la vida de un joven huérfano adolecente cuando en tu nombre le obsequiemos una experiencia NuParla completa. ¡Todos ganamos con NuParla!
            </P>
          </div>

          <a href="#register"><div
            style={{
              margin: "60px auto",
              width: "70%",
              padding: 10,
              textAlign: "center",
              color: "white",
              backgroundColor: "red",
              borderRadius: 20,
            }}
          >
            <h2 style={{ color: "white" }}>
            ¡Sí, veo valor!
            </h2>
            <P className="p">
            Quiero la experiencia completa de NuParla por solo US$49.95. 
(Haz clic para ir directamente a la sección de pagos.)
            </P>
          </div></a>

          <div style={{ color: "#38448a", width: "90%", margin: "auto" }}>
            <h1 style={{ textAlign: "center" }}>
              Los siguientes pasos para tomar...
            </h1>
            <P>
            Si vas a tomar la ruta de aprender inglés GRATIS online, felicidades. Si adicionalmente quieres aprovechar la experiencia COMPLETA de NuParla por solo US$49.95, solo pagas abajo y creas tu tu cuenta abajo. ¡Felicidades! Espero escuchar de todos tus logros y éxitos en los meses y años por venir.
            <br/><br/>
            Bienvenido a la familia NuParla...
            <br/><br/>
            Un servidor,
            <br/>
            Harold Zapata
            <br/><br/>
            P.D. En dado caso que seas una de aquellas personas que les gusta los resúmenes primordialmente (como yo), te presento lo esencial del asunto:
            <br/><br/>
            Puedes aprender inglés GRATIS online con nuestros 90 vídeos que te llevan de la mano desde un nivel básico, elemental, intermedio e intermedio avanzado completamente GRATIS (solo regálanos un Like a cada video si obtienes valor y subscríbete al canal de YouTube de NuParla). Si deseas experimentar NuParla en su totalidad, por un pago único de solo US$49.95, obtienes el app de NuParla, el app NRS para repetición espaciada, la plataforma cibernética de aprendizaje avanzado, el software para gestión de ventas para Afiliados ($10 por referidos), mas 5 bonuses adicionales para prepararte para eventos importantes a ti, a un ser amado y a un huérfano en tu país.  
            <br/><br/>
            Entonces, si te parece...¿qué esperas? Únete a la experiencia completa de NuParla por US$49.95, o mira los vídeos completamente gratis. De una u otra forma, ¡todos ganamos con NuParla!
            </P>
          </div>

          <div
            style={{
              border: "8px solid rgb(0, 36, 98)",
              margin: "60px auto",
              width: "90%",
            }}
          >
            <div
              style={{
                backgroundColor: "rgb(0, 36, 98)",
                padding: "10px 0px",
                color: "white",
                width: "100%",
                textAlign: "center",
                margin: "0px auto 0px auto",
              }}
            >
              <h2 style={{ color: "white" }}>REPASEMOS</h2>
              <h1 style={{ color: "white" }}>TODO LO QUE ES TUYO</h1>
              <h2 style={{ color: "white" }}>
              Cuando eliges obtener TODO el poder de NuParla
              </h2>
            </div>

            <div style={{ textAlign: "center" }}>
              <img
                src={mainImg}
                style={{ width: "80%", margin: "auto", textAlign: "center" }}
              />
            </div>

            <div style={{ width: "100%", margin: "auto" }}>
              <div style={{ margin: "auto" }}>
                <img src={prices} style={{ width: "100%" }} />
                {/* <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >El método neurocientífico exclusivo de NuParla</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ Inestimable)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >La plataforma avanzada de aprendizaje cibernético: </li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($400)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Más de 100+ videos (50+ horas) de coaching con Hárold</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($1,700)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Más de 100+ grabaciones descargables (30+ horas) con Hárold</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($700)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Sistema bi-direccional de mensajes exclusivo a NuParla</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($400)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li > Programa de Afiliados NuParleros para ganar dinero</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ Inestimable)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Oficina virtual y sitio de web replicable para gestionar tus ventas</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($750)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Ingreso a NuParla Village</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($300)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Acceso de por vida a NuParla University</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ Inestimable)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >BONUS e-book: Inglés subconciente: Los 7 secretos de NuParla</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($35)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >BONUS curso exclusivo: Prepared For The Job Interview</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($250)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li  >BONUS curso exclusivo: Prepared For Traveling Abroad</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($250)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li  >BONUS curso exclusivo: Prepared For The USCIS Civics Test</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($250)</p>
                        </div> */}
              </div>

              <h1
                style={{
                  color: "red",
                  textAlign: "center",
                  margin: "20px 0px",
                }}
              >
                Valorado en: US $<s>4,995</s>
              </h1>

              <P style={{ width: "90", textAlign: "center", margin: "auto" }}>
              Si quieres todas las poderosas herramientas de NuParla para aprender inglés con tu mejor amigo, y obsequiarle esa misma oportunidad a un huérfano adolecente en tu país, entonces di “Sí” a esta oportunidad. 
              </P>

              

              <h1 style={{ color: "red", textAlign: "center", marginTop: 20 }}>
                ¿ACEPTAS?
              </h1>
            </div>

            <div
              style={{
                backgroundColor: "rgb(0, 36, 98)",
                padding: "15px 0px",
                color: "white",
                width: "100%",
                textAlign: "center",
                margin: "60px auto 0px auto",
              }}
            >
              <h2 style={{ color: "white" }}>
              Únete y regala la experiencia COMPLETA de NuParla con un pago único 
              </h2>
              <h1 style={{ color: "red" }}>DE SOLO US$49.95 </h1>
            </div>
          </div>

          <div
            style={{
              width: "80%",
              margin: "auto",
              border: "1px solid #bebebe",
            }}
            id="register"
          >
            <div style={{ width: "100%", margin: "auto" }}>
              {/* <h2
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "10px 0px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                PASO 1. ELIGE TU PLAN
              </h2>
              <div style={{ width: "90%", margin: "auto" }}>
                <div className="flex" style={{ color: "#38448a" }}>
                <Checkbox
                style={{marginTop: -20}}
                color="primary"

                  checked={this.state.plan == 1}
                  onChange={( ) => {this.setState({plan: 1})}}
                />
                  <P>Un pago de US $399 - (total: US $399)</P>
                </div>
                <div className="flex" style={{ color: "#38448a" }}>
                <Checkbox
                style={{marginTop: -20}}
                color="primary"

                  checked={this.state.plan == 2}
                  onChange={( ) => {this.setState({plan: 2})}}
                />
                  <P>Dos pagos de US $249 - (total: US $498)</P>
                </div>
                <div className="flex" style={{ color: "#38448a" }}>
                <Checkbox
                style={{marginTop: -20}}
                  color="primary"
                  checked={this.state.plan == 3}
                  onChange={( ) => {this.setState({plan: 3})}}
                />
                  <P>Tres pago de US $199 - (total: US $597)</P>
                </div>
              </div> */}

              <h2
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "10px 0px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                PASO 1. INFORMACIÓN DE CONTACTO Y CREDENCIALES DE PLATAFORMA
              </h2>

              <div style={{ width: "90%", margin: "auto" }}>
                <Grid50>
                  <div>
                    <P2>Nombre:</P2>
                    <input
                      className="input"
                      onChange={(e) =>
                        this.setState({ firstName: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <P2>Apellido:</P2>
                    <input
                      className="input"
                      onChange={(e) =>
                        this.setState({ lastName: e.target.value })
                      }
                    />
                  </div>
                </Grid50>
                <div>
                  <P2>Correo electrónico:</P2>
                  <input
                    className="input"
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>
                {/* <Grid50>
                <div style={{width: '90%'}}>
                  <P2>País:</P2>
                  <CountryDropdown
                  classes="region-select"
                  value={this.state.country}
                  onChange={(val) => this.selectCountry(val)} />
                </div>
                <div>
                  <P2>Estado/Depto:</P2>
                  <RegionDropdown
                  classes="region-select"
                  country={this.state.country}
                  value={this.state.region}
                  onChange={(val) => this.selectRegion(val)} />
                </div>

                </Grid50> */}
                
                
                
                

                

                

                {/* <div>
                  <P2>Nombre de usuario</P2>
                  <input
                    className="input"
                    placeholder="(para ingresar a tu plataforma virtual)"
                    onChange={(e) =>
                      this.setState({ userName: e.target.value })
                    }
                  />
                </div> */}

                <div>
                  <P2>Contraseña</P2>
                  <input
                  type="password"
                  placeholder= "(mínimo 8 dígitos alfa-numéricos)"
                    className="input"
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                </div>

                <div>
                  <P2>Contraseña confirmada</P2>
                  <input
                  type="password"
                    className="input"
                    onChange={(e) =>
                      this.setState({ verifyPassword: e.target.value })
                    }
                  />
                </div>
              </div>
              <h2
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "10px 0px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                PASO 2. PAGO US$49.95
              </h2>
              <div style={{width: '90%', margin: 'auto'}}>
              <div className="flex">
              <input
                type="checkbox"
                style={{ marginTop: 35, marginRight: 10, marginLeft: 10, cursor:'pointer' }}
                checked={this.state.payment == 'stripe'}
                onChange={()=>this.setState({payment: 'stripe'})}
              />
              <img src={stripe} style={{height: 80}}/>
            </div>
            <div className="flex">
              <input
                type="checkbox"
                style={{ marginTop: 35, marginRight: 10, marginLeft: 10, cursor:'pointer' }}
                checked={this.state.payment == 'paypal'}
                
                onChange={()=>this.setState({payment: 'paypal'})}
              />
              <img src={paypal} style={{height: 80}}/>
            </div>
            <div className="flex">
              <input
                type="checkbox"
                style={{ marginTop: 35, marginRight: 10, marginLeft: 10, cursor:'pointer' }}
                checked={this.state.payment == 'btc'}
                onChange={()=>this.setState({payment: 'btc'})}
              />
              <img src={bitcoin} style={{height: 80}}/>
            </div>

              </div>
                <div style={{width: '90%', margin: 'auto', textAlign: 'center'}}>
                  <div style={{margin: '10px auto', backgroundColor: '#ebebeb', borderRadius: 5, padding: 10}}>
                  <p>*Clicking on Crea tu cuenta (Create Your Account) means that you have read and agree with NuParla’s <a href="/terms" className="terms-highlight" style={{color: 'black'}}>Terms</a> and <a href="/privacy" className="terms-highlight" style={{color: 'black'}}>Privacy</a> policies. </p>
                  <p>*Al hacerle clic a Crea tu cuenta significa que has leído y apruebas las políticas de términos y privacidad de NuParla.</p>
                

                  </div>
                
                <button className="next-step"  onClick={() => this.verify()}>
                Crea tu cuenta
                </button>

                </div>
              
            </div>
          </div>

          <div
            style={{
              backgroundColor: "rgb(0, 36, 98)",
              padding: "15px 5%",
              color: "white",
              width: "90%",
              textAlign: "center",
              margin: "60px auto 0px auto",
            }}
          >
            <img />
            <div
              className="flex"
              style={{
                margin: "auto",
                width: 300,
                justifyContent: "space-around",
              }}
            >
              <Link to="/terms">
                <P>Terms</P>
              </Link>
              <Link to="/privacy">
                <P>Privacy</P>
              </Link>
              <Link to="/support">
                <P>Support</P>
              </Link>
              
            </div>

            <P>
              © 2021 NuParla LLC - All Rights Reserved - 211 N. State St. Suite 203, Hemet, CA-92543
              
            </P>
            <p style={{fontSize: 12}}>This site is not a part of the Facebook website or Facebook Inc., nor Audible website nor Amazon, Inc. It is neither endorsed by Facebook, Amazon nor Audible in any way. 
FACEBOOK is a trademark of FACEBOOK, Inc., and AUDIBLE is a trademark of AMAZON, Inc. </p>
          </div>
        </div>
        <Dialog
        open={this.state.backdrop}
        style={{padding: '40px 40px 20x 40px'}}
        >
          <p style={{margin: 20}}>Please Wait...</p>
          <CircularProgress style={{width: 30, margin: '10px auto'}} />
        </Dialog>
      </div>
    );
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 55% 45%;
  margin-top: 30px;

  @media (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Grid2 = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  margin: 30px 20px;
  @media (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Grid50 = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  margin-top: 60px;
  column-gap: 4%;
  @media (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
  }
`;
const Grid3 = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  margin-top: 60px;
  @media (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const SelectDiv = styled.div`
  border: 4px solid rgba(164, 209, 250, 0.78);
  width: 80%;
  padding: 10px;
  margin: auto;
  margin-top: 40px;
  background-color: rgb(228, 239, 249);
  color: rgb(30, 77, 167);
`;

const P = styled.p`
  font-size: x-large;

  @media (max-width: 800px) {
    font-size: large;
  }
`;

const P2 = styled.p`
  font-size: x-large;
  margin-bottom: 0px;

  @media (max-width: 800px) {
    font-size: large;
  }
`;
