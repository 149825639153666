import React, { Component } from "react";
import  '../../../App.css'
import { makeStyles } from '@material-ui/core/styles';

import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";
import nuparla from '../../../Images/banner.jpg'
import { TextField, Paper } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { fire } from "../../../config";
import "../../../config" ;
import storage from "../../../config";
import * as firebase from "firebase";
import { getQueriesForElement } from "@testing-library/react";
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '../../../Images/user-avatar.png'
import Dialog from '@material-ui/core/Dialog';
import ProfilePicture from "profile-picture"
import "profile-picture/build/ProfilePicture.css"
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

const whiteTheme = createMuiTheme({
  palette: { primary: { main: "##edd490" }, secondary: { main: "#3A66A7" } },
});

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));
  

export default class profile extends Component {
  constructor() {
    super();
    this.state = {
      user: {
          firstName: '',
          lastName: '',
          userName: '',
          email: '',
          address: {
            country: '',
            region: '',
            phone: '',
            address: '',
            city: '',
            zipcode: ''
          },
          card: '',
          url: ''
      },
      url: '',
      changed: false,
      dialog: false
    };
    this.profilePictureRef = React.createRef();
  }

  componentDidMount(){

    this.getUser()
  }

  handleUpload() {
    const PP = this.profilePictureRef.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();
    console.log(PP)
    console.log(imageData)
    console.log(file)
    
  
  }

  // selectCountry (val) {
  //   let {user} = this.state;
  //   user.address.country = val;
  //   this.setState({ user });
  // }
  
  // selectRegion (val) {
  //   let {user} = this.state;
  //   user.address.region = val;
  //   this.setState({ user });
  // }

  getUser(){
    firebase.firestore().collection('Users').doc(localStorage.getItem('email')).onSnapshot(res => {
        
    this.setState({user: res.data()})
    
    })
  }

  save(){
      let {user} = this.state;
      if (this.state.url == ""){
        firebase.firestore().collection('Users').doc(localStorage.getItem('email')).set(user)
        this.setState({changed: false})

      } else {
        let upload = storage
        .ref(this.state.url.name)
        .put(this.state.url);
      upload.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref(this.state.url.name)
            .getDownloadURL()
            .then((url) => {
              user.url = url;
              firebase.firestore().collection('Users').doc(localStorage.getItem('email')).set(user)
              this.setState({changed: false})

            })})

      }
      
  }



  render(){
      let {user} = this.state;

      return (
        <div style={{width: '100%', height: '100%', paddingBottom: 40,paddingTop: 30, backgroundColor: '#909090'}}>
            <h1 style={{fontSize: 'xx-large', color: '#edd490', backgroundColor: '#3c3b6e', padding: 6, borderRadius: 10, margin: 'auto', width: '50%'}}>PROFILE</h1>


            <Grid>
              


            <div style={{padding: 20, width: '90%', margin: '10px auto', borderRadius: 10, border: '1px solid #edd490', textAlign: 'center', backgroundColor: '#bebebe'}}>
                <TextField fullWidth  variant="filled" color="primary" style={{margin: 7, color: '#edd490'}} label="First Name" value={user.firstName} onChange={(e)=> {
                    user.firstName = e.target.value;
                    this.setState({user, changed: true})

                }} />
                <TextField fullWidth  variant="filled" style={{margin: 10}} label="Last Name" value={user.lastName} onChange={(e)=> {
                    user.lastName = e.target.value;
                    this.setState({user, changed: true})
                }} />
                <TextField fullWidth variant="filled" style={{margin: 10}} label="Username" value={user.userName} onChange={(e)=> {
                    user.userName = e.target.value;
                    this.setState({user, changed: true})
                }} />
                <TextField fullWidth variant="filled" style={{margin: 10}} label="Email" disabled value={user.email} onChange={(e)=> {
                   
                }} />
                <TextField fullWidth variant="filled" style={{margin: 10}} label="Address" value={user.address.address} onChange={(e)=> {
                    user.address.address = e.target.value;
                    this.setState({user, changed: true})
                }} />

                <TextField fullWidth variant="filled" style={{margin: 10}} label="City" value={user.address.city} onChange={(e)=> {
                    user.address.city = e.target.value;
                    this.setState({user, changed: true})
                }} />

                

                <TextField fullWidth variant="filled" style={{margin: 10}} label="State" value={user.address.region} onChange={(e)=> {
                    user.address.region = e.target.value;
                    this.setState({user, changed: true})
                }} />

                <TextField fullWidth variant="filled" style={{margin: 10}} label="Country" value={user.address.country} onChange={(e)=> {
                  console.log(user)
                    user.address.country = e.target.value;
                    this.setState({user, changed: true})
                }} />


                  {/* <CountryDropdown
                  classes="region-select"
                  value={user.address.country}
                  onChange={(val) => this.selectCountry(val)} />

                  <RegionDropdown
                  classes="region-select"
                  country={user.address.country}
                  value={user.address.region}
                  onChange={(val) => this.selectRegion(val)} /> */}


                
                
                <TextField fullWidth variant="filled" style={{margin: 10}} label="zip code" value={user.address.zipcode} onChange={(e)=> {
                    user.address.zipcode = e.target.value;
                    this.setState({user, changed: true})
                }} />
                
                <TextField fullWidth variant="filled" style={{margin: 10}} label="Phone Number" value={user.address.phone} onChange={(e)=> {
                    let user2 = this.state.user;
                    let address = user2.address;
                    
                    address.phone = e.target.value;
                    user2.address = address;
                    this.setState({user: user2, changed: true})
                }} />
                {/* <TextField fullWidth variant="outlined" style={{margin: 7}} label="Credit/Debit card" value={user.card} onChange={(e)=> {
                    user.card = e.target.value;
                    this.setState({user, changed: true})
                }} /> */}


            </div>

            <div style={{textAlign: 'center', margin: '10px auto'}}>
              {window.innerWidth >= 700 && <div style={{padding: 10, width: '100%',backgroundColor: '#bebebe', border: '1px solid #edd490', borderRadius: 10, height: 230, margin: '10px auto'}} >
                <img src={this.state.url != "" ? URL.createObjectURL(this.state.url) : user.url == undefined ? Avatar : user.url == "" ? Avatar : user.url} style={{borderRadius: 5, height: 150, width: 'auto'}} />
                <input accept="image/*" style={{height: 0, width: 0, display: 'none'}} id="icon-button-file" type="file" onChange={(e)=> {this.setState({url: e.target.files[0], changed: true})}} />
                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                <label htmlFor="icon-button-file">
                    <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                    </IconButton>
                </label>
                {/* <IconButton color="primary" onClick={() => {
                  this.setState({dialog: true})
                }}>
                    <PhotoCamera />
                    </IconButton> */}
                <IconButton color="secondary" aria-label="delete" onClick={()=> {
                    user.url = "";
                    this.setState({user, url: "", changed: true})
                }}>
                    <DeleteIcon />
                </IconButton>

                </div>
                

            </div>}

            {window.innerWidth < 700 && <Paper style={{padding: 10, width: '200',height: 230, margin: '10px auto'}} >
                <img src={this.state.url != "" ? URL.createObjectURL(this.state.url) : user.url == undefined ? Avatar : user.url == "" ? Avatar : user.url} style={{borderRadius: 5, height: 150, width: 'auto'}} />
                <input accept="image/*" style={{height: 0, width: 0, display: 'none'}} id="icon-button-file" type="file" onChange={(e)=> {this.setState({url: e.target.files[0], changed: true})}} />
                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                <label htmlFor="icon-button-file">
                    <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                    </IconButton>
                </label>
                {/* <IconButton color="primary" onClick={() => {
                  this.setState({dialog: true})
                }}>
                    <PhotoCamera />
                    </IconButton> */}
                <IconButton color="secondary" aria-label="delete" onClick={()=> {
                    user.url = "";
                    this.setState({user, url: "", changed: true})
                }}>
                    <DeleteIcon />
                </IconButton>

                </div>
                

            </Paper>}
            {/* <div style={{margin: 'auto'}} className="aprende-btn" onClick={()=> {
              localStorage.clear();
              window.location.reload()
            }}> Logout</div> */}

              </div>

            </Grid>
              
            <Button variant="contained"  color="primary" disabled={!this.state.changed} onClick={()=> this.save()}> Save</Button>
            
            <Dialog
            open={this.state.dialog}
            onClose={()=> this.setState({dialog: false})}
            >
            <ProfilePicture
              ref={this.profilePictureRef}
              useHelper={true}
              debug={true}
            />

            <button onClick={this.handleUpload.bind(this)}>Upload</button>

            </Dialog>



 
            
        </div>

      )
  }

}

const Grid = styled.div`
    display: grid;
    grid-template-columns: 70% 30%;
    margin: 10px;

    @media (max-width: 800px){
        display: flex;
        flex-wrap: wrap;
    }

`