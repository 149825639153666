import React, {Component} from 'react';
import '../../App.css';
import styled from 'styled-components';

import logoblack from '../../Images/nuparla em1 .1 w.jpg'
import logogold from '../../Images/nuparla em1 .1.png'


export default class privacy extends Component {
    constructor(){
        super();
        this.state = {
            lang: 'english'
        }
    }

    componentDidMount(){

    }

    render(){
        
        return (
            <div style={{maxWidth: 1280, margin: '20px auto'}}>
                <div style={{height: '100px'}}>
                    <a href="/"><img src={logoblack} className="logo-top" /></a>
                    <div style={{display: 'flex', flexWrap: 'wrap', float: 'right'}}>
                    <p className="p10 pointer phover " onClick={()=>{
                        if (this.state.lang == 'english'){
                            this.setState({lang: 'spanish'})
                        } else {
                            this.setState({lang: 'english'})
                        }
                    }}>{this.state.lang == 'english' ? "Spanish" : this.state.lang == "spanish" ? "English" : 'English'}</p>
                        

                    </div>
                </div> 
                
                {this.state.lang == 'english' && <div style={{width: '90%', margin: 'auto'}}>
                    <H1 style={{width: '90%', margin: 'auto', textAlign: 'center'}}>PRIVACY POLICY</H1>
                    


                        <h3>1. General</h3>
                        <P2>At NuParla, we care about your personal data, so we have prepared this Privacy
                            Policy to explain how we collect, use and share it.<br/><br/>
                            This Privacy Policy (“Privacy Policy”) details the personal data NuParla LLC
                            (“NuParla”, “we”, “us” or “our”) receives about you, how we process it and your
                            rights and obligations in relation to your personal data. NuParla LLC, a Californiaregistered company in the United States is the data controller for the purposes of
                            the General Data Protection Regulation (“GDPR”) and any relevant local legislation
                            (“Data Protection Laws”).<br/><br/>
                            By using or accessing any of our websites such as www.nuparla.com,
                            www.aprendeinglésganadinero.com, our back office, apps or other services (the
                            "Service"), you agree to the terms of this Privacy Policy. Capitalized terms not
                            defined here have the meanings set forth in the terms and conditions (the “Terms
                            and Conditions”), located at www.nuparla.com/terms. We may update our Privacy
                            Policy to reflect changes to our information practices. If we do this and the changes
                            are material, we will post a notice that we have made changes to this Privacy Policy
                            on the Website for at least 7 days before the changes are made, and we will
                            indicate the date these terms were last revised at the bottom of the Privacy Policy.
                            Any revisions to this Privacy Policy will become effective at the end of that 7-day
                            period. </P2>

                        <h3>2. Information We Collect</h3>
                        <P2>This Privacy Policy explains how we collect, use and share your personal data.</P2>
                        <P2><u><i>Information you provide</i></u></P2>
                        <P2>Through the registration process to the Service, you will provide us with your
                            name, e-mail address and date of birth. You will also provide us with your
                            payment transaction information when you choose to pay for NuParla services.</P2>

                        <P2><u><i>Activity Data</i></u></P2>
                        <P2>When you use the Service, you will submit information and content to your
                            profile. We will also generate data about your use of our Services including your 
                            engagement in educational activities on the Service, or your sending of
                            messages and otherwise transmitting information to other users (“Activity
                            Data”). We also collect technical data about how you interact with our Services;
                            for more information, see Cookies.</P2>

                        <P2><u><i>Live Speaking Practice</i></u></P2>
                        <P2>When you take a Live Speaking Lesson, we will use your computer's
                            microphone throughout the lesson to speak to a NuParla tutor. NuParla records
                            these audio lessons and retains them until you close your NuParla account.
                            NuParla uses this data to help improve the quality of the lessons and for internal
                            research purposes.<br/><br/>
                            NuParla does not share any of your personal data with the tutor. NuParla only
                            shares some basic language skill level information with the tutor, including
                            lesson exercises prior to your lesson, so they understand how best to speak to
                            you.</P2>

                        <P2><u><i>Test Data</i></u></P2>
                        <P2>If you register for the NuParla English Test, you may also provide us with your
                            gender, native language, secondary school, and self-reported test scores for
                            other exams. Each time you take a test you must also provide a photo of a
                            current, valid driver’s license, passport, or other government issued ID and a
                            take a photo of your face to verify your identity (“Testing ID”).</P2>

                        <P2><u><i>Product Research and Development</i></u></P2>
                        <P2>We may contact you to participate in product research activities. These may
                            include surveys, interviews, and other types of feedback sessions. When you
                            participate in these research activities, the information you provide will be used
                            to test, improve, and develop our products and services. We will record the
                            video, audio and text transcriptions of this feedback together with any additional
                            contact information you provide and will retain this data for two years</P2>
                        
                        <P2>Please contact us at info@nuparla.com to:<br/>
                            • Request more information about the research activities you have
                            volunteered for.<br/>
                            • Opt out of being contacted for research activities.<br/>
                            • Request a copy of any research related data you have provided to
                            us.</P2>
                            

                        <h3>3. Cookies</h3>
                        <P2>When you enter the Website, we collect your browser type and your IP address (a
                            unique address that identifies your computer on the Internet). When you use our
                            mobile Application, we collect your device type, your device ID, and your IP
                            address.<br/><br/>
                            In addition, we store certain information from your browser using "cookies." A
                            cookie is a piece of data stored on the user's computer tied to information about
                            the user. We use session ID cookies to confirm that users are logged in. If you do
                            not want information collected through the use of cookies, there is a simple
                            procedure in most browsers that allows you to deny or accept the cookie feature;
                            however, you should note that cookies may be necessary to provide you certain
                            features available on the Website (e.g., customized delivery of information).<br/><br/>
                            You can find more information about cookies and how to manage them
                            at http://www.allaboutcookies.org. </P2>


                        <h3>4. Information Obtained by Third Parties</h3>
                        <P2>To support and enhance the Service, we work with a variety of third party
                            advertising networks, marketing analytics service providers and website analysis
                            firms, such as Google, Facebook, Oath, Unity and others (collectively, “Third Party
                            Advertising Service Providers”). These Third Party Advertising Service Providers
                            collect and use personal data about your visits to and use of the Website and
                            mobile Application, as well as other websites in order to serve you with
                            advertisements and content tailored to meet your preferences and likely interests
                            or better understand ad effectiveness. Aggregate demographic and interest data
                            will also be used for market research purposes.<br/><br/>
                            We use Google Analytics which is a web analytics tool that helps us understand
                            how users engage with the Website. Like many services, Google Analytics uses
                            first-party cookies to track user interactions as in our case, where they are used to
                            collect information about how users use our site. This information is used to
                            compile reports and to help us improve our Website. The reports disclose website
                            trends without identifying individual visitors. You can opt out of Google Analytics
                            without affecting how you visit our site – for more information on opting out of being
                            tracked by Google Analytics across all websites you use, visit this Google
                            page: https://tools.google.com/dlpage/gaoptout.<br/><br/>
                            You can opt-out of receiving personalized ads from advertisers and ad networks
                            that are members of the Network Advertising Initiative (“NAI”) or who follow the
                            DAA Self-Regulatory Principles for Online Behavioral Advertising using their
                            respective opt-out tools. The NAI's opt-out tool can be found
                            here http://www.networkadvertising.org/choices/ and the DAA's opt out tool can be
                            found here http://www.aboutads.info/choices/.<br/><br/>
                            In addition, your mobile devices may offer settings that enable you to make choices
                            about the collection, use, or transfer of mobile app information for online behavioral
                            advertising (for example, Apple iOS’ Advertising ID and Google Android’s
                            Advertising ID). Please note that opting out does not prevent the display of all
                            advertisements to you.</P2>


                        <h3>5. Use of information obtained by NuParla</h3>
                        <P2>NuParla may occasionally send you service related and product change
                            announcements through the general operation of the Service. We will send you
                            reminder notifications to support the NuParla Subconscious English teaching
                            methodology by reinforcing the learning cadence and help maintain your learning
                            streak. We process your data to help provide you with the Service and offer
                            personalized features, to understand and improve our Service and to keep our
                            Service safe and secure.<br/><br/>
                            NuParla may use or share anonymous data collected through the Service,
                            including Activity Data without limitation. As a business, it is critical that we perform
                            our contract with you with the best service possible, and it is in our legitimate
                            interests to perform these processing functions and to enable service e-mails by
                            default to keep your data secure and provide our Service. You may opt out of any
                            non-essential service e-mails at any time.<br/><br/>
                            We may also use your contact information to send you notifications regarding new
                            services, offers and promotions offered by NuParla if you affirmatively consent to
                            receive such communications.<br/><br/>
                            Profile information is used by NuParla to be presented back to and edited by you
                            when you access the Service and to be presented to other users. NuParla uses
                            this information to provide you with support, to send you essential notifications, to
                            enforce our terms, conditions and policies, to communicate with you, to administer
                            the Services, and for internal operations, including troubleshooting, data analysis, 
                            testing, research, statistical, and survey purposes. NuParla’s Terms and
                            Conditions provide the contractual lawful basis for these processing activities.<br/><br/>
                            <u><i>NuParla English Test</i></u><br/>
                            When you take a test in the NuParla English Test, we will use your computer's
                            camera and microphone throughout the test to collect a Testing Video.<br/><br/>
                            We will process information in connection with the NuParla English Test
                            comprising given name(s), surname(s), date of birth, gender, native language,
                            country, secondary school, self-reported test scores for other exams, application
                            IDs, a picture or scan of a current, valid driver’s license, passport or other
                            government issued ID, your Testing ID, Testing Video, and answers to questions
                            as part of the testing and evaluation process in order:<br/>
                            • To provide accurate testing results to Score Recipient(s) (as defined below)<br/>
                            • To compare your Testing ID to your Testing Video to ensure that the person
                            who registered for the examination (and whose identity NuParla has
                            validated) is the same person who took the examination<br/>
                            • To store and review the Testing Video recording to validate that you took the
                            examination without improper assistance<br/>
                            • To evaluate your performance on the NuParla English Test, to report results
                            and to evaluate the usefulness, accuracy and other aspects of the NuParla
                            English Test<br/>
                            • For our research team to validate and improve the examination, in an
                            anonymized format<br/>
                            • To analyze audio recordings of verbal responses to improve our ability to
                            understand the spoken language<br/>
                            • To share your Testing ID with your consent upon initiation of a dispute by a
                            Score Recipient<br/>
                            NuParla’s Terms and Conditions provide the contractual lawful basis for these
                            English Test processing activities.</P2>


                        <h3>6. Sharing your Personal Data with Third Parties</h3>
                        <P2>NuParla shares your personal data only when it is necessary to offer the Service,
                            legally required, or permitted by you.<br/><br/>
                            We will provide personal data to hosting providers such as Amazon Web
                            Services, search engine providers such as Google, analytics providers such as
                            Crashlytics, and support providers such as ZenDesk.<br/><br/>
                            These data processors help us bring you the Service. For example, we may
                            share your information in order to detect where or how you encountered a bug
                            when using our mobile application. In connection with these operations, our
                            service providers will have access to personal data for a limited time. When we
                            utilize service providers for processing any personal data, we implement
                            contractual protections limiting the use of that personal data to the provision of
                            services to NuParla.<br/><br/>
                            We will be required to access and disclose personal data in response to lawful
                            requests, such as subpoenas or court orders, or in compliance with applicable
                            laws. Additionally, we will access and share account or other personal data
                            when we believe it is necessary to comply with law, to protect our interests or
                            property, to prevent fraud or other illegal activity perpetrated through the Service
                            or using the NuParla name, or to prevent imminent harm. This will include
                            accessing and sharing personal data with other companies, lawyers, agents or
                            government agencies if required.<br/><br/>
                            NuParla will share the complete and accurate results of NuParla English Tests
                            with such institutions, including universities, potential employers, or other third
                            parties, (collectively, “Score Recipients”) as specified when you send your
                            results after finishing a test. These results will include your test score, a video
                            of the interview section of your test, and your written responses to certain parts
                            of the test. We will also share your full name, date of birth, e-mail address, and
                            the photo you take of yourself. We will never share examination results with any
                            party without your approval, except that anonymized examination results will be
                            used by NuParla and its partners to improve the examination and for research
                            and analysis. We will not share your Testing ID with any Score Recipients,
                            except with your consent upon initiation of a dispute by a Score Recipient.<br/><br/>
                            If the ownership of all or substantially all of the NuParla business, or individual
                            business units or assets owned by NuParla that are related to the Service, were
                            to change, your personal data will be transferred to the new owner. In any such
                            transfer of information, your personal data would remain subject to this section.<br/><br/>
                            NuParla will share aggregate or anonymous data collected through the Service,
                            including Activity Data, for purposes such as understanding or improving the
                            service.</P2>

                        <h3>7. Data Subject Rights and Data Retention</h3>
                        <P2>You can manage your account settings from your dashboard to update, amend,
                            and correct your information.<br/><br/>
                            You also have the following rights in relation to the personal data we hold about
                            you, unless provided otherwise by local law:<br/>
                            • To request access to, or erasure of, the personal data we hold about you<br/>
                            • To request us to restrict the processing of the personal data we hold about
                            you<br/>
                            • To object to us processing personal data relating to you<br/>
                            • Where you have given us consent to process your personal data, you have
                            the right to withdraw that consent at any time<br/>
                            • To export the personal data you have provided to NuParla in a format that
                            can be transferred electronically to a third party<br/>
                            • To delete your account with NuParla by following the instructions available
                            through the Service<br/><br/>
                            NuParla will retain your data until your account is deleted, after which point we will
                            retain anonymous data collected through the Service, including Activity Data,
                            which may be used by NuParla and shared with third parties in any manner.
                            Information associated with the NuParla English Test, including examination
                            results and your Testing Video, will be collectively deleted from your NuParla
                            account after three (3) years have passed from the date you upload your test, but
                            anonymized examination data, including your examination results and Testing
                            Video, will be kept indefinitely by NuParla to improve the examination and for
                            research and analysis.<br/><br/>
                            Please note that some of these rights are not absolute. In some cases, we may
                            refuse a request to exercise particular rights if complying with it meant that we are
                            no longer able to meet our contractual obligation to provide you with particular
                            products and services. We will keep you informed as to the actions that we can
                            take when you make your request.</P2>

                        <h3>8. Third Party Websites and Links</h3>
                        <P2>Please note that you may have cookies placed on your computer by third party
                            websites that refer you to our Service. Although we do not share your personal
                            data with these third party websites unless it is reasonably necessary to offer the
                            Service, they may be able to link certain non-personally identifiable information we 
                            transfer to them with personal data they previously collected from you. Please
                            review the privacy policies of each website you visit to better understand their
                            privacy practices. In addition, NuParla would like to inform you that anytime you
                            click on links (including advertising banners), which take you to third party
                            websites, you will be subject to the third parties’ privacy policies.<br/><br/>
                            Our Services may contain links to other sites operated by third parties. NuParla
                            does not control such other sites and is not responsible for their content, their
                            privacy policies, or their use of personal data, including any personal or financial
                            information collected by our third party payment processor to process payments
                            for in-app purchases. NuParla’s inclusion of such links does not imply any
                            endorsement of the content on such sites or of their owners or operators except
                            as disclosed on the Services. Any information submitted by you directly to these
                            third parties is subject to that third party’s privacy policy</P2>

                        <h3>9. California Privacy Rights</h3>
                        <P2>Residents of California have the right to:<br/>
                            • Know what personal information we collect about you and from where we
                            collect it.<br/>
                            • Access a copy of the personal information we hold about you.<br/>
                            • Understand how we process your personal information and how it may be
                            shared.<br/>
                            • Opt out of having your personal information shared with certain third parties.<br/>
                            • Request we delete your NuParla account and personal information.<br/><br/>
                            To make such a request, please send an email to legal@nuparla.com.</P2>

                        <h3>10. Children under age of Digital Consent</h3>
                        <P2>The Service is only for adults. At all times NuParla does not knowingly collect
                            personal information from children in connection with the Service. If we discover
                            that we have unknowingly collected personal information from these children, we
                            will delete it. If you believe we have collected personal information from a child,
                            please contact us at legal@nuparla.com.</P2>


                        <h3>11. Links</h3>
                        <P2>The Service may contain links to other websites. We are not responsible for the
                            privacy practices of other websites. We encourage users to be aware when they 
                            leave the Service to read the privacy statements of other websites that collect
                            personally identifiable information. This Privacy Policy applies only to information
                            collected by NuParla via the Service.</P2>

                        <h3>12. Information Security</h3>
                        <P2>NuParla has implemented administrative and technical safeguards it believes are
                            appropriate to protect the confidentiality, integrity and availability of your personal
                            data, Testing ID, User Photo, access credentials, and NuParla English Test
                            results. However, given sufficient resources, a determined attacker could defeat
                            those safeguards and may, as a result, gain access to the data we seek to
                            protect. </P2>


                            <P style={{textAlign: 'center'}}>LASTEST REVISION DATE: June 5, 2020</P>


                </div>}

























                {this.state.lang == 'spanish' && <div style={{width: '90%', margin: 'auto'}}>
                    <H1 style={{width: '90%', margin: 'auto', textAlign: 'center'}}>POLÍTICA DE PRIVACIDAD</H1>
                    


                        <h3>1. General</h3>
                        <P2>En NuParla, nos preocupamos por sus datos personales, por lo que hemos
                            preparado esta Política de privacidad para explicar cómo los recopilamos,
                            usamos y compartimos.<br/><br/>
                            Esta Política de privacidad ("Política de privacidad") detalla los datos
                            personales que NuParla LLC ("NuParla", "nosotros", "nosotros" o "nuestro")
                            recibe sobre usted, cómo procesamos y cuáles son sus derechos y
                            obligaciones en relación con su datos personales. NuParla LLC, una compañía
                            registrada en California en los Estados Unidos, es el controlador de datos a los
                            efectos del Reglamento General de Protección de Datos ("GDPR") y cualquier
                            legislación local relevante ("Leyes de Protección de Datos").<br/><br/>
                            Al usar o acceder a cualquiera de nuestros sitios web como www.nuparla.com,
                            www.aprendeinglésganadinero.com, nuestra oficina virtual, aplicaciones u
                            otros servicios (el "Servicio"), usted acepta los términos de esta Política de
                            privacidad. Los términos no establecidos aquí tienen los significados
                            establecidos en los Términos y Condiciones, determinados en
                            www.nuparla.com/terms. Podemos actualizar nuestra Política de privacidad
                            para cambiar los cambios en nuestras prácticas de información. Si hacemos
                            esto y los cambios son materiales, publicamos un aviso de que hemos realizado
                            cambios en esta Política de privacidad en el sitio web durante al menos 7 días
                            antes de que se realicen los cambios, e indicamos la fecha de la última revisión
                            de estos términos en La parte inferior de la Política de privacidad. Cualquier
                            revisión de esta Política de privacidad entrará en vigencia al final de ese período
                            de 7 días. </P2>

                        <h3>2. Información que recopilamos</h3>
                        <P2>Esta Política de privacidad explica cómo recopilamos, usamos y compartimos sus datos personales.</P2>
                        <P2><u><i>Información que usted proporciona</i></u></P2>
                        <P2>A través del proceso de registro en el Servicio, nos proporcionará su
                            nombre, dirección de correo electrónico y fecha de nacimiento. También 
                            nos proporcionará su información de transacción de pago cuando elija
                            pagar por los servicios de NuParla.</P2>

                        <P2><u><i>Datos de actividad</i></u></P2>
                        <P2>Cuando use el Servicio, enviará información y contenido a su perfil.
                            También generaremos datos sobre su uso de nuestros Servicios, incluida
                            su participación en actividades educativas en el Servicio, o el envío de
                            mensajes y, de otro modo, la transmisión de información a otros usuarios
                            ("Datos de actividad"). También recopilamos datos técnicos sobre cómo
                            interactúa con nuestros Servicios. Para obtener más información, consulte
                            Cookies.</P2>

                        <P2><u><i>Practica de hablar en vivo</i></u></P2>
                        <P2>Al interactuar con las lecciones y al hablar en vivo, usaremos el micrófono
                            de su computadora durante toda la lección para hablar con un tutor de
                            NuParla. NuParla graba estas lecciones de audio y las retiene hasta que
                            cierre su cuenta de NuParla. NuParla utiliza estos datos para ayudar a
                            mejorar la calidad de las lecciones y para fines de investigación interna.<br/><br/>
                            NuParla no comparte ninguno de sus datos personales con el tutor. NuParla
                            solo comparte información básica sobre el nivel de su habilidad del idioma
                            con el tutor, incluidos los ejercicios antes de la lección, para que entiendan
                            la mejor manera de hablar con usted.</P2>

                        <P2><u><i>Datos de prueba</i></u></P2>
                        <P2>Si se registra para el Examen de Inglés NuParla, también puede
                            proporcionarnos su género, idioma nativo, escuela secundaria y puntajes
                            de otros exámenes que usted divulgue voluntariamente. Cada vez que
                            realice una prueba, también debe proporcionar una foto de una licencia de
                            conducir válida, pasaporte u otra identificación emitida por su gobierno y
                            una foto de su cara para verificar su identidad ("Identificación de prueba").</P2>

                        <P2><u><i>Investigación y desarrollo de productos</i></u></P2>
                        <P2>Podemos contactarlo para participar en actividades de investigación de
                            productos. Estos pueden incluir encuestas, entrevistas y otros tipos de
                            sesiones de retroalimentación. Cuando participe en estas actividades de
                            investigación, la información que proporcione se utilizará para evaluar,
                            mejorar y desarrollar nuestros productos y servicios. Grabaremos las
                            transcripciones de video, audio y texto de estos comentarios junto con 
                            cualquier información de contacto adicional que proporcione y
                            conservaremos estos datos durante tres (3) años.</P2>
                        
                        <P2>Por favor contáctenos en info@nuparla.com para:<br/>
                            • Solicitar más información sobre las actividades de investigación
                            para las que se ha ofrecido.<br/>
                            • Optar por no ser contactado para actividades de investigación.<br/>
                            • Solicitar una copia de cualquier información relacionada con la
                            investigación que nos haya proporcionado.</P2>
                            

                        <h3>3. Cookies</h3>
                        <P2>Cuando ingresa al Servicio, recopilamos su tipo de navegador y su dirección IP
                            (una dirección única que identifica su computadora en Internet). Cuando utiliza
                            nuestra aplicación móvil, recopilamos su tipo de dispositivo, su ID de dispositivo
                            y su dirección IP.<br/><br/>
                            Además, almacenamos cierta información de su navegador usando "cookies".
                            Un cookie es un dato almacenado en la computadora del usuario vinculado a
                            la información sobre el usuario. Utilizamos cookies de identificación de sesión
                            para confirmar que los usuarios han iniciado sesión. Si no desea que se recopile
                            información mediante el uso de cookies, existe un procedimiento simple en la
                            mayoría de los navegadores que le permite negar o aceptar la función de
                            cookies; sin embargo, debe tener en cuenta que las cookies pueden ser
                            necesarias para proporcionarle ciertas funciones disponibles en el sitio web (por
                            ejemplo, entrega personalizada de información).<br/><br/>
                            Puede encontrar más información sobre las cookies y cómo administrarlas en
                            http://www.allaboutcookies.org.</P2>


                        <h3>4. Información obtenida por terceros</h3>
                        <P2>Para respaldar y mejorar el Servicio, trabajamos con una variedad de redes de
                            publicidad de terceros, proveedores de servicios de análisis de marketing y
                            empresas de análisis de sitios web, como Google, Facebook, Oath, Unity y
                            otras (colectivamente, "Proveedores de servicios de publicidad de terceros").
                            Estos proveedores de servicios de publicidad de terceros recopilan y utilizan
                            datos personales sobre sus visitas y uso del sitio web y la aplicación móvil, así
                            como otros sitios web con el fin de ofrecerle anuncios y contenido adaptado
                            para satisfacer sus preferencias e intereses o comprender mejor la eficacia del 
                            anuncio. Los datos demográficos y de interés agregados también se utilizarán
                            para fines de investigación de mercado.<br/><br/>
                            Utilizamos Google Analytics, que es una herramienta de análisis web que nos
                            ayuda a comprender cómo los usuarios interactúan con el sitio web. Al igual
                            que muchos servicios, Google Analytics utiliza cookies propias para rastrear las
                            interacciones de los usuarios, como en nuestro caso, donde se utilizan para
                            recopilar información sobre cómo los usuarios usan nuestro sitio. Esta
                            información se utiliza para compilar informes y para ayudarnos a mejorar
                            nuestro sitio web. Los informes revelan las tendencias del sitio web sin
                            identificar a los visitantes individuales. Puede optar por no participar en Google
                            Analytics sin afectar la forma en que visita nuestro sitio; para obtener más
                            información sobre cómo dejar de ser rastreado por Google Analytics en todos
                            los sitios web que utiliza, visite esta página de Google:
                            https://tools.google.com/dlpage/ Gaoptout.<br/><br/>
                            Puede optar por no recibir anuncios personalizados de anunciantes y redes
                            publicitarias que son miembros de Network Advertising Initiative ("NAI") o que
                            siguen los Principios de autorregulación DAA para la publicidad conductual en
                            línea utilizando sus respectivas herramientas de exclusión voluntaria. La
                            herramienta de exclusión del NAI se puede encontrar aquí
                            http://www.networkadvertising.org/choices/ y la herramienta de exclusión del
                            DAA se puede encontrar aquí http://www.aboutads.info/choices/.<br/><br/>
                            Además, sus dispositivos móviles pueden ofrecer configuraciones que le
                            permitan tomar decisiones sobre la recopilación, el uso o la transferencia de
                            información de la aplicación móvil para la publicidad conductual en línea (por
                            ejemplo, la ID de publicidad de Apple iOS y la ID de publicidad de Google
                            Android). Tenga en cuenta que la exclusión voluntaria no impide que se le
                            muestren todos los anuncios.</P2>


                        <h3>5. Uso de información obtenida por NuParla</h3>
                        <P2>NuParla ocasionalmente puede enviarle anuncios relacionados con el servicio
                            y cambios de productos a través de la operación general del Servicio. Le
                            enviaremos notificaciones recordatorias para apoyar la metodología de
                            enseñanza de inglés de NuParla Subconsciente, reforzando la cadencia de
                            aprendizaje y ayudando a mantener su racha de aprendizaje. Procesamos sus
                            datos para ayudarlo a proporcionarle el Servicio y ofrecer funciones
                            personalizadas, para comprender y mejorar nuestro Servicio y para mantener
                            nuestro Servicio seguro y protegido.<br/><br/>
                            NuParla puede usar o compartir datos anónimos recopilados a través del
                            Servicio, incluidos los Datos de actividad sin limitación. Como empresa, es
                            fundamental que cumplamos nuestro contrato con usted con el mejor servicio
                            posible, y es de nuestro interés legítimo realizar estas funciones de
                            procesamiento y habilitar los correos electrónicos de servicio de forma
                            predeterminada para mantener sus datos seguros y proporcionar nuestro
                            Servicio . Puede optar por no recibir ningún correo electrónico de servicio no
                            esencial en cualquier momento<br/><br/>
                            También podemos utilizar su información de contacto para enviarle
                            notificaciones con respecto a nuevos servicios, ofertas y promociones ofrecidas
                            por NuParla si acepta de manera afirmativa recibir tales comunicaciones.<br/><br/>
                            NuParla utiliza la información de perfil para que usted la presente y edite cuando
                            acceda al Servicio y para que se la presente a otros usuarios. NuParla utiliza
                            esta información para brindarle soporte, enviarle notificaciones esenciales,
                            hacer cumplir nuestros términos, condiciones y políticas, comunicarse con
                            usted, administrar los Servicios y para operaciones internas, incluida la
                            resolución de problemas, análisis de datos, pruebas, investigación, fines
                            estadísticos y de encuestas. Los Términos y Condiciones de NuParla
                            proporcionan la base legal contractual para estas actividades de
                            procesamiento.<br/><br/>
                            <u><i>Prueba del inglés de NuParla</i></u><br/>
                            Cuando realice una prueba en la Prueba de inglés de NuParla, utilizaremos la
                            cámara y el micrófono de su computadora durante toda la prueba para recopilar
                            un Video de prueba.<br/><br/>
                            Procesaremos información en relación con el Examen de Inglés NuParla que
                            incluya nombre (s), apellido (s), fecha de nacimiento, género, idioma nativo,
                            país, escuela secundaria, puntajes de los exámenes auto-informados de otros
                            exámenes, identificaciones de solicitud, un imagen o escaneo de una licencia
                            de conducir válida, pasaporte u otra identificación emitida por el gobierno, su
                            identificación de prueba, video de prueba y respuestas a preguntas como parte
                            del proceso de prueba y evaluación para:<br/>
                            • Proporcionar resultados de pruebas precisos a los destinatarios de la
                            puntuación (como se define a continuación)<br/>
                            • Comparar su identificación de prueba con su video de prueba para
                            asegurarse de que la persona que se registró para el examen (y cuya
                            identidad ha validado NuParla) es la misma persona que realizó el examen<br/>
                            • Para almacenar y revisar la grabación del Video de prueba para validar
                            que realizó el examen sin asistencia inadecuada<br/>
                            • Evaluar su desempeño en el Examen de Inglés NuParla, informar los
                            resultados y evaluar la utilidad, precisión y otros aspectos del Examen de
                            Inglés NuParla<br/>
                            • Para que nuestro equipo de investigación valide y mejore el examen, en
                            un formato anónimo<br/>
                            • Analizar grabaciones de audio de respuestas verbales para mejorar
                            nuestra capacidad de entender el lenguaje hablado.<br/>
                            • Para compartir su ID de prueba con su consentimiento al inicio de una
                            disputa por un Destinatario de puntaje<br/>
                            Los Términos y Condiciones de NuParla proporcionan la base legal contractual
                            para estas actividades de procesamiento de pruebas de inglés.</P2>


                        <h3>6. Compartiendo sus datos personales con terceros</h3>
                        <P2>NuParla comparte sus datos personales solo cuando sea necesario ofrecer el
                            Servicio, sea legalmente requerido o sea permitido por usted.<br/><br/>
                            Proporcionaremos datos personales a proveedores de alojamiento como
                            Amazon Web Services, proveedores de motores de búsqueda como Google,
                            proveedores de análisis como Crashlytics y proveedores de soporte como
                            ZenDesk.<br/><br/>
                            Estos procesadores de datos nos ayudan a brindarle el Servicio. Por ejemplo,
                            podemos compartir su información para detectar dónde o cómo encontró un
                            error al usar nuestra aplicación móvil. En relación con estas operaciones,
                            nuestros proveedores de servicios tendrán acceso a datos personales por un
                            tiempo limitado. Cuando utilizamos proveedores de servicios para procesar
                            datos personales, implementamos protecciones contractuales que limitan el
                            uso de esos datos personales a la prestación de servicios a NuParla.<br/><br/>
                            Se nos pedirá que accedamos y divulguemos datos personales en respuesta a
                            solicitudes legales, como citaciones u órdenes judiciales, o de conformidad con
                            las leyes aplicables. Además, accederemos y compartiremos la cuenta u otros
                            datos personales cuando creamos que es necesario cumplir con la ley, proteger
                            nuestros intereses o propiedad, evitar fraudes u otras actividades ilegales
                            perpetradas a través del Servicio o utilizando el nombre de NuParla, o para 
                            prevenir Daño inminente. Esto incluirá el acceso y el intercambio de datos
                            personales con otras empresas, abogados, agentes o agencias
                            gubernamentales si fuere requerido.<br/><br/>
                            NuParla compartirá los resultados completos y precisos de las Pruebas de
                            inglés de NuParla con dichas instituciones, incluidas universidades, posibles
                            empleadores u otros terceros (colectivamente, "Destinatarios de puntajes")
                            según lo especificado cuando envía sus resultados después de terminar una
                            prueba. Estos resultados incluirán su puntaje de la prueba, un video de la
                            sección de entrevista de su prueba y sus respuestas escritas a ciertas partes
                            de la prueba. También compartiremos su nombre completo, fecha de
                            nacimiento, dirección de correo electrónico y la foto que tome de usted mismo.
                            Nunca compartiremos los resultados de los exámenes con ninguna de las
                            partes sin su aprobación, excepto que NuParla y sus socios utilizarán los
                            resultados de los exámenes anónimos para mejorar el examen y para la
                            investigación y el análisis. No compartiremos su identificación de prueba con
                            ningún destinatario de puntaje, excepto con su consentimiento al inicio de una
                            disputa por parte de un destinatario de puntaje.<br/><br/>
                            Si la propiedad de todo o sustancialmente todo el negocio de NuParla, o
                            unidades de negocios individuales o activos propiedad de NuParla que están
                            relacionados con el Servicio, cambiaran, sus datos personales se transferirán
                            al nuevo propietario. En dicha transferencia de información, sus datos
                            personales permanecerán sujetos a esta sección.<br/><br/>
                            NuParla compartirá datos agregados o anónimos recopilados a través del
                            Servicio, incluidos los Datos de actividad, con fines tales como comprender o
                            mejorar el servicio.</P2>

                        <h3>7. Derecho del sujeto de datos y retención de datos</h3>
                        <P2>Puede administrar la configuración de su cuenta desde su panel de control para
                            actualizar, modificar y corregir su información.<br/><br/>
                            También tiene los siguientes derechos en relación con los datos personales que
                            tenemos sobre usted, a menos que la ley local disponga lo contrario:<br/>
                            • Para solicitar acceso o eliminación de los datos personales que tenemos
                            sobre usted.<br/>
                            • Solicitarnos que restrinjamos el procesamiento de los datos personales
                            que tenemos sobre usted<br/>
                            • Objetarnos el procesamiento de datos personales relacionados con usted<br/>
                            • Cuando nos haya dado su consentimiento para procesar sus datos
                            personales, tiene derecho a retirar ese consentimiento en cualquier
                            momento<br/>
                            • Para exportar los datos personales que ha proporcionado a NuParla en un
                            formato que puede transferirse electrónicamente a un tercero<br/>
                            • Para eliminar su cuenta con NuParla siguiendo las instrucciones
                            disponibles a través del Servicio<br/><br/>
                            NuParla retendrá sus datos hasta que se elimine su cuenta, después de lo cual
                            retendremos los datos anónimos recopilados a través del Servicio, incluidos los
                            Datos de actividad, que NuParla puede usar y compartir con terceros de
                            cualquier manera. La información asociada con la prueba de inglés de NuParla,
                            incluidos los resultados del examen y su video de prueba, se eliminará
                            colectivamente de su cuenta de NuParla después de que hayan transcurrido
                            tres (3) años desde la fecha en que cargó su prueba, pero los datos de examen
                            anónimos, incluidos los resultados de su examen y el Video de prueba será
                            mantenido indefinidamente por NuParla para mejorar el examen y para la
                            investigación y análisis.<br/><br/>
                            Tenga en cuenta que algunos de estos derechos no son absolutos. En algunos
                            casos, podemos rechazar una solicitud para ejercer derechos particulares si
                            cumplir con ella significa que ya no podemos cumplir con nuestra obligación
                            contractual de proporcionarle productos y servicios particulares. Le
                            mantendremos informado sobre las acciones que podemos tomar cuando
                            realice su solicitud.</P2>

                        <h3>8. Sitios web y enlaces de terceros</h3>
                        <P2>Tenga en cuenta que los sitios web de terceros pueden colocar cookies en su
                            computadora que lo remiten a nuestro Servicio. Aunque no compartimos sus
                            datos personales con estos sitios web de terceros, a menos que sea
                            razonablemente necesario para ofrecer el Servicio, es posible que puedan
                            vincular cierta información de identificación no personal que les transferimos
                            con datos personales que previamente recopilaron de usted. Revise las
                            políticas de privacidad de cada sitio web que visite para comprender mejor sus
                            prácticas de privacidad. Además, NuParla desea informarle que cada vez que
                            haga clic en enlaces (incluidos banners publicitarios), que lo llevarán a sitios
                            web de terceros, estará sujeto a las políticas de privacidad de terceros.<br/><br/>
                            Nuestros servicios pueden contener enlaces a otros sitios operados por
                            terceros. NuParla no controla dichos otros sitios y no es responsable de su
                            contenido, sus políticas de privacidad o su uso de datos personales, incluida
                            cualquier información personal o financiera recopilada por nuestro procesador
                            de pagos externos para procesar pagos para compras en alguna aplicación
                            nuestra. La inclusión de dichos enlaces por parte de NuParla no implica ninguna
                            aprobación del contenido de dichos sitios o de sus propietarios u operadores,
                            excepto según lo revelado en los servicios. Cualquier información enviada por
                            usted directamente a estos terceros está sujeta a la política de privacidad de
                            ese tercero.</P2>

                        <h3>9. Derechos de privacidad de California</h3>
                        <P2>Los residentes de California tienen derecho a:<br/>
                            • Saber qué información personal recopilamos sobre ellos y de dónde la recopilamos.<br/>
                            • Acceder a una copia de la información personal que tenemos sobre ellos.<br/>
                            • Comprender cómo procesamos su información personal y cómo se puede
                            compartir.<br/>
                            • Optar por no compartir su información personal con ciertos terceros.<br/>
                            • Solicitar que eliminemos su cuenta NuParla e información personal.<br/><br/>
                            Para realizar dicha solicitud, envíe un correo electrónico a legal@nuparla.com.</P2>

                        <h3>10. Niños menores de edad para consentimiento digital</h3>
                        <P2>El Servicio es solo para adultos. En todo momento, NuParla no recopila
                            deliberadamente información personal de niños en relación con el Servicio. Si
                            descubrimos que sin saberlo hemos recopilado información personal de estos
                            niños, la eliminaremos. Si cree que hemos recopilado información personal de
                            un niño, comuníquese con nosotros a legal@nuparla.com.</P2>


                        <h3>11. Enlaces</h3>
                        <P2>El Servicio puede contener enlaces a otros sitios web. No somos responsables
                            de las prácticas de privacidad de otros sitios web. Animamos a los usuarios a
                            que estén atentos cuando abandonan el Servicio para leer las declaraciones de
                            privacidad de otros sitios web que recopilan información de identificación
                            personal. Esta Política de privacidad se aplica solo a la información recopilada
                            por NuParla a través del Servicio.</P2>

                        <h3>12. Seguridad de información</h3>
                        <P2>NuParla ha implementado salvaguardas administrativas y técnicas que
                            considera apropiadas para proteger la confidencialidad, integridad y
                            disponibilidad de sus datos personales, identificación de prueba, foto de
                            usuario, credenciales de acceso y resultados de la prueba de inglés de NuParla.
                            Sin embargo, dados los recursos suficientes, un atacante determinado podría
                            vencer esas salvaguardas y, como resultado, podría obtener acceso a los datos
                            que buscamos proteger.</P2>


                            <P style={{textAlign: 'center'}}>ÚLITMA ECHA DE REVISIÓN: 5 de junio, 2020</P>


                </div>}
























                {this.state.lang == 'spanish' && <div style={{width: '90%', margin: 'auto'}}>
                    







                </div>}
                



            </div>
        )
    }
}



const P = styled.p `
    font-size: medium;
    color: #888;
    text-align: justify;
    
`

const P2 = styled.p `
    font-size: medium;
    color: #888;
    text-align: justify;
    margin-left: 20px;
    
`

const H1 = styled.p `
    font-family: Arial, Helvetica, sans-serif;
    margin: 60px;
    font-size: xx-large;
    font-weight: bold;
`