import React, { Component } from "react";
import "../../../App.css";
import styled from "styled-components";
import { fire } from "../../../config";
import storage from "../../../config";
import "../../../config";
import * as firebase from "firebase";
import ReactPlayer from "react-player";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { Input } from "antd";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TextField } from "@material-ui/core";
import Dropzone from 'react-dropzone'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ReactAudioPlayer from 'react-audio-player';

export default class level1 extends Component {
    constructor(){
        super()
        this.state = {
            videoLink: '',
            video: ''

        }

    }

    componentDidMount(){
        firebase.firestore().collection('Training').doc('introduction').onSnapshot(res => {
            this.setState({video: res.data().url})
            console.log(res.data())
        
        })
    }

    uploadIntroVideo(){
        firebase.firestore().collection('Training').doc('introduction').set({
            type: 'video',
            url: this.state.videoLink,
            date: Date.now()
        
        }).then(res => {
            console.log(res)
            this.setState({file: null, videoLink: ''})
        })
    }

    render(){
        return (
            <div>
                <div style={{width: 400, margin: 'auto'}}>
                    <h1>Intro Video</h1>
                        <TextField fullWidth variant="outlined" margin="dense" placeholder="add intro video link here..." onChange={(e)=> this.setState({videoLink: e.target.value})} />

                       
                        
                                                
                        <Button variant="contained" color="primary"  onClick={()=> {
                            if (this.state.videoLink == ''){
                                alert('Please add a video link')
                            } else {
                                this.uploadIntroVideo()
                            }
                        }}>
                        Upload Video
                        </Button>

                        <ReactPlayer
                        
                          style={{ margin: "10px auto 10px auto", border: '1px solid #bebebe' }}
                          height="240px"
                          width="426px"
                          controls={true}
                          playing={false}
                          
                          url={this.state.video}
                        />

                    </div>

            </div>
        )
    }

}