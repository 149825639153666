import React, { Component } from "react";
import "../../../App.css";
import styled from "styled-components";
import { fire } from "../../../config";
import storage from "../../../config";
import "../../../config";
import * as firebase from "firebase";
import ReactPlayer from "react-player";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { Input } from "antd";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TextField } from "@material-ui/core";
import Dropzone from 'react-dropzone'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ReactAudioPlayer from 'react-audio-player';
import MicIcon from '@material-ui/icons/Mic';
import StopIcon from '@material-ui/icons/Stop';
import SendIcon from '@material-ui/icons/Send';
import MicRecorder from 'mic-recorder-to-mp3';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });


export default class level1 extends Component {
    constructor(){
        super()
        this.state = {
            videoLink: '',
            video: '',
            recording: false,
            audio: '',
            isRecording: false,
            blobURL: '',
            recorded: false

        }

    }

    componentDidMount(){
        firebase.firestore().collection('Training').doc('introduction').onSnapshot(res => {
            this.setState({video: res.data().url})
           
        
        })
        firebase.firestore().collection('WhyAudios').doc(localStorage.getItem('email')).onSnapshot(res => {
        
            if (res.data() == undefined){

            } else {
                this.setState({audio: res.data().whyAudio, recorded: true})
              
            }
        
        })
        
    }

    start = () => {
        this.setState({recording: true})
        
          Mp3Recorder
            .start()
            .then(() => {
              this.setState({ isRecording: true });
            }).catch((e) => console.error(e));
        
      };
    
      stop = () => {
        this.setState({recording: false})
        Mp3Recorder
          .stop()
          .getMp3()
          .then(([buffer, blob]) => {
            const blobURL = URL.createObjectURL(blob)
            console.log(blob)
            this.setState({ blobURL, blob: blob, isRecording: false, recorded: true });
          }).catch((e) => console.log(e));
      };
    
      send = () => {
        let upload = storage
            .ref(this.state.blob.size.toString())
            .put(this.state.blob);
          upload.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
              console.log(error);
            },
            () => {
              storage
                .ref(this.state.blob.size.toString())
                .getDownloadURL()
                .then(async (url) => {
                  firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
                    intro: true,
                    learningProgress: {
                      level: 1,
                      lesson: 1,
                      step: 1
                    }
                }).then(res => {
                
                      localStorage.setItem('level', 1)
                  })
    
                  firebase.firestore().collection('WhyAudios').doc(localStorage.getItem('email')).set({
                    whyAudio: url,
                    date: Date.now(),
                    user: localStorage.getItem('email')
                }).then(res => {
                      
                  })
    
                
            })})
      }

    uploadIntroVideo(){
        firebase.firestore().collection('Training').doc('introduction').set({
            type: 'video',
            url: this.state.videoLink,
            date: Date.now()
        
        }).then(res => {
            
            this.setState({file: null, videoLink: ''})
        })
    }

    render(){
        return (
            <div style={{paddingBottom: 20, backgroundColor: '#606060'}}>
                <div style={{width: 400, margin: 'auto'}}>
                    <h2 style={{fontFamily: 'cursive', color: '#edd290'}}>Intro Video</h2>
                        

                        <ReactPlayer
                        
                          style={{ margin: "10px auto 10px auto", border: '1px solid #bebebe' }}
                          height="240px"
                          width="426px"
                          controls={true}
                          playing={false}
                          
                          url="https://vimeo.com/454558836"
                        />

                    </div>

                    <Paper style={{width: 380, padding: 10, margin: '20px auto'}}>

                    {this.state.audio == '' && <div style={{textAlign: 'center'}}>
                    <h2>Tell us your <b>WHY?</b></h2>
                    <p>Why do you want to learn english?</p>
                    <p>Record a 30 second audio below.</p>
                    {this.state.recorded && <div style={{width: '100%'}}>
                      <audio src={this.state.blobURL} style={{padding: 10,  }} controls="controls" />
                      </div>}
                    <Button variant="contained" style={{backgroundColor: this.state.recording ? 'red' : 'green', color: 'white',margin: 5, width: 100 }}>
                    {!this.state.recording && <MicIcon onClick={this.start} />}
                    {this.state.recording && <StopIcon onClick={this.stop}/>}

                    </Button>
                    
                    <Button variant="contained" style={{cursor: 'pointer', width: 100, margin: 5, backgroundColor: this.state.recrded ? 'blue' : ''}} disabled={this.state.isRecording}>
                      Send
                     
                    <SendIcon onClick={this.send} />
                
                    </Button>
                    
                    
                    
                    {this.state.recording && <h3>Recording...</h3>}
                

                    </div>}
                    {this.state.audio != '' && <div style={{textAlign: 'center'}}>
                        <p>Your Why Audio:</p>
                        <div>
                        <audio src={this.state.audio} style={{padding: 10 }} controls="controls" />

                        </div>
                     
                        {/* <Button variant="contained" color="primary" onClick={() => this.setState({audio: ''})}>Record Again</Button> */}
                        </div>}

                    </Paper>

                   
                
                   

            </div>
        )
    }

}