import React, { Component } from 'react';
import { Button } from "@material-ui/core";




export default class Congratulations extends Component {

    render (){
        return (
            <div style={{padding: 20, backgroundColor: '#bebebe'}}>
                
                   <div style={{textAlign: 'center'}}>
                    <h2>Congratulations</h2>
                    <h3>You are now an affiliate!</h3>
                    <h2 style={{fontSize: 'x-large', color: 'darkblue', fontWeight: 'bold'}}>Understanding NuParla Affiliate Commissions and OTPs</h2>
                            <p>How to get paid as an Affiliate with NuParla</p>

                            <div style={{textAlign: 'left'}}>
                                <p>So you want to be a NuParla Affiliate because you love how we help people like yourself to speak English fluently all around the world. You appreciate our platform and are committed to uplift our values of Integrity, Quality and Productivity.</p>
                                <p>In short, you love NuParla. We provide a commission as a way of saying “Thanks” to you for all you do. We want to partner with you to grow your NuParla team. </p>
                                <p>Here is everything you need to know about earning commissions, and receiving that sweet payout.</p>
                                <h2>Step #1. Set Up PayPal</h2>
                                <p>As a NuParla Affiliate in the US, Latin America or Spain, you can only receive payouts through PayPal. It’s very easy to do. Just follow the these steps:</p>
                                <ol>
                                    <li>Visit PayPal’s website and click the Sign Up button in the top-right corner.</li>
                                    <li>Select the account type you want — Personal or Business — and click Continue. (Most people sign up with a Personal Account, unless you are a business). </li>
                                    <li>Add in your full name, email address, create a password, and after confirming the password, then click Continue.</li>
                                    <li>Add in your address and other required info, and then click Continue.</li>
                                    <li>Optional: Click the blue Get started button, add in your credit card info, and click Add card. Notice, this is optional. </li>
                                    <li>Verify your email address by clicking the link in the email sent to you by PayPal.</li>
                                </ol>
                                <p>Once you’ve done this, you’re all set! Get ready for those direct deposits!</p>

                                <h2>Step #2: Understand Orders-To-Pay (OTPs)</h2>
                                <p>NuParla offers a 7-day refund starting from the day of purchase to all customers. During this time, you will see your customer inside your Holding Tank, next to a time countdown. Once they refund period is up, your customer’s status is now OTP, meaning that the commission due to you will be officially approved. </p>
                                <p>If, however, your customer cancels during the 7-day refund period, their status will be updated inside your holding tank as cancelled, they will receive their refund and you will receive no commission. </p>
                                <p>Affiliate pending commissions are approved weekly on Wednesdays for all the referral work you did for the previous seven days (Wednesday to Wednesday). We send the report and funds to PayPal so ensure it hits your PayPal account on Fridays. It’s that simple. </p>
                                <p>So, for example, if you recruit a paying customer on Thursday December 1, their 7-day refund period will expire Thursday December 8. If they did not cancel, then on Friday December 9 they become OTP approved. They will be on the report that goes to PayPal the following Wednesday, December 13, and you receive your commission on Friday, December 15. </p>
                                <p>There is no commission threshold. You earn what you worked. </p>
                                <p>If you are ever denied commissions, it’s because:</p>
                                <ol>
                                    <li>The customer canceled their account during the 7-day refund period; or</li>
                                    <li>We made a mistake. If this happens, click on Tickets inside your Back Office and send us a message. We’ll gladly correct the payment status to OTP approved. </li>
                                </ol>
                                <p>If in an extreme case a customer cancels after their 7-day refund period (after we paid you a commission) and we decide to honor that cancelation, the commissions we paid you for that special-case cancellation will be deducted from any future commissions owed you. We will contact you in this extreme case so you become aware. Like you, we don’t like any surprises. Communication is key.</p>
                                <h2>Step #3: Build Your NuParla Team!</h2>
                                <ol>
                                    <li>Be a product of the product. As a NuParla Student, your speaking English fluently is vital, because YOU are the product. It’s your time to shine!</li>
                                    <li>Harness the power of your unique link. Do 3 clicks: Invite – WhatsApp (for example) – select Prospect. Your success depends on how many 3-click invitations you do per hour, every day! </li>
                                    <li>The NuParla.com website has the videos, testimonials and spells out everything for your Prospect. You just smile, and let them hear you tell them your story in English. It’s that easy!</li>
                                    <li>Follow-up is KEY! The fortune is not made in invitation, as much as in the follow-up. Make sure to take the NuParla Sales Course inside your Training folder for more information.</li>
                                    <li>Be active in social media. Use our NuParla marketing posts for the different holidays and special thoughts as presented by NuParla. Post, post, post!</li>
                                    <li>Join the weekly Facebook “Live” training events. </li>
                                    <li>Repeat steps 1 through 6 and watch your team grow. (Yes, it’s all about your team!) </li>

                                </ol>
                                <p>Si acabas de comenzar con nosotros y quieres aprender el inglés más rápido, te recomiendo que instales el plugin de Reverso en tu navegador (Chrome es el más fácil) y subraya las palabras or frases en estas instrucciones para crecer en tu descubrimiento del inglés.</p>
                                <p>We hope this inspires you on to greatness.</p>
                                <p>-Harold Zapata, Founder & CEO</p>
                            </div>
               


            </div>
            </div>
        )
    }
}