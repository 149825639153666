import React, { Component } from "react";
import  '../../../App.css'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import { TextField, Button } from "@material-ui/core";
import * as firebase from "firebase";
import "../../../config" ;
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';


export default class affiliates extends Component {
    constructor(){
        super();
        this.state = {
            users: []
           

        }
    }

    componentDidMount(){
        firebase.firestore().collection('Users').get().then(res => {
            console.log(res.docs)
            this.setState({users: res.docs})
            
        })
       

    }

    earnings(user){
        let earnings = 0;
        if (user.first){
            let list = user.first.concat(user.second, user.third, user.fourth, user.fifth)
        list.forEach(user => {
            earnings = earnings + user.commission;
        })

        if (user.rankAdvanceBonus){
            user.rankAdvanceBonus.forEach(bonus => {
                earnings = earnings + bonus.bonus;
            })
        }
        return earnings;

        }
        
    }


    render(){ 

        return (
            <div>
                <h1>Affiliates</h1>
                <TableContainer component={Paper} style={{width: '90%', margin: '10px auto'}}>
                    <Table >
                        <TableHead>
                        <TableRow style={{backgroundColor: '#add8e6'}}>
                            <TableCell style={{fontWeight: 'bold'}}>Name</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} align="right">Rank</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} align="right">referrals</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} align="right">Earnings</TableCell>
                            
                            
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.state.users.map((user) => (
                            <TableRow style={{cursor: 'pointer',backgroundColor: '#ffffe0'}} onClick={()=>{
                               
                            }}>
                            <TableCell component="th" scope="row">
                                {user.data().name}
                            </TableCell>
                            <TableCell align="right">{user.data().rank}</TableCell>
                            <TableCell align="right">{user.data().first && user.data().first.length}</TableCell>
                            <TableCell align="right">{this.earnings(user.data())}</TableCell>
                          
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>


            </div>
        )

    }
} 