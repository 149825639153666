import React, {Component} from 'react';
import '../../App.css';
import styled from 'styled-components';
import "../../config";
import * as firebase from "firebase";
import refresh from '../../Images/refresh.png';
import logo from '../../Images/nuparla em1 .1.png'
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import ConvertKit from "convertkit-react";
import ConvertKitComponent from './convertKit'
import ScriptTag from 'react-script-tag';
import ReactPlayer from "react-player";


export default class free extends Component {
    constructor(){
        super();
        this.state = {
            lessons: [],
            selected: null,
            dialog: false,
            dialog2: false,
            dialog3: false,
            name: '',
            email: '',

            videos: [
                "https://youtu.be/_jVFcMd9ZAc",
                "https://youtu.be/7C28f64SjHg",
                "https://youtu.be/BGZ_n7DlaqI",
                "https://youtu.be/OIVyJ1kvtbk",
                "https://youtu.be/OwfW8x3CwC4",
                "https://youtu.be/P3SI7i_OOIs",
                "https://youtu.be/JCez9VvFANw",
                "https://youtu.be/-5zjvaodf4o",
                "https://youtu.be/Xcx03OSEvog",
                "https://youtu.be/9diXzl9Dffk",
                "https://youtu.be/4dfrVVruPvs",
                "https://youtu.be/yHaewDHzVRM",
                "https://youtu.be/Emnr7gBS9ZY",
                "https://youtu.be/Uesbq2nUlTA",
                "https://youtu.be/ByWavZUw2XI",
                "https://youtu.be/3a6NHCB2UPU",
                "https://youtu.be/YfoC-JgwTb0",
                "https://youtu.be/AmWHEhYyjrU",
                "https://youtu.be/9SL7_8ge0jI",
                "https://youtu.be/D7oXz2QLhEY",
                "https://youtu.be/ReQVn5NZHQ4",
                "https://youtu.be/ZV39pJkFg2k",

                "https://youtu.be/t7zYP-h2DQI",
                "https://youtu.be/WikGq284h98",
                "https://youtu.be/02f5JVDV1I8",
                "https://youtu.be/eOQlDzzqLRM",
                "https://youtu.be/sbKAFijJIxE",
                "https://youtu.be/wYd0s2ZU1aY",
                "https://youtu.be/SigX7581LYI",
                "https://youtu.be/EDbkOD04qwM",
                "https://youtu.be/9eakzYaOxeg",
                "https://youtu.be/4SXPU66IlLE",
                "https://youtu.be/--9Ff0mNEck",
                "https://youtu.be/WpC1ffU0_eY",
                "https://youtu.be/PvHWCocB1XI",
                "https://youtu.be/3IvxCmQMVoA",
                "https://youtu.be/-Y99jvqogcs",
                "https://youtu.be/3iiDkghwmc0",

                "https://youtu.be/cq4ItWZHvYM",
                "https://youtu.be/PAOJQO_lrHY",
                "https://youtu.be/KL8fYWk1ug8",
                "https://youtu.be/G4viqZ7zzMs",
                "https://youtu.be/2-ZD0t5bIyQ",
                "https://youtu.be/X0Ysj2v_Tfk",
                "https://youtu.be/kWdxqjXLYhE",
                "https://youtu.be/jAxQT5dBVpw",
                "https://youtu.be/fOc3_JVzYu4",
                "https://youtu.be/Xi7Lg79Ya-M",
                "https://youtu.be/YaQ-rgCRFVw",
                "https://youtu.be/FfLjfuJp8WM",
                "https://youtu.be/AGUK_ww1Iac",
                "https://youtu.be/o2U9SK0aZG0",
                "https://youtu.be/JEU8uhiCwRI",
                "https://youtu.be/x0ADb4MSNXM",


                "https://youtu.be/ARp6SwFlig8",
                "https://youtu.be/gjgif_g-kkQ",
                "https://youtu.be/U2dXT_Dd2KU",
                "https://youtu.be/YRg6BaH5Hdk",
                "https://youtu.be/JXhqaAk4FyI",
                "https://youtu.be/IzGAEan0atU",
                "https://youtu.be/RaC0Vs9sEL0",
                "https://youtu.be/ASvysHasdBM",
                "https://youtu.be/m_pMUxunCpM",
                "https://youtu.be/NtBEqdxXyTY",
                "https://youtu.be/DFAiVUTL9jw",
                "https://youtu.be/sPzqFP1auOY",
                "https://youtu.be/jtTX0rlATKY",
                "https://youtu.be/Ql4-59kr9x8",
                "https://youtu.be/h12Mv-DhQic",
                "https://youtu.be/o_LXXlCADW8"


            ]



        }
    }

    subscribe(){
        console.log(this.state.name)
        console.log(this.state.email)
        firebase.firestore().collection('Subscribers').doc(this.state.email).set({
            email: this.state.email,
            name: this.state.name
          }).then(res => {
            
            localStorage.setItem('freeSub', this.state.name)

        })
        this.setState({dialog2: false})
        
    }

    componentDidMount(){
        var lessons = this.state.lessons;
        firebase.firestore().collection('Course').doc('level1').get().then(res => {
  
            lessons.push(...res.data().lessons)

            firebase.firestore().collection('Course').doc('level2').get().then(res => {
  
                lessons.push(...res.data().lessons)
                firebase.firestore().collection('Course').doc('level3').get().then(res => {
  
                    lessons.push(...res.data().lessons)
                    firebase.firestore().collection('Course').doc('level4').get().then(res => {
  
                        lessons.push(...res.data().lessons)
                        this.setState({lessons})
                        console.log(lessons)
                        
                  
                  
                      })
                    
              
              
                  })
                
          
          
              })
            
      
      
          })

          if (localStorage.getItem('freeSub') == null) {
              this.setState({dialog2: true})
          }

          
    }

    render(){
        
        return (
            <div style={{textAlign: 'center', backgroundColor: '#aeaeae'}}>
                <div style={{backgroundColor: '#404040'}}>
                    <img src={logo} style={{width: '40%', padding: 10, margin: 'auto'}} />

                </div>

                <div> <h1 style={{fontSize: 'xx-large', marginTop: 40}}>Introduction</h1> </div>

                <div style={{display: 'flex', justifyContent: 'center'  , width: '100%'}}>
                <div style={{cursor: 'pointer', backgroundColor: 'white', justifyContent: 'center', borderRadius: 10, boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', padding: 10, textAlign: 'center', margin: '10px', width: '300px'}} onClick={() => {
                    this.setState({selected: "https://www.youtube.com/watch?v=4VjpeaUMNKU", dialog3: true, }) 
                    }}>
                    
                        <img src='https://img.youtube.com/vi/4VjpeaUMNKU/0.jpg' style={{width: 250}} />
                        <h3 style={{textAlign: 'center', color: '#009dff'}}>Introducción a la Academia de inglés </h3>                                    
                    
                    

                </div>
                <div style={{cursor: 'pointer', backgroundColor: 'white', justifyContent: 'center', borderRadius: 10, boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', padding: 10,  textAlign: 'center', margin: '10px', width: '300px'}} onClick={() => {
                    this.setState({selected: "https://www.youtube.com/watch?v=INPT2D2gGcI", dialog3: true, }) 
                    }}>
                    
                        <img src='https://img.youtube.com/vi/INPT2D2gGcI/0.jpg' style={{width: 250}} />
                    
                        <h3 style={{textAlign: 'center', color: '#009dff'}}>Los 12 beneficios </h3>                                    
                    
                    

                </div>
                
                </div>

                


                <div style={{width: '80%', margin: 'auto', textAlign: 'center'}}>
                    {this.state.lessons.map((lesson, index) => {
                        return (
                            <div>
                                {index == 0 && <div> <h1 style={{fontSize: 'xx-large', marginTop: 40}}>Level 1</h1> </div>}
                                {index == 29 && <div> <h1 style={{fontSize: 'xx-large', marginTop: 40}}>Level 2</h1> </div>}
                                {index == 48 && <div> <h1 style={{fontSize: 'xx-large', marginTop: 40}}>Level 3</h1> </div>}
                                {index == 68 && <div> <h1 style={{fontSize: 'xx-large', marginTop: 40}}>Level 4</h1> </div>}

                                {index == 0 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#1</span> Questions, Greetings and Introductions</h2>
                  
                </div>}

                {index == 4 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#2</span> Dialogue 1.0</h2>
                  
                </div>}

                {index == 8 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#3</span> Family</h2>
                  
                </div>}

                {index == 13 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#4</span> Restaurant 1.0</h2>
                  
                </div>}

                {index == 17 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#5</span> Foods and Quantities 1.0</h2>
                  
                </div>}

                {index == 21 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#6</span> Shopping and Numbers 1.0</h2>
                  
                </div>}

                {index == 25 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#7</span> Socializing</h2>
                  
                </div>}






                {index == 29 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#1</span> Sports</h2>
                  
                </div>}

                {index == 31 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#2</span> Life Events</h2>
                  
                </div>}

                {index == 35 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#3</span> Work Life</h2>
                  
                </div>}

                {index == 37 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#4</span> Dialogue 2.0</h2>
                  
                </div>}

                {index == 41 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#5</span> Restaurants and food</h2>
                  
                </div>}

                {index == 43 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#6</span> Travel 1.0</h2>
                  
                </div>}

                {index == 45 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#7</span> I feel sick</h2>
                  
                </div>}






                {index == 48 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#1</span> Travel 2.0</h2>
                  
                </div>}

                {index == 50 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#2</span> Dialogue 3.0</h2>
                  
                </div>}

                {index == 53 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#3</span> Health & Fitness</h2>
                  
                </div>}

                {index == 55 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#4</span> Work Life 2.0</h2>
                  
                </div>}

                {index == 58 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#5</span> Politics</h2>
                  
                </div>}

                {index == 61 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#6</span> Foods and Quantities 2.0</h2>
                  
                </div>}

                {index == 64 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#7</span> Religion</h2>
                  
                </div>}



                {index == 68 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#1</span> Dialogue 4.0</h2>
                  
                </div>}

                {index == 70 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#2</span> Nature & Our Planet</h2>
                  
                </div>}

                {index == 73 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#3</span> Feelings & Love</h2>
                  
                </div>}

                {index == 76 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#4</span> Driving Around</h2>
                  
                </div>}

                {index == 80 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#5</span> Discipline</h2>
                  
                </div>}

                {index == 82 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#6</span> Job Interview</h2>
                  
                </div>}

                {index == 85 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#7</span> Business Basics</h2>
                  
                </div>}


















                                <div style={{cursor: 'pointer', backgroundColor: 'white', justifyContent: 'space-around', borderRadius: 10, boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', padding: 10, display: 'flex', textAlign: 'left', margin: 18}} onClick={() => {
                                    
                                    if(lesson.data.title == 'Refresher Course & Grammar'){
                                        
                                        this.setState({selected: lesson.data.video, dialog3: true, })
                                    }else {
                                        this.setState({selected: lesson.data.number - 1, dialog: true, })
                                    }
                                         
                                    }}>
                                    
                                    {lesson.data.title != 'Refresher Course & Grammar' && <img src={lesson.data.avatar} style={{width: 80, height: 80, margin: 'auto'}} />}
                                    {lesson.data.title == 'Refresher Course & Grammar' && <img src={refresh} style={{width: 80, height: 80, margin: 'auto', marginLeft: '10%', }} />}
                                    {lesson.data.title != 'Refresher Course & Grammar' && <div style={{width: '80%'}}>
                                        {index < 29 && <h3 style={{textAlign: 'left'}}>Lesson #{lesson.data.number}: {lesson.data.title} </h3>}                                    
                                        {index >= 29 && index < 57 && <h3 style={{textAlign: 'left'}}>Lesson #{lesson.data.number}: {lesson.data.title} </h3>}                                    
                                        {index >= 57 && index < 86 && <h3 style={{textAlign: 'left'}}>Lesson #{lesson.data.number}: {lesson.data.title} </h3>}                                    
                                        {index >= 86 && <h3 style={{textAlign: 'left'}}>Lesson #{lesson.data.number}: {lesson.data.title} </h3>}   
                                        <h4>{lesson.data.description}</h4>                                 
                                    </div>}
                                    {lesson.data.title == 'Refresher Course & Grammar' && <div style={{width: '70%'}} >
                                        <h3 style={{textAlign: 'left', color: '#009dff'}}>{lesson.data.title} #{lesson.data.number} </h3>                                    
                                    </div>}
                                    

                                </div>

                            </div>
                            
                        )
                    })

                    }

                </div>




                <Dialog
                    open={this.state.dialog}
                    onClose={() => this.setState({dialog: false})}
                    
                >
                    <div>
                    <ReactPlayer
                          
                        
                          height={window.innerWidth >= 800 ? '360px' : window.innerWidth >= 500 ? '280px' : '200px'}
                          width={window.innerWidth >= 800 ? '580px' :window.innerWidth >= 500 ? '400px' : '360px'}
                          controls={true}
                          playing={true}
                          
                          // url={lesson.data.video}
                          url={ this.state.videos[this.state.selected]}
                          
                        />

                    </div>
                    

                </Dialog>



                <Dialog
                    open={this.state.dialog3}
                    onClose={() => this.setState({dialog3: false})}
                    
                >
                    <div>
                    <ReactPlayer
                          
                        
                          height={window.innerWidth >= 800 ? '360px' : window.innerWidth >= 500 ? '280px' : '200px'}
                          width={window.innerWidth >= 800 ? '580px' :window.innerWidth >= 500 ? '400px' : '360px'}
                          controls={true}
                          playing={true}
                          
                          // url={lesson.data.video}
                          url={this.state.selected}
                          
                        />

                    </div>
                    

                </Dialog>






                <Dialog
                    open={this.state.dialog2}
                    onClose={() => this.setState({dialog2: false})}
                    fullWidth
                >
                    <div style={{height: 550, marginTop: '-160px', position: 'relative', textAlign: 'center', backgroundColor: '#404040'}}>
                        <CancelIcon style={{position: 'absolute', top: 20, right: 20, color: '#ebebeb', cursor: 'pointer'}} onClick={() => this.setState({dialog2: false})} />
                        {/* <img src={logo} style={{width: '60%', margin: '10px auto'}} />

                        <h2 style={{color: '#edd490'}}>¡BIENVENIDOS!</h2> */}
                        {/* <div style={{padding: 20, width: 300, margin: 'auto'}}>
                            <input style={{border: '2px solid #edd490', width: '80%',margin: 6, backgroundColor: '#303030', color: 'white', textDecoration: 'none', outline: 'none'}} placeholder="Nombre" onChange={(e) => {
                                this.setState({name: e.target.value})
                            }} />
                            <input style={{border: '2px solid #edd490',width: '80%', margin: 6, backgroundColor: '#303030', color: 'white', textDecoration: 'none', outline: 'none'}} placeholder="Email" onChange={(e) => {
                                this.setState({email: e.target.value})
                            }} />

                            <button style={{backgroundColor: '#edd490', color: '#404040',margin: 5, border: 'none', outline: 'none', cursor: 'pointer', }} onClick={() => this.subscribe()} >Subscríbete</button>

                        </div> */}

                        <iframe src="https://inglessubconsciente.ck.page/2f757e12aa" width="100%" height="100%"></iframe>
{/* {this.state.dialog2 && <ScriptTag async data-uid="2f757e12aa"  src="https://inglessubconsciente.ck.page/2f757e12aa/index.js"/>} */}
                        


                    </div>
                    

                </Dialog>

            </div>
        )
    }
}


