import React, { Component } from "react";
import  '../../../App.css'
import { TextField, Button } from "@material-ui/core";
import storage from "../../../config";
import * as firebase from "firebase";
import { fire } from "../../../config";
import "../../../config" ;
import styled from "styled-components";

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import InstagramIcon from '@material-ui/icons/Instagram';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';



export default class commissions extends Component {
    constructor(){
        super();
        this.state ={
            
            commissions: [],
            user: {
                first: []
            },
            holdingDialog: false,
            selected: ''
            

        }
    }

    componentDidMount(){
        firebase.firestore().collection('Users').doc(localStorage.getItem('email')).onSnapshot(res => {
           console.log(res.data())
           this.setState({user: res.data()})
        //    let commissions = res.data().first.concat(res.data().second, res.data().third, res.data().fourth, res.data().fifth)
   

            
        //     // commissions.sort((a, b) => a.date.localeCompare(b.date))
        //     commissions.sort(function(a, b){
        //         return a.date > b.date;
        //       });
        //     commissions = commissions.reverse()
        //     console.log(commissions)
        //     commissions.forEach(user => {
        //         firebase.firestore().collection('Users').doc(user.email).get().then(res => {
        //             console.log(res.data())
        //             user.name = res.data().name;
        //             this.setState({commissions: commissions})
        //         })

                
        //     });
        //     let holding = {}
        //     commissions.forEach(user => {
        //         if (user.status == 'pending'){
        //             user.daysLeft = this.daysLeft(user.date)
        //             if (user.daysLeft == 1){
        //                 holding.one.push(user)
        //             }
        //         }
        //     })
        
          
           
          })


    }

    timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year ;
        return time;
      }

    daysLeft(date){
        var today = Date.now()
        var difference = today - date;
        var days = difference/86400000;
        days = 7 - days;
        return Math.round(days);
    }
     
    totalEarnings(){
        let earnings = 0;
        this.state.commissions.forEach(user => {
            earnings = earnings + user.commission;
        })
        if(this.state.user.rankAdvanceBonus){
            this.state.user.rankAdvanceBonus.forEach(bonus => {
                earnings = earnings + bonus.bonus;
            })
        }
        return earnings;
    }

    rankAdvance(){
        let Bonus = 0;
        if (this.state.user.rankAdvanceBonus){
            this.state.user.rankAdvanceBonus.map(bonus => {
                Bonus = Bonus + bonus.bonus;
            })
            return Bonus;
        } else {
            return '0';
        }
    }
    holding(){
        let holding = 0;
        this.state.commissions.map(user => {
            if (user.status == 'pending'){
                holding = holding + user.commission;
            }
        })
        return holding;
    }



    render(){
        return (
            <div>
                
                <h1 style={{fontSize: 'xx-large'}}>Your Commissions</h1>
                {/* <div style={{margin: '20px 10px', padding: 15, borderRadius: 10, backgroundColor: '#ebebeb', display: 'flex', justifyContent: 'space-around'}}>
                    <div>
                        <p><b>Total Earnings:</b> ${this.totalEarnings()}</p>
                        <p><b>Rank Advance Bonus:</b> ${this.rankAdvance()}</p>
                        <p><b>Holding Tank:</b> ${this.holding()}</p>


                    </div>
                    <div>
                        <p><b>Total Referrals:</b> 0</p>
                        <p><b>Next Rank:</b> {this.state.user.rank == 'hero' ? 'instructor' : this.state.user.rank == 'instructor' ? 'teacher' : this.state.user.rank == 'teacher' ? 'professor' : this.state.user.rank == 'professor' ? 'master' : 'hero'}</p>

                        <p><b>Referrals Needed:</b> {this.state.user.rank == 'hero' ? 5 - this.state.user.first.length : this.state.user.rank == 'instructor' ? 21 - this.state.user.first.length : 0 }</p>


                    </div>
                    

                </div> */}
                <Grid>
                <TableContainer component={Paper} style={{width: '90%', margin: '10px auto'}}>
                    <Table >
                        <TableHead>
                        <TableRow style={{backgroundColor: '#add8e6'}}>
                            <TableCell style={{fontWeight: 'bold'}}>Name</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} align="right">Commission</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} align="right">Date</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} align="right">Status</TableCell>
                            
                            
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.state.commissions.map((user) => (
                            <TableRow style={{cursor: 'pointer',backgroundColor: '#ffffe0'}} onClick={()=>{
                               
                            }}>
                            <TableCell component="th" scope="row">
                                {user.name}
                            </TableCell>
                            <TableCell align="right">{"$"+ user.commission}</TableCell>
                            <TableCell align="right">{this.timeConverter(user.date)}</TableCell>
                            <TableCell align="right">{user.status}</TableCell>
                          
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                    <TableContainer component={Paper} style={{width: '90%', margin: '10px auto'}}>
                    <Table >
                        <TableHead>
                        <TableRow style={{backgroundColor: '#add8e6'}}>
                            <TableCell style={{fontWeight: 'bold'}}>Holding Tank</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} align="right">Days left</TableCell>
                            
                            
                            
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.state.commissions.map((user) => {
                            if (user.status == 'pending'){
                                return (

                                    <TableRow style={{cursor: 'pointer',backgroundColor: '#ffffe0'}} onClick={()=>{
                                        this.setState({selected: user, holdingDialog: true})
                               
                                    }}>
                                    <TableCell component="th" scope="row">
                                    {user.name}
                                    </TableCell>
                                  
                                    <TableCell align="right">{this.daysLeft(user.date)}</TableCell>
                                   
                                  
                                    </TableRow>

                                )
                            }
                          
                            
})}
                        </TableBody>
                    </Table>
                    </TableContainer>
                    
                </Grid>

                <Dialog
                open={this.state.holdingDialog}
                fullWidth
                onClose={()=> this.setState({holdingDialog: false})}
                >
                    <h2 style={{textAlign: 'center'}}>Details</h2>
                    <div style={{margin: 10, padding: 10}}>
                        <p><b>Name:</b> {this.state.selected.name}</p>
                        <p><b>Commission:</b> ${this.state.selected.commission}</p>
                        <p><b>Recruited On:</b> {this.timeConverter(this.state.selected.date)}</p>
                        <p><b>Days Left till clearance:</b> {this.daysLeft(this.state.selected.date)}</p>

                    </div>

                </Dialog>
                
                
            </div>
        )
    }
}

const Grid = styled.div `
    display: grid;
    grid-template-columns: 60% 40%;
    margin: 10px;
    padding: 10px;
`