import React, { Component } from "react";
import "../../../App.css";
import styled from "styled-components";
import { fire } from "../../../config";
import storage from "../../../config";
import "../../../config";
import * as firebase from "firebase";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Input } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import logo from '../../../Images/nuparla em1 .1 w.png'
import banner from "../../../Images/banner.jpg";
import Dialog from '@material-ui/core/Dialog';
import { TextField } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import refreshIcon from '../../../Images/refresh.png'
import congrats from '../../../Images/congrats.png'
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactSwipe from 'react-swipe';
import Timer from '../Timer/timer2';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

export default class intermediate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slide: 0,
            slideEnded: false,
            questions: [
                {
                    question: "",
                    options: []
                }
            ],
            correct: [],
            solved: [], 
            done: false,
            testCompleted: false,
            selected: null,
            user: null,
            index: 0,
            loop: [
                1,
                2,
                3
            ]
        }
    }

    componentDidMount() {
        firebase.firestore().collection('Course').doc('Final').get().then(res => {
      
     
          this.setState({questions: res.data().questions})
          
          
    
    
        })
    
        firebase.firestore().collection('Users').doc(localStorage.getItem('email')).onSnapshot(res => {
          this.setState({user: res.data()})
        })
    
    
    }

    evaluate(){
        
        this.setState({testCompleted: true})

        // if (this.state.correct.length >= 80){
        //     firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
        //         learningProgress: {
        //           level: 3,
        //           lesson: 49,
        //           step: 1
        //         }
        //       })

        // }
    }

    next(){
       
        
        setTimeout(
            function() {
                this.setState({done: false, selected: null});
                this.setState({index: this.state.solved.length})
            }
            .bind(this),
            200
        );
    }

    

    render (){
        let reactSwipeEl;
        let slide;
        let name;
        let slideEnded = 1;
        let i = this.state.index;

        if (this.state.testCompleted && this.state.correct.length>= 80){
            return (
                <div>
                    <Dialog
                    open={true}
                    fullScreen>
                        <div style={{width: '100%', height: '100%', backgroundColor: '#edd290', textAlign: 'center',overflow: 'scroll', overflowX: 'hidden', }}>
                            <div>
                                <img style={{width: '100%', height: '100%'}} src={banner} />
                                
                            </div>
                            <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', padding: 15, margin: window.innerWidth >= 400 ? '20px' : '20px 4px', boxShadow: '0px 3px 10px #515151', borderRadius: 5  }}>
                                <img style={{width: '70%', margin: '10px auto'}} src={congrats} />
                                <h1 style={{fontSize: 'large',color: 'white'}}>You have passed your Final Exam with a score of {this.state.correct.length }/100!</h1>
                                
                                

                            
                            
                            
                            </div>

                        </div>
                        
                    </Dialog>
                    

                </div>
            )
        }else if (this.state.testCompleted && this.state.correct.length <= 80) {
            return (
                <div>
                    <Dialog
                    open={true}
                    fullScreen>
                        <div style={{width: '100%', height: '100%', backgroundColor: '#edd290', textAlign: 'center', overflow: 'scroll', overflowX: 'hidden', }}>
                            <div>
                                <img style={{width: '100%', height: '100%'}} src={banner} />
                                
                            </div>
                            <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', padding: 15, margin: window.innerWidth >= 400 ? '20px' : '20px 4px', boxShadow: '0px 3px 10px #515151', borderRadius: 5  }}>
                                <h1 style={{fontSize: 'xx-large',color: 'white'}}>INTERMEDIATE EXAM</h1>
                                <p style={{color: 'white'}}>Your score is less than 80%, please try again.</p>
                                
                                

                            
                            
                            
                            </div>

                        </div>
                        
                    </Dialog>
                    

                </div>
            )

        } else if (!this.state.testCompleted ) {
            return (
                <div style={{backgroundColor: '#edd290'}}>
                    <Dialog
                    open={true}
                    fullScreen>
                        <div style={{width: '100%', height: '100%', backgroundColor: '#edd290', textAlign: 'center', overflow: 'scroll', overflowX: 'hidden', }}>
                            <div>
                                <img style={{width: '100%', height: '100%'}} src={banner} />
                                
                            </div>
                            <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', padding: 15, margin: window.innerWidth >= 400 ? '20px' : '20px 4px', boxShadow: '0px 3px 10px #515151', borderRadius: 5  }}>
                                <h1 style={{fontSize: 'xx-large',color: 'white'}}>INTERMEDIATE EXAM</h1>
                                <p style={{color: 'white'}}>(Multiple Choice Questions)</p>
                                <div style={{margin: 'auto', textAlign: 'center'}}>
                                   
                                <Timer/>
                                </div>
                                

                            
                            <ReactSwipe
                                className="carousel"
                                swipeOptions={{ continuous: true, speed: 400, startSlide: this.state.slide,  callback: () => {
                                    slide =  reactSwipeEl.getPos()
                                    setTimeout(() => this.setState({slide: slide}) , 350)
                                    // this.setState({slide: slide})
                                } }}
                                ref={el => (reactSwipeEl = el)}
                            >
                                
                                    {this.state.loop.map((question, index) => {
                                        return (
                                            <div >
                                                
                                                <div style={{padding: '10px 5px', margin: window.innerWidth >= 400 ? '20px 10vw' : '20px 2vw', backgroundColor: 'rgba(255,255,255, 0.9)', borderRadius: 5,boxShadow: '0px 3px 10px #515151' }}>
                                                <H2>Question # {i + 1}/100</H2>
                                                <div style={{display: 'flex', flexWrap: 'wrap', width: '90%', borderRadius: 6, padding: 5, margin: '10px auto', justifyContent: 'center'}}>
                                                    {this.state.questions[i].question.split(' ').map((word, index) => {
                                                        if (word == '*'){
                                                        
                                                        return (
                                                            <H3 style={{backgroundColor: '#bebebe', color: 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0}}>?</H3>
                                                        )
                                                        }else {
                                                        return (
                                                            <H3>{word}</H3>
                                                            
                                                        )

                                                        }
                                                        
                                                    })

                                                    }

                                                    </div>
                                                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', justifyContent: 'center'}}>
                                                    {this.state.questions[i].options.map(option => {
                                                        return (
                                                        <div style={{backgroundColor: option == this.state.selected ? 'rgb(30, 77, 167)' : "#ebebeb",  margin: 10, cursor: 'pointer', padding: 2, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)', justifyContent: 'center'}} 
                                                        onClick={() => {
                                                            if (!this.state.done){
                                                                this.setState({done: true, selected: option})
                                                                if (option == this.state.questions[i].ans){
                                                                let {solved, correct} = this.state;
                                                                solved.push(question)
                                                                correct.push(question)
                                                               
                                                                this.setState({solved,correct})
                                                               
                                                                if (solved.length == this.state.questions.length){
                                                                    this.evaluate()
                                                                }
                                                                } else {
                                                                    let {solved} = this.state;
                                                                    solved.push(question)
        
                                                                    this.setState({solved})
                                                                 
                                                                    if (solved.length == this.state.questions.length){
                                                                        this.evaluate()
                                                                    }
    
                                                                }
                                                            }
                                                            
                                                        }}>
                                                            <H3 style={{color: option == this.state.selected ? 'white' : "black",}}>{option}</H3>

                                                        </div>
                                                        )
                                                    })

                                                    }

                                                    </div>

                                                </div>
                                                

                                            </div>
                                        )
                                    })

                                    }

                           
                                
                            </ReactSwipe>
                            <div style={{height: 80}}>
                                
                                <Button onClick={() => {
                                    if (this.state.done){
                                        reactSwipeEl.next();
                                        this.next();
                                    }
                                    
                                    
                                    
                                    
                                    }}  style={{textAlign: "center",  backgroundColor: '#f0dba6', color: 'black', margin: 10}} variant="contained" >Next Question</Button>
                            </div>
                            </div>

                        </div>
                        
                    </Dialog>
                    

                </div>
            )

        }
        
    }
}

const H3 = styled.h3 `
  font-weight: bold;
  margin: 2px ;
  padding: 4px;

  @media(max-width: 500px ){
    font-size: medium;
  }

`

const H2 = styled.h2 `
  font-weight: bold;
  font-size: x-large;
  margin: 5px;
  padding: 5px;
  font-family: 'Apple Chancery';

  @media(max-width: 500px ){
    font-size: large;
  }

`

const Word = styled.h3 `
  padding: 10px;
  margin: 5px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
  border-radius: 5px 5px 0px 0px;
`