import React, { Component } from 'react';
import { Button } from "@material-ui/core";
import "../../config";
import * as firebase from "firebase";
import { Dialog } from "@material-ui/core";
import Contract from '../SalesPage/affiliateContract'
import Congratulations from './congratulations'

import {
    BrowserRouter as Router,
    Route,
    Switch,
    Link,
    Redirect,
  } from "react-router-dom";

export default class intro extends Component {
    constructor(props){
        super(props)
        this.state = {
            dialog: true,
            open: false,
            accepted: false
        }
    }


    componentDidMount(){
        firebase.firestore().collection('Users').doc(localStorage.getItem('email')).get().then(res => {
            if (res.data().affiliate == false){
                this.setState({open:true})
            }
        })


    }

    render (){
        if (this.state.accepted){
            return (
                <Congratulations />
            )
        }else return (
            <div>
                <Dialog
                open={this.state.open}
                onClose={()=> this.setState({open: false})}
                fullWidth
                style={{padding: 20}}
                >
                    <div style={{textAlign: 'center'}}>
                    <h2>Become an affiliate!</h2>
                    <div style={{width: '90%', margin: 'auto', height: 300, overflow: 'scroll', overflowX: 'hidden'}}>
                    <Contract />


                    </div>
                    {/* <div>
                        <input type="checkbox"/>

                    </div> */}
                    
                    <Button variant="contained" style={{width: 'max-content',margin: '10px auto'}} color="primary" onClick={()=> {
                    firebase
                    .firestore()
                    .collection("Users")
                    .doc(localStorage.getItem('email'))
                    .update({
                        affiliate: true
                    }).then(res => {
                      
                        this.setState({accepted: true, open: false})
                        
                    })
                    }}>Agree and continue</Button>

                    </div>
                   
                    


                

                    
                    
                </Dialog>


            </div>
        )
    }
}