import React, { Component } from "react";
import "../../../App.css";
import styled from "styled-components";
import { fire } from "../../../config";
import storage from "../../../config";
import "../../../config";
import * as firebase from "firebase";
import ReactPlayer from "react-player";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { Input } from "antd";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TextField } from "@material-ui/core";
import Dropzone from 'react-dropzone'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ReactAudioPlayer from 'react-audio-player';
import MicIcon from '@material-ui/icons/Mic';
import StopIcon from '@material-ui/icons/Stop';
import SendIcon from '@material-ui/icons/Send';
import MicRecorder from 'mic-recorder-to-mp3';
import book from '../../../Images/header-book.PNG'
import share from '../../../Images/1047 2.png'
import { Dialog } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import ebook from '../../../Images/ebook.png'
import job from '../../../Images/ORANGE BOX.png'
import civics from '../../../Images/AMERICAN FLAG BOX.png'

import '../affiliate.css'



const Mp3Recorder = new MicRecorder({ bitRate: 128 });


export default class Bonus extends Component {
    constructor(){
        super()
        this.state = {
            dialogBook: false,
            dialogShare: false

        }

    }

    componentDidMount(){
        firebase.firestore().collection('Training').doc('introduction').onSnapshot(res => {
            this.setState({video: res.data().url})
           
        
        })
        
        
    }


    

    render(){
        return (
            <div style={{paddingBottom: 20, backgroundColor: '#707070', height: '100%', width: '100%'}}>
                <h2 style={{fontFamily: 'cursive', color: '#edd290'}}>Bonus Lessons</h2>

                <div style={{width: '100%', margin: 'auto', display: 'flex', justifyContent: 'space-around', textAlign: 'center'}}>
                    

                    <div onClick={() => this.setState({dialogBook: true})} className="bonus">
                        <h3 style={{fontSize: 'large', fontWeight: 'bold', color: '#edd290',cursor: 'pointer'}}>Bonus #1</h3>
                        <img src={ebook} style={{cursor: 'pointer',width: '60%', margin: 'auto'}} />
                        <p style={{color: '#edd290'}}>Inglés Subconsciente</p>
                        

                    </div>

                    <div onClick={() => this.setState({})} className="bonus">
                        <h3 style={{fontSize: 'large', fontWeight: 'bold', color: '#edd290',cursor: 'pointer'}}>Bonus #2</h3>
                        <img src={job} style={{cursor: 'pointer',width: '60%', margin: 'auto'}} />
                        <p>Prepared for the Job Interview</p>
                        

                    </div>

                    <div onClick={() => this.setState({})} className="bonus">
                        <h3 style={{fontSize: 'large', fontWeight: 'bold', color: '#edd290',cursor: 'pointer'}}>Bonus #3</h3>
                        <img src={civics} style={{cursor: 'pointer',width: '60%', margin: 'auto'}} />
                        <p>download e-book</p>
                        

                    </div>

                    <div onClick={() => this.setState({dialogShare: true})} className="bonus">
                        <h3 style={{fontSize: 'large', fontWeight: 'bold', color: '#edd290',cursor: 'pointer'}}>Bonus #4</h3>
                        <img src={share} style={{cursor: 'pointer',width: '90%', height: '60%', margin: 'auto'}} />
                        <p>2 x 1</p>
                        

                    </div>
                        

                    </div>
                
                    <Dialog 
                        open={this.state.dialogBook}
                        onClose={()=> this.setState({dialogBook: false})}
                        fullWidth
                        >
                            <div style={{textAlign: 'center', width: '100%', height: '100%', backgroundColor: '#ebebeb',padding: 10, border: '5px solid #edd490'}}>
                                <img src={book} style={{width: '80%', margin: 'auto'}} />
                                <div style={{textAlign: 'center', width: '100%'}}>
              
                                    <h2 style={{fontSize: 'x-large', marginBottom: '20px'}}>Descarga el libro (pdf) y audiolibro (mp3)</h2>
                                    <div style={{display: 'flex',width: 350, position: 'relative', justifyContent: 'space-around', margin: 'auto' }}><p style={{marginTop: 7}}>Subconscious English:</p> 
                                        <a href="https://firebasestorage.googleapis.com/v0/b/nuparla-bbc0a.appspot.com/o/Docs%2Fbooks%2FSubconscious%20English%20FINAL%20DRAFT.pdf?alt=media&token=bffd585d-0c2b-435b-8d43-6db3c1dfb9b2" download target="_blank"><Button variant="contained" style={{backgroundColor: 'green', color: 'white'}}><GetAppIcon />PDF</Button></a> 
                                        <a href="https://firebasestorage.googleapis.com/v0/b/nuparla-bbc0a.appspot.com/o/Docs%2Fbooks%2FSubconscious%20English%20Audiobook%20(1).mp3?alt=media&token=92916ffd-99e9-4cfe-a179-4cc71db91b57" download target="_blank"><Button variant="contained" style={{ backgroundColor: 'green', color: 'white'}}><GetAppIcon />MP3</Button></a>
                                    

                                    </div>

                                    <div style={{display: 'flex',width: 350,margin: '10px 0', position: 'relative', justifyContent: 'space-around', margin: 'auto' }}><p style={{marginTop: 7}}>Inglés Subconsciente:</p> 
                                        <a href="https://firebasestorage.googleapis.com/v0/b/nuparla-bbc0a.appspot.com/o/Docs%2Fbooks%2FIngles%20Subconsciente%20FINAL%20DRAFT.pdf?alt=media&token=1651da3a-e62a-4564-885d-5d8cc28b2939" download target="_blank"><Button variant="contained" style={{backgroundColor: 'green', color: 'white'}}><GetAppIcon />PDF</Button></a> 
                                        <a href="https://firebasestorage.googleapis.com/v0/b/nuparla-bbc0a.appspot.com/o/Docs%2Fbooks%2FIngles%20Subconsciente%20LIBRO%20FINAL.mp3?alt=media&token=0c9b9f02-5f6b-4f3c-ba31-74325fbc3441" download target="_blank"><Button variant="contained" style={{ backgroundColor: 'green', color: 'white'}}><GetAppIcon />MP3</Button></a>
                                    

                                    </div>
                                
                                </div>

                            </div>

                    
                    
                    
                    </Dialog>
                    <Dialog 
                        open={this.state.dialogShare}
                        onClose={()=> this.setState({dialogShare: false})}
                        fullWidth
                        >
                            <div style={{textAlign: 'center', width: '100%', height: '100%', padding: 10, border: '5px solid #edd490'}}>
                            <h3 style={{fontSize: 'large', fontWeight: 'bold',cursor: 'pointer'}}>Share with a friend!</h3>

                            <TextField fullWidth variant="filled" placeholder="Enter your friend's email" label="Email" margin="dense" style={{color: 'black'}} onChange={(e)=>{this.setState({email: e.target.value})}}/>
                            <TextField fullWidth variant="filled" placeholder="Enter your friend's name" label="Name" margin="dense" style={{color: 'black'}} onChange={(e)=>{this.setState({name: e.target.value})}}/>

                            <Button variant="contained" style={{backgroundColor: '#404040', color: '#edd490'}}>Share</Button>

                            </div>

                    
                    
                    
                    </Dialog>

            </div>
        )
    }

}