import React, {Component} from 'react';
import '../../App.css';
import styled from 'styled-components';

import logoblack from '../../Images/nuparla em1 .1 w.jpg'
import logogold from '../../Images/nuparla em1 .1.png'


export default class terms extends Component {
    constructor(){
        super();
        this.state = {
            lang: 'english'
        }
    }

    componentDidMount(){

    }

    render(){
        
        return (
            <div style={{maxWidth: 1280, margin: '20px auto'}}>
                <div style={{height: '100px'}}>
                    <a href="/"><img src={logoblack} className="logo-top" /></a>
                    <div style={{display: 'flex', flexWrap: 'wrap', float: 'right'}}>
                    <p className="p10 pointer phover " onClick={()=>{
                        if (this.state.lang == 'english'){
                            this.setState({lang: 'spanish'})
                        } else {
                            this.setState({lang: 'english'})
                        }
                    }}>{this.state.lang == 'english' ? "Spanish" : this.state.lang == "spanish" ? "English" : 'English'}</p>
                        

                    </div>
                </div> 
                
                {this.state.lang == 'english' && <div style={{width: '90%', margin: 'auto'}}>
                    <H1 style={{width: '90%', margin: 'auto', textAlign: 'center'}}>TERMS AND CONDITIONS</H1>
                    <P >IMPORTANT – PLEASE CAREFULLY READ AND UNDERSTAND THESE
                        TERMS AND CONDITIONS OF USE & SALE (THESE “TERMS”) BEFORE
                        ACCESSING, USING, OR SUBSCRIBING OR PLACING AN ORDER OVER
                        WWW.NUPARLA.COM OR ANY OTHER OF OUR SITES WHICH LINK TO
                        THESE TERMS.<br/><br/>
                        THESE TERMS CONTAIN DISCLAIMERS OF WARRANTIES AND
                        LIMITATIONS OF LIABILITIES INCLUDING ARBITRATION AND CLASS
                        ACTION WAIVER PROVISIONS THAT WAIVE YOUR RIGHT TO A COURT
                        HEARING, RIGHT TO A JURY TRIAL, AND RIGHT TO PARTICIPATE IN A
                        CLASS ACTION. ARBITRATION IS MANDATORY AND IS THE EXCLUSIVE
                        REMEDY FOR ANY AND ALL DISPUTES UNLESS SPECIFIED BELOW. THESE
                        TERMS FORM AN ESSENTIAL BASIS OF OUR AGREEMENT.<br/><br/>
                        THIS IS A BINDING AGREEMENT. THESE TERMS TOGETHER WITH OUR
                        PRIVACY STATEMENT AND DATA PROCESSING ADDENDUM FORM A
                        LEGALLY BINDING AGREEMENT (THE “AGREEMENT”) BETWEEN YOU AND
                        NUPARLA LLC.</P>


                        <h3>1. General</h3>
                        <P2>NuParla websites (www.nuparla.com, www.aprendeinglésganadinero.com, and 
                        others, “Websites”), its mobile applications, (“Apps”), the course NuParla Online 
                        English Academy, NuParla University and related services (collectively the 
                        “Service”) are operated exclusively by NuParla LLC, a California-registered 
                        limited liability company (“NuParla,” “us,” or “we”). Access and use of the Service 
                        is subject to the following Terms and Conditions of Service (the "Terms" or 
                        “Terms and Conditions”). You ("Student," "Affiliate," "you"), by accessing or 
                        using any part of the Service, represent that you have read, understood, and 
                        agree to be bound by these Terms and Conditions including any future 
                        modifications. </P2>

                        <h3>2. Description of Website and Service</h3>
                        <P2>
                        The Service allows users to access and use a variety of educational services, 
                        including learning or practicing English through the NuParla Online English 
                        Academy. Although the 90 online English-learning videos are free on 
                        NuParla.com and the NuParla YouTube channel, to experience the complete 
                        NuParla experience there is a one-time fee of $49.95.<br/><br/>
                        NuParla reserves the right to suspend all access to the Service temporarily or 
                        permanently if payments are not made, or if the card is declined, and that upon 
                        payment, such Service will be restored. Questions can be addressed online to 
                        our Customer Service team at apoyo@nuparla.com.<br/><br/>
                        Lifetime access to the complete experience of the NuParla Online English 
                        Academy, is defined as the useful life of the product, which means that as long 
                        as you have fulfilled all payment obligations and have complied with the terms 
                        of service, you will continue to have access to the course until it is suspended, 
                        the website is no longer in service, or the company closes or files for bankruptcy, 
                        whichever occurs first. NuParla may, in its sole discretion and at any time, 
                        update, change, suspend, make improvements to or discontinue any aspect of 
                        the Service.


                        </P2>

                        <h3>3. Registration; Submission of Content</h3>
                        <P2><u><i>a. Registration</i></u><br/><br/>
                        In connection with registering for and using the Service, you agree 1. to provide 
accurate, current and complete information about you as requested by NuParla; 
2. to maintain the confidentiality of your password and other information related 
to the security of your account; 3. to maintain and promptly update any 
registration information you provide to NuParla, to keep such information 
accurate, current and complete; and 4. to be fully responsible for all use of your 
account and for any actions that take place through your account.<br/><br/>
                            <u><i>b. General Content</i></u><br/><br/>
                            As a condition of submitting any ratings, reviews, information, data, text, 
photographs, audio/video clips, audiovisual works, postings or other materials 
on or about the Services (“Content”), you hereby grant to NuParla a royalty free, 
perpetual, irrevocable, worldwide, nonexclusive, transferable, and sub￾licensable license to use, reproduce, copy, adapt, modify, merge, distribute, 
publicly display, create derivative works from, incorporate such Content into 
other works. You represent that you own or have secured all legal rights 
necessary for the Content submitted by you to be used by you. You understand 
that other users will have access to the Content and that neither they or NuParla have any obligation to you or anyone else to maintain the confidentiality of the 
Content you submitted.</P2>


                        <h3>4. Inappropriate Use</h3>
                        <P2>You will not upload, display or otherwise provide on or through the Service any 
content that: 1. is libelous, defamatory, abusive, threatening, harassing, hateful, 
offensive or otherwise violates any law or infringes upon the right of any third 
party (including copyright, trademark, privacy, publicity or other personal or 
proprietary rights); or 2. in NuParla’s sole judgment, is objectionable or which 
restricts or inhibits any other person from using the Service or which may expose 
NuParla or its users to any harm or liability of any kind.</P2>


                        <h3>5. Indemnification of NuParla</h3>
                        <P2>You agree to defend, indemnify and hold harmless NuParla and its directors, 
officers, employees, contractors, agents, suppliers, licensors, successors and 
assigns, from and against any and all losses, claims, causes of action, 
obligations, liabilities and damages whatsoever, including attorneys' fees, 
arising out of or relating to your access or use of the Service, any false 
representation made to us (as part of these Terms and Conditions or otherwise), 
your breach of any of these Terms and Conditions, or any claim that any 
translation we provide to you is inaccurate, inappropriate or defective in any way 
whatsoever.</P2>


                        <h3>6. License to Apps</h3>
                        <P2>Subject to the terms of these Terms and Conditions, NuParla grants you a nontransferable, non-exclusive license to download, install, and use one copy of 
each App in object code form only on an interactive wireless device that you 
own or control. You may not derive or attempt to derive the source code of all 
or any portion of any App, permit any third party to derive or attempt to derive 
such source code, or reverse engineer, decompile, disassemble, or translate 
any App or any part thereof. NuParla and its licensors own and shall retain all 
intellectual property rights and other rights in and to the Apps, and any changes, 
modifications, or corrections thereto.</P2>

                        <h3>7. Payment Processors</h3>
                        <P2>All financial transactions made in connection with the Service will be processed 
by a third party in accordance with their respective terms of use, privacy policy, and/or any applicable payment terms and conditions. We encourage you to 
learn about the practices of such third party. In no event will NuParla be 
responsible for the actions or inactions of any third party payment processor, 
including, but not limited to, system downtime or payment service outages.</P2>

                        <h3>8. Refund Policy</h3>
                        <P2>NuParla offers a 3-day satisfaction guarantee, starting the day the Service was 
purchased. In the event that NuParla suspends or terminates your use of the 
Service or you close your account voluntarily, you understand and agree that 
you will receive no refund or exchange of any kind after the 3-day refund period. <br/><br/>
In the event that you decide your purchase was not the right decision for you, 
you can contact our customer service team at soporte@nuparla.com within 3
calendar days of the purchase, and request such refund. To qualify for the 
refund, you must provide evidence of having watched and interacted with the 
course, as mentioned above. Failure to include such evidence along with the 
refund request within the 3-day refund time period will result in no refund, as all 
refund requests require evidence of the work the Student did throughout the 
course. This refund policy does not apply to any additionally purchased personal 
NuParla coaching.</P2>

                        <h3>9. Affiliate Program; Online Marketing</h3>
                        <P2>If you choose to share the Service with others from the back office by clicking 
Affiliate, you will have the opportunity to receive a commission of 20% of the 
purchase ($49.95), which equals to $10, when another person (a “Prospect”) 
creates a NuParla account by using your Affiliate Link. Such commission is paid 
via PayPal (or Zelle if you’re in the US) on the first Friday after the three (3) day 
refund period expires, the Prospect makes payment, and a “Sale” is registered.<br/><br/>
You agree to provide certain information (such as, but not necessarily limited to 
a completed W-9 for US residents). If you do not complete the requisite 
information within one hundred and twenty (120) days of earning your first 
commission, you will be deemed to have permanently waived all rights to further 
commissions. If you are not a resident of the United States, taxes (including 
without limitation VAT) may be withheld where required by applicable law. You 
are solely responsible for complying with all tax laws in your respective 
jurisdiction or country of residence including, but not limited to, the payment of 
all required taxes to, and filing of all returns and other required documents with, 
the applicable governing body. 
<br/><br/>
NuParla may offer you an opportunity to become an independent NuParla
affiliate (“Affiliate,” "NuParlero"), wherein you have the opportunity to earn 
additional money for NuParla accounts that you sell to other users subject to 
your acceptance of the terms of the NuParla Affiliate agreement (the “Affiliate 
Agreement”). NuParla reserves the sole and exclusive right to determine the 
amount of remuneration each Affiliate will receive in exchange for the Affiliate’s 
efforts. Affiliate commission is further discussed in the Affiliate Agreement. <br/><br/>
If you wish to grow your reach by social media marketing campaigns through 
ads, such as those found in Facebook ads, YouTube ads, etc., they must be 
done through your own personal social media accounts, and disclose the fact 
that you are being compensated for referring and registering new persons onto 
the Service. The creation of profiles, fan pages, or websites that use the name 
NuParla or "Aprende inglés. Gana dinero." or “Aprende inglés GRATIS online” 
the use of our logo is strictly prohibited.</P2>

                        <h3>10. Independent Contractor Status</h3>
                        <P2>For avoidance of doubt, Affiliates are independent contractors and are not 
employees or agents of NuParla. Affiliates have no authority to act on behalf of 
or bind NuParla in any way. Affiliates shall be solely and exclusively responsible 
for all costs and other expenses incurred. Nothing in this Agreement should be 
construed as creating a partnership, venture alliance, or any other similar 
relationship. Each party shall be an independent contractor in its performance 
hereunder and shall retain control over its personnel and the manner in which 
such personnel perform hereunder. In no case shall such persons be 
considered employees of the other party by virtue of their participation or 
performance hereunder.</P2>


                        <h3>11. Earning Disclosure</h3>
                        <P2>There is no guarantee that you will earn any money with NuParla, or by using 
the techniques and ideas in the Service marketing materials. Examples in these 
materials are not to be interpreted as a promise or guarantee of earnings. 
Earning potential is entirely dependent on the person using our product, ideas 
and techniques. We do not position this product as a "get rich scheme." Any 
claims made of actual earnings or examples of actual results can be verified 
upon request. Your level of success in attaining the results claimed in our 
materials depends on the time you devote to the program, ideas and techniques 
mentioned, your finances, knowledge and various skills. Since these factors 
differ according to individuals, we cannot guarantee your success or income 
level. Nor are we responsible for any of your actions or results.<br/><br/>
Materials in our product and our website may contain information that includes 
or is based upon forward-looking statements within the meaning of the Private 
Securities Litigation Reform Act of 1995. Forward-looking statements give our 
expectations or forecasts of future events. You can identify these statements by 
the fact that they do not relate strictly to historical or current facts. They use 
words such as "anticipate," "estimate," "expect," "project," "intend," "plan," 
"believe," and other words and terms of similar meaning in connection with a 
description of potential earnings or financial performance.<br/><br/>
Any and all forward looking statements here or on any of our sales material are 
intended to express our opinion of earnings potential. Many factors will be 
important in determining your actual results and no guarantees are made that 
you will achieve results similar to ours or anybody else’s, in fact no guarantees 
are made that you will achieve any results from our ideas and techniques in our 
material.</P2>

                        <h3>12. Non-transfer of Intellectual Property</h3>
                        <P2>The Service is protected by copyright law. Any and all original materials 
provided to you are for your individual use only under a single-user license. You 
are not authorized to use any of our intellectual property for the commercial 
purposes. All intellectual property, including NuParla x 90 Days' copyrighted 
course programs and/or materials, are the sole property of NuParla LLC. No 
license to sell or distribute our materials is granted or implied. By purchasing 
this product, you agree: 1. not to infringe any copyright, patent, trademark, trade 
secret or other intellectual property rights; 2. that any Confidential Information 
shared by NuParla is confidential and wholly owned, and belongs solely and 
exclusively to NuParla; 3. not to disclose such information to any other person 
or to use it in any way other than in discussion with the NuParla. Furthermore, 
by purchasing the Service, you agree not to violate, or show any possibility of 
violating, the Terms contained in this paragraph. NuParla is entitled to use 
precautionary measures to prohibit such violations and protect against harm that 
such violations could incur.</P2>

                        <h3>13. NO REPRESENTATIONS OR WARRANTIES BY NUPARLA</h3>
                        <P2>THE SERVICE, INCLUDING ALL IMAGES, AUDIO FILES AND OTHER 
CONTENT THEREIN, AND ANY OTHER INFORMATION, PROPERTY AND 
7
RIGHTS GRANTED OR PROVIDED TO YOU BY NUPARLA ARE PROVIDED 
TO YOU ON AN “AS IS” BASIS. NUPARLA AND ITS SUPPLIERS MAKE NO 
REPRESENTATIONS OR WARRANTIES OF ANY KIND WITH RESPECT TO 
THE SERVICE, EITHER EXPRESS OR IMPLIED, AND ALL SUCH 
REPRESENTATIONS AND WARRANTIES, INCLUDING WARRANTIES OF 
MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NONINFRINGEMENT, ARE EXPRESSLY DISCLAIMED. WITHOUT LIMITING THE 
GENERALITY OF THE FOREGOING, NUPARLA DOES NOT MAKE ANY 
REPRESENTATION OR WARRANTY OF ANY KIND RELATING TO 
ACCURACY, SERVICE AVAILABILITY, COMPLETENESS, INFORMATIONAL 
CONTENT, ERROR-FREE OPERATION, RESULTS TO BE OBTAINED FROM 
USE, OR NON-INFRINGEMENT. ACCESS AND USE OF THE SERVICE MAY 
BE UNAVAILABLE DURING PERIODS OF PEAK DEMAND, SYSTEM 
UPGRADES, MALFUNCTIONS OR SCHEDULED OR UNSCHEDULED 
MAINTENANCE OR FOR OTHER REASONS.<br/><br/>
The Service is developed strictly for educational purposes only. You are 100% 
responsible for your own progress and results. NuParla makes no 
representations, warranties or guarantees, oral or in writing, as the results 
experienced by each Student can vary significantly. You acknowledge that, as 
with any business endeavor, there is an inherent risk of capital loss and there is 
no guarantee that you will achieve your objectives as a result of participation in 
the Service. The education and information on the program is intended for a 
general audience and is not intended to be, nor should it be construed as, 
specific advice tailored to anyone. NuParla assumes no responsibility for errors 
or omissions that may appear in the program materials.</P2>

                        <h3>14. LIMITATION OF LIABILITY AND TYPES OF DAMAGES</h3>
                        <P2>IN NO EVENT WILL NUPARLA BE LIABLE TO YOU OR ANY THIRD PARTY 
CLAIMING THROUGH YOU (WHETHER BASED IN CONTRACT, TORT, 
STRICT LIABILITY OR OTHER THEORY) FOR INDIRECT, INCIDENTAL, 
SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING OUT OF 
OR RELATING TO THE ACCESS OR USE OF, OR THE INABILITY TO 
ACCESS OR USE, THE SERVICE OR ANY PORTION THEREOF, 
INCLUDING BUT NOT LIMITED TO THE LOSS OF USE OF THE SERVICE, 
INACCURATE RESULTS, LOSS OF PROFITS, BUSINESS INTERRUPTION, 
OR DAMAGES STEMMING FROM LOSS OR CORRUPTION OF DATA OR 
DATA BEING RENDERED INACCURATE, THE COST OF RECOVERING ANY DATA, THE COST OF SUBSTITUTE SERVICES OR CLAIMS BY THIRD 
PARTIES FOR ANY DAMAGE TO COMPUTERS, SOFTWARE, MODEMS, 
TELEPHONES OR OTHER PROPERTY, EVEN IF NUPARLA HAS BEEN 
ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NUPARLA’S 
LIABILITY TO YOU OR ANY THIRD PARTY CLAIMING THROUGH YOU FOR 
ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE 
ACTION, IS LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO NUPARLA
FOR THE SERVICE IN THE 12 MONTHS PRIOR TO THE INITIAL ACTION 
GIVING RISE TO LIABILITY. THIS IS AN AGGREGATE LIMIT. THE 
EXISTENCE OF MORE THAN ONE CLAIM HEREUNDER WILL NOT 
INCREASE THIS LIMIT.</P2>

                        <h3>15. Termination</h3>
                        <P2>NuParla may terminate your access and use of the Service immediately at any 
time, for breach of Terms, and at such time you will have no further right to use 
the Service. You may terminate your NuParla account at any time by following 
the instructions available through the Service. The provisions of these Terms 
and Conditions relating to the protection and enforcement of NuParla’s 
proprietary rights, your representations and warranties, disclaimer of 
representations and warranties, release and indemnities, limitations of liability 
and types of damages, ownership of data and information, governing law and 
venue, and miscellaneous provisions shall survive any such termination. </P2>

                        <h3>16. Proprietary Rights in Service Content and Activity Materials</h3>
                        <P2>All content available through the Service, including designs, text, graphics, 
images, information, software, audio and other files, and their selection and 
arrangement (the "Service Content"), are the proprietary property of NuParla or 
its licensors. No Service Content may be modified, copied, distributed, framed, 
reproduced, republished, downloaded, scraped, displayed, posted, transmitted, 
or sold in any form or by any means, in whole or in part, other than as expressly 
permitted in these Terms and Conditions. You may not use any data mining, 
robots, scraping or similar data gathering or extraction methods to obtain 
Service Content. As between you and NuParla, all data, information and 
materials generated from your access and use of the educational activities 
made available on or through the Service, including exercises, typed and audio 
message content generated by you (collectively, the “Activity Materials”), shall 
be exclusively owned by NuParla, and you shall not have any right to use such 
Activity Materials except as expressly authorized by these Terms and Conditions. Activity Materials will not include Translation Materials. By using the 
Service, you hereby assign to NuParla any and all rights, title and interest, 
including any intellectual property rights or proprietary rights, in the Activity 
Materials. All rights of NuParla or its licensors that are not expressly granted in 
these Terms and Conditions are reserved to NuParla and its licensors. </P2>

                        <h3>17. Trademarks</h3>
                        <P2>“NuParla,” “Aprende inglés. Gana dinero." Or “Aprende inglés GRATIS online.” 
and all other trademarks, service marks, graphics and logos used in connection 
with the Service are trademarks or service marks of NuParla LLC, and certain 
of them are registered with the United States Patent and Trademark Office. 
Access and use of the Service does not grant or provide you with the right or 
license to reproduce or otherwise use the NuParla name or any NuParla or thirdparty trademarks, service marks, graphics or logos.</P2>

                        <h3>18. Privacy</h3>
                        <P2>Use of the Service is also governed by our Privacy Policy, a copy of which is 
located at www.nuparla.com/privacy. By using the Service, you consent to the 
terms of the Privacy Policy.</P2>

                        <h3>19. Governing Law and Arbitration; No Class Action</h3>
                        <P2>These Terms and Conditions, its subject matter and NuParla’s and your 
respective rights under these Terms and Conditions, as well as and any claim, 
cause of action or dispute (“claim”) arising out of or related to these Terms and 
Conditions, shall be governed by and construed under the laws of the State of 
California, United States of America. ANY DISPUTE OR CLAIM RELATING IN 
ANY WAY TO THESE TERMS AND CONDITIONS OR THE SERVICE WILL 
BE RESOLVED BY BINDING ARBITRATION, RATHER THAN IN COURT, 
except for NuParla’s right to seek injunctive relief as set forth below. Unless 
otherwise expressly required by applicable law, each party shall bear its own 
attorneys’ fees without regard to which party is deemed the prevailing party in 
the arbitration proceeding.<br/><br/>
If you intend to seek arbitration you must first send written notice to NuParla’s 
Administration Office of your intent to arbitrate (“Notice”). The Notice to NuParla
should be sent by electronic mail to soporte@nuparla.com. The Notice must (a) 
describe the nature and basis of the claim or dispute; and (b) set forth the 
specific relief sought; and (c) set forth your name, affiliate ID number, address and contact information. If we intend to seek arbitration against you, we will send 
any notice of dispute to you at the contact information we have for you.<br/><br/>    
The arbitration will be conducted before a neutral single arbitrator in Riverside 
County located in the State of California, United States, whose decision will be 
final and binding,<br/><br/>
To the fullest extent permitted by applicable law, YOU AND NUPARLA EACH 
AGREE THAT ANY DISPUTE RESOLUTION PROCEEDING WILL BE 
CONDUCTED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, 
CONSOLIDATED OR REPRESENTATIVE ACTION. If for any reason a claim 
proceeds in court rather than in arbitration, YOU AND NUPARLA EACH WAIVE 
ANY RIGHT TO A JURY TRIAL. </P2>

                        <h3>20. Language</h3>
                        <P2>This agreement was originally written in English (US). To the extent any 
translated version of this agreement conflicts with the English version, the 
English version controls.</P2>

                        <h3>21. Miscellaneous</h3>
                        <P2>These Terms and Conditions constitute the entire agreement between NuParla
and you concerning the subject matter hereof. In the event that any of the Terms 
and Conditions are held by a court or other tribunal of competent jurisdiction to 
be unenforceable, such provisions shall be limited or eliminated to the minimum 
extent necessary so that these Terms and Conditions shall otherwise remain in 
full force and effect. A waiver by NuParla or you of any provision of these Terms 
and Conditions or any breach thereof, in any one instance, will not waive such 
term or condition or any subsequent breach thereof. NuParla may assign its 
rights or obligations under these Terms and Conditions without condition. These 
Terms and Conditions will be binding upon and will inure to the benefit of 
NuParla and you, and NuParla’s and your respective successors and permitted 
assigns.</P2>

                            











                </div>}
























                {this.state.lang == 'spanish' && <div style={{width: '90%', margin: 'auto'}}>
                    <H1 style={{width: '90%', margin: 'auto', textAlign: 'center'}}>TÉRMINOS Y CONDICIONES</H1>
                    <P >IMPORTANTE: LEA CUIDADOSAMENTE Y ENTIENDA ESTOS TÉRMINOS Y
                        CONDICIONES DE USO Y VENTA (ESTOS "TÉRMINOS") ANTES DE
                        ACCEDER, USAR O SUSCRIBIR O REALIZAR UN PEDIDO EN
                        WWW.NUPARLA.COM O CUALQUIER OTRO DE NUESTROS SITIOS DE WEB
                        QUE ENLACEN A ESTOS TÉRMINOS.<br/><br/>
                        ESTOS TÉRMINOS CONTIENEN RENUNCIAS A GARANTÍAS Y
                        LIMITACIONES DE RESPONSABILIDADES, INCLUIDAS LAS DISPOSICIONES
                        DE ARBITRAJE Y DE EXENCIÓN DE ACCIÓN DE CLASE QUE RENUNCIAN A
                        SU DERECHO A UNA AUDIENCIA JUDICIAL, DERECHO A UN JUICIO JURADO
                        Y DERECHO A PARTICIPAR EN UNA ACCIÓN DE CLASE. EL ARBITRAJE ES
                        OBLIGATORIO Y ES EL RECURSO EXCLUSIVO PARA CUALQUIERA Y
                        TODAS LAS DISPUTAS A MENOS QUE SE ESPECIFIQUE A CONTINUACIÓN.
                        ESTOS TÉRMINOS FORMAN UNA BASE ESENCIAL DE NUESTRO ACUERDO.<br/><br/>
                        ESTE ES UN ACUERDO VINCULANTE. ESTOS TÉRMINOS JUNTOS CON
                        NUESTRA DECLARACIÓN DE PRIVACIDAD Y ADENDA DE PROCESAMIENTO
                        DE DATOS FORMULAN UN ACUERDO LEGALMENTE VINCULANTE (EL
                        "ACUERDO") ENTRE USTED Y NUPARLA LLC.</P>


                        <h3>1. General</h3>
                        <P2>Los sitios web de NuParla que incluyen, pero no se limitan a www.nuparla.com, 
www.aprendeinglésganadinero.com y otros (“Sitios web”), sus aplicaciones 
móviles (“Aplicaciones”), el curso NuParla Online English Academy, la NuParla
University y otros servicios relacionados (colectivamente el “Servicio") son 
operados exclusivamente por NuParla LLC, una compañía LLC registrada en 
California (" NuParla "," nosotros "o" nuestro(s)"). El acceso y uso del Servicio 
está sujeto a los siguientes Términos y Condiciones del Servicio (los "Términos" 
o "Términos y Condiciones"). Usted ("Estudiante", "Afiliado", "usted"), al 
acceder o utilizar cualquier parte del Servicio, declara que ha leído, entendido 
y acepta regirse por estos Términos y condiciones, incluidas las modificaciones 
futuras. </P2>

                        <h3>2. Descripción del sitio web y servicio</h3>
                        <P2>El Servicio permite a los usuarios acceder y utilizar una variedad de servicios 
educativos, que incluyen aprender o practicar inglés a través del NuParla Online 
English Academy. Aunque los 90 videos de aprendizaje de inglés en línea son 
gratuitos en NuParla.com y el canal de YouTube de NuParla, para experimentar 
la experiencia completa de NuParla hay una tarifa única de $ 49.95.<br/><br/>
NuParla se reserva el derecho de suspender todo el acceso al Servicio de forma 
temporal o permanente si no se realizan los pagos, o si se rechaza la tarjeta de 
crédito o débito, y que luego del pago, dicho Servicio se restablecerá. Preguntas 
pueden dirigirse en línea a nuestro equipo de Servicio al Cliente en 
apoyo@nuparla.com.<br/><br/>
El acceso de por vida al curso NuParla x 90 días, y a NuParla University, se 
define como la vida útil del producto, lo que significa que mientras haya 
cumplido con todas las obligaciones de pago y haya cumplido con los términos 
del Servicio, continuará teniendo acceso al curso hasta que se suspenda, el 
sitio web ya no esté en servicio o la empresa cierre o se declare en bancarrota, 
lo que ocurra primero. NuParla puede, a su exclusivo criterio y en cualquier 
momento, actualizar, cambiar, suspender, realizar mejoras o suspender 
cualquier aspecto del Servicio.</P2>

                        <h3>3. Registro; Envío de contenido</h3>
                        <P2><u><i>a. Registro</i></u><br/><br/>
                        En relación con el registro y el uso del Servicio, usted acepta 1. proporcionar 
información precisa, actual y completa sobre usted según lo solicite NuParla; 2. 
para mantener la confidencialidad de su contraseña y otra información 
relacionada con la seguridad de su cuenta; 3. mantener y actualizar 
rápidamente cualquier información de registro que proporcione a NuParla, para 
mantener dicha información precisa, actual y completa; y 4. ser totalmente 
responsable del uso de su cuenta y de cualquier acción que tenga lugar a través 
de ella.<br/><br/>
                            <u><i>b. Contenido general</i></u><br/><br/>
                            Como condición para enviar calificaciones, evaluaciones, información, datos, 
textos, fotografías, clips de audio / video, obras audiovisuales, publicaciones u 
otros materiales sobre los Servicios ("Contenido"), usted le otorga a NuParla un 
derecho de acceso libre de regalías, una licencia perpetua, irrevocable, 
mundial, no exclusiva, transferible y sub-licenciable para usar, reproducir, 
copiar, adaptar, modificar, fusionar, distribuir, mostrar públicamente, crear 
obras derivadas, incorporar dicho Contenido a otras obras. Usted declara que 
posee o ha asegurado todos los derechos legales necesarios para que el 
Contenido enviado por usted sea utilizado por usted. Usted comprende que otros usuarios tendrán acceso al Contenido y que ni ellos ni NuParla tienen 
obligación alguna con usted ni con ninguna otra persona de mantener la 
confidencialidad del Contenido que envió.</P2>


                        <h3>4. Uso inapropiado</h3>
                        <P2>No cargará, mostrará ni proporcionará de ningún otro modo en el Servicio ni a
                            través de él ningún contenido que: 1. sea calumnioso, difamatorio, abusivo,
                            amenazante, acosador, odioso, ofensivo o de otra manera viole cualquier ley o
                            infrinja el derecho de un tercero (incluido derechos de autor, marca registrada,
                            privacidad, publicidad u otros derechos personales o de propiedad); o 2. a juicio
                            exclusivo de NuParla, que tal contenido sea objetable o restringe o inhibe a
                            cualquier otra persona a utilizar el Servicio o puede exponer a NuParla o sus
                            usuarios a cualquier daño o responsabilidad de cualquier tipo. </P2>


                        <h3>5. Indemnización de NuParla</h3>
                        <P2>Usted acepta defender, indemnizar y eximir de responsabilidad a NuParla y sus
                            directores, funcionarios, empleados, contratistas, agentes, proveedores,
                            otorgantes de licencias, sucesores y cesionarios, de y contra todas y cada una
                            de las pérdidas, reclamos, causas de acción, obligaciones, responsabilidades
                            y daños de cualquier tipo, incluidos los honorarios de abogados, derivados o
                            relacionados con su acceso o uso del Servicio, cualquier representación falsa
                            que se nos haga (como parte de estos Términos y Condiciones o de otra
                            manera), su incumplimiento de cualquiera de estos Términos y Condiciones, o 
                            cualquier afirme que cualquier traducción que le proporcionemos sea inexacta,
                            inapropiada o defectuosa de cualquier manera.</P2>


                        <h3>6. Licencia para aplicaciones</h3>
                        <P2>Sujeto a los términos de estos Términos y Condiciones, NuParla le otorga una
                            licencia no transferible y no exclusiva para descargar, instalar y usar una copia
                            de cada aplicación solo en forma de código de objeto, en un dispositivo móvil
                            que usted posee o controla. No puede derivar o intentar derivar el código fuente
                            de toda o parte de la Aplicación, permitir que un tercero derive o intente derivar
                            dicho código fuente, o realizar ingeniería inversa, descompilar, desensamblar o
                            traducir cualquier Aplicación o parte de ella. NuParla y sus licenciantes poseen
                            y conservarán todos los derechos de propiedad intelectual y otros derechos
                            sobre las Aplicaciones, y cualquier cambio, modificación o corrección a las
                            mismas.</P2>

                        <h3>7. Procesadores de pago</h3>
                        <P2>Todas las transacciones financieras realizadas en relación con el Servicio serán
                            procesadas por un tercero de acuerdo con sus respectivos términos de uso,
                            política de privacidad y / o los términos y condiciones de pago aplicables. Le
                            recomendamos que conozca las prácticas de dicho tercero. En ningún caso,
                            NuParla será responsable de las acciones o inacciones de cualquier procesador
                            de pagos de terceros, incluidos, entre otros, el tiempo de inactividad del sistema
                            o las interrupciones del servicio de pago.</P2>

                        <h3>8. Política de reembolso</h3>
                        <P2>NuParla ofrece una garantía de satisfacción de 3 días, comenzando el día en 
que se compró el Servicio. En el caso de que NuParla suspenda o termine su 
uso del Servicio o si usted cierre su cuenta voluntariamente, comprende y 
acepta que no recibirá ningún reembolso o cambio de ningún tipo después del 
período de reembolso de 3 días. <br/><br/>
En caso de que decida que su compra no fue la decisión correcta para usted, 
puede comunicarse con nuestro equipo de servicio al cliente en 
soporte@nuparla.com dentro de los 3 días calendario posteriores a la compra 
y solicitar dicho reembolso. Para calificar para el reembolso, debe proporcionar 
evidencia de haber visto e interactuado con el curso, como se mencionó 
anteriormente. Si no se incluye dicha evidencia junto con la solicitud de 
reembolso dentro del período de tiempo de reembolso de 3 días, no habrá 
reembolso, ya que todas las solicitudes de reembolso requieren evidencia del 
trabajo que el Estudiante realizó durante el curso. Esta política de reembolso 
no se aplica a ningún coaching personal NuParla adquirido adicionalmente.</P2>

                        <h3>9. Programa de afiliados; Mercadeo en línea</h3>
                        <P2>Si elige compartir el Servicio con otros desde su oficina virtual de gestión de 
ventas al hacer clic en Affiliate, tendrá la oportunidad de recibir una comisión 
del 20% de la compra ($49.95), que equivale a $10, cuando otra persona (un 
"Cliente potencial") cree una cuenta de NuParla utilizando su Enlace de afiliado. 
Dicha comisión se paga a través de PayPal el primer viernes después de que 
expire el período de reembolso de tres (3) días, el Cliente potencial realiza el 
pago y se registra una "Venta".<br/><br/>
Usted acepta proporcionar cierta información (como, entre otros, un formulario 
W-9 completo para residentes de EE. UU.). Si no completa la información 
requerida dentro de los ciento veinte (120) días de haber obtenido su primera 
comisión, se considerará que ha renunciado permanentemente a todos los 
derechos de comisiones adicionales. Si no es residente de los Estados Unidos, 
los impuestos (incluido, entre otros, el IVA) pueden retenerse cuando lo exija la 
ley aplicable. Usted es el único responsable de cumplir con todas las leyes 
fiscales en su respectiva jurisdicción o país de residencia, incluido, entre otros, 
el pago de todos los impuestos requeridos y la presentación de todas las declaraciones y otros documentos requeridos ante el organismo rector 
correspondiente.<br/><br/>
NuParla puede ofrecerle la oportunidad de convertirse en un afiliado 
independiente de NuParla ("Afiliado", "NuParlero"), en el que tiene la 
oportunidad de ganar dinero adicional para las cuentas de NuParla que venda 
a otros usuarios sujeto a su aceptación de los términos del Acuerdo de afiliación 
(el "Acuerdo de afiliación") de NuParla. NuParla se reserva el derecho único y 
exclusivo de determinar el monto de la remuneración que recibirá cada Afiliado 
a cambio de los esfuerzos del Afiliado. La comisión de afiliación se trata más 
detalladamente en el Acuerdo de afiliación.<br/><br/>
Si desea aumentar su alcance mediante campañas de marketing en redes 
sociales a través de anuncios, como los que se encuentran en anuncios de 
Facebook, anuncios de YouTube, etc., debe hacerlo a través de sus propias
cuentas personales de redes sociales, y revelar el hecho de que está siendo 
compensado para referir y registrar nuevas personas en el Servicio. La creación 
de perfiles, páginas de fans o sitios web que usan el nombre NuParla o 
"Aprende inglés. Gana dinero." o “Aprende inglés GRATIS online” o el uso de 
nuestro logotipo está estrictamente prohibido.</P2>

                        <h3>10. Estado del contratista independiente</h3>
                        <P2>Para evitar dudas, los Afiliados son contratistas independientes y no son
                            empleados o agentes de NuParla. Los afiliados no tienen autoridad para
                            actuar en nombre o vincular a NuParla de ninguna manera. Los afiliados serán
                            única y exclusivamente responsables de todos los costos y otros gastos
                            incurridos. Nada en este Acuerdo debe interpretarse como la creación de una
                            asociación, alianza o cualquier otra relación similar. Cada parte será un
                            contratista independiente en su desempeño en virtud del presente y deberá
                            retener el control sobre su personal y la forma en que dicho personal se
                            desempeña en virtud del presente. En ningún caso, tales personas serán
                            consideradas empleados de la otra parte en virtud de su participación o
                            desempeño en virtud del presente.</P2>


                        <h3>11. Divulgación de ganancias</h3>
                        <P2>No hay garantía de que ganará dinero con NuParla, o mediante el uso de las
                            técnicas e ideas en los materiales de marketing del Servicio. Los ejemplos en
                            estos materiales no deben interpretarse como una promesa o garantía de
                            ganancias. El potencial de ingresos depende completamente de la persona
                            que usa nuestros productos, ideas y técnicas. No posicionamos este producto
                            como un "esquema para hacerse rico". Cualquier reclamo de ganancias reales
                            o ejemplos de resultados reales se puede verificar a pedido. Su nivel de éxito
                            en el logro de los resultados declarados en nuestros materiales depende del
                            tiempo que dedique al programa, las ideas y técnicas mencionadas, sus
                            finanzas, conocimiento y diversas habilidades. Dado que estos factores
                            difieren según las personas, no podemos garantizar su éxito o nivel de
                            ingresos. Tampoco somos responsables de ninguna de sus acciones o
                            resultados.<br/><br/>
                            Los materiales en nuestro producto y nuestro sitio web pueden contener
                            información que incluye o se basa en declaraciones prospectivas en el sentido
                            de la Ley de Reforma de Litigios de Valores Privados de 1995 (Private
                            Securities Litigation Reform Act of 1995). Las declaraciones prospectivas dan
                            nuestras expectativas o pronósticos de eventos futuros. Puede identificar
                            estas declaraciones por el hecho de que no se relacionan estrictamente con 
                            hechos históricos o actuales. Usan palabras como "anticipar", "estimar",
                            "esperar", "proyectar", "pretender", "planear", "creer" y otras palabras y
                            términos de significado similar en relación con una descripción de ganancias
                            potenciales o rendimiento financiero.<br/><br/>
                            Todas y cada una de las declaraciones a futuro aquí o en cualquiera de
                            nuestros materiales de venta están destinadas a expresar nuestra opinión
                            sobre el potencial de ganancias. Muchos factores serán importantes para
                            determinar sus resultados reales y no se hacen garantías de que obtendrá
                            resultados similares a los nuestros o de cualquier otra persona, de hecho, no
                            se garantiza que obtendrá ningún resultado de nuestras ideas y técnicas en
                            nuestro material.</P2>

                        <h3>12. No transferencia de propiedad intelectual</h3>
                        <P2>El Servicio está protegido por la ley de derechos de autor. Todos y cada uno
                            de los materiales originales que se le proporcionan son para su uso individual
                            solo bajo una licencia de usuario único. Usted no está autorizado a utilizar
                            ninguna de nuestra propiedad intelectual para fines comerciales. Toda la
                            propiedad intelectual, incluidos los programas y / o materiales con derechos
                            de autor de NuParla x 90 Days, son propiedad exclusiva de NuParla LLC. No
                            se otorga ni implica ninguna licencia para vender o distribuir nuestros
                            materiales. Al comprar este producto, usted acepta: 1. no infringir ningún
                            derecho de autor, patente, marca registrada, secreto comercial u otros
                            derechos de propiedad intelectual; 2. que cualquier información confidencial
                            compartida por NuParla es confidencial y de propiedad total, y pertenece única
                            y exclusivamente a NuParla; 3. no divulgar dicha información a ninguna otra
                            persona o usarla de cualquier otra manera que no sea en discusión con
                            NuParla. Además, al comprar el Servicio, usted acepta no violar ni mostrar
                            ninguna posibilidad de violar los Términos contenidos en este párrafo. NuParla
                            tiene derecho a utilizar medidas de precaución para prohibir tales violaciones
                            y protegerse contra el daño en el que puedan incurrir tales violaciones.     </P2>

                        <h3>13. NINGUNA REPRESENTACIÓN O GARANTÍA DE NUPARLA</h3>
                        <P2>EL SERVICIO, INCLUYENDO TODAS LAS IMÁGENES, ARCHIVOS DE
                            AUDIO Y OTRO CONTENIDO EN EL MISMO, Y CUALQUIER OTRA
                            INFORMACIÓN, PROPIEDAD Y DERECHOS OTORGADOS O
                            PROPORCIONADOS POR NUPARLA, SE LE PROPORCIONAN "TAL
                            CUAL". NUPARLA Y SUS PROVEEDORES NO OFRECEN
                            REPRESENTACIONES O GARANTÍAS DE NINGÚN TIPO CON RESPECTO
                            AL SERVICIO, EXPRESA O IMPLÍCITA, Y TODAS LAS 
                            REPRESENTACIONES Y GARANTÍAS, INCLUYENDO LAS GARANTÍAS DE
                            COMERCIABILIDAD, IDONEIDAD PARA UN PROPÓSITO EN PARTICULAR
                            O AUSENCIA DE USURPACIÓN SON EXPLÍCITAMENTE NEGADOS. SIN
                            LIMITAR LA GENERALIDAD DE LO ANTERIOR, NUPARLA NO OFRECE
                            NINGUNA REPRESENTACIÓN O GARANTÍA DE NINGÚN TIPO
                            RELACIONADO CON LA EXACTITUD, DISPONIBILIDAD DEL SERVICIO,
                            COMPLETIDAD, CONTENIDO INFORMATIVO, OPERACIÓN LIBRE DE
                            ERRORES, RESULTADOS QUE SE OBTENERÁN DEL USO O POR NO
                            INFRACCIÓN. EL ACCESO Y USO DEL SERVICIO PUEDE NO ESTAR
                            DISPONIBLE DURANTE PERÍODOS DE DEMANDA PICO,
                            ACTUALIZACIONES DEL SISTEMA, MAL FUNCIONAMIENTO O
                            MANTENIMIENTO PROGRAMADO O NO PROGRAMADO O POR OTRAS
                            RAZONES.<br/><br/>
                            El Servicio se desarrolla estrictamente solo con fines educativos. Usted es
                            100% responsable de su propio progreso y resultados. NuParla no hace
                            representaciones o garantías, orales o escritas, ya que los resultados
                            experimentados por cada Estudiante pueden variar significativamente. Usted
                            reconoce que, como con cualquier esfuerzo comercial, existe un riesgo
                            inherente de pérdida de capital y no hay garantía de que alcanzará sus
                            objetivos como resultado de la participación en el Servicio. La educación e
                            información sobre el programa está destinada a una audiencia general y no
                            pretende ser, ni debe interpretarse como ser, un asesoramiento específico a
                            medida de nadie. NuParla no asume ninguna responsabilidad por errores u
                            omisiones que puedan aparecer en los materiales del programa.</P2>

                        <h3>14. LIMITACIÓN DE RESPONSABILIDAD Y TIPOS DE DAÑOS</h3>
                        <P2>EN NINGÚN CASO NUPARLA SERÁ RESPONSABLE CON USTED O
                            CUALQUIER TERCERO QUE RECLAME A TRAVÉS DE USTED (POR BASE
                            EN CONTRATO, AGRAVIO, RESPONSABILIDAD ESTRICTA U OTRA
                            TEORÍA) POR DAÑOS INDIRECTOS, INCIDENTALES, ESPECIALES,
                            CONSECUENTES O EJEMPLARES DERIVADOS O RELACIONADOS CON
                            EL ACCESO O RELACIONADOS CON EL ACCESO O USO O LA
                            INCAPACIDAD DE ACCEDER O UTILIZAR EL SERVICIO O CUALQUIER
                            PARTE DE LOS MISMOS, INCLUIDOS, PERO SIN LIMITARSE A LA
                            PÉRDIDA DE USO DEL SERVICIO, RESULTADOS INCORRECTOS,
                            PÉRDIDA DE BENEFICIOS, INTERRUPCIÓN EMPRESARIAL O DAÑOS DE
                            LA PÉRDIDA O CORRUPCIÓN DE DATOS O DATOS QUE SE ENTREGAN
                            INEXACUTADOS, EL COSTO DE RECUPERAR CUALQUIER DATO, EL
                            COSTO DE LOS SERVICIOS SUSTITUTOS O DE LAS RECLAMACIONES
                            DE TERCEROS POR CUALQUIER DAÑO A LAS COMPUTADORAS, 
                            SOFTWARE, MODEMS, TELÉFONOS U OTRA PROPIEDAD, INCLUSO SI
                            NUPARLA HA SIDO INFORMADO DE DICHA POSIBILIDAD. LA
                            RESPONSABILIDAD DE NUPARLA CON USTED O CUALQUIER TERCERO
                            QUE RECLAME A TRAVÉS DE USTED POR CUALQUIER CAUSA Y SIN
                            IMPORTAR LA FORMA DE LA ACCIÓN, SE LIMITA A LA CANTIDAD
                            PAGADA, SI CUALQUIERA, POR USTED A NUPARLA POR EL SERVICIO
                            EN LOS 12 MESES ANTES DE LA INICIAL ACCIÓN QUE LE DA A LA
                            RESPONSABILIDAD. ESTE ES UN LÍMITE AGREGADO. LA EXISTENCIA
                            DE MÁS DE UNA RECLAMACIÓN AQUÍ NO AUMENTARÁ ESTE LÍMITE.</P2>

                        <h3>15. Terminación</h3>
                        <P2>NuParla puede rescindir su acceso y uso del Servicio inmediatamente en
                            cualquier momento, por incumplimiento de los Términos, y en ese momento
                            no tendrá más derecho a usar el Servicio. Puede cancelar su cuenta de
                            NuParla en cualquier momento siguiendo las instrucciones disponibles a
                            través del Servicio. Las disposiciones de estos Términos y condiciones
                            relacionadas con la protección y el cumplimiento de los derechos de propiedad
                            de NuParla, sus representaciones y garantías, la exención de responsabilidad
                            de representaciones y garantías, exenciones e indemnizaciones, limitaciones
                            de responsabilidad y tipos de daños, propiedad de datos e información, leyes
                            vigentes y lugar, y disposiciones diversas, sobrevivirán a dicha terminación. </P2>

                        <h3>16. Derechos de propiedad en el contenido del servicio y materiales de actividad</h3>
                        <P2>Todo el contenido disponible a través del Servicio, incluidos los diseños, texto,
                            gráficos, imágenes, información, software, audio y otros archivos, y su
                            selección y disposición (el "Contenido del Servicio"), son propiedad de
                            NuParla o sus licenciantes. Ningún contenido del servicio puede ser
                            modificado, copiado, distribuido, enmarcado, reproducido, republicado,
                            descargado, raspado digitalmente, exhibido, publicado, transmitido o vendido
                            de ninguna forma o por ningún medio, en su totalidad o en parte, excepto lo
                            expresamente permitido en estos Términos y Condiciones. No puede utilizar
                            ningún método de extracción de datos, robots, raspado digitales o recopilación
                            de datos o métodos de extracción similares para obtener el Contenido del
                            servicio. Entre usted y NuParla, todos los datos, información y materiales
                            generados a partir de su acceso y uso de las actividades educativas
                            disponibles en o a través del Servicio, incluidos ejercicios, contenido
                            mecanografiado y mensajes de audio generados por usted (colectivamente,
                            los "Materiales de actividad"), será propiedad exclusiva de NuParla, y usted
                            no tendrá ningún derecho a usar dichos Materiales de Actividad, excepto según lo expresamente autorizado por estos Términos y Condiciones. Los
                            materiales de la actividad no incluirán materiales de traducción. Al usar el
                            Servicio, usted asigna a NuParla todos y cada uno de los derechos, títulos e
                            intereses, incluidos los derechos de propiedad intelectual o derechos de
                            propiedad, en los Materiales de la Actividad. Todos los derechos de NuParla
                            o sus licenciantes que no se otorgan expresamente en estos Términos y
                            Condiciones están reservados a NuParla y sus licenciantes. </P2>

                        <h3>17. Marcas registradas</h3>
                        <P2>“NuParla”, “Aprende inglés. Gana dinero. " y todas las demás marcas
                            comerciales, marcas de servicio, gráficos y logotipos utilizados en relación con
                            el Servicio son marcas comerciales o marcas de servicio de NuParla LLC, y
                            algunas de ellas están registradas en la Oficina de Patentes y Marcas de los
                            Estados Unidos. Acceso y uso de El servicio no le otorga ni le otorga el
                            derecho o la licencia para reproducir o utilizar el nombre de NuParla o
                            cualquier marca registrada de NuParla o de terceros, marcas de servicio,
                            gráficos o logotipos. </P2>

                        <h3>18. Privacidad</h3>
                        <P2>El uso del Servicio también se rige por nuestra Política de Privacidad, cuya
                            copia se encuentra en <a href="/privacy">www.nuparla.com/privacy</a>. Al usar el Servicio, usted
                            acepta los términos de la Política de Privacidad. </P2>

                        <h3>19. Ley aplicable y arbitraje; Sin demanda colectiva</h3>
                        <P2>Estos Términos y condiciones, su objeto y los derechos de NuParla y sus
                            respectivos derechos en virtud de estos Términos y Condiciones, así como
                            cualquier reclamo, causa de acción o disputa ("reclamo") que surja de estos
                            Términos y Condiciones o estén relacionados con ellos será gobernado e
                            interpretado bajo las leyes del estado de California, Estados Unidos de
                            América. CUALQUIER DISPUTA O RECLAMACIÓN RELACIONADA DE
                            CUALQUIER FORMA CON ESTOS TÉRMINOS Y CONDICIONES O EL
                            SERVICIO SE RESOLVERÁ POR ARBITRAJE VINCULANTE, EN LUGAR
                            DE EN TRIBUNAL, excepto por el derecho de NuParla a buscar medidas
                            cautelares como se establece a continuación. A menos que la ley aplicable
                            exija expresamente lo contrario, cada parte asumirá los honorarios de sus
                            propios abogados sin importar qué parte se considere la parte prevaleciente
                            en el procedimiento de arbitraje.<br/><br/>
                            Si tiene la intención de solicitar un arbitraje, primero debe enviar un aviso por
                            escrito a la Oficina de Administración de NuParla sobre su intención de arbitrar 
                            ("Aviso"). El aviso a NuParla debe enviarse por correo electrónico a
                            legal@nuparla.com. El Aviso debe (a) describir la naturaleza y la base del
                            reclamo o disputa; y (b) establecer la reparación específica buscada; y (c)
                            establecer su nombre, número de identificación de afiliado, dirección e
                            información de contacto. Si tenemos la intención de solicitar un arbitraje en su
                            contra, le enviaremos cualquier aviso de disputa a la información de contacto
                            que tenemos para usted.<br/><br/>
                            El arbitraje se llevará a cabo ante un árbitro único neutral en el condado de
                            Riverside ubicado en el estado de California, Estados Unidos, cuya decisión
                            será definitiva y vinculante.<br/><br/>    
                            En la mayor medida permitida por la ley aplicable, USTED Y NUPARLA
                            ACUERDAN QUE CUALQUIER PROCEDIMIENTO DE RESOLUCIÓN DE
                            CONTROVERSIAS SERÁ REALIZADO SOLO EN UNA BASE INDIVIDUAL Y
                            NO EN UNA DEMANDA COLECTIVA, CONSOLIDADA O ACCIÓN POR
                            REPRESENTANTE. Si, por algún motivo, un reclamo se lleva a cabo en un
                            tribunal en lugar de un arbitraje, USTED Y NUPARLA CADA UNO
                            RENUNCIAN AL DERECHO A UN JUICIO CON JURADO.</P2>

                        <h3>20. Idioma</h3>
                        <P2>Este acuerdo fue escrito originalmente en inglés (EE. UU.). En la medida en
                            que cualquier versión traducida de este acuerdo entre en conflicto con la
                            versión en inglés, la versión en inglés gobierna.</P2>

                        <h3>21. Varios</h3>
                        <P2>Estos Términos y Condiciones constituyen el acuerdo completo entre NuParla
                            y usted con respecto al tema del presente. En el caso de que un tribunal u otro
                            tribunal de jurisdicción competente considere que alguno de los Términos y
                            Condiciones no se puede hacer cumplir, dichas disposiciones se limitarán o
                            eliminarán en la medida mínima necesaria para que estos Términos y
                            Condiciones permanezcan en plena vigencia y efecto. Una renuncia por parte
                            de NuParla o de usted a cualquier disposición de estos Términos y
                            Condiciones o cualquier incumplimiento de los mismos, en cualquier caso, no
                            renunciará a dicho término o condición o cualquier incumplimiento posterior
                            de los mismos. NuParla puede ceder sus derechos u obligaciones bajo estos
                            Términos y Condiciones sin condición. Estos Términos y Condiciones serán
                            vinculantes y redundarán en beneficio de NuParla y usted, y de NuParla y sus
                            respectivos sucesores y cesionarios permitidos. </P2>

                            











                </div>}
                



            </div>
        )
    }
}



const P = styled.p `
    font-size: medium;
    color: #888;
    text-align: justify;
    
`

const P2 = styled.p `
    font-size: medium;
    color: #888;
    text-align: justify;
    margin-left: 20px;
    
`

const H1 = styled.p `
    font-family: Arial, Helvetica, sans-serif;
    margin: 60px;
    font-size: xx-large;
    font-weight: bold;
`