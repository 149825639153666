import React, { Component } from "react";
import "../../App.css";
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";

export default class slider extends Component {
  constructor() {
    super();
    this.state = {
      selected: "",
    };
  }

  componentDidMount() {
    if (window.location.pathname == "/admin/courses") {
      this.setState({ selected: "courses" });
    } else if (window.location.pathname == "/admin/students") {
      this.setState({ selected: "students" });
    } else if (window.location.pathname == "/admin/tutor") {
      this.setState({ selected: "tutor" });
    } else if (window.location.pathname == "/admin/admin") {
      this.setState({ selected: "admin" });
    } else if (window.location.pathname == "/admin/messages") {
      this.setState({ selected: "messaging" });
    } else if (window.location.pathname == "/admin/events") {
      this.setState({ selected: "events" });
    }
  }

  componentDidUpdate(){
    // if (window.location.pathname == "/admin/courses") {
    //   this.setState({ selected: "courses" });
    // } else if (window.location.pathname == "/admin/students") {
    //   this.setState({ selected: "students" });
    // } else if (window.location.pathname == "/admin/tutor") {
    //   this.setState({ selected: "tutor" });
    // } else if (window.location.pathname == "/admin/admin") {
    //   this.setState({ selected: "admin" });
    // } else if (window.location.pathname == "/admin/messages") {
    //   this.setState({ selected: "messaging" });
    // } else if (window.location.pathname == "/admin/events") {
    //   this.setState({ selected: "events" });
    // }

  }

  render() {
    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: 70,
          margin: 0,
          display: "flex",
          backgroundColor: "white",
          overflow: "scroll",
          overflowY: "hidden",
        }}
      >
        <a href="/admin/courses">
          <p
            className={
              this.state.selected == "courses"
                ? "aprende-btn-active"
                : "aprende-btn"
            }
            onClick={() => {
              this.setState({ selected: "courses" });
            }}
          >
            Courses
          </p>
        </a>

        <Link to="/admin/students">
          <p
            className={
              this.state.selected == "students"
                ? "aprende-btn-active"
                : "aprende-btn"
            }
            onClick={() => {
              this.setState({ selected: "students" });
            }}
          >
            Students
          </p>
        </Link>
        <Link to="/admin/tutors">
          <p
            className={
              this.state.selected == "tutors"
                ? "aprende-btn-active"
                : "aprende-btn"
            }
            onClick={() => {
              this.setState({ selected: "tutors" });
            }}
          >
            Tutors
          </p>
        </Link>
        <Link to="/admin/admin">
          <p
            className={
              this.state.selected == "admin"
                ? "aprende-btn-active"
                : "aprende-btn"
            }
            onClick={() => {
              this.setState({ selected: "admin" });
            }}
          >
            Admin
          </p>
        </Link>
        <Link to="/admin/messages">
          <p
            className={
              this.state.selected == "messaging"
                ? "aprende-btn-active"
                : "aprende-btn"
            }
            onClick={() => {
              this.setState({ selected: "messaging" });
            }}
          >
            Messaging
          </p>
        </Link>
        <Link to="/admin/events">
          <p
            className={
              this.state.selected == "events"
                ? "aprende-btn-active"
                : "aprende-btn"
            }
            onClick={() => {
              this.setState({ selected: "events" });
            }}
          >
            Events
          </p>
        </Link>
      </div>
    );
  }
}

const Grid = styled.div`
  padding: 0px;
  margin: 0px;
  display: grid;
  width: 340px;
  grid-template-columns: 20% 20% 20% 20% 20%;
`;

const Grid50 = styled.div`
  padding: 0px;
  margin: 0px;
  display: grid;
  width: 340px;
  grid-template-columns: 50% 50%;
`;

const P = styled.p`
  margin: 0px;
  padding: 0px;
`;

const Sdiv = styled.div`
  background-color: #d2d2d2;
  border-radius: 5px;
  padding: 4px 10px;
  margin: 5px;
`;
