import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';

import { makeStyles, useTheme, StylesProvider } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";

import LayersIcon from "@material-ui/icons/Layers";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import BusinessIcon from "@material-ui/icons/Business";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DeviceHubRoundedIcon from "@material-ui/icons/DeviceHubRounded";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Popover from "@material-ui/core/Popover";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SurroundSoundIcon from "@material-ui/icons/SurroundSound";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Contract from '../SalesPage/affiliateContract'
import "../../config";
import * as firebase from "firebase";
import "../../App.css";
import "./affiliate.css";
import StatusSlider from "./statusSlider";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import banner from "../../Images/banner.jpg";
import IntroDialog from './intro'
import { Dialog } from "@material-ui/core";

// import profile from './profile'
// import users from './users'
// import companyAccount from './companyAccount'
// import packageDetails from './packageDetails'
// import groups from './groups';

const whiteTheme = createMuiTheme({
  palette: { primary: { main: "#000" }, secondary: { main: "#3A66A7" } },
});

const drawerWidth = 200;

const styles = {
  active: {
    backgroundColor: "#404040",
  },
  gold: {
    color: "#e9c979",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: 1,
    },
    backgroundColor: "black",
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#707070",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [verified, setVerified] = React.useState(false);
  const [selected, setSelected] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [affiliateVerified, setAffiliateVerified] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const drawer = (
    <div>
      <div style={{ backgroundColor: "black" }} color="primary" />
      {/* <a href="/"><img src={logo} style={{height: 40, position: 'absolute', marginTop: -50, marginLeft: 20}} /></a> */}
      <Divider />
      <div className="nav-header">
        <a href="/affiliate/profile">
          {!props.url && <AccountCircleIcon
          style={{
            width: "6vw",
            height: "6vw",
            color: "#bebebe",
            marginTop: "2%",
            cursor: 'pointer'
          }}
        />}
        {
          props.url &&<div style={{width: '6vw', height: '6vw', margin: '2% auto 10px auto', borderRadius: 100, overflow: 'hidden'}}><img src={props.url} style={{
            width: "6vw",
            minHeight: "6vw",
            color: "#bebebe",
            cursor: 'pointer',
          }} />
          </div>
        }
        </a>
        <div>
        <p className="white">Hi, {localStorage.getItem('firstName')}</p>
        </div>
        

      </div>

      <div style={{ width: "100%", textAlign: "center" }}>
        <div
          style={{
            margin: 10,
            height: 112,
            backgroundColor: "#bebebe",
            padding: 2,
            border: '2px solid #800000',
            borderRadius: 5,
            boxShadow: "0px 2px 6px rgba(0,0,0,0.6)",
          }}
        >
          
          <p style={{margin: '1px auto', padding: '1px auto'}}>ID: {props.id}</p>
          <p style={{margin: '1px auto', padding: '1px auto'}}>Rank: {props.rank ? props.rank.capitalize() : props.rank}</p>
          <a href='/affiliate/profile'><p className="nav-btn">My Profile</p></a>
        <a href='/affiliate'><p className="nav-btn" onClick={() => localStorage.clear()}>logout</p></a>

        </div>
      </div>
      <Divider />

      <div style={{ width: "100%", textAlign: "center" }}>
        <h3
          className="white"
          style={{ fontSize: "x-large", margin: "25px 10px", fontFamily: 'Apple Chancery', color: '#edd290' }}
        >
          Gana dinero
        </h3>
      </div>
      {props.affiliate && <List>
        <Divider />

        <a href="/affiliate/commissions">
          <ListItem
            button
            style={selected == "commissions" ? styles.active : null}
            onClick={() => {
              setSelected("commissions");
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <MonetizationOnIcon style={styles.gold} />
            </ListItemIcon>
            <ListItemText primary="Commissions" />
          </ListItem>
        </a>
        <Divider />

        {/* <a href="/affiliate/team-view">
          <ListItem
            button
            style={selected == "team" ? styles.active : null}
            onClick={() => {
              setSelected("team");
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <PeopleAltIcon style={styles.gold} />
            </ListItemIcon>
            <ListItemText primary="Team View" />
          </ListItem>
        </a>
        <Divider /> */}

        

        <a href="/affiliate/marketing">
          <ListItem
            button
            style={selected == "marketing" ? styles.active : null}
            onClick={() => {
              setSelected("marketing");
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <BusinessCenterIcon style={styles.gold} />
            </ListItemIcon>
            <ListItemText primary="Marketing" />
          </ListItem>
        </a>
        <Divider />

        <a href="/affiliate/training">
          <ListItem
            button
            style={selected == "training" ? styles.active : null}
            onClick={() => {
              setSelected("training");
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <LocalLibraryIcon style={styles.gold} />
            </ListItemIcon>
            <ListItemText primary="Training" />
          </ListItem>
        </a>
        <Divider />

        {/* <Link to="/affiliate/training">
          <ListItem
            button
            style={selected == "reports" ? styles.active : null}
            onClick={() => {
              setSelected("reports");
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <AssignmentIcon style={styles.gold} />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItem>
        </Link>
        <Divider /> */}

        <a href="/affiliate/tickets">
          <ListItem
            button
            style={selected == "ticket" ? styles.active : null}
            onClick={() => {
              setSelected("ticket");
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <SurroundSoundIcon style={styles.gold} />
            </ListItemIcon>
            <ListItemText primary="Ticket" />
          </ListItem>
        </a>
        <Divider style={{marginBottom: 100}} />


        
          
    
        

        <a href="/affiliate/invite"
        style={{position: 'fixed', bottom: 0, left: 0, backgroundColor: '#404040', padding: '0px 40px 0px 20px'}}>
          <ListItem

            button
            
            onClick={() => {
              
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <PersonAddIcon style={styles.gold} />
            </ListItemIcon>
            <ListItemText style={{color: 'white'}} primary="Invite" />
          </ListItem>
        </a>
      </List>}

      {!props.affiliate && <a href="/affiliate/agreement"><List>
        <ListItem
            button
            style={selected == "ticket" ? styles.active : null}
            onClick={() => {
             
              
              
            }}
          >
            <ListItemIcon>
              <SurroundSoundIcon style={styles.gold} />
            </ListItemIcon>
            <ListItemText primary="Affiliate" />
          </ListItem>
      </List></a>

      }
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  
  if (affiliateVerified == true){
    return <Redirect to='/affiliate/congratulations' />
  }
  return (
    <Router>
      <MuiThemeProvider theme={whiteTheme}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" color="primary" style={{backgroundColor: 'rgba(0,0,0,0)', boxShadow: 'none'}}>
        {/* <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          
        </Toolbar> */}
      </AppBar>
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <a href="/affiliate" style={{position: 'relative'}}><img src={banner} className="banner" /></a>
          {/* <div className="menuButtonMobile" style={{position: 'fixed',top: 0, left: 0, width: '15vw', height: '15vw', backgroundColor: 'black'}}> */}
          <MenuIcon style={{position: 'absolute',top: 0, left: 0, padding: 10,cursor: 'pointer', margin: 'auto', padding: 10, color: 'white', width: '19vw', height: '19vw', backgroundColor: 'black', paddingLeft: '30px'}} className="menuButtonMobile" onClick={handleDrawerToggle}/>

          {/* </div> */}
          
                {/* <IntroDialog open={dialogOpen} />  */}
          {/* <Dialog
          open={dialogOpen}
          onClose={()=> setDialogOpen(false)}
          fullWidth
          style={{padding: 20}}
          >
            {!verified && <div style={{textAlign: 'center'}}>
            <h2>Become an affiliate!</h2>
            <div style={{width: '90%', margin: 'auto', height: 300, overflow: 'scroll', overflowX: 'hidden'}}>
              <Contract />


            </div>
            <Button variant="contained" style={{width: 'max-content',margin: '10px auto'}} color="secondary" onClick={()=> {
              firebase
              .firestore()
              .collection("Users")
              .doc(localStorage.getItem('email'))
              .update({
                affiliate: true
              }).then(res => {
                console.log(res)
                setVerified(true)
                setAffiliateVerified(true)
                return <Redirect to="/congratulations" />
              })
            }}>Agree and continue</Button>

            </div>}
            {verified && <div style={{textAlign: 'center'}}>
              <h2>Congratulations</h2>
              <h3>You are now an affiliate!</h3>
              <h2 style={{fontSize: 'x-large', color: 'darkblue', fontWeight: 'bold'}}>Understanding NuParla Affiliate Commissions and OTPs</h2>
                    <p>How to get paid as an Affiliate with NuParla</p>

                    <div style={{textAlign: 'left'}}>
                        <p>So you want to be a NuParla Affiliate because you love how we help people like yourself to speak English fluently all around the world. You appreciate our platform and are committed to uplift our values of Integrity, Quality and Productivity.</p>
                        <p>In short, you love NuParla. We provide a commission as a way of saying “Thanks” to you for all you do. We want to partner with you to grow your NuParla team. </p>
                        <p>Here is everything you need to know about earning commissions, and receiving that sweet payout.</p>
                        <h2>Step #1. Set Up PayPal</h2>
                        <p>As a NuParla Affiliate in the US, Latin America or Spain, you can only receive payouts through PayPal. It’s very easy to do. Just follow the these steps:</p>
                        <ol>
                            <li>Visit PayPal’s website and click the Sign Up button in the top-right corner.</li>
                            <li>Select the account type you want — Personal or Business — and click Continue. (Most people sign up with a Personal Account, unless you are a business). </li>
                            <li>Add in your full name, email address, create a password, and after confirming the password, then click Continue.</li>
                            <li>Add in your address and other required info, and then click Continue.</li>
                            <li>Optional: Click the blue Get started button, add in your credit card info, and click Add card. Notice, this is optional. </li>
                            <li>Verify your email address by clicking the link in the email sent to you by PayPal.</li>
                        </ol>
                        <p>Once you’ve done this, you’re all set! Get ready for those direct deposits!</p>

                        <h2>Step #2: Understand Orders-To-Pay (OTPs)</h2>
                        <p>NuParla offers a 7-day refund starting from the day of purchase to all customers. During this time, you will see your customer inside your Holding Tank, next to a time countdown. Once they refund period is up, your customer’s status is now OTP, meaning that the commission due to you will be officially approved. </p>
                        <p>If, however, your customer cancels during the 7-day refund period, their status will be updated inside your holding tank as cancelled, they will receive their refund and you will receive no commission. </p>
                        <p>Affiliate pending commissions are approved weekly on Wednesdays for all the referral work you did for the previous seven days (Wednesday to Wednesday). We send the report and funds to PayPal so ensure it hits your PayPal account on Fridays. It’s that simple. </p>
                        <p>So, for example, if you recruit a paying customer on Thursday December 1, their 7-day refund period will expire Thursday December 8. If they did not cancel, then on Friday December 9 they become OTP approved. They will be on the report that goes to PayPal the following Wednesday, December 13, and you receive your commission on Friday, December 15. </p>
                        <p>There is no commission threshold. You earn what you worked. </p>
                        <p>If you are ever denied commissions, it’s because:</p>
                        <ol>
                            <li>The customer canceled their account during the 7-day refund period; or</li>
                            <li>We made a mistake. If this happens, click on Tickets inside your Back Office and send us a message. We’ll gladly correct the payment status to OTP approved. </li>
                        </ol>
                        <p>If in an extreme case a customer cancels after their 7-day refund period (after we paid you a commission) and we decide to honor that cancelation, the commissions we paid you for that special-case cancellation will be deducted from any future commissions owed you. We will contact you in this extreme case so you become aware. Like you, we don’t like any surprises. Communication is key.</p>
                        <h2>Step #3: Build Your NuParla Team!</h2>
                        <ol>
                            <li>Be a product of the product. As a NuParla Student, your speaking English fluently is vital, because YOU are the product. It’s your time to shine!</li>
                            <li>Harness the power of your unique link. Do 3 clicks: Invite – WhatsApp (for example) – select Prospect. Your success depends on how many 3-click invitations you do per hour, every day! </li>
                            <li>The NuParla.com website has the videos, testimonials and spells out everything for your Prospect. You just smile, and let them hear you tell them your story in English. It’s that easy!</li>
                            <li>Follow-up is KEY! The fortune is not made in invitation, as much as in the follow-up. Make sure to take the NuParla Sales Course inside your Training folder for more information.</li>
                            <li>Be active in social media. Use our NuParla marketing posts for the different holidays and special thoughts as presented by NuParla. Post, post, post!</li>
                            <li>Join the weekly Facebook “Live” training events. </li>
                            <li>Repeat steps 1 through 6 and watch your team grow. (Yes, it’s all about your team!) </li>

                        </ol>
                        <p>Si acabas de comenzar con nosotros y quieres aprender el inglés más rápido, te recomiendo que instales el plugin de Reverso en tu navegador (Chrome es el más fácil) y subraya las palabras or frases en estas instrucciones para crecer en tu descubrimiento del inglés.</p>
                        <p>We hope this inspires you on to greatness.</p>
                        <p>-Harold Zapata, Founder & CEO</p>
                    </div>
              <Button variant="contained" style={{width: 'max-content',margin: '10px auto'}} color="secondary" onClick={()=> {
              setDialogOpen(false)
             
            }}>Ok</Button>


            </div>

            }
            
          </Dialog> */}

          <Switch>
            {/* <Route exact path="/admin/company-account" component={companyAccount} />
          <Route exact path="/admin/package-details" component={packageDetails} />
          <Route path="/admin/users" component={users} />
          <Route path="/admin/groups" component={groups} /> */}
          </Switch>
        </div>
      </MuiThemeProvider>
    </Router>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
