import React, { Component } from "react";
import  '../../../App.css'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import { TextField, Button } from "@material-ui/core";
import * as firebase from "firebase";
import "../../../config" ;
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';


export default class reports extends Component {
    constructor(){
        super();
        this.state = {
            users: [],
            dailyNew: [],
            cancelled: [],
            audios: [],
            openTickets: [],
            closedTickets: [],
            repliedTickets: [],
            tickets: [],
            advanced: []

           

        }
    }

    componentDidMount(){
        let {dailyNew, cancelled} = this.state;
        dailyNew = []

        firebase.firestore().collection('Users').get().then(res => {
            console.log(res.docs)
            let {advanced} = this.state;
            this.setState({users: res.docs})
            let today = Date.now()
            today = today - 86400000;
            res.docs.forEach(user => {
                console.log(user.data())
                console.log(today)
                if (user.data().date > today){
                    dailyNew.push(user.data())
                }
                if (user.data().status == 'cancelled'){
                    cancelled.push(user.data())
                }
                if (user.rankAdvanceBonus){
                    console.log('advance')
                    user.rankAdvanceBonus.forEach(rankAdvance => {
                        if (rankAdvance.date > today){
                            advanced.push({
                                user: user,
                                advance: rankAdvance
                            })
    
                        }
                    })

                }
                let totalEarnings = 0;
               

                

            })
            this.setState({dailyNew, cancelled, advanced})

            
            
        })

        firebase.firestore().collection('Tickets').get().then(res => {
          
            let tickets = []
            let today = Date.now()

            today = today - 86400000;
            let {openTickets, closedTickets, repliedTickets} = this.state;
            res.docs.forEach(ticket => {
                if (ticket.data().date > today){
                    tickets.push(ticket.data())
                }
                if (ticket.data().status == 'open'){
                    openTickets.push(ticket)
                    if (ticket.data().chat){
                        ticket.data().chat.forEach(message => {
                            if (message.sender_type == 'admin' && message.timestamp > today){
                                repliedTickets.push(ticket)
                            }
                        })

                    }
                    
                }
                if (ticket.data().closedDate > today){
                    closedTickets.push(ticket)
                }
               

            })
            
            

            this.setState({tickets: tickets, openTickets, closedTickets, repliedTickets})

            
            
        })

        firebase.firestore().collection('WhyAudios').get().then(res => {
            let audios = []
            console.log(res.docs)
            res.docs.forEach(audio => {
                
                firebase.firestore().collection('Users').doc(audio.id).get().then(res => {
                    audios.push({
                        audio: audio.data(),
                        user: res.data()
                        
                    })
                    this.setState({audios: audios})
                    console.log(this.state.audios)

                })
                
            })
        })
        
       

    }

    

    timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year ;
        return time;
      }


    render(){ 

        return (
            <div>
                <h1>Reports</h1>

                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <Paper elevation={1} style={{padding: 10, margin: 10, height: 400, width: 500}}>
                        <h3>Daily New Affiliates ({this.state.dailyNew.length})</h3>
                        {/* <div style={{overflow: 'scroll',overflowX: 'hidden', height: 350}}>
                            {this.state.dailyNew.map(user => {
                                return (
                                    <div style={{margin: '2px auto', borderRadius: 4, width: '90%', backgroundColor: '#ebebeb'}}>
                                        <p style={{padding: 4}}>{user.name}</p>

                                    </div>
                                    

                                )
                            })}
                        </div> */}

                        <TableContainer component={Paper} style={{width: '90%', margin: '10px auto', height: 340}}>
                        <Table >
                            <TableHead>
                            <TableRow style={{backgroundColor: '#add8e6'}}>
                                <TableCell style={{fontWeight: 'bold'}}>Affiliates</TableCell>
                                <TableCell style={{fontWeight: 'bold'}} align="right">User Name</TableCell>
                                <TableCell style={{fontWeight: 'bold'}} align="right">Country</TableCell>
                                <TableCell style={{fontWeight: 'bold'}} align="right">Payment</TableCell>
                                
                                
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.dailyNew.map((user) => (
                                <TableRow style={{cursor: 'pointer'}} onClick={()=>{
                                    
                                }}>
                                <TableCell component="th" scope="row">
                                    {user.name}
                                </TableCell>
                                <TableCell align="right">{user.userName}</TableCell>
                                <TableCell align="right">{user.address.country}</TableCell>
                                <TableCell align="right">$399</TableCell>
                              
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>

                    </Paper>
                    <Paper elevation={1} style={{padding: 10, margin: 10, height: 400, width: 250}}>
                    <h3>Cancelled ({this.state.cancelled.length})</h3>
                        <div style={{overflow: 'scroll',overflowX: 'hidden', height: 350}}>
                            {this.state.cancelled.map(user => {
                                return (
                                    <div style={{margin: '2px auto', borderRadius: 4, width: '90%', backgroundColor: '#ebebeb'}}>
                                        <p style={{padding: 4}}>{user.name}</p>

                                    </div>
                                    

                                )
                            })}
                        </div>

                    </Paper>
                    <Paper elevation={1} style={{padding: 10, margin: 10, height: 400, width: 500}}>
                    <h3><b>WHY</b> audios</h3>

                    <TableContainer component={Paper} style={{width: '95%',height: 340, overflow: 'scroll', margin: '10px auto'}}>
                        <Table >
                            <TableHead>
                            <TableRow style={{backgroundColor: '#add8e6'}}>
                                <TableCell style={{fontWeight: 'bold'}}>Affiliates</TableCell>
                                
                                <TableCell style={{fontWeight: 'bold'}} align="right">Audio</TableCell>
                                
                                
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.audios.map((audio) => (
                                <TableRow style={{cursor: 'pointer'}} onClick={()=>{
                                    
                                }}>
                                <TableCell component="th" scope="row">
                                    {audio.user.name}
                                </TableCell>
                               
                                <TableCell align="right">
                                    <audio src={audio.audio.whyAudio} style={{padding: 10, margin: 'auto' }} controls="controls" />
                                </TableCell>
                              
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>

                    </Paper>
                    <Paper elevation={1} style={{padding: 10, margin: 10, height: 400, width: 250}}>
                    <h3>Tickets ({this.state.cancelled.length})</h3>
                        <p>Created Today: <b>{this.state.tickets.length}</b></p>
                        <p>Closed Today: <b>{this.state.closedTickets.length}</b></p>
                        <p>Replied Today: <b>{this.state.repliedTickets.length}</b></p>
                        <p>Remaining Open: <b>{this.state.openTickets.length}</b></p>

                    </Paper>
                    <Paper elevation={1} style={{padding: 10, margin: 10, height: 400, width: 500}}>
                    <h3>Users advanced to next rank</h3>

                    <TableContainer component={Paper} style={{width: '90%', margin: '10px auto'}}>
                        <Table >
                            <TableHead>
                            <TableRow style={{backgroundColor: '#add8e6'}}>
                                <TableCell style={{fontWeight: 'bold'}}>Affiliates</TableCell>
                                
                                <TableCell style={{fontWeight: 'bold'}} align="right">Advanced to:</TableCell>
                                
                                
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.advanced.map((user) => (
                                <TableRow style={{cursor: 'pointer'}} onClick={()=>{
                                    
                                }}>
                                <TableCell component="th" scope="row">
                                    {user.user.name}
                                </TableCell>
                               
                                <TableCell align="right">
                                    {user.rankAdvance.rank}
                                </TableCell>
                              
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>

                    </Paper>

                </div>
                

            </div>
        )

    }
} 

