import React, { Component } from "react";
import  '../../../App.css'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import { TextField, Button } from "@material-ui/core";
import * as firebase from "firebase";
import "../../../config" ;
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';


export default class financials extends Component {
    constructor(){
        super();
        this.state = {
            users: [],
            otp: [],
            bitcoinUsers: []
           

        }
    }

    componentDidMount(){
        let {otp, bitcoinUsers} = this.state;
        firebase.firestore().collection('Users').get().then(res => {
            console.log(res.docs)
            this.setState({users: res.docs})
            
            res.docs.forEach(user => {
                if (user.data().paymentMethod == 'btc'){
                    bitcoinUsers.push(user.data())
                }
                console.log(user.data())
                let list = user.data().first.concat(user.data().second, user.data().third, user.data().fourth, user.data().fifth)
                list.forEach(user => {
                    if ((Date.now() - user.date) >= 592200000 ){
                        otp.push(user)

                    }
                })

            })

            this.setState({otp, bitcoinUsers})
            
        })
       

    }

    earnings(user){
        let earnings = 0;
        if (user.first){
            let list = user.first.concat(user.second, user.third, user.fourth, user.fifth)
        list.forEach(user => {
            earnings = earnings + user.commission;
        })

        if (user.rankAdvanceBonus){
            user.rankAdvanceBonus.forEach(bonus => {
                earnings = earnings + bonus.bonus;
            })
        }
        return earnings;

        }
        
    }


    render(){ 
        
        let {bitcoinUsers} = this.state;
        return (

            <div>
                <h1>Financials</h1>
                <h2 style={{float: 'left', marginLeft: '10%'}}>Order To Pay:</h2>
                <TableContainer component={Paper} style={{width: '90%', margin: '10px auto', height: 400, overflow: 'scroll', overflowX: 'hidden'}}>
                    <Table >
                        <TableHead>
                        <TableRow style={{backgroundColor: '#add8e6'}}>
                            <TableCell style={{fontWeight: 'bold'}}>User</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} align="right">Commission</TableCell>
                            
                            
                            
                        </TableRow>
                        </TableHead>
                        <TableBody style={{height: 600, overflow: 'scroll'}}>
                        {this.state.otp.map((user) => (
                            <TableRow style={{cursor: 'pointer',backgroundColor: '#ffffe0'}} onClick={()=>{
                               
                            }}>
                            <TableCell component="th" scope="row">
                                {user.email}
                            </TableCell>
                            <TableCell align="right">${user.commission}</TableCell>
                            
                          
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>








                    <h2 style={{float: 'left', marginLeft: '10%'}}>Bitcoin Payments:</h2>
                    <TableContainer component={Paper} style={{width: '90%', margin: '10px auto', height: 400, overflow: 'scroll', overflowX: 'hidden'}}>
                        <Table >
                            <TableHead>
                            <TableRow style={{backgroundColor: '#add8e6'}}>
                                <TableCell style={{fontWeight: 'bold'}}>User</TableCell>
                                <TableCell style={{fontWeight: 'bold'}} align="right">Payment Status</TableCell>
                                
                                
                                
                            </TableRow>
                            </TableHead>
                            <TableBody style={{height: 600, overflow: 'scroll'}}>
                            {bitcoinUsers.map((user) => (
                                <TableRow style={{cursor: 'pointer',backgroundColor: '#ffffe0'}} onClick={()=>{
                                
                                }}>
                                <TableCell component="th" scope="row">
                                    {user.email}
                                </TableCell>
                                <TableCell >
                                    {/* <div style={{width: '100%', textAlign: 'right', float: 'right'}}>  */}
                                    {user.paid == true && <p style={{padding: 10, float: 'right', borderRadius: 5, backgroundColor: 'green', color: 'white'}} onClick={() => {

                                    }}>Verified</p>}
                                    {user.paid == false && <p style={{padding: 10, float: 'right', borderRadius: 5, backgroundColor: 'orange', color: 'white'}} onClick={() => {
                                        firebase
                                        .firestore()
                                        .collection("Users")
                                        .doc(user.email)
                                        .update({
                                          paid: true
                                        })
                                        user.paid = true;
                                        this.setState({bitcoinUsers})

                                    }}>Not Verified</p>}

                                    {/* </div> */}
                                    
                                </TableCell>
                                
                            
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>


            </div>
        )

    }
} 