import React, {Component} from 'react';
import '../../App.css';
import styled from 'styled-components';

import logoblack from '../../Images/nuparla em1 .1 w.jpg'
import details from '../../Images/details.png'
import logogold from '../../Images/nuparla em1 .1.png'


export default class terms extends Component {
    constructor(){
        super();
        this.state = {
            lang: 'spanish'
        }
    }

    componentDidMount(){

    }

    render(){
        
        return (
            <div style={{maxWidth: 1280, margin: '20px auto'}}>
                <div style={{height: '100px'}}>
                    <a href="/"><img src={logoblack} className="logo-top" /></a>
                    <div style={{display: 'flex', flexWrap: 'wrap', float: 'right'}}>
                    <p className="p10 pointer phover " onClick={()=>{
                        if (this.state.lang == 'english'){
                            this.setState({lang: 'spanish'})
                        } else {
                            this.setState({lang: 'english'})
                        }
                    }}>{this.state.lang == 'english' ? "Spanish" : this.state.lang == "spanish" ? "English" : 'English'}</p>
                        

                    </div>
                </div> 
                
                {this.state.lang == 'english' && <div style={{width: '90%', margin: 'auto'}}>
                    <H1 style={{width: '90%', margin: 'auto', textAlign: 'center'}}>TERMS AND CONDITIONS</H1>
                    <P >IMPORTANT – PLEASE CAREFULLY READ AND UNDERSTAND THESE
                        TERMS AND CONDITIONS OF USE & SALE (THESE “TERMS”) BEFORE
                        ACCESSING, USING, OR SUBSCRIBING OR PLACING AN ORDER OVER
                        WWW.NUPARLA.COM OR ANY OTHER OF OUR SITES WHICH LINK TO
                        THESE TERMS.<br/><br/>
                        THESE TERMS CONTAIN DISCLAIMERS OF WARRANTIES AND
                        LIMITATIONS OF LIABILITIES INCLUDING ARBITRATION AND CLASS
                        ACTION WAIVER PROVISIONS THAT WAIVE YOUR RIGHT TO A COURT
                        HEARING, RIGHT TO A JURY TRIAL, AND RIGHT TO PARTICIPATE IN A
                        CLASS ACTION. ARBITRATION IS MANDATORY AND IS THE EXCLUSIVE
                        REMEDY FOR ANY AND ALL DISPUTES UNLESS SPECIFIED BELOW. THESE
                        TERMS FORM AN ESSENTIAL BASIS OF OUR AGREEMENT.<br/><br/>
                        THIS IS A BINDING AGREEMENT. THESE TERMS TOGETHER WITH OUR
                        PRIVACY STATEMENT AND DATA PROCESSING ADDENDUM FORM A
                        LEGALLY BINDING AGREEMENT (THE “AGREEMENT”) BETWEEN YOU AND
                        NUPARLA LLC.</P>


                        <h3>1. General</h3>
                        <P2>NuParla websites (www.nuparla.com, www.aprendeinglésganadinero.com, and
                            others, “Websites”), its mobile applications, (“Apps”), the course NuParla x 90
                            Days, NuParla University and related services (collectively the “Service”) are
                            operated exclusively by NuParla LLC, a California-registered limited liability
                            company (“NuParla,” “us,” or “we”). Access and use of the Service is subject to
                            the following Terms and Conditions of Service (the "Terms" or “Terms and
                            Conditions”). You ("Student," "Affiliate," "you"), by accessing or using any part
                            of the Service, represent that you have read, understood, and agree to be bound
                            by these Terms and Conditions including any future modifications. </P2>

                        <h3>2. Description of Website and Service</h3>
                        <P2>The Service allows users to access and use a variety of educational services,
                            including learning or practicing English through the NuParla x 90 Days course.
                            The course has three payment options:<br/><br/>
                            Option #1: A one-time (1) payment of $399;<br/><br/>
                            Option #2: Two (2) monthly payments US $249 (first payment due at the time of
                            purchase of US $249 and another payment 30 days after for US $249), total:
                            US $498;<br/><br/>
                            Option #3: Three (3) monthly payments of US $199. (first payment due at the
                            time of purchase of US $199 and another two monthly payment 30 days and 60
                            days respectively for US $199 each), total: US $597.<br/><br/>
                            Should you choose options #2 or #3, you further acknowledge and agree that
                            payments are due on a recurring basis in accordance with the payment terms
                            for the Service purchased and therefore authorize the automatic payment
                            collection terms applicable on a monthly basis and for a specific amount. You
                            are responsible for the monthly payments, (unless the Service is cancelled in
                            accordance with these Terms). Otherwise, NuParla reserves the right to
                            suspend all access to the Service temporarily or permanently if payments are
                            not made, or if the card is declined, and that upon payment, such Service will
                            be restored. Questions can be addressed online to our Customer Service team
                            at apoyo@nuparla.com.<br/><br/>
                            Lifetime access to the NuParla x 90 Days course, and NuParla University, is
                            defined as the useful life of the product, which means that as long as you have
                            fulfilled all payment obligations and have complied with the terms of service, you
                            will continue to have access to the course until it is suspended, the website is
                            no longer in service, or the company closes or files for bankruptcy, whichever
                            occurs first. NuParla may, in its sole discretion and at any time, update, change,
                            suspend, make improvements to or discontinue any aspect of the Service.</P2>

                        <h3>3. Registration; Submission of Content</h3>
                        <P2><u><i>a. Registration</i></u><br/><br/>
                            In connection with registering for and using the Service, you agree 1. to provide
                            accurate, current and complete information about you as requested by NuParla;
                            2. to maintain the confidentiality of your password and other information related
                            to the security of your account; 3. to maintain and promptly update any
                            registration information you provide to NuParla, to keep such information
                            accurate, current and complete; and 4. to be fully responsible for all use of your
                            account and for any actions that take place through your account.<br/><br/>
                            <u><i>b. General Content</i></u><br/><br/>
                            As a condition of submitting any ratings, reviews, information, data, text,
                            photographs, audio/video clips, audiovisual works, postings or other materials
                            on or about the Services (“Content”), you hereby grant to NuParla a royalty free,
                            perpetual, irrevocable, worldwide, nonexclusive, transferable, and sublicensable license to use, reproduce, copy, adapt, modify, merge, distribute,
                            publicly display, create derivative works from, incorporate such Content into
                            other works. You represent that you own or have secured all legal rights
                            necessary for the Content submitted by you to be used by you. You understand
                            that other users will have access to the Content and that neither they or NuParla
                            have any obligation to you or anyone else to maintain the confidentiality of the
                            Content you submitted.</P2>


                        <h3>4. Inappropriate Use</h3>
                        <P2>You will not upload, display or otherwise provide on or through the Service any
                            content that: 1. is libelous, defamatory, abusive, threatening, harassing, hateful,
                            offensive or otherwise violates any law or infringes upon the right of any third
                            party (including copyright, trademark, privacy, publicity or other personal or
                            proprietary rights); or 2. in NuParla’s sole judgment, is objectionable or which
                            restricts or inhibits any other person from using the Service or which may expose
                            NuParla or its users to any harm or liability of any kind. </P2>


                        <h3>5. Indemnification of NuParla</h3>
                        <P2>You agree to defend, indemnify and hold harmless NuParla and its directors,
                            officers, employees, contractors, agents, suppliers, licensors, successors and
                            assigns, from and against any and all losses, claims, causes of action,
                            obligations, liabilities and damages whatsoever, including attorneys' fees,
                            arising out of or relating to your access or use of the Service, any false
                            representation made to us (as part of these Terms and Conditions or otherwise),
                            your breach of any of these Terms and Conditions, or any claim that any
                            translation we provide to you is inaccurate, inappropriate or defective in any way
                            whatsoever.</P2>


                        <h3>6. License to Apps</h3>
                        <P2>Subject to the terms of these Terms and Conditions, NuParla grants you a nontransferable, non-exclusive license to download, install, and use one copy of
                            each App in object code form only on an interactive wireless device that you 
                            own or control. You may not derive or attempt to derive the source code of all
                            or any portion of any App, permit any third party to derive or attempt to derive
                            such source code, or reverse engineer, decompile, disassemble, or translate
                            any App or any part thereof. NuParla and its licensors own and shall retain all
                            intellectual property rights and other rig.</P2>

                        <h3>7. Payment Processors</h3>
                        <P2>All financial transactions made in connection with the Service will be processed
                            by a third party in accordance with their respective terms of use, privacy policy,
                            and/or any applicable payment terms and conditions. We encourage you to
                            learn about the practices of such third party. In no event will NuParla be
                            responsible for the actions or inactions of any third party payment processor,
                            including, but not limited to, system downtime or payment service outages.</P2>

                        <h3>8. Refund Policy</h3>
                        <P2>NuParla offers a 7-day satisfaction guarantee, starting the day the Service was
                            purchased, provided that you 1. watched the Introduction: Subconscious
                            English; and 2. completed the first two lessons in their entirety; and 3. did not
                            consume more than 25% of the course video content. In the event that NuParla
                            suspends or terminates your use of the Service or you close your account
                            voluntarily, you understand and agree that you will receive no refund or
                            exchange of any kind after the 7-day refund period. We use a data provider that
                            provides the exact course usage metrics.<br/><br/>
                            Requested reimbursements within a 24-hour period following the purchase will
                            require additional verification in order to prevent fraud and cyber-piracy. No
                            reimbursements shall be given if paid with cash.<br/><br/>
                            In the event that you decide your purchase was not the right decision for you,
                            you can contact our customer service team at apoyo@nuparla.com within 7
                            calendar days of the purchase, and request such refund. To qualify for the
                            refund, you must provide evidence of having watched and interacted with the
                            course, as mentioned above. Failure to include such evidence along with the
                            refund request within the 7-day refund time period will result in no refund, as all
                            refund requests require evidence of the work the Student did throughout the
                            course. This refund policy does not apply to any additionally purchased personal
                            NuParla coaching. </P2>

                        <h3>9. Affiliate Program; Online Marketing</h3>
                        <P2>If you choose to share the Service with others, you will have the opportunity to
                            receive a commission of $100 when another person (a “Prospect”) creates a
                            NuParla account by using your Affiliate Link. Such commission is paid via
                            PayPal on the first Friday after the seven (7) day refund period expires, the
                            Prospect makes payment, and a “Sale” is registered.<br/><br/>
                            You agree to provide certain information (such as, but not necessarily limited to
                            a completed W-9 for US residents). If you do not complete the requisite
                            information within one hundred and twenty (120) days of earning your first
                            commission, you will be deemed to have permanently waived all rights to further
                            commissions. If you are not a resident of the United States, taxes (including
                            without limitation VAT) may be withheld where required by applicable law. You
                            are solely responsible for complying with all tax laws in your respective
                            jurisdiction or country of residence including, but not limited to, the payment of
                            all required taxes to, and filing of all returns and other required documents with,
                            the applicable governing body.<br/><br/>
                            NuParla may offer you an opportunity to become an independent NuParla
                            affiliate (“Affiliate,” "NuParlero"), wherein you have the opportunity to earn
                            additional money for NuParla accounts that you sell to other users subject to
                            your acceptance of the terms of the NuParla Affiliate agreement (the “Affiliate
                            Agreement”). NuParla reserves the sole and exclusive right to determine the
                            amount of remuneration each Affiliate will receive in exchange for the Affiliate’s
                            efforts. Affiliate commission is further discussed in the Affiliate Agreement.<br/><br/>
                            If you wish to grow your reach by social media marketing campaigns through
                            ads, such as those found in Facebook ads, YouTube ads, etc., they must be
                            done through your own personal social media accounts, and disclose the fact
                            that you are being compensated for referring and registering new persons onto
                            the Service. The creation of profiles, fan pages, or websites that use the name
                            NuParla or "Aprende inglés. Gana dinero." or the use of our logo is strictly
                            prohibited.</P2>

                        <h3>10. Independent Contractor Status</h3>
                        <P2>For avoidance of doubt, Affiliates are independent contractors and are not
                            employees or agents of NuParla. Affiliates have no authority to act on behalf of
                            or bind NuParla in any way. Affiliates shall be solely and exclusively responsible
                            for all costs and other expenses incurred. Nothing in this Agreement should be
                            construed as creating a partnership, venture alliance, or any other similar
                            relationship. Each party shall be an independent contractor in its performance 
                            hereunder and shall retain control over its personnel and the manner in which
                            such personnel perform hereunder. In no case shall such persons be
                            considered employees of the other party by virtue of their participation or
                            performance hereunder.</P2>


                        <h3>11. Earning Disclosure</h3>
                        <P2>There is no guarantee that you will earn any money with NuParla, or by using
                            the techniques and ideas in the Service marketing materials. Examples in these
                            materials are not to be interpreted as a promise or guarantee of earnings.
                            Earning potential is entirely dependent on the person using our product, ideas
                            and techniques. We do not position this product as a "get rich scheme." Any
                            claims made of actual earnings or examples of actual results can be verified
                            upon request. Your level of success in attaining the results claimed in our
                            materials depends on the time you devote to the program, ideas and techniques
                            mentioned, your finances, knowledge and various skills. Since these factors
                            differ according to individuals, we cannot guarantee your success or income
                            level. Nor are we responsible for any of your actions or results.<br/><br/>
                            Materials in our product and our website may contain information that includes
                            or is based upon forward-looking statements within the meaning of the Private
                            Securities Litigation Reform Act of 1995. Forward-looking statements give our
                            expectations or forecasts of future events. You can identify these statements by
                            the fact that they do not relate strictly to historical or current facts. They use
                            words such as "anticipate," "estimate," "expect," "project," "intend," "plan,"
                            "believe," and other words and terms of similar meaning in connection with a
                            description of potential earnings or financial performance.<br/><br/>
                            Any and all forward looking statements here or on any of our sales material are
                            intended to express our opinion of earnings potential. Many factors will be
                            important in determining your actual results and no guarantees are made that
                            you will achieve results similar to ours or anybody else’s, in fact no guarantees
                            are made that you will achieve any results from our ideas and techniques in our
                            material.</P2>

                        <h3>12. Non-transfer of Intellectual Property</h3>
                        <P2>The Service is protected by copyright law. Any and all original materials
                            provided to you are for your individual use only under a single-user license. You
                            are not authorized to use any of our intellectual property for the commercial
                            purposes. All intellectual property, including NuParla x 90 Days' copyrighted
                            course programs and/or materials, are the sole property of NuParla LLC. No
                            license to sell or distribute our materials is granted or implied. By purchasing
                            this product, you agree: 1. not to infringe any copyright, patent, trademark, trade
                            secret or other intellectual property rights; 2. that any Confidential Information
                            shared by NuParla is confidential and wholly owned, and belongs solely and
                            exclusively to NuParla; 3. not to disclose such information to any other person
                            or to use it in any way other than in discussion with the NuParla. Furthermore,
                            by purchasing the Service, you agree not to violate, or show any possibility of
                            violating, the Terms contained in this paragraph. NuParla is entitled to use
                            precautionary measures to prohibit such violations and protect against harm that
                            such violations could incur. </P2>

                        <h3>13. NO REPRESENTATIONS OR WARRANTIES BY NUPARLA</h3>
                        <P2>THE SERVICE, INCLUDING ALL IMAGES, AUDIO FILES AND OTHER
                            CONTENT THEREIN, AND ANY OTHER INFORMATION, PROPERTY AND
                            RIGHTS GRANTED OR PROVIDED TO YOU BY NUPARLA ARE PROVIDED
                            TO YOU ON AN “AS IS” BASIS. NUPARLA AND ITS SUPPLIERS MAKE NO
                            REPRESENTATIONS OR WARRANTIES OF ANY KIND WITH RESPECT TO
                            THE SERVICE, EITHER EXPRESS OR IMPLIED, AND ALL SUCH
                            REPRESENTATIONS AND WARRANTIES, INCLUDING WARRANTIES OF
                            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NONINFRINGEMENT, ARE EXPRESSLY DISCLAIMED. WITHOUT LIMITING THE
                            GENERALITY OF THE FOREGOING, NUPARLA DOES NOT MAKE ANY
                            REPRESENTATION OR WARRANTY OF ANY KIND RELATING TO
                            ACCURACY, SERVICE AVAILABILITY, COMPLETENESS, INFORMATIONAL
                            CONTENT, ERROR-FREE OPERATION, RESULTS TO BE OBTAINED FROM
                            USE, OR NON-INFRINGEMENT. ACCESS AND USE OF THE SERVICE MAY
                            BE UNAVAILABLE DURING PERIODS OF PEAK DEMAND, SYSTEM
                            UPGRADES, MALFUNCTIONS OR SCHEDULED OR UNSCHEDULED
                            MAINTENANCE OR FOR OTHER REASONS.<br/><br/>
                            The Service is developed strictly for educational purposes only. You are 100%
                            responsible for your own progress and results. NuParla makes no
                            representations, warranties or guarantees, oral or in writing, as the results 
                            experienced by each Student can vary significantly. You acknowledge that, as
                            with any business endeavor, there is an inherent risk of capital loss and there is
                            no guarantee that you will achieve your objectives as a result of participation in
                            the Service. The education and information on the program is intended for a
                            general audience and is not intended to be, nor should it be construed as,
                            specific advice tailored to anyone. NuParla assumes no responsibility for errors
                            or omissions that may appear in the program materials.</P2>

                        <h3>14. LIMITATION OF LIABILITY AND TYPES OF DAMAGES</h3>
                        <P2>IN NO EVENT WILL NUPARLA BE LIABLE TO YOU OR ANY THIRD PARTY
                            CLAIMING THROUGH YOU (WHETHER BASED IN CONTRACT, TORT,
                            STRICT LIABILITY OR OTHER THEORY) FOR INDIRECT, INCIDENTAL,
                            SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING OUT OF
                            OR RELATING TO THE ACCESS OR USE OF, OR THE INABILITY TO
                            ACCESS OR USE, THE SERVICE OR ANY PORTION THEREOF,
                            INCLUDING BUT NOT LIMITED TO THE LOSS OF USE OF THE SERVICE,
                            INACCURATE RESULTS, LOSS OF PROFITS, BUSINESS INTERRUPTION,
                            OR DAMAGES STEMMING FROM LOSS OR CORRUPTION OF DATA OR
                            DATA BEING RENDERED INACCURATE, THE COST OF RECOVERING ANY
                            DATA, THE COST OF SUBSTITUTE SERVICES OR CLAIMS BY THIRD
                            PARTIES FOR ANY DAMAGE TO COMPUTERS, SOFTWARE, MODEMS,
                            TELEPHONES OR OTHER PROPERTY, EVEN IF NUPARLA HAS BEEN
                            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NUPARLA’S
                            LIABILITY TO YOU OR ANY THIRD PARTY CLAIMING THROUGH YOU FOR
                            ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE
                            ACTION, IS LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO NUPARLA
                            FOR THE SERVICE IN THE 12 MONTHS PRIOR TO THE INITIAL ACTION
                            GIVING RISE TO LIABILITY. THIS IS AN AGGREGATE LIMIT. THE
                            EXISTENCE OF MORE THAN ONE CLAIM HEREUNDER WILL NOT
                            INCREASE THIS LIMIT.</P2>

                        <h3>15. Termination</h3>
                        <P2>NuParla may terminate your access and use of the Service immediately at any
                            time, for breach of Terms, and at such time you will have no further right to use
                            the Service. You may terminate your NuParla account at any time by following
                            the instructions available through the Service. The provisions of these Terms
                            and Conditions relating to the protection and enforcement of NuParla’s
                            proprietary rights, your representations and warranties, disclaimer of 
                            representations and warranties, release and indemnities, limitations of liability
                            and types of damages, ownership of data and information, governing law and
                            venue, and miscellaneous provisions shall survive any such termination. </P2>

                        <h3>16. Proprietary Rights in Service Content and Activity Materials</h3>
                        <P2>All content available through the Service, including designs, text, graphics,
                            images, information, software, audio and other files, and their selection and
                            arrangement (the "Service Content"), are the proprietary property of NuParla or
                            its licensors. No Service Content may be modified, copied, distributed, framed,
                            reproduced, republished, downloaded, scraped, displayed, posted, transmitted,
                            or sold in any form or by any means, in whole or in part, other than as expressly
                            permitted in these Terms and Conditions. You may not use any data mining,
                            robots, scraping or similar data gathering or extraction methods to obtain
                            Service Content. As between you and NuParla, all data, information and
                            materials generated from your access and use of the educational activities
                            made available on or through the Service, including exercises, typed and audio
                            message content generated by you (collectively, the “Activity Materials”), shall
                            be exclusively owned by NuParla, and you shall not have any right to use such
                            Activity Materials except as expressly authorized by these Terms and
                            Conditions. Activity Materials will not include Translation Materials. By using the
                            Service, you hereby assign to NuParla any and all rights, title and interest,
                            including any intellectual property rights or proprietary rights, in the Activity
                            Materials. All rights of NuParla or its licensors that are not expressly granted in
                            these Terms and Conditions are reserved to NuParla and its licensors. </P2>

                        <h3>17. Trademarks</h3>
                        <P2>“NuParla,” “Aprende inglés. Gana dinero." and all other trademarks, service
                            marks, graphics and logos used in connection with the Service are trademarks
                            or service marks of NuParla LLC, and certain of them are registered with the
                            United States Patent and Trademark Office. Access and use of the Service does
                            not grant or provide you with the right or license to reproduce or otherwise use
                            the NuParla name or any NuParla or third-party trademarks, service marks,
                            graphics or logos. </P2>

                        <h3>18. Privacy</h3>
                        <P2>Use of the Service is also governed by our Privacy Policy, a copy of which is
                            located at <a href="/privacy">www.nuparla.com/privacy</a>. By using the Service, you consent to the
                            terms of the Privacy Policy. </P2>

                        <h3>19. Governing Law and Arbitration; No Class Action</h3>
                        <P2>These Terms and Conditions, its subject matter and NuParla’s and your
                            respective rights under these Terms and Conditions, as well as and any claim,
                            cause of action or dispute (“claim”) arising out of or related to these Terms and
                            Conditions, shall be governed by and construed under the laws of the State of
                            California, United States of America. ANY DISPUTE OR CLAIM RELATING IN
                            ANY WAY TO THESE TERMS AND CONDITIONS OR THE SERVICE WILL
                            BE RESOLVED BY BINDING ARBITRATION, RATHER THAN IN COURT,
                            except for NuParla’s right to seek injunctive relief as set forth below. Unless
                            otherwise expressly required by applicable law, each party shall bear its own
                            attorneys’ fees without regard to which party is deemed the prevailing party in
                            the arbitration proceeding.<br/><br/>
                            If you intend to seek arbitration you must first send written notice to NuParla’s
                            Administration Office of your intent to arbitrate (“Notice”). The Notice to NuParla
                            should be sent by electronic mail to legal@nuparla.com. The Notice must (a)
                            describe the nature and basis of the claim or dispute; and (b) set forth the
                            specific relief sought; and (c) set forth your name, affiliate ID number, address
                            and contact information. If we intend to seek arbitration against you, we will send
                            any notice of dispute to you at the contact information we have for you.<br/><br/>
                            The arbitration will be conducted before a neutral single arbitrator in Riverside
                            County located in the State of California, United States, whose decision will be
                            final and binding.<br/><br/>    
                            To the fullest extent permitted by applicable law, YOU AND NUPARLA EACH
                            AGREE THAT ANY DISPUTE RESOLUTION PROCEEDING WILL BE
                            CONDUCTED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS,
                            CONSOLIDATED OR REPRESENTATIVE ACTION. If for any reason a claim
                            proceeds in court rather than in arbitration, YOU AND NUPARLA EACH WAIVE
                            ANY RIGHT TO A JURY TRIAL. </P2>

                        <h3>20. Language</h3>
                        <P2>This agreement was originally written in English (US). To the extent any
                            translated version of this agreement conflicts with the English version, the
                            English version controls.</P2>

                        <h3>21. Miscellaneous</h3>
                        <P2>These Terms and Conditions constitute the entire agreement between NuParla
                            and you concerning the subject matter hereof. In the event that any of the Terms
                            and Conditions are held by a court or other tribunal of competent jurisdiction to
                            be unenforceable, such provisions shall be limited or eliminated to the minimum
                            extent necessary so that these Terms and Conditions shall otherwise remain in
                            full force and effect. A waiver by NuParla or you of any provision of these Terms
                            and Conditions or any breach thereof, in any one instance, will not waive such
                            term or condition or any subsequent breach thereof. NuParla may assign its
                            rights or obligations under these Terms and Conditions without condition. These
                            Terms and Conditions will be binding upon and will inure to the benefit of
                            NuParla and you, and NuParla’s and your respective successors and permitted
                            assigns. </P2>

                            











                </div>}
























                {this.state.lang == 'spanish' && <div style={{width: '90%', margin: 'auto'}}>
                    <H1 style={{width: '90%', margin: 'auto', textAlign: 'center'}}>ACUERDO DEL PROGRAMA DE AFILIADOS NUPARLA</H1>
                    <P ><b>POR FAVOR LEA ESTE ACUERDO CUIDADOSAMENTE. AL UNIRSE Y PARTICIPAR EN EL PROGRAMA DE AFILIADOS DE NUPARLA, USTED ACEPTA ESTAR OBLIGADO POR ESTE ACUERDO Y TODOS LOS TÉRMINOS INCORPORADOS POR REFERENCIA. TENGA EN CUENTA QUE LAS SECCIONES 18 Y 20 CONTIENEN UNA CLÁUSULA DE ARBITRAJE VINCULANTE Y UNA RENUNCIA DE ACCIÓN COLECTIVA, QUE, SI ES APLICABLE A USTED, AFECTA SUS DERECHOS LEGALES. SI NO ESTÁ DE ACUERDO CON ESTOS TÉRMINOS, ENTONCES NO PARTICIPE EN EL PROGRAMA DE AFILIADOS DE NUPARLA.</b><br/><br/>
                        <b>Este Acuerdo del Programa de Afiliados de NuParla ("Acuerdo") se aplica al acceso y participación de cada Afiliado ("Afiliado", "usted" o "su") en el Programa de Afiliados de NuParla (el "Programa"), que es operado por NuParla LLC (la  “Compañía”, “nuestro”, “nos” o “nosotros”). Este Acuerdo no altera de ninguna manera los términos o condiciones de cualquier otro acuerdo que pueda tener con la Compañía para otros productos y servicios. Además, este Acuerdo complementa e incorpora por referencia los Términos de servicio de la Compañía en https://NuParla.com/terms ("Términos y condiciones"). En caso de cualquier conflicto entre los Términos y condiciones y este Acuerdo relacionado con el Programa, este Acuerdo prevalecerá.</b><br/><br/>
                        <b>El "Sitio" se refiere al sitio web de NuParla en https://NuParla.com así como a la oficina virtual (Back Office) de NuParla, y "Su sitio web" se refiere a cualquier sitio web que pueda crear fuera del enlace único de NuParla que se le proporcionó para su Sitio replicado.</b> </P>


                        <h3>1. Inscripción.</h3>
                        <P2>Para inscribirse en el programa, debe (1) completar el proceso de inscripción y convertirse en estudiante de NuParla. Luego, si desea convertirse en Afiliado de NuParla y acepta el Acuerdo del Programa de Afiliados que se encuentra en www.NuParla.com/affiliate; y (2) proporcionar una cuenta PayPal válida para que usted reciba pago, y una vez que verifiquemos su cuenta PayPal, se le otorgará acceso al Programa. La Compañía se reserva el derecho de reevaluar su registro en cualquier momento y se reserva el derecho de disciplinar o rescindir este Acuerdo y su participación en el Programa por violaciones de este Acuerdo, los Términos y condiciones de NuParla o la Política de privacidad en cualquier momento, con o sin aviso. </P2>

                        <h3>2. Autorización y Contrato.</h3>
                        <P2>Al ejecutar electrónicamente el Acuerdo de Afiliado de NuParla ("Acuerdo"), solicita autorización legal para convertirse en Afiliado de NuParla y celebrar un contrato con NuParla LLC, en adelante "NuParla". Usted reconoce que, antes de firmar electrónicamente, recibió, leyó y comprendió la Declaración de divulgación de ingresos de NuParla, que leyó y comprendió los Términos y condiciones de NuParla y la Política de privacidad de NuParla, tal como se publica en www.NuParla.com, y que ha leído y está de acuerdo con todos los términos establecidos en este Acuerdo. NuParla se reserva el derecho de rechazar cualquier solicitud por cualquier motivo dentro de los 30 días posteriores a la recepción.</P2>

                        <h3>3. Vencimiento, Renovación y Terminación.</h3>
                        <P2>El término de este Acuerdo es siempre que usted sea propietario de un puesto en nuestra estructura de ventas como Afiliado. No hay pagos de renovación ni contratos de renovación. En caso de rescisión, por cualquier motivo, comprende que perderá permanentemente todos los derechos como Afiliado. No será elegible para vender servicios de NuParla ni para recibir comisiones, bonificaciones u otros ingresos que resulten de las actividades de su anterior organización de ventas de línea descendente. En caso de cancelación o rescisión, renuncia a todos los derechos que tiene, incluidos, entre otros, los derechos de propiedad, a su anterior organización de línea descendente y a cualquier bonificación, comisión u otra remuneración derivada de las ventas y otras actividades de su anterior organización de línea descendente. NuParla se reserva el derecho de rescindir todos los Acuerdos de Afiliados con un aviso de 30 días si la Compañía elige: (1) cesar las operaciones comerciales; (2) disolverse como entidad comercial; o (3) finalizar la distribución de sus servicios a través de canales de venta directa. Los Afiliados pueden cancelar este Acuerdo en cualquier momento, y por cualquier motivo, mediante notificación por escrito a NuParla en su dirección comercial principal como se publica en NuParla.com. NuParla puede cancelar este Acuerdo por cualquier motivo mediante notificación por escrito al Afiliado con 30 días de antelación. NuParla también puede tomar acciones antes de la terminación del Acuerdo, si el Afiliado de NuParla incumple alguna de sus disposiciones.</P2>


                        <h3>4. Política de marketing internacional.</h3>
                        <P2>Un Afiliado de NuParla está autorizado para vender cursos de inglés en línea de NuParla a los clientes y para reclutar a otros Afiliados solo en los países en los que NuParla está autorizado para realizar negocios. Al participar en el Programa, usted garantiza que no es residente de ningún país actualmente sancionado por la Oficina de Control de Activos Extranjeros ("OFAC"), que incluye: Balcanes, Bielorrusia, Birmania, Costa de Marfil, República Democrática del Congo, Irán, Irak, Liberia (el antiguo régimen de Charles Taylor), Líbano, Libia, Corea del Norte, Somalia, Sudán, Siria, Yemen y Zimbabue. Tenga en cuenta que la lista de países sancionados puede cambiar en cualquier momento; sin embargo, siempre se puede encontrar una lista actualizada en http://www.treas.gov/offices/enforcement/ofac/programs. Se prohíbe la participación en el Programa como residente de un país sancionado. Además, acepta cumplir con las restricciones de la OFAC aplicables y no anunciar ni participar en actividades comerciales con personas o empresas que residan en países sancionados. Usted es responsable del cumplimiento de las leyes de su jurisdicción gubernamental local. De conformidad con la Sección 1, debe tener una cuenta de PayPal para participar, que no está disponible en todos los países. Su uso de PayPal estará sujeto a términos separados requeridos y puestos a disposición por PayPal, y no se regirá por este Acuerdo.</P2>


                        <h3>5. Cómo los Afiliados ganan comisiones.</h3>
                        <P2>Una vez aceptado en el Programa, se pondrá a su disposición un enlace de Afiliado único a través de su Oficina Virtual (haga clic en Invitar). Podrá revisar los recursos de marketing autorizados del Programa, que estarán sujetos a la licencia en la Sección 6 y los términos de este Acuerdo.<br/>

                            Para cada persona que refiera al Sitio utilizando su enlace único que completa un
                            transacción (una “venta”) en el Sitio que resulte en un pago único a la Compañía de US $399, usted recibirá comisiones, sobrecomisiones y bonificaciones por avance de rango de acuerdo con la siguiente tabla:</P2>
                            <img src={details} style={{width: '80%', margin: 'auto'}} />
<br/>
                            Por ejemplo: Usted invitó, a través de su enlace personal único, a cuatro personas que completaron una transacción de US $399 cada una. Recibe comisiones de Inicio rápido de $ 100 por cada nuevo cliente (un total de US $400 en comisiones). Cuando su quinto cliente se registra a través de su enlace, comienza a recibir Comisiones de Inicio rápido de US $125 por cada nuevo cliente que se una a través de su enlace a partir de ese momento, más un Bono de Avance de rango de US $250, más US $30 por cada cliente nuevo traído por cualquiera de las cinco personas que usted afilió desde la fecha en que usted avanzó a Instructor. Clientes nuevos que trajeron sus primeros cuatro reclutas mientras estaba en el rango de Héroe no se consideran para sobrecomisiones.
<br/>
                            No recibirá comisiones de Afiliados por transacciones reembolsadas o devueltas. Si se emite un reembolso después de que la Comisión de Afiliados ya haya sido aprobada y pagada, se creará un saldo negativo en su cuenta y se deducirá de las futuras Comisiones de Afiliados. Cualquier pago recibido por la Compañía a través de Stripe (Visa/Mastercard), PayPal o Bitcoin de un cliente resultará en una referencia válida creada y una Comisión de Afiliados pagadera, sujeta a verificación y aprobación por parte de la Compañía. Para ganar Comisiones de Afiliado, debe cumplir con todos los términos de este Acuerdo, incluidas las Restricciones del Programa en las Secciones 8, 9, 10 y 11.
<br/>
                            La Comisión de Afiliados irá al último enlace en el que hizo clic la persona que realizó la compra antes de completar su transacción, siempre que dicha compra se produzca dentro de los 90 días posteriores a que se hizo clic en el Enlace. Por ejemplo, si el Cliente A hace clic en un enlace proporcionado por el Afiliado X, pero luego hace clic en un enlace proporcionado por el Afiliado Y, y completa una transacción en el Sitio, el Afiliado Y recibirá la Comisión de Afiliado completa por transacciones relacionadas con el Cliente A. Comisiones de Afiliado no se dividirá ni transferirá. Para mayor claridad, los clics en los enlaces son válidos por 90 días, por lo que si un cliente compra más de 90 días después de hacer clic en el enlace, no recibirá una comisión de Afiliados por dicha transacción.
<br/>
                            Los clientes que se acojan a la oferta de 2 o 3 pagos mensuales (dos pagos de US $249 o 3 pagos de US $199), la comisión del Afiliado será el 25% del pago mensual recibido (US $62.25 o US $49.75 respectivamente).
<br/>
                            El Afiliado comisiona cuando su Cliente se convierte en OTP (Order-To-Pay Orden de pago), lo que ocurre el día después de que expira el período de reembolso de 7 días mencionado en la Sección 21. Las comisiones se envían a la cuenta de PayPal del Afiliado los viernes.
<br/>

                        <h3>6. Concesión de licencia(s). </h3>
                        <P2>Usted declara y garantiza que acepta cumplir con lo siguiente:
                            <br/>
                            Sujeto a su cumplimiento continuo de este Acuerdo, le otorgamos un derecho no exclusivo, intransferible, no sub-licenciable, revocable y limitado para (i) acceder a nuestro Sitio a través de los Enlaces que le proporciona la Compañía únicamente de acuerdo con los términos de este Acuerdo, incluidas las restricciones establecidas en las Secciones 8, 9, 10 y 11; y (ii) únicamente en relación con dichos Enlaces, para utilizar nuestros logotipos, nombres comerciales, marcas comerciales y material de identificación similar, incluida la información de marketing y publicidad que le proporcionamos o autorizamos para tal fin (colectivamente, los "Materiales con licencia").
                            <ul>
                                <li><p>a.Solo tiene derecho a utilizar los Materiales con licencia en la medida en que sea un miembro activo del Programa. Usted acepta que todos los usos de los Materiales con licencia serán en nombre del Programa y que la buena voluntad asociada al mismo redundará en beneficio exclusivo de la Compañía.</p></li>
                                <li><p>b.Cada una de las partes acuerda no utilizar los materiales de propiedad de la otra de ninguna manera que sea despectiva, engañosa, obscena o que de otra manera retrate a la parte de manera negativa. Cada parte se reserva todos sus derechos respectivos sobre los materiales patentados cubiertos por esta licencia. Aparte de la licencia otorgada en este Acuerdo, cada parte retiene todos los derechos, títulos e intereses de sus respectivos derechos y ningún derecho, título o interés se transfiere a la otra.</p></li>
                                <li><p>c.Excepto por la licencia limitada otorgada bajo esta sección, usted no obtiene ningún derecho bajo este Acuerdo sobre ninguna propiedad intelectual, incluida cualquier propiedad intelectual con respecto a nuestro Sitio, el Enlace que le proporciona la Compañía, formatos de enlace, especificaciones técnicas, código, pautas o ilustraciones gráficas mencionadas anteriormente, o con respecto a nuestro nombre de dominio.</p></li>
                            </ul>

                        </P2>

                        <h3>7. Presentación del plan.</h3>
                        <P2>Usted acepta, al presentar el Plan de compensación de NuParla, presentarlo en su totalidad como se describe en los materiales oficiales, enfatizando que las ventas de productos a los clientes deben recibir una compensación en forma de comisiones y bonificaciones por volumen de línea descendente. Al presentar el plan a los posibles clientes, acepta dejar en claro que la compra de nuestro curso de inglés NuParla en línea no es un requisito para participar en el plan de compensación. Además, acepta abstenerse de presentar la oportunidad NuParla como un programa de inversión pasiva. Las comisiones son impulsadas únicamente por los esfuerzos de cada Afiliado individual, ya que las ventas de nuestro curso de inglés son consumadas por clientes fuera de la red de Afiliados.</P2>

                        <h3>8. Restricciones generales.</h3>
                        <P2>Usted declara y garantiza que acepta cumplir con lo siguiente:
                            <ul>
                                <li><p>a.Solo utilizará los Enlaces que le proporcionamos sin manipulación o modificación de ningún tipo.</p></li>
                                <li><p>No puede "auto-referirse", lo que significa que solo las transacciones de otras personas que usen su Enlace resultarán en comisiones de Afiliados. </p></li>
                                <li><p>c.No participará en ningún comportamiento fraudulento, abusivo o dañino para el Sitio o el Programa a nuestra discreción.. </p></li>
                                <li><p>d.Todos los dominios que utilizan su Enlace de Afiliado deben estar incluidos en su perfil de Afiliado.</p></li>
                                <li><p>e.Cualquier sitio web que usted cree (el sitio replicado de su empresa; su Enlace de Afiliado único no incluido) no copiará, se parecerá ni reflejará de ninguna manera el aspecto o la estructura del Sitio. Tampoco utilizará ningún medio para crear la impresión de que su sitio web es nuestro sitio o cualquier parte de nuestro sitio, incluso al enmarcar nuestro sitio de cualquier manera.</p></li>

                            </ul>

                             </P2>

                        <h3>9. Programa de afiliados; Mercadeo en línea</h3>
                        <P2>Si elige compartir el Servicio con otros, tendrá la oportunidad de recibir una
                            comisión de US $100 cuando otra persona (un "Cliente potencial") cree una
                            cuenta de NuParla utilizando su Enlace de afiliado. Dicha comisión se paga a
                            través de PayPal el primer viernes después de que expire el período de
                            reembolso de siete (7) días, el Cliente potencial realiza el pago y se registra
                            una "Venta".<br/><br/>
                            Usted acepta proporcionar cierta información (como, entre otros, un formulario
                            W-9 completo para residentes de EE. UU.). Si no completa la información
                            requerida dentro de los ciento veinte (120) días de haber obtenido su primera
                            comisión, se considerará que ha renunciado permanentemente a todos los
                            derechos de comisiones adicionales. Si no es residente de los Estados Unidos,
                            los impuestos (incluido, entre otros, el IVA) pueden retenerse cuando lo exija la
                            ley aplicable. Usted es el único responsable de cumplir con todas las leyes
                            fiscales en su respectiva jurisdicción o país de residencia, incluido, entre otros,
                            el pago de todos los impuestos requeridos y la presentación de todas las
                            declaraciones y otros documentos requeridos ante el organismo rector
                            correspondiente.<br/><br/>
                            NuParla puede ofrecerle la oportunidad de convertirse en un afiliado
                            independiente de NuParla ("Afiliado", "NuParlero"), en el que tiene la
                            oportunidad de ganar dinero adicional para las cuentas de NuParla que venda
                            a otros usuarios sujeto a su aceptación de los términos del Acuerdo de afiliación
                            (el "Acuerdo de afiliación") de NuParla. NuParla se reserva el derecho único y
                            exclusivo de determinar el monto de la remuneración que recibirá cada Afiliado
                            a cambio de los esfuerzos del Afiliado. La comisión de afiliación se trata más
                            detalladamente en el Acuerdo de afiliación.<br/><br/>
                            Si desea aumentar su alcance mediante campañas de marketing en redes
                            sociales a través de anuncios, como los que se encuentran en anuncios de
                            Facebook, anuncios de YouTube, etc., debe hacerlo a través de sus propias 
                            cuentas personales de redes sociales, y revelar el hecho de que está siendo
                            compensado para referir y registrar nuevas personas en el Servicio. La creación
                            de perfiles, páginas de fans o sitios web que usan el nombre NuParla o
                            "Aprende inglés. Gana dinero." o el uso de nuestro logotipo está estrictamente
                            prohibido.</P2>

                        <h3>10. Estado del contratista independiente</h3>
                        <P2>Para evitar dudas, los Afiliados son contratistas independientes y no son
                            empleados o agentes de NuParla. Los afiliados no tienen autoridad para
                            actuar en nombre o vincular a NuParla de ninguna manera. Los afiliados serán
                            única y exclusivamente responsables de todos los costos y otros gastos
                            incurridos. Nada en este Acuerdo debe interpretarse como la creación de una
                            asociación, alianza o cualquier otra relación similar. Cada parte será un
                            contratista independiente en su desempeño en virtud del presente y deberá
                            retener el control sobre su personal y la forma en que dicho personal se
                            desempeña en virtud del presente. En ningún caso, tales personas serán
                            consideradas empleados de la otra parte en virtud de su participación o
                            desempeño en virtud del presente.</P2>


                        <h3>11. Divulgación de ganancias</h3>
                        <P2>No hay garantía de que ganará dinero con NuParla, o mediante el uso de las
                            técnicas e ideas en los materiales de marketing del Servicio. Los ejemplos en
                            estos materiales no deben interpretarse como una promesa o garantía de
                            ganancias. El potencial de ingresos depende completamente de la persona
                            que usa nuestros productos, ideas y técnicas. No posicionamos este producto
                            como un "esquema para hacerse rico". Cualquier reclamo de ganancias reales
                            o ejemplos de resultados reales se puede verificar a pedido. Su nivel de éxito
                            en el logro de los resultados declarados en nuestros materiales depende del
                            tiempo que dedique al programa, las ideas y técnicas mencionadas, sus
                            finanzas, conocimiento y diversas habilidades. Dado que estos factores
                            difieren según las personas, no podemos garantizar su éxito o nivel de
                            ingresos. Tampoco somos responsables de ninguna de sus acciones o
                            resultados.<br/><br/>
                            Los materiales en nuestro producto y nuestro sitio web pueden contener
                            información que incluye o se basa en declaraciones prospectivas en el sentido
                            de la Ley de Reforma de Litigios de Valores Privados de 1995 (Private
                            Securities Litigation Reform Act of 1995). Las declaraciones prospectivas dan
                            nuestras expectativas o pronósticos de eventos futuros. Puede identificar
                            estas declaraciones por el hecho de que no se relacionan estrictamente con 
                            hechos históricos o actuales. Usan palabras como "anticipar", "estimar",
                            "esperar", "proyectar", "pretender", "planear", "creer" y otras palabras y
                            términos de significado similar en relación con una descripción de ganancias
                            potenciales o rendimiento financiero.<br/><br/>
                            Todas y cada una de las declaraciones a futuro aquí o en cualquiera de
                            nuestros materiales de venta están destinadas a expresar nuestra opinión
                            sobre el potencial de ganancias. Muchos factores serán importantes para
                            determinar sus resultados reales y no se hacen garantías de que obtendrá
                            resultados similares a los nuestros o de cualquier otra persona, de hecho, no
                            se garantiza que obtendrá ningún resultado de nuestras ideas y técnicas en
                            nuestro material.</P2>

                        <h3>12. No transferencia de propiedad intelectual</h3>
                        <P2>El Servicio está protegido por la ley de derechos de autor. Todos y cada uno
                            de los materiales originales que se le proporcionan son para su uso individual
                            solo bajo una licencia de usuario único. Usted no está autorizado a utilizar
                            ninguna de nuestra propiedad intelectual para fines comerciales. Toda la
                            propiedad intelectual, incluidos los programas y / o materiales con derechos
                            de autor de NuParla x 90 Days, son propiedad exclusiva de NuParla LLC. No
                            se otorga ni implica ninguna licencia para vender o distribuir nuestros
                            materiales. Al comprar este producto, usted acepta: 1. no infringir ningún
                            derecho de autor, patente, marca registrada, secreto comercial u otros
                            derechos de propiedad intelectual; 2. que cualquier información confidencial
                            compartida por NuParla es confidencial y de propiedad total, y pertenece única
                            y exclusivamente a NuParla; 3. no divulgar dicha información a ninguna otra
                            persona o usarla de cualquier otra manera que no sea en discusión con
                            NuParla. Además, al comprar el Servicio, usted acepta no violar ni mostrar
                            ninguna posibilidad de violar los Términos contenidos en este párrafo. NuParla
                            tiene derecho a utilizar medidas de precaución para prohibir tales violaciones
                            y protegerse contra el daño en el que puedan incurrir tales violaciones.     </P2>

                        <h3>13. NINGUNA REPRESENTACIÓN O GARANTÍA DE NUPARLA</h3>
                        <P2>EL SERVICIO, INCLUYENDO TODAS LAS IMÁGENES, ARCHIVOS DE
                            AUDIO Y OTRO CONTENIDO EN EL MISMO, Y CUALQUIER OTRA
                            INFORMACIÓN, PROPIEDAD Y DERECHOS OTORGADOS O
                            PROPORCIONADOS POR NUPARLA, SE LE PROPORCIONAN "TAL
                            CUAL". NUPARLA Y SUS PROVEEDORES NO OFRECEN
                            REPRESENTACIONES O GARANTÍAS DE NINGÚN TIPO CON RESPECTO
                            AL SERVICIO, EXPRESA O IMPLÍCITA, Y TODAS LAS 
                            REPRESENTACIONES Y GARANTÍAS, INCLUYENDO LAS GARANTÍAS DE
                            COMERCIABILIDAD, IDONEIDAD PARA UN PROPÓSITO EN PARTICULAR
                            O AUSENCIA DE USURPACIÓN SON EXPLÍCITAMENTE NEGADOS. SIN
                            LIMITAR LA GENERALIDAD DE LO ANTERIOR, NUPARLA NO OFRECE
                            NINGUNA REPRESENTACIÓN O GARANTÍA DE NINGÚN TIPO
                            RELACIONADO CON LA EXACTITUD, DISPONIBILIDAD DEL SERVICIO,
                            COMPLETIDAD, CONTENIDO INFORMATIVO, OPERACIÓN LIBRE DE
                            ERRORES, RESULTADOS QUE SE OBTENERÁN DEL USO O POR NO
                            INFRACCIÓN. EL ACCESO Y USO DEL SERVICIO PUEDE NO ESTAR
                            DISPONIBLE DURANTE PERÍODOS DE DEMANDA PICO,
                            ACTUALIZACIONES DEL SISTEMA, MAL FUNCIONAMIENTO O
                            MANTENIMIENTO PROGRAMADO O NO PROGRAMADO O POR OTRAS
                            RAZONES.<br/><br/>
                            El Servicio se desarrolla estrictamente solo con fines educativos. Usted es
                            100% responsable de su propio progreso y resultados. NuParla no hace
                            representaciones o garantías, orales o escritas, ya que los resultados
                            experimentados por cada Estudiante pueden variar significativamente. Usted
                            reconoce que, como con cualquier esfuerzo comercial, existe un riesgo
                            inherente de pérdida de capital y no hay garantía de que alcanzará sus
                            objetivos como resultado de la participación en el Servicio. La educación e
                            información sobre el programa está destinada a una audiencia general y no
                            pretende ser, ni debe interpretarse como ser, un asesoramiento específico a
                            medida de nadie. NuParla no asume ninguna responsabilidad por errores u
                            omisiones que puedan aparecer en los materiales del programa.</P2>

                        <h3>14. LIMITACIÓN DE RESPONSABILIDAD Y TIPOS DE DAÑOS</h3>
                        <P2>EN NINGÚN CASO NUPARLA SERÁ RESPONSABLE CON USTED O
                            CUALQUIER TERCERO QUE RECLAME A TRAVÉS DE USTED (POR BASE
                            EN CONTRATO, AGRAVIO, RESPONSABILIDAD ESTRICTA U OTRA
                            TEORÍA) POR DAÑOS INDIRECTOS, INCIDENTALES, ESPECIALES,
                            CONSECUENTES O EJEMPLARES DERIVADOS O RELACIONADOS CON
                            EL ACCESO O RELACIONADOS CON EL ACCESO O USO O LA
                            INCAPACIDAD DE ACCEDER O UTILIZAR EL SERVICIO O CUALQUIER
                            PARTE DE LOS MISMOS, INCLUIDOS, PERO SIN LIMITARSE A LA
                            PÉRDIDA DE USO DEL SERVICIO, RESULTADOS INCORRECTOS,
                            PÉRDIDA DE BENEFICIOS, INTERRUPCIÓN EMPRESARIAL O DAÑOS DE
                            LA PÉRDIDA O CORRUPCIÓN DE DATOS O DATOS QUE SE ENTREGAN
                            INEXACUTADOS, EL COSTO DE RECUPERAR CUALQUIER DATO, EL
                            COSTO DE LOS SERVICIOS SUSTITUTOS O DE LAS RECLAMACIONES
                            DE TERCEROS POR CUALQUIER DAÑO A LAS COMPUTADORAS, 
                            SOFTWARE, MODEMS, TELÉFONOS U OTRA PROPIEDAD, INCLUSO SI
                            NUPARLA HA SIDO INFORMADO DE DICHA POSIBILIDAD. LA
                            RESPONSABILIDAD DE NUPARLA CON USTED O CUALQUIER TERCERO
                            QUE RECLAME A TRAVÉS DE USTED POR CUALQUIER CAUSA Y SIN
                            IMPORTAR LA FORMA DE LA ACCIÓN, SE LIMITA A LA CANTIDAD
                            PAGADA, SI CUALQUIERA, POR USTED A NUPARLA POR EL SERVICIO
                            EN LOS 12 MESES ANTES DE LA INICIAL ACCIÓN QUE LE DA A LA
                            RESPONSABILIDAD. ESTE ES UN LÍMITE AGREGADO. LA EXISTENCIA
                            DE MÁS DE UNA RECLAMACIÓN AQUÍ NO AUMENTARÁ ESTE LÍMITE.</P2>

                        <h3>15. Terminación</h3>
                        <P2>NuParla puede rescindir su acceso y uso del Servicio inmediatamente en
                            cualquier momento, por incumplimiento de los Términos, y en ese momento
                            no tendrá más derecho a usar el Servicio. Puede cancelar su cuenta de
                            NuParla en cualquier momento siguiendo las instrucciones disponibles a
                            través del Servicio. Las disposiciones de estos Términos y condiciones
                            relacionadas con la protección y el cumplimiento de los derechos de propiedad
                            de NuParla, sus representaciones y garantías, la exención de responsabilidad
                            de representaciones y garantías, exenciones e indemnizaciones, limitaciones
                            de responsabilidad y tipos de daños, propiedad de datos e información, leyes
                            vigentes y lugar, y disposiciones diversas, sobrevivirán a dicha terminación. </P2>

                        <h3>16. Derechos de propiedad en el contenido del servicio y materiales de actividad</h3>
                        <P2>Todo el contenido disponible a través del Servicio, incluidos los diseños, texto,
                            gráficos, imágenes, información, software, audio y otros archivos, y su
                            selección y disposición (el "Contenido del Servicio"), son propiedad de
                            NuParla o sus licenciantes. Ningún contenido del servicio puede ser
                            modificado, copiado, distribuido, enmarcado, reproducido, republicado,
                            descargado, raspado digitalmente, exhibido, publicado, transmitido o vendido
                            de ninguna forma o por ningún medio, en su totalidad o en parte, excepto lo
                            expresamente permitido en estos Términos y Condiciones. No puede utilizar
                            ningún método de extracción de datos, robots, raspado digitales o recopilación
                            de datos o métodos de extracción similares para obtener el Contenido del
                            servicio. Entre usted y NuParla, todos los datos, información y materiales
                            generados a partir de su acceso y uso de las actividades educativas
                            disponibles en o a través del Servicio, incluidos ejercicios, contenido
                            mecanografiado y mensajes de audio generados por usted (colectivamente,
                            los "Materiales de actividad"), será propiedad exclusiva de NuParla, y usted
                            no tendrá ningún derecho a usar dichos Materiales de Actividad, excepto según lo expresamente autorizado por estos Términos y Condiciones. Los
                            materiales de la actividad no incluirán materiales de traducción. Al usar el
                            Servicio, usted asigna a NuParla todos y cada uno de los derechos, títulos e
                            intereses, incluidos los derechos de propiedad intelectual o derechos de
                            propiedad, en los Materiales de la Actividad. Todos los derechos de NuParla
                            o sus licenciantes que no se otorgan expresamente en estos Términos y
                            Condiciones están reservados a NuParla y sus licenciantes. </P2>

                        <h3>17. Marcas registradas</h3>
                        <P2>“NuParla”, “Aprende inglés. Gana dinero. " y todas las demás marcas
                            comerciales, marcas de servicio, gráficos y logotipos utilizados en relación con
                            el Servicio son marcas comerciales o marcas de servicio de NuParla LLC, y
                            algunas de ellas están registradas en la Oficina de Patentes y Marcas de los
                            Estados Unidos. Acceso y uso de El servicio no le otorga ni le otorga el
                            derecho o la licencia para reproducir o utilizar el nombre de NuParla o
                            cualquier marca registrada de NuParla o de terceros, marcas de servicio,
                            gráficos o logotipos. </P2>

                        <h3>18. Privacidad</h3>
                        <P2>El uso del Servicio también se rige por nuestra Política de Privacidad, cuya
                            copia se encuentra en <a href="/privacy">www.nuparla.com/privacy</a>. Al usar el Servicio, usted
                            acepta los términos de la Política de Privacidad. </P2>

                        <h3>19. Ley aplicable y arbitraje; Sin demanda colectiva</h3>
                        <P2>Estos Términos y condiciones, su objeto y los derechos de NuParla y sus
                            respectivos derechos en virtud de estos Términos y Condiciones, así como
                            cualquier reclamo, causa de acción o disputa ("reclamo") que surja de estos
                            Términos y Condiciones o estén relacionados con ellos será gobernado e
                            interpretado bajo las leyes del estado de California, Estados Unidos de
                            América. CUALQUIER DISPUTA O RECLAMACIÓN RELACIONADA DE
                            CUALQUIER FORMA CON ESTOS TÉRMINOS Y CONDICIONES O EL
                            SERVICIO SE RESOLVERÁ POR ARBITRAJE VINCULANTE, EN LUGAR
                            DE EN TRIBUNAL, excepto por el derecho de NuParla a buscar medidas
                            cautelares como se establece a continuación. A menos que la ley aplicable
                            exija expresamente lo contrario, cada parte asumirá los honorarios de sus
                            propios abogados sin importar qué parte se considere la parte prevaleciente
                            en el procedimiento de arbitraje.<br/><br/>
                            Si tiene la intención de solicitar un arbitraje, primero debe enviar un aviso por
                            escrito a la Oficina de Administración de NuParla sobre su intención de arbitrar 
                            ("Aviso"). El aviso a NuParla debe enviarse por correo electrónico a
                            legal@nuparla.com. El Aviso debe (a) describir la naturaleza y la base del
                            reclamo o disputa; y (b) establecer la reparación específica buscada; y (c)
                            establecer su nombre, número de identificación de afiliado, dirección e
                            información de contacto. Si tenemos la intención de solicitar un arbitraje en su
                            contra, le enviaremos cualquier aviso de disputa a la información de contacto
                            que tenemos para usted.<br/><br/>
                            El arbitraje se llevará a cabo ante un árbitro único neutral en el condado de
                            Riverside ubicado en el estado de California, Estados Unidos, cuya decisión
                            será definitiva y vinculante.<br/><br/>    
                            En la mayor medida permitida por la ley aplicable, USTED Y NUPARLA
                            ACUERDAN QUE CUALQUIER PROCEDIMIENTO DE RESOLUCIÓN DE
                            CONTROVERSIAS SERÁ REALIZADO SOLO EN UNA BASE INDIVIDUAL Y
                            NO EN UNA DEMANDA COLECTIVA, CONSOLIDADA O ACCIÓN POR
                            REPRESENTANTE. Si, por algún motivo, un reclamo se lleva a cabo en un
                            tribunal en lugar de un arbitraje, USTED Y NUPARLA CADA UNO
                            RENUNCIAN AL DERECHO A UN JUICIO CON JURADO.</P2>

                        <h3>20. Idioma</h3>
                        <P2>Este acuerdo fue escrito originalmente en inglés (EE. UU.). En la medida en
                            que cualquier versión traducida de este acuerdo entre en conflicto con la
                            versión en inglés, la versión en inglés gobierna.</P2>

                        <h3>21. Varios</h3>
                        <P2>Estos Términos y Condiciones constituyen el acuerdo completo entre NuParla
                            y usted con respecto al tema del presente. En el caso de que un tribunal u otro
                            tribunal de jurisdicción competente considere que alguno de los Términos y
                            Condiciones no se puede hacer cumplir, dichas disposiciones se limitarán o
                            eliminarán en la medida mínima necesaria para que estos Términos y
                            Condiciones permanezcan en plena vigencia y efecto. Una renuncia por parte
                            de NuParla o de usted a cualquier disposición de estos Términos y
                            Condiciones o cualquier incumplimiento de los mismos, en cualquier caso, no
                            renunciará a dicho término o condición o cualquier incumplimiento posterior
                            de los mismos. NuParla puede ceder sus derechos u obligaciones bajo estos
                            Términos y Condiciones sin condición. Estos Términos y Condiciones serán
                            vinculantes y redundarán en beneficio de NuParla y usted, y de NuParla y sus
                            respectivos sucesores y cesionarios permitidos. </P2>

                            











                </div>}
                



            </div>
        )
    }
}



const P = styled.p `
    font-size: medium;
    color: #888;
    text-align: justify;
    
`

const P2 = styled.p `
    font-size: medium;
    color: #888;
    text-align: justify;
    margin-left: 20px;
    
`

const H1 = styled.p `
    font-family: Arial, Helvetica, sans-serif;
    margin: 60px;
    font-size: xx-large;
    font-weight: bold;
`