import React, {Component} from 'react';
import '../../App.css';
import styled from 'styled-components';
import DraftsIcon from '@material-ui/icons/Drafts';
import logoblack from '../../Images/nuparla em1 .1 w.jpg'
import banner from '../../Images/banner.jpg'
import { TextField, Paper, Button } from "@material-ui/core";
import "../../config";
import * as firebase from "firebase";


export default class support extends Component {
    constructor(){
        super();
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            message: '',
            sent: false

        }
    }

    componentDidMount(){

    }

    send(){
        if (this.state.message == ''){
            alert('Please enter a message')
        } else if (this.state.firstName == ''){
            alert('Please enter a name')
        } else if (this.state.email == ''){
            alert('Please enter your email')
        } else {

            firebase.firestore().collection('ContactMessages').doc(this.state.email).set({
                firstName: this.state.firstName,
                country: this.state.lastName,
                email: this.state.email,
                message: this.state.message,
                timestamp: Date.now()
            }).then(res => {
                  console.log(res)
                  this.setState({sent: true})
              })

        }

    }

    render(){
        
        return (
            <div style={{ height: '100vh', textAlign: 'center', backgroundColor: '#ebebeb'}}>
                <div>
                    <img src={banner} style={{width: '100vw'}} />
                </div>

                <div style={{padding: 40}}>
                <h2>COMUNÍCATE CON NOSOTROS</h2>
                <p>NuParla LLC 211 N. State St Suite 203 Hemet, CA 92543</p>
                <Paper style={{padding: '30px 20px'}} elevation={3}>
                <Grid>
                    <div>
                        <DraftsIcon style={{width: '90%', height: 280, margin: '20px auto', color: "#e9c979"}} />

                    </div>
                    {!this.state.sent && <div style={{width: '90%', margin: 'auto', textAlign: 'center'}}>
                        <TextField style={{margin: 5}} fullWidth variant="outlined" label="Nombre" value={this.state.firstName} onChange={(e)=> {
                            this.setState({firstName: e.target.value})
                        }} />
                        <TextField style={{margin: 5}} fullWidth variant="outlined" label="País" value={this.state.lastName} onChange={(e)=> {
                            this.setState({lastName: e.target.value})
                        }}/>
                        <TextField style={{margin: 5}} fullWidth variant="outlined" label="Correo electrónico" value={this.state.email} onChange={(e)=> {
                            this.setState({email: e.target.value})
                        }}/>
                       
                        <TextField style={{margin: 5}} fullWidth multiline variant="outlined" label="Mensaje" value={this.state.message} onChange={(e)=> {
                            this.setState({message: e.target.value})
                        }}/>

                        <Button variant="contained" color="primary" style={{margin: '20px auto'}} onClick={() => this.send()}>Send</Button>



                    </div>}
                    {this.state.sent && <div>
                        <p>Tu mensaje ha sido enviado. Nos comunicaremos contigo prontamente. Gracias!</p>
                    </div>}

                </Grid>

                </Paper>

                </div>
                
                


              
                



            </div>
        )
    }
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;

    @media (max-width: 600px){
        grid-template-columns: 0% 100%;
    }
`

