import React, { Component } from "react";
import  '../../../App.css'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import { TextField, Button } from "@material-ui/core";
import * as firebase from "firebase";
import "../../../config" ;
import { fire } from "../../../config";
import storage from "../../../config";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Link,
    Redirect,
  } from "react-router-dom";

  import MicRecorder from 'mic-recorder-to-mp3';
  const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export default class student extends Component {
    constructor(props){
        super(props);
        this.state = {
            user: {},
            replyText: '',
            reply: '',

            recording: false,
            audio: '',
            isRecording: false,
            blobURL: '',
            recorded: false,
            response: ''
           

        }
    }

    componentDidMount(){
        // firebase.firestore().collection('Users').get().then(res => {
        //     console.log(res.docs)
        //     this.setState({users: res.docs})
            
        // })

        console.log(this.props)

        this.setState({user: this.props.history.location.user})
       

    }

    reply(lesson,level){
        



          firebase.firestore().collection('Users').doc(this.props.history.location.user.email).update({
            adminResponses: firebase.firestore.FieldValue.arrayUnion({
              level: level,
              lesson: lesson,
              message: this.state.replyText,
              type: 'text'
            })
    
          }).then(()=> {
            this.setState({reply: ''})
          }) 

    }



    start = () => {
        this.setState({recording: true})
        
          Mp3Recorder
            .start()
            .then(() => {
              this.setState({ isRecording: true });
            }).catch((e) => console.error(e));
        
      };
    
      stop = () => {
        this.setState({recording: false})
        Mp3Recorder
          .stop()
          .getMp3()
          .then(([buffer, blob]) => {
            const blobURL = URL.createObjectURL(blob)
            console.log(blob)
            this.setState({ blobURL, blob: blob, isRecording: false, recorded: true });
          }).catch((e) => console.log(e));
      };
    
    
    
      send = (lesson,level) => {
    
        if (this.state.blobURL != ''){
    
          let upload = storage
            .ref(this.state.blob.size.toString())
            .put(this.state.blob);
          upload.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
              console.log(error);
            },
            () => {
              storage
                .ref(this.state.blob.size.toString())
                .getDownloadURL()
                .then(async (url) => {
                  firebase.firestore().collection('Users').doc(this.props.history.location.user.email).update({
                    adminResponses: firebase.firestore.FieldValue.arrayUnion({
                      level: level,
                      lesson: lesson,
                      audio: url,
                      type: 'audio'
                    })
            
                  }).then(()=> {
                    this.setState({reply: ''})
                  }) 
                  
    
                
            })})
        }
      }
    


    render(){ 
        

        return (
            <div>
                <h1>Student Details</h1>
                <Paper style={{width: '90%', margin: 'auto', padding: 20, margin: 10}}> 
                <div style={{display: 'flex'}}>
                        <h3>Name: </h3>
                        <p style={{margin: '2px 10px'}}>{this.props.history.location.user.name}</p>                     
                                           
                    </div>

                
                    <div style={{display: 'flex'}}>
                        <h3>Progress: </h3>
                        <p style={{margin: '2px 10px'}}>Level: {this.props.history.location.user.learningProgress.level}</p>                     
                        <p style={{margin: '2px 10px'}}>Lesson: {this.props.history.location.user.learningProgress.lesson}</p>                     
                    </div>

                </Paper>

                <div style={{width: '90%', margin: 'auto', padding: 20, margin: 10}}>
                    <h3 style={{fontSize: 'x-large', fontWeight: 'bold'}}>Lesson Responses: </h3>

                    {this.props.history.location.user.Responses && this.props.history.location.user.Responses.map(response => {
                        return (
                            <Paper style={{padding: 20, display: 'flex', flexWrap: 'wrap', margin: '15px auto'}}>
                                <h3 style={{margin: 10}}>Lesson {response.lesson}</h3>
                                <audio src={response.audio} style={{padding: 10,  }} controls="controls" />
                                {this.state.reply != response.lesson && <Button variant="contained" style={{backgroundColor: 'green', color: 'white'}} onClick={() => this.setState({reply: response.lesson})}>Reply</Button>}
                                {this.state.reply == response.lesson && <div style={{display: 'flex', width: '90%', margin: '10px auto'}}>
                                    <TextField fullWidth variant="outlined" placeholder="Enter your reply here" onChange={(e) => {this.setState({replyText: e.target.value})}}/>

                                    <Button onClick={() => this.reply(response.lesson, response.level)} variant="contained" color="primary">Send</Button>

                                </div>}
                                {this.state.reply == response.lesson && <p>Or send audio response</p>}
                                {this.state.reply == response.lesson && <div style={{display: 'flex', width: '90%', margin: '10px auto'}}>
                                {!this.state.recorded && <Button variant="contained" style={{backgroundColor: this.state.isRecording ? 'red' : 'green', color: 'white'}} onClick={() => {
                      if (this.state.isRecording){
                        this.stop()
                      } else {
                        this.start()
                      }
                    }}>{this.state.isRecording ? 'Stop Recording' : 'Click here to start recording'}</Button>}
                    {this.state.recorded && <div style={{width: '100%'}}>
                      <audio src={this.state.blobURL} style={{padding: 10,  }} controls="controls" />
                      </div>}
                      {this.state.isRecording && <h3>Recording...</h3>}
                      {this.state.recorded && <Button variant="contained" color="primary" onClick={() => this.send(response.lesson, response.level)}>Send</Button>}

                                </div>}

                                
                            </Paper>
                        )
                    })

                    }

                </div>
                



            

            </div>
        )

    }
} 