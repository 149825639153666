import React, { Component } from "react";
import  '../../../App.css'
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";
import nuparla from '../../../Images/banner.jpg'
import { TextField, Button } from "@material-ui/core";
import { fire } from "../../../config";
import "../../../config" ;
import * as firebase from "firebase";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import logo from "../../../Images/nuparla em1 .1.png";
import Paper from '@material-ui/core/Paper';


export default class loginAdmin extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: ''
    };
  }

  componentDidMount(){

  }

  reset(){
      if (this.state.email != ''){
          firebase.auth().sendPasswordResetEmail(this.state.email).then(() => {
              console.log('sent')
          })
      }

  }


  render(){

      return (
        <div style={{height: '100vh', width: '100vw', backgroundColor: 'rgba(0,0,0,0.8)', textAlign: 'center'}}>
          <img src={logo} style={{maxWidth: 400, minWidth: 200, margin: '40px auto'}} />


          <Paper style={{margin: 'auto', padding: 30, maxWidth: 600, minWidth: 300, textAlign: 'center', backgroundColor:  '#edd290', boxShadow: '0px 3px 6px rgba(0,0,0,0.4)'}}>
            <h2 style={{color: '#202020', fontFamily: 'Apple Chancery'}}>Reset Password</h2>
            <p>Enter your email and you will get a link to reset your password.</p>

            <TextField fullWidth variant="filled" placeholder="Enter Email" label="Email" margin="dense" onChange={(e)=>{this.setState({email: e.target.value})}}/>
            <Button variant="contained" color="primary" style={{ margin: '20px auto', backgroundColor: '#202020', color: '#edd290'}} onClick={()=>this.reset()} >Submit</Button>
           

          </Paper>
 
          <Backdrop open={this.state.backdrop} >
        <CircularProgress color="inherit" />
      </Backdrop>
        </div>

      )
  }


}