import React, { Component, } from "react";
import  '../../../App.css'
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";
import nuparla from '../../../Images/banner.jpg'
import { TextField, Button } from "@material-ui/core";

export default class loginAdmin extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: ''
    };
  }

  componentDidMount(){

  }

  login(){
    if (this.state.email == "harold.zapata@nuparla.com" && this.state.password == "12345"){
      localStorage.clear()
      localStorage.setItem("AdminLogin", "true")
      window.location.reload()
    } else if (this.state.email == "asadirshad5@gmail.com" && this.state.password == "12345"){
      localStorage.clear()
      localStorage.setItem("AdminLogin", "true")
      window.location.reload()
    }
  }


  render(){

      return (
        <div style={{height: '100vh', width: '100vw', backgroundColor: '#bebebe'}}>
          <img src={nuparla} style={{width: '100vw'}} />

          <div style={{margin: '100px auto',textAlign: 'center', backgroundColor: 'white', width: 400, padding: 10, borderRadius: 10, boxShadow: '0px 3px 6px rgba(0,0,0,0.4)'}}>
            <h2 style={{}}>Admin Login</h2>

            <TextField fullWidth variant="outlined" placeholder="Enter Email" label="Email" margin="dense" onChange={(e)=>{this.setState({email: e.target.value})}}/>

            <TextField fullWidth variant="outlined" placeholder="Enter Password" label="Password" margin="dense" onChange={(e)=>{this.setState({password: e.target.value})}} />
            <Button variant="contained" color="primary" style={{margin: 10}} onClick={()=>this.login()} >Login</Button>

          </div>
 
            
        </div>

      )
  }


}