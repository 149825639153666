import React, { Component } from "react";
import  '../../../App.css'
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";
import nuparla from '../../../Images/banner.jpg'
import { TextField, Button } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { fire } from "../../../config";
import "../../../config" ;
import * as firebase from "firebase";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import logo from "../../../Images/nuparla em1 .1.png";


export default class loginAdmin extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: ''
    };
  }

  componentDidMount(){

  }

  login(){
    this.setState({backdrop: true})
      let user;
    firebase.auth().signInWithEmailAndPassword(this.state.email.toLowerCase(), this.state.password).then(res => {
       
        firebase.firestore().collection('Users').doc(this.state.email.toLowerCase()).onSnapshot(res => {
          
            user = res.data()
            localStorage.clear();
        localStorage.setItem("AffiliateLogin", "true");
        localStorage.setItem("email", user.email)
        localStorage.setItem("firstName", user.firstName)
        localStorage.setItem("lastName", user.lastName)
        localStorage.setItem('paid',user.paid )
        localStorage.setItem('payment', user.paymentMethod)
        localStorage.setItem('plan', user.plan)
        window.location.reload()
        })
        this.setState({backdrop: false})
        
        }).catch(error => {
        if (error) {
            
        this.setState({backdrop: false})

            alert('Password or email incorrect')
        

      
        } else {
            console.log("pass")
        this.setState({backdrop: false})

        }
    })
  }


  render(){

      return (
        <div style={{height: '100vh', width: '100vw', backgroundColor: 'rgba(0,0,0,0.8)', textAlign: 'center'}}>
          <img src={logo} style={{maxWidth: 400, minWidth: 200, margin: '40px auto'}} />

          <Paper style={{margin: 'auto', padding: 30, maxWidth: 600, minWidth: 300, textAlign: 'center', backgroundColor:  '#edd290', boxShadow: '0px 3px 6px rgba(0,0,0,0.4)'}}>
            <h2 style={{color: '#202020', fontFamily: 'Apple Chancery'}}>LOGIN</h2>

            <TextField fullWidth variant="filled" placeholder="Enter Email" label="Email" margin="dense" style={{color: 'black'}} onChange={(e)=>{this.setState({email: e.target.value})}}/>

            <TextField fullWidth variant="filled" placeholder="Enter Password" label="Password" margin="dense" type="password" onChange={(e)=>{this.setState({password: e.target.value})}} />
            <Button variant="contained"  style={{ margin: '20px auto', backgroundColor: '#202020', color: '#edd290'}} onClick={()=>this.login()} >Login</Button>
            <a href="/reset-password"><p style={{color: 'black'}}>Forgot Password?</p></a>

          </Paper>
 
          <Backdrop open={this.state.backdrop} >
        <CircularProgress color="inherit" />
      </Backdrop>
        </div>

      )
  }


}