import React, { Component } from "react";
import "../../App.css";
import styled from "styled-components";
import LinearProgress from "@material-ui/core/LinearProgress";
import '../../config' ;
import storage from "../../config";
import * as firebase from "firebase";
import heroMedal from '../../Images/iron.png'
import instructorMedal from '../../Images/bronze.png'
import teacherMedal from '../../Images/silver.png'


export default class statusSlider extends Component {
  constructor() {
    super();
    this.state = {
      commissions: [],
      user: {
          first: [],
          learningProgress: 0
      },
      holdingDialog: false,
      selected: '',
      eta: '',
      progress: ''
    };
  }

  componentDidMount() {
    firebase.firestore().collection('Users').doc(localStorage.getItem('email')).onSnapshot(res => {
  
      this.setState({user: res.data()})
      let progress;
      if (res.data().learningProgress.level == 1) {
        progress = ((res.data().learningProgress.lesson - 1 )/29) * 100;
      } else if (res.data().learningProgress.level == 2) {
        progress = ((res.data().learningProgress.lesson - 30)/19) * 100;
      }else if (res.data().learningProgress.level == 3) {
        progress = ((res.data().learningProgress.lesson - 49)/20) * 100;
      }else if (res.data().learningProgress.level == 4) {
        progress = ((res.data().learningProgress.lesson - 69)/20) * 100;
      }
      
      progress = Math.round(progress * 10) / 10;
      this.setState({progress: progress})
      // let commissions = res.data().first.concat(res.data().second, res.data().third, res.data().fourth, res.data().fifth)


       
      //  // commissions.sort((a, b) => a.date.localeCompare(b.date))
      //  commissions.sort(function(a, b){
      //      return a.date > b.date;
      //    });
      //  commissions = commissions.reverse()
      //  console.log(commissions)
      //  commissions.forEach(user => {
      //      firebase.firestore().collection('Users').doc(user.email).get().then(res => {
         
      //          user.name = res.data().name;
      //          this.setState({commissions: commissions})
      //      })

           
      //  });
      
       let date = res.data().date;
       let currentDate = Date.now()
       let difference = currentDate - date;
       let eta = date + 7776000000;
      
       this.setState({eta: this.timeConverter(eta)})

   
     
      
     })
  }

  timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year ;
    return time;
  }

  totalEarnings(){
    let earnings = 0;
    this.state.commissions.forEach(user => {
        earnings = earnings + user.commission;
    })
    if(this.state.user.rankAdvanceBonus){
        this.state.user.rankAdvanceBonus.forEach(bonus => {
            earnings = earnings + bonus.bonus;
        })
    }
    return earnings;
}

  render() {
    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: 134,
          margin: 0,
          paddingTop: 10,
          display: "flex",
          backgroundColor: "#707070",
          overflow: "scroll",
          overflowY: "hidden",
          borderBottom: '1px solid #303030'
        }}
      >
        <div className="slider-card">
          <Grid>
            <Sdiv>
              <P>sub</P>
              <P>-</P>
            </Sdiv>
            <Sdiv>
              <P>1</P>
              <P>-</P>
            </Sdiv>
            <Sdiv>
              <P>2</P>
              <P>-</P>
            </Sdiv>
            <Sdiv>
              <P>3</P>
              <P>-</P>
            </Sdiv>
            <Sdiv>
              <P>4</P>
              <P>-</P>
            </Sdiv>
          </Grid>

          <Sdiv>
            <P>level {this.state.user ? this.state.user.learningProgress.level : ""} progress: {this.state.progress}%</P>
            <LinearProgress
              variant="determinate"
              value={this.state.progress}
              style={{ margin: 5, height: 10, borderRadius: 3, color: "#3c3b6e" }}
            />
          </Sdiv>
        </div>

        <div className="slider-card" style={{ width: 160, minWidth: 160 }}>
          <Sdiv>
            <P>Review</P>
            <P>-</P>
          </Sdiv>
          <Sdiv style={{ marginTop: 10 }}>
            <P>ETA</P>
            <P>{this.state.eta}</P>
          </Sdiv>
        </div>

        <div className="slider-card">
          <Grid50>
            <Sdiv>
              <P>Referrels</P>
              {/* <P>{this.state.user.first != [] ? this.state.user.first.length : ''}</P> */}
            </Sdiv>
            <Sdiv>
              <P>Earnings</P>
              <P>${this.totalEarnings()}</P>
            </Sdiv>
          </Grid50>
          {/* <Sdiv>
            <P>Until Next Rank: {this.state.user.rank == 'hero' ? 5 - this.state.user.first.length : this.state.user.rank == 'instructor' ? 21 - this.state.user.first.length : 0 }</P>
            <LinearProgress
              variant="determinate"
              value={0}
              style={{ margin: 5, height: 10, borderRadius: 3 }}
            />
          </Sdiv> */}
        </div>
        <img src={this.state.user.rank == 'hero' ? heroMedal : this.state.user.rank == 'instructor' ? instructorMedal : this.state.user.rank == 'teacher' ? teacherMedal : ''} style={{height: '90%', margin: 'auto 20px'}} />
      </div>
    );
  }
}

const Grid = styled.div`
  padding: 0px;
  margin: 0px;
  display: grid;
  width: 340px;
  grid-template-columns: 20% 20% 20% 20% 20%;
`;

const Grid50 = styled.div`
  padding: 0px;
  margin: 0px;
  display: grid;
  width: 340px;
  grid-template-columns: 50% 50%;
`;

const P = styled.p`
  margin: 0px;
  padding: 0px;
  color: #800000;
`;

const Sdiv = styled.div`
  background-color: #d2d2d2;
  border-radius: 5px;
  padding: 1px 8px;
  margin: 4px;
`;
