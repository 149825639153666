import React, { Component } from "react";
import  '../../../App.css'
import { TextField, Button } from "@material-ui/core";
import storage from "../../../config";
import * as firebase from "firebase";
import { fire } from "../../../config";
import "../../../config" ;
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import InstagramIcon from '@material-ui/icons/Instagram';
import Paper from '@material-ui/core/Paper';


export default class invite extends Component {
    constructor(){
        super();
        this.state ={
            referral: '',
            whatsapp: '',
            facebook: ''
            

        }
    }

    componentDidMount(){
        firebase.firestore().collection('Users').doc(localStorage.getItem('email')).onSnapshot(res => {
           console.log(res.data())
           this.setState({referral: res.data().referral})
           this.setState({whatsapp: "https://wa.me/?text=Estoy%20aprendiendo%20inglés%20y%20ganando%20dinero%20con%20NuParla,%20y%20creo%20que%20te%20fascinará%20también.%20Mira%20esto%3A%20https%3A%2F%2Fnuparla.com%2Freferral%2F" + res.data().referral + "%20Las%20lecciones%20en%20línea%20son%20fáciles%20de%20entender,%20divertidas%20y%20nos%20pagan%20cada%20viernes.%20¡Tu%20opinión%20es%20muy%20importante%20para%20mi!"})
            this.setState({facebook: "https://www.facebook.com/sharer/sharer.php?u=nuparla.com/referral/" +res.data().referral})
            this.setState({email: "mailto:?to=&body=Estoy%20aprendiendo%20inglés%20y%20ganando%20dinero%20con%20NuParla,%20y%20creo%20que%20te%20fascinará%20también.%20Mira%20esto%3A%20https%3A%2F%2Fnuparla.com%2Freferral%2F" + res.data().referral + "%20Las%20lecciones%20en%20línea%20son%20fáciles%20de%20entender,%20divertidas%20y%20nos%20pagan%20cada%20viernes.%20¡Tu%20opinión%20es%20muy%20importante%20para%20mi!,&subject=NuParla Invitation"})
      
          })


    }



    render(){
        return (
            <div style={{padding: '40px auto', minHeight: 400, width: '100%', backgroundColor: '#707070',padding: 0, textAlign: 'center'}}>
                
                <h1 style={{fontSize: 'xx-large', color: '#edd290', paddingTop: 20}}>Ayuda a que otros se beneficien con hablar inglés…</h1>
                <h2 style={{fontSize: 'x-large', fontWeight: 'bold', color: '#eee390'}}>¡Invita a tus amigos y comienza a ganar dinero!</h2>
                <Paper style={{margin: 'auto', padding: 30, margin: '30px auto', maxWidth: 600, minWidth: 300, textAlign: 'center', backgroundColor:  '#202020', boxShadow: '0px 3px 6px rgba(0,0,0,0.4)'}}>
                <p style={{color: 'white'}}>Comparte tu link peronsal:</p>
                <p style={{color: 'white', fontSize: 'large'}}>{"https://nuparla.com/referral/" +this.state.referral}</p>
                <a href={this.state.whatsapp}><Button variant="contained" style={{backgroundColor: '#25d366', color: 'white', margin: '15px 5px'}} ><WhatsAppIcon style={{marginRight: 5, color: 'white'}} /> Whatsapp</Button></a>
                <a href={this.state.facebook} target="aksakjbkjbdkjhe"><Button variant="contained" style={{backgroundColor: '#3c5898', color: 'white',margin: '15px 5px'}} ><FacebookIcon style={{marginRight: 5, color: 'white'}} /> Facebook</Button></a>
                {/* <a href={this.state.whatsapp}><Button variant="contained" style={{backgroundColor: '#dd2a7b', color: 'white',margin: '15px 5px'}}  ><InstagramIcon style={{marginRight: 5, color: 'white'}} /> Instagram</Button></a> */}
                <a href={this.state.email}><Button variant="contained" style={{backgroundColor: '#b23121', color: 'white', margin: '15px 5px'}}  ><EmailIcon style={{marginRight: 5, color: 'white'}} /> Email</Button></a>
                </Paper>
                
                
            </div>
        )
    }
}