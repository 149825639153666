import React, { Component } from "react";
import "../../App.css";
import styled from "styled-components";
import AppBar from "./AppBar";
import StatusSlider from "./statusSlider";
import AprendeSlider from "./aprendeSlider";
import Level1 from "./Courses/level1";
import Level2 from "./Courses/level2";
import Level3 from "./Courses/level3";
import Level4 from "./Courses/level4";
import Bonus from './Courses/bonus';
import Intermediate from "./Courses/intermediate";
import Final from "./Courses/final";
import Intro from './Courses/Intro';
import messaging from './Messaging/chat'
import Login from '../Affiliate/Auth/login'
import Reset from '../Affiliate/Auth/resetPassword'
import Profile from '../Affiliate/Profile/profile'
import Tickets from '../Affiliate/Tickets/tickets'
import Commissions from '../Affiliate/Commissions/commissions'
import Invite from '../Affiliate/Referral/invite'
import Training from '../Affiliate/Training/training'
import Marketing from '../Affiliate/Marketing/marketing'
import Landing from '../Affiliate/Landing/landing'
import TeamView from '../Affiliate/TeamView/teamView'
import ReactPlayer from "react-player";
import { TextField, Button } from "@material-ui/core";
import MicIcon from '@material-ui/icons/Mic';
import StopIcon from '@material-ui/icons/Stop';
import SendIcon from '@material-ui/icons/Send';
import MicRecorder from 'mic-recorder-to-mp3';
import Congratulations from './congratulations'
import BecomeAffiliate from './intro'
import '../../config' ;
import storage from "../../config";
import * as firebase from "firebase";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import { Dialog } from "@material-ui/core";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });


export default class affiliate extends Component {
  constructor() {
    super();
    this.state = {
      user: {
        address: {},
        url: '',
        learningProgress: {
          level: ''
        }
      },
      dialog: false,
      intro: '', 
      watched: false,
      recording: false,
      audio: '',
      isRecording: false,
        blobURL: '',
    };
  }

  start = () => {
    this.setState({recording: true})
    
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true });
        }).catch((e) => console.error(e));
    
  };

  stop = () => {
    this.setState({recording: false})
    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob)
        console.log(blob)
        this.setState({ blobURL, audio: blob, isRecording: false });
      }).catch((e) => console.log(e));
  };

  send = () => {
    let upload = storage
        .ref(this.state.audio.size.toString())
        .put(this.state.audio);
      upload.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref(this.state.audio.size.toString())
            .getDownloadURL()
            .then(async (url) => {
              firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
                intro: true,
            }).then(res => {
                  console.log(res)
              })

              firebase.firestore().collection('WhyAudios').doc(localStorage.getItem('email')).set({
                whyAudio: url,
                date: Date.now()
            }).then(res => {
                  console.log(res)
              })

            
        })})
  }

  componentDidMount() {
    console.log('here')



    if (localStorage.getItem('email')){
      console.log('here')
      firebase.firestore().collection('Users').doc(localStorage.getItem('email')).onSnapshot(res => {
        
        this.setState({user: res.data()})
        console.log(res.data().learningProgress.level)
        localStorage.setItem('level', res.data().learningProgress.level)
        if (res.data().intro == false){
          this.setState({dialog: true})
        }
        
        })
  
        // firebase.firestore().collection('Training').doc('intro').onSnapshot(res => {
          
        //   this.setState({intro: res.data().url})
         
          
        //   })

    }
    

  }

  render() {

    if (localStorage.getItem("AffiliateLogin") != "true"  ){
      return (
        <Login />
      )
    } else if (localStorage.getItem('paid') == "false"){
      console.log('nottrue')
      console.log(localStorage.getItem('paid'))
      return (
        <Redirect
          to={{
            pathname: "/payment",
            state: {
              payment: localStorage.getItem('payment'),
              userName: localStorage.getItem('firstName'),
              email: localStorage.getItem('email'),
              plan: localStorage.getItem('plan'),
            },
          }}
        />
      )
    }else if (localStorage.getItem('paid') == "true" && localStorage.getItem("AffiliateLogin") == "true") { 
      return (
        <Router>
          <div style={{ textAlign: "center", height: '100vh', backgroundColor: '#707070' }}>
            <AppBar url= {this.state.user.url} rank = {this.state.user.rank} id={this.state.user.userName} country={this.state.user.address.country} affiliate = {this.state.user.affiliate} />
            <div className="container-div" >
              <StatusSlider />
              <h3 style={{ margin: 2, padding: 0, fontSize: "x-large", fontFamily: 'Apple Chancery', color: '#edd290' }}>
                Aprende inglés
              </h3>
  
              <AprendeSlider />
              <Switch>
              <Route exact path="/affiliate/" component={Landing}  />
  
                <Route path="/affiliate/level1" component={Level1}  />
                <Route path="/affiliate/level2" component={Level2}  />
                <Route path="/affiliate/level3" component={Level3}  />
                <Route path="/affiliate/level4" component={Level4}  />
                <Route path="/affiliate/introduction" component={Intro} />
                <Route path="/affiliate/intermediate" component={Intermediate} />
                <Route path="/affiliate/final" component={Final} />
                <Route path="/affiliate/messaging" component={messaging} />
                <Route path="/affiliate/profile" component={Profile} />
                <Route path="/affiliate/tickets" component={Tickets} />
                <Route path="/affiliate/commissions" component={Commissions} />
                <Route path="/affiliate/invite" component={Invite} />
                <Route path="/affiliate/training" component={Training} />
                <Route path="/affiliate/marketing" component={Marketing} />
                <Route path="/affiliate/team-view" component={TeamView} />
                <Route path="/affiliate/agreement" component={BecomeAffiliate} />
                <Route path="/affiliate/bonus-courses" component={Bonus} />
              </Switch>
            </div>
            <Dialog 
            open={this.state.dialog}
            onClose={()=> this.setState({dialog: false})}
            fullWidth
            >
              <div style={{textAlign: 'center'}}>
              <h2>Welcome to NuParla</h2>
              <p>Start by watching the following video</p>
              <ReactPlayer
                                          onContextMenu ={(e) => e.preventDefault()}
                                              style={{ margin: "0px auto 20px auto", width: 380 }}
                                              controls={true}
                                              width={560}
                                              playing={false}
                                              config={{ file: { 
                                              attributes: {
                                                  controlsList: 'nodownload'
                                              }
                                              }}}
                                              url="https://firebasestorage.googleapis.com/v0/b/nuparla-bbc0a.appspot.com/o/One%20minute%20video%20intro%20back%20office.mp4?alt=media&token=55bc92da-48c9-4de4-b646-216534ee3749"
                                          />
  
              </div>
              <Button variant="contained" color='primary' style={{margin: '10px auto', width: 100}} onClick={()=> {
                this.setState({dialog: false})
                
              }}>Skip</Button>
              <Button variant="contained" color='primary' style={{margin: '10px auto', width: 100}} onClick={()=> {
                this.setState({dialog: false})
                firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
                  intro: true,
              }).then(res => {
                    console.log(res)
                })
                
              }}>Close</Button>
              
              
            </Dialog>
          </div>
        </Router>
      );
    } else {
      return (
        <div>

        </div>
      )
    }
    
  }
}
