import React, { Component } from "react";
import  '../../../App.css'
import { TextField, Button } from "@material-ui/core";
import storage from "../../../config";
import * as firebase from "firebase";
import { fire } from "../../../config";
import "../../../config" ;
import styled from "styled-components";

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import InstagramIcon from '@material-ui/icons/Instagram';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import avatar from '../../../Images/user-avatar.png';
import Hero from '../../../Images/iron.png';
import Instructor from '../../../Images/bronze.png'
import Teacher from '../../../Images/silver.png'



export default class commissions extends Component {
    constructor(){
        super();
        this.state ={
            
            recentGraduates: [],
            rankAdvances: [],
            topAffiliates: [],
            topStudents: []

            

        }
    }

    componentDidMount(){
        firebase.firestore().collection('Users').orderBy("learningProgress.lesson", "desc").limit(5).get().then(res => {
            console.log(res.docs)

            
        
            this.setState({topStudents: res.docs})
            
        })

        firebase.firestore().collection('Users').orderBy("first", "desc").limit(5).get().then(res => {
            console.log(res.docs)

            
        
            this.setState({topAffiliates: res.docs})
            
        })


    }

    timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year ;
        return time;
      }

     


    




    render(){
        return (
            <div style={{backgroundColor: '#909090', height: '100%'}}>
                <p>Pre-launch beta version - (5th February - 5th April)</p>

                <div style={{backgroundColor: '#800000', padding: 10, borderRadius: 10, color: '#800000', width: '80%', margin: 'auto'}}>
                    <h1 style={{color: 'white', fontSize: 'x-large'}}>LATEST UPDATES</h1>

                </div>

                <div style={{width: 'auto',padding: 10,  margin:'10px 3vw', textAlign: 'left', border: '2px solid #edd490', borderRadius: 10, backgroundColor: '#bebebe', boxShadow: '0px 2px 10px rgba(0,0,0,0.6)'}}>
                <H>Latest NuParla News:</H>
                    <p>Opening on February 5th, 2021!</p>


                </div>

                <Grid>

                    <div style={{width: '90%', margin: 'auto', height: 500}}>
                        <H>Top Students: </H>
                        <Paper style={{height: 422}}>
                        <TableContainer component={Paper}>
                            <Table  >
                                <TableHead >
                                <TableRow style={{height: 40}}>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left" style={{color: '#3c3b6e', fontWeight: 'bold'}}>User</TableCell>
                                    <TableCell align="right" style={{color: '#3c3b6e', fontWeight: 'bold'}}>Level</TableCell>
                                   
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {this.state.topStudents.map((user) => (
                                    <TableRow>
                                        <TableCell align="left" ><div style={{maxWidth: 40, height: 40, borderRadius: 40, margin: 'auto 1px', overflow: 'hidden'}}><img style={{width: 40, borderRadius: 40, margin: 'auto 2px'}} src={user.data().url == undefined ? avatar : user.data().url == null ? avatar : user.data().url != "" ? user.data().url : avatar} /></div></TableCell>
                                    <TableCell align="left">
                                        {user.data().name}
                                    </TableCell>
                                    <TableCell align="right">{user.data().learningProgress && user.data().learningProgress.level}</TableCell>
                                   
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </Paper>
                        


                    </div>
                    
                    <div style={{width: '90%', margin: 'auto', height: 500}}>
                        <H>Top Affiliates: </H>
                        <Paper style={{height: 422}}>
                        <TableContainer component={Paper}>
                            <Table  >
                                <TableHead>
                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left" style={{color: '#3c3b6e', fontWeight: 'bold'}}>User</TableCell>
                                    <TableCell align="right" style={{color: '#3c3b6e', fontWeight: 'bold'}}>Rank</TableCell>
                                   
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {this.state.topAffiliates.map((user) => (
                                    <TableRow>
                                        <TableCell align="left"><div style={{width: 40, height: 40, borderRadius: 40, margin: 'auto', overflow: 'hidden'}}><img style={{width: 40, borderRadius: 40, margin: 'auto'}} src={user.data().url == undefined ? avatar : user.data().url == null ? avatar : user.data().url != "" ? user.data().url : avatar} /></div></TableCell>
                                    <TableCell align="left">
                                        {user.data().name}
                                    </TableCell>
                                <TableCell align="right">{user.data().rank == 'hero' ? <img style={{width: 35, height: 35, margin: 'auto'}} src={Hero}/> : user.data().rank == 'instructor' ? <img style={{width: 35, height: 35, margin: 'auto'}} src={Instructor}/> : user.data().rank == 'teacher' ? <img style={{width: 35, height: 35, margin: 'auto'}} src={Teacher}/> : '-'}</TableCell>
                                   
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </Paper>
                        


                    </div>
                    

                </Grid>
                <Grid>

                

                    <div style={{width: '90%', margin: 'auto', height: 500}}>
                        <H>Recent Rank Advances: </H>
                        <Paper style={{height: 422}}>
                        <TableContainer component={Paper}>
                            <Table  >
                                <TableHead>
                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left" >User</TableCell>
                                    <TableCell align="right">Level</TableCell>
                                   
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {this.state.rankAdvances.map((user) => (
                                    <TableRow>
                                        <TableCell align="left"><div style={{width: 40, height: 40, borderRadius: 40, margin: 'auto', overflow: 'hidden'}}><img style={{width: 40, borderRadius: 40, margin: 'auto'}} src={user.data().url == undefined ? avatar : user.data().url == null ? avatar : user.data().url != "" ? user.data().url : avatar} /></div></TableCell>
                                    <TableCell align="left">
                                        {user.data().name}
                                    </TableCell>
                                    <TableCell align="right">{user.data().learningProgress && user.data().learningProgress.level}</TableCell>
                                   
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </Paper>
                        


                    </div>

                    <div style={{width: '90%', margin: 'auto', height: 500}}>
                        <H>Recent level Advances: </H>
                        <Paper style={{height: 422}}>
                        <TableContainer component={Paper}>
                            <Table  >
                                <TableHead>
                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left" >User</TableCell>
                                    <TableCell align="right">Level</TableCell>
                                   
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {this.state.rankAdvances.map((user) => (
                                    <TableRow>
                                        <TableCell align="left"><div style={{width: 40, height: 40, borderRadius: 40, margin: 'auto', overflow: 'hidden'}}><img style={{width: 40, borderRadius: 40, margin: 'auto'}} src={user.data().url == undefined ? avatar : user.data().url == null ? avatar : user.data().url != "" ? user.data().url : avatar} /></div></TableCell>
                                    <TableCell align="left">
                                        {user.data().name}
                                    </TableCell>
                                    <TableCell align="right">{user.data().learningProgress && user.data().learningProgress.level}</TableCell>
                                   
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </Paper>
                        


                    </div>

                </Grid>
                
                

                    

                
            </div>
        )
    }
}

const Grid = styled.div `
    display: grid;
    grid-template-columns: 50% 50%;
    width: auto;
    margin: 10px 3vw;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #edd490;
    background-color: #bebebe;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.6);

    @media (max-width: 700px) {
    display: flex;
    flex-wrap: wrap;
     }
`


const H = styled.h3 `
    color: #edd490;
    font-size: large;
    font-weight: bold;
    font-family: cursive;
    background-color: #3c3b6e;
    padding: 4px;
    border-radius: 5px;
    text-align: center;
    width: 80%;
    margin: 8px auto;


`