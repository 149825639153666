import React, { Component } from "react";
import "../../../App.css";
import styled from "styled-components";
import { fire } from "../../../config";
import storage from "../../../config";
import "../../../config";
import * as firebase from "firebase";
import ReactPlayer from "react-player";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { Input } from "antd";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TextField } from "@material-ui/core";
import Dropzone from 'react-dropzone'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ReactAudioPlayer from 'react-audio-player';



export default class level1 extends Component {
  constructor() {
    super();
    this.state = {
      course: "",
      video: "",
      url: "",
      Course1: [],
      Course2: [],
      Course3: [],
      Course4: [],
      Course5: [],
      Course6: [],
      Course7: [],
      currentWord: '',
      currentOption: ''
    };
  }

  componentDidMount() {
    this.getCourses();
  }
  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }
  

  getCourses() {
    firebase
      .database()
      .ref("Courses")
      .child("level1")
      .once("value")
      .then((snapshot) => {
        snapshot.val().map((course, index) => {
          if (index == 0) {
            this.setState({ Course1: course });
          } else if (index == 1) {
            this.setState({ Course2: course });
          } else if (index == 2) {
            this.setState({ Course3: course });
          } else if (index == 3) {
            this.setState({ Course4: course });
          } else if (index == 4) {
            this.setState({ Course5: course });
          } else if (index == 5) {
            this.setState({ Course6: course });
          } else if (index == 6) {
            this.setState({ Course7: course });
          }
        });
      });
  }

  newLesson1() {
    let { Course1 } = this.state;
    Course1.push({
      mainVideo: "",
      teachingVideo: "",
      audioStory: "",
      textStory: "",
      reviewVideo: "",
    });
    this.setState({ Course1 });
  }

  newLesson2() {
    let { Course2 } = this.state;
    Course2.push({
      mainVideo: "",
      teachingVideo: "",
      audioStory: "",
      textStory: "",
      reviewVideo: "",
    });
    this.setState({ Course2 });
  }

  newLesson3() {
    let { Course3 } = this.state;
    Course3.push({
      mainVideo: "",
      teachingVideo: "",
      audioStory: "",
      textStory: "",
      reviewVideo: "",
    });
    this.setState({ Course3 });
  }

  newLesson4() {
    let { Course4 } = this.state;
    Course4.push({
      mainVideo: "",
      teachingVideo: "",
      audioStory: "",
      textStory: "",
      reviewVideo: "",
    });
    this.setState({ Course4 });
  }

  newLesson5() {
    let { Course5 } = this.state;
    Course5.push({
      mainVideo: "",
      teachingVideo: "",
      audioStory: "",
      textStory: "",
      reviewVideo: "",
    });
    this.setState({ Course5 });
  }

  newLesson6() {
    let { Course6 } = this.state;
    Course6.push({
      mainVideo: "",
      teachingVideo: "",
      audioStory: "",
      textStory: "",
      reviewVideo: "",
    });
    this.setState({ Course6 });
  }

  newLesson7() {
    let { Course7 } = this.state;
    Course7.push({
      mainVideo: "",
      teachingVideo: "",
      audioStory: "",
      textStory: "",
      reviewVideo: "",
    });
    this.setState({ Course7 });
  }

  upload(file) {
    console.log("here");
    let { video } = this.state;
    const upload = storage.ref(file.name).put(file);
    upload.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref(video.name)
          .getDownloadURL()
          .then((url) => {
            console.log(url);
            this.setState({ url: url });
          });
      }
    );
  }

  saveLessons(index, lessons) {
    console.log(index)
    firebase
      .database()
      .ref("Courses")
      .child("level1")
      .child(0)
      .child(index)
      .set(lessons)
      .then(console.log("done"));
  }

  render() {
    const { Dragger } = Upload;

    const props = {
      name: "file",
      multiple: false,
      action: (file) => {
        this.upload(file);
      },
      onChange(info, index) {
        const { status } = info.file;
        if (status !== "uploading") {
          console.log(info.file, info.fileList, index);
        }
        if (status === "done") {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };
    let {
      Course1,
      Course2,
      Course3,
      Course4,
      Course5,
      Course6,
      Course7,
    } = this.state;
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          margin: "20px auto",
        }}
      >
        <ExpansionPanel

          expanded={this.state.course == "1"}
          onChange={() =>
            this.state.course == "1"
              ? this.setState({ course: "" })
              : this.setState({ course: "1" })
          }
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>Course 1</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: "100%" }}>
              
              {Course1.map((lesson, index) => {
                return (
                  <Paper
                    elevation={1}
                    style={{
                      margin: 20,
                      padding: 20,
                      textAlign: "center",
                      backgroundColor: "#ebebeb",
                    }}
                  >
                    <h2 style={{backgroundColor: '#505050', padding: 20, width: 500, color: '#e9c979', fontWeight: 'bold', borderRadius: 5, margin: '10px auto'}} >Lesson {index + 1}</h2>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h2 className="montserrat">Main Video</h2>

                      {/* <input
                        id="contained-button-file"
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) => {
                          lesson.mainVideo = e.target.files[0];
                        }}
                      /> */}
                      

                      {lesson.mainVideo && <ReactPlayer
                        
                          style={{ margin: "10px auto 10px auto", border: '1px solid #bebebe' }}
                          height="240px"
                          width="426px"
                          controls={true}
                          playing={false}
                          
                          url={lesson.mainVideo}
                        />}

                      <TextField  margin="dense" style={{width: 400}} value={lesson.mainVideo} variant='outlined' placeholder="copy video link here" onChange={(e)=> {
                        lesson.mainVideo = e.target.value;
                        this.setState({Course1})
                      }}/>
                      
                    </Paper>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h2 className="montserrat">Teaching Video</h2>

                      
                      {lesson.teachingVideo && <ReactPlayer
                        
                        style={{ margin: "10px auto 10px auto", border: '1px solid #bebebe' }}
                        height="240px"
                        width="426px"
                        controls={true}
                        playing={false}
                        
                        url={lesson.teachingVideo}
                      />}
                      <TextField style={{width: 400}} margin="dense" value={lesson.teachingVideo} variant='outlined' placeholder="copy video link here" onChange={(e)=> {
                        lesson.teachingVideo = e.target.value;
                        this.setState({Course1})
                      }}/>
                    </Paper>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h2 className="montserrat">Audio Story</h2>
                      
                      <div style={{border: '1px solid #bebebe',maxWidth: 300, margin: 'auto', padding: 20, borderRadius: 5}}> 
                        <Dropzone onDrop={acceptedFiles => {
                          lesson.audioStory = acceptedFiles[0]
                          this.setState({Course1})
                          console.log(lesson.audioStory)
                        }}>
                          {({getRootProps, getInputProps}) => (
                            <section>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop the audio story. Or click to upload</p>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </div>
                      {lesson.audioStory && <ReactAudioPlayer
                      style={{margin: '10px auto'}}
                        src={typeof lesson.audioStory == "string" ? lesson.audioStory :  URL.createObjectURL(lesson.audioStory)}
                        
                        controls
                      />}
                      
                      {/* <input
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) =>
                          (lesson.audioStory = e.target.files[0])
                        }
                      /> */}
                    </Paper>

                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h2 className="montserrat">Text Story</h2>

                      <Input.TextArea
                      value={lesson.textStory}
                        onChange={(e) => {
                          lesson.textStory = e.target.value;
                        }}
                      />
                    </Paper>


                    <Paper
                    elevation={2}
                    style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Exercise Questions</h3>
                      <p>Select a module...</p>
                      <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around'}}>
                        <Button variant="contained" style={{backgroundColor: '#ffff66', width: 300, height: 60, margin: 10}} onClick={()=> {
                          if (lesson.exercise){
                            lesson.exercise.push({
                              type: 'mcq',
                              question: '',
                              options: [],
                              correctOption: '',
                              url: ''
                            }) 

                            this.setState({Course1})
                          } else {
                            lesson.exercise = []
                            lesson.exercise.push({
                              type: 'mcq',
                              question: '',
                              options: [],
                              correctOption: '',
                              url: ''
                            }) 
                            this.setState({Course1})

                          }
                          
                        }}>Multiple Choice </Button>







                        <Button variant="contained" style={{backgroundColor: '#00cc00', width: 300, height: 60, margin: 10}} onClick={()=> {
                          if (lesson.exercise){
                            lesson.exercise.push({
                              type: 'fill the gap text',
                              question: [''],
                              options: [],
                              correctOptions: [''],
                              
                            }) 

                            this.setState({Course1})
                          } else {
                            lesson.exercise = []
                            lesson.exercise.push({
                              type: 'fill the gap text',
                              question: [''],
                              options: [],
                              correctOptions: [''],
                          
                            }) 
                            this.setState({Course1})

                          }
                          
                        }}>Fill the gap with correct word (text)</Button>
                        
                        <Button variant="contained" style={{backgroundColor: '#0099ff', width: 300, height: 60, margin: 10}} onClick={()=> {
                          if (lesson.exercise){
                            lesson.exercise.push({
                              type: 'fill the blank audio',
                              question: [''],
                              words: [],
                              audio: ''
                              
                            }) 

                            this.setState({Course1})
                          } else {
                            lesson.exercise = []
                            lesson.exercise.push({
                              type: 'fill the blank audio',
                              question: [''],
                              words: [],
                              audio: ''
                          
                            }) 
                            this.setState({Course1})

                          }
                          
                        }}>Fill the blank (audio)</Button>
                        <Button variant="contained" style={{backgroundColor: '#00cc00', width: 300, height: 60, margin: 10}} onClick={()=> {
                          if (lesson.exercise){
                            lesson.exercise.push({
                              type: 'match the pair',
                              pairs: []
                              
                            }) 

                            this.setState({Course1})
                          } else {
                            lesson.exercise = []
                            lesson.exercise.push({
                              type: 'match the pair',
                              pairs: []
                          
                            }) 
                            this.setState({Course1})

                          }
                          
                        }}>Match the pair</Button>
                        <Button variant="contained" style={{backgroundColor: '#0099ff', width: 300, height: 60, margin: 10}} onClick={()=> {
                          if (lesson.exercise){
                            lesson.exercise.push({
                              type: 'correct order',
                              sentence: ''
                              
                            }) 

                            this.setState({Course1})
                          } else {
                            lesson.exercise = []
                            lesson.exercise.push({
                              type: 'correct order',
                              sentence: '',
                              shuffled: ''
                          
                            }) 
                            this.setState({Course1})

                          }
                          
                        }}>Correct Order</Button>
                        <Button variant="contained" style={{backgroundColor: '#9966ff', width: 300, height: 60, margin: 10}} onClick={()=> {
                          if (lesson.exercise){
                            lesson.exercise.push({
                              type: 'true false',
                              sentence: '',
                              status: ''
                              
                            }) 

                            this.setState({Course1})
                          } else {
                            lesson.exercise = []
                            lesson.exercise.push({
                              type: 'true false',
                              sentence: '',
                              status: ''
                          
                            }) 
                            this.setState({Course1})

                          }
                          
                        }}>True / False</Button>
                      </div>
                      
                      <div > 
                        {lesson.exercise && lesson.exercise.map(question => {
                          if (question.type == 'mcq'){
                            return (
                              <div style={{ margin: '30px 0px', textAlign: 'center'}}>
                                <h2 style={{borderRadius: 5, padding: 10, backgroundColor: '#00688b', color: 'white'}}>Multiple Choice Question</h2>
                                <TextField style={{margin: '15px 15px 5px 15px', width: '80%'}} placeholder="Enter Question here" variant="outlined" margin="dense" value={question.question} onChange={(e)=> {
                                  question.question = e.target.value;
                                  this.setState({Course1})
                                }}/>
                                
                                <div>
                                  <TextField value={this.state.currentOption} placeholder=" + add option" variant="outlined" margin= 'dense' onChange={(e)=>{this.setState({currentOption: e.target.value})}} onKeyPress={(e)=>{
                                    if (e.key == 'Enter'){
                                      if (this.state.currentOption != ''){
                                        question.options.push(this.state.currentOption)
                                      }
                                      
                                      this.setState({Course1})
                                      this.setState({currentOption: ''})
                                    }
                                  }} />
                                </div>

                                <div>
                                  {question.options.map(word => {
                                    return (
                                      <h4 style={{padding: 5, cursor: 'pointer', minWidth: 100, width: 'max-content', margin: '5px auto'}} className={question.correctOption == word ? "selected-word" : "word"}  onClick={()=> {
                                       if (question.correctOption == word){

                                       } else {
                                         question.correctOption = word
                                         this.setState({Course1})
                                       }
                                      }}>{word}</h4>
                                    )
                                  })

                                  }


                                </div>
                                

                              </div>
                            )
                          } else if (question.type == "fill the gap text"){
                            
                            return (
                              <div style={{margin: '30px 0px', textAlign: 'center'}}>
                                <h2 style={{borderRadius: 5, padding: 10, backgroundColor: '#00688b', color: 'white'}} >Fill in the gap (text)</h2>
                                <TextField style={{margin: '15px 15px 5px 15px', width: '80%'}} placeholder="Enter Question here" variant="outlined" margin="dense" value={question.question.join(' ')} onChange={(e)=>{
                                  
                                  let q = e.target.value.split(" ")
                                  question.question = q;
                                  this.setState({Course1})
                                }}/>

                                <div style={{display: 'flex', justifyContent: 'center', margin: 'auto'}}>
                                {question.question.map(word => {
                                  return (
                                    <h4 style={{margin: 5, padding: 5, cursor: 'pointer'}} className={question.options.filter(option => { return option == word }).length >= 1 ? "selected-word" : '' }  onClick={()=> {
                                      if (question.options.filter(option => {
                                        return option == word
                                      }).length >= 1 ){
                                        question.options = question.options.filter(option => {
                                          return option != word
                                        })
                                        this.setState({Course1})
                                        
                                      } else {
                                        question.options.push(word)
                                        this.setState({Course1})
                                        console.log(question.options)
                                      }
                                    }}>{word}</h4>
                                  )
                                })

                                }

                                </div>

                                <div style={{margin: '10px auto', padding: 10, width: '80%', border: '1px solid #bebebe',  borderRadius: 5}}>
                                  <h3>Words</h3>
                                  <div style={{display: 'flex', justifyContent: 'center'}}>
                                    {question.options.map(word => {
                                      return (
                                        <p className="selected-word" style={{margin: 5, cursor: 'pointer'}} onClick={()=> {
                                          question.options = question.options.filter(option => {
                                            return option != word
                                          })
                                          this.setState({Course1})
                                        }}>{word}</p>
                                      )
                                    })

                                    }

                                  </div>

                                  

                                  <TextField variant="outlined" margin="dense" style={{width: '90%', margin: 'auto'}} value={this.state.currentWord} placeholer="add more words..." onKeyPress={(e)=>{
                                    if (e.key == 'Enter'){
                                     
                                      question.options.push(this.state.currentWord)
                                      this.setState({Course1})
                                      this.setState({currentWord: ''})
                                    }
                                  }} onChange={(e)=> {
                                     this.setState({currentWord: e.target.value})

                                  }}/>


                                </div>
                                

                              </div>
                            )

                          } else if (question.type == "fill the blank audio"){
                            
                            return (
                              <div style={{margin: '30px 0px', textAlign: 'center'}}>
                                <h2 style={{borderRadius: 5, padding: 10, backgroundColor: '#00688b', color: 'white'}} >Fill in the gap (audio)</h2>
                                <TextField style={{margin: '15px 15px 5px 15px', width: '80%'}} placeholder="Enter Question here" variant="outlined" margin="dense" value={question.question.join(' ')} onChange={(e)=>{
                                  
                                  let q = e.target.value.split(" ")
                                  question.question = q;
                                  this.setState({Course1})
                                }}/>


                              <div style={{display: 'flex', justifyContent: 'center', margin: 'auto'}}>
                                {question.question.map(word => {
                                  return (
                                    <h4 style={{margin: 5, padding: 5, cursor: 'pointer'}} className={question.words.filter(option => { return option == word }).length >= 1 ? "selected-word" : '' }  onClick={()=> {
                                      if (question.words.filter(option => {
                                        return option == word
                                      }).length >= 1 ){
                                        question.words = question.words.filter(option => {
                                          return option != word
                                        })
                                        this.setState({Course1})
                                        
                                      } else {
                                        question.words.push(word)
                                        this.setState({Course1})
                                      
                                      }
                                    }}>{word}</h4>
                                  )
                                })

                                }
                                </div>

                                <div style={{border: '1px solid #bebebe',maxWidth: 300, margin: 'auto', padding: 20, borderRadius: 5}}> 
                                  <Dropzone onDrop={acceptedFiles => {
                                    
                                    question.audio = acceptedFiles[0]

                                    let upload = storage
                                    .ref(question.audio.name)
                                    .put(question.audio);
                                  upload.on(
                                    "state_changed",
                                    (snapshot) => {},
                                    (error) => {
                                      console.log(error);
                                    },
                                    () => {
                                      storage
                                        .ref(question.audio.name)
                                        .getDownloadURL()
                                        .then((url) => {
                                          console.log(url);
                                          question.audio = url;
                                        })})



                                    this.setState({Course1})
                                    
                                  }}>
                                    {({getRootProps, getInputProps}) => (
                                      <section>
                                        <div {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          <p>Drag 'n' drop the audio here. Or click to upload</p>
                                        </div>
                                      </section>
                                    )}
                                  </Dropzone>
                                </div>
                                {question.audio && <ReactAudioPlayer
                                style={{margin: '10px auto'}}
                                  src={typeof question.audio == "string" ? question.audio :  URL.createObjectURL(question.audio)}
                               
                                  controls
                                />}

                              </div>
                            )

                          }else if (question.type == "match the pair"){
                            
                            return (
                              <div style={{margin: '30px 0px', textAlign: 'center'}}>
                                <h2 style={{borderRadius: 5, padding: 10, backgroundColor: '#00688b', color: 'white'}} >Match the pairs</h2>
                                <Button variant="contained" style={{margin: 10}} onClick={()=>{
                                  question.pairs.push({
                                    word: '',
                                    phrase: ''
                                  })
                                  this.setState({Course1})
                                }}>Add a pair</Button>
                                
                                <div>
                                  {question.pairs.map(pair => {
                                    return (
                                      <div style={{display: 'flex', flexWrap: 'wrap', margin: 10, justifyContent: 'center'}}>
                                        <TextField value={pair.word} style={{margin: 5}} variant="outlined" margin="dense" label="word" placeholder="word" onChange={(e)=>{
                                          pair.word = e.target.value
                                          this.setState({Course1})

                                        }}/>
                                        <TextField value={pair.phrase} style={{margin: 5, width: 300}} variant="outlined" margin="dense" label="phrase" placeholder="phrase" onChange={(e)=>{
                                          pair.phrase = e.target.value
                                          this.setState({Course1})

                                        }}/>

                                      </div>
                                    )
                                  })

                                  }
                                </div>
                              </div>
                            )
                          } else if (question.type == "true false"){
                            
                            return (
                              <div style={{margin: '30px 0px', textAlign: 'center'}}>
                                <h2 style={{borderRadius: 5, padding: 10, backgroundColor: '#00688b', color: 'white'}} >True / False</h2>
                                <TextField variant="outlined" margin="dense" style={{width: '80%'}} placeholder="Enter the sentence" value={question.sentence} onChange={(e)=> {
                                  question.sentence = e.target.value;
                                  this.setState({Course1})
                                }}/>

                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                  <Button color={question.status == true ? "primary" : ""} variant="contained" style={{margin: 5}} onClick={()=>{
                                    question.status = true;
                                    this.setState({Course1})
                                  }}>True</Button>
                                  <Button color={question.status == false ? "primary" : ""} variant="contained" style={{margin: 5}} onClick={()=>{
                                    question.status = false;
                                    this.setState({Course1})
                                  }}>False</Button>

                                </div>
                                
                                
                              </div>
                            )
                          }else if (question.type == "correct order"){
                            
                            return (
                              <div style={{margin: '30px 0px', textAlign: 'center'}}>
                                <h2 style={{borderRadius: 5, padding: 10, backgroundColor: '#00688b', color: 'white'}} >Correct Order</h2>
                                <TextField variant="outlined" margin="dense" style={{width: '80%'}} placeholder="Enter the sentence" value={question.sentence.join(' ')} onChange={(e)=> {
                                  question.sentence = e.target.value.split(" ");
                                  const a = e.target.value.split(" ");
                                  question.shuffled = this.shuffle(a);
                                  this.setState({Course1})
                                }}/>

                               
                                
                                
                              </div>
                            )
                          }




                        })

                        }
                      </div>
                    </Paper>

                    <Button
                      variant="contained"
                      onClick={() => {
                        console.log(Course1)
                        if (typeof lesson.audioStory != 'string'){
                          let upload = storage
                          .ref(lesson.audioStory.name)
                          .put(lesson.audioStory);
                        upload.on(
                          "state_changed",
                          (snapshot) => {},
                          (error) => {
                            console.log(error);
                          },
                          () => {
                            storage
                              .ref(lesson.audioStory.name)
                              .getDownloadURL()
                              .then((url) => {
                                console.log(url);
                                lesson.audioStory = url;
                                        
                                  this.setState({
                                    Course1: Course1,
                                  });
                                  this.saveLessons(
                                    index,
                                    Course1
                                  );
                                });
                            }
                          );

                        } else {
                          this.saveLessons(
                            index,
                            Course1
                          );

                        }
                        
                        }
                    }
                               
                    >
                      Save and upload lesson
                    </Button>
                  </Paper>
                );
              })}
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.newLesson1()}
              >
                + New Lesson
              </Button>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={this.state.course == "2"}
          onChange={() =>
            this.state.course == "2"
              ? this.setState({ course: "" })
              : this.setState({ course: "2" })
          }
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>Course 2</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: "100%" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.newLesson2()}
              >
                + New Lesson
              </Button>
              {Course2.map((lesson, index) => {
                return (
                  <Paper
                    elevation={1}
                    style={{
                      margin: 20,
                      padding: 20,
                      textAlign: "center",
                      backgroundColor: "#ebebeb",
                    }}
                  >
                    <h3>Lesson {index + 1}</h3>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Main Video</h3>

                      <input
                        id="contained-button-file"
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) => {
                          lesson.mainVideo = e.target.files[0];
                        }}
                      />
                      {/* <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Upload
                  </Button>
                </label> */}
                    </Paper>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Teaching video</h3>
                      <input
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) =>
                          (lesson.teachingVideo = e.target.files[0])
                        }
                      />
                    </Paper>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Audio Story</h3>
                      <input
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) =>
                          (lesson.audioStory = e.target.files[0])
                        }
                      />
                    </Paper>

                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Text Story</h3>
                      <Input.TextArea
                        onChange={(e) => {
                          lesson.textStory = e.target.value;
                        }}
                      />
                    </Paper>

                    <Button
                      variant="contained"
                      onClick={() => {
                        let upload = storage
                          .ref(lesson.mainVideo.name)
                          .put(lesson.mainVideo);
                        upload.on(
                          "state_changed",
                          (snapshot) => {},
                          (error) => {
                            console.log(error);
                          },
                          () => {
                            storage
                              .ref(lesson.mainVideo.name)
                              .getDownloadURL()
                              .then((url) => {
                                console.log(url);
                                lesson.mainVideo = url;

                                upload = storage
                                  .ref(lesson.teachingVideo.name)
                                  .put(lesson.teachingVideo);
                                upload.on(
                                  "state_changed",
                                  (snapshot) => {},
                                  (error) => {
                                    console.log(error);
                                  },
                                  () => {
                                    storage
                                      .ref(lesson.teachingVideo.name)
                                      .getDownloadURL()
                                      .then((url) => {
                                        console.log(url);
                                        lesson.teachingVideo = url;

                                        const upload = storage
                                          .ref(lesson.audioStory.name)
                                          .put(lesson.audioStory);
                                        upload.on(
                                          "state_changed",
                                          (snapshot) => {},
                                          (error) => {
                                            console.log(error);
                                          },
                                          () => {
                                            storage
                                              .ref(lesson.audioStory.name)
                                              .getDownloadURL()
                                              .then((url) => {
                                                console.log(url);
                                                lesson.audioStory = url;
                                                this.setState({
                                                  Course2: Course2,
                                                });
                                                this.saveLessons(
                                                  index,
                                                  Course2
                                                );
                                              });
                                          }
                                        );
                                      });
                                  }
                                );
                              });
                          }
                        );
                      }}
                    >
                      Save and upload lesson
                    </Button>
                  </Paper>
                );
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={this.state.course == "3"}
          onChange={() =>
            this.state.course == "3"
              ? this.setState({ course: "" })
              : this.setState({ course: "3" })
          }
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>Course 3</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: "100%" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.newLesson3()}
              >
                + New Lesson
              </Button>
              {Course3.map((lesson, index) => {
                return (
                  <Paper
                    elevation={1}
                    style={{
                      margin: 20,
                      padding: 20,
                      textAlign: "center",
                      backgroundColor: "#ebebeb",
                    }}
                  >
                    <h3>Lesson {index + 1}</h3>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Main Video</h3>

                      <input
                        id="contained-button-file"
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) => {
                          lesson.mainVideo = e.target.files[0];
                        }}
                      />
                      {/* <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Upload
                  </Button>
                </label> */}
                    </Paper>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Teaching video</h3>
                      <input
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) =>
                          (lesson.teachingVideo = e.target.files[0])
                        }
                      />
                    </Paper>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Audio Story</h3>
                      <input
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) =>
                          (lesson.audioStory = e.target.files[0])
                        }
                      />
                    </Paper>

                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Text Story</h3>
                      <Input.TextArea
                        onChange={(e) => {
                          lesson.textStory = e.target.value;
                        }}
                      />
                    </Paper>

                    <Button
                      variant="contained"
                      onClick={() => {
                        let upload = storage
                          .ref(lesson.mainVideo.name)
                          .put(lesson.mainVideo);
                        upload.on(
                          "state_changed",
                          (snapshot) => {},
                          (error) => {
                            console.log(error);
                          },
                          () => {
                            storage
                              .ref(lesson.mainVideo.name)
                              .getDownloadURL()
                              .then((url) => {
                                console.log(url);
                                lesson.mainVideo = url;

                                upload = storage
                                  .ref(lesson.teachingVideo.name)
                                  .put(lesson.teachingVideo);
                                upload.on(
                                  "state_changed",
                                  (snapshot) => {},
                                  (error) => {
                                    console.log(error);
                                  },
                                  () => {
                                    storage
                                      .ref(lesson.teachingVideo.name)
                                      .getDownloadURL()
                                      .then((url) => {
                                        console.log(url);
                                        lesson.teachingVideo = url;

                                        const upload = storage
                                          .ref(lesson.audioStory.name)
                                          .put(lesson.audioStory);
                                        upload.on(
                                          "state_changed",
                                          (snapshot) => {},
                                          (error) => {
                                            console.log(error);
                                          },
                                          () => {
                                            storage
                                              .ref(lesson.audioStory.name)
                                              .getDownloadURL()
                                              .then((url) => {
                                                console.log(url);
                                                lesson.audioStory = url;
                                                this.setState({
                                                  Course3: Course3,
                                                });
                                                this.saveLessons(
                                                  index,
                                                  Course3
                                                );
                                              });
                                          }
                                        );
                                      });
                                  }
                                );
                              });
                          }
                        );
                      }}
                    >
                      Save and upload lesson
                    </Button>
                  </Paper>
                );
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={this.state.course == "4"}
          onChange={() =>
            this.state.course == "4"
              ? this.setState({ course: "" })
              : this.setState({ course: "4" })
          }
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>Course 4</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: "100%" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.newLesson4()}
              >
                + New Lesson
              </Button>
              {Course4.map((lesson, index) => {
                return (
                  <Paper
                    elevation={1}
                    style={{
                      margin: 20,
                      padding: 20,
                      textAlign: "center",
                      backgroundColor: "#ebebeb",
                    }}
                  >
                    <h3>Lesson {index + 1}</h3>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Main Video</h3>

                      <input
                        id="contained-button-file"
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) => {
                          lesson.mainVideo = e.target.files[0];
                        }}
                      />
                      {/* <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Upload
                  </Button>
                </label> */}
                    </Paper>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Teaching video</h3>
                      <input
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) =>
                          (lesson.teachingVideo = e.target.files[0])
                        }
                      />
                    </Paper>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Audio Story</h3>
                      <input
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) =>
                          (lesson.audioStory = e.target.files[0])
                        }
                      />
                    </Paper>

                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Text Story</h3>
                      <Input.TextArea
                        onChange={(e) => {
                          lesson.textStory = e.target.value;
                        }}
                      />
                    </Paper>

                    <Button
                      variant="contained"
                      onClick={() => {
                        let upload = storage
                          .ref(lesson.mainVideo.name)
                          .put(lesson.mainVideo);
                        upload.on(
                          "state_changed",
                          (snapshot) => {},
                          (error) => {
                            console.log(error);
                          },
                          () => {
                            storage
                              .ref(lesson.mainVideo.name)
                              .getDownloadURL()
                              .then((url) => {
                                console.log(url);
                                lesson.mainVideo = url;

                                upload = storage
                                  .ref(lesson.teachingVideo.name)
                                  .put(lesson.teachingVideo);
                                upload.on(
                                  "state_changed",
                                  (snapshot) => {},
                                  (error) => {
                                    console.log(error);
                                  },
                                  () => {
                                    storage
                                      .ref(lesson.teachingVideo.name)
                                      .getDownloadURL()
                                      .then((url) => {
                                        console.log(url);
                                        lesson.teachingVideo = url;

                                        const upload = storage
                                          .ref(lesson.audioStory.name)
                                          .put(lesson.audioStory);
                                        upload.on(
                                          "state_changed",
                                          (snapshot) => {},
                                          (error) => {
                                            console.log(error);
                                          },
                                          () => {
                                            storage
                                              .ref(lesson.audioStory.name)
                                              .getDownloadURL()
                                              .then((url) => {
                                                console.log(url);
                                                lesson.audioStory = url;
                                                this.setState({
                                                  Course4: Course4,
                                                });
                                                this.saveLessons(
                                                  index,
                                                  Course4
                                                );
                                              });
                                          }
                                        );
                                      });
                                  }
                                );
                              });
                          }
                        );
                      }}
                    >
                      Save and upload lesson
                    </Button>
                  </Paper>
                );
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={this.state.course == "5"}
          onChange={() =>
            this.state.course == "5"
              ? this.setState({ course: "" })
              : this.setState({ course: "5" })
          }
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>Course 5</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: "100%" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.newLesson5()}
              >
                + New Lesson
              </Button>
              {Course5.map((lesson, index) => {
                return (
                  <Paper
                    elevation={1}
                    style={{
                      margin: 20,
                      padding: 20,
                      textAlign: "center",
                      backgroundColor: "#ebebeb",
                    }}
                  >
                    <h3>Lesson {index + 1}</h3>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Main Video</h3>

                      <input
                        id="contained-button-file"
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) => {
                          lesson.mainVideo = e.target.files[0];
                        }}
                      />
                      {/* <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Upload
                  </Button>
                </label> */}
                    </Paper>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Teaching video</h3>
                      <input
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) =>
                          (lesson.teachingVideo = e.target.files[0])
                        }
                      />
                    </Paper>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Audio Story</h3>
                      <input
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) =>
                          (lesson.audioStory = e.target.files[0])
                        }
                      />
                    </Paper>

                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Text Story</h3>
                      <Input.TextArea
                        onChange={(e) => {
                          lesson.textStory = e.target.value;
                        }}
                      />
                    </Paper>

                    <Button
                      variant="contained"
                      onClick={() => {
                        let upload = storage
                          .ref(lesson.mainVideo.name)
                          .put(lesson.mainVideo);
                        upload.on(
                          "state_changed",
                          (snapshot) => {},
                          (error) => {
                            console.log(error);
                          },
                          () => {
                            storage
                              .ref(lesson.mainVideo.name)
                              .getDownloadURL()
                              .then((url) => {
                                console.log(url);
                                lesson.mainVideo = url;

                                upload = storage
                                  .ref(lesson.teachingVideo.name)
                                  .put(lesson.teachingVideo);
                                upload.on(
                                  "state_changed",
                                  (snapshot) => {},
                                  (error) => {
                                    console.log(error);
                                  },
                                  () => {
                                    storage
                                      .ref(lesson.teachingVideo.name)
                                      .getDownloadURL()
                                      .then((url) => {
                                        console.log(url);
                                        lesson.teachingVideo = url;

                                        const upload = storage
                                          .ref(lesson.audioStory.name)
                                          .put(lesson.audioStory);
                                        upload.on(
                                          "state_changed",
                                          (snapshot) => {},
                                          (error) => {
                                            console.log(error);
                                          },
                                          () => {
                                            storage
                                              .ref(lesson.audioStory.name)
                                              .getDownloadURL()
                                              .then((url) => {
                                                console.log(url);
                                                lesson.audioStory = url;
                                                this.setState({
                                                  Course5: Course5,
                                                });
                                                this.saveLessons(
                                                  index,
                                                  Course5
                                                );
                                              });
                                          }
                                        );
                                      });
                                  }
                                );
                              });
                          }
                        );
                      }}
                    >
                      Save and upload lesson
                    </Button>
                  </Paper>
                );
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={this.state.course == "6"}
          onChange={() =>
            this.state.course == "6"
              ? this.setState({ course: "" })
              : this.setState({ course: "6" })
          }
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>Course 6</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: "100%" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.newLesson6()}
              >
                + New Lesson
              </Button>
              {Course6.map((lesson, index) => {
                return (
                  <Paper
                    elevation={1}
                    style={{
                      margin: 20,
                      padding: 20,
                      textAlign: "center",
                      backgroundColor: "#ebebeb",
                    }}
                  >
                    <h3>Lesson {index + 1}</h3>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Main Video</h3>

                      <input
                        id="contained-button-file"
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) => {
                          lesson.mainVideo = e.target.files[0];
                        }}
                      />
                      {/* <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Upload
                  </Button>
                </label> */}
                    </Paper>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Teaching video</h3>
                      <input
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) =>
                          (lesson.teachingVideo = e.target.files[0])
                        }
                      />
                    </Paper>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Audio Story</h3>
                      <input
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) =>
                          (lesson.audioStory = e.target.files[0])
                        }
                      />
                    </Paper>

                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Text Story</h3>
                      <Input.TextArea
                        onChange={(e) => {
                          lesson.textStory = e.target.value;
                        }}
                      />
                    </Paper>

                    <Button
                      onClick={() => {
                        let upload = storage
                          .ref(lesson.mainVideo.name)
                          .put(lesson.mainVideo);
                        upload.on(
                          "state_changed",
                          (snapshot) => {},
                          (error) => {
                            console.log(error);
                          },
                          () => {
                            storage
                              .ref(lesson.mainVideo.name)
                              .getDownloadURL()
                              .then((url) => {
                                console.log(url);
                                lesson.mainVideo = url;

                                upload = storage
                                  .ref(lesson.teachingVideo.name)
                                  .put(lesson.teachingVideo);
                                upload.on(
                                  "state_changed",
                                  (snapshot) => {},
                                  (error) => {
                                    console.log(error);
                                  },
                                  () => {
                                    storage
                                      .ref(lesson.teachingVideo.name)
                                      .getDownloadURL()
                                      .then((url) => {
                                        console.log(url);
                                        lesson.teachingVideo = url;

                                        const upload = storage
                                          .ref(lesson.audioStory.name)
                                          .put(lesson.audioStory);
                                        upload.on(
                                          "state_changed",
                                          (snapshot) => {},
                                          (error) => {
                                            console.log(error);
                                          },
                                          () => {
                                            storage
                                              .ref(lesson.audioStory.name)
                                              .getDownloadURL()
                                              .then((url) => {
                                                console.log(url);
                                                lesson.audioStory = url;
                                                this.setState({
                                                  Course6: Course6,
                                                });
                                                this.saveLessons(
                                                  index,
                                                  Course6
                                                );
                                              });
                                          }
                                        );
                                      });
                                  }
                                );
                              });
                          }
                        );
                      }}
                    >
                      Save and upload lesson
                    </Button>
                  </Paper>
                );
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={this.state.course == "7"}
          onChange={() =>
            this.state.course == "7"
              ? this.setState({ course: "" })
              : this.setState({ course: "7" })
          }
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>Course 7</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: "100%" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.newLesson7()}
              >
                + New Lesson
              </Button>
              {Course7.map((lesson, index) => {
                return (
                  <Paper
                    elevation={1}
                    style={{
                      margin: 20,
                      padding: 20,
                      textAlign: "center",
                      backgroundColor: "#ebebeb",
                    }}
                  >
                    <h3>Lesson {index + 1}</h3>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Main Video</h3>

                      <input
                        id="contained-button-file"
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) => {
                          lesson.mainVideo = e.target.files[0];
                        }}
                      />
                      {/* <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Upload
                  </Button>
                </label> */}
                    </Paper>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Teaching video</h3>
                      <input
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) =>
                          (lesson.teachingVideo = e.target.files[0])
                        }
                      />
                    </Paper>
                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Audio Story</h3>
                      <input
                        placeholder="Basic usage"
                        type="file"
                        onChange={(e) =>
                          (lesson.audioStory = e.target.files[0])
                        }
                      />
                    </Paper>

                    <Paper
                      elevation={2}
                      style={{ margin: 20, padding: 20, textAlign: "center" }}
                    >
                      <h3>Text Story</h3>
                      <Input.TextArea
                        onChange={(e) => {
                          lesson.textStory = e.target.value;
                        }}
                      />
                    </Paper>

                    <Button
                      onClick={() => {
                        let upload = storage
                          .ref(lesson.mainVideo.name)
                          .put(lesson.mainVideo);
                        upload.on(
                          "state_changed",
                          (snapshot) => {},
                          (error) => {
                            console.log(error);
                          },
                          () => {
                            storage
                              .ref(lesson.mainVideo.name)
                              .getDownloadURL()
                              .then((url) => {
                                console.log(url);
                                lesson.mainVideo = url;

                                upload = storage
                                  .ref(lesson.teachingVideo.name)
                                  .put(lesson.teachingVideo);
                                upload.on(
                                  "state_changed",
                                  (snapshot) => {},
                                  (error) => {
                                    console.log(error);
                                  },
                                  () => {
                                    storage
                                      .ref(lesson.teachingVideo.name)
                                      .getDownloadURL()
                                      .then((url) => {
                                        console.log(url);
                                        lesson.teachingVideo = url;

                                        const upload = storage
                                          .ref(lesson.audioStory.name)
                                          .put(lesson.audioStory);
                                        upload.on(
                                          "state_changed",
                                          (snapshot) => {},
                                          (error) => {
                                            console.log(error);
                                          },
                                          () => {
                                            storage
                                              .ref(lesson.audioStory.name)
                                              .getDownloadURL()
                                              .then((url) => {
                                                console.log(url);
                                                lesson.audioStory = url;
                                                this.setState({
                                                  Course7: Course7,
                                                });
                                                this.saveLessons(
                                                  index,
                                                  Course7
                                                );
                                              });
                                          }
                                        );
                                      });
                                  }
                                );
                              });
                          }
                        );
                      }}
                    >
                      Save and upload lesson
                    </Button>
                  </Paper>
                );
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

const Card = styled.div`
  margin: 20px;
  width: 150px;
  height: 120px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
`;
