import React, { Component } from "react";
import  '../../../App.css'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import { TextField, Button } from "@material-ui/core";
import ReactAudioPlayer from 'react-audio-player';
import * as firebase from "firebase";
import "../../../config" ;
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';
import LocalMoviesIcon from '@material-ui/icons/LocalMovies';
import PanoramaIcon from '@material-ui/icons/Panorama';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import styled from "styled-components";
import storage from "../../../config";
import ReactPlayer from "react-player";

import VideoLibraryOutlinedIcon from '@material-ui/icons/VideoLibraryOutlined';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import LibraryMusicOutlinedIcon from '@material-ui/icons/LibraryMusicOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';






export default class training extends Component {
    constructor(){
        super();
        this.state = {
            file: null,
            pictureTitle: "",
            training: [],
            videoTitle: '',
            videoLink: '',
            docTitle: '',
            audioTitle: ''
           

        }
    }

    componentDidMount(){
        firebase.firestore().collection('Training').onSnapshot(res => {
            this.setState({training: res.docs})  
            console.log(res.docs)
        })        
       
        
       

    }

    uploadAudio(file){
        console.log('here')
        if (file){
            console.log('here')
            let upload = storage
            .ref(file.name)
            .put(file);
        upload.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
            console.log(error);
            },
            () => {
            storage
                .ref(file.name)
                .getDownloadURL()
                .then((url) => {
                
                    firebase.firestore().collection('Training').add({
                        type: 'audio',
                        url: url,
                        title: this.state.audioTitle,
                        date: Date.now()
                    
                    }).then(res => {
                        console.log(res)
                        this.setState({file: null, audioTitle: ''})
                    })
                

                })})

            
        }

    }
    uploadDocument(file){
        
        if (file){
            console.log('here')
            let upload = storage
            .ref(file.name)
            .put(file);
        upload.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
            console.log(error);
            },
            () => {
            storage
                .ref(file.name)
                .getDownloadURL()
                .then((url) => {
                
                    firebase.firestore().collection('Training').add({
                        type: 'document',
                        url: url,
                        title: this.state.docTitle,
                        date: Date.now()
                    
                    }).then(res => {
                        console.log(res)
                        this.setState({file: null, docTitle: ''})
                    })
                

                })})

            
        }

    }

    uploadPicture(file){
        console.log('here')
        if (file){
            console.log('here')
            let upload = storage
            .ref(file.name)
            .put(file);
        upload.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
            console.log(error);
            },
            () => {
            storage
                .ref(file.name)
                .getDownloadURL()
                .then((url) => {
                
                    firebase.firestore().collection('Training').add({
                        type: 'image',
                        url: url,
                        title: this.state.pictureTitle,
                        date: Date.now()
                    
                    }).then(res => {
                        console.log(res)
                        this.setState({file: null, pictureTitle: ''})
                    })
                

                })})

            
        }

    }


    uploadVideo(){
        firebase.firestore().collection('Training').add({
            type: 'video',
            url: this.state.videoLink,
            title: this.state.videoTitle,
            date: Date.now()
        
        }).then(res => {
            console.log(res)
            this.setState({videoTitle: '', videoLink: ''})
        })

    }

  


    render(){ 
        if (this.state.selected == 'videos'){
            return (
             
                <div style={{position: 'relative'}}>
                    <h1>Videos</h1>
                    <ArrowBackIcon style={{position: 'absolute', top: 20, left: 30, cursor: 'pointer'}}  onClick={()=> this.setState({selected: ''})}/>

                    <div style={{margin: 20, padding: 10, display: 'flex',flexWrap: 'wrap', justifyContent: 'center'}}>
                        {this.state.training.map(file => {
                            console.log(file.data())
                            if (file.data().type == 'video'){
                                return (
                                    <div style={{padding: 10, borderRadius: 5, margin: 10, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)', backgroundColor: 'white', width: 'max-content'}}>
                                       <ReactPlayer
                                        onContextMenu ={(e) => e.preventDefault()}
                                            style={{ margin: "0px auto 20px auto" }}
                                            controls={true}
                                            playing={false}
                                            config={{ file: { 
                                            attributes: {
                                                controlsList: 'nodownload'
                                            }
                                            }}}
                                            url={file.data().url}
                                        />
                                        <p>{file.data().title}</p>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
                

            )
        } else if (this.state.selected == 'pictures'){
            return (
                <div style={{position: 'relative'}}>
                    <h1>Pictures</h1>
                    <ArrowBackIcon style={{position: 'absolute', top: 20, left: 30, cursor: 'pointer'}}  onClick={()=> this.setState({selected: ''})}/>


                    <div style={{margin: 20, padding: 10, display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                        {this.state.training.map(file => {
                            console.log(file.data())
                            if (file.data().type == 'image'){
                                return (
                                    <div style={{padding: 10, borderRadius: 5, margin: 10, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)', backgroundColor: 'white', width: 'max-content'}}>
                                       <img src={file.data().url} style={{maxWidth: 150, maxHeight: 150}} />
                                       <p>{file.data().title}</p>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>

            )
        } else if (this.state.selected == 'audio'){
            return (
                <div style={{position: 'relative'}}>
                    <h1>Audios</h1>
                    <ArrowBackIcon style={{position: 'absolute', top: 20, left: 30, cursor: 'pointer'}}  onClick={()=> this.setState({selected: ''})}/>

                    <div style={{margin: 20, padding: 10, display: 'flex', flexWrap: 'wrap',justifyContent: 'center'}}>
                        {this.state.training.map(file => {
                            console.log(file.data())
                            if (file.data().type == 'audio'){
                                return (
                                    <div style={{padding: 10, borderRadius: 5, margin: 10, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)', backgroundColor: 'white', width: 'max-content'}}>
                                    <ReactAudioPlayer
                                    style={{margin: '10px auto'}}
                                        src={file.data().url}
                                        
                                        controls
                                    />
                                    <p>{file.data().title}</p>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>

            )
        } else if (this.state.selected == 'documents'){
            return (
                <div style={{position: 'relative'}}>
                    <h1>Documents</h1>
                    <ArrowBackIcon style={{position: 'absolute', top: 20, left: 30, cursor: 'pointer'}}  onClick={()=> this.setState({selected: ''})}/>

                    <div style={{margin: 20, padding: 10, display: 'flex',flexWrap: 'wrap', justifyContent: 'center'}}>
                        {this.state.training.map(file => {
                            console.log(file.data())
                            if (file.data().type == 'document'){
                                return (
                                    <div style={{padding: 10, borderRadius: 5, margin: 10, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)', backgroundColor: 'white', width: 'max-content'}}>
                                    <a href={file.data().url} style={{display: 'flex'}}>
                                        <InsertDriveFileIcon style={{width: 40, height: 40, color: 'grey'}} />
                                        <p style={{color: 'black'}}>{file.data().title}</p></a>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>

            )
        } else {
            return (
                <div>
                    <h1 style={{fontSize: 'xx-large', color: '#edd490', backgroundColor: '#3c3b6e', padding: 6, borderRadius: 10, margin: 'auto', width: '50%'}}>TRAINING</h1>
    
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Box onClick={()=> this.setState({selected: 'videos'})}>
                            <VideoLibraryOutlinedIcon style={{height: 100, width: 100, color: '#edd490'}} />
                            <p style={{color: '#edd490'}}>Videos</p>
                        </Box>
                        <Box onClick={()=> this.setState({selected: 'pictures'})}>
                            <PhotoLibraryOutlinedIcon style={{height: 100, width: 100, color: '#edd490'}} />
                            <p style={{color: '#edd490'}}>Pictures</p>
                        </Box>
                        <Box onClick={()=> this.setState({selected: 'audio'})}>
                            <LibraryMusicOutlinedIcon style={{height: 100, width: 100, color: '#edd490'}} />
                            <p style={{color: '#edd490'}}>Audio</p>
                        </Box>
                        <Box onClick={()=> this.setState({selected: 'documents'})}>
                            <InsertDriveFileOutlinedIcon style={{height: 100, width: 100, color: '#edd490'}} />
                            <p style={{color: '#edd490'}}>Documents</p>
                        </Box>
    
                       
                        
    
                    </div>
    
                    
    
    
                </div>
            )

        }

        

    }
} 

const Box = styled.div`
    width: 140px;
    height: 140px;
    margin: 10px;
    padding: 7px;
    background-color: #505050;
    border: 1px solid #edd490;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 3px 7px rgba(0,0,0,0.3);
`

