import React, { Component } from "react";
import "../../../App.css";
import styled from "styled-components";
import Level1 from "./level1";
import Intro from './Intro';
import level1 from '../../../Images/YELLOW BOX.png'
import level2 from '../../../Images/BLUE BOX.png'
import level4 from '../../../Images/GREEN BOX.png'
import level3 from '../../../Images/RED BOX.png'
import intro from '../../../Images/PURPLE BOX.png'

export default class courses extends Component {
  constructor() {
    super();
    this.state = {
      selected: "",
    };
  }

  componentDidMount() {}

  render() {
    if (this.state.selected == "intro") {
      return <Intro />;
    } else if (this.state.selected == "1") {
      return <Level1 />;
    } else if (this.state.selected == "2") {
      return <h1>khsakj</h1>;
    }
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Card onClick={() => this.setState({ selected: "intro" })}>
          <h3>Introduction</h3>
          <img src={intro} style={{width: '60%', height: '60%'}} />

        </Card>
        <Card onClick={() => this.setState({ selected: "1" })}>
          <h3>Level 1</h3>
          <img src={level1} style={{width: '60%', height: '60%'}} />
        </Card>
        <Card onClick={() => this.setState({ selected: "2" })}>
          <h3>Level 2</h3>
          <img src={level2} style={{width: '60%', height: '60%'}} />

        </Card>
        <Card>
          <h3>Level 3</h3>
          <img src={level3} style={{width: '60%', height: '60%'}} />

        </Card>
        <Card>
          <h3>Level 4</h3>
          <img src={level4} style={{width: '60%', height: '60%'}} />

        </Card>
      </div>
    );
  }
}

const Card = styled.div`
  margin: 20px;
  width: 150px;
  height: 120px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
`;
