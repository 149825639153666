import firebase from "firebase";
import "firebase/storage";

var firebaseConfig = {
  apiKey: "AIzaSyBoi0xCewCSuDCmGv6YH67I8FLQFWe4xfQ",
    authDomain: "nuparla-bbc0a.firebaseapp.com",
    databaseURL: "https://nuparla-bbc0a.firebaseio.com",
    projectId: "nuparla-bbc0a",
    storageBucket: "nuparla-bbc0a.appspot.com",
    messagingSenderId: "972573621348",
    appId: "1:972573621348:web:16b279f550d4be41697371",
    measurementId: "G-7Q65HZZCGW"
};

const fire = firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
export { fire, storage as default };
