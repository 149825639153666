import React, { Component } from 'react';
import ScriptTag from 'react-script-tag';
export default class ConverKit extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    
  }
 render() {
  if (this.state.hasError) {
    // You can render any custom fallback UI
    return <h1>Something went wrong.</h1>;
  }
    return (<ScriptTag async data-uid="2f757e12aa" isHydrating={true} src="https://inglessubconsciente.ck.page/2f757e12aa/index.js" defer/>);
}
}
