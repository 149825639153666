import React from "react";
import "./App.css";
import landingPage from "./Components/SalesPage/landingPage";
import invitationPage from "./Components/SalesPage/invitationPage";
import support from './Components/SalesPage/support'
import terms from "./Components/SalesPage/terms";
import paypal from "./Components/SalesPage/payWithPaypal";
import privacy from "./Components/SalesPage/privacy";
import affiliateApp from "./Components/Affiliate/affiliate";
import backOffice from "./Components/BackOffice/backOffice";
import Reset from './Components/Affiliate/Auth/resetPassword';
import VideoPlayer from './Components/Player/videoPlayer';
import Free from './Components/SalesPage/free';

import LoginAdmin from './Components/BackOffice/Auth/login'
import convertKit from './Components/SalesPage/convertKit'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  return (
    <div>
      <Router>
        <Switch>
        <Route exact path="login" component={LoginAdmin} />
          <Route exact path="/" component={landingPage} />
          <Route exact path="/referral/:id/" component={invitationPage} />
          <Route exact path="/terms" component={terms} />
          <Route exact path="/privacy" component={privacy} />
          <Route exact path="/support" component={support} />
          <Route exact path="/reset-password" component={Reset} />
          <Route path="/affiliate" component={affiliateApp} />
          <Route path="/free" component={Free} />
          
          <Route path="/admin" component={backOffice} />
          <Route path="/subscribe" component={convertKit} />
          
          <Route path="/payment" component={paypal} />
          <Route path="/videoplayer" component={VideoPlayer} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
