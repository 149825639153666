import React, { Component } from "react";
import "../../App.css";
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";
import mainImg from "../../Images/Full Set.png";
import mainImg2 from "../../Images/Full Set.png";
import question from "../../Images/question.png";
import harold1 from "../../Images/harold1.png";
import dollar from "../../Images/100dollars.png";
import group1 from "../../Images/Box with Mobile 4 Sets.png";
import levelsImg from '../../Images/IPHONEBOX.jpg';
import lapEbook from '../../Images/LAP AND EBOOK.png';
import pic1 from "../../Images/pic1.JPG";
import pic2 from "../../Images/pic2.png";
import level1 from "../../Images/ebook.png";
import diploma from '../../Images/samplediploma.jpg';
import headerBook from '../../Images/header-book.PNG'
import interview from "../../Images/ORANGE BOX.png";
import travel from "../../Images/EBOOK 3.png";
import civic from "../../Images/EBOOK 4.png";
import pic3 from "../../Images/pic3.JPG";
import harold2 from "../../Images/harold2.jpeg";
import logobg from "../../Images/logobg.jpg";
import logoblack from "../../Images/nuparla em1 .1 w.png";
import logogold from "../../Images/nuparla em1 .1.png";
import heading from "../../Images/Untitled-1.png";
import prices from "../../Images/prices.png";
import paypal from "../../Images/paypal.png";
import stripe from "../../Images/stripe.png";
import bitcoin from "../../Images/bitcoin.png";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { fire } from "../../config";
import "../../config";
import * as firebase from "firebase";
import { TextField, Button } from "@material-ui/core";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import ConvertKit from "convertkit-react";
import ReactPlayer from "react-player";
import Dialog from '@material-ui/core/Dialog';
import GetAppIcon from '@material-ui/icons/GetApp';

import ConvertKitComponent from './convertKit'
import Checkbox from '@material-ui/core/Checkbox';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));



export default class landingPage extends Component {
  constructor() {
    super();
    this.state = {
      package: "",
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      address: "",
      password: "",
      verifyPassword: "",
      registered: false,
      payment: '',
      user: null,
      url: '',
      referrer: '',
      backdrop: false,
      intro: '',
      country: '',
      region: '',
      plan: ''
    };
  }

  componentDidMount() {
    
    if (this.state.user == null){
        firebase.firestore().collection('Users').where('referral', '==', this.props.match.params.id).get().then(res => {
            
            if (this.state.user == null){
                this.setState({referrer: res.docs[0].data().firstName})
                this.setState({url: res.docs[0].data().url})
                this.setState({user:  res.docs[0].data()})
            }

        })

    }

    
    
    
    // firebase.firestore().collection('Users').onSnapshot(res => {

    //     let users = []
        
    //         res.docs.forEach(doc => {
           
    //             users.push(doc.data())
    //             if (doc.data().referral == this.props.match.params.id){
    //                 this.setState({referrer: doc.data().name})
    //             this.setState({url: doc.data().url})
    //             this.setState({user: doc.data()})
    //             console.log(doc.data())

    //             }
                
            
            
    //     })    

    //     let user = users.filter(user => {
    //         return user.referral == this.props.match.params.id
    //     })

      
    
               
     

    // })

  }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 selectCountry (val) {
  this.setState({ country: val });
}

selectRegion (val) {
  this.setState({ region: val });
}
 


  verify() {
    if (this.state.firstName == '' || this.state.email == '' || this.state.password == ''){
      this.setState({ registered: false, backdrop: false });
      alert('Please fill all the fields')

    } else if (this.state.payment == ''){
      this.setState({ registered: false, backdrop: false });
      alert('Please select a payment plan')

    } else if (this.state.plan == ''){
      this.setState({ registered: false, backdrop: false });
      alert('Please select a payment plan')

    }else if (this.state.password === this.state.verifyPassword) {
      // console.log(this.state.firstName);
      // console.log(this.state.address);
      // console.log(this.state.email);
      // console.log(this.state.password);
      this.setState({backdrop: true})
    }
    
    if (this.state.password === this.state.verifyPassword) {
      console.log(this.state.firstName);
      console.log(this.state.address);
      console.log(this.state.email);
      console.log(this.state.password);

      fire
        .auth()
        .createUserWithEmailAndPassword(this.state.email, this.state.password)
        .then((u) => {
          
          var uid = u.user.uid;
          let referral = this.makeid(12)
          
          try {
              this.register(uid)
              
          } catch (error) {
              console.log(error)
              
          }
          
        })
        .catch((error) => console.log(error))
      
    } else {
      this.setState({ registered: false });
      console.log("wrong");
    }
  }


  register(uid){
      
      firebase.firestore().collection('Users').where('referral', '==', this.props.match.params.id).get().then(res => {
        console.log(res.docs[0].data().name)
        firebase.firestore().collection('Users').doc(this.state.email).set({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            name: this.state.firstName + " " + this.state.lastName,
            email: this.state.email,
            address: {
              country: '',
              region: ''
            },
            userName: this.state.email.split('@')[0],
            uid: uid,
            package: this.state.package,
            paid: false,
            sponsor: this.state.user.email,
            rank: 'hero',
            referral: this.makeid(12),
            date: Date.now(),
            intro: false,
            affiliate: false,
            first: [],
            second: [],
            third: [],
            fourth: [],
            fifth: [],
            plan: this.state.plan,
            learningProgress: {
              level: 0
            }
          }).then(res => {
              console.log(res)
          })
          if (res.docs[0].data().rank == 'hero'){
              if (res.docs[0].data().first.length == 4){
                firebase.firestore().collection('Users').doc(res.docs[0].data().email).update({
                    first: firebase.firestore.FieldValue.arrayUnion({
                        email: this.state.email,
                        status: 'pending',
                        date: Date.now(),
                        commission: 125
    
                    }),
                    rank: 'instructor',
                    rankAdvanceBonus: [
                        {
                            rank: 'instructor',
                            bonus: 250,
                            status: 'pending',
                            date: Date.now()

                        }
                    ]

                }).then(rest => {
                      console.log(rest)
                      if (res.docs[0].data().sponsor){

                      }else {
                        this.setState({registered: true})
                      }
                      
                  })

              } else {
                firebase.firestore().collection('Users').doc(res.docs[0].data().email).update({
                    first: firebase.firestore.FieldValue.arrayUnion({
                        email: this.state.email,
                        status: 'pending',
                        date: Date.now(),
                        commission: 100
    
                      }),
                }).then(rese => {
                      console.log(rese)
                      if (res.docs[0].data().sponsor){

                      }else {
                        this.setState({registered: true})
                      }

                  })

              }

              if (res.docs[0].data().sponsor){
                firebase.firestore().collection('Users').doc(res.docs[0].data().sponsor).get().then(res => {
                    if (res.data().rank == 'instructor' || res.data().rank == 'teacher'){
                        firebase.firestore().collection('Users').doc(res.data().email).update({
                            second: firebase.firestore.FieldValue.arrayUnion({
                                email: this.state.email,
                                status: 'pending',
                                date: Date.now(),
                                commission: 30
            
                            })
                        }).then(rese => {
                          if (res.data().sponsor){

                          }else {
                            this.setState({registered: true})
                          }
                        })

                    }
                    if (res.data().sponsor){
                        firebase.firestore().collection('Users').doc(res.data().sponsor).get().then(res => {
                            if (res.data().rank == 'teacher'){
                                firebase.firestore().collection('Users').doc(res.data().email).update({
                                    third: firebase.firestore.FieldValue.arrayUnion({
                                        email: this.state.email,
                                        status: 'pending',
                                        date: Date.now(),
                                        commission: 15
                    
                                    })
                                }).then(res => {
                                  
                                    this.setState({registered: true})
                                  
                                })
        
                            }
                        })
                    }
                })

              }
            

          }else if (res.docs[0].data().rank == 'instructor'){
            if (res.docs[0].data().first.length == 20){
                firebase.firestore().collection('Users').doc(res.docs[0].data().email).update({
                    first: firebase.firestore.FieldValue.arrayUnion({
                        email: this.state.email,
                        status: 'pending',
                        date: Date.now(),
                        commission: 150
    
                    }),
                    rank: 'teacher',
                    rankAdvanceBonus: [
                        {
                            rank: 'teacher',
                            bonus: 1000,
                            status: 'pending',
                            date: Date.now()

                        }
                    ]

                }).then(resp => {
                      console.log(resp)
                      if (res.docs[0].data().sponsor){

                      }else {
                        this.setState({registered: true})
                      }
                  })

              } else {
                firebase.firestore().collection('Users').doc(res.docs[0].data().email).update({
                    first: firebase.firestore.FieldValue.arrayUnion({
                        email: this.state.email,
                        status: 'pending',
                        date: Date.now(),
                        commission: 125
    
                      }),
                }).then(rese => {
                      console.log(rese)
                      if (res.docs[0].data().sponsor){

                      }else {
                        this.setState({registered: true})
                      }
                  })

              }

              if (res.docs[0].data().sponsor){
                firebase.firestore().collection('Users').doc(res.docs[0].data().sponsor).get().then(res => {
                    if (res.data().rank == 'instructor'){
                        firebase.firestore().collection('Users').doc(res.data().email).update({
                            second: firebase.firestore.FieldValue.arrayUnion({
                                email: this.state.email,
                                status: 'pending',
                                date: Date.now(),
                                commission: 30
            
                            })
                        }).then(res => {
                          
                            this.setState({registered: true})
                          
                        })

                    } else if (res.data().rank == 'teacher'){
                        firebase.firestore().collection('Users').doc(res.data().email).update({
                            second: firebase.firestore.FieldValue.arrayUnion({
                                email: this.state.email,
                                status: 'pending',
                                date: Date.now(),
                                commission: 30
            
                            })
                        }).then(res => {
                         
                            this.setState({registered: true})
                          
                        })

                    }
                })

              }

          }else if (res.docs[0].data().rank == 'teacher'){
            if (res.docs[0].data().first.length == 40){
                firebase.firestore().collection('Users').doc(res.docs[0].data().email).update({
                    first: firebase.firestore.FieldValue.arrayUnion({
                        email: this.state.email,
                        status: 'pending',
                        date: Date.now(),
                        commission: 175
    
                    }),
                    rank: 'teacher',
                    rankAdvanceBonus: [
                        {
                            rank: 'teacher',
                            bonus: 2500,
                            status: 'pending',
                            date: Date.now()

                        }
                    ]

                }).then(rese => {
                      console.log(res)
                      if (res.docs[0].data().sponsor){

                      }else {
                        this.setState({registered: true})
                      }
                  })

              } else {
                firebase.firestore().collection('Users').doc(res.docs[0].data().email).update({
                    first: firebase.firestore.FieldValue.arrayUnion({
                        email: this.state.email,
                        status: 'pending',
                        date: Date.now(),
                        commission: 150
    
                      }),
                }).then(rese => {
                      console.log(res)
                      if (res.docs[0].data().sponsor){

                      }else {
                        this.setState({registered: true})
                      }
                  })

              }

              if (res.docs[0].data().sponsor){
                firebase.firestore().collection('Users').doc(res.docs[0].data().sponsor).get().then(res => {
                    if (res.data().rank == 'instructor'){
                        firebase.firestore().collection('Users').doc(res.data().email).update({
                            second: firebase.firestore.FieldValue.arrayUnion({
                                email: this.state.email,
                                status: 'pending',
                                date: Date.now(),
                                commission: 30
            
                            })
                        }).then(res => {
                          
                            this.setState({registered: true})
                          
                        })

                    } else if (res.data().rank == 'teacher'){
                        firebase.firestore().collection('Users').doc(res.data().email).update({
                            second: firebase.firestore.FieldValue.arrayUnion({
                                email: this.state.email,
                                status: 'pending',
                                date: Date.now(),
                                commission: 30
            
                            })
                        }).then(res => {
                         
                            this.setState({registered: true})
                          
                        })

                    }
                })

              }

          }

    })
    
  }

  render() {
    if (this.state.registered == true) {
      this.setState({backdrop: false})
      return (
        <Redirect
          to={{
            pathname: "/payment",
            state: {
              userName: this.state.userName,
              email: this.state.email,
              plan: this.state.plan,
              payment: this.state.payment
            },
          }}
        />
      );
    }

    
    return (
      <div>
        <div className="top-bg-div">
        {this.state.user && <div style={{height: 'max-content', width: '90%', margin: '20px auto', backgroundColor: 'rgba(255,255,255,0.9)', boxShadow: '0px 3px 8px rgba(0,0,0,0.6)', borderRadius: 10, display: window.innerWidth >= 600 ? 'flex' : '', textAlign: 'center', padding: 10}}>
            {this.state.url && <div style={{height: 160, minWidth: 160, margin: 'auto 20px', borderRadius: 100, overflow: 'hidden'}}><img style={{height: '100%', width: 160,}} src={this.state.url} /></div>}
            {!this.state.url && <AccountCircleIcon style={{height: 160, width: 160, margin: 'auto 20px'}} />}
            <P style={{margin: 'auto 10px', fontSize: 'xx-large', color: 'black'}}><b>{this.state.referrer}</b>  te invita a que aprendás inglés y ganes dinero con NuParla. Mira detalladamente este sitio de web y si te gusta, regístrate <b><i>hoy mismo</i></b> y, <u>¡aprovecha la oportunidad que te estamos brindando!</u> </P>

        </div>}
          <div
            className="header-sales"
            style={{
              justifyContent: "space-between",
              borderBottom: "2px dashed rgba(240,240,240,0.2)",
            }}
          >
            <img src={logogold} className="logo-top" />
            <div
              className="header-menu"
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "max-content",
              }}
            >
              <h4 className="p10 pointer phover " style={{color: 'white', marginTop: 15}}>Únete</h4>
              <h4 className="p10 pointer phover " style={{color: 'white', marginTop: 15}}>Testimonios</h4>
              <a href="/affiliate"><h4 className="p10 pointer phover " style={{color: 'white', marginTop: 15}}>Acceso</h4></a>
            </div>
          </div>

          <div className="center" style={{ marginTop: 10 }}>
            <img src={heading} style={{ width: "100%" }} />
          </div>

          <Grid>
            <div>
            {window.innerWidth >= 900 && <div
                // style={{
                //   width: "90%",
                //   height: 500,
                //   margin: "auto",
                //   boxShadow: "0px 2px 30px rgba(0,0,0,0.6)",
                // }}
              >
                <ReactPlayer
                                        onContextMenu ={(e) => e.preventDefault()}
                                            style={{ margin: "0px auto 20px auto"}}
                                            controls={true}
                                            width={450}
                                            height={window.innerwidth >= 1000 ? 200 : 300}
                                            playing={false}
                                            config={{ file: { 
                                            attributes: {
                                                controlsList: 'nodownload'
                                            }
                                            }}}
                                            url="https://vimeo.com/451683517"
                                        />
              </div>}
              {window.innerWidth < 899 && <div
                // style={{
                //   width: "90%",
                //   height: 500,
                //   margin: "auto",
                //   boxShadow: "0px 2px 30px rgba(0,0,0,0.6)",
                // }}
              >
                <ReactPlayer
                                        onContextMenu ={(e) => e.preventDefault()}
                                            style={{ margin: "0px auto 20px auto"}}
                                            controls={true}
                                            width={340}
                                            height={window.innerwidth >= 1000 ? 200 : 300}
                                            playing={false}
                                            config={{ file: { 
                                            attributes: {
                                                controlsList: 'nodownload'
                                            }
                                            }}}
                                            url="https://vimeo.com/451683517"
                                        />
              </div>}
              <P className="p" style={{ margin: "40px", textAlign: "center" }}>
              NuParla Online FREE English Academy te permite hablar inglés con clases 100% GRATIS online. No pagues $500, $1,000 o $2,500 dólares por otros cursos online, cuando TODO lo que necesitas para dominar el inglés en menos de 90 días y graduarte, lo tienes con NuParla GRATIS!
              </P>
              
            </div>
            <div
              style={{
                width: "90%",
                backgroundColor: "rgba(255,255,255, 0.8)",
                height: "max-content",
                padding: 10,
                margin: "0px auto",
                textAlign: 'center'
              }}
            >
              
              <img src={diploma} style={{ width: "100%" }} /> 
             
              
              

              {/* <ul>
                <li className="p">
                  Periodo inicial de{" "}
                  <span style={{ fontWeight: "bold" }}>silencio</span> -{" "}
                  <i>solo escucha e internaliza...</i>
                </li>
                <li className="p">
                  Escenas y expresiones que te harán reír -{" "}
                  <i>
                    <span style={{ fontWeight: "bold" }}>profundiza</span> el
                    ritmo
                  </i>
                </li>
                <li className="p">
                  Mensajes comprensibles que
                  <span style={{ fontWeight: "bold" }}>endulzan</span> tu oido -
                  adquiérlo subconcientemente
                </li>
                <li className="p">
                  Sin necesidad de traducirlo en tu cabeza -ahora el inglés{" "}
                  <span style={{ fontWeight: "bold" }}>fluye</span>
                  naturalmente de ti
                </li>
                <li className="p">
                  Interacción personalizada - comete errores con nosotros{" "}
                  <span style={{ fontWeight: "bold" }}>sin miedo</span>
                </li>
              </ul> */}
            </div>
          </Grid>
        </div>

        <div className="main-page">
          <div
            style={{
              display: "flex",
              width: "90%",
              flexWrap: "wrap",
              textAlign: "center",
              justifyContent: "space-around",
              margin: "30px 20px",
            }}
          >
            <img className="question" src={question} />
            {window.innerwidth >= 450 && <div
              className="center"
              style={{
                width: '70%',
                borderTop: "2px solid #bebebe",
                borderBottom: "2px solid #bebebe",
                padding: "10px 0px",
              }}
            >
              <h2>¿Qué significa...</h2>
              <h1 style={{ display: "flex", flexWrap: "wrap" }}>
              NuParla Online FREE English Academy
              </h1>
              <h2>para ti...?</h2>
            </div>}

            {window.innerWidth < 600 && <div
              className="center"
              style={{
                width: '90%',

                borderTop: "2px solid #bebebe",
                borderBottom: "2px solid #bebebe",
                padding: "10px 0px",
              }}
            >
              <h2>¿Qué significa...</h2>
              <h1 style={{fontSize: 'xx-large', display: "flex", flexWrap: "wrap" }}>
              NuParla Online FREE English Academy
              </h1>
              <h2>para ti...?</h2>
            </div>}
          </div>

          <Grid2>
            <div style={{ width: "90%", margin: "auto" }}>
              <P className="no-m-p ">
                <span style={{ fontWeight: "bold", color: "#2874A6" }}>
                  De:
                </span>{" "}
                Harold Zapata
              </P>
              <P className="no-m-p ">
                <span style={{ fontWeight: "bold", color: "#2874A6" }}>
                  Desde:
                </span>{" "}
                Los Ángeles, California{" "}
              </P>
              <P>
                ¿Qué significa{" "}
                <span style={{ fontWeight: "bold" }}>NuParla Online FREE English Academy</span>{" "}
                para ti...?
              </P>
              <P>Cada persona tiene un distinto "por qué" y "para qué"...</P>
              <P>
                Algunos tienen que manejar el idioma para obtener ese mejor
                trabajo, o para poder graduarse, o pasar el TOEFL o el examen
                oral y escrito para la ciudadanía americana.{" "}
              </P>
              <P>
                Para otros, el inglés les es útil para viajar por el mundo con
                confianza, conocer nuevas culturas, amistades, y aun el amor...
              </P>
              {/* <P className="p">
                Mi "por qué" fue el amor...Nací en New York, pero me criaron en
                Cali. Hablé inglés por primera vez a los 5 años cuando ingresé
                al kínder cerca de Philadelphia. Me enamoré de Bonnie después de
                que ella me besó, y eso me motivó a aprender el idioma. Luego a
                los 9 años me enamoré de Mónica y Antonella en Cali; y puro
                ping-pong de Colombia a USA toda mi niñez y juventud, y siempre
                un loco enamorado, lo que me motivó a aprender dos lenguas
                maternas. :-)
              </P> */}
              <P className="p">
              Por eso estamos ofreciendo las video-clases de la Academia de inglés 100% gratis online aquí y en el canal de YouTube de NuParla. Ahora no es necesario pagar cientos o miles de dólares para aprender inglés y hablarlo fluidamente en menos de 12 semanas. 
              </P>
            </div>
            <img src={harold1} style={{ margin: "auto", width: "90%" }} />
          </Grid2>


          <div style={{ width: "100%", textAlign: "center" }}>
            <img src={levelsImg} style={{ width: "70%", margin: "auto" }} />
          </div>

          <Grid50 style={{width: '90%', margin: 'auto'}}>
            <div>
              <h3 style={{fontSize: 'large', fontWeight: 'bold'}}>PRÁCTICO:</h3>
              <p>3 a 4 lecciones por curso (90 video-lecciones…45 horas de enseñanzas en total)</p>
              <ul>
                <li style={{fontSize: 20}}>4 niveles (principiante, elemental, intermedio, intermedio avanzado) </li>
                <li style={{fontSize: 20}}>7 cursos por nivel </li>
                <li style={{fontSize: 20}}>3 a 4 lecciones por curso</li>
                <li style={{fontSize: 20}}>Introducción 100% en español</li>

                <li style={{fontSize: 20}}>Primeras lecciones 50% inglés y 50% español, luego 100% en inglés</li>

              </ul>

            </div>
            <div>
            <h3 style={{fontSize: 'large', fontWeight: 'bold'}}>FÁCIL: </h3>
              <ul>
                <li style={{fontSize: 20}}>De no saber ni una palabra a hablarlo fluidamente </li>
                <li style={{fontSize: 20}}>30 minutos diarios por 90 días</li>
                <li style={{fontSize: 20}}>Utilizamos videos y tráiler de películas divertidos</li>
                <li style={{fontSize: 20}}>Sin frustraciones ni gramática complicada</li>

                <li style={{fontSize: 20}}>Video-lecciones 100% GRATIS online</li>

              </ul>

            </div>
          </Grid50>
          


          

          
          <div
          style={{marginTop: 30}}
            
          >
            {window.innerWidth >= 900 && <div
                // style={{
                //   width: "90%",
                //   height: 500,
                //   margin: "auto",
                //   boxShadow: "0px 2px 30px rgba(0,0,0,0.6)",
                // }}
              >
                <ReactPlayer
                                        onContextMenu ={(e) => e.preventDefault()}
                                            style={{ margin: "0px auto 20px auto"}}
                                            controls={true}
                                            width={650}
                                            height={360}
                                            playing={false}
                                            config={{ file: { 
                                            attributes: {
                                                controlsList: 'nodownload'
                                            }
                                            }}}
                                            url="https://www.youtube.com/watch?v=L2q6aonDoSQ&feature=youtu.be"
                                        />
              </div>}
              {window.innerWidth <= 899 && <div
                // style={{
                //   width: "90%",
                //   height: 500,
                //   margin: "auto",
                //   boxShadow: "0px 2px 30px rgba(0,0,0,0.6)",
                // }}
              >
                <ReactPlayer
                                        onContextMenu ={(e) => e.preventDefault()}
                                            style={{ margin: "0px auto 20px auto"}}
                                            controls={true}
                                            width={340}
                                            height={200}
                                            playing={false}
                                            config={{ file: { 
                                            attributes: {
                                                controlsList: 'nodownload'
                                            }
                                            }}}
                                            url="https://www.youtube.com/watch?v=L2q6aonDoSQ&feature=youtu.be"
                                        />
              </div>}

          </div>

          <div
            style={{
              width: "90%",
              margin: "50px auto",
              border: "5px solid rgb(0, 36, 98)",
            }}
          >
            <div style={{ width: "100%", margin: "auto" }}>
              <h1
                className="mmedium"
                style={{
                  backgroundColor: "rgb(0, 36, 98)",
                  color: "white",
                  width: "100%",
                  padding: "20px 0px",
                  textAlign: "center",
                }}
              >
                Los 90 videos gratis son el paso #1 de 5 pasos. Mejora tu experiencia por un pago único de solo US$49.95, por lo que NuParla te ofrece adicionalemente: 
              </h1>
              <div style={{ width: "100%", textAlign: "center" }}>
                <img src={mainImg2} style={{ width: "90%", margin: "auto" }} />
              </div>
              <div style={{ width: "90%", margin: "auto", color: 'black', fontSize: 'large' }}>
                <img src={prices} style={{ width: "100%", margin: "auto" }} />

                {/* <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >El método neurocientífico exclusivo de NuParla</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ Inestimable)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >La plataforma avanzada de aprendizaje cibernético: </li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 400)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Más de 100+ videos (50+ horas) de coaching con Harold</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 1,700)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Más de 100+ grabaciones descargables (30+ horas) con Harold</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 700)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Sistema bi-direccional de mensajes exclusivo a NuParla</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 400)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li > Programa de Afiliados NuParleros para ganar dinero</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ Inestimable)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Oficina virtual y sitio de web replicable para gestionar tus ventas</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 750)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Ingreso a NuParla Village</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 300)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Acceso de por vida a NuParla University</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ Inestimable)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >BONUS e-book: Inglés Subconsciente (libro y audio en inglés y español)</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 35)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >BONUS curso exclusivo: Prepared For The Job Interview</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 250)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li  >BONUS curso exclusivo: Prepared For Traveling Abroad</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 250)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li  >BONUS curso exclusivo: Prepared For The USCIS Civics Test</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ 250)</p>
                        </div> */}
              </div>
            </div>

            <h1
              style={{ color: "red", margin: "30px 10px", textAlign: "center" }}
            >
              Valorado en: US $<s>4,995.00</s>
            </h1>
            <P>Por solo: US$49.95 (1% del valor actual por tiempo limitado)</P>
            <P style={{ width: "80%", textAlign: "center", margin: "auto" }}>
            Ahora, 100% de Latinos pueden aprender inglés GRATIS con nuestros vídeos online, y si desean la experiencia COMPLETA de NuParla por US$49.95, pueden aprenderlo más rápido con un ser amado, obtener adiestramiento y asistencia personalizada antes de una entrevista de empleo o cita para la ciudadanía americana, y cambiar la vida de un adolecente huérfano en su país.
            </P>

            

            <h1
              style={{
                backgroundColor: "rgb(0, 36, 98)",
                color: "white",
                width: "100%",
                padding: "20px 0px",
                textAlign: "center",
              }}
            >
              ¿VES VALOR POR SOLO US$49.95…?
            </h1>
          </div>

          <a href="#register"><div
            style={{
              margin: "auto",
              width: "70%",
              padding: "10px 20px",
              textAlign: "center",
              color: "white",
              backgroundColor: "red",
              borderRadius: 20,
            }}
          >
            <h1 style={{ color: "white" }}>¡Sí, veo valor!</h1>
            <P>
            Quiero la experiencia completa de NuParla por solo US$49.95. <br/>
            (Haz clic para ir directamente a la sección de pagos.)
            </P>
          </div></a>
          <div style={{ width: "90%", margin: "auto" }}>
            <h1
              className="mmedium"
              style={{ textAlign: "center", margin: "30px auto" }}
            >
              ¿Necesitas más información antes de acudir al llamado...?
            </h1>

            <h1
              className="mmedium"
              style={{
                fontWeight: "normal",
                textAlign: "center",
                margin: "30px auto",
              }}
            >
              Te desglosamos todas las cosas increíbles que serán tuyas cuando{" "}
            </h1>

            <h1
              className="mmedium"
              style={{
                fontWeight: "normal",
                textAlign: "center",
                margin: "30px auto",
              }}
            >
              aceptas el reto y te{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>
                UNAS HOY MISMO...
              </span>
            </h1>

            <h1
              className="mmedium"
              style={{
                color: "red",
                fontWeight: "normal",
                textAlign: "center",
              }}
            >
              Lo <i>PRIMERO</i> a lo que TENDRÁS ACCESO es al...
            </h1>
          </div>
          

          

          <Grid50>
            <div style={{ padding: 20, textAlign: "center" }}>
              <h2
                className="mmedium"
                style={{
                  width: "80%",
                  margin: "20px auto",
                  textAlign: "center",
                }}
              >
                NuParla tiene todo lo que necesitas para aprender inglés sin
                frustraciones.
              </h2>

              <P
                style={{
                  width: "80%",
                  margin: "20px auto",
                  textAlign: "center",
                }}
              >
                Te escuchamos y animamos por el camino correcto con nuestro
                sistema de mensajería de dos vías. Desarrolla tu confianza con
                nuestro equipo bilingüe, sin miedo o vergüenza. ¡Estamos juntos!
              </P>

              <P
                style={{
                  width: "80%",
                  margin: "20px auto",
                  textAlign: "center",
                }}
              >
                Estamos estructurados para que converses en inglés con confianza
                en menos de 90 días.
              </P>
            </div>
            {/* <img src={pic1} style={{ width: "90%", margin: "auto" }} /> */}
            <div style={{textAlign: 'center', width: '100%'}}>
              <img src={headerBook} style={{ width: "90%", marginTop: '20%', marginBottom: '10%' }} />
              <h2 style={{fontSize: 'x-large', marginBottom: '20px'}}>Descarga el capítulo 1 del libro (pdf) y audiolibro (mp3)</h2>
              <div style={{display: 'flex',width: 350, position: 'relative', justifyContent: 'space-around', margin: 'auto' }}><p style={{marginTop: 7}}>Subconscious English:</p> 
                <a href="https://firebasestorage.googleapis.com/v0/b/nuparla-bbc0a.appspot.com/o/Docs%2FSubconscious%20English%20Dedication%20Introduction%20and%20Chapter%201.pdf?alt=media&token=2be48f03-18e1-464d-8226-b3c72c7197c7" download target="_blank"><Button variant="contained" style={{backgroundColor: 'green', color: 'white'}}><GetAppIcon />PDF</Button></a> 
                <a href="https://firebasestorage.googleapis.com/v0/b/nuparla-bbc0a.appspot.com/o/Docs%2FSubconscious%20English%20Dedication%20Intro%20and%20Chapter%201.mp3?alt=media&token=4d44053f-2835-40bd-b4de-a356b1f00d4c" download target="_blank"><Button variant="contained" style={{ backgroundColor: 'green', color: 'white'}}><GetAppIcon />MP3</Button></a>
              

              </div>

              <div style={{display: 'flex',width: 350,margin: '10px 0', position: 'relative', justifyContent: 'space-around', margin: 'auto' }}><p style={{marginTop: 7}}>Inglés Subconsciente:</p> 
                <a href="https://firebasestorage.googleapis.com/v0/b/nuparla-bbc0a.appspot.com/o/Docs%2Fchap1%2FChap%201.pdf?alt=media&token=b7b62088-8085-4610-8374-9d35d072bfdf" download target="_blank"><Button variant="contained" style={{backgroundColor: 'green', color: 'white'}}><GetAppIcon />PDF</Button></a> 
                <a href="https://firebasestorage.googleapis.com/v0/b/nuparla-bbc0a.appspot.com/o/Docs%2FIngles%20Subconsciente%20Intro%20y%20Capitulo%201.mp3?alt=media&token=ecedaaf3-c752-4e99-bb9e-71b18bc612a0" download target="_blank"><Button variant="contained" style={{ backgroundColor: 'green', color: 'white'}}><GetAppIcon />MP3</Button></a>
              

              </div>
              
            </div>
          </Grid50>
          <Grid50>
            <img src={pic2} style={{ width: "60%", margin: "auto" }} />
            <div>
              <h2
                style={{
                  fontWeight: "normal",
                  width: "80%",
                  color: "#38448a",
                  margin: "20px auto",
                  textAlign: "left",
                }}
              >
                Más de 30 horas de audio-conversaciones y afirmaciones
                descargables a tu dispositivo móvil para interactuar:
                <li>en el gimnasio</li>
                <li>en la ducha</li>
                <li>cuando viajes</li>
                <li>al dormir</li>
                <li>donde sea...</li>
              </h2>
            </div>
          </Grid50>
          <Grid50>
            <h1
              className="mmedium"
              style={{
                fontWeight: "normal",
                width: "80%",
                margin: "20px auto",
                textAlign: "center",
              }}
            >
              Aprende inglés. Gana dinero. Todo el sistema para afiliados{" "}
              <i>
                <b>
                  <u>NuParleros</u>
                </b>
              </i>{" "}
              está a tu alance.
            </h1>
            <img src={diploma} style={{width: '80%', margin: 'auto'}} />

          </Grid50>

          <P style={{ width: "80%", margin: "20px auto", textAlign: "center" }}>
            NuParla te va a fascinar, y al compartirlo con personas en tu
            círculo de influencia, NuParla te paga de *$100 a $225 dólares por
            cada referido que se une al reto NuParla x 90 días (*depende en
            cúantos hayas ingresado). Imagínate si refieres a solo una persona
            al día...desde tu casa, desde tu WhatsApp o Facebook...¿Cómo pudiera
            cambiar tus finanzas...tu vida y la de tu familia? Tu oficina
            virtual está lista para que recomiendes desde el primer día, con tu
            propio sitio de web replicable, tus guiones, instrucciones y
            materiales de marketing para tus redes sociales. Pagamos todos los
            viernes por PayPal. Ingresa a alguien hoy, y comisionas la siguente
            semana. Es así de fácil.
          </P>

          <a href="#register"><div
            style={{
              margin: "auto",
              width: "70%",
              color: "white",
              padding: "10px 20px",
              textAlign: "center",
              backgroundColor: "red",
              borderRadius: 20,
            }}
          >
            <h2 style={{ color: "white" }}>
              Me uno al reto NuParla x 90 días ¡AHORA MISMO!
            </h2>
            <P className="p">
              Quiero aprender inglés y que mis amigos y familia también lo
              aprendan.{" "}
            </P>
          </div></a>

          <Grid50>
            <div
              style={{
                color: "#38448a",
                width: "90%",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <h1 className="no-m-p">Bonus #1</h1>
              <h2 className="no-m-p msmall">Inglés Subconsciente:</h2>
              <P className="no-m-p">
                La guía definitiva para que tu héroe interior emerja hablando inglés
              </P>
              <h3 className="no-m-p" style={{ color: "black" }}>
                e-book y audio libro
              </h3>

              <img src={level1} style={{ width: "50%" }} />

              <a href="#register"><div
                style={{
                  margin: "20px auto",
                  width: "90%",
                  padding: 10,
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "red",
                  borderRadius: 20,
                }}
              >
                <h2 className="msmall" style={{ color: "white" }}>
                ¡Sí, veo valor!
                </h2>
                <P className="p">
                Quiero la experiencia completa de NuParla por solo US$49.95. <br/>
                (Haz clic para ir directamente a la sección de pagos.) 
                </P>
              </div></a>
            </div>

            <P style={{ width: "90%", color: "#38448a", margin: "auto" }}>
              El método único a NuParla se descubre en este asombroso libro
              cibernético (e-Book) en español, escrito por Harold Zapata, donde
              se adquiere el inglés por medio de:
              <br />
              1. Historias (endulzando el oído)
              <br />
              2. Internalización (suministrando al subconsciente)
              <br />
              3. Repetición (activando la mente)
              <br />
              4. Postura (calmando el cuerpo)
              <br />
              5. Decisión (fortaleciendo la voluntad)
              <br />
              6. Emociones (involucrando el corazón)
              <br />
              7. Conexión social (caminado por La Aldea [the Village])
            </P>
          </Grid50>

          {window.innerWidth <= 600 && (
            <Grid50>
              <div
                style={{
                  color: "#38448a",
                  width: "90%",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <h1 className="no-m-p">Bonus #2</h1>
                <h2 className="no-m-p msmall">
                  Prepared for the Job Interview:
                </h2>
                <h3 className="no-m-p" style={{ color: "black" }}>
                  (valuado en US $250)
                </h3>

                <img src={interview} style={{ width: "50%" }} />

                <a href="#register"><div
                  style={{
                    margin: "20px auto",
                    width: "90%",
                    padding: 10,
                    textAlign: "center",
                    color: "white",
                    backgroundColor: "red",
                    borderRadius: 20,
                  }}
                >
                  <h2 className="msmall" style={{ color: "white" }}>
                ¡Sí, veo valor!
                </h2>
                <P className="p">
                Quiero la experiencia completa de NuParla por solo US$49.95. <br/>
                (Haz clic para ir directamente a la sección de pagos.) 
                </P>
                </div></a>
              </div>

              <P style={{ width: "90%", color: "#38448a", margin: "auto" }}>
                Aceptaste el reto <b>NuParla x 90 días</b>, y desde cero
                creciste. Pasaste por los 4 niveles, de Principiante a
                Intermediario Avanzado: ¡Felicidades por tu logro! Ahora tienes
                tu primera cita para una entrevista de trabajo, y todo es en
                inglés. Don't worry! Con este curso estarás listo y confiado en
                2 semanas. Repasaremos los principios de una entrevista exitosa
                y cómo contestar preguntas difíciles.{" "}
                <b>
                  Tendrás repaso con nuestra Directora de Entrevistas de NuParla
                  antes de tu entrevista.
                </b>{" "}
                ¡Estamos juntos!
              </P>
            </Grid50>
          )}

          {window.innerWidth > 600 && (
            <Grid50>
              <P style={{ width: "90%", color: "#38448a", margin: "auto" }}>
                Aceptaste el reto <b>NuParla x 90 días</b>, y desde cero
                creciste. Pasaste por los 4 niveles, de Principiante a
                Intermediario Avanzado: ¡Felicidades por tu logro! Ahora tienes
                tu primera cita para una entrevista de trabajo, y todo es en
                inglés. Don't worry! Con este curso estarás listo y confiado en
                2 semanas. Repasaremos los principios de una entrevista exitosa
                y cómo contestar preguntas difíciles.{" "}
                <b>
                  Tendrás repaso con nuestra Directora de Entrevistas de NuParla
                  antes de tu entrevista.
                </b>{" "}
                ¡Estamos juntos!
              </P>

              <div
                style={{
                  color: "#38448a",
                  width: "90%",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <h1 className="no-m-p">Bonus #2</h1>
                <h2 className="no-m-p msmall">
                  Prepared for the Job Interview:
                </h2>
                <h3 className="no-m-p" style={{ color: "black" }}>
                  (valuado en US $250)
                </h3>

                <img src={interview} style={{ width: "50%" }} />

                <a href="#register"><div
                  style={{
                    margin: "20px auto",
                    width: "90%",
                    padding: 10,
                    textAlign: "center",
                    color: "white",
                    backgroundColor: "red",
                    borderRadius: 20,
                  }}
                >
                  <h2 className="msmall" style={{ color: "white" }}>
                ¡Sí, veo valor!
                </h2>
                <P className="p">
                Quiero la experiencia completa de NuParla por solo US$49.95. <br/>
                (Haz clic para ir directamente a la sección de pagos.) 
                </P>
                </div></a>
              </div>
            </Grid50>
          )}

<Grid50>
            <div
              style={{
                color: "#38448a",
                width: "90%",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <h1 className="no-m-p">Bonus #3</h1>
              <h2 className="no-m-p msmall">Prepared For The USCIS Civics Test:</h2>
              <h3 className="no-m-p" style={{ color: "black" }}>
                {" "}
                (valuado en US $250)
              </h3>

              <img src={civic} style={{ width: "50%" }} />

              <a href="#register"><div
                style={{
                  margin: "20px auto",
                  width: "90%",
                  padding: 10,
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "red",
                  borderRadius: 20,
                }}
              >
                <h2 className="msmall" style={{ color: "white" }}>
                ¡Sí, veo valor!
                </h2>
                <P className="p">
                Quiero la experiencia completa de NuParla por solo US$49.95. <br/>
                (Haz clic para ir directamente a la sección de pagos.) 
                </P>
              </div></a>
            </div>

            <P style={{ width: "90%", color: "#38448a", margin: "auto" }}>
                Solo tú sabes TODO por lo que pasaste para llegar a Estados
                Unidos. Lograste que te dieran la residencia permanente.
                ¡Felicidades! Sabemos muy personalmente cuán difícil eso es.
                Pero ahora, se cumplen tus cinco años, y es tu turno para
                obtener la ciudadanía americana. Y la entrevista oral y examen
                por escrito son in English. Are you ready?
                <br />
                <br />
                Con nuestro curso, lo estarás y {" "}
                <b>
                   tendrás repaso con nuestra Directora de Entrevistas de NuParla
                  antes de ese día.
                </b>{" "}
                ¡Estamos juntos!
              </P>
          </Grid50>

          {window.innerWidth <= 600 && (
            <Grid50>
              <div
                style={{
                  color: "#38448a",
                  width: "90%",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <h1 className="no-m-p">Bonus #4</h1>
                <h2 className="no-m-p msmall">
                  2 x 1:
                </h2>
                <h3 className="no-m-p" style={{ color: "black" }}>
                  (valuado en US $2,500)
                </h3>

                <img src={mainImg} style={{ width: "70%" }} />

                <a href="#register"><div
                  style={{
                    margin: "20px auto",
                    width: "90%",
                    padding: 10,
                    textAlign: "center",
                    color: "white",
                    backgroundColor: "red",
                    borderRadius: 20,
                  }}
                >
                  <h2 className="msmall" style={{ color: "white" }}>
                ¡Sí, veo valor!
                </h2>
                <P className="p">
                Quiero la experiencia completa de NuParla por solo US$49.95. <br/>
                (Haz clic para ir directamente a la sección de pagos.) 
                </P>
                </div></a>
              </div>

              <P style={{ width: "90%", color: "#38448a", margin: "auto" }}>
              Sabemos lo importante que es practicar inglés no solo con nosotros, sino también con alguien que quieres o admiras. Es por eso que le estamos obsequiando una entrada a nuestra Academia de inglés a UNA (1) persona que escojas. Esta persona tendrá su propia posición y acceso a todo lo que tú tendrás al unirte a NuParla. Así que piensa bien a quién le quieres regalar este obsequio para que juntos aprendan inglés, y si desean, ganen dinero. 
              </P>
            </Grid50>
          )}

          {window.innerWidth > 600 && (
            <Grid50>
              <P style={{ width: "90%", color: "#38448a", margin: "auto" }}>
              Sabemos lo importante que es practicar inglés no solo con nosotros, sino también con alguien que quieres o admiras. Es por eso que le estamos obsequiando una entrada a nuestra Academia de inglés a UNA (1) persona que escojas. Esta persona tendrá su propia posición y acceso a todo lo que tú tendrás al unirte a NuParla. Así que piensa bien a quién le quieres regalar este obsequio para que juntos aprendan inglés, y si desean, ganen dinero. 
              </P>

              <div
                style={{
                  color: "#38448a",
                  width: "90%",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <h1 className="no-m-p">Bonus #4</h1>
                <h2 className="no-m-p msmall">
                  2 x 1:
                </h2>
                <h3 className="no-m-p" style={{ color: "black" }}>
                  (valuado en US $2,500)
                </h3>

                <img src={mainImg} style={{ width: "70%" }} />

                <a href="#register"><div
                  style={{
                    margin: "20px auto",
                    width: "90%",
                    padding: 10,
                    textAlign: "center",
                    color: "white",
                    backgroundColor: "red",
                    borderRadius: 20,
                  }}
                >
                  <h2 className="msmall" style={{ color: "white" }}>
                ¡Sí, veo valor!
                </h2>
                <P className="p">
                Quiero la experiencia completa de NuParla por solo US$49.95. <br/>
                (Haz clic para ir directamente a la sección de pagos.) 
                </P>
                </div></a>
              </div>
            </Grid50>
          )}

          <h1
            style={{
              color: "red",
              fontWeight: "normal",
              textAlign: "center",
              marginTop: 50,
            }}
          >
            Lo SEGUNDO a lo que TENDRÁS ACCESO es a...
          </h1>

          <Grid3>
            <div style={{ width: "80%", margin: "auto", textAlign: "center" }}>
              <h1 className="mmedium" style={{ width: "80%", margin: "auto" }}>
                Harold Zapata
              </h1>
              {/* <div style={{width: '100%', margin: 'auto', height: 200, backgroundColor: 'black', opacity: 0.2}}>
              </div> */}
              <img src={lapEbook} style={{margin: '30px auto', width: '90%'}} />
            </div>
            <div style={{ width: "80%", margin: "auto" }}>
              <P>
                Raro es encontrar a un nativo americano y nativo latinoamericano
                en el mismo Coach - alguien bilingüe y bicultural - que inspire
                confianza, transparencia y honestidad...De esos tipos que se
                quitan la camisa para dártela si te falta, y que en el bus se
                pone de pie para ceder su asiento a una dama. Pero sobre todo,
                alguien que entienda lo importante que el inglés es para ti, y
                que sepa enseñarte intuitivamente... Con más de 25 años de
                experiencia en comunicación bilingüe y negocios, NuParla sale de
                Harold en tiempos del Coronavirus para ayudar a LATINOS a
                aprender inglés y ganar dinero desde sus casas. Más que un
                curso, NuParla es un MOVIMIENTO con una noble misión:{" "}
                <b>Aprende inglés. Gana dinero.</b>
              </P>
            </div>
          </Grid3>

          <a href="#register"><div
            style={{
              margin: "60px auto",
              width: "70%",
              padding: 10,
              textAlign: "center",
              color: "white",
              backgroundColor: "red",
              borderRadius: 20,
            }}
          >
            <h2 style={{ color: "white" }}>¡ME UNO AL MOVIMIENTO NUPARLA!</h2>
            <P className="p">Gracias Harold por esta oportunidad.</P>
          </div></a>

          <div style={{ width: "90%", margin: "auto" }}>
            <h2 style={{ textAlign: "center" }}>
              Tu misión, si decides aceptarla...
            </h2>

            <h1
              className="mmedium"
              style={{
                color: "red",
                width: "90%",
                textAlign: "center",
                margin: "30px auto",
              }}
            >
              Es TOMAR ACCIÓN y completar 90 pasos (1 paso diario) para aprender
              inglés por el resto de tu vida...
            </h1>

            <P>
              <b>Sí,</b> tomará 30 minutos + de tu tiempo cada día...
              <br />
              <br />
              <b>Sí,</b> tienes que decidir a enfocarte y hacer las tareas...
              <br />
              <br />
              <b>Sí,</b> tendrás que confiar en el proceso y dónde este te va a
              llevar...
              <br />
              <br />
              <b>PERO cada día,</b> te estarás acercando más a tus metas, y ¡las
              lograrás!
              <br />
              <br />
              Nuestra única pregunta para ti es...
            </P>

            <h1
              style={{ width: "90%", textAlign: "center", margin: "30px auto" }}
            >
              ¿TIENES POR DENTRO LO NECESARIO PARA TRIUNFAR?
            </h1>

            <P>
              Para cuando terminen los 90 días:
              <li>podrás conversar en inglés</li>
              <li>
                con algunas pocas referencias, te habrá salido el curso gratis
              </li>
              <li>
                estarás listo para ingresar a NuParla University y a los muchos
                cursos en inglés que estamos preparando para ti
              </li>
              <li>
                y quién sabe, quizás hasta podrías hacer de NuParla una
                oportunidad financieramente lucrativa...
              </li>
            </P>
          </div>
          <Grid50>
            <div style={{ width: "80%", textAlign: "center", margin: "auto" }}>
              <h1>¡NuParla es única!</h1>
              <img src={logobg} style={{ width: "80%" }} />
            </div>
            <P style={{ width: "80%", margin: "auto" }}>
              NuParla será el único y último curso que necesitarás para hablar
              inglés.
              <br />
              <br />
              Combinamos CONOCIMIENTO: La torre de Babel es donde dicen que se
              confundieron los idiomas, y con nuestra metodología de 7 pasos
              intuitivos, seremos tu puente confiable de idiomas...
              <br />
              <br />
              Combinamos EJECUCIÓN: Las dos antorchas representan descubrimiento
              y un avance seguro. El problema no es falta conocimiento, sino
              falta de ejecución. Más que nuevos caminos, lo que brindamos son
              nuevos ojos de entendimiento...
              <br />
              <br />
              Combinamos RESPONSABILIDAD: El círculo representa nuestra cuesta
              de un aprendizaje continuo con enfoque y rendimiento de cuentas...
            </P>
          </Grid50>

          <a href="#register"><div
            style={{
              margin: "60px auto",
              width: "70%",
              padding: 10,
              textAlign: "center",
              color: "white",
              backgroundColor: "red",
              borderRadius: 20,
            }}
          >
            <h2 style={{ color: "white" }}>
              ¡ÚNETE AL RETO NUPARLA x 90 DÍAS!
            </h2>
            <P>Aprende inglés. Gana dinero.</P>
          </div></a>

          <div style={{ textAlign: "center" }}>
            <img
              src={mainImg}
              style={{ width: "80%", margin: "auto", textAlign: "center" }}
            />
          </div>

          <Grid3
            style={{
              textAlign: "center",
              color: "#38448a",
              margin: "auto",
              width: "90%",
            }}
          >
            <div></div>
            <h1>La decisión final la tienes TÚ...</h1>
          </Grid3>

          <Grid3 style={{ textAlign: "center", color: "#38448a", marginTop: window.innerWidth >= 900 ? -400 : 0 }}>
            <div></div>
            <P style={{ width: "80%", margin: "auto" }}>
              <b>
                <i>¿Por qué debiera escoger NuParla?</i>
              </b>{" "}
              Porque si no podemos ayudar a que hables inglés, y con algunas
              referencias poder pagar tu curso, entonces no merecemos tu
              inversión de $399.{" "}
            </P>
          </Grid3>

          <Grid3 style={{ textAlign: "center", color: "#38448a" }}>
            <div></div>
            <P style={{ width: "80%", margin: "auto" }}>
              Tú y yo sabemos que ningún curso que valga la pena tiene sentido a
              menos que su creador te entienda como LATINO, pero que sea tan
              Estadounidense que pueda plantar el inglés muy dentro de tu
              corazón y mente para que fluya de ti naturalmente. Con NuParla,
              estamos comprometidos a luchar a tu lado más que cualquier otro
              curso, y no nos rendiremos hasta que hables el inglés para que
              logres tus metas.{" "}
            </P>
          </Grid3>

          <Grid3 style={{ textAlign: "center", color: "#38448a" }}>
            <div></div>
            <P style={{ width: "80%", margin: "auto" }}>
              Así que escoge la plataforma que tantos están prefiriendo. No
              dejes que pase más tiempo, o que otro tome tu lugar. Si actúas
              ahora tendrás potencialmente doble las oportunidades con tu nuevo
              conocimiento del inglés en 90 días.{" "}
              <u>
                Eso es lo que yo haría, y he estado haciendo esto por dos
                décadas.
              </u>{" "}
              Pero, la verdad es que no puedo decirte qué es lo que tienes que
              hacer. Tú harás lo que es mejor para ti y tus metas.{" "}
            </P>
          </Grid3>

          <Grid3 style={{ textAlign: "center", color: "#38448a" }}>
            <div></div>
            <P style={{ width: "80%", margin: "auto" }}>
              Si vas a decirle Sí al reto <b>"Nuparla x 90 días"</b> entonces no
              dilates. Un proverbio oriental dice:{" "}
              <b>
                "El mejor momento para plantar un árbol fue hace 20 años...mas
                el segundo mejor momento es AHORA MISMO..."
              </b>{" "}
            </P>
          </Grid3>

          <a href="#register"><div
            style={{
              margin: "60px auto",
              width: "70%",
              padding: 10,
              textAlign: "center",
              color: "white",
              backgroundColor: "red",
              borderRadius: 20,
            }}
          >
            <h2 style={{ color: "white" }}>
              ¡ÚNETE AL RETO NUPARLA x 90 DÍAS!
            </h2>
            <P>Aprende inglés. Gana dinero.</P>
          </div></a>

          <div style={{ color: "#38448a", width: "90%", margin: "auto" }}>
            <h1 style={{ textAlign: "center" }}>¿Existe alguna garantía?</h1>
            <P>
              Por supuesto... :-)
              <br />
              <br />
              Te garantizo que si haces los 90 pasos, uno cada día, y le dedicas
              esfuerzo y enfoque para hacer las tareas,{" "}
              <b>
                vas a hablar, expresarte y hacerte entender en inglés con
                confianza y naturalmente...
              </b>
              <br />
              <br />
              Te garantizo que si refieres NuParla a alguien, te pagaremos entre
              $100 a $225, y no tienes límite para cuántos puedas referir (con
              que vivan en algún país latinoamericano, España o Estados Unidos).
              <br />
              <br />
              Por si alguna razón no sientes que todo esto es verdad dentro de
              los primeros 7 días de comprarlo, te devolvemos tus $399.
              <br />
              <br />
              <b>
                <u>
                  Pero si eres como casi todos, sé que esta experiencia
                  enriquecerá tu vida al aprender inglés y ganar dinero.
                </u>
              </b>{" "}
            </P>
          </div>

          <a href="#register"><div
            style={{
              margin: "60px auto",
              width: "70%",
              padding: 10,
              textAlign: "center",
              color: "white",
              backgroundColor: "red",
              borderRadius: 20,
            }}
          >
            <h2 style={{ color: "white" }}>
              ¡ÚNETE AL RETO NUPARLA x 90 DÍAS!
            </h2>
            <P className="p">
              Quiero entrenamiento individual diario y rendición de cuentas del
              día 1 al 90...
            </P>
          </div></a>

          <div style={{ color: "#38448a", width: "90%", margin: "auto" }}>
            <h1 style={{ textAlign: "center" }}>
              Los siguientes pasos para tomar...
            </h1>
            <P>
              De aquí solo falta finalizar los detalles.{" "}
              <b>Haz clic en el botón de abajo para crear tu cuenta,</b> ¡y por
              fin comenzamos!
              <br />
              <br />
              Gracias por tomarte el tiempo para leer esta carta y ver los
              videos. Espero escuchar de todos tus logros y éxitos en los meses
              y años por venir.
              <br />
              <br />
              Bienvenido a la familia NuParla...
              <br />
              <br />
              Un servidor,
              <br />
              <br />
              <b>Harold Zapata</b>
              <br />
              <br />
              <b>P.D.</b> En dado caso que seas una de aquellas personas que les
              gusta los resúmenes primordialmente (como yo), te presento lo
              esencial del asunto:
              <br />
              <br />
              <b>
                <i>
                  Cuando te unes al reto "NuParla x 90 días" (por solo $399) vas
                  a ingresar a una plataforma avanzada donde aprenderás, en 90
                  días, cuatro niveles de inglés por medio de una metodología
                  propia que incluye sesiones en videos, con tareas en audios,
                  mas cuatro bonus adicionales para prepararte para eventos
                  importantes. Obtendrás también tu propia oficina virtual para
                  registrar a algún referido en USA, latinoamérica o España,
                  para comisionar entre $100 a $225.
                </i>
              </b>
              <br />
              <br />
              Y si en los primeros 7 días NuParla no es para ti, te devolvemos
              los $399.
              <br />
              <br />
              Entonces, si te parece...¿qué esperas?{" "}
              <b>Únete al reto NuParla x 90 días.</b>
            </P>
          </div>

          <div
            style={{
              border: "8px solid rgb(0, 36, 98)",
              margin: "60px auto",
              width: "90%",
            }}
          >
            <div
              style={{
                backgroundColor: "rgb(0, 36, 98)",
                padding: "10px 0px",
                color: "white",
                width: "100%",
                textAlign: "center",
                margin: "0px auto 0px auto",
              }}
            >
              <h2 style={{ color: "white" }}>REPASEMOS</h2>
              <h1 style={{ color: "white" }}>TODO LO QUE ES TUYO</h1>
              <h2 style={{ color: "white" }}>
                Cuando aceptes el reto de NuParla x 90 días ¡HOY!
              </h2>
            </div>

            <div style={{ textAlign: "center" }}>
              <img
                src={mainImg}
                style={{ width: "80%", margin: "auto", textAlign: "center" }}
              />
            </div>

            <div style={{ width: "100%", margin: "auto" }}>
              <div style={{ margin: "auto" }}>
                <img src={prices} style={{ width: "100%" }} />
                {/* <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >El método neurocientífico exclusivo de NuParla</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ Inestimable)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >La plataforma avanzada de aprendizaje cibernético: </li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($400)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Más de 100+ videos (50+ horas) de coaching con Hárold</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($1,700)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Más de 100+ grabaciones descargables (30+ horas) con Hárold</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($700)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Sistema bi-direccional de mensajes exclusivo a NuParla</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($400)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li > Programa de Afiliados NuParleros para ganar dinero</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ Inestimable)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Oficina virtual y sitio de web replicable para gestionar tus ventas</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($750)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Ingreso a NuParla Village</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($300)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >Acceso de por vida a NuParla University</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($ Inestimable)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >BONUS e-book: Inglés subconciente: Los 7 secretos de NuParla</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($35)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li >BONUS curso exclusivo: Prepared For The Job Interview</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($250)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li  >BONUS curso exclusivo: Prepared For Traveling Abroad</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($250)</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: 700, margin: 'auto'}}>
                            <li  >BONUS curso exclusivo: Prepared For The USCIS Civics Test</li>
                            <p className="no-m-p" style={{color: 'red', fontWeight: 'bold'}}>($250)</p>
                        </div> */}
              </div>

              <h1
                style={{
                  color: "red",
                  textAlign: "center",
                  margin: "20px 0px",
                }}
              >
                Valorado en: US $<s>5,035</s>
              </h1>

              <P style={{ width: "90", textAlign: "center", margin: "auto" }}>
                Si quieres aprender inglés de una vez por todas, entonces esta
                es la misión que te estamos dando...
              </P>

              <P style={{ width: "90", textAlign: "center", margin: "auto" }}>
                Solo 30 minutos diarios por 90 días...
              </P>

              <h1 style={{ color: "red", textAlign: "center", marginTop: 20 }}>
                ¿ACEPTAS?
              </h1>
            </div>

            <div
              style={{
                backgroundColor: "rgb(0, 36, 98)",
                padding: "15px 0px",
                color: "white",
                width: "100%",
                textAlign: "center",
                margin: "60px auto 0px auto",
              }}
            >
              <h2 style={{ color: "white" }}>
                Únete al reto NuParla x 90 días con un pago de
              </h2>
              <h1 style={{ color: "red" }}>SOLO US $399</h1>
            </div>
          </div>

          <div
            style={{
              width: "80%",
              margin: "auto",
              border: "1px solid #bebebe",
            }}
            id="register"
          >
            <div style={{ width: "100%", margin: "auto" }}>
              <h2
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "10px 0px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                PASO 1. ELIGE TU PLAN
              </h2>
              <div style={{ width: "90%", margin: "auto" }}>
                <div className="flex" style={{ color: "#38448a" }}>
                <Checkbox
                style={{marginTop: -20}}
                color="primary"

                  checked={this.state.plan == 1}
                  onChange={( ) => {this.setState({plan: 1})}}
                />
                  <P>Un pago de US $399 - (total: US $399)</P>
                </div>
                <div className="flex" style={{ color: "#38448a" }}>
                <Checkbox
                style={{marginTop: -20}}
                color="primary"

                  checked={this.state.plan == 2}
                  onChange={( ) => {this.setState({plan: 2})}}
                />
                  <P>Dos pagos de US $249 - (total: US $498)</P>
                </div>
                <div className="flex" style={{ color: "#38448a" }}>
                <Checkbox
                style={{marginTop: -20}}
                color="primary"

                  checked={this.state.plan == 3}
                  onChange={( ) => {this.setState({plan: 3})}}
                />
                  <P>Tres pago de US $199 - (total: US $597)</P>
                </div>
              </div>

              <h2
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "10px 0px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                PASO 2. INFORMACIÓN DE CONTACTO Y CREDENCIALES DE PLATAFORMA
              </h2>

              <div style={{ width: "90%", margin: "auto" }}>
                <Grid50>
                  <div>
                    <P2>Nombre:</P2>
                    <input
                      className="input"
                      onChange={(e) =>
                        this.setState({ firstName: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <P2>Apellido:</P2>
                    <input
                      className="input"
                      onChange={(e) =>
                        this.setState({ lastName: e.target.value })
                      }
                    />
                  </div>
                </Grid50>
                <div>
                  <P2>Correo electrónico:</P2>
                  <input
                    className="input"
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>
                
                
                
                
                

                

                

                {/* <div>
                  <P2>Nombre de usuario</P2>
                  <input
                    className="input"
                    placeholder="(para ingresar a tu plataforma virtual)"
                    onChange={(e) =>
                      this.setState({ userName: e.target.value })
                    }
                  />
                </div> */}

                <div>
                  <P2>Contraseña</P2>
                  <input
                  type="password"
                  placeholder= "(mínimo 8 dígitos alfa-numéricos)"
                    className="input"
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                </div>

                <div>
                  <P2>Contraseña confirmada</P2>
                  <input
                  type="password"
                    className="input"
                    onChange={(e) =>
                      this.setState({ verifyPassword: e.target.value })
                    }
                  />
                </div>
              </div>
              <h2
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "10px 0px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                PASO 3. PAGO
              </h2>
              <div style={{width: '90%', margin: 'auto'}}>
              <div className="flex">
              <input
                type="checkbox"
                style={{ marginTop: 35, marginRight: 10, marginLeft: 10, cursor:'pointer' }}
                checked={this.state.payment == 'stripe'}
                onChange={()=>this.setState({payment: 'stripe'})}
              />
              <img src={stripe} style={{height: 80}}/>
            </div>
            <div className="flex">
              <input
                type="checkbox"
                style={{ marginTop: 35, marginRight: 10, marginLeft: 10, cursor:'pointer' }}
                checked={this.state.payment == 'paypal'}
                
                onChange={()=>this.setState({payment: 'paypal'})}
              />
              <img src={paypal} style={{height: 80}}/>
            </div>
            <div className="flex">
              <input
                type="checkbox"
                style={{ marginTop: 35, marginRight: 10, marginLeft: 10, cursor:'pointer' }}
                checked={this.state.payment == 'btc'}
                onChange={()=>this.setState({payment: 'btc'})}
              />
              <img src={bitcoin} style={{height: 80}}/>
            </div>

              </div>
              <div style={{width: '90%', margin: 'auto', textAlign: 'center'}}>
              <div style={{margin: '10px auto', backgroundColor: '#ebebeb', borderRadius: 5, padding: 10}}>
                  <p>*Clicking on Crea tu cuenta (Create Your Account) means that you have read and agree with NuParla’s <a href="/terms" className="terms-highlight" style={{color: 'black'}}>Terms</a> and <a href="/privacy" className="terms-highlight" style={{color: 'black'}}>Privacy</a> policies. </p>
                  <p>*Al hacerle clic a Crea tu cuenta significa que has leído y apruebas las políticas de términos y privacidad de NuParla.</p>
                

                  </div>
                
                <button className="next-step"  onClick={() => this.verify()}>
                Crea tu cuenta
                </button>

                </div>
            </div>
          </div>

          <div
            style={{
              backgroundColor: "rgb(0, 36, 98)",
              padding: "15px 5%",
              color: "white",
              width: "90%",
              textAlign: "center",
              margin: "60px auto 0px auto",
            }}
          >
            <img />
            <div
              className="flex"
              style={{
                margin: "auto",
                width: 300,
                justifyContent: "space-around",
              }}
            >
              <Link to="/terms">
                <P>Terms</P>
              </Link>
              <Link to="/privacy">
                <P>Privacy</P>
              </Link>
              <Link to="/support">
                <P>Support</P>
              </Link>
            </div>

            <P>
              © 2021 NuParla LLC - All Rights Reserved - 211 N. State St. Suite 203, Hemet, CA-92543
              
            </P>
          </div>
        </div>
        <Dialog
        open={this.state.backdrop}
        style={{padding: '40px 40px 20x 40px'}}
        >
          <p style={{margin: 20}}>Please Wait...</p>
          <CircularProgress style={{width: 30, margin: '10px auto'}} />
        </Dialog>
      </div>
    );
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 55% 45%;
  margin-top: 30px;

  @media (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Grid2 = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  margin: 30px 20px;
  @media (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Grid50 = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  margin-top: 60px;
  column-gap: 4%;
  @media (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
  }
`;
const Grid3 = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  margin-top: 60px;
  @media (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const SelectDiv = styled.div`
  border: 4px solid rgba(164, 209, 250, 0.78);
  width: 80%;
  padding: 10px;
  margin: auto;
  margin-top: 40px;
  background-color: rgb(228, 239, 249);
  color: rgb(30, 77, 167);
`;

const P = styled.p`
  font-size: x-large;

  @media (max-width: 800px) {
    font-size: large;
  }
`;

const P2 = styled.p`
  font-size: x-large;
  margin-bottom: 0px;

  @media (max-width: 800px) {
    font-size: large;
  }
`;
