import React, {useState} from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import DraftsIcon from '@material-ui/icons/Drafts';

import { makeStyles, useTheme, StylesProvider } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";

import LayersIcon from "@material-ui/icons/Layers";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import BusinessIcon from "@material-ui/icons/Business";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DeviceHubRoundedIcon from "@material-ui/icons/DeviceHubRounded";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Popover from "@material-ui/core/Popover";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SurroundSoundIcon from "@material-ui/icons/SurroundSound";
import "../../App.css";
import "./backOffice.css";
// import StatusSlider from "./statusSlider";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import banner from "../../Images/banner.jpg";
// import profile from './profile'
// import users from './users'
// import companyAccount from './companyAccount'
// import packageDetails from './packageDetails'
// import groups from './groups';

const whiteTheme = createMuiTheme({
  palette: { primary: { main: "#000" }, secondary: { main: "#3A66A7" } },
});

const drawerWidth = 200;

const styles = {
  active: {
    backgroundColor: "#404040",
  },
  gold: {
    color: "#e9c979",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: 1,
    },
    backgroundColor: "black",
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#707070",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => ++value); // update the state to force render
}

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selected, setSelected] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const forceUpdate = useForceUpdate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const drawer = (
    <div>
      <div style={{ backgroundColor: "black" }} color="primary" />
      {/* <a href="/"><img src={logo} style={{height: 40, position: 'absolute', marginTop: -50, marginLeft: 20}} /></a> */}
      <Divider />
      <div className="nav-header">
        <AccountCircleIcon
          style={{
            width: "6vw",
            height: "6vw",
            color: "#bebebe",
            marginTop: "2%",
          }}
        />
        {/* <p className="white">Hi, User</p> */}
      </div>

      <div style={{ width: "100%", textAlign: "center" }}>
        <h3
          className="white"
          style={{ fontSize: "x-large", margin: "10px 10px" }}
        >
          Back Office
        </h3>
      </div>
      <List>
        <Divider />

        <a href="/admin/financials">
          <ListItem
            button
            style={selected == "financials" ? styles.active : null}
            onClick={() => {
              setSelected("financials");
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <MonetizationOnIcon style={styles.gold} />
            </ListItemIcon>
            <ListItemText primary="Financials" />
          </ListItem>
        </a>
        <Divider />

        <a href="/admin/affiliates">
          <ListItem
            button
            style={selected == "affiliates" ? styles.active : null}
            onClick={() => {
              setSelected("affiliates");
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <PeopleAltIcon style={styles.gold} />
            </ListItemIcon>
            <ListItemText primary="Affiliates" />
          </ListItem>
        </a>
        <Divider />

        <a href="/admin/marketing">
          <ListItem
            button
            style={selected == "marketing" ? styles.active : null}
            onClick={() => {
              setSelected("marketing");
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon style={styles.gold} />
            </ListItemIcon>
            <ListItemText primary="Marketing" />
          </ListItem>
        </a>
        <Divider />

        <a href="/admin/training">
          <ListItem
            button
            style={selected == "training" ? styles.active : null}
            onClick={() => {
              setSelected("training");
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <BusinessCenterIcon style={styles.gold} />
            </ListItemIcon>
            <ListItemText primary="Training" />
          </ListItem>
        </a>
        <Divider />

        <a href="/admin/reports">
          <ListItem
            button
            style={selected == "reports" ? styles.active : null}
            onClick={() => {
              setSelected("reports");
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <LocalLibraryIcon style={styles.gold} />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItem>
        </a>
        <Divider />

        <a href="/admin/tickets">
          <ListItem
            button
            style={selected == "tickets" ? styles.active : null}
            onClick={() => {
              setSelected("tickets");
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <AssignmentIcon style={styles.gold} />
            </ListItemIcon>
            <ListItemText primary="Tickets" />
          </ListItem>
        </a>
        <Divider />

        <a href="/admin/contact-messages">
          <ListItem
            button
            style={selected == "messages" ? styles.active : null}
            onClick={() => {
              setSelected("messages");
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <DraftsIcon style={styles.gold} />
            </ListItemIcon>
            <ListItemText primary="Messages" />
          </ListItem>
        </a>
        <Divider />



        <div 
        style={{position: 'fixed', bottom: 0, left: 0, backgroundColor: '#404040', padding: '0px 37px 0px 20px'}}>
          <ListItem

            button
            
            onClick = {() => {
              localStorage.clear()
              setMobileOpen(false)
              props.window.location.reload()
            }}
          >
            <ListItemIcon>
             
            </ListItemIcon>
            <ListItemText style={{color: 'white'}} primary="Log out" />
          </ListItem>
        </div>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Router>
      <MuiThemeProvider theme={whiteTheme}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" color="primary" style={{backgroundColor: 'rgba(0,0,0,0)', boxShadow: 'none'}} >
        <Toolbar>
          <IconButton
          style={{position: 'fixed', top: 20, left: 20}}
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          
          
        </Toolbar>
      </AppBar>
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <img src={banner} className="banner" />

          <Switch>
            {/* <Route exact path="/admin/company-account" component={companyAccount} />
          <Route exact path="/admin/package-details" component={packageDetails} />
          <Route path="/admin/users" component={users} />
          <Route path="/admin/groups" component={groups} /> */}
          </Switch>
        </div>
      </MuiThemeProvider>
    </Router>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
