import React, { Component } from "react";
import "../../../App.css";
import styled from "styled-components";
import { fire } from "../../../config";
import storage from "../../../config";
import "../../../config";
import * as firebase from "firebase";
import ReactPlayer from "react-player";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Input } from "antd";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import ReactAudioPlayer from 'react-audio-player';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import logo from '../../../Images/nuparla em1 .1.png'
import Dialog from '@material-ui/core/Dialog';
import { TextField } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import refreshIcon from '../../../Images/refresh.png'
import { makeStyles } from '@material-ui/core/styles';
import { Steps } from 'antd';
import MicRecorder from 'mic-recorder-to-mp3';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Cert from '../../../Images/level 1.jpg'
import GetAppIcon from '@material-ui/icons/GetApp';
import Bgn from '../../../Images/bgn-black.jpg'
const Mp3Recorder = new MicRecorder({ bitRate: 128 });


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const { Step } = Steps;

export default class level1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      dialog: false,
      exercise: [],
      index: 0,

      shuffled: [],
      ans: [],
      sentence: [],
      open: false,
      open2: false,


      level1: {
        lessons: []
      },
      selectedLesson: null,
      
      dialogVideo: false,
      dialogAudio: false,
      dialogExercise: false,
      dialogResponse: false,
      dialogDownload: false,

      Index: 0,
      qIndex: 0,

      user: {},

      recording: false,
      audio: '',
      isRecording: false,
      blobURL: '',
      recorded: false,
      response: '',

      progress: false,

      word1: '',
      word2: '',
      word3: '',
      word4: '',
      word5: '',
      word6: '',
      word7: '',

      dialogCert: false,

      shuffled: [],
      wrongQuestions: [],

      result: null,
      responseSent: false,

      audioStoryLink: '',
      audioStoryDialog: false

    };
  }

  componentDidMount() {
    firebase.firestore().collection('Course').doc('level1').get().then(res => {
  
 
      this.setState({level1: res.data()})
      


    })

    firebase.firestore().collection('Users').doc(localStorage.getItem('email')).onSnapshot(res => {
     
      this.setState({user: res.data()})

      // firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
      //   learningProgress: {
      //     level: 1,
      //     lesson: 1,
      //     step: 1
      //   }
      // })
    })


  }



  start = () => {
    this.setState({recording: true})
    
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true });
        }).catch((e) => console.error(e));
    
  };

  stop = () => {
    this.setState({recording: false})
    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob)
        console.log(blob)
        this.setState({ blobURL, blob: blob, isRecording: false, recorded: true });
      }).catch((e) => console.log(e));
  };

  shuffle(array){
    var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;

  }

  send = (lesson) => {
    this.setState({recorded: false,progress: true })

    if (this.state.blobURL != ''){

      let upload = storage
        .ref(`responses/${this.state.user.userName + lesson}`)
        .put(this.state.blob);
      upload.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref(`responses/${this.state.user.userName + lesson}`)
            .getDownloadURL()
            .then(async (url) => {
              firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
                Responses: firebase.firestore.FieldValue.arrayUnion({
                  level: 1,
                  lesson: lesson,
                  audio: url
                }) 
        
              }).then((res) => {
                this.setState({responseSent: true, progress: false})
              })
              

            
        })})
    }
  }


  sendResponse = (lesson) => {

    firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
      Responses: firebase.firestore.FieldValue.arrayUnion({
        level: 1,
        lesson: lesson,
        text: this.state.response
      }) 

    }).then((res) => {
      this.setState({responseSent: true})
    })
    

  }




   handleClick = () => {
    this.setState({open: true})
    };

   handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }

    // this.setState({open: false})
  };

  getCourses() {
    firebase
      .database()
      .ref("Courses")
      .child("level1")
      .once("value")
      .then((snapshot) => {
        this.setState({ courses: snapshot.val() });
      });
  }

   shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
    }

  updateProgress(lesson, step) {

    if (lesson == 30 && step == 1){
      console.log('he')
      this.setState({dialogCert: true})
      firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
        learningProgress: {
          level: 2,
          lesson: lesson,
          step: step
        }
      })
    } else {
      firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
        learningProgress: {
          level: 1,
          lesson: lesson,
          step: step
        }
      })
    }

    
  }

  render() {

    if (localStorage.getItem('level') == 0){
      return (
        <div>
          <p>This Level becomes active once you have finished the previous Level. </p>
        </div>
      )
    }else {
      return (
        <div style={{backgroundColor: '#bebebe', padding: 20}}>
          <h1 style={{fontSize: 'x-large', color: '#800000'}}>Level 1</h1>
 
          {this.state.level1.lessons.map((lesson,index) => {

            return (
              <div>
                {index == 0 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#1</span> Questions, Greetings and Introductions</h2>
                  
                </div>}

                {index == 4 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#2</span> Dialogue 1.0</h2>
                  
                </div>}

                {index == 8 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#3</span> Family</h2>
                  
                </div>}

                {index == 13 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#4</span> Restaurant 1.0</h2>
                  
                </div>}

                {index == 17 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#5</span> Foods and Quantities 1.0</h2>
                  
                </div>}

                {index == 21 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#6</span> Shopping and Numbers 1.0</h2>
                  
                </div>}

                {index == 25 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#7</span> Socializing</h2>
                  
                </div>}

                <div style={{cursor: 'pointer', backgroundColor: 'white', width: '90%', margin: '25px auto', borderRadius: '10px', boxShadow: '0px 2px 8px rgba(0,0,0,0.2)', marginBottom: lesson.data.title == 'Refresher Course & Grammar' ? 60 : 25}} >
                  <div style={{display: 'flex', position: 'relative'}} onClick={() => {
                    if(this.state.selectedLesson == index){
                      this.setState({selectedLesson: null})
                    } else {
                      this.setState({selectedLesson: index})
                    }
                    
                  }}>
                    {this.state.user.learningProgress.lesson >= index + 1 && <div style={{position: 'absolute', right: 0, top: 0,width: 30, height: 30, borderRadius: '0px 10px 0px 20px', backgroundColor: '#65a765'}} ><CheckCircleOutlineIcon style={{width: 20, margin: 'auto', color: 'white'}} /></div>}

                    {lesson.data.title != 'Refresher Course & Grammar' && <img style={{width: 80, height: 80, margin: '10px'}} src={lesson.data.avatar} alt="avatar" />}
                    {lesson.data.title == 'Refresher Course & Grammar' && <img style={{width: 80, height: 80, margin: '10px', marginLeft: 40}} src={refreshIcon} alt="avatar" />}
                    {lesson.data.title != 'Refresher Course & Grammar' && <div style={{textAlign: 'left'}}>
                      <H3 style={{marginRight: 30, color: '#3c3b6e'}}>Lesson {lesson.data.number}: {lesson.data.title}</H3>
                      <p style={{marginLeft: 93}}>{lesson.data.description}</p>
                    </div>}

                    {lesson.data.title == 'Refresher Course & Grammar' && <div style={{textAlign: 'left', margin: 'auto 30px'}}>
                      <h2 style={{fontSize: 'x-large'}}>{lesson.data.title} #{lesson.data.number}</h2>
                      
                    </div>}
                    
                  </div>
                  {this.state.selectedLesson == index && <div style={{backgroundColor: '#fafafa', borderRadius: '0px 0px 10px 10px', width: '100%',padding: 10, borderTop: '1px solid #bebebe' }}>
                    <div style={{width: '80%', margin: '10px auto'}}>
                    <Steps current={this.state.user.learningProgress.lesson < index + 1 ? null : this.state.user.learningProgress.lesson > index + 1 ? 6 : this.state.user.learningProgress.step - 1 }>
                      <Step title="Step 1" description="Video" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 1){
                            this.setState({dialogVideo: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 1 && this.state.user.learningProgress.step -1 >= 0)
                          {
                            this.setState({dialogVideo: true})
                          }
                          
                        }}/>
                      {lesson.data.title != 'Refresher Course & Grammar' && <Step title="Step 2"  description="Audio" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 1){
                            this.setState({dialogAudio: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 1 && this.state.user.learningProgress.step -1 >= 1){
                            this.setState({dialogAudio: true})
                          }

                    
                        }}/>}
                      <Step title={lesson.data.title == 'Refresher Course & Grammar' ? "Step 2" : "Step 3"} description="Exercise" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 1){
                            this.setState({dialogExercise: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 1 && this.state.user.learningProgress.step -1 >= 2){
                            this.setState({dialogExercise: true})
                          
                          }
                        
                        }}/>
                      {lesson.data.title != 'Refresher Course & Grammar' && <Step title="Step 4" description="Track" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 1){
                            this.setState({dialogResponse: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 1 && this.state.user.learningProgress.step -1 >= 3){
                            this.setState({dialogResponse: true})
                          }
                        
                        }}/>}
                      {lesson.data.title != 'Refresher Course & Grammar' && <Step title="Step 5" description="Download" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 1){
                            this.setState({dialogDownload: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 1 && this.state.user.learningProgress.step -1 >= 4){
                            this.setState({dialogDownload: true})

                          }
                    
                        }}/>}
                    </Steps>
                    </div>







                    <Dialog
                    open={this.state.dialogVideo}
                    onClose={() => this.setState({dialogVideo: false})}
                    fullScreen
                    >
                      
                      <div style={{height: '100%', width: '100%', backgroundColor: "#505050",position: 'relative'}} >
                        
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 30, right: 20, color: '#edd490'}} onClick={() => this.setState({dialogVideo: false})} />
                      <img src={logo} style={{width: 200, marginTop: 20, marginLeft: 20 }} />
                      <h1 style={{textAlign: 'center', fontSize: 'x-large', color: '#edd490'}}>Step 1. VIDEO LESSON</h1>

                      <div   style={{padding: 0, width: 'min-content', border: '2px solid black', boxShadow: '0px 1px 12px #edd490', textAlign: 'center', margin: '30px auto', zIndex: 1}}>
                  
                        
                        <ReactPlayer
                          
                            style={{ margin: "10px auto 10px auto", border: '1px solid #bebebe' }}
                            height={window.innerWidth >= 800 ? '420px' : window.innerWidth >= 500 ? '280px' : '200px'}
                            width={window.innerWidth >= 800 ? '740px' :window.innerWidth >= 500 ? '480px' : '360px'}
                            controls={true}
                            playing={true}
                            
                            // url={lesson.data.video}
                            url={lesson.data.video}
                            style={{zIndex: 2}}
                            
                          />

                                
                                

                      </div>


                      <div className="aprende-btn" style={{cursor: 'pointer', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.user.learningProgress.level == 1 && this.state.user.learningProgress.lesson == index + 1 && this.state.user.learningProgress.step == 1 && lesson.data.title != 'Refresher Course & Grammar'){
                          this.updateProgress(index + 1, 2)
                        }
                        if (this.state.user.learningProgress.level == 1 && this.state.user.learningProgress.lesson == index + 1 && this.state.user.learningProgress.step == 1 && lesson.data.title == 'Refresher Course & Grammar'){
                          this.updateProgress(index + 1, 3)
                        }
                        if (lesson.data.title != 'Refresher Course & Grammar'){
                          this.setState({dialogVideo: false, dialogAudio: true})
                        } else  {
                          this.setState({dialogVideo: false, dialogExercise: true})

                        }
                        
                      }} >Continue</div>
                      

                      </div>
                    </Dialog>








                    <Dialog
                    open={this.state.dialogAudio}
                    onClose={() => this.setState({dialogAudio: false})}
                    fullScreen
                    >
                      <div style={{height: '100%', width: '100%', backgroundColor: "#505050",position: 'relative'}} >

                      <img src={logo} style={{width: 200, marginTop: 20, marginLeft: 20}} />
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', color: '#edd490', top: 20, right: 20}} onClick={() => this.setState({dialogAudio: false})} />
                      <h1 style={{textAlign: 'center',fontSize: 'x-large', color: '#edd490'}}>Step 2. AUDIO STORY LESSON</h1>

                      
                      
                        <div style={{width: '100%', textAlign: 'center'}}>

                        
                        <audio src={lesson.data.audioStory} style={{padding: 0, margin: '30px auto' }} controls="false" controlsList="nodownload"/>
                        </div>

                      

                      <Dialog 
                      open={this.state.audioStoryDialog}
                      onClose={() => this.setState({audioStoryDialog: false})}
                      >
                        <ReactPlayer
                          
                          style={{ margin: "auto", border: '1px solid #bebebe' }}
                          height='240px'
                          width='400px'
                          controls={true}
                          playing={true}
                          
                          // url={lesson.data.video}
                          url={this.state.audioStoryLink}
                          
                        />
                        

                      </Dialog>

                      


                      {lesson.data.number == 1 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>Hello, and welcome to Step 2. The Audio Story. Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                          Please click below to watch a short 1 minute video (approximate):<br/>
                          <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/465390535"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                          <br/>
                          In this episode, Moe, Larry and Curly need to testify to defend their friend and dancer in a murder trial. The dialogue starts off between the prosecutor and the witness.<br/>
                          <br/>
                          “What is your name?” Asks the lawyer.<br/>
                          “Gale Tempest,” answers the witness. <br/>
                          “What is your occupation?”<br/>
                          “I’m a dancer,” responds Gale. <br/>
                          <br/>
                          “On the night of February the 13th, were you working at the Black Bottom Café when this murder was committed?” Barks the lawyer. <br/>
                          <br/>
                          “Yes, but I didn’t do it,” answers a frightened Gale. <br/>
                          “Then who killed Kirk Robbin?” Shouts the attorney.<br/>
                          “I don’t know.”<br/>
                          “Didn’t you kill Kirk Robbin?” Screams the prosecutor. <br/>
                          <br/>
                          “I object, your honor!” Interrupts the defense attorney. “I ask that the last question be stricken from the records.” He turns to the jury. “It is merely an attempt to influence this intelligent, broad-minded and most intellectual jury.”<br/>
                          <br/>
                          Question: <br/>
                          What is the name of the witness?<br/>
                          What is her occupation?<br/>
                          Where was she on the night of February 13?<br/>
                          Who is accusing her?<br/>
                          Does the jury look intellectual?</p>
                      </Paper>}


                      {lesson.data.number == 2 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story. Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate): <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/465243336"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        <br/>
                        In this episode, Curly takes the stand. <br/>
                        <br/>
                        “Take off your hat.” Demands the bailiff. Curly obeys. <br/>
                        “Now, raise your right hand.” Curly raises his right hand, but puts his hat back on.<br/>
                        “Now, place your left hand here,” indicating to place it on the Bible. <br/>
                        <br/>
                        Curly takes the cane in his left hand to his right hand, places his left hand on the Bible, but his hat is still on. <br/>
                        <br/>
                        The judge orders Curly to take his hat off, and a series of commands are given between the judge and the bailiff to which Curly does the best he can to obey.<br/>
                        <br/>
                        “Take off your hat!” <br/>
                        “Now, raise your right hand!” <br/>
                        “Now, place your left hand here!” <br/>
                        <br/>
                        Finally, the bailiff asks: “Will you get rid of that hat?”<br/>
                        <br/>
                        Curly places the hat on the bailiff and asks him to raise his right hand. He obeys Curly instinctively, and catches himself doing so. The bailiff corrects his action and asks quickly:<br/>
                        <br/>
                        “Do you swear to tell the truth, the whole truth, and nothing but the truth?”<br/>
                        <br/>
                        He speaks so fast, that Curly doesn’t understand him.<br/>
                        <br/>
                        Questions:<br/>
                        Did Curly follow orders?<br/>
                        Why should a person take off his or her when taking the stand?<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 3 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story. Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate):  <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/465378371"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        <br/>
                        In this episode, Moe, Larry and Curly are re-enacting  what happened on the night of the murder before the court. They were playing in a bar and were showing the court how they were doing it, when suddenly, Larry’s bow becomes entangled with the bailiff’s toupee, and  he takes it off. <br/>
                        <br/>
                        Larry confuses it with a large spider, and screams: “A tarantula!”<br/>
                        <br/>
                        Moe and Curly try killing it with a gavel, but Curly hits Moe’s foot, instead.<br/>
                        <br/>
                        When a police officer arrives, Moe takes his gun and shoots five rounds at the “tarantula.” He screams: “I killed it!” But they later discover it’s not a tarantula, but a toupee. <br/>
                        <br/>
                        The bailiff takes his toupee from Moe and yells: “I’ll sue you for this!” <br/>
                        <br/>
                        Moe answers, “Oh, superstitious, ey?” <br/>
                        <br/>
                        The judge looks at Moe, Larry and Curly and says: “Gentlemen, you must control your killer instincts.” <br/>
                        <br/>

                        Questions:<br/>
                        Who screamed “tarantula”? <br/>
                        Did the toupee look like a spider?<br/>
                        Do you think Moe, Larry and Curly have “killer instincts”?<br/>
                        <br/>
                        </p>
                      </Paper>}

                      {lesson.data.number == 4 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story. Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate): <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/465399931"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        <br/>
                        In this episode, the defense attorney introduces Moe, Larry and Curly to the court. <br/>
                        <br/>
                        “If it please the court, your honor, I would like to introduce my three main witnesses…”<br/>
                        <br/>
                        But when he does so, he discovers the boys are “gone to lunch.” He asks permission to leave for a moment to look for them. “Will you pardon me, your honor? I’ll be right back.”<br/>
                        <br/>
                        The defense attorney finds the three pals playing Knucklebones, and asks: “Gentlemen, gentlemen! Don’t you realize Miss Gale Tempest’s life is at stake?”  The boys run back to the courtroom. <br/>
                        <br/>
                        Moe and Larry close a small gate behind them. The defense attorney jumps over it. Curly tries to do the same, but he falls down. Everyone laughs. The judge hits the gavel. “Will you gentlemen please try to be a little more quiet in the court?”<br/>
                        <br/>
                        Curly answers: “Certainly, judgy, the gate is locked!”<br/>
                        <br/>
                        Questions: <br/>
                        <br/>
                        According to the note on the bench, where did Moe, Larry and Curly go off to?<br/>
                        Who found the boys playing Knucklebones? <br/>
                        Why is Miss Tempest’s life at stake?<br/>
                        <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 5 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story. Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate): <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/465406112"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        <br/>
                        In this episode, Curly takes the stand. <br/>
                        <br/>
                        “Take the stand,” orders the judge. <br/>
                        <br/>
                        Curly takes the chair in his hand. “I got it, now what do I do with it?”<br/>
                        <br/>
                        The bailiff is fed up. He takes the chair and orders Curly to: “Sit down!”  <br/>
                        <br/>
                        When Curly sits down, he falls back. Moe and Larry help him up. Moe hits Curly on the head. “What’s the matter with you?”<br/>
                        <br/>
                        Curly answers: “I’m a victim of circumstance.”<br/>
                        <br/>
                        Moe slaps Curly in the face. “Be quiet,” and leaves. <br/>
                        <br/>
                        The judge says: “Proceed with the case…”<br/>
                        <br/>

                        Questions:<br/>
                        Why does Curly feel he is a victim of circumstance?<br/>
                        Who slapped Curly?<br/>
                        Who told Curly to sit down?<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 6 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story. Please use the Reverso Context plugin or app to highlight any word you don’t know.<br/> 
                        <br/>
                        Please click below to watch a short 1 minute video (approximate):<br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/465413519"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        <br/>
                        In this episode, Curly answers questions from the defense attorney regarding what happened on the night of the murder.<br/>
                        <br/>
                        “Proceed with the testimony,” orders the judge.<br/>
                        <br/>
                        The defense attorney approaches Curly. “Mr. Howard, kindly tell the court what you know about the murder of Kirk Robbin.” <br/>
                        <br/>
                        “Well, it was like this, Mr. Court…” starts Curly.<br/>
                        <br/>
                        “Address the judge as ‘your honor,’” interrupts the attorney.<br/>
                        <br/>
                        “Well, it was like this, my honor…”<br/>
                        <br/>
                        “’Your honor,’ not ‘my honor,’” interrupts once again the attorney.<br/>
                        <br/>
                        That confuses Curly. “Why? Don’t you like him?”<br/>
                        <br/>
                        “Allow the witness to proceed. The court understands him,” orders in the judge. <br/>
                        <br/>
                        “Thanks courtey, you’re a pal,” answers Curly. That lack of respect stuns the judge. Curly then proceeds to talk “street-talk” with idioms and vernacular that only street people would understand: <br/>
                        <br/>
                        “Well, me and my pals, we’re musicians. We were tarring up some hot swing music in the orchestra. Gale over there, was swinging her fans. Her sweetie, Kirk Robbin, was inhaling a bottle of hootch at the table. And a hoofer by the name of Buck Wing was getting ready to shake his tootsies.”  Nobody understands Curly. <br/>
                        <br/>
                        “Kindly speak English and drop the vernacular,” asks the defense attorney. <br/>
                        <br/>
                        Curly doesn’t know what vernacular means. He thinks it’s the brand name of his hat. “Vernacular? That’s a Derby!” Derby is the brand name of his hat. Then he drops it. <br/>
                        <br/>
                        “No, not that. Talk so the jury can understand,” whispers the attorney. <br/>
                        <br/>
                        “Is everybody dumb?” Asks Curly. “Say judgy, if you let me, my partners and Gale kind’a act it out for ya, and we’ll show ya just what happened.” <br/>
                        <br/>
                        “If counsel doesn’t object, that’s an excellent idea,” suggests the judge. <br/>
                        <br/>
                        To which both lawyers and Curly respond: “No objection!” Curly leaves the stand. <br/>
                        <br/>

                        Questions: <br/>
                        Was Curly being disrespectful, or was he trying his best?<br/>
                        What does vernacular mean?<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 7 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story. Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate):<br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/465421580"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        <br/>
                        In this episode, Moe and Curly try to prove the defense attorney’s assumption, that Gale Tempest couldn’t pull the trigger because it would take a lot of strength to do it. <br/>
                        <br/>
                        The prosecutor barks: “Why, this is preposterous, when the police broke into the office they found this woman, the defendant, Gale Tempest, bending over the body of a murdered man with a revolver clutched in here hand.” <br/>
                        <br/>
                        “Which only goes to prove that my client is innocent,” interrupts the defense attorney, “and I’ll prove it!” He approaches the jury box. “Ladies and gentlemen, the action of this pistol is so hard, it would take the strength of a mule to pull the trigger,” as he points to Curly. “Try it.” <br/>
                        <br/>
                        “I’m no mule,” Curly responds offended. <br/>
                        <br/>
                        “No, your ears are too short, come one,” Moe pokes Curly in the eyes. <br/>
                        <br/>
                        “So I’m a mule,” answers Curly.<br/>
                        <br/>
                        “Pull the trigger,” the defense attorney give the gun to Curly. “Never fear, it’s not loaded!”<br/>
                        <br/>
                        Curly struggles with all his might to pull the trigger. He finally does it. “Click!”<br/>
                        <br/>
                        “How can Gale Tempest’s frail little finger, pull the rusty trigger of that instrument of destruction?”<br/>
                        <br/>
                        Curly pulls the trigger again, and it was loaded! The bullet barely misses the defense attorney, as he leans into one of the jurors, an old woman. <br/>
                        <br/>
                        “Broad Lane. 9972. After 5 o’clock!” She gives the attorney her address and sets a time for a rendezvous.<br/>
                        <br/>

                        Questions:<br/>
                        Is Curly a mule?<br/>
                        Could Gale’s little finger pull the trigger?<br/>
                        What do you think will happen at 9972 Broad Lane after 5 o’clock? <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 8 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story. Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate): <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/465430633"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        <br/>
                        In this episode, Curly is told by the judge to throw his gum away. As he does, it lands on Moe’s nose. Larry tries to clean it, but everything goes wrong. <br/>
                        <br/>
                        On the stand, Curly enjoys his gum. <br/>
                        <br/>
                        “Stop chewing that gum!” Orders the judge. <br/>
                        <br/>
                        “Certainly.” Curly tangles his fingers with the gum. Then he makes a ball out of it and throws it away. But it lands on Moe’s nose. Everyone laughs. <br/>
                        <br/>
                        Larry wants to help. “Wait a minute. Hold still. I’ll get it.” He twists Moe’s nose.<br/>
                        <br/>
                        “Ouch, my nose!” Moe screams. Larry takes the gum off Moe’s nose with a napkin, throws it on the floor and stamps on it. He then begins shouting like a madman. <br/>
                        <br/>
                        “Hey, you’re in a court, not in the woods, Tarzan,” Moe slaps Larry, “cut it out! Come on.”<br/>
                        <br/>
                        The judge strikes his gavel. “Order in this court.” <br/>
                        <br/>

                        Questions: <br/>
                        Is it polite to chew gum while on the stand?<br/>
                        Who began to shout like a madman?<br/>
                        Why did Moe compare Larry to Tarzan?<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 9 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story. Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate): <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/465480383"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        <br/>
                        In this episode, the boys need to grab a parrot with an important note tied to its foot. <br/>
                        <br/>
                        The parrot cries: “Read the letter. Read the letter.” <br/>
                        <br/>
                        The judge asks: “What does this parrot mean by: ‘find the letter?’”<br/>
                        <br/>
                        Moe sees something. “Look, there’s a note tied to the parrot’s foot.”  He opens the cage, calling the parrot out: “Here, Poly, Poly, Poly…” but the parrot flies away. <br/>
                        <br/>
                        Curly follows the parrot, and hits the jury on their head with a gavel. The parrot lands on a lamp post. <br/>
                        <br/>
                        Larry asks. “How do we get him now?” <br/>
                        “You’ve got to wet its feathers so that it can’t fly,” responds Moe. Curly takes off. <br/>
                        <br/>
                        Moe tell Larry: “Come on. Give me a hand.” Larry gets on top of Moe.<br/>
                        <br/>
                        “Wait a minute. Hold it,” says Larry. Then they both fall down. <br/>
                        <br/>

                        Questions:<br/>
                        What’s the parrot’s name?<br/>
                        What does Curly use to hit the jurors on their heads with?<br/>
                        Who does Moe ask to give him a hand?<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 10 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story. Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate): <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/465484850"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        <br/>
                        In this episode, Curly uses a hose to bring down the parrot, but not without getting everyone wet. <br/>
                        <br/>
                        The prosecutor shouts: “I object your honor, these proceedings, this court room…”<br/>
                        <br/>
                        In the meantime, Curly gets the firehose and showers the police officer and the jurors. The police officer tries to turn off the hose, but he breaks the knob. <br/>
                        <br/>
                        Curly wets the prosecutor and bailiff. Then he takes aim at the parrot. <br/>
                        <br/>
                        The police officer shouts: “Shut that off!”<br/>
                        <br/>
                        “Wait a minute, I’’ll have to tie a knot…” cries Curly, as he ties a knot on the firehose. <br/>
                        <br/>
                        Moe finds the note and reads it: “I got it. Get this: ‘Who killed Kirk Robbin? I killed Kirk Robbin. And not with my little bow and arrow. Don’t try to find me. I’ve gone to Buffalo. Signed. Buck Wing.”<br/>
                        <br/>
                        The whole room erupts with “hurray’s.” <br/>
                        <br/>
                        The camera crew asks the team to pose for a picture. “Wait a minute, wait a minute,” says Curly. As he holds the firehose and poses for the picture. <br/>
                        <br/>
                        “Just a second now,” asks Moe, as he, Larry and Curly fluff their hair for the picture. <br/>
                        <br/>
                        “Now, ready…hold it!” Shouts the cameraman, when suddenly…<br/>
                        <br/>
                        Boom! The firehose explodes, and showers everyone. <br/>
                        <br/>
                        THE END<br/>
                        <br/>

                        Questions:<br/>
                        Where was the important note located?<br/>
                        How did the note clear Gale Tempest?<br/>
                        Who was the only person that didn’t get wet? (Hint, he’s also known as: Your Honor.<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 11 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story. Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate): <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/465494647"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        <br/>
                        In this new story, Shemp replaces Curly, but the laughs are the same. Shemp discovers he just inherited $500,000 but he needs to marry a woman before 6 pm that day. Let the laughter begin…<br/>
                        <br/>
                        Voice professor Shemp Howard teaches a woman how to sing from a recording of a professional singer:<br/>
                        <br/>
                        “Now you see, Miss Tinklemeyer, that’s the way it should be sung. Shall we try it again?”<br/>
                        <br/>
                        “I’ll try anything for you, professor…” flirts Miss Tinklemeyer. <br/>
                        <br/>
                        Larry plays the piano, as Miss Tinklemeyer sings off key, hurting the professor’s ear. <br/>
                        <br/>
                        “That’s enough, that’s enough. We’ve done enough for the day. You might hurt your voice, you know, bend it, crack it or break it or something.”<br/>
                        <br/>
                        “Oh professor, you are so considerate. I’m just CRAZY about you!”<br/>
                        <br/>
                        “Don’t forget, we’ve got another lesson on Tuesday,” says Shemp as he takes Miss Tinklemeyer to the door. <br/>
                        <br/>
                        “Gargle with old razor blades,” speaks Shemp, sarcastically. <br/>
                        <br/>
                        “All right professor, I know you wouldn’t want anything to happen to my throat,” she continues flirting with Professor Shemp. The door closes. Shemp answers her question to Larry:<br/>
                        <br/>
                        “Except to have somebody cut it,” quips Shemp sarcastically.<br/>
                        <br/>
                        “How would you like to be married to a dame like that?” asks Larry.<br/>
                        <br/>
                        Shemp shivers. “Don’t even say that.” <br/>
                        <br/>
                        Questions:<br/>
                        True or False: Is Miss Tinklemeyer crazy for Shemp?<br/>
                        Which two phrases does Shemp say sarcastically?<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 12 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story. Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate): <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/465514595"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        <br/>
                        In this episode, Moe tells Shemp that he just inherited $500k, but he needs to marry before 6 pm that afternoon to get it. <br/>
                        <br/>
                        Moe barges in. “Shut up and listen,” he tells Shemp. “Do you remember your uncle Caleb?”<br/>
                        <br/>
                        Shemp starts saying some bad things about his old uncle Caleb: “Do I? Why that old kite. He’d steal flies from a flying spider. He’s a loss and a weasel.”<br/><br/>

                        “Yeah? Well he just died and left you $500,000 (five hundred thousand) bucks. <br/><br/>

                        “Five hundred thousand bucks?” Shemp has a change of heart. He starts crying: “Poor old uncle Caleb. Like I was saying, he was a swell guy. Give you the shirt off his back, and throw in the buttons too…”<br/><br/>

                        “But there’s only one little catch,” interrupts Moe. “You get the dough provided that you’re married.” 
                        <br/><br/>
                        “Married? No, no fellas, I’m gonna faint.” Shemp falls back on a chair. 
                        <br/><br/>
                        “Or…” says Moe.
                        <br/><br/>
                        “Or what?” Asks Shemp. 
                        <br/><br/>
                        “If you get married within 48 hours of the reading of the will…”
                        <br/><br/>
                        Larry tells Shemp: “You’ve just got 7 hours to get yourself a bride!”
                        <br/><br/>
                        “It can’t be done. No woman has ever been interested in me,” answers Shemp.
                        <br/><br/>
                        “Maybe not, pal. But if you try real hard, you may find one that is interested in your half a million bucks!”
                        <br/><br/>
                        “Maybe you’ve got something there,” quips Shemp.
                        <br/><br/>
                        “Your darn right he has. You know any girls?” Asks Moe. 
                        <br/><br/>
                        “I’ve got a lot of numbers but I haven’t had much luck with them lately,” Shemp takes a little black notebook from his pocket. 
                        <br/><br/>
                        “Well you can’t get killed for trying. Where’s the phone?”
                        <br/><br/>
                        “There’s a phone booth down the hall…”
                        <br/><br/>
                        “Well what are we waiting for?” Shouts Moe. “Come on, down on the hall…” As Moe takes Shemp by the hair and they all leave through the door. 
                        <br/><br/>

                        Questions:<br/>
                        By what time must Shemp be married that afternoon?<br/>
                        What’s the name of Shemp’s uncle that left him the money?<br/>
                        Is Shemp a ladies’ man?<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 13 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story. Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate): 
                        <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/465579264"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button><br/>
                        In this episode, Shemp calls several women he knows to see if they’d like to get married, with no luck. 
                        <br/><br/>
                        The boys arrive at the phone booth. 
                        <br/><br/>
                        “Here’s six nickels,” Larry places the coins in Shemp’s hand. 
                        <br/><br/>
                        “That’s seventeen calls, you ought to hit the jackpot with one of them. Go ahead,” commands Moe. 
                        <br/><br/>
                        Shemp starts dialing. An hour later…
                        <br/><br/>
                        “Say, he’s been in there over an hour,” says Larry.
                        <br/><br/>
                        “What are getting nervous about? When Shemp pours on the charm, no dame can resist him.”
                        <br/><br/>
                        “I hope you’re right,” whispers Larry.
                        <br/><br/>
                        “Hello? Is this Ginger Gray? It is? Well this is your little snookem’s. I’m about to do you a big favor. Will you marry me?” The lady slams of the phone.  Shemp comes out of the phone booth. 
                        <br/><br/>
                        “Hey, any luck?” Moe asks. 
                        <br/><br/>
                        Shemp shakes his head. “I’ve got one phone number and one nickel left.” 
                        <br/><br/>
                        “Well, start using it,” answers Moe. 
                        <br/><br/>

                        Questions:<br/>
                        How many nickels did Larry put in Shemp’s hand?<br/>
                        How much are nickels? <br/>
                        What happens when Shemp “pours on the charm?” Lol!<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 14 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story. Please use the Reverso Context plugin or app to highlight any word you don’t know. 
                        <br/><br/>
                        Please click below to watch a short 1 minute video (approximate): 
                        <br/><button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/465579432"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button><br/>
                        In this episode, Larry finds a woman and asks her if she’d like to marry Shemp. That doesn’t work out well. 
                        <br/><br/>
                        As Larry waits outside nervously eating his fingernails, Moe and Shemp get all tangled up inside the phone booth. 
                        <br/><br/>
                        “How’s that?” Asks Moe all tangled up.
                        <br/><br/>
                        “It doesn’t feel as good as when I did this.” Shemp moves a wire. That angers Moe.
                        <br/><br/>
                        “We aren’t getting no place fast. Let’s get out of this thing the right way you little moron, get this off…”
                        <br/><br/>
                        Larry asks the gents how they’re doing in the booth, and they punch him in the face. When Larry stands up, a woman approaches him. 
                        <br/><br/>
                        “Say, miss. Would you like to get married?” Asks Larry. 
                        <br/><br/>
                        “What?” The woman responds, shocked. 
                        <br/><br/>
                        “Get married?”
                        <br/><br/>
                        “Well, I don’t know, but you are kind’a cute!” She flirts with Larry. 
                        <br/><br/>
                        “No, it’s not me, it’s him,” as he points to Shemp. Shemp’s face is all tangled up in wires as his nose is pressed against the glass. 
                        <br/><br/>
                        The woman yells, and slaps Larry across the face.  
                        <br/><br/>
                        Questions: <br/>
                        Did you laugh when you saw Shemp’s face pressed against the glass?<br/>
                        Why did the woman slap Larry’s face?<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 15 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story. Please use the Reverso Context plugin or app to highlight any word you don’t know. 
                        <br/><br/>
                        Please click below to watch a short 1 minute video (approximate): https://vimeo.com/465579747
                        <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/465579747"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button><br/>
                        In this episode, the boys find a cute woman entering her hotel room, and they get the idea to prepare Shemp to meet her and ask if she would marry him. 
                        <br/><br/>
                        The boys walk down the hall and they see a beautiful woman entering inside her hotel room. 
                        <br/><br/>
                        “Wait a minute!” Moe stops them dead on their tracks. 
                        <br/><br/>
                        The women sees them and enters her room. They whistle.
                        <br/><br/>
                        “Did you get a load of that?” Asks Moe. 
                        <br/><br/>
                        “What a dish!” Sighs Larry. 
                        <br/><br/>
                        “I wonder who she is?” Asks Shemp. 
                        <br/><br/>
                        Moe asks the bellboy. He tells them she’s Miss Hopkins.
                        <br/><br/>
                        Larry suggests: “Go get her, Shemp.” 
                        <br/><br/>
                        Moe pulls him back from his hair. “Wait a minute. You can’t propose to a girl looking like that? We got to give you the works. Come on!” Moe and Larry take Shemp down the hall. 
                        <br/><br/>
                        Shemp shaves himself, while Larry irons his clothes and Moe tailors his pants. “I’m telling you to hold still,” warns Moe. “I said, stand still!” Moe pokes Shemp with the needle. 
                        <br/><br/>
                        Shemp can’t see himself in the mirror, and thinks he cut off his head. Moe turns the mirror, and Shemp smiles: “Ah, there I am. And as pretty as a picture!” Moe slaps Shemp across the face. 
                        <br/><br/>
                        The boys exit their room. “You look good. You look great, kid. You’re a sinch. Go to it! Come one, come on!” 
                        <br/><br/>
                        Shemp knocks on the door. 
                        <br/><br/>
                        Questions: <br/>
                        What’s the name of the beautiful woman?<br/>
                        What does Larry mean by: “She’s a dish?”<br/>
                        Why is Shemp nervous to meet her?<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 16 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story. Please use the Reverso Context plugin or app to highlight any word you don’t know. 
                        <br/><br/>
                        Please click below to watch a short 1 minute video (approximate): https://vimeo.com/465580095
                        <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/465580095"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button><br/>
                        In this episode, Shemp is confused for someone else, and is hit by the woman. He is rescued by his singing student, who accepts getting married with Shemp. 
                        <br/><br/>
                        Shemp knocks on the door. Miss Hopkins lets him in, confusing Shemp with her cousin Basel.
                        <br/><br/>
                        “Come in, cousin Basel.” The woman starts kissing Shemp. “I’m so happy to see you! I’ve just been dying to meet you! You’re even cuter than that woman said you were.” He wants to say something, but she doesn’t let him. 
                        <br/><br/>
                        Moe and Larry are eavesdropping. “Oh, boy. Success,” says Larry, as they hear the kisses. “Listen to those kisses,” adds Moe. 
                        <br/><br/>
                        Inside the room, the phone rings suddenly. “Now don’t you go away,” smiles Miss Hopkins. “Hello? Yes, this is Miss Hopkins. You are cousin Basel? But then who is…”
                        <br/><br/>
                        “I  was going to…” Shemp stands up and gets slapped across the face.
                        <br/><br/>
                        “How dar you pretend to be my cousin Basel. I’ll teach you a thing or two. Don’t you dare strike me. Taking advantage of a poor woman…”
                        <br/><br/>
                        Outside, Larry and Moe hear what’s going on. “Boy, the kisses are getting louder,” says Larry.
                        <br/><br/>
                        “You, you, horrible person, you…” Miss Hopkins punches Shemp so hard, that he bursts through the door. 
                        <br/><br/>
                        Moe and Larry pick up Shemp. “What happened, kid?” Asks Moe.
                        <br/><br/>
                        “I can’t help it if I ain’t cousin Basel,” sighs Shemp. 
                        <br/><br/>
                        Then Shemp’s singing student, Miss Tinklemeyer, shows up. 
                        <br/><br/>
                        “Oh, professor, are you all right?” She asks. 
                        <br/><br/>
                        “Oh, I’m fine. All I wanted to do is propose…” 
                        <br/><br/>
                        “Propose? Oh, professor, this is so sudden!”
                        <br/><br/>
                        “You mean you’ll really marry him?” Asks Moe. 
                        <br/><br/>
                        “Oh, of course. He’s my little old dreamboat,” she flirts with Shemp’s hair.
                        <br/><br/>
                        “Your little dreamboat is sailing,” as Shemp starts sailing away. 
                        <br/><br/>
                        “Wait a minute. Are you going to sail away from a half a million bucks? We’ve only got two hours left. Come one, get going.”
                        <br/><br/>
                        They leave hurriedly. 
                        <br/><br/>
                        Questions: <br/>
                        Why couldn’t Shemp clear the confusion that he wasn’t cousin Basel?<br/>
                        How hard did Miss Hopkins punch Shemp?<br/>
                        Is Miss Tinklemeyer still crazy about her professor?<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 17 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story. Please use the Reverso Context plugin or app to highlight any word you don’t know. 
                        <br/><br/>
                        Please click below to watch a short 1 minute video (approximate): https://vimeo.com/465580475
                        <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/465580475"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button><br/>
                        In this episode, Shemp loses the wedding ring. 
                        <br/><br/>
                        The boys and Miss Tinklemeyer come in to J.M. Benton’s office. He’s the Justice of the Peace. 
                        <br/><br/>
                        “Come on right in!” Mr. Benton lets them in.
                        <br/><br/>
                        “Say, here’s the license. My pal wants to get married in a rush,” says Moe. 
                        <br/><br/>
                        “Oh, splendid,” cries Mr. Benton. The phone rings, and Mr. Benton passes the phone to Moe. 
                        <br/><br/>
                        “Hello. Yes. What? How many of them? Wow! Yeah, yeah. Ok. Thanks.” He turns to Shemp. “That was the clerk at the apartment. Those dames you phoned this morning found out about the $500k in the papers.” Moe now turns to Mr. Benton. “Come on, pal. We’ve got to make this fast!” 
                        <br/><br/>
                        “Have you got the ring?” Asks Mr. Benton.  Shemp fumbles around with the ring and he accidently throws it inside the piano. 
                        <br/><br/>
                        “Do you see anything?” Moe asks. 
                        <br/><br/>
                        “Yeah, wires. I think the piano is out of tune,” responds Shemp. Moe slams the piano cover on Shemp’s head. 
                        <br/><br/>
                        “Quit playing around in there and get that ring,” commands Moe. But Shemp is all tangled up with the piano chords. 
                        <br/><br/>
                        “Let’s back him up and let’s bring him out,” chimes Larry. The chords stretch, and snap Shemp back to the piano. While there, he finds the ring. “Wait a minute, fellas. Here’s the ring!”
                        <br/><br/>
                        They take Shemp to Mr. Benton.  “Ok, father, we’re ready for the kickoff,” says Moe. 
                        <br/><br/>
                        “Join hands, you lovebirds,” asks Mr. Benton. 
                        <br/><br/>
                        Questions: <br/>
                        Who are the lovebirds?<br/>
                        What happened to the ladies Shemp called earlier that day?<br/>
                        Do you smell trouble?  <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 18 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story. Please use the Reverso Context plugin or app to highlight any word you don’t know. 
                        <br/><br/>
                        Please click below to watch a short 1 minute video (approximate): 
                        <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/465580872"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button><br/>
                        In this episode, the ladies Shemp first proposed to earlier that day discover the truth about the half a million dollars, and disrupt the marriage ceremony. 
                        <br/><br/>
                        The ladies Shemp first proposed to over the phone barge in Mr. Benton’s office and disrupt the ceremony. 
                        <br/><br/>
                        “Wait a minute, who is this character?” A woman points to Miss Tinklemeyer. 
                        <br/><br/>
                        “She is the bride,” answers Mr. Benton. 
                        <br/><br/>
                        “Yeah?” Interrupts another woman. “Not while I’ve got my strength. Tell the truth. Didn’t you propose to me?” She asks Shemp. 
                        <br/><br/>
                        “Yes, but I…” 
                        <br/><br/>
                        “Didn’t ya?”
                        <br/><br/>
                        “Yes, but you turned me down.” 
                        <br/><br/>
                        “That was before I read that paper. Now I accept.” She turns to Mr. Benton. “Ok, toots, let her rip.” 
                        <br/><br/>
                        Now all the women start fighting over Shemp.
                        <br/><br/>
                        “Look at that, we better do something fast,” suggests Larry. Moe jumps in the middle of everything: 
                        <br/><br/>
                        “Wait a minute, wait a minute. What’s going on here?” All the women hit Moe with the newspaper. They do the same to Larry. One lady hits Mr. Benton with a bird cage. As they keep fighting, a woman hits Shemp with a vase. 
                        <br/><br/>
                        Miss Tinklemeyer picks Mr. Benton and Shemp up. “Oh Mr. Benton, please, please. Hurry.” 
                        <br/><br/>
                        “Hold hands, you lovebirds,” replies Mr. Benton, half dizzy. 
                        <br/><br/>
                        The ladies leave the room as Mr. Benton marries Shemp and Miss Tinklemeyer. 
                        <br/><br/>
                        “We’re married,” sighs the new Mrs. Howard (Shemp’s last name). 
                        <br/><br/>
                        “We made it! It’s 6 o’clock and you’re married!”
                        <br/><br/>
                        Shemp is dizzy. “What happened? What happened?”
                        <br/><br/>
                        “You’re married, kid,” shouts Moe. “Say something!”
                        <br/><br/>
                        Shemp turns to his new bride, shivers, and shouts: “HELP!” and runs away…
                        <br/><br/>
                        THE END.<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 19 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        In honor of the 35th anniversary, let’s take a listen to a song that united the world as one.
                        <br/><br/>
                        Please click below to watch the song. Use the Reverso Context plugin or app to translate the words. Learn the first stanza and chorus. 
                        <br/><button onClick={() => {
                            this.setState({audioStoryLink: "https://youtu.be/9AjkUyX0rVw"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button><br/>
                        We Are The World<br/>
                        By Michael Jackson and Lionel Richie<br/>
                        <br/>
                        1. There comes a time <br/>
                        When we heed a certain call<br/>
                        When the world must come together as one<br/>
                        There are people dying<br/>
                        Oh, and it's time to lend a hand to life<br/>
                        The greatest gift of all<br/>
                        <br/>
                        Chorus: <br/>
                        We are the world<br/>
                        We are the children<br/>
                        We are the ones who make a brighter day, so let's start giving<br/>
                        There's a choice we're making<br/>
                        We're saving our own lives<br/>
                        It's true we'll make a better day, just you and me<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 20 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        In honor of the 35th anniversary, let’s take a listen to a song that united the world as one.
                        <br/><br/>
                        Please click: below to watch the song. Use the Reverso Context plugin or app to translate the words. Learn the second stanza and chorus.
                        <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://youtu.be/9AjkUyX0rVw"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button><br/>
                        We Are The World<br/>
                        By Michael Jackson and Lionel Richie
                        <br/><br/>
                        2. We can't go on<br/>
                        Pretending day-by-day<br/>
                        That someone, somewhere soon make a change<br/>
                        We're all a part of God's great big family<br/>
                        And the truth, you know, love is all we need
                        <br/><br/>
                        Chorus: <br/>
                        We are the world<br/>
                        We are the children<br/>
                        We are the ones who make a brighter day, so let's start giving<br/>
                        There's a choice we're making<br/>
                        We're saving our own lives<br/>
                        It's true we'll make a better day, just you and me<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 21 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        In honor of the 35th anniversary, let’s take a listen to a song that united the world as one.
                        <br/><br/>
                        Please click below to watch the song. Use the Reverso Context plugin or app to translate the words.  Learn the third stanza and chorus.
                        <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://youtu.be/9AjkUyX0rVw"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button><br/>
                        We Are The World<br/>
                        By Michael Jackson and Lionel Richie<br/>
                        <br/>
                        3. Oh, send them your heart<br/>
                        So they know that someone cares<br/>
                        And their lives will be stronger and free<br/>
                        As God has shown us by turning stones to bread<br/>
                        And so we all must lend a helping hand<br/>
                        <br/>
                        Chorus: <br/>
                        We are the world<br/>
                        We are the children<br/>
                        We are the ones who make a brighter day, so let's start giving<br/>
                        There's a choice we're making<br/>
                        We're saving our own lives<br/>
                        It's true we'll make a better day, just you and me<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 22 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        In honor of the 35th anniversary, let’s take a listen to a song that united the world as one.
                        <br/><br/>
                        Please click below to watch the song. Use the Reverso Context plugin or app to translate the words. Learn the bridge and chorus.
                        <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://youtu.be/9AjkUyX0rVw"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button><br/>
                        We Are The World<br/>
                        By Michael Jackson and Lionel Richie<br/>
                        <br/><br/>

                        Bridge:<br/>
                        When you're down and out, there seems no hope at all<br/>
                        But if you just believe there's no way we can fall<br/>
                        Well, well, well, well let us realize<br/>
                        Oh, that a change can only come<br/>
                        When we stand together as one, yeah, yeah, yeah<br/>
                        <br/>
                        Chorus: <br/>
                        We are the world<br/>
                        We are the children<br/>
                        We are the ones who make a brighter day, so let's start giving<br/>
                        There's a choice we're making<br/>
                        We're saving our own lives<br/>
                        It's true we'll make a better day, just you and me<br/>
                        <br/>

                        A more modern version of We Are The World was sung 10 years ago by more contemporary artists for help to those in Haiti after the devastating earthquake that killed more than 200,000 people. View it here:<br/>

                        https://youtu.be/Glny4jSciVI
                        </p>
                      </Paper>}








                      <button style={{fontSize: 'large', border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.user.learningProgress.level == 1 && this.state.user.learningProgress.lesson == index + 1 && this.state.user.learningProgress.step == 2){
                          this.updateProgress(index + 1, 3)
                        }
                        this.setState({dialogAudio: false, dialogExercise: true})
                      }} >Continue</button>
                      <div>

                      </div>
                      </div>
                    </Dialog>










                    <Dialog
                      open={this.state.dialogExercise}
                      onClose={() => this.setState({dialogExercise: false})}
                      fullScreen
                    >
                      <div style={{width: '100%', height: '100%', backgroundColor: '#505050'}}>
                      <img src={logo} style={{width: 200, marginTop: 20, marginLeft: 20}} />
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 20, right: 20, color: '#edd490'}} onClick={() => this.setState({dialogExercise: false})} />
                      {lesson.data.title == 'Refresher Course & Grammar' ? <h1 style={{textAlign: 'center',fontSize: 'x-large', color: '#edd490'}}>Step 2. EXCERCISES:</h1> :<h1 style={{textAlign: 'center',fontSize: 'x-large', color: '#edd490'}}>Step 3. EXCERCISES:</h1> }
                      <p style={{width: '100%', textAlign: 'center', color: 'white'}}>Don’t forget to use Reverso Context if you don’t understand a word or question.</p>
                      <div style={{width: '90%', margin: '30px auto', textAlign: 'center', padding: 15, border: '2px solid #edd490', borderRadius: 20, backgroundColor: '#bebebe', boxShadow: '0px 2px 7px rgba(0,0,0,0.5)'}}>
                        {this.state.Index >= lesson.questions.length ? <div style={{textAlign: 'center', width: '90%', margin: 'auto'}}>
                                <H3>Your Score:</H3>
                                <H3 style={{fontSize: 'x-large', fontWeight: 'bold'}}>{lesson.questions.length - this.state.wrongQuestions.length}/{lesson.questions.length}</H3>
                                {((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) == 1 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>Awesome!</H3> : ((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) >= 0.9 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>Great!</H3> : ((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) >= 0.8 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>Good Job!</H3> : ((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) >= 0.7 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>Getting Close!</H3> : ((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) >= 0.6 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>We've got work to do</H3> : <H3>That's why we're here. Let's do it again!</H3>}
                                {((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) < 0.5 && <Button onClick={() => {
                                  this.setState({word1: '', word2: '', word3: '', word4: '',word5: '', word6: '', word7: '', result: null, Index: 0, wrongQuestions: []})
                                }} variant="contained" color="primary">
                                  Repeat Exercise
                                </Button>}
                              </div> : ''
                            
                            
                              
                          

                        }
                        
                        {lesson.questions.map((question,qIndex) => {
                          
                          if (qIndex == this.state.Index){
                           
                            if (question.type == 'ftb'){
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>Fill in the blank</h2>
                                  <div style={{display: 'flex', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, flexWrap: 'wrap', margin: '10px auto', width: '90%', justifyContent: 'center'}}>
                                  {question.question && question.question.split(' ').map((word, index) => {
                                    if (word == '__1__'){
                                      
                                      return (
                                        <TextField style={{width: 'max-content', minWidth: 100, marginTop: 5}} value={this.state.word1} onChange={( e) => {this.setState({word1: e.target.value}) }} />
                                      )
                                    }else {
                                      return (
                                        <H3 >{word}</H3>
                                        
                                      )

                                    }
                                    
                                  })

                                  }

                                  </div>
                                  {this.state.result == null && <Button variant="contained" style={{backgroundColor: '#ebebeb'}} onClick={() => {
                                    if(this.state.word1.split(" ")[0].toLowerCase() == question.ans.toLowerCase()){
                                      this.setState({result: true})
                                    } else {
                                      console.log(this.state.word1.split(" ")[0].toLowerCase())
                                      this.setState({result: false})
                                    }
                                  }}>Check</Button>}
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}
                                  <div style={{display: 'flex'}}>

                                    {this.state.result == false && <div style={{display: 'flex'}}>
                                      <H3 >Correct Answer: <span style={{color: '#000080'}}>{question.ans}</span></H3>
                                    </div>

                                    }
                                    
                                    {this.state.result != null && <div style={{width: '100%', textAlign: 'right', margin: 'auto'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                                  </div>
                                </div>

                              )
                            } else if (question.type == 'mcq'){
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>Multiple choice</h2>
                                  <div style={{display: 'flex', flexWrap: 'wrap', width: '90%', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, margin: '10px auto', justifyContent: 'center'}}>
                                  {question.question && question.question.split(' ').map((word, index) => {
                                    if (word == '____'){
                                      
                                      return (
                                        <H3 style={{backgroundColor: this.state.word1 == ''? '#bebebe' : this.state.word1 == question.ans ? '#008000' : '#c91b1b', color: 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0}}>{this.state.word1}</H3>
                                      )
                                    }else {
                                      return (
                                        <H3>{word}</H3>
                                        
                                      )

                                    }
                                    
                                  })

                                  }

                                  </div>
                                  <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                                  {question.options.map(option => {
                                    return (
                                      <div style={{margin: 10, cursor: 'pointer', padding: 2, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={() => {
                                        this.setState({word1: option})
                                        if (option == question.ans){
                                          this.setState({result: true})
                                        } else {
                                          this.setState({result: false})

                                        }
                                      }}>
                                        <H3>{option}</H3>

                                      </div>
                                    )
                                  })

                                  }

                                  </div>
                                  
                                  
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}

                                  <div style={{display: 'flex'}}>
                                  {this.state.result == false && <div style={{display: 'flex'}}>
                                      <H3 >Correct Answer: <span style={{color: '#000080'}}>{question.ans}</span></H3>
                                    </div>

                                    }
                               
                                  {this.state.result != null && <div style={{width: '100%', textAlign: 'right'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                              </div>
                                </div>

                              )
                            } else if (question.type == 'co'){
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>Correct order</h2>
                                  
                                  <div style={{display: 'flex', flexWrap: 'wrap', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, width: '90%',margin: '10px auto', justifyContent: 'center'}}>
                                  {question.question && question.question.split(' ').map((word, index) => {
                                    if (word == '__1__'){
                                      
                                      return (
                                        <H3 style={{backgroundColor: this.state.word1 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word1 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word1}</H3>

                                      )
                                    }else if (word == '__2__'){
                                      return (
                                        <H3 style={{backgroundColor: this.state.word2 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word2 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word2}</H3>

                                      )

                                    }else if (word == '__3__'){
                                      return (
                                        <H3 style={{backgroundColor: this.state.word3 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word3 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word3}</H3>

                                      )

                                    }else if (word == '__4__'){
                                      return (
                                        <H3 style={{backgroundColor: this.state.word4 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word4 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word4}</H3>

                                      )

                                    }else {
                                      return (
                                        <H3>{word}</H3>
                                        
                                      )

                                    }
                                    
                                  })

                                  }

                                  </div>
                                  <div style={{display: 'flex', flexWrap: 'wrap', width: '90%', margin: 'auto', justifyContent: 'center'}}>
                                  {question.options.map(option => {
                                    return (
                                      <div style={{margin: 10, cursor: 'pointer', padding: 2, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={() => {
                                       
                                        if (this.state.word1 == ''){
                                          this.setState({word1: option})
                                        }else if (this.state.word2 == ''){
                                          this.setState({word2: option})

                                          if (question.ans.split(' ').length == 2 && this.state.word1 != ''){
                                          

                                            if (question.ans == this.state.word1 + ' ' + option){
                                              this.setState({result: true})
                                            }else {
                                              this.setState({result: false})
                                            }
                                          }

                                        }else if (this.state.word3 == '' && this.state.word2 != ''){
                                          this.setState({word3: option})
                                          console.log(question.ans)
                                          console.log(question.ans.split(' ').length)
                                          if (question.ans.split(' ').length == 3){
                                          
                                            if (question.ans == this.state.word1 + ' ' + this.state.word2 + ' ' + option){
                                              this.setState({result: true})
                                            } else {
                                              this.setState({result: false})
                                            }
                                          }
                                        }else if (this.state.word4 == '' && this.state.word3 != ''){
                                          this.setState({word4: option})
                                          if (question.ans.split(' ').length == 4){
                                            if (question.ans == this.state.word1 + ' ' + this.state.word2 + ' ' + this.state.word3 + ' ' + option){
                                              this.setState({result: true})
                                            }else {
                                              this.setState({result: false})
                                            }
                                          }
                                        }
                                        
                                      }}>
                                        <H3>{option}</H3>

                                      </div>
                                    )
                                  })

                                  }

                                  </div>
                                  
                                  
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}

                                  <div style={{display: 'flex'}}>
                                  {this.state.result == false && <div>
                                      <H3 style={{width: 200}}>Correct Answer:</H3>
                                      <div style={{display: 'flex'}}>
                                      {question.ans.split(' ').map(word => {
                                        return (
                                          <h4 style={{ minWidth: 50, margin: 10, padding: 5, backgroundColor: '#ebebeb', borderRadius: 5}}>{word}</h4>
                                        )
                                      })

                                      }

                                      </div>
                                      
                                    </div>

                                    }
                               
                                  {this.state.result != null && <div style={{width: '100%', textAlign: 'right'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                              </div>
                                </div>

                              )
                            }else if (question.type == 'tf'){
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>True or False</h2>
                                  
                                  <div style={{display: 'flex', flexWrap: 'wrap', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, width: '90%',margin: '10px auto', justifyContent: 'center'}}>
                                  {question.question && question.question.split(' ').map((word, index) => {
                                    if (word == '____'){
                                      
                                      return (
                                        <TextField variant="outlined" value={this.state.word1} onChange={( e) => {this.setState({word1: e.target.value}) }} />
                                      )
                                    }else {
                                      return (
                                        <H3>{word}</H3>
                                        
                                      )

                                    }
                                    
                                  })

                                  }

                                  </div>
                                  <div style={{display: 'flex', width: '90%',margin: 'auto', justifyContent: 'center'}}>
                                  <H3 style={{margin: 10, cursor: 'pointer', padding: 8, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={()=> {
                                    if (question.ans == true){
                                      this.setState({result: true})
                                    } else {
                                      this.setState({result: false})

                                    }
                                  }}>True</H3>
                                  <H3 style={{margin: 10, cursor: 'pointer', padding: 8, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={()=> {
                                    if (question.ans == false){
                                      this.setState({result: true})
                                    } else {
                                      this.setState({result: false})

                                    }
                                  }}>False</H3>

                                  </div>
                                  
                                  
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}

                                  <div style={{display: 'flex'}}>

                                  {this.state.result == false && <div style={{display: 'flex'}}>
                                      <H3 >Correct Answer: <span style={{color: '#000080'}}>{question.ans == true ? 'True' : 'False'}</span></H3>
                                    </div>

                                    }
                               
                                  {this.state.result != null && <div style={{width: '100%', textAlign: 'right'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                              </div>
                                </div>

                              )
                            }else if (question.type == 'match'){
                              
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>Match the following</h2>
                                  
                                  <div style={{display: 'flex', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, flexWrap: 'wrap', width: '90%',margin: '10px auto', justifyContent: 'center'}}>
                                  {question.question.map((word, index) => {
                                    return (
                                      <div>
                                        <H3 style={{backgroundColor: '#bebebe', borderRadius: '5px 5px 0px 0px', padding: 10, marginBottom: 1}}>{word}</H3>
                                        <H3 style={{backgroundColor: '#cdeefd', borderRadius: '0px 0px 5px 5px', padding: 10, marginTop: 1}}>{index == 0? this.state.word1 : index == 1? this.state.word2 : index == 2 ? this.state.word3 : index == 3 ? this.state.word4 : index == 4 ? this.state.word5 : index == 5 ? this.state.word6 : index == 6 ? this.state.word7 : ''}</H3>

                                      </div>

                                    )
                                    
                                    
                                    
                                  })

                                  }

                                  </div>
                                  <div style={{display: 'flex', flexWrap: 'wrap', width: '90%', margin: '10px auto', justifyContent: 'center'}}>
                                  {question.shuffled.map(option => {

                                    return (
                                      
                                        <H3 style={{margin: 10, cursor: 'pointer', padding: 8, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={()=> {
                                          if (this.state.word1 == ''){
                                            this.setState({word1: option})
                                          } else if (this.state.word2 == ''){
                                            this.setState({word2: option})
                                            if (question.ans.length == 2){
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word3 == ''){
                                            this.setState({word3: option})
                                            if (question.ans.length == 3){
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word4 == ''){
                                            this.setState({word4: option})
                                            if (question.ans.length == 4){
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, this.state.word3, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word5 == ''){
                                            this.setState({word5: option})
                                            if (question.ans.length == 5){
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word6 == ''){
                                            this.setState({word6: option})
                                            if (question.ans.length == 6){
                                              console.log(question.ans)
                                              console.log(JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, this.state.word5, option]))
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, this.state.word5, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word7 == ''){
                                            this.setState({word7: option})
                                            if (question.ans.length == 7){
                                              console.log(question.ans)
                                              console.log(JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, this.state.word5, this.state.word6, option]))
                                              
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, this.state.word5, this.state.word6, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }
                                        }}>{option}</H3>

                                     
                                    )
                                  })

                                  }

                                  </div>
                                  
                                  
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}

                                  <div style={{display: 'flex'}}>
                                  {this.state.result == false && <div>
                                      <H3 style={{width: 200}}>Correct Answer:</H3>
                                      <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                      {question.ans.map(word => {
                                        return (
                                          <h4 style={{ minWidth: 50, maxWidth: 'max-content', margin: 10, padding: 5, backgroundColor: '#ebebeb', borderRadius: 5}}>{word}</h4>
                                        )
                                      })

                                      }

                                      </div>
                                      
                                    </div>

                                    }
                               
                                  {this.state.result != null && <div style={{width: '100%', textAlign: 'right'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                              </div>
                                </div>

                              )
                            } 
                          
                            
                          }
                        })

                        }


                      </div>


                      {this.state.Index >= lesson.questions.length && <button style={{border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.Index >= lesson.questions.length){
                          console.log(this.state.user.learningProgress.lesson)
                          console.log(index + 1)
                          if (this.state.user.learningProgress.level == 1 && this.state.user.learningProgress.lesson == index + 1 && this.state.user.learningProgress.step == 3){
                            if (lesson.data.title == 'Refresher Course & Grammar'){
                             
                              this.updateProgress(index + 2, 1)
                            }else {
                              this.updateProgress(index + 1, 4)
                            }
                            
                          }

                          if (lesson.data.title == 'Refresher Course & Grammar'){
                            this.setState({dialogExercise: false})
                          }else {
                            this.setState({dialogExercise: false, dialogResponse: true})
                          }
                          
                          this.setState({word1: '', word2: '', word3: '', word4: '',word5: '', word6: '', word7: '', result: null, Index: 0, wrongQuestions: []})
                        }


                        
                      }} >Continue</button>}
                      <div>

                      </div>
                      </div>
                    </Dialog>















                    <Dialog
                    open={this.state.dialogResponse}
                    onClose={() => this.setState({dialogResponse: false})}
                    fullScreen
                    >
                      <div style={{width: '100%', height: '100%', backgroundColor: '#505050'}} >

                      <img src={logo} style={{width: 200, marginTop: 20, marginLeft: 20}} />
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 20, right: 20, color: '#edd490'}} onClick={() => this.setState({dialogResponse: false,recording: false,
                        audio: '',
                        isRecording: false,
                        blobURL: '',
                        recorded: false,
                        response: '',
                        responseSent: false})} />
                      <h1 style={{textAlign: 'center',fontSize: 'x-large', color: '#edd490'}}>Step 4. Track Your Progress</h1>

                      <Paper style={{width: '80%', margin: '30px auto',padding: 20, textAlign: 'center'}}>
                        <H3 style={{margin: 20}}>Please speak into the microphone the following sentence: </H3>
                        <H3><i>{lesson.data.sentence}</i></H3>
                        {/* <TextField multiline variant="outlined" style={{width: '80%', margin: 'auto'}} placeholder="type here..."  onChange={(e) => this.setState({response: e.target.value})}/> */}
                      {/* <div style={{width: '100%', textAlign: 'center', margin: 'auto'}}>
                      {this.state.response != '' && <Button variant="contained" color="primary" onClick={() => this.sendResponse(index + 1)}>Send Text</Button>}
                      </div> */}
                      

                        {/* <h3 style={{margin: '20px auto'}}>OR</h3> */}
                    {!this.state.recorded && !this.state.responseSent && !this.state.progress && <Button variant="contained" style={{backgroundColor: this.state.isRecording ? 'red' : 'green', color: 'white'}} onClick={() => {
                      if (this.state.isRecording){
                        this.stop()
                      } else {
                        this.start()
                      }
                    }}>{this.state.isRecording ? 'Stop Recording' : 'Click here to start recording'}</Button>}
                    {this.state.recorded && <div style={{width: '100%'}}>
                      <audio src={this.state.blobURL} style={{padding: 10,  }} controls="controls" /> 
                      </div>}
                      {this.state.progress && <div style={{width: '100%'}}>
                      <CircularProgress color="inherit" />
                      </div>}
                      

                      
                      {this.state.isRecording && <h3>Recording...</h3>}
                      {this.state.recorded && !this.state.responseSent && <Button variant="contained" color="primary" onClick={() => this.send(lesson.data.number)}>Send Audio</Button>}
                      
                      {this.state.responseSent && <p>Audio has been sent</p>

                      }


                      </Paper>
                      {this.state.user.adminResponses && this.state.user.adminResponses.map(response => {
                        if (response.level == 1 && response.lesson == lesson.data.number){
                          return (
                            <Paper style={{width: '80%', margin: '10px auto',padding: 20, textAlign: 'center'}}>
                              <H3>You have a response from Harold Zapata!</H3>
                              {response.type == 'audio' ? <audio src={response.audio} style={{padding: 10,  }} controls="controls" download='false' /> : <p>{response.message}</p>}


                            </Paper>
                          )
                        }
                      })

                      }


                      <button style={{border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.user.learningProgress.level == 1 && this.state.user.learningProgress.lesson == index + 1 && this.state.user.learningProgress.step == 4){
                          this.updateProgress(index + 1, 5)
                        }
                        this.setState({dialogResponse: false, dialogDownload: true,recording: false,
                          audio: '',
                          isRecording: false,
                          blobURL: '',
                          recorded: false,
                          response: '',
                          responseSent: false})
                      }} >Continue</button>
                      <div>

                      </div>
                      </div>
                    </Dialog>











                    <Dialog
                    open={this.state.dialogDownload}
                    onClose={() => this.setState({dialogDownload: false})}
                    fullScreen
                    >
                      <div style={{width: '100%', height: '100%', backgroundColor: '#505050'}}>

                      
                      <img src={logo} style={{width: 200, marginTop: 20, marginLeft: 20}} />
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 20, right: 20, color: '#edd490'}} onClick={() => this.setState({dialogDownload: false})} />
                      <h1 style={{textAlign: 'center',fontSize: 'x-large', color: '#edd490'}}>Step 5. DOWNLOAD PRINTABLE PDF AND AUDIO MP3 FILES.</h1>

                      <Paper style={{width: '80%', margin: 'auto', textAlign: 'center', padding: 10}}>
                        <audio src={lesson.data.downloadable} controls />
                        <div style={{width: '100%', textAlign: 'center'}}>
                        <p style={{ padding: 5, borderRadius: 5,margin: '5px auto', color: 'green'}}>Click the 3 dots to download</p>
                        </div>

                        <a href={lesson.data.pdf} download target="_blank"><Button variant="contained" style={{backgroundColor: 'green', color: 'white'}}><GetAppIcon />Download PDF</Button></a> 
                        


                      </Paper>


                      <button style={{border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.user.learningProgress.level == 1 && this.state.user.learningProgress.lesson == index + 1 && this.state.user.learningProgress.step == 5){
                          this.updateProgress(index + 2, 1)
                        }
                        this.setState({dialogDownload: false})
                      }} >Continue</button>
                      <div>

                      </div>
                      </div>
                    </Dialog>

                  </div>}

                </div>
                

              </div>

            )
          } )}

          <Dialog 
          open={this.state.dialogCert}
          onClose={() => this.setState({dialogCert: false})}
          fullScreen>
            <div style={{height: '100%', width: '100vw' , backgroundColor: '#707070', textAlign: 'center' }}>
                <div style={{textAlign: 'center', maxWidth: 800, margin: 'auto', maxHeight: '100%', position: 'relative'}}>
                  <img src={Cert} style={{textAlign: 'center', width: '100%', height: '100%'}}  />
                  <p style={{position: 'absolute', top: '36%', color: '#505050', fontSize: window.innerWidth >= 500 ? 'xx-large' : 'large' , width: '100%', textAlign: 'center'}}>{localStorage.getItem('firstName')} {localStorage.getItem('lastName')}</p>

                </div>

                <button style={{border: '2px solid #edd490',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#404040', color: '#edd490', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                      this.setState({dialogCert: false})
                      }} >Continue</button>

            </div>

            
            

          </Dialog>


          
        </div>
      )
























  //     return (
  //       <div>
         
  //         <Paper style={{ margin: 20, padding: 20 }}>
  //           <Steps direction="horizontal" size="small" current={0}>
  //             {this.state.courses.map((course, index) => {
  //               return <Step title={"course" + ++index} />;
  //             })}
  //           </Steps>
  
  //           {this.state.courses.length > 0 &&
  //             this.state.courses[0].map((lesson, index) => {
  //               return (
  //                 <Paper style={{ margin: 20, padding: 20 }}>
  //                   <h2 style={{backgroundColor: '#505050', padding: 20, width: 500, color: '#e9c979', fontWeight: 'bold', borderRadius: 5, margin: '10px auto'}} >Lesson {index + 1}</h2>
  //                   <h3 className="montserrat">Lesson Video</h3>
  //                   <ReactPlayer
  //                   onContextMenu ={(e) => e.preventDefault()}
  //                     style={{ margin: "20px auto 50px auto" }}
  //                     controls={true}
  //                     playing={false}
  //                     config={{ file: { 
  //                       attributes: {
  //                         controlsList: 'nodownload'
  //                       }
  //                     }}}
  //                     url={lesson.mainVideo}
  //                   />
  
  
  //                   <h3 className="montserrat">Teaching Video</h3>
  //                   <ReactPlayer
  //                     onContextMenu ={(e) => e.preventDefault()}
  //                     style={{ margin: "20px auto 50px auto" }}
  //                     controls={true}
  //                     playing={false}
  //                     config={{ file: { 
  //                       attributes: {
  //                         controlsList: 'nodownload'
  //                       }
  //                     }}}
  //                     url={lesson.teachingVideo}
  //                   />
  
  
  //                     <h3 className="montserrat">Audio Story</h3>
  //                     {lesson.audioStory && <ReactAudioPlayer
  //                       style={{margin: '10px auto'}}
  //                         src={ lesson.audioStory }
                          
  //                         controls
  //                       />}
  
  
  
  //                   <h3 className="montserrat">Text Story</h3>
  //                   <Input.TextArea
  //                       style={{height: 120}}
  //                       value={lesson.textStory}
                  
  //                       />
  //                   <Button variant="contained" color="primary" style={{margin: 10}} onClick={()=>{
  //                     console.log(lesson.exercise)
  //                     this.setState({
  //                     exercise: lesson.exercise,
  //                     dialog: true,
  //                     index: 0
  //                     })}} >Exercises</Button>
  //                 </Paper>
  //               );
  //             })}
  //         </Paper>
  //         <Dialog
          
  //         fullScreen
  //           open={this.state.dialog}>
  //             <div style={{backgroundColor: '#e6f3f8', height: '100%', width: '100%'}}>
  
              
  //             <h2 style={{textAlign: 'center'}} >Exercise</h2>
              
  //             <div style={{margin: 30, textAlign: 'center'}}>
  //               {this.state.exercise.map((question, index) => {
        
  //                 if (index == this.state.index + 1){
  //                   if (question.type == "fill the gap text"){
  //                     let word1 = '';
  //                     let word2 = '';
  //                     let word3 = '';
  //                     let ansWord1 = '';
  //                     let ansWord2 = '';
  //                     let ansWord3 = '';
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
  //                         <div style={{display: 'flex', justifyContent: 'center', margin: 20}}>
  //                           {question.question.map(word => {
  //                             if (question.options.filter(Word => {return Word == word}).length >= 1){
  //                               word1 = word;
  //                               return (
  //                                 <TextField margin="dense" disabled style={{marginTop: 2, cursor: 'pointer', width: 'max-content'}} variant="outlined" value={this.state.word1}  onClick={()=> this.setState({word1: ''})}/>
  //                               )
  //                             }else {
  //                               return (
  //                                 <H3>{word}</H3>
  //                               )
  //                             }
                              
  //                           })
    
  //                           }
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
  //                         {question.options.map(word => {
  //                           return (
  //                             <H3 style={{cursor: 'pointer'}} onClick={()=> {
  //                               if (this.state.word1 == ''){
    
  //                                 this.setState({word1: word})
  //                                 ansWord1 = word;
  //                                 if (ansWord1 == word1){
  //                                   console.log("correct")
  //                                   this.setState({open: true})
  //                                 } else  {
  //                                   this.setState({open2: true})
  //                                 }
  //                               }
  //                             }} >{word}</H3>
  //                           )
  //                         })
    
  //                         }
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   } else if (question.type == "fill the blank audio"){
  //                     let word1 = '';
  //                     let word2 = '';
  //                     let word3 = '';
  //                     let ansWord1 = '';
  //                     let ansWord2 = '';
  //                     let ansWord3 = '';
                      
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
  //                       <ReactAudioPlayer
  //                       style={{margin: '10px auto'}}
  //                         src={ question.audio }
                          
  //                         controls
  //                       />
  //                         <div style={{display: 'flex', justifyContent: 'center', margin: 20}}>
  //                           {question.question.map(word => {
  //                             if (question.words.filter(Word => {return Word == word}).length >= 1){
  //                               word1 = word;
  //                               return (
  //                                 <TextField margin="dense" disabled style={{marginTop: 2, cursor: 'pointer', width: 'max-content'}} variant="outlined" value={this.state.word1}  onClick={()=> this.setState({word1: ''})}/>
  //                               )
  //                             }else {
  //                               return (
  //                                 <H3>{word}</H3>
  //                               )
  //                             }
                              
  //                           })
    
  //                           }
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
  //                         {question.words.map(word => {
  //                           return (
  //                             <H3 style={{cursor: 'pointer'}} onClick={()=> {
  //                               if (this.state.word1 == ''){
    
  //                                 this.setState({word1: word})
  //                                 ansWord1 = word;
  //                                 if (ansWord1 == word1){
  //                                   console.log("correct")
  //                                   this.setState({open: true})
  //                                 } else {
  //                                   this.setState({open2: true})
  //                                 }
  //                               }
  //                             }} >{word}</H3>
  //                           )
  //                         })
    
  //                         }
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   } else if (question.type == "match the pair"){
                      
  //                     const shuffled = question.pairs;
  //                     let completed = 0;
                    
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
                        
  //                         <div style={{display: 'flex', justifyContent: 'center', margin: 20}}>
  //                           {question.pairs.map((pair, index) => {
  //                             return (
  //                               <div style={{margin: '10px'}}>
  //                                 <Word>{pair.word}</Word>
  //                                 <Phrase>
  //                                   <p>{index == 0 ? this.state.phrase1 : index == 1 ? this.state.phrase2 : index == 2 ? this.state.phrase3 : index == 3 ? this.state.phrase4 : ''}</p>
  //                                 </Phrase>
                                  
  
  //                               </div>
  //                             )
                              
  //                           })
    
  //                           }
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
  //                         {shuffled.map((pair, index) => {
  //                           return (
  //                             <Phrase style={{cursor: 'pointer'}} onClick={()=>{
  //                               if (this.state.phrase1 == ''){
  //                                 this.setState({phrase1: pair.phrase})
  //                               } else if (this.state.phrase2 == ''){
  //                                 this.setState({phrase2: pair.phrase})
  //                               }else if (this.state.phrase3 == ''){
  //                                 this.setState({phrase3: pair.phrase})
  //                               }else if (this.state.phrase4 == ''){
  //                                 this.setState({phrase4: pair.phrase})
  //                               }
  //                               completed = completed + 1;
  //                               let {ans} = this.state;
  //                               ans.push(pair.phrase)
  //                               console.log(ans)
  //                               let pairs = []
  //                               question.pairs.forEach(pair => {
  //                                 pairs.push(pair.phrase)
  //                               })
  //                               console.log(pairs)
  //                               if (ans.length == pairs.length){
  //                                 if (JSON.stringify(ans)==JSON.stringify(pairs)){
  //                                   console.log('correct')
  //                                   this.setState({open: true})
  //                                 } else {
  //                                   this.setState({open: false})
  
  //                                 }
  //                               }
  
                                
  
  //                             }}>
  //                               <p>{pair.phrase}</p> 
  //                             </Phrase>
  //                           )
  //                         })
    
  //                         }
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   }else if (question.type == "correct order"){
  //                     const correct = question.sentence;
                      
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
                       
  //                         <div style={{display: 'flex', width: 400, height: 100, justifyContent: 'center', border: '1px solid #bebebe', padding: 10, backgroundColor: '#ebebeb', margin: '20px auto'}}>
  //                           {this.state.sentence.map(word => {
  //                             return (
  //                               <h4>{word}</h4> 
  //                             )
                              
                              
  //                           })
    
  //                           }
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
  //                         {question.shuffled.map(word => {
  //                           return (
  //                             <Button variant="contained" onClick={()=> {
  //                               let {sentence} = this.state;
  //                               sentence.push(word);
  //                               this.setState({sentence})
  //                               console.log(sentence)
  //                               console.log(correct)
  //                               if (sentence.length == question.sentence.length){
  //                                 if (JSON.stringify(sentence)==JSON.stringify(correct)){
  //                                   console.log('correct')
  //                                   this.setState({open: true})
  //                                 } else {
  //                                   this.setState({open2: true})
  
  //                                 }
  //                               }
  //                             }}>{word}</Button>
  //                           )
  //                         })
    
  //                         }
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   } else if (question.type == "true false"){
                      
                      
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
                       
  //                         <div style={{display: 'flex', width: 400, height: 100, justifyContent: 'center', border: '1px solid #bebebe', padding: 10, backgroundColor: '#ebebeb', margin: '20px auto'}}>
                            
  //                               <h4>{question.sentence}</h4> 
                            
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
                          
  //                             <Button variant="contained" onClick={()=> {
                   
                                
  //                               if (question.status == true){
  //                                 console.log("correct")
  //                                 this.setState({open: true})
  //                               } else {
  //                                 this.setState({open2: true})
  
  //                                 console.log("false")
  //                               }
  //                             }}>True</Button>
  
  //                             <Button variant="contained" onClick={()=> {
                       
                                
  //                               if (question.status == false){
  //                                 console.log("correct")
  //                                 this.setState({open: true})
  
  //                               } else {
  //                                 this.setState({open2: true})
                                  
  //                                 console.log("false")
  //                               }
  //                             }}>False</Button>
                          
    
                          
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   } else if (question.type == "mcq"){
                      
                      
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : question.type == "mcq" ? "Select the correct option" : "Solve"  } </h2>
                       
  //                         <div style={{display: 'flex', justifyContent: 'center', margin: 20}}>
                            
  //                               <h4>{question.question}</h4> 
                            
    
  //                         </div>
    
  //                         <div style={{}}>
  //                           {question.options.map(option => {
  //                             return (
  //                               <Button variant="contained" onClick={()=> {
                                    
                                                  
  //                                   if (question.correctOption == option){
  //                                     console.log("correct")
  //                                   this.setState({open: true})
  
  //                                   } else {
  //                                   this.setState({open2: true})
  
  //                                     console.log("false")
  //                                   }
  //                                 }}>{option}</Button>
  //                             )
  //                           })
  
  //                           }
                          
                              
        
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   }
                    
  //                 } 
  //               })
  
  //               }
  //             </div>
  
  
  
  
  
  //               {/* <div style={{position: 'fixed', bottom: 20, margin: 'auto'}}>
  //               <Alert severity="success">
  //                 <AlertTitle>Success</AlertTitle>
  //                 This is a success alert — <strong>check it out!</strong>
  //               </Alert>
  //               </div> */}
  
  // <Snackbar open={this.state.open} autoHideDuration={10000} onClose={this.handleClose}>
  //         <Alert onClose={this.handleClose} severity="success">
  //           Correct!
  //         </Alert>
  //       </Snackbar>
  //       <Snackbar open={this.state.open2} autoHideDuration={10000} >
  //         <Alert severity="error">
  //           Wrong!
  //         </Alert>
  //       </Snackbar>
  
  //             <CancelIcon  onClick={()=> this.setState({dialog: false})} style={{position: 'fixed', top: 30, left: 30, cursor: 'pointer', opacity: 0.8}} />
  //             {this.state.index != 0 && <Button variant="contained" color="primary" style={{position: 'fixed', bottom: 30, left: 80}} onClick={()=> {
  //               this.setState({index: this.state.index - 1, open: false, open2: false})
  //               this.setState({word1: '',
  //               word2: '',
  //               word3: '',
  //               word4: '',
  //               phrase1: '',
  //               phrase2: '',
  //               phrase3: '',
  //               phrase4: '',
  //               shuffled: [],
  //               ans: [],
  //               sentence: [],})
  
  //             }} >Previous</Button>}
  //             {this.state.index != (this.state.exercise.length - 2) && <Button variant="contained" color="primary" style={{position: 'fixed', bottom: 30, right: 80}} onClick={()=> {
  //               this.setState({index: this.state.index + 1, open: false, open2: false})
  //               this.setState({word1: '',
  //               word2: '',
  //               word3: '',
  //               word4: '',
  //               phrase1: '',
  //               phrase2: '',
  //               phrase3: '',
  //               phrase4: '',
  //               shuffled: [],
  //               ans: [],
  //               sentence: [],})
  //             }} >Next</Button>}
  //             </div>
  //         </Dialog>
  //       </div>
  //     );

    }

    
  }
}


const H3 = styled.h3 `
  font-weight: bold;
  margin: 5px;
  padding: 5px;

  @media(max-width: 500px ){
    font-size: medium;
  }

`

const Word = styled.h3 `
  padding: 10px;
  margin: 5px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
  border-radius: 5px 5px 0px 0px;
`

const Phrase = styled.div `
  padding: 10px;
  margin: 5px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
  text-align: center;


`