import React, { Component } from "react";
import  '../../../App.css'
import {Recorder} from 'react-voice-recorder'
import 'react-voice-recorder/dist/index.css'
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";
import MicRecorder from 'mic-recorder-to-mp3';
import { TextField, Button } from "@material-ui/core";
import Timer from 'react-compound-timer'
import MicIcon from '@material-ui/icons/Mic';
import StopIcon from '@material-ui/icons/Stop';
import SendIcon from '@material-ui/icons/Send';
import { fire } from "../../../config";
import "../../../config" ;
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import storage from "../../../config";
import * as firebase from "firebase";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export default class chats extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      messages: [],
      recording: false,
      audio: '',
      file: '',
      text: '',
      isRecording: false,
        blobURL: '',
        isBlocked: false,
        chats: []
      
    };
  }

  componentDidMount(){
    firebase.firestore().collection('chats').doc(localStorage.getItem('email')).onSnapshot(res => {
      if (res.data()){
        this.setState({messages: res.data().messages})
      }
     
      const container = document.getElementById("chatbox");
    if (container) {
       container.scrollTo(0, container.scrollHeight)
    }
    })

    const container = document.getElementById("chatbox");
    if (container) {
       container.scrollTo(0, container.scrollHeight)
    }

    firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
      newMessage: false
    })



  }


  start = () => {
    this.setState({recording: true})
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true });
        }).catch((e) => console.error(e));
    }
  };

  stop = () => {
    this.setState({recording: false})
    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob)
        console.log(blob)
        this.setState({ blobURL, audio: blob, isRecording: false });
      }).catch((e) => console.log(e));
  };

  send = async () => {
    
    if (this.state.audio != ''){
      let upload = storage
        .ref(this.state.audio.size.toString())
        .put(this.state.audio);
      upload.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref(this.state.audio.size.toString())
            .getDownloadURL()
            .then(async (url) => {
              const messages = await firebase.firestore().collection('chats').doc(localStorage.getItem('email')).get()
              console.log(messages.data())


              if (messages.data() == undefined){
                firebase.firestore().collection('chats').doc(localStorage.getItem('email')).set({
                  messages: [{
                    sender: localStorage.getItem('email'),
                    type: 'audio',
                    message: url,
                    timestamp: Date.now()
                  }],
                  recieverHasRead: false
                }).then(res => {
               
                  this.setState({text: '', audio: ''})
                })

              } else {
                firebase.firestore().collection('chats').doc(localStorage.getItem('email')).update({
                  messages: firebase.firestore.FieldValue.arrayUnion({
                    sender: localStorage.getItem('email'),
                    type: 'audio',
                    message: url,
                    timestamp: Date.now()
                  }),
                  recieverHasRead: false
                }).then(res => {
            
                  this.setState({text: '', audio: ''})
                })

              }

            })})

    } else if (this.state.file != ''){
      let upload = storage
        .ref(this.state.file.name)
        .put(this.state.file);
      upload.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref(this.state.file.name)
            .getDownloadURL()
            .then(async (url) => {
              const messages = await firebase.firestore().collection('chats').doc(localStorage.getItem('email')).get()
          


              if (messages.data() == undefined){
                firebase.firestore().collection('chats').doc(localStorage.getItem('email')).set({
                  messages: [{
                    sender: localStorage.getItem('email'),
                    type: 'img',
                    message: url,
                    timestamp: Date.now()
                  }],
                  recieverHasRead: false
                }).then(res => {
                  console.log(res)
                  this.setState({text: '', file: '', audio: ''})
                })

              } else {
                firebase.firestore().collection('chats').doc(localStorage.getItem('email')).update({
                  messages: firebase.firestore.FieldValue.arrayUnion({
                    sender: localStorage.getItem('email'),
                    type: 'img',
                    message: url,
                    timestamp: Date.now()
                  }),
                  recieverHasRead: false
                }).then(res => {
                  console.log(res)
                  this.setState({text: '', audio: '', file: ''})
                })

              }

            })})

    } else {
      console.log('here')
      const messages = await firebase.firestore().collection('chats').doc(localStorage.getItem('email')).get()
      console.log(messages.data())


      if (messages.data() == undefined){
        firebase.firestore().collection('chats').doc(localStorage.getItem('email')).set({
          messages: [{
            sender: localStorage.getItem('email'),
            type: 'text',
            message: this.state.text,
            timestamp: Date.now()
          }],
          recieverHasRead: false
        }).then(res => {
          console.log(res)
          this.setState({text: ''})
        })

      } else {
        firebase.firestore().collection('chats').doc(localStorage.getItem('email')).update({
          messages: firebase.firestore.FieldValue.arrayUnion({
            sender: localStorage.getItem('email'),
            type: 'text',
            message: this.state.text,
            timestamp: Date.now()
          }),
          recieverHasRead: false
        }).then(res => {
          console.log(res)
          this.setState({text: ''})
        })

      }

      

    }

  }


  render(){

      return (
        <div style={{ height: '100%', backgroundColor:'#fafafa'}}>

          <h2 style={{backgroundColor: '#777777', color: '#e9c979'}}>Messaging</h2>
          <div id="chatbox" style={{height: '400px', marginBottom: 70, overflow: 'scroll', overflowX: 'hidden' }}>
            {this.state.messages.map(message => {
              if (message.sender == localStorage.getItem('email')){
                return (
                  <div style={{width: '100%'}}>
                    <div style={{paddingRight: 10, display: 'block', textAlign:'right', height: 'max-content', width: '51%', maxWidth: '80%', borderRadius: 6, float: 'right'}}>
                      {message.type == 'text' &&<p style={{padding: 8, float: 'right', width: 'max-content', backgroundColor: "#b5e2ff", borderRadius: 6 }}>{ message.message}</p>}
                      {message.type == 'audio' && <p style={{padding: 8, float: 'right', width: 'max-content', backgroundColor: "#b5e2ff", borderRadius: 6 }}><audio src={message.message} style={{padding: 10, margin: 'auto' }} controls="controls" /></p>}
                      {message.type == 'img' && <p style={{padding: 8, float: 'right', width: 'max-content', backgroundColor: "#b5e2ff", borderRadius: 6 }}><img src={message.message} style={{padding: 10, margin: 'auto', height: 120, width: 120 }} /></p>}
                    </div>
                  </div>
                  
                )
              }else {
                return (
                  <div style={{width: '100%'}}>
                    <div style={{paddingLeft: 10, display: 'block', textAlign:'left', height: 'max-content', width: '51%', maxWidth: '80%', borderRadius: 6, float: 'left'}}>
                      {message.type == 'text' &&<p style={{padding: 8, float: 'left', width: 'max-content', backgroundColor: "#b5e2ff", borderRadius: 6 }}>{ message.message}</p>}
                      {message.type == 'audio' && <p style={{padding: 8, float: 'left', width: 'max-content', backgroundColor: "#b5e2ff", borderRadius: 6 }}><audio src={message.message} style={{padding: 10, margin: 'auto' }} controls="controls" /></p>}
                      {message.type == 'img' && <p style={{padding: 8, float: 'left', width: 'max-content', backgroundColor: "#b5e2ff", borderRadius: 6 }}><img src={message.message} style={{padding: 10, margin: 'auto', height: 120, width: 120 }} /></p>}
                    </div>
                  </div>
                )
              }
            })

            }
            
          </div>
          <div style={{position: 'fixed', bottom: 0, textAlign: 'left', left: window.innerWidth >= 600 ? 200 : 0, padding: 10, width: '100%', height: 'max-content', backgroundColor: '#bebebe'}}>
          <div style={{position: 'fixed', right: 120, bottom: 15}}>

          </div>
          <input accept="image/*" style={{height: 0, width: 0, display: 'none'}} id="icon-button-file" type="file" onChange={(e)=> {this.setState({file: e.target.files[0]})}} />
            
                <label htmlFor="icon-button-file">
                    <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                    </IconButton>
                </label>
            <button  style={{cursor: 'pointer',border: 'none', position: 'fixed', right: 80, bottom: 10, backgroundColor: 'green', padding: '10px 10px 5px 10px', borderRadius: 4, color: 'white' }} disabled={this.state.isRecording}>
            {!this.state.recording && <MicIcon onClick={this.start} />}
            {this.state.recording && <StopIcon onClick={this.stop}/>}
            </button>
            <button  style={{cursor: 'pointer',border: 'none', position: 'fixed', right: 20, bottom: 10, backgroundColor: '#1357a6', padding: '10px 10px 5px 10px', borderRadius: 4, color: 'white' }} disabled={this.state.isRecording}>
            <SendIcon onClick={this.send} />
         
            </button>
            
            {/* <div style={{padding: 10, }}>
                <audio src={this.state.blobURL}  controls="controls" />
            </div> */}
            {this.state.audio != '' && <audio src={this.state.blobURL} style={{padding: 0, marginBottom: '-20px' }} controls="controls" />}
            {this.state.recording && <h3>Recording...</h3>}
            {this.state.audio == '' && !this.state.recording && <TextField  multiline variant="outlined" style={{marginBottom: -20, backgroundColor: 'white', borderRadius: 5, width: 'calc(100% - 380px)'}} value={this.state.text} onChange={(e)=> {
              this.setState({text: e.target.value})
            }} />}

          </div>
            
        </div>

      )
  }


}