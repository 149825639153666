import React, {Component} from 'react';
import '../../App.css';
import styled from 'styled-components';
import ReactPlayer from "react-player";



export default class VideoPlayer extends Component {
    constructor(){
        super();
        this.state = {
            video: ''
        }
    }

    componentDidMount(){
        console.log(this.props.location.pathname.split('/')[2])
        this.setState({video: "https://vimeo.com/" + this.props.location.pathname.split('/')[2]})

    }

    render(){
        
        return (
            <div style={{width: '100%', height: 240, marginTop: 0, paddingTop: 0,paddingBottom: 0, marginBottom: 0, backgroundColor: '#303030'}}>
                <ReactPlayer
                                        onContextMenu ={(e) => e.preventDefault()}
                                            style={{ margin: "0px auto 20px auto", backgroundColor: '#404040'}}
                                            controls={true}
                                            width={380}
                                            height={240}
                                            playing={false}
                                            config={{ file: { 
                                            attributes: {
                                                controlsList: 'nodownload'
                                            }
                                            }}}
                                            url={this.state.video}
                                        />

                

            </div>
        )
    }
}



