import React from 'react';
import Tree from 'react-d3-tree';
import storage from "../../../config";
import * as firebase from "firebase";
import "../../../config" ;
 


const svgSquare = {
    shape: 'circle',
    shapeProps: {
      r: 10,
      x: 20,
      stroke: 'Dark blue'
    }
  }
  
export default class TeamView extends React.Component {

    constructor(){
        super()
        this.state = {
            translate: {
                
            },
            height: 20,
            data: {
                name: '',
                children: []
            },
            Data: [{}]

        }
    }

    componentDidMount(){
        const dimensions = this.treeContainer.getBoundingClientRect();
        this.setState({
            translate: {
              x: dimensions.width / 2,
              y: 20
            },
            
          });
          let {data} = this.state;
          let Data = []


          firebase.firestore().collection('Users').doc(localStorage.getItem('email')).get().then(res => {
            console.log(res.data())
            data.name = res.data().name;
            
            this.setState({user: res.data()})
            res.data().first.forEach(user => {
                firebase.firestore().collection('Users').doc(user.email).get().then(res2 => {
                    let children = res2.data().first;
                    data.children.push({
                        name: res2.data().name,
                        children: []
                        

                    })

                    children.forEach(user2 => {
                        firebase.firestore().collection('Users').doc(user2.email).get().then(res3 => {
                            data.children.forEach(child => {
                                if (child.name == res2.data().name){
                                    child.children.push({
                                        name: res3.data().name,
                                        children: []
                                    })
                                    

                                    res3.data().first.forEach(user3 => {
                                        firebase.firestore().collection('Users').doc(user3.email).get().then(res4 => {
                                            child.children.forEach(child2 => {
                                                if (child2.name == res3.data().name){
                                                    child2.children.push({
                                                        name: res4.data().name,
                                                        children: []
                                                    })
                                                    console.log(data)
                                                    this.setState({Data: [data]})

                                                }
                                            })
                                        })
                                    })
                                }
                            })
                        })
                    })

                })
                
            });


        })
    }
  render() {
    return (
        
      
      <div id="treeWrapper" style={{width: '100%', height: 700, margin: 'auto', textAlign: 'center', backgroundColor: '#ebebeb'}} ref={tc => (this.treeContainer = tc)}>
          <h2>Team View</h2>
          <p>Use the mouse to drag and zoom. Click on the nodes to expand or collapse</p>
        <Tree data={this.state.Data} nodeSvgShape={svgSquare} orientation="vertical" translate= {this.state.translate} pathFunc="step" style={{width: 'max-content'}}/>
 
      </div>
    );
  }
}