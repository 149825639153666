import React, { Component } from "react";
import "../../App.css";
import styled from "styled-components";
import LinearProgress from "@material-ui/core/LinearProgress";
import '../../config' ;
import storage from "../../config";
import * as firebase from "firebase";
import Dialog from '@material-ui/core/Dialog';
import Chat from './Messaging/Chats';
import CloseIcon from '@material-ui/icons/Close';

export default class aprendeSlider extends Component {
  constructor() {
    super();
    this.state = {
      selected: "",
      user: {},
      chat: false
    };
  }

  componentDidMount() {
    firebase.firestore().collection('Users').doc(localStorage.getItem('email')).onSnapshot(res => {
         
      this.setState({user: res.data()})
  })
    if (window.location.pathname == "/affiliate/introduction") {
      this.setState({ selected: "introduction" });
    } else if (window.location.pathname == "/affiliate/level1") {
      this.setState({ selected: "1" });
    } else if (window.location.pathname == "/affiliate/level2") {
      this.setState({ selected: "2" });
    } else if (window.location.pathname == "/affiliate/level3") {
      this.setState({ selected: "3" });
    } else if (window.location.pathname == "/affiliate/level4") {
      this.setState({ selected: "4" });
    } else if (window.location.pathname == "/affiliate/university") {
      this.setState({ selected: "uni" });
    } else if (window.location.pathname == "/affiliate/messaging") {
      this.setState({ selected: "messaging" });
    } else if (window.location.pathname == "/affiliate/nrs") {
      this.setState({ selected: "nrs" });
    }else if (window.location.pathname == "/affiliate/training") {
      this.setState({ selected: "training" });
    }
  }

  render() {
    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: 70,
          margin: 0,
          display: "flex",
          backgroundColor: "#707070",
          overflow: "scroll",
          overflowY: "hidden",
          borderBottom: '1px solid #303030'

        }}
      >
        <a href="/affiliate/introduction">
          <p
            className={
              this.state.selected == "introduction"
                ? "aprende-btn-active"
                : "aprende-btn"
            }
            onClick={() => {
              this.setState({ selected: "introduction" });
            }}
          >
            Introduction
          </p>
        </a>
        <a href="/affiliate/level1">
          <p
            className={
              this.state.selected == "1" ? "aprende-btn-active" : "aprende-btn"
            }
            onClick={() => {
              this.setState({ selected: "1" });
            }}
          >
            Level 1
          </p>
        </a>
        <a href="/affiliate/level2">
          <p
            className={
              this.state.selected == "2" ? "aprende-btn-active" : "aprende-btn"
            }
            onClick={() => {
              this.setState({ selected: "2" });
            }}
          >
            Level 2
          </p>
        </a>
        <a href="/affiliate/level3">
          <p
            className={
              this.state.selected == "3" ? "aprende-btn-active" : "aprende-btn"
            }
            onClick={() => {
              this.setState({ selected: "3" });
            }}
          >
            Level 3
          </p>
        </a>
        <a href="/affiliate/level4">
          <p
            className={
              this.state.selected == "4" ? "aprende-btn-active" : "aprende-btn"
            }
            onClick={() => {
              this.setState({ selected: "4" });
            }}
          >
            Level 4
          </p>
        </a>
        <a href="/affiliate/bonus-courses">
          <p
            className={
              this.state.selected == "bonus" ? "aprende-btn-active" : "aprende-btn"
            }
            onClick={() => {
              this.setState({ selected: "bonus" });
            }}
          >
            Bonus
          </p>
        </a>
        <a href="/affiliate/university">
          <p
            className={
              this.state.selected == "uni"
                ? "aprende-btn-active"
                : "aprende-btn"
            }
            onClick={() => {
              this.setState({ selected: "uni" });
            }}
          >
            University
          </p>
        </a>
        
          <p
            className={
              this.state.selected == "message"
                ? "aprende-btn-active"
                : "aprende-btn"
            }
            onClick={() => {
              this.setState({ chat: true });
            }}
          >
            Messaging
          </p>
          {this.state.user.newMessage && <div style={{width: 10, height: 10, position: 'absolute', top: 5, right: 3, borderRadius: 10,  backgroundColor: 'red'}}></div>}
        
        <a href="/affiliate/nrs">
          <p
            className={
              this.state.selected == "nrs"
                ? "aprende-btn-active"
                : "aprende-btn"
            }
            onClick={() => {
              this.setState({ selected: "nrs" });
            }}
          >
            NRS
          </p>
        </a>

        <a href="/affiliate/training">
          <p
            className={
              this.state.selected == "training"
                ? "aprende-btn-active"
                : "aprende-btn"
            }
            onClick={() => {
              this.setState({ selected: "training" });
            }}
          >
            Training
          </p>
        </a>




        {this.state.chat && <div style={{position: "fixed", right: 50, bottom: 0, zIndex: 2}}>
          <CloseIcon style={{position: 'absolute', top: 14, right: 20, color: '#bebebe', cursor: 'pointer'}} onClick={() => this.setState({chat: false})}/>
          <div style={{width: '100%', height: '100%'}}>
            <Chat/>
          </div>
          
        </div>}
      </div>
    );
  }
}

const Grid = styled.div`
  padding: 0px;
  margin: 0px;
  display: grid;
  width: 340px;
  grid-template-columns: 20% 20% 20% 20% 20%;
`;

const Grid50 = styled.div`
  padding: 0px;
  margin: 0px;
  display: grid;
  width: 340px;
  grid-template-columns: 50% 50%;
`;

const P = styled.p`
  margin: 0px;
  padding: 0px;
`;

const Sdiv = styled.div`
  background-color: #d2d2d2;
  border-radius: 5px;
  padding: 4px 10px;
  margin: 5px;
`;
