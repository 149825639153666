import React, { Component } from "react";
import  '../../../App.css'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import { TextField, Button } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';

import ReactAudioPlayer from 'react-audio-player';
import * as firebase from "firebase";
import "../../../config" ;
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';
import LocalMoviesIcon from '@material-ui/icons/LocalMovies';
import PanoramaIcon from '@material-ui/icons/Panorama';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import styled from "styled-components";
import storage from "../../../config";
import ReactPlayer from "react-player";







export default class training extends Component {
    constructor(){
        super();
        this.state = {
            file: null,
            pictureTitle: "",
            training: [],
            videoTitle: '',
            videoLink: '',
            docTitle: '',
            audioTitle: ''
           

        }
    }

    componentDidMount(){
        firebase.firestore().collection('Training').onSnapshot(res => {
            this.setState({training: res.docs})  
            console.log(res.docs)
        })        
       
        
       

    }

    deleteImg(id){
        firebase.firestore().collection('Training').doc(id).delete()


    }

    deleteVideo(id){
        firebase.firestore().collection('Training').doc(id).delete()


    }

    deleteDoc(id){
        firebase.firestore().collection('Training').doc(id).delete()


    }

    deleteAudio(id){
        firebase.firestore().collection('Training').doc(id).delete()


    }

    uploadAudio(file){
        console.log('here')
        if (file){
            console.log('here')
            let upload = storage
            .ref(file.name)
            .put(file);
        upload.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
            console.log(error);
            },
            () => {
            storage
                .ref(file.name)
                .getDownloadURL()
                .then((url) => {
                
                    firebase.firestore().collection('Training').add({
                        type: 'audio',
                        url: url,
                        title: this.state.audioTitle,
                        date: Date.now()
                    
                    }).then(res => {
                        console.log(res)
                        this.setState({file: null, audioTitle: ''})
                    })
                

                })})

            
        }

    }
    uploadDocument(file){
        
        if (file){
            console.log('here')
            let upload = storage
            .ref(file.name)
            .put(file);
        upload.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
            console.log(error);
            },
            () => {
            storage
                .ref(file.name)
                .getDownloadURL()
                .then((url) => {
                
                    firebase.firestore().collection('Training').add({
                        type: 'document',
                        url: url,
                        title: this.state.docTitle,
                        date: Date.now()
                    
                    }).then(res => {
                        console.log(res)
                        this.setState({file: null, docTitle: ''})
                    })
                

                })})

            
        }

    }

    uploadPicture(file){
        console.log('here')
        if (file){
            console.log('here')
            let upload = storage
            .ref(file.name)
            .put(file);
        upload.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
            console.log(error);
            },
            () => {
            storage
                .ref(file.name)
                .getDownloadURL()
                .then((url) => {
                
                    firebase.firestore().collection('Training').add({
                        type: 'image',
                        url: url,
                        title: this.state.pictureTitle,
                        date: Date.now()
                    
                    }).then(res => {
                        console.log(res)
                        this.setState({file: null, pictureTitle: ''})
                    })
                

                })})

            
        }

    }


    uploadVideo(){
        firebase.firestore().collection('Training').add({
            type: 'video',
            url: this.state.videoLink,
            title: this.state.videoTitle,
            date: Date.now()
        
        }).then(res => {
            console.log(res)
            this.setState({videoTitle: '', videoLink: ''})
        })

    }

    uploadIntroVideo(){
        firebase.firestore().collection('Training').doc('introFirst').set({
            type: 'video',
            url: this.state.videoLink,
            date: Date.now()
        
        }).then(res => {
            console.log(res)
            this.setState({file: null, videoLink: ''})
        })
    }

  


    render(){ 
        if (this.state.selected == 'videos'){
            return (
             
                <div style={{position: 'relative'}}>
                    <h1>Videos</h1>
                    <ArrowBackIcon style={{position: 'absolute', top: 20, left: 30, cursor: 'pointer'}}  onClick={()=> this.setState({selected: ''})}/>
                    
                    <div>
                        <div style={{maxWidth: 400, margin: 'auto'}} >
                        <TextField fullWidth variant="outlined" margin="dense" placeholder="add video link here..." onChange={(e)=> this.setState({videoLink: e.target.value})} />
                        <TextField fullWidth variant="outlined" margin="dense" placeholder="add video title here..." onChange={(e)=> this.setState({videoTitle: e.target.value})} />

                        </div>
                        
                        
                            <Button variant="contained" color="primary" component="span" onClick={()=> {
                                if (this.state.videoLink == ''){
                                    alert('Please add a video link')
                                } else if (this.state.videoTitle == ''){
                                    alert('Please add a video title')
                                } else {
                                    this.uploadVideo()
                                }
                            }}>
                            Upload Video
                            </Button>
                    
                    </div>
                    <div style={{margin: 20, padding: 10, display: 'flex',flexWrap: 'wrap', justifyContent: 'center'}}>
                        {this.state.training.map(file => {
                            console.log(file.data())
                            if (file.data().type == 'video'){
                                return (
                                    <div style={{position: 'relative', padding: 20, borderRadius: 5, margin: 10, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)', backgroundColor: 'white', width: 'max-content'}}>
                                       <ReactPlayer
                                        onContextMenu ={(e) => e.preventDefault()}
                                            style={{ margin: "0px auto 20px auto" }}
                                            controls={true}
                                            playing={false}
                                            config={{ file: { 
                                            attributes: {
                                                controlsList: 'nodownload'
                                            }
                                            }}}
                                            url={file.data().url}
                                        />
                                        <p>{file.data().title}</p>
                                       <CancelIcon style={{position: 'absolute', top: -10, right: -10, cursor: 'pointer', }} onClick={() => this.deleteImg(file.id)} />

                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
                

            )
        } else if (this.state.selected == 'pictures'){
            return (
                <div style={{position: 'relative'}}>
                    <h1>Pictures</h1>
                    <ArrowBackIcon style={{position: 'absolute', top: 20, left: 30, cursor: 'pointer'}}  onClick={()=> this.setState({selected: ''})}/>
                    <TextField variant="outlined" margin="dense" placeholder='title' onChange={(e)=> this.setState({pictureTitle: e.target.value})} />
                    <div>
                        <input
                            accept="image/*"
                            id="contained-button-file"
                            multiple
                            type="file"
                            style={{display: 'none'}}
                            onChange={(e)=> {
                                if(this.state.pictureTitle == ''){
                                    alert('Please add a title')
                                } else {
                                    this.setState({file: e.target.files[0]})
                                this.uploadPicture(e.target.files[0])

                                }
                                
                            }}
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" color="primary" component="span" >
                            Upload Picture
                            </Button>
                        </label>
                    </div>

                    <div style={{margin: 20, padding: 10, display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                        {this.state.training.map(file => {
                            console.log(file.data())
                            if (file.data().type == 'image'){
                                return (
                                    <div style={{position: 'relative', padding: 20, borderRadius: 5, maxWidth: 190, margin: 10, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)', backgroundColor: 'white', width: 'max-content'}}>
                                       <img src={file.data().url} style={{width: 150, height: 150}} />
                                       <p>{file.data().title}</p>
                                       <CancelIcon style={{position: 'absolute', top: -10, right: -10, cursor: 'pointer', }} onClick={() => this.deleteImg(file.id)} />

                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>

            )
        } else if (this.state.selected == 'audio'){
            return (
                <div style={{position: 'relative'}}>
                    <h1>Audios</h1>
                    <ArrowBackIcon style={{position: 'absolute', top: 20, left: 30, cursor: 'pointer'}}  onClick={()=> this.setState({selected: ''})}/>
                    <TextField variant="outlined" margin="dense" placeholder='title' onChange={(e)=> this.setState({audioTitle: e.target.value})} />
                    
                    <div>
                        <input
                           
                            id="contained-button-file"
                            multiple
                            type="file"
                            style={{display: 'none'}}
                            onChange={(e)=> {
                                if(this.state.audioTitle == ''){
                                    alert('Please add a title')
                                } else {
                                    this.setState({file: e.target.files[0]})
                                this.uploadAudio(e.target.files[0])

                                }
                                
                            }}
                            
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" color="primary" component="span">
                            Upload Audio
                            </Button>
                        </label>
                    </div>
                    <div style={{margin: 20, padding: 10, display: 'flex', justifyContent: 'center'}}>
                        {this.state.training.map(file => {
                            console.log(file.data())
                            if (file.data().type == 'audio'){
                                return (
                                    <div style={{position: 'relative', padding: 20, borderRadius: 5, margin: 10, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)', backgroundColor: 'white', width: 'max-content'}}>
                                    <ReactAudioPlayer
                                    style={{margin: '10px auto'}}
                                        src={file.data().url}
                                        
                                        controls
                                    />
                                    <p>{file.data().title}</p>
                                    <CancelIcon style={{position: 'absolute', top: -10, right: -10, cursor: 'pointer', }} onClick={() => this.deleteDoc(file.id)} />

                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>

            )
        } else if (this.state.selected == 'documents'){
            return (
                <div style={{position: 'relative'}}>
                    <h1>Documents</h1>
                    <ArrowBackIcon style={{position: 'absolute', top: 20, left: 30, cursor: 'pointer'}}  onClick={()=> this.setState({selected: ''})}/>
                    <TextField variant="outlined" margin="dense" placeholder='title' onChange={(e)=> this.setState({docTitle: e.target.value})} />
                    
                    <div>
                        <input
                         
                            id="contained-button-file"
                            multiple
                            type="file"
                            style={{display: 'none'}}
                            onChange={(e)=> {
                                if(this.state.docTitle == ''){
                                    alert('Please add a title')
                                } else {
                                    this.setState({file: e.target.files[0]})
                                this.uploadDocument(e.target.files[0])

                                }
                                
                            }}
                            
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" color="primary" component="span">
                            Upload Document
                            </Button>
                        </label>
                    </div>
                    <div style={{margin: 20, padding: 10, display: 'flex',flexWrap: 'wrap', justifyContent: 'center'}}>
                        {this.state.training.map(file => {
                            console.log(file.data())
                            if (file.data().type == 'document'){
                                return (
                                    <div style={{position: 'relative', padding: 20, borderRadius: 5, margin: 10, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)', backgroundColor: 'white', width: 'max-content'}}>
                                    <a href={file.data().url} style={{display: 'flex'}}>
                                        <InsertDriveFileIcon style={{width: 40, height: 40, color: 'grey'}} />
                                        <p style={{color: 'black'}}>{file.data().title}</p></a>
                                        <CancelIcon style={{position: 'absolute', top: -10, right: -10, cursor: 'pointer', }} onClick={() => this.deleteDoc(file.id)} />

                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>

            )
        } else {
            return (
                <div>
                    <h1>Training</h1>
    
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Box onClick={()=> this.setState({selected: 'videos'})}>
                            <LocalMoviesIcon style={{height: 100, width: 100, color: '#0c71e0'}} />
                            <p>Videos</p>
                        </Box>
                        <Box onClick={()=> this.setState({selected: 'pictures'})}>
                            <PanoramaIcon style={{height: 100, width: 100, color: 'green'}} />
                            <p>Pictures</p>
                        </Box>
                        <Box onClick={()=> this.setState({selected: 'audio'})}>
                            <AudiotrackIcon style={{height: 100, width: 100, color: 'brown'}} />
                            <p>Audio</p>
                        </Box>
                        <Box onClick={()=> this.setState({selected: 'documents'})}>
                            <InsertDriveFileIcon style={{height: 100, width: 100, color: 'grey'}} />
                            <p>Documents</p>
                        </Box>
    
                       
                        
    
                    </div>
                    <div style={{width: 400, margin: 'auto'}}>
                        <TextField fullWidth variant="outlined" margin="dense" placeholder="add intro video link here..." onChange={(e)=> this.setState({videoLink: e.target.value})} />

                       
                        
                                                
                        <Button variant="contained" color="primary"  onClick={()=> {
                            if (this.state.videoLink == ''){
                                alert('Please add a video link')
                            } else {
                                this.uploadIntroVideo()
                            }
                        }}>
                        Upload Video
                        </Button>

                    </div>
                    
                    
    
                    
    
    
                </div>
            )

        }

        

    }
} 

const Box = styled.div`
    width: 140px;
    height: 140px;
    margin: 10px;
    padding: 7px;
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 3px 7px rgba(0,0,0,0.3);
`

