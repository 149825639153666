import React, { Component } from "react";
import  '../../../App.css'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import { TextField, Button } from "@material-ui/core";
import * as firebase from "firebase";
import "../../../config" ;
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';


export default class tickets extends Component {
    constructor(){
        super();
        this.state = {
            dialog: false,
            dialog2: false,
            error: false,
            category: '',
            text: '',
            tickets: [],
            id: '',
            selected: '',
            totalTickets: []

        }
    }

    componentDidMount(){
        firebase.firestore().collection('Tickets').onSnapshot(res => {
            let tickets = []
        
            res.docs.forEach(doc => {
           
                tickets.push(doc.data())
            
            
        })        
          this.setState({tickets: tickets, totalTickets: tickets})
          if (this.state.selected != ''){

          }
          console.log(tickets)

        })
       

    }

    send(){
        firebase.firestore().collection('Tickets').doc(this.state.selected.id).update({
            chat: firebase.firestore.FieldValue.arrayUnion({
              sender: localStorage.getItem('email'),
              message: this.state.text,
              sender_type: 'admin',
              timestamp: Date.now()
            }),
            userRead: false
          }).then(res => {
            console.log(res)
           
            let {selected} = this.state;
            selected.chat.push({
                sender: localStorage.getItem('email'),
              message: this.state.text,
              sender_type: 'admin',
              timestamp: Date.now()
            })
            this.setState({selected, text: ''})
          })

    }


    createTicket(){
        if (this.state.category == ''){
            this.setState({error: true})
        } else {
            this.setState({error: false, dialog: false})
            console.log(this.state.id)
            const id = '000' + this.state.id;
            firebase.firestore().collection('Tickets').doc('000' + id).set({
                issue: this.state.text,
                category: this.state.category,
                id: this.state.id,
                response: '',
                status: 'open',
                user: localStorage.getItem('email'),
                userRead: true,
                adminRead: false,
                date: Date.now()
              }).then(res => {
                console.log(res)
                this.setState({text: '', category: ''})
              })



        }

    }

    getDate(unixtime) {

        var u = new Date(unixtime);
    
          return u.getUTCFullYear() +
            '-' + ('0' + u.getUTCMonth()).slice(-2) +
            '-' + ('0' + u.getUTCDate()).slice(-2)
    };

    read(ticket){
      
        ticket.adminRead = true;
        firebase.firestore().collection('Tickets').doc(ticket.id).set(ticket).then(res => {
            
          })

        
    }

    education(){
        this.setState({category: 'education'})
        
        let {tickets} = this.state;
        let Tickets = this.state.totalTickets;
        tickets = [];
        Tickets.forEach(ticket => {
            if (ticket.category == 'education'){
                tickets.push(ticket)
            }
        })

        this.setState({tickets})

    }

    business(){
        this.setState({category: 'business'})
        let {tickets} = this.state;
        let Tickets = this.state.totalTickets;
        tickets = [];
        Tickets.forEach(ticket => {
            if (ticket.category == 'business'){
                tickets.push(ticket)
            }
        })

        this.setState({tickets})

    }


    closeTicket(){
    
            firebase.firestore().collection('Tickets').doc(this.state.selected.id).update({
                status: 'closed',
                closedDate: Date.now(),
                adminRead: true
              }).then(res => {
                console.log(res)
               
                let {selected} = this.state;
                selected.chat.push({
                    sender: localStorage.getItem('email'),
                  message: this.state.text,
                  sender_type: 'admin',
                  timestamp: Date.now()
                })
                this.setState({selected, text: ''})
              })
    
        



    }

    render(){ 
        if (this.state.selected != ''){
            return (
                <div style={{ height: '100%', position: 'relative'}}>
              <ArrowBackIcon style={{position: 'absolute', top: 20, left: 30, cursor: 'pointer'}}  onClick={()=> this.setState({selected: ''})}/>
              <Button style={{position: 'absolute', top: 20, right: 30, cursor: 'pointer'}} variant="contained" onClick = {() => this.closeTicket()}>Close Ticket</Button>
              <h2 >Ticket {this.state.selected.id}</h2>

              
              <div id="chatbox" style={{height: '600px', marginBottom: 100, overflow: 'scroll', overflowX: 'hidden' }}>
                {this.state.selected.chat && this.state.selected.chat.map(message => {
                  if (message.sender_type == 'admin'){
                    return (
                      <div style={{width: '100%'}}>
                        <div style={{paddingRight: 10, display: 'block', textAlign:'right', height: 'max-content', width: '51%', maxWidth: '80%', borderRadius: 6, float: 'right'}}>
                          <p style={{padding: 8, float: 'right', width: 'max-content', backgroundColor: "#b5e2ff", borderRadius: 6 }}>{ message.message}</p>
                        </div>
                      </div>
                      
                    )
                  }else {
                    return (
                      <div style={{width: '100%'}}>
                        <div style={{paddingLeft: 10, display: 'block', textAlign:'left', height: 'max-content', width: '51%', maxWidth: '80%', borderRadius: 6, float: 'left'}}>
                          <p style={{padding: 8, float: 'left', width: 'max-content', backgroundColor: "#b5e2ff", borderRadius: 6 }}>{ message.message}</p>
                        </div>
                      </div>
                    )
                  }
                })
    
                }
                
              </div>
              {this.state.selected.status == 'open' && <div style={{position: 'fixed', bottom: 0, textAlign: 'left', left: 200, padding: 10, width: '100%', height: 'max-content', backgroundColor: '#bebebe'}}>
              <div style={{position: 'fixed', right: 120, bottom: 15}}>
    
              </div>
              
                
                  
                
                <button  style={{cursor: 'pointer',border: 'none', position: 'fixed', right: 20, bottom: 15, backgroundColor: '#1357a6', padding: '10px 10px 5px 10px', borderRadius: 4, color: 'white' }} disabled={this.state.isRecording}>
                <SendIcon onClick={() => this.send()} />
             
                </button>
                
               
                
                <TextField  multiline variant="outlined" style={{marginBottom: -20, backgroundColor: 'white', borderRadius: 5, width: '60%'}} value={this.state.text} onChange={(e)=> {
                  this.setState({text: e.target.value})
                }} />
    
              </div>}

              {this.state.selected.status == 'closed' && <div style={{position: 'fixed', bottom: 0, textAlign: 'center', left: 200, padding: 0, width: '100%', height: 'max-content', backgroundColor: '#bebebe'}}>
                  <h2>This ticket has been closed</h2>
                </div>}
                
            </div>
            )

        } else {
            return (
                <div>
                    <FormControlLabel
                        control={
                        <Checkbox
                                checked={this.state.category == 'education'}
                                onChange={()=> this.education()}
                                name="Education"
                                color="primary"
                            />
                            }
                            label="Education"
                        />
    
                        <FormControlLabel
                        control={
                        <Checkbox
                                checked={this.state.category == 'business'}
                                onChange={() => this.business()}
                                name="Business"
                                color="primary"
                            />
                            }
                            label="Business"
                        />  
    
                    
    
                    <TableContainer component={Paper} style={{width: '90%', margin: '10px auto'}}>
                        <Table >
                            <TableHead>
                            <TableRow style={{backgroundColor: '#add8e6'}}>
                                <TableCell style={{fontWeight: 'bold'}}>Tickets</TableCell>
                                <TableCell style={{fontWeight: 'bold'}} align="right">Id</TableCell>
                                <TableCell style={{fontWeight: 'bold'}} align="right">Date</TableCell>
                                <TableCell style={{fontWeight: 'bold'}} align="right">Status</TableCell>
                                
                                
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.tickets.map((ticket) => (
                                <TableRow style={{cursor: 'pointer', backgroundColor: ticket.adminRead == true ? '' : '#ffffe0'}} onClick={()=>{
                                    this.setState({selected: ticket, dialog2: true})
                                    this.read(ticket)
                                }}>
                                <TableCell component="th" scope="row">
                                    {ticket.chat && ticket.chat[0].message.slice(0,20)}
                                </TableCell>
                                <TableCell align="right">{ticket.id}</TableCell>
                                <TableCell align="right">{this.getDate(ticket.date)}</TableCell>
                                <TableCell align="right">{ticket.status}</TableCell>
                              
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    
    
    
    
                    <Dialog
                    fullWidth
                    open={this.state.dialog}
                    > 
                    <div style={{width: '100%', textAlign: 'center', padding: 10}}>   
                        <h2>New Ticket</h2>
                        {this.state.error && <p style={{color: 'red'}}>Please select a category</p>}
                        <FormControlLabel
                        control={
                        <Checkbox
                                checked={this.state.category == 'education'}
                                onChange={()=> this.setState({category: 'education'})}
                                name="Education"
                                color="primary"
                            />
                            }
                            label="Education"
                        />
    
                        <FormControlLabel
                        control={
                        <Checkbox
                                checked={this.state.category == 'business'}
                                onChange={() => this.setState({category: 'business'})}
                                name="Business"
                                color="primary"
                            />
                            }
                            label="Business"
                        />  
    
                        <div style={{width: '90%', margin: '10px auto'}}>
                            <TextField fullWidth variant="outlined" placeholder="type your issue here" multiline onChange={(e)=> this.setState({text: e.target.value})}/>
    
                        </div>
    
                        <div style={{display: 'flex', justifyContent: 'space-around', margin: '10px auto'}}>
                            <Button variant="contained" onClick={()=> this.setState({dialog: false})} >Cancel</Button>
                            <Button variant="contained" color="primary" onClick={()=> this.createTicket()}>Open Ticket</Button>
                             
                        </div>
    
                        
                    </div>
    
                    </Dialog>
    
                    {/* <Dialog
                    fullWidth
                    open={this.state.dialog2}
                    > 
                    <div style={{width: '100%', textAlign: 'center', padding: 10}}>   
                        <h2 >Ticket {this.state.selected.id}</h2>
                      
                   <div style={{display: 'flex', justifyContent: 'space-around'}}>
                       <div style={{padding: '10px 10px 0px 10px', borderRadius: 5, backgroundColor: '#ebebeb'}}>
                           <p>Category</p>
                           <h3 style={{fontWeight: 'bold'}}>{this.state.selected.category}</h3>
    
                       </div>
                       <div style={{padding: '10px 10px 0px 10px', borderRadius: 5, backgroundColor: '#ebebeb'}}>
                           <p>Status</p>
                           <h3 style={{fontWeight: 'bold'}}>{this.state.selected.status}</h3>
    
                       </div>
    
                   </div>
                   
                    
    
                        <Paper elevation={2} style={{width: '90%', height: 'max-content', margin: '20px auto'}}>
    
                            <p style={{padding: 10, margin: 10, width: '80%', float: 'left', borderRadius: 10, backgroundColor: '#add8e6'}}>{this.state.selected.issue}</p>
                            {this.state.selected.response != "" && <p style={{padding: 10, margin: 10, width: '80%', float: 'right', borderRadius: 10, backgroundColor: '#add8e6'}}>{this.state.selected.response}</p>}
                            {this.state.selected.status == 'open' && <TextField variant="outlined" multiline fullWidth placeholder="Enter response here" onChange={(e) => this.setState({text: e.target.value})} />}
                            
    
                        </Paper>
                        
    
                    
                            <Button variant="contained" style={{margin: '10px 30px'}} onClick={()=> this.setState({dialog2: false})} >Cancel</Button>
                            {this.state.selected.status == 'open' && <Button color="primary" variant="contained" style={{margin: '10px 30px'}} onClick={()=> this.closeTicket()} >Close Ticket</Button>}
    
                           
                        
    
                        
                    </div>
    
                    </Dialog> */}
                </div>
            )

        }

        
    }
} 