import React, { useState, useEffect, useRef } from "react";
import { fire } from "../../config";
import "../../config";
import * as firebase from "firebase";
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
import { toast } from "react-toastify";
import { TextField, Button } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { Redirect } from "react-router-dom";
import logo from "../../Images/nuparla em1 .1.png";




function PayWithPayPal(props) {
  const items = [
    {
      name: "item",
      value: 19,
    },
  ];
  const total = 40;
  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);
  const paypalRef = useRef();
  const [pay, setPay] = useState('paypal')
  const [product] = React.useState({
    name: "NuParla Education Plan",
    price: 49.95,
    description: "NuParla education plan"
  });




  useEffect(() => {

    return toast("Success! Check email for details", { type: "success" });
  }, []);


  async function handleToken(token, addresses) {
    
      const response = await axios.post(
        "https://iqloud.herokuapp.com/checkout",
        { token, product }
      );
      const { status } = response.data;
      
      if (status === "success") {
        
        firebase
        .firestore()
        .collection("Users")
        .doc(props.location.state.email)
        .update({
          paid: true
        })
  
        setPaidFor(true);
  
      return (<Paper style={{margin: '60px auto', padding: 20, width: 400, textAlign: 'center' }} elevation={2}>
        <h3>Payment Successful!.</h3>
        <a href="/affiliate/login"><Button variant="contained" color="primary" >Login Now</Button></a>
        </Paper>)
      } else {
        return toast("Something went wrong", { type: "error" });
      }
    
    
  }

  useEffect(() => {
    try {
      window.paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: "NuParla Education Plan",
                amount: {
                  currency_code: "USD",
                  value: props.location.state.plan == 1 ? 399 : props.location.state.plan == 2 ? 249 : props.location.state.plan == 3 ? 199 : 399,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          setPaidFor(true);
          console.log("ORDER", order);
        },
        onError: (err) => {
          setError(err);
          console.error("ERROR", err);
        },
      })
      .render(paypalRef.current);
      
    } catch (error) {
      console.log(error)
      
    }
    
  }, [items]);

  if (paidFor) {
    firebase
      .firestore()
      .collection("Users")
      .doc(props.location.state.email)
      .update({
        paid: true
      })

    return (<Paper style={{margin: '60px auto', padding: 20, width: 400, textAlign: 'center' }} elevation={2}>
      <h3>Payment Successful!</h3>
      <a href="/affiliate/login"><Button variant="contained" color="primary" >Login Now</Button></a>
      </Paper>)
  }

  if (error) {
    return <div>Error in processing order. Please Retry again</div>;
  }
  
   

  if (props.location.state.payment == 'stripe'){
    return (
      <div style={{width: '100%',textAlign: 'center', padding: '20px 10vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.8)'}}>
        <img style={{maxWidth: 400, minWidth: 200, margin: '40px auto'}} src={logo}/>
        <Paper style={{margin: 'auto', padding: 20, maxWidth: 600, minWidth: 300, textAlign: 'center', backgroundColor:  '#edd290'}}>
        <h3>Account created, please proceed with the payment...</h3>
        <StripeCheckout
          stripeKey="pk_live_51HEgK8JH7jU7lhN8CbGNmBHDcawzSsP9PLEvEejnUKSwQBNsFiW743LVVlHAShU8Wenw70JGcA3v3gRdZJIAdgEj00W4X95Ep9"
          token={handleToken}
          amount={49.95 * 100}
          name="NuParla Education Plan"
        />

        
        
      <Button variant="contained" style={{position: 'fixed', margin: '20px auto', bottom: 5, right: '20px', backgroundColor: '#202020', color: '#edd290'}} onClick={() => localStorage.clear()}>Logout</Button>
      </Paper>

      </div>
      
      

    )
  } else if (props.location.state.payment == 'paypal'){
    return (
      <div style={{width: '100%',textAlign: 'center', padding: '20px 10vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.8)'}}>
        <img style={{maxWidth: 400, minWidth: 200, margin: '40px auto'}} src={logo}/>
        <Paper style={{margin: 'auto', padding: 20, maxWidth: 600, minWidth: 300, textAlign: 'center', backgroundColor:  '#edd290'}} >
        <h3>Account created, please proceed with the payment...</h3>
        <div
          style={{ width: "max-content", maxWidth: 600, margin: "20px auto" }}
          ref={paypalRef}
        />
        
        <Button variant="contained" style={{position: 'fixed', margin: '20px auto', bottom: 5, right: '20px', backgroundColor: '#202020', color: '#edd290'}} onClick={() => localStorage.clear()}>Logout</Button>

      </Paper>
      </div>
      
    );

  } else if (props.location.state.payment == 'btc'){
    return (
      <div style={{width: '100%',textAlign: 'center', padding: '20px 10vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.8)'}}>
        <img style={{maxWidth: 400, minWidth: 200, margin: '40px auto'}} src={logo}/>
        <Paper  style={{margin: 'auto', padding: 20, maxWidth: 600, minWidth: 300, textAlign: 'center', backgroundColor:  '#edd290'}}>
        <h3>Account created, please proceed with the payment...</h3>
        
        <a 
          
          href="https://commerce.coinbase.com/checkout/bfd901be-72d3-464b-abb1-acef22734cad">
          <Button variant="contained" style={{ margin: '20px auto', backgroundColor: '#202020', color: '#edd290'}} >Pay with Crypto</Button>
        </a>


      
        
        {/* <script src="https://commerce.coinbase.com/v1/checkout.js?version=201807">
        </script> */}
        
        <Button variant="contained" style={{position: 'fixed', margin: '20px auto', bottom: 5, right: '20px', backgroundColor: '#202020', color: '#edd290'}} onClick={() => localStorage.clear()}>Logout</Button>

      </Paper>
      </div>
      
  
    )
  }

  

}

export default PayWithPayPal;
