import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "../../../App.css";
import styled from "styled-components";
import { fire } from "../../../config";
import storage from "../../../config";
import "../../../config";
import * as firebase from "firebase";
import ReactPlayer from "react-player";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Input } from "antd";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import ReactAudioPlayer from 'react-audio-player';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import logo from '../../../Images/nuparla em1 .1 w.png'
import Dialog from '@material-ui/core/Dialog';
import { TextField } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import refreshIcon from '../../../Images/refresh.png'
import { makeStyles } from '@material-ui/core/styles';
import { Steps } from 'antd';
import MicRecorder from 'mic-recorder-to-mp3';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
const Mp3Recorder = new MicRecorder({ bitRate: 128 });


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const { Step } = Steps;

export default class level3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      dialog: false,
      exercise: [],
      index: 0,

      shuffled: [],
      ans: [],
      sentence: [],
      open: false,
      open2: false,


      level4: {
        lessons: []
      },
      selectedLesson: null,
      
      dialogVideo: false,
      dialogAudio: false,
      dialogExercise: false,
      dialogResponse: false,
      dialogDownload: false,

      Index: 0,
      qIndex: 0,

      user: {},

      recording: false,
      audio: '',
      isRecording: false,
      blobURL: '',
      recorded: false,
      response: '',

      progress: false,

      word1: '',
      word2: '',
      word3: '',
      word4: '',
      word5: '',
      word6: '',
      word7: '',

      shuffled: [],
      wrongQuestions: [],

      result: null,
      responseSent: false,

      audioStoryLink: '',
      audioStoryDialog: false

    };
  }

  componentDidMount() {
    firebase.firestore().collection('Course').doc('level4').get().then(res => {
  
 
      this.setState({level4: res.data()})

      // console.log(res.data())

      // console.log({
      //   data: {},
      //   lessons: [
      //     res.data().lessons[1]
      //   ]
      // })

      // firebase.firestore().collection('Course').doc('level3').set({
      //   data: {},
      //   lessons: [
      //     res.data().lessons[1]
      //   ]
      // })
      


    })

    // firebase.firestore().collection('Course').doc('level4').set()

    

    firebase.firestore().collection('Users').doc(localStorage.getItem('email')).onSnapshot(res => {
     
      this.setState({user: res.data()})
      console.log(res.data())

      // firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
      //   learningProgress: {
      //     level: 1,
      //     lesson: 1,
      //     step: 1
      //   }
      // })
    })


  }



  start = () => {
    this.setState({recording: true})
    
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true });
        }).catch((e) => console.error(e));
    
  };

  stop = () => {
    this.setState({recording: false})
    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob)
        console.log(blob)
        this.setState({ blobURL, blob: blob, isRecording: false, recorded: true });
      }).catch((e) => console.log(e));
  };

  shuffle(array){
    var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;

  }

  send = (lesson) => {
    this.setState({recorded: false,progress: true })

    if (this.state.blobURL != ''){

      let upload = storage
        .ref(`responses/${this.state.user.userName + lesson}`)
        .put(this.state.blob);
      upload.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref(`responses/${this.state.user.userName + lesson}`)
            .getDownloadURL()
            .then(async (url) => {
              firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
                Responses: firebase.firestore.FieldValue.arrayUnion({
                  level: 4,
                  lesson: lesson,
                  audio: url
                }) 
        
              }).then((res) => {
                this.setState({responseSent: true, progress: false})
              })
              

            
        })})
    }
  }


  sendResponse = (lesson) => {

    firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
      Responses: firebase.firestore.FieldValue.arrayUnion({
        level: 4,
        lesson: lesson,
        text: this.state.response
      }) 

    }).then((res) => {
      this.setState({responseSent: true})
    })
    

  }




   handleClick = () => {
    this.setState({open: true})
    };

   handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }

    // this.setState({open: false})
  };

  getCourses() {
    firebase
      .database()
      .ref("Courses")
      .child("level4")
      .once("value")
      .then((snapshot) => {
        this.setState({ courses: snapshot.val() });
      });
  }

   shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
    }

  updateProgress(lesson, step) {

    firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
        learningProgress: {
          level: 4,
          lesson: lesson,
          step: step
        }
      })
  }

  render() {

    if (this.state.user.learningProgress && this.state.user.learningProgress.level <= 3){
      return (
        <div>
          <p>This Level becomes active once you have finished the previous levels. </p>
        </div>
      )
    }else {
      return (
        <div style={{backgroundColor: '#ebebeb', padding: 20}}>
          <h1 style={{fontSize: 'x-large'}}>Level 4</h1>
 
          {this.state.level4.lessons.map((lesson,index) => {

            return (
              <div>
                {index == 0 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#1</span> Dialogue 4.0</h2>
                  
                </div>}

                {index == 2 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#2</span> Nature & Our Planet</h2>
                  
                </div>}

                {index == 5 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#3</span> Feelings & Love</h2>
                  
                </div>}

                {index == 8 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#4</span> Driving Around</h2>
                  
                </div>}

                {index == 12 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#5</span> Discipline</h2>
                  
                </div>}

                {index == 14 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#6</span> Job Interview</h2>
                  
                </div>}

                {index == 17 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#7</span> Business Basics</h2>
                  
                </div>}
              

                <div style={{cursor: 'pointer', backgroundColor: 'white', width: '90%', margin: '25px auto', borderRadius: '10px', boxShadow: '0px 2px 8px rgba(0,0,0,0.2)', marginBottom: lesson.data.title == 'Refresher Course & Grammar' ? 60 : 25}} >
                  <div style={{display: 'flex'}} onClick={() => {
                    if(this.state.selectedLesson == index){
                      this.setState({selectedLesson: null})
                    } else {
                      this.setState({selectedLesson: index})
                    }
                    
                  }}>
                    {lesson.data.title != 'Refresher Course & Grammar' && <img style={{width: 80, height: 80, margin: '10px'}} src={lesson.data.avatar} alt="avatar" />}
                    {lesson.data.title == 'Refresher Course & Grammar' && <img style={{width: 80, height: 80, margin: '10px', marginLeft: 40}} src={refreshIcon} alt="avatar" />}
                    {lesson.data.title != 'Refresher Course & Grammar' && <div style={{textAlign: 'left'}}>
                      <H3>Lesson {lesson.data.number}: {lesson.data.title}</H3>
                      <p style={{marginLeft: 93}}>{lesson.data.description}</p>
                    </div>}

                    {lesson.data.title == 'Refresher Course & Grammar' && <div style={{textAlign: 'left', margin: 'auto 30px'}}>
                      <h2 style={{fontSize: 'x-large'}}>{lesson.data.title} #{lesson.data.number}</h2>
                      
                    </div>}

                  </div>
                  {this.state.selectedLesson == index && <div style={{backgroundColor: '#fafafa', borderRadius: '0px 0px 10px 10px', width: '100%',padding: 10, borderTop: '1px solid #bebebe' }}>
                    <div style={{width: '80%', margin: '10px auto'}}>
                    <Steps current={this.state.user.learningProgress.lesson < index + 69 ? null : this.state.user.learningProgress.lesson > index + 69 ? 6 : this.state.user.learningProgress.step - 1 }>
                      <Step title="Step 1" description="Video" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 68){
                            this.setState({dialogVideo: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 68 && this.state.user.learningProgress.step -1 >= 0)
                          {
                            this.setState({dialogVideo: true})
                          }
                          
                        }}/>
                      {lesson.data.title != 'Refresher Course & Grammar' && <Step title="Step 2"  description="Audio" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 68){
                            this.setState({dialogAudio: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 68 && this.state.user.learningProgress.step -1 >= 1){
                            this.setState({dialogAudio: true})
                          }

                    
                        }}/>}
                      <Step title={lesson.data.title == 'Refresher Course & Grammar' ? "Step 2" : "Step 3"} description="Exercise" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 69){
                            this.setState({dialogExercise: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 69 && this.state.user.learningProgress.step -1 >= 2){
                            this.setState({dialogExercise: true})
                          
                          }
                        
                        }}/>
                      {lesson.data.title != 'Refresher Course & Grammar' && <Step title="Step 4" description="Track" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 69){
                            this.setState({dialogResponse: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 69 && this.state.user.learningProgress.step -1 >= 3){
                            this.setState({dialogResponse: true})
                          }
                        
                        }}/>}
                      {lesson.data.title != 'Refresher Course & Grammar' && <Step title="Step 5" description="Download" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 69){
                            this.setState({dialogDownload: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 69 && this.state.user.learningProgress.step -1 >= 4){
                            this.setState({dialogDownload: true})

                          }
                    
                        }}/>}
                    </Steps>
                    </div>







                    <Dialog
                    open={this.state.dialogVideo}
                    onClose={() => this.setState({dialogVideo: false})}
                    fullScreen
                    >
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 20, right: 20}} onClick={() => this.setState({dialogVideo: false})} />
                      <img src={logo} style={{width: 200}} />
                      <h1 style={{textAlign: 'center', fontSize: 'x-large'}}>Step 1. VIDEO LESSON</h1>

                      <Paper elevation={3}  style={{padding: 10,textAlign: 'center', margin: '30px auto',}}>
                  
                        
                        <ReactPlayer
                          
                            style={{ margin: "10px auto 10px auto", border: '1px solid #bebebe' }}
                            height={window.innerWidth >= 800 ? '420px' : window.innerWidth >= 500 ? '280px' : '200px'}
                            width={window.innerWidth >= 800 ? '740px' :window.innerWidth >= 500 ? '480px' : '360px'}
                            controls={true}
                            playing={true}
                            
                            // url={lesson.data.video}
                            url={lesson.data.video}
                            
                          />

                                
                                

                      </Paper>


                      <button style={{fontSize: 'large', border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.user.learningProgress.level == 4 && this.state.user.learningProgress.lesson == index + 69 && this.state.user.learningProgress.step == 1 && lesson.data.title != 'Refresher Course & Grammar'){
                          this.updateProgress(index + 69, 2)
                        }
                        if (this.state.user.learningProgress.level == 4 && this.state.user.learningProgress.lesson == index + 69 && this.state.user.learningProgress.step == 1 && lesson.data.title == 'Refresher Course & Grammar'){
                          this.updateProgress(index + 69, 3)
                        }
                        if (lesson.data.title != 'Refresher Course & Grammar'){
                          this.setState({dialogVideo: false, dialogAudio: true})
                        } else  {
                          this.setState({dialogVideo: false, dialogExercise: true})

                        }
                        
                      }} >Continue</button>
                      <div>

                      </div>
                    </Dialog>








                    <Dialog
                    open={this.state.dialogAudio}
                    onClose={() => this.setState({dialogAudio: false})}
                    fullScreen
                    >
                      <img src={logo} style={{width: 200, marginTop: -20}} />
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 20, right: 20}} onClick={() => this.setState({dialogAudio: false})} />
                      <h1 style={{textAlign: 'center',fontSize: 'x-large'}}>Step 2. AUDIO STORY LESSON</h1>

                      <Paper elevation={3} style={{padding: 10,textAlign: 'center', margin: '30px auto',}}>
                      
                        
                        <audio src={lesson.data.audioStory} style={{padding: 10 }} controls="false" controlsList="nodownload"/>


                      </Paper>

                      <Dialog 
                      open={this.state.audioStoryDialog}
                      onClose={() => this.setState({audioStoryDialog: false})}
                      >
                        <ReactPlayer
                          
                          style={{ margin: "auto", border: '1px solid #bebebe' }}
                          height='240px'
                          width='400px'
                          controls={true}
                          playing={true}
                          
                          // url={lesson.data.video}
                          url={this.state.audioStoryLink}
                          
                        />
                        

                      </Dialog>

                      


                      {lesson.data.number == 55 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 55, titled: You know what? He is not here.<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/> 
                        
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498770640"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        <br/>
                        In this episode, Laurel and Hardy write a note renouncing the Foreign Legion to the commander. <br/>
                        <br/>
                        Laurel and Hardy barge in the commodore’s office.<br/>
                        <br/>
                        “Say, what’s the big idea keeping us waiting!” shouts Hardy. But nobody is inside the commander’s office. <br/>
                        “You know what?” asks Laurel.<br/>
                        “What?”<br/>
                        “He’s not here.”<br/>
                        “Well, of course he’s not here!”<br/>
                        “Let’s look for him,” says Laurel.<br/>
                        “Look nothing. I’ll leave him a note.”<br/>
                        “I wouldn’t leave him a note. Just leave him a PS. That’s good enough for him,” says Laurel.<br/>
                        <br/>
                        Hardy write the commander a note. “Now he can put that in his pipe and smoke it,” quips Hardy.<br/>
                        “Maybe he doesn’t smoke a pipe?”<br/>
                        “Well, whatever he smokes, I guess.” <br/>
                        <br/>
                        Hardy finds cigars and takes a few for the road. Laurel takes the rest. As they walk outside, they just miss the commander and his soldiers. <br/>
                        <br/>
                        “What did you say in the note, Ollie?”<br/>
                        “I said plenty, and I could’ve  told him a lot more!”<br/>
                        “That will learn him…three cents a day…”<br/>
                        “Right,” says Hardy.<br/>
                        <br/>
                        Laurel and Hardy walk away.<br/>
                          </p>
                      </Paper>}


                      {lesson.data.number == 56 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 56, titled: Wait till I get my hands on them!<br/>
                        <br/>

                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498770255"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                         <br/>

                        In this episode, Laurel and Hardy sing and dance for the troops. <br/>
                        <br/>
                        The commander and his soldiers barely miss Laurel and Hardy. He enters in his office and reads the note Hardy left him.<br/>
                        <br/>
                        “Never during my army career have I been so grossly insulted,” screams the commander. “Just wait till I get my hands on them!”<br/>
                        <br/>
                        He barges out of his office and marches with his men in search of Laurel and Hardy.<br/>
                        <br/>
                        The boys find a band. As they start playing a tune they know, they ready themselves for a brief show. Laurel dances while Harvey sings: <br/>
                        <br/>
                        “Shine on, shine on harvest moon up in the sky. I ain’t had no lovin' since January, February, June or July. It’s no time, ain’t no time to stay outdoors and spoon, so shine on, shine on harvest moon, for me and my gal.”<br/>
                        <br/>
                        Then he joins Laurel in the dance while the band plays. As they finish, they say good bye to everyone. <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 57 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 57, titled: Now I’m so happy!<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498770017"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                         <br/>
                        In this episode, Georgette arrives to visit her husband, Francois. <br/>
                        <br/>
                        The boys are on their way out of the Foreign Legion headquarters, unaware that the commander is seeking after them. <br/>
                        <br/>
                        “I wonder what time the plane leaves to Paris,” asks Laurel.<br/>
                        “The sooner the better for me,” says Hardy. He asks for directions. “Say, which way to the depot?”<br/>
                        “Just around the corner,” exclaims a soldier. <br/>
                        <br/>
                        As they leave the gate unseen by anyone, we here the commander saying: “Let no one pass through that gate no matter what happens.” But Laurel and Hardy leave just as they are closing the gates. <br/>
                        <br/>
                        “Say, we are not going to take a plane, are we?” asks Laurel.<br/>
                        “I should say not,” answers Hardy. They look at three planes flying in formation. <br/>
                        “I wonder what keeps them up?” asks Laurel.<br/>
                        “I don’t know, but I know what keeps me down,” Hardy steps on the ground. <br/>
                        “Me too. Good old terra cotta for me, anytime,” says Laurel.<br/>
                        <br/>
                        Suddenly a plane lands. Georgette steps out. Francois is there to kiss her. <br/>
                        <br/>
                        “Francois!”<br/>
                        “Georgette, darling!” They kiss. <br/>
                        “Now I’m so happy. At last I am here with you. Have you missed me?” sighs Georgette.<br/>
                        “Every day has been a year, darling. I’ll bring the car for you. You wait here, just a moment.”<br/>
                        <br/>
                        Then, Laurel and Hardy appear…<br/>
                        </p>
                      </Paper>}

                      {lesson.data.number == 58 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 58, titled: This lady happens to be my wife!<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>

                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498769663"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                         <br/>
                        In this episode, Hady finds Georgette, and her husband. <br/>
                        <br/>
                        Laurel and Hardy approach a plane to ask a person a question. <br/>
                        <br/>
                        “Could you tell us the way to the railroad depot?” asks Hardy.<br/>
                        <br/>
                        Georgette turns around. <br/>
                        <br/>
                        “Mr. Hardy!”<br/>
                        “Georgette!” Hardy drops his luggage. “So you changed your mind! Darling, I forgive you everything!”<br/>
                        “Say, I thought you said you’ve forgotten her,” interrupts Laurel.<br/>
                        “Don’t be silly. How can I forget my little Georgette.” <br/>
                        <br/>
                        Hardy asks Laurel to leave so he can kiss Georgette. He then starts kissing her, but she is struggling asking him not to do it. <br/>
                        <br/>
                        Suddenly, Francois arrives in the car and sees Hardy kissing his wife. He pulls Hardy from Georgette. <br/>
                        <br/>
                        “What’s the big idea?” asks Hardy.<br/>
                        “The idea is that this lady happens to be my wife!” <br/>
                        “Oh, is that so? Your wife?”<br/>
                        “Yes, you would-be homewrecker!” <br/>
                        “Francois, please don’t hit him!”<br/>
                        “Kindly step into the car, my dear, I want to speak to this gentleman alone.” She leaves. “Now, once more, I am going to give you a piece of advice: keep away from my wife. If I ever see or hear of you being in her presence again, I’ll see to it that your worthless carcass lies sizzling in the desert sun at the mercy of the vultures. Do you understand?” shouts Francois. <br/>
                        “Yes, sir,” says Hardy and Laurel. <br/>
                        “Hey mister, you don’t have to worry about us. We are leaving right now. Aren’t we Ollie?”<br/>
                        “We certainly are! Yes, sir!” shouts Hardy.<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 59 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 59, titled: You are under arrest for desertion!<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498769424"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                         <br/>
                        In this episode, Laurel and Hardy are arrested for desertion. <br/>
                        <br/>
                        The commander and his soldiers find Laurel and Hardy outside the plane. He commands:<br/>
                        <br/>
                        “Fall in!”<br/>
                        “Who is he talking to?” asks Laurel<br/>
                        “Fall in!”<br/>
                        “What do you mean: ‘Fall in,’ We are through. Didn’t you get our note?” asks Hardy.<br/>
                        “Yes, I got your note, and when you signed it, you signed your own death warrant,” barks the commander. “You’re under arrest for desertion!”<br/>
                        <br/>
                        The soldiers take the men away. <br/>
                        <br/>
                        Now we see Laurel and Hardy in prisoner garbs, entering prison. The soldiers present them to the prison guard. <br/>
                        <br/>
                        “Two deserters,” shouts a soldier. <br/>
                        “Deserters, eh? Come one, I’ll show you to your suite.” He shoves Hardy in the jail cell, but forgets to throw Laurel inside. Laurel sits on the guard’s desk.<br/>
                        <br/>
                        “But, what did we do?” asks Laurel.<br/>
                        “What did you do? Why you…” the guard realizes he’s talking to the deserter. He throws Laurel inside the same jail cell Hardy is in.<br/>
                        “Hey mister, you forgot your keys. Look!” Laurel points to the keys the guard left inside the lock. <br/>
                        <br/>
                        Hardy hits Laurel. “Why don’t you keep your mouth shut!”<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 60 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 60, titled: The prisoners will be shot at sunrise.<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         h
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498769237"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                         <br/>
                        In this episode, the commander sentences Laurel and Hardy to be shot at sunrise.<br/>
                        <br/>
                        The commander and his generals take a vote. Laurel and Hardy will be shot at sunrise. <br/>
                        <br/>
                        “The prisoners have been found guilty, and will be shot at sunrise,” says the commander. <br/>
                        <br/>
                        Laurel and Hardy are alone in their cell.<br/>
                        <br/>
                        “That’s a nice pickle we’re in: shot at sunrise,” says Hardy.<br/>
                        “I hope it’s cloudy tomorrow,” says Laurel. Hardy just stares at him. “Say Ollie, do you still want to come back as a horse?”<br/>
                        “I don’t care if I don’t ever come back,” says Hardy.<br/>
                        <br/>
                        Laurel plays a harp for Hardy. He drops it on his head.<br/>
                        <br/>
                        The guard shouts: “Shut up! Go to sleep!”<br/>
                        <br/>
                        Laurel takes the harp from off Hardy’s head.<br/>
                        <br/>
                        “Put that thing down!” exclaims Hardy.<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 61 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 61, titled: We are making our escape!<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498769036"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                         <br/>
                        In this episode, Laurel and Hardy escape from prison. <br/>
                        <br/>
                        Somebody throws a note inside Laurel and Hardy’s jail cell. <br/>
                        <br/>
                        The note reads:<br/>
                        <br/>
                        “Lift the floor board and you’ll find a tunnel which leads to the outer wall. Use your own judgement. A pal.” <br/>
                        <br/>
                        Hardy opens the floor board, and there’s a tunnel there! <br/>
                        <br/>
                        He instructs Laurel to go inside. <br/>
                        <br/>
                        “Where are we going?” asks Laurel from inside the tunnel. <br/>
                        “This tunnel leads to the outside wall. We are making our escape!” says Hardy.<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 62 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 62, titled: Will you hurry? This is our last chance!<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498768916"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                         <br/>
                        In this episode, Laurel and Hardy continue their escape. <br/>
                        <br/>
                        Laurel and Hardy are inside a tunnel that leads to the outer wall of the prison.<br/>
                        <br/>
                        “We are making our escape,” whispers Hardy.<br/>
                        “Are we allowed to do that?” asks Laurel.  Hardy slaps him on his butt.<br/>
                        “Go ahead!” shouts Hardy.<br/>
                        <br/>
                        The guys continue in the tunnel.<br/>
                        <br/>
                        “Isn’t this fun?” asks Laurel.<br/>
                        “It sure is. I’m dying with laughter,” cries Hardy sarcastically. <br/>
                        <br/>
                        He spanks Laurel. Laurel hits Hardy back. They start a fight, and that makes the tunnel to start caving in.<br/>
                        <br/>
                        “See what you’ve done? Now we’ve got to dig our way out…Go back to the cell and get those tin plates,” says Hardy.<br/>
                        “I don’t want to go back to the cell,” says Laurel.<br/>
                        “Will you hurry? This is our last chance!” cries Hardy.<br/>
                        “Why do I always have to do everything? It’s always me, me, me. Never you…” <br/>
                        <br/>
                        Laurel crawls over Hardy with much effort.<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 63 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 63, titled: They escaped!<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498768710"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                         <br/>
                        In this episode, the commander discovers the prisoners have escaped. <br/>
                        <br/>
                        It’s sunrise. Soldiers enter the jail and find the guard. <br/>
                        <br/>
                        “Bring the prisoners!” commands a soldier. <br/>
                        “Yes, sir,” obeys the guard. <br/>
                        <br/>
                        He enters the cell. “Okay, come on, you. The sun is up!” <br/>
                        <br/>
                        He discovers they are not in their cell.  The guard runs to the soldiers. <br/>
                        <br/>
                        “They escaped!” shouts the guard. <br/>
                        <br/>
                        A soldier heads to the phone. “Give me the commander!”<br/>
                        <br/>
                        The commander picks up the phone. <br/>
                        <br/>
                        “Sir, the prisoners have escaped!”<br/>
                        “What? The prisoners have escaped? Then find them!” shouts the commander. <br/>
                        “Yes, sir!”<br/>
                        <br/>
                        The commander talks to his soldiers. <br/>
                        <br/>
                        “I want both those prisoners brought  dead or alive. Call out the entire post, if necessary!” <br/>
                        <br/>
                        Meanwhile, Laurel and Hardy continue digging their way out of the tunnel. <br/>
                        <br/>
                        “I wonder what time it is?” asks Laurel.<br/>
                        “How do I know?”<br/>
                        “We should be beyond the wall by now,” says Laurel.<br/>
                        “I guess you’re right. We better start digging up.”<br/>
                        “That’s a good idea.”<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 64 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 64, titled At a time like this, you had to do that!<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498768359"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                         <br/>
                        In this episode, Laurel and Hardy leave the tunnel and hide in a closet. <br/>
                        <br/>
                        Laurel and Hardy exit the tunnel and find themselves inside a liquor chamber. <br/>
                        <br/>
                        “I wonder where we are?” asks Laurel.<br/>
                        “Maybe some salon outside the fort,” replies Hardy. “See if the coast is clear.”<br/>
                        <br/>
                        Laurel and Hardy enter a room. As they seek to open the door to leave, they hear a knock at the door.<br/>
                        <br/>
                        “Georgette, may I come in?” It’s Francois!<br/>
                        <br/>
                        Laurel and Hardy run to a closet and hide there. Francois enters the room.<br/>
                        <br/>
                        “Georgette, are you all right?” He hears her answer that all is well from inside the restroom.  He leaves, suspicious after hearing sounds in the room. <br/>
                        <br/>
                        Suddenly, Georgette comes out of the restroom. Heads to a table to put on perfume. Then, Laurel sneezes from inside the closet, and the closet falls down to the ground, making Georgette to scream and faint. <br/>
                        <br/>
                        Like the Phoenix rising out of the ashes, Laurel and Hardy break through the fallen closet and take off articles of woman’s clothes from themselves. <br/>
                        <br/>
                        “At a time like this,” cries Hardy, “you had to do that!” Hardy slaps Laurel. <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 65 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 65, titled: What is the meaning of this?<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498767871"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                         <br/>
                        In this episode, Laurel and Hardy are sought after Francois and the rest of the soldiers. <br/>
                        <br/>
                        Laurel and Hardy see Georgette fainted on the floor. <br/>
                        <br/>
                        “Quick,” exclaims Hardy, “help me get her on the bed!”<br/>
                        <br/>
                        They place Georgette on the bed. <br/>
                        <br/>
                        “Quick, get me a glass of water,” says Hardy. Laurel runs outside the room to fetch some water. <br/>
                        <br/>
                        Hardy takes Georgette by the hand. Francois enters the room and sees Hardy kissing Georgette’s hand. Laurel pushes Francois out of the way and pours water on the sleeping Georgette, waking her up. <br/>
                        <br/>
                        “Don’t do that!” says Hardy.<br/>
                        “What is the meaning of this?” asks Francois. <br/>
                        “What do we say?” asks Laurel.<br/>
                        “You think up your story, I’ll think up mine!” says Hardy.<br/>
                        <br/>
                        Both men run out of the room through the window. Francois exits the room and gathers the soldiers. <br/>
                        <br/>
                        “Pay attention all of you! The men that were to be shot this morning escaped. Make sure all gates are closed and make an entire inspection of the entire barracks. Whoever brings them back will be given a reward and six-weeks furlough. That’s all!”<br/>
                        <br/>
                        The soldiers scramble to find Laurel and Hardy, but they are hiding a top of a tree. <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 66 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 66, titled: Let us cover up the hole.<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate): <br/>
                        
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498767588"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        <br/>
                        In this episode, Laurel and Hardy try escaping and find themselves in the same liquor depot from where they started their escape. <br/>
                        <br/>
                        Laurel and Hardy escape the soldiers’ search efforts by hiding inside the tunics of two Arab me as they walk across the barracks. <br/>
                        <br/>
                        Suddenly, they trip and are discovered by the soldiers. They run for their lives. <br/>
                        <br/>
                        Meanwhile, the guard comes back inside their jail cell, and discovers the hole they escaped from. He send soldiers inside the tunnel. <br/>
                        <br/>
                        Laurel and Hardy find themselves back where they started: inside the liquor depot of a saloon. As soldiers start coming out of the hole, they open wine bottles and hit each soldier with the corks. <br/>
                        <br/>
                        “Let’s cover up the hole!” exclaims Hardy. They pour a barrel of wine down the hole, and the soldiers drink the whole lot of it. <br/>
                        <br/>
                        Laurel and Hardy exit the liquor depot. <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 67 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 67, titled: Hangar No. 4.<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498767430"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                         <br/>
                        In this episode, Laurel and Hardy escape to an airplane hangar as they are persecuted by the soldiers of the Foreign Legion. <br/>
                        <br/>
                        The soldiers that were in the tunnel under the jail cell come out drunk one by one, to the astonishment of the jail guard. <br/>
                        <br/>
                        Some men actually salute the guard, skunk drunk.<br/>
                        <br/>
                        Meanwhile, Laurel and Hardy run inside an airplane hangar, but are spotted by a soldier. He shoots his rifle to the air, calling the attention of other soldiers, and everyone now runs after Laurel and Hardy.<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 68 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 68, titled: Good. Now let’s get out of here!<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498767203"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                         <br/>
                        In this episode, Laurel and Hardy hide inside a plane from their persecutors. <br/>
                        <br/>
                        Laurel and Hardy run into Hangar number 4, followed by a platoon of Foreign Legion soldiers. <br/>
                        <br/>
                        The soldiers look for the pair, but to no avail. They are hiding inside an airplane. <br/>
                        <br/>
                        “Hey,” whispers Hardy to Laurel, “see if they’re gone.”  Laurel raises his head, and sees the soldiers running away. <br/>
                        “Yeah, we’ve given them the slip.”<br/>
                        “Good, now let’s get out of here.”<br/>
                        <br/>
                        On his way out, Laurel accidently turns the airplane on, and they are forced to get away using the vessel. <br/>
                        <br/>
                        The throng of soldiers chase after the stolen airplane. <br/>
                        <br/>
                        Laurel and Hardy are pulling away from the throng. <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 69 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 69, titled: The flying deuces. <br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498766854"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                         <br/>
                        In this episode, Laurel and Hardy attempt their escape from the Foreign Legion by the use of a stolen airplane. Just one problem: they don’t know how to fly it.<br/>
                        <br/>
                        The plane bumps up and down while taking off. It is obvious both men don’t know how to fly a plane. <br/>
                        <br/>
                        The plane finally takes off, erratically. <br/>
                        <br/>
                        Laurel is afraid and hugs Hardy, who’s trying his best to fly the plane. <br/>
                        <br/>
                        The plane dives toward the soldiers, and barely flies over them. Then it barely misses the control tower. <br/>
                        <br/>
                        Laurel and Hardy are afraid and don’t know what they’re doing. <br/>
                        <br/>
                        Suddenly, Hardy breaks the maneuvering wheel and falls to the back of the plane. Laurel is left alone to steer it. <br/>
                        <br/>
                        The plane nose-dives again, sending Hardy to the front of the plane, wrapped in a parachute. <br/>
                        <br/>
                        Laurel takes the parachute off Hardy and throws it away, leaving the men without parachutes. <br/>
                        <br/>
                        Hardy falls through a hole, and Laurel tries pulling him out, while the plane navigates by itself erratically in the air…<br/>
                        </p>
                      </Paper>}




                      {lesson.data.number == 70 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 70, titled: Ollie, is that really you?<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498766487"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                         <br/>
                        In this last episode, the airplane crashes with Laurel and Hardy in it, and Hardy’s wish to come back in reincarnation as a horse becomes a reality. <br/>
                        <br/>
                        Laurel and Hardy lose all control of the plane after their maneuvering wheels break off. <br/>
                        <br/>
                        The plane spirals out of control, as we see both men hugging each other, going in circles.<br/>
                        <br/>
                        The plane nose-dives for the last time. <br/>
                        <br/>
                        It crashes on the ground. Silence.<br/>
                        <br/>
                        Suddenly, Laurel comes out of the wreckage. He cleans himself as he usually does. <br/>
                        <br/>
                        Hardy, however, appears as a sprit with wings, levitating out of the wreckage, symbolizing he has died in the crash and is going to heaven.<br/>
                        <br/>
                        As he rises to the sky, he waives goodbye at Laurel.<br/>
                        <br/>
                        Laurel doesn’t know what just happened.<br/>
                        <br/>
                        Then we see Laurel sometime later, walking down a dusty road. <br/>
                        <br/>
                        Suddenly, he hears Hardy.<br/>
                        <br/>
                        “Hey, Stan. Look!”  He turns around. “I’m over here!”  Laurel sees a horse with Hardy’s mustache and hat.<br/>
                        <br/>
                        “Well, here’s another nice mess you’ve gotten me into,” says the horse. <br/>
                        “Ollie, is that really you?”<br/>
                        “Well, of course it’s me.”<br/>
                        “Gee, I’m glad to see you.”<br/>
                        <br/>
                        Laurel hugs the reincarnated horse. <br/>
                        <br/>
                        The End.<br/>
                        </p>
                      </Paper>}




                      <button style={{fontSize: 'large', border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.user.learningProgress.level == 4 && this.state.user.learningProgress.lesson == index + 69 && this.state.user.learningProgress.step == 2){
                          this.updateProgress(index + 69, 3)
                        }
                        this.setState({dialogAudio: false, dialogExercise: true})
                      }} >Continue</button>
                      <div>

                      </div>
                    </Dialog>










                    <Dialog
                      open={this.state.dialogExercise}
                      onClose={() => this.setState({dialogExercise: false})}
                      fullScreen
                    >
                      <div style={{width: '100%', height: '100%', backgroundColor: '#eff7fa'}}>
                      <img src={logo} style={{width: 200, marginTop: -20}} />
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 20, right: 20}} onClick={() => this.setState({dialogExercise: false})} />
                      {lesson.data.title == 'Refresher Course & Grammar' ? <h1 style={{textAlign: 'center',fontSize: 'x-large'}}>Step 2. EXCERCISES:</h1> :<h1 style={{textAlign: 'center',fontSize: 'x-large'}}>Step 3. EXCERCISES:</h1> }
                      <p style={{width: '100%', textAlign: 'center'}}>Don’t forget to use Reverso Context if you don’t understand a word or question.</p>
                      <Paper style={{width: '90%', margin: '30px auto', textAlign: 'center', padding: 10}}>
                        {this.state.Index >= lesson.questions.length ? <div style={{textAlign: 'center', width: '90%', margin: 'auto'}}>
                                <H3>Your Score:</H3>
                                <H3 style={{fontSize: 'x-large', fontWeight: 'bold'}}>{lesson.questions.length - this.state.wrongQuestions.length}/{lesson.questions.length}</H3>
                                {((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) == 1 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>Awesome!</H3> : ((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) >= 0.9 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>Great!</H3> : ((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) >= 0.8 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>Good Job!</H3> : ((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) >= 0.7 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>Getting Close!</H3> : ((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) >= 0.6 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>We've got work to do</H3> : <H3>That's why we're here. Let's do it again!</H3>}
                                {((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) < 0.5 && <Button onClick={() => {
                                  this.setState({word1: '', word2: '', word3: '', word4: '',word5: '', word6: '', word7: '', result: null, Index: 0, wrongQuestions: []})
                                }} variant="contained" color="primary">
                                  Repeat Exercise
                                </Button>}
                              </div> : ''
                            
                            
                              
                          

                        }
                        
                        {lesson.questions.map((question,qIndex) => {
                          
                          if (qIndex == this.state.Index){
                           
                            if (question.type == 'ftb'){
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>Fill in the blank</h2>
                                  <div style={{display: 'flex', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, flexWrap: 'wrap', margin: '10px auto', width: '90%', justifyContent: 'center'}}>
                                  {question.question && question.question.split(' ').map((word, index) => {
                                    if (word == '__1__'){
                                      
                                      return (
                                        <TextField style={{width: 'max-content', minWidth: 100, marginTop: 5}} value={this.state.word1} onChange={( e) => {this.setState({word1: e.target.value}) }} />
                                      )
                                    }else {
                                      return (
                                        <H3 >{word}</H3>
                                        
                                      )

                                    }
                                    
                                  })

                                  }

                                  </div>
                                  {this.state.result == null && <Button variant="contained" style={{backgroundColor: '#ebebeb'}} onClick={() => {
                                    if(this.state.word1.split(" ")[0].toLowerCase() == question.ans.toLowerCase()){
                                      this.setState({result: true})
                                    } else {
                                      console.log(this.state.word1.split(" ")[0].toLowerCase())
                                      this.setState({result: false})
                                    }
                                  }}>Check</Button>}
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}
                                  <div style={{display: 'flex'}}>

                                    {this.state.result == false && <div style={{display: 'flex'}}>
                                      <H3 >Correct Answer: <span style={{color: '#000080'}}>{question.ans}</span></H3>
                                    </div>

                                    }
                                    
                                    {this.state.result != null && <div style={{width: '100%', textAlign: 'right', margin: 'auto'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                                  </div>
                                </div>

                              )
                            } else if (question.type == 'mcq'){
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>Multiple choice</h2>
                                  <div style={{display: 'flex', flexWrap: 'wrap', width: '90%', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, margin: '10px auto', justifyContent: 'center'}}>
                                  {question.question && question.question.split(' ').map((word, index) => {
                                    if (word == '____'){
                                      
                                      return (
                                        <H3 style={{backgroundColor: this.state.word1 == ''? '#bebebe' : this.state.word1 == question.ans ? '#008000' : '#c91b1b', color: 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0}}>{this.state.word1}</H3>
                                      )
                                    }else {
                                      return (
                                        <H3>{word}</H3>
                                        
                                      )

                                    }
                                    
                                  })

                                  }

                                  </div>
                                  <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                                  {question.options.map(option => {
                                    return (
                                      <div style={{margin: 10, cursor: 'pointer', padding: 2, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={() => {
                                        this.setState({word1: option})
                                        if (option == question.ans){
                                          this.setState({result: true})
                                        } else {
                                          this.setState({result: false})

                                        }
                                      }}>
                                        <H3>{option}</H3>

                                      </div>
                                    )
                                  })

                                  }

                                  </div>
                                  
                                  
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}

                                  <div style={{display: 'flex'}}>
                                  {this.state.result == false && <div style={{display: 'flex'}}>
                                      <H3 >Correct Answer: <span style={{color: '#000080'}}>{question.ans}</span></H3>
                                    </div>

                                    }
                               
                                  {this.state.result != null && <div style={{width: '100%', textAlign: 'right'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                              </div>
                                </div>

                              )
                            } else if (question.type == 'co'){
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>Correct order</h2>
                                  
                                  <div style={{display: 'flex', flexWrap: 'wrap', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, width: '90%',margin: '10px auto', justifyContent: 'center'}}>
                                  {question.question && question.question.split(' ').map((word, index) => {
                                    if (word == '__1__'){
                                      
                                      return (
                                        <H3 style={{backgroundColor: this.state.word1 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word1 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word1}</H3>

                                      )
                                    }else if (word == '__2__'){
                                      return (
                                        <H3 style={{backgroundColor: this.state.word2 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word2 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word2}</H3>

                                      )

                                    }else if (word == '__3__'){
                                      return (
                                        <H3 style={{backgroundColor: this.state.word3 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word3 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word3}</H3>

                                      )

                                    }else if (word == '__4__'){
                                      return (
                                        <H3 style={{backgroundColor: this.state.word4 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word4 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word4}</H3>

                                      )

                                    }else if (word == '__5__'){
                                        return (
                                          <H3 style={{backgroundColor: this.state.word4 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word4 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word5}</H3>
  
                                        )
  
                                    }else if (word == '__6__'){
                                        return (
                                          <H3 style={{backgroundColor: this.state.word4 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word4 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word6}</H3>
  
                                        )
  
                                      }else {
                                      return (
                                        <H3>{word}</H3>
                                        
                                      )

                                    }
                                    
                                  })

                                  }

                                  </div>
                                  <div style={{display: 'flex', flexWrap: 'wrap', width: '90%', margin: 'auto', justifyContent: 'center'}}>
                                  {question.options.map(option => {
                                    return (
                                      <div style={{margin: 10, cursor: 'pointer', padding: 2, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={() => {
                                       
                                        if (this.state.word1 == ''){
                                          this.setState({word1: option})
                                        }else if (this.state.word2 == ''){
                                          this.setState({word2: option})

                                          if (question.ans.split(' ').length == 2 && this.state.word1 != ''){
                                          

                                            if (question.ans == this.state.word1 + ' ' + option){
                                              this.setState({result: true})
                                            }else {
                                              this.setState({result: false})
                                            }
                                          }

                                        }else if (this.state.word3 == '' && this.state.word2 != ''){
                                          this.setState({word3: option})
                                          console.log(question.ans)
                                          console.log(question.ans.split(' ').length)
                                          if (question.ans.split(' ').length == 3){
                                          
                                            if (question.ans == this.state.word1 + ' ' + this.state.word2 + ' ' + option){
                                              this.setState({result: true})
                                            } else {
                                              this.setState({result: false})
                                            }
                                          }
                                        }else if (this.state.word4 == '' && this.state.word3 != ''){
                                          this.setState({word4: option})
                                          if (question.ans.split(' ').length == 4){
                                            if (question.ans == this.state.word1 + ' ' + this.state.word2 + ' ' + this.state.word3 + ' ' + option){
                                              this.setState({result: true})
                                            }else {
                                              this.setState({result: false})
                                            }
                                          }
                                        }
                                        
                                      }}>
                                        <H3>{option}</H3>

                                      </div>
                                    )
                                  })

                                  }

                                  </div>
                                  
                                  
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}

                                  <div style={{display: 'flex'}}>
                                  {this.state.result == false && <div>
                                      <H3 style={{width: 200}}>Correct Answer:</H3>
                                      <div style={{display: 'flex'}}>
                                      {question.ans.split(' ').map(word => {
                                        return (
                                          <h4 style={{ minWidth: 50, margin: 10, padding: 5, backgroundColor: '#ebebeb', borderRadius: 5}}>{word}</h4>
                                        )
                                      })

                                      }

                                      </div>
                                      
                                    </div>

                                    }
                               
                                  {this.state.result != null && <div style={{width: '100%', textAlign: 'right'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                              </div>
                                </div>

                              )
                            }else if (question.type == 'tf'){
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>True or False</h2>
                                  
                                  <div style={{display: 'flex', flexWrap: 'wrap', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, width: '90%',margin: '10px auto', justifyContent: 'center'}}>
                                  {question.question && question.question.split(' ').map((word, index) => {
                                    if (word == '____'){
                                      
                                      return (
                                        <TextField variant="outlined" value={this.state.word1} onChange={( e) => {this.setState({word1: e.target.value}) }} />
                                      )
                                    }else {
                                      return (
                                        <H3>{word}</H3>
                                        
                                      )

                                    }
                                    
                                  })

                                  }

                                  </div>
                                  <div style={{display: 'flex', width: '90%',margin: 'auto', justifyContent: 'center'}}>
                                  <H3 style={{margin: 10, cursor: 'pointer', padding: 8, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={()=> {
                                    if (question.ans == true){
                                      this.setState({result: true})
                                    } else {
                                      this.setState({result: false})

                                    }
                                  }}>True</H3>
                                  <H3 style={{margin: 10, cursor: 'pointer', padding: 8, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={()=> {
                                    if (question.ans == false){
                                      this.setState({result: true})
                                    } else {
                                      this.setState({result: false})

                                    }
                                  }}>False</H3>

                                  </div>
                                  
                                  
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}

                                  <div style={{display: 'flex'}}>

                                  {this.state.result == false && <div style={{display: 'flex'}}>
                                      <H3 >Correct Answer: <span style={{color: '#000080'}}>{question.ans == true ? 'True' : 'False'}</span></H3>
                                    </div>

                                    }
                               
                                  {this.state.result != null && <div style={{width: '100%', textAlign: 'right'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                              </div>
                                </div>

                              )
                            }else if (question.type == 'match'){
                              
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>Match the following</h2>
                                  
                                  <div style={{display: 'flex', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, flexWrap: 'wrap', width: '90%',margin: '10px auto', justifyContent: 'center'}}>
                                  {question.question.map((word, index) => {
                                    return (
                                      <div>
                                        <H3 style={{backgroundColor: '#bebebe', borderRadius: '5px 5px 0px 0px', padding: 10, marginBottom: 1}}>{word}</H3>
                                        <H3 style={{backgroundColor: '#cdeefd', borderRadius: '0px 0px 5px 5px', padding: 10, marginTop: 1}}>{index == 0? this.state.word1 : index == 1? this.state.word2 : index == 2 ? this.state.word3 : index == 3 ? this.state.word4 : index == 4 ? this.state.word5 : index == 5 ? this.state.word6 : index == 6 ? this.state.word7 : ''}</H3>

                                      </div>

                                    )
                                    
                                    
                                    
                                  })

                                  }

                                  </div>
                                  <div style={{display: 'flex', flexWrap: 'wrap', width: '90%', margin: '10px auto', justifyContent: 'center'}}>
                                  {question.shuffled.map(option => {

                                    return (
                                      
                                        <H3 style={{margin: 10, cursor: 'pointer', padding: 8, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={()=> {
                                          if (this.state.word1 == ''){
                                            this.setState({word1: option})
                                          } else if (this.state.word2 == ''){
                                            this.setState({word2: option})
                                            if (question.ans.length == 2){
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word3 == ''){
                                            this.setState({word3: option})
                                            if (question.ans.length == 3){
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word4 == ''){
                                            this.setState({word4: option})
                                            if (question.ans.length == 4){
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, this.state.word3, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word5 == ''){
                                            this.setState({word5: option})
                                            if (question.ans.length == 5){
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word6 == ''){
                                            this.setState({word6: option})
                                            if (question.ans.length == 6){
                                              console.log(question.ans)
                                              console.log(JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, this.state.word5, option]))
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, this.state.word5, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word7 == ''){
                                            this.setState({word7: option})
                                            if (question.ans.length == 7){
                                              console.log(question.ans)
                                              console.log(JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, this.state.word5, this.state.word6, option]))
                                              
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, this.state.word5, this.state.word6, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }
                                        }}>{option}</H3>

                                     
                                    )
                                  })

                                  }

                                  </div>
                                  
                                  
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}

                                  <div style={{display: 'flex'}}>
                                  {this.state.result == false && <div>
                                      <H3 style={{width: 200}}>Correct Answer:</H3>
                                      <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                      {question.ans.map(word => {
                                        return (
                                          <h4 style={{ minWidth: 50, maxWidth: 'max-content', margin: 10, padding: 5, backgroundColor: '#ebebeb', borderRadius: 5}}>{word}</h4>
                                        )
                                      })

                                      }

                                      </div>
                                      
                                    </div>

                                    }
                               
                                  {this.state.result != null && <div style={{width: '100%', textAlign: 'right'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                              </div>
                                </div>

                              )
                            } 
                          
                            
                          }
                        })

                        }


                      </Paper>


                      {this.state.Index >= lesson.questions.length && <button style={{border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.Index >= lesson.questions.length){

                          if (this.state.user.learningProgress.level == 4 && this.state.user.learningProgress.lesson == index + 69 && this.state.user.learningProgress.step == 3){
                            if (lesson.data.title == 'Refresher Course & Grammar'){
                              this.updateProgress(index + 70, 1)
                            }else {
                              this.updateProgress(index + 69, 4)
                            }
                            
                          }

                          if (lesson.data.title == 'Refresher Course & Grammar'){
                            this.setState({dialogExercise: false})
                          }else {
                            this.setState({dialogExercise: false, dialogResponse: true})
                          }
                          
                          this.setState({word1: '', word2: '', word3: '', word4: '',word5: '', word6: '', word7: '', result: null, Index: 0, wrongQuestions: []})
                        }


                        
                      }} >Continue</button>}
                      <div>

                      </div>
                      </div>
                    </Dialog>















                    <Dialog
                    open={this.state.dialogResponse}
                    onClose={() => this.setState({dialogResponse: false})}
                    fullScreen
                    >
                      <img src={logo} style={{width: 200, marginTop: -20}} />
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 20, right: 20}} onClick={() => this.setState({dialogResponse: false,recording: false,
                        audio: '',
                        isRecording: false,
                        blobURL: '',
                        recorded: false,
                        response: '',
                        responseSent: false})} />
                      <h1 style={{textAlign: 'center',fontSize: 'x-large'}}>Step 4. Track Your Progress</h1>

                      <Paper style={{width: '80%', margin: '30px auto',padding: 20, textAlign: 'center'}}>
                        <H3 style={{margin: 20}}>Please speak into the microphone the following sentence: </H3>
                        <H3><i>{lesson.data.sentence}</i></H3>
                        {/* <TextField multiline variant="outlined" style={{width: '80%', margin: 'auto'}} placeholder="type here..."  onChange={(e) => this.setState({response: e.target.value})}/> */}
                      {/* <div style={{width: '100%', textAlign: 'center', margin: 'auto'}}>
                      {this.state.response != '' && <Button variant="contained" color="primary" onClick={() => this.sendResponse(index + 1)}>Send Text</Button>}
                      </div> */}
                      

                        {/* <h3 style={{margin: '20px auto'}}>OR</h3> */}
                    {!this.state.recorded && !this.state.responseSent && !this.state.progress && <Button variant="contained" style={{backgroundColor: this.state.isRecording ? 'red' : 'green', color: 'white'}} onClick={() => {
                      if (this.state.isRecording){
                        this.stop()
                      } else {
                        this.start()
                      }
                    }}>{this.state.isRecording ? 'Stop Recording' : 'Click here to start recording'}</Button>}
                    {this.state.recorded && <div style={{width: '100%'}}>
                      <audio src={this.state.blobURL} style={{padding: 10,  }} controls="controls" /> 
                      </div>}
                      {this.state.progress && <div style={{width: '100%'}}>
                      <CircularProgress color="inherit" />
                      </div>}
                      

                      
                      {this.state.isRecording && <h3>Recording...</h3>}
                      {this.state.recorded && !this.state.responseSent && <Button variant="contained" color="primary" onClick={() => this.send(lesson.data.number)}>Send Audio</Button>}
                      
                      {this.state.responseSent && <p>Audio has been sent</p>

                      }


                      </Paper>
                      {this.state.user.adminResponses && this.state.user.adminResponses.map(response => {
                        if (response.level == 4 && response.lesson == lesson.data.number){
                          return (
                            <Paper style={{width: '80%', margin: '10px auto',padding: 20, textAlign: 'center'}}>
                              <H3>You have a response from Harold Zapata!</H3>
                              <audio src={response.audio} style={{padding: 10,  }} controls="controls" download='false' />


                            </Paper>
                          )
                        }
                      })

                      }


                      <button style={{border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.user.learningProgress.level == 4 && this.state.user.learningProgress.lesson == index + 69 && this.state.user.learningProgress.step == 4){
                          this.updateProgress(index + 69, 5)
                        }
                        this.setState({dialogResponse: false, dialogDownload: true,recording: false,
                          audio: '',
                          isRecording: false,
                          blobURL: '',
                          recorded: false,
                          response: '',
                          responseSent: false})
                      }} >Continue</button>
                      <div>

                      </div>
                    </Dialog>











                    <Dialog
                    open={this.state.dialogDownload}
                    onClose={() => this.setState({dialogDownload: false})}
                    fullScreen
                    >
                      <img src={logo} style={{width: 200, marginTop: -20}} />
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 20, right: 20}} onClick={() => this.setState({dialogDownload: false})} />
                      <h1 style={{textAlign: 'center',fontSize: 'x-large'}}>Step 5. DOWNLOAD AUDIO MP3.</h1>

                      <Paper style={{width: '80%', margin: 'auto', textAlign: 'center', padding: 10}}>
                        <audio src={lesson.data.downloadable} controls />
                        <div style={{width: '100%', textAlign: 'center'}}>
                        <p style={{ padding: 5, borderRadius: 5,margin: '5px auto', color: 'green'}}>Click the 3 dots to download</p>
                        </div>
                        


                      </Paper>


                      <button style={{border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.user.learningProgress.level == 4 && this.state.user.learningProgress.lesson == index + 69 && this.state.user.learningProgress.step == 5){
                          this.updateProgress(index + 70, 1)
                        }
                        this.setState({dialogDownload: false})
                      }} >Continue</button>
                      <div>

                      </div>
                    </Dialog>

                  </div>}

                </div>

                
                

              </div>

            )
          } )}
          {this.state.user.learningProgress ? this.state.user.learningProgress.lesson >= 89 && <div>
            <a href="/affiliate/final"><Button  variant="primary" >Proceed to Final Exam</Button></a>
          </div> : <div></div>}

          
          
        </div>
      )
























  //     return (
  //       <div>
         
  //         <Paper style={{ margin: 20, padding: 20 }}>
  //           <Steps direction="horizontal" size="small" current={0}>
  //             {this.state.courses.map((course, index) => {
  //               return <Step title={"course" + ++index} />;
  //             })}
  //           </Steps>
  
  //           {this.state.courses.length > 0 &&
  //             this.state.courses[0].map((lesson, index) => {
  //               return (
  //                 <Paper style={{ margin: 20, padding: 20 }}>
  //                   <h2 style={{backgroundColor: '#505050', padding: 20, width: 500, color: '#e9c979', fontWeight: 'bold', borderRadius: 5, margin: '10px auto'}} >Lesson {index + 1}</h2>
  //                   <h3 className="montserrat">Lesson Video</h3>
  //                   <ReactPlayer
  //                   onContextMenu ={(e) => e.preventDefault()}
  //                     style={{ margin: "20px auto 50px auto" }}
  //                     controls={true}
  //                     playing={false}
  //                     config={{ file: { 
  //                       attributes: {
  //                         controlsList: 'nodownload'
  //                       }
  //                     }}}
  //                     url={lesson.mainVideo}
  //                   />
  
  
  //                   <h3 className="montserrat">Teaching Video</h3>
  //                   <ReactPlayer
  //                     onContextMenu ={(e) => e.preventDefault()}
  //                     style={{ margin: "20px auto 50px auto" }}
  //                     controls={true}
  //                     playing={false}
  //                     config={{ file: { 
  //                       attributes: {
  //                         controlsList: 'nodownload'
  //                       }
  //                     }}}
  //                     url={lesson.teachingVideo}
  //                   />
  
  
  //                     <h3 className="montserrat">Audio Story</h3>
  //                     {lesson.audioStory && <ReactAudioPlayer
  //                       style={{margin: '10px auto'}}
  //                         src={ lesson.audioStory }
                          
  //                         controls
  //                       />}
  
  
  
  //                   <h3 className="montserrat">Text Story</h3>
  //                   <Input.TextArea
  //                       style={{height: 120}}
  //                       value={lesson.textStory}
                  
  //                       />
  //                   <Button variant="contained" color="primary" style={{margin: 10}} onClick={()=>{
  //                     console.log(lesson.exercise)
  //                     this.setState({
  //                     exercise: lesson.exercise,
  //                     dialog: true,
  //                     index: 0
  //                     })}} >Exercises</Button>
  //                 </Paper>
  //               );
  //             })}
  //         </Paper>
  //         <Dialog
          
  //         fullScreen
  //           open={this.state.dialog}>
  //             <div style={{backgroundColor: '#e6f3f8', height: '100%', width: '100%'}}>
  
              
  //             <h2 style={{textAlign: 'center'}} >Exercise</h2>
              
  //             <div style={{margin: 30, textAlign: 'center'}}>
  //               {this.state.exercise.map((question, index) => {
        
  //                 if (index == this.state.index + 1){
  //                   if (question.type == "fill the gap text"){
  //                     let word1 = '';
  //                     let word2 = '';
  //                     let word3 = '';
  //                     let ansWord1 = '';
  //                     let ansWord2 = '';
  //                     let ansWord3 = '';
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
  //                         <div style={{display: 'flex', justifyContent: 'center', margin: 20}}>
  //                           {question.question.map(word => {
  //                             if (question.options.filter(Word => {return Word == word}).length >= 1){
  //                               word1 = word;
  //                               return (
  //                                 <TextField margin="dense" disabled style={{marginTop: 2, cursor: 'pointer', width: 'max-content'}} variant="outlined" value={this.state.word1}  onClick={()=> this.setState({word1: ''})}/>
  //                               )
  //                             }else {
  //                               return (
  //                                 <H3>{word}</H3>
  //                               )
  //                             }
                              
  //                           })
    
  //                           }
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
  //                         {question.options.map(word => {
  //                           return (
  //                             <H3 style={{cursor: 'pointer'}} onClick={()=> {
  //                               if (this.state.word1 == ''){
    
  //                                 this.setState({word1: word})
  //                                 ansWord1 = word;
  //                                 if (ansWord1 == word1){
  //                                   console.log("correct")
  //                                   this.setState({open: true})
  //                                 } else  {
  //                                   this.setState({open2: true})
  //                                 }
  //                               }
  //                             }} >{word}</H3>
  //                           )
  //                         })
    
  //                         }
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   } else if (question.type == "fill the blank audio"){
  //                     let word1 = '';
  //                     let word2 = '';
  //                     let word3 = '';
  //                     let ansWord1 = '';
  //                     let ansWord2 = '';
  //                     let ansWord3 = '';
                      
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
  //                       <ReactAudioPlayer
  //                       style={{margin: '10px auto'}}
  //                         src={ question.audio }
                          
  //                         controls
  //                       />
  //                         <div style={{display: 'flex', justifyContent: 'center', margin: 20}}>
  //                           {question.question.map(word => {
  //                             if (question.words.filter(Word => {return Word == word}).length >= 1){
  //                               word1 = word;
  //                               return (
  //                                 <TextField margin="dense" disabled style={{marginTop: 2, cursor: 'pointer', width: 'max-content'}} variant="outlined" value={this.state.word1}  onClick={()=> this.setState({word1: ''})}/>
  //                               )
  //                             }else {
  //                               return (
  //                                 <H3>{word}</H3>
  //                               )
  //                             }
                              
  //                           })
    
  //                           }
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
  //                         {question.words.map(word => {
  //                           return (
  //                             <H3 style={{cursor: 'pointer'}} onClick={()=> {
  //                               if (this.state.word1 == ''){
    
  //                                 this.setState({word1: word})
  //                                 ansWord1 = word;
  //                                 if (ansWord1 == word1){
  //                                   console.log("correct")
  //                                   this.setState({open: true})
  //                                 } else {
  //                                   this.setState({open2: true})
  //                                 }
  //                               }
  //                             }} >{word}</H3>
  //                           )
  //                         })
    
  //                         }
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   } else if (question.type == "match the pair"){
                      
  //                     const shuffled = question.pairs;
  //                     let completed = 0;
                    
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
                        
  //                         <div style={{display: 'flex', justifyContent: 'center', margin: 20}}>
  //                           {question.pairs.map((pair, index) => {
  //                             return (
  //                               <div style={{margin: '10px'}}>
  //                                 <Word>{pair.word}</Word>
  //                                 <Phrase>
  //                                   <p>{index == 0 ? this.state.phrase1 : index == 1 ? this.state.phrase2 : index == 2 ? this.state.phrase3 : index == 3 ? this.state.phrase4 : ''}</p>
  //                                 </Phrase>
                                  
  
  //                               </div>
  //                             )
                              
  //                           })
    
  //                           }
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
  //                         {shuffled.map((pair, index) => {
  //                           return (
  //                             <Phrase style={{cursor: 'pointer'}} onClick={()=>{
  //                               if (this.state.phrase1 == ''){
  //                                 this.setState({phrase1: pair.phrase})
  //                               } else if (this.state.phrase2 == ''){
  //                                 this.setState({phrase2: pair.phrase})
  //                               }else if (this.state.phrase3 == ''){
  //                                 this.setState({phrase3: pair.phrase})
  //                               }else if (this.state.phrase4 == ''){
  //                                 this.setState({phrase4: pair.phrase})
  //                               }
  //                               completed = completed + 1;
  //                               let {ans} = this.state;
  //                               ans.push(pair.phrase)
  //                               console.log(ans)
  //                               let pairs = []
  //                               question.pairs.forEach(pair => {
  //                                 pairs.push(pair.phrase)
  //                               })
  //                               console.log(pairs)
  //                               if (ans.length == pairs.length){
  //                                 if (JSON.stringify(ans)==JSON.stringify(pairs)){
  //                                   console.log('correct')
  //                                   this.setState({open: true})
  //                                 } else {
  //                                   this.setState({open: false})
  
  //                                 }
  //                               }
  
                                
  
  //                             }}>
  //                               <p>{pair.phrase}</p> 
  //                             </Phrase>
  //                           )
  //                         })
    
  //                         }
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   }else if (question.type == "correct order"){
  //                     const correct = question.sentence;
                      
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
                       
  //                         <div style={{display: 'flex', width: 400, height: 100, justifyContent: 'center', border: '1px solid #bebebe', padding: 10, backgroundColor: '#ebebeb', margin: '20px auto'}}>
  //                           {this.state.sentence.map(word => {
  //                             return (
  //                               <h4>{word}</h4> 
  //                             )
                              
                              
  //                           })
    
  //                           }
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
  //                         {question.shuffled.map(word => {
  //                           return (
  //                             <Button variant="contained" onClick={()=> {
  //                               let {sentence} = this.state;
  //                               sentence.push(word);
  //                               this.setState({sentence})
  //                               console.log(sentence)
  //                               console.log(correct)
  //                               if (sentence.length == question.sentence.length){
  //                                 if (JSON.stringify(sentence)==JSON.stringify(correct)){
  //                                   console.log('correct')
  //                                   this.setState({open: true})
  //                                 } else {
  //                                   this.setState({open2: true})
  
  //                                 }
  //                               }
  //                             }}>{word}</Button>
  //                           )
  //                         })
    
  //                         }
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   } else if (question.type == "true false"){
                      
                      
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
                       
  //                         <div style={{display: 'flex', width: 400, height: 100, justifyContent: 'center', border: '1px solid #bebebe', padding: 10, backgroundColor: '#ebebeb', margin: '20px auto'}}>
                            
  //                               <h4>{question.sentence}</h4> 
                            
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
                          
  //                             <Button variant="contained" onClick={()=> {
                   
                                
  //                               if (question.status == true){
  //                                 console.log("correct")
  //                                 this.setState({open: true})
  //                               } else {
  //                                 this.setState({open2: true})
  
  //                                 console.log("false")
  //                               }
  //                             }}>True</Button>
  
  //                             <Button variant="contained" onClick={()=> {
                       
                                
  //                               if (question.status == false){
  //                                 console.log("correct")
  //                                 this.setState({open: true})
  
  //                               } else {
  //                                 this.setState({open2: true})
                                  
  //                                 console.log("false")
  //                               }
  //                             }}>False</Button>
                          
    
                          
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   } else if (question.type == "mcq"){
                      
                      
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : question.type == "mcq" ? "Select the correct option" : "Solve"  } </h2>
                       
  //                         <div style={{display: 'flex', justifyContent: 'center', margin: 20}}>
                            
  //                               <h4>{question.question}</h4> 
                            
    
  //                         </div>
    
  //                         <div style={{}}>
  //                           {question.options.map(option => {
  //                             return (
  //                               <Button variant="contained" onClick={()=> {
                                    
                                                  
  //                                   if (question.correctOption == option){
  //                                     console.log("correct")
  //                                   this.setState({open: true})
  
  //                                   } else {
  //                                   this.setState({open2: true})
  
  //                                     console.log("false")
  //                                   }
  //                                 }}>{option}</Button>
  //                             )
  //                           })
  
  //                           }
                          
                              
        
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   }
                    
  //                 } 
  //               })
  
  //               }
  //             </div>
  
  
  
  
  
  //               {/* <div style={{position: 'fixed', bottom: 20, margin: 'auto'}}>
  //               <Alert severity="success">
  //                 <AlertTitle>Success</AlertTitle>
  //                 This is a success alert — <strong>check it out!</strong>
  //               </Alert>
  //               </div> */}
  
  // <Snackbar open={this.state.open} autoHideDuration={10000} onClose={this.handleClose}>
  //         <Alert onClose={this.handleClose} severity="success">
  //           Correct!
  //         </Alert>
  //       </Snackbar>
  //       <Snackbar open={this.state.open2} autoHideDuration={10000} >
  //         <Alert severity="error">
  //           Wrong!
  //         </Alert>
  //       </Snackbar>
  
  //             <CancelIcon  onClick={()=> this.setState({dialog: false})} style={{position: 'fixed', top: 30, left: 30, cursor: 'pointer', opacity: 0.8}} />
  //             {this.state.index != 0 && <Button variant="contained" color="primary" style={{position: 'fixed', bottom: 30, left: 80}} onClick={()=> {
  //               this.setState({index: this.state.index - 1, open: false, open2: false})
  //               this.setState({word1: '',
  //               word2: '',
  //               word3: '',
  //               word4: '',
  //               phrase1: '',
  //               phrase2: '',
  //               phrase3: '',
  //               phrase4: '',
  //               shuffled: [],
  //               ans: [],
  //               sentence: [],})
  
  //             }} >Previous</Button>}
  //             {this.state.index != (this.state.exercise.length - 2) && <Button variant="contained" color="primary" style={{position: 'fixed', bottom: 30, right: 80}} onClick={()=> {
  //               this.setState({index: this.state.index + 1, open: false, open2: false})
  //               this.setState({word1: '',
  //               word2: '',
  //               word3: '',
  //               word4: '',
  //               phrase1: '',
  //               phrase2: '',
  //               phrase3: '',
  //               phrase4: '',
  //               shuffled: [],
  //               ans: [],
  //               sentence: [],})
  //             }} >Next</Button>}
  //             </div>
  //         </Dialog>
  //       </div>
  //     );

    }

    
  }
}


const H3 = styled.h3 `
  font-weight: bold;
  margin: 5px;
  padding: 5px;

  @media(max-width: 500px ){
    font-size: medium;
  }

`

const Word = styled.h3 `
  padding: 10px;
  margin: 5px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
  border-radius: 5px 5px 0px 0px;
`

const Phrase = styled.div `
  padding: 10px;
  margin: 5px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
  text-align: center;


`