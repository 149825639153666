import React, { Component } from "react";
import "../../App.css";
import styled from "styled-components";
import AppBar from "./AppBar";
// import StatusSlider from "./statusSlider";
import Slider from "./slider";
import Login from '../BackOffice/Auth/login'

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import Messaging from './Messaging/chat'
import Courses from "./Courses/courses";
import Tickets from './Tickets/tickets'
import Affiliates from './Affiliates/affiliates'
import Students from './Students/students'
import Student from './Students/studentDetails'
import Financials from './Financials/finacials'
import Training from './Training/training'
import Marketing from './Marketing/marketing'
import Reports from './Reports/reports'
import ContactMessages from './Messaging/contactMessages'

export default class backOffice extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  render() {
    if (localStorage.getItem("AdminLogin") != "true" ){
      return (
        <Login />
      )
    }
    return (
      <Router>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#ebebeb",
            width: "100vw",
            height: "max-content",
            minHeight: "100vh",
          }}
        >
          <AppBar />
          <div className="container-div">
            {/* <StatusSlider />
          <h3 style={{ margin: 2, padding: 0, fontSize: "x-large" }}>
            Aprende Ingles
          </h3> */}

            <Slider />

            <Switch>
              <Route path="/admin/courses" component={Courses} />
              <Route path="/admin/messages" component={Messaging} />
              <Route path="/admin/tickets" component={Tickets} />
              <Route path="/admin/affiliates" component={Affiliates} />
              <Route path="/admin/financials" component={Financials} />
              <Route path="/admin/training" component={Training} />
              <Route path="/admin/marketing" component={Marketing} />
              <Route path="/admin/reports" component={Reports} />
              <Route path="/admin/contact-messages" component={ContactMessages} />

              <Route exact path="/admin/students" component={Students} />
              <Route exact path="/admin/students/:id" component={Student} />

            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}
