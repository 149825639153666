import React, { Component } from "react";
import  '../../../App.css'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import { TextField, Button } from "@material-ui/core";
import * as firebase from "firebase";
import "../../../config" ;
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Link,
    Redirect,
  } from "react-router-dom";

export default class students extends Component {
    constructor(){
        super();
        this.state = {
            users: []
           

        }
    }

    componentDidMount(){
        firebase.firestore().collection('Users').get().then(res => {
            console.log(res.docs)
            this.setState({users: res.docs})
            
        })
       

    }

    


    render(){ 

        return (
            <div>
                <h1>Students</h1>
                <TableContainer component={Paper} style={{width: '90%', margin: '10px auto'}}>
                    <Table >
                        <TableHead>
                        <TableRow style={{backgroundColor: '#add8e6'}}>
                            <TableCell style={{fontWeight: 'bold'}}>Name</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} align="right">Level</TableCell>
                            
                            
                            
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.state.users.map((user) => (
                            <Link  to={{
                                pathname: `/admin/students/${user.data().userName}`,
                                user: user.data()
                                }}  style={{width: '120%', margin: 0}}>
                            <TableRow style={{cursor: 'pointer',backgroundColor: '#ffffe0'}} onClick={()=>{
                                return (
                                    <Redirect to="/admin/students/user" />
                                )
                               
                            }}>
                            <TableCell style={{width: '100%'}} component="th" scope="row">
                                    <p>{user.data().name}</p>
                                
                            </TableCell>
                            <TableCell style={{width: '100%'}} align="right">{user.data().learningProgress &&  user.data().learningProgress.level}</TableCell>
                            </TableRow></Link>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>


            </div>
        )

    }
} 