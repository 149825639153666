import React, { Component } from "react";
import "../../../App.css";
import styled from "styled-components";
import * as firebase from "firebase";
import "../../../config" ;
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default class courses extends Component {
  constructor() {
    super();
    this.state = {
      messages: [],
    };
  }

  componentDidMount() {
    firebase.firestore().collection('ContactMessages').get().then(res => {
        this.setState({messages: res.docs})
    })
  }

  render() {
   
    return (
      <div
      style={{padding: 30}}
      >
          <h2>Contact Messages</h2>
          {this.state.messages.map(message => {
              return (
                <ExpansionPanel

                expanded={this.state.course == "1"}
                onChange={() =>
                  this.state.course == "1"
                    ? this.setState({ course: "" })
                    : this.setState({ course: "1" })
                }
              >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <h3>{message.data().email}</h3>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div style={{padding: 10, backgroundColor: '#ebebeb', borderRadius: 6,textAlign: 'left', width: '90%', margin: 'auto'}}>
                      <p><b>Name:</b> {message.data().firstName +message.data().lastName}</p>
                      <p><b>Message:</b> {message.data().message}</p>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              )

          })}


        
      </div>
    );
  }
}

const Card = styled.div`
  margin: 20px;
  width: 150px;
  height: 120px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
`;
