import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import "../../../App.css";
import styled from "styled-components";
import { fire } from "../../../config";
import storage from "../../../config";
import "../../../config";
import * as firebase from "firebase";
import ReactPlayer from "react-player";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Input } from "antd";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import ReactAudioPlayer from 'react-audio-player';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import logo from '../../../Images/nuparla em1 .1 w.png'
import Dialog from '@material-ui/core/Dialog';
import { TextField } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import refreshIcon from '../../../Images/refresh.png'
import { makeStyles } from '@material-ui/core/styles';
import { Steps } from 'antd';
import MicRecorder from 'mic-recorder-to-mp3';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import  FileSaver  from 'file-saver';
import { saveAs } from 'file-saver';
import axios from 'axios'
import fileDownload from 'js-file-download'
const Mp3Recorder = new MicRecorder({ bitRate: 128 });


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const { Step } = Steps;

export default class level1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      dialog: false,
      exercise: [],
      index: 0,

      shuffled: [],
      ans: [],
      sentence: [],
      open: false,
      open2: false,


      level2: {
        lessons: []
      },
      selectedLesson: null,
      
      dialogVideo: false,
      dialogAudio: false,
      dialogExercise: false,
      dialogResponse: false,
      dialogDownload: false,

      Index: 0,
      qIndex: 0,

      user: {},

      recording: false,
      audio: '',
      isRecording: false,
      blobURL: '',
      recorded: false,
      response: '',

      progress: false,

      word1: '',
      word2: '',
      word3: '',
      word4: '',
      word5: '',
      word6: '',
      word7: '',

      shuffled: [],
      wrongQuestions: [],

      result: null,
      responseSent: false,

      audioStoryLink: '',
      audioStoryDialog: false

    };
  }

  componentDidMount() {
    firebase.firestore().collection('Course').doc('level2').get().then(res => {
  
 
      this.setState({level2: res.data()})

      // console.log(res.data())

      // console.log({
      //   data: {},
      //   lessons: [
      //     res.data().lessons[1]
      //   ]
      // })

      // firebase.firestore().collection('Course').doc('level3').set({
      //   data: {},
      //   lessons: [
      //     res.data().lessons[1]
      //   ]
      // })
      


    })

    

    firebase.firestore().collection('Users').doc(localStorage.getItem('email')).onSnapshot(res => {
     
      this.setState({user: res.data()})
      console.log(res.data())

      // firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
      //   learningProgress: {
      //     level: 1,
      //     lesson: 1,
      //     step: 1
      //   }
      // })
    })


  }

  download(ur){
    
    storage.ref('AudioStories/level3/Lesson 39 audio story.mp3').getDownloadURL()
  .then((url) => {
    console.log(url)
    // `url` is the download URL for 'images/stars.jpg'

    // This can be downloaded directly:
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      var blob = xhr.response;
    };
    xhr.open('GET', url);
    xhr.send();

    // Or inserted into an <img> element
    // var img = document.getElementById('myimg');
    // img.setAttribute('src', url);
  })
  .catch((error) => {
    console.log(error)
    // Handle any errors
  });
    
    

  }

  start = () => {
    this.setState({recording: true})
    
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true });
        }).catch((e) => console.error(e));
    
  };

  stop = () => {
    this.setState({recording: false})
    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob)
        console.log(blob)
        this.setState({ blobURL, blob: blob, isRecording: false, recorded: true });
      }).catch((e) => console.log(e));
  };

  shuffle(array){
    var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;

  }

  send = (lesson) => {
    this.setState({recorded: false,progress: true })

    if (this.state.blobURL != ''){

      let upload = storage
        .ref(`responses/${this.state.user.userName + lesson}`)
        .put(this.state.blob);
      upload.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref(`responses/${this.state.user.userName + lesson}`)
            .getDownloadURL()
            .then(async (url) => {
              firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
                Responses: firebase.firestore.FieldValue.arrayUnion({
                  level: 2,
                  lesson: lesson,
                  audio: url
                }) 
        
              }).then((res) => {
                this.setState({responseSent: true, progress: false})
              })
              

            
        })})
    }
  }


  sendResponse = (lesson) => {

    firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
      Responses: firebase.firestore.FieldValue.arrayUnion({
        level: 2,
        lesson: lesson,
        text: this.state.response
      }) 

    }).then((res) => {
      this.setState({responseSent: true})
    })
    

  }




   handleClick = () => {
    this.setState({open: true})
    };

   handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }

    // this.setState({open: false})
  };

  getCourses() {
    firebase
      .database()
      .ref("Courses")
      .child("level2")
      .once("value")
      .then((snapshot) => {
        this.setState({ courses: snapshot.val() });
      });
  }

   shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
    }

  updateProgress(lesson, step) {

    firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
        learningProgress: {
          level: 2,
          lesson: lesson,
          step: step
        }
      })
  }

  render() {

    if (this.state.user.learningProgress && this.state.user.learningProgress.level <= 1){
      return (
        <div>
          <p>This Level becomes active once you have finished the previous levels. </p>
        </div>
      )
    }else {
      return (
        <div style={{backgroundColor: '#ebebeb', padding: 20}}>
          <h1 style={{fontSize: 'x-large'}}>Level 2</h1>
 
          {this.state.level2.lessons.map((lesson,index) => {

            return (
              <div>
                {index == 0 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#1</span> Sports</h2>
                  
                </div>}

                {index == 2 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#2</span> Life Events</h2>
                  
                </div>}

                {index == 6 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#3</span> Work Life</h2>
                  
                </div>}

                {index == 8 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#4</span> Dialogue 2.0</h2>
                  
                </div>}

                {index == 12 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#5</span> Restaurants and food</h2>
                  
                </div>}

                {index == 14 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#6</span> Travel 1.0</h2>
                  
                </div>}

                {index == 16 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#7</span> I feel sick</h2>
                  
                </div>}

                <div style={{cursor: 'pointer', backgroundColor: 'white', width: '90%', margin: '25px auto', borderRadius: '10px', boxShadow: '0px 2px 8px rgba(0,0,0,0.2)', marginBottom: lesson.data.title == 'Refresher Course & Grammar' ? 60 : 25}} >
                  <div style={{display: 'flex'}} onClick={() => {
                    if(this.state.selectedLesson == index){
                      this.setState({selectedLesson: null})
                    } else {
                      this.setState({selectedLesson: index})
                    }
                    
                  }}>
                    {lesson.data.title != 'Refresher Course & Grammar' && <img style={{width: 80, height: 80, margin: '10px'}} src={lesson.data.avatar} alt="avatar" />}
                    {lesson.data.title == 'Refresher Course & Grammar' && <img style={{width: 80, height: 80, margin: '10px', marginLeft: 40}} src={refreshIcon} alt="avatar" />}
                    {lesson.data.title != 'Refresher Course & Grammar' && <div style={{textAlign: 'left'}}>
                      <H3>Lesson {lesson.data.number}: {lesson.data.title}</H3>
                      <p style={{marginLeft: 93}}>{lesson.data.description}</p>
                    </div>}

                    {lesson.data.title == 'Refresher Course & Grammar' && <div style={{textAlign: 'left', margin: 'auto 30px'}}>
                      <h2 style={{fontSize: 'x-large'}}>{lesson.data.title} #{lesson.data.number}</h2>
                      
                    </div>}

                  </div>
                  {this.state.selectedLesson == index && <div style={{backgroundColor: '#fafafa', borderRadius: '0px 0px 10px 10px', width: '100%',padding: 10, borderTop: '1px solid #bebebe' }}>
                    <div style={{width: '80%', margin: '10px auto'}}>
                    <Steps current={this.state.user.learningProgress.lesson < index + 30 ? null : this.state.user.learningProgress.lesson > index + 30 ? 6 : this.state.user.learningProgress.step - 1 }>
                      <Step title="Step 1" description="Video" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 29){
                            this.setState({dialogVideo: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 29 && this.state.user.learningProgress.step -1 >= 0)
                          {
                            this.setState({dialogVideo: true})
                          }
                          
                        }}/>
                      {lesson.data.title != 'Refresher Course & Grammar' && <Step title="Step 2"  description="Audio" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 29){
                            this.setState({dialogAudio: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 29 && this.state.user.learningProgress.step -1 >= 1){
                            this.setState({dialogAudio: true})
                          }

                    
                        }}/>}
                      <Step title={lesson.data.title == 'Refresher Course & Grammar' ? "Step 2" : "Step 3"} description="Exercise" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 30){
                            this.setState({dialogExercise: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 30 && this.state.user.learningProgress.step -1 >= 2){
                            this.setState({dialogExercise: true})
                          
                          }
                        
                        }}/>
                      {lesson.data.title != 'Refresher Course & Grammar' && <Step title="Step 4" description="Track" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 30){
                            this.setState({dialogResponse: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 30 && this.state.user.learningProgress.step -1 >= 3){
                            this.setState({dialogResponse: true})
                          }
                        
                        }}/>}
                      {lesson.data.title != 'Refresher Course & Grammar' && <Step title="Step 5" description="Download" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 30){
                            this.setState({dialogDownload: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 30 && this.state.user.learningProgress.step -1 >= 4){
                            this.setState({dialogDownload: true})

                          }
                    
                        }}/>}
                    </Steps>
                    </div>







                    <Dialog
                    open={this.state.dialogVideo}
                    onClose={() => this.setState({dialogVideo: false})}
                    fullScreen
                    >
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 20, right: 20}} onClick={() => this.setState({dialogVideo: false})} />
                      <img src={logo} style={{width: 200}} />
                      <h1 style={{textAlign: 'center', fontSize: 'x-large'}}>Step 1. VIDEO LESSON</h1>

                      <Paper elevation={3}  style={{padding: 10,textAlign: 'center', margin: '30px auto',}}>
                  
                        
                        <ReactPlayer
                          
                            style={{ margin: "10px auto 10px auto", border: '1px solid #bebebe' }}
                            height={window.innerWidth >= 800 ? '420px' : window.innerWidth >= 500 ? '280px' : '200px'}
                            width={window.innerWidth >= 800 ? '740px' :window.innerWidth >= 500 ? '480px' : '360px'}
                            controls={true}
                            playing={true}
                            
                            // url={lesson.data.video}
                            url={lesson.data.video}
                            
                          />

                                
                                

                      </Paper>


                      <button style={{fontSize: 'large', border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.user.learningProgress.level == 2 && this.state.user.learningProgress.lesson == index + 30 && this.state.user.learningProgress.step == 1 && lesson.data.title != 'Refresher Course & Grammar'){
                          this.updateProgress(index + 30, 2)
                        }
                        if (this.state.user.learningProgress.level == 2 && this.state.user.learningProgress.lesson == index + 30 && this.state.user.learningProgress.step == 1 && lesson.data.title == 'Refresher Course & Grammar'){
                          this.updateProgress(index + 30, 3)
                        }
                        if (lesson.data.title != 'Refresher Course & Grammar'){
                          this.setState({dialogVideo: false, dialogAudio: true})
                        } else  {
                          this.setState({dialogVideo: false, dialogExercise: true})

                        }
                        
                      }} >Continue</button>
                      <div>

                      </div>
                    </Dialog>








                    <Dialog
                    open={this.state.dialogAudio}
                    onClose={() => this.setState({dialogAudio: false})}
                    fullScreen
                    >
                      <img src={logo} style={{width: 200, marginTop: -20}} />
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 20, right: 20}} onClick={() => this.setState({dialogAudio: false})} />
                      <h1 style={{textAlign: 'center',fontSize: 'x-large'}}>Step 2. AUDIO STORY LESSON</h1>

                      <Paper elevation={3} style={{padding: 10,textAlign: 'center', margin: '30px auto',}}>
                      
                        
                        <audio src={lesson.data.audioStory} style={{padding: 10 }} controls="false" controlsList="nodownload"/>


                      </Paper>

                      <Dialog 
                      open={this.state.audioStoryDialog}
                      onClose={() => this.setState({audioStoryDialog: false})}
                      >
                        <ReactPlayer
                          
                          style={{ margin: "auto", border: '1px solid #bebebe' }}
                          height='240px'
                          width='400px'
                          controls={true}
                          playing={true}
                          
                          // url={lesson.data.video}
                          url={this.state.audioStoryLink}
                          
                        />
                        

                      </Dialog>

                      


                      {lesson.data.number == 23 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story for lesson 23, titled: Sing a song of six pants. – part 1 Hey fellas, listen to this letter! <br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>

                        Please click below to watch a short 1 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/486119863"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                         <br/>
                        In this episode, Larry, Moe and Shemp own a dry cleaners. Larry is working hard on a spot that is shined on by the sun. <br/>
                        <br/>
                        “That is the toughest spot I ever tackled,” he sighs. “It won’t beat me!” He is determined. <br/>
                        <br/>
                        Shemp is working on some pants. “Shemp, give me a hand, will you?” asks Larry. Shemp answers: “I can’t now, I’ve got to finish my pants.” <br/>
                        <br/>
                        Suddenly, Moe runs in. “Hey, fellas, listen to this letter.” He reads an important letter…<br/>
                        <br/>
                        “Gentlemen,” Shemp interrupts Moe. “That aint’ for us, we’re not gentlemen.”  Moe answers: “Speak for yourself,” as he hits Shemp on the nose. Moe continues reading: “Gentlemen, this is to inform you that unless overdue payments for tailoring equipment are made within 24 hours, we will repossess said equipment. Balance now due: $321.86. I. Fleecem, President. (I fleece them).”<br/>
                        <br/>
                        Larry moans, “that’ll put us out of business. What will we do?”<br/>
                        <br/>
                        “You guys worry about that,” chimes Shemp, “I’m too young to worry and get wrinkles on my pretty little face. What we need is a little music to cheer us up.” <br/>
                        <br/>
                        Shemp walks away from his pants, to the radio. <br/>
                        <br/>

                        Questions:<br/>
                        How much do the boys owe the bank?<br/>
                        Why does worrying produce wrinkles on our faces?<br/>


                          </p>
                      </Paper>}


                      {lesson.data.number == 24 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story for lesson 24 titled: Sing a song of six pants. – part 2 A large reward is offered for the thief. <br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate): <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/486121206"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        
                        <br/>
                        In this episode, the three stooges listen to a news flash on the radio. <br/>
                        <br/>
                        “Newsflashes! Police are frantically scouring the town in search of Terry “Slippery Finger” Hargan. Hargan has robbed his 18th consecutive safe in 9 days. A large reward is offered for his capture.”<br/>
                        <br/>
                        Then an advertisement is heard: “Does your car have indigestion? Does it burp in the morning? Try No-burpoline. The only gasoline-containing bi-carbonated soda…” Shemp turns the radio off. <br/>
                        <br/>
                        “Hey, Moe. Why don’t we capture Hargan and collect our reward, then we can pay our bills!” He points to his head showing that he is smart. <br/>
                        “Oh, it’s as simple as all that,” answers Moe sarcastically. “Hargan is going to walk right in here and is going to let you capture him. Your nuts!” Moe thinks on the seriousness of his situation. “We’re gonna be paupers, paupers!”<br/>
                        “Are you kidding? We’re not even married,” yells Shemp.  <br/>
                        <br/>
                        Moe pokes Shemp in his eyes. “Why won’t you be quiet. Think!”<br/>
                        <br/>
                        Meanwhile, Larry cleaned that spot so much, that he made a hole in the pants. He smiles. “Well anyway, I got the spot out.” Then he realizes the spot came from a sun ray through the window. <br/>
                        <br/>
                        “Oh boy, am I a sap…” [“sap” is slang for fool or dupe.]<br/>
                        <br/>

                        Questions:<br/>
                        What does the term “pauper” mean? <br/>
                        Why does Moe think they’re going to become paupers?<br/>
                        Do you have a feeling the three stooges will meet Hargan in the next episodes? (Yes, me too!)<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 25 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story for lesson 25, titled:  Sing a song of six pants:– part 3 Who wants lunch? <br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate): <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/486122365"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        
                        <br/>
                        In this episode, the three stooges use their dry cleaning equipment to make lunch. <br/>
                        <br/>
                        “Who wants lunch?” asks Moe. Everyone wants lunch. <br/>
                        <br/>
                        Moe prepares pancakes on the ironing press. Lunch is now served. <br/>
                        <br/>
                        Shemp is reading the newspaper’s funnies, and laughs very loud. <br/>
                        <br/>
                        “What are you stalling around for?” Moe takes Shemp by the nose. “You know you’ve got a rush order to get out. Get Mr. Grant’s pants and hand-press them!” <br/>
                        “All right, all right! But where are they?”<br/>
                        “I placed them up on the shade. What did you do with them?” yells Moe. <br/>
                        “I didn’t touch them,” says Shemp. <br/>
                        <br/>
                        Moe brings the shade down and there are the pants. “Here, hurry up and press those.” <br/>
                        <br/>
                        But the pants curl up. <br/>
                        <br/>
                        “Why are you standing around for?”<br/>
                        <br/>

                        Questions:<br/>
                        Have you ever cooked pancakes on a iron press?<br/>
                        What do you think “stalling around” means?<br/>
                        Have you ever laughed reading a newspaper’s funnies?<br/>
                        </p>
                      </Paper>}

                      {lesson.data.number == 26 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story for lesson 26, titled: Sing a song of six pants. – part 4 You double crosser!<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate): <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/486123831"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        
                        <br/>
                        In this episode, Shemp has trouble ironing some pants. <br/>
                        <br/>
                        Shemp struggles to iron pants that naturally curl up. He places them on the ironing board, burns his hand with the hot iron, and even gets on the to straighten the pants out. <br/>
                        <br/>
                        Suddenly the ironing board closes up on his, throws him inside the ironing closet and spits him right out, dropping him on the floor. <br/>
                        <br/>
                        “You double-crosser!” he shouts at the ironing board and throws it into the closet, but it comes right out, hitting him on the head. <br/>
                        <br/>
                        Shemp growls at it “Wise guy. This time I’ll close you!” He attempts to hit it with a broom, when suddenly Moe appears and he hits Moe instead on the face. <br/>
                        <br/>
                        “I’m sorry, Moe,” says Shemp. <br/>
                        “Ah, think nothing of it,” replies Moe. <br/>
                        “Oh, gee. Thanks!” smiles Shemp. <br/>
                        <br/>
                        Moe shouts “Your welcome,” and hits Shemp on the head with the broomstick, places it inside his mouth and drags Shemp away. <br/>
                        <br/>

                        Questions:<br/>
                        Have you ever had a stubborn pant or shirt you had difficulty ironing?<br/>
                        What does “double-crosser” mean?<br/>
                        <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 27 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story for lesson 27, titled: Sing a song of six pants. – part 5 The thief is in the neighborhood. <br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate): <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/486125766"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        
                        <br/>
                        In this episode, the three stooges meet a police officer in pursuit of a thief inside their store. <br/>
                        <br/>
                        Terry “Slippery Finger” Hargan is a thief that runs inside the three stooge’s store because a police officer is in hot pursuit of him. The thief poses as a manikin, and nobody saw him do that. <br/>
                        <br/>
                        The police officer barges in the store.  “Hey, fellas!” <br/>
                        <br/>
                        The stooges turn around. “A customer!” cries Larry. They run to meet him. <br/>
                        <br/>
                        “Say, have you seen…” the officer is interrupted. The stooges gather around him and start selling him. <br/>
                        “Yes, you’ve really come to the right place,” persuades Moe.  <br/>
                        “You bet. This is the store where your dollars have more sense (pronounced cents),” chimes Shemp. <br/>
                        “Say, we’ve got the very sport coat you’ve been looking for,” says Moe.<br/>
                        “But I don’t want a coat,” hints the police officer. <br/>
                        “But listen, mister. We want to give you a bargain. Where did you ever get this mess?” asks Shemp. <br/>
                        “I bought it here,” says the officer. <br/>
                        “Oh, what a beautiful masterpiece,” smiles Shemp. <br/>
                        <br/>
                        Meanwhile, Moe and Larry take off a sports coat from a manikin, who happens to be the thief. <br/>
                        <br/>
                        “Here, see the beauty?” Moe places the sportscoat in front of the officer. <br/>
                        “Genuine import. Smell the ocean!” shouts Larry as he shoves it in the officer’s face to smell it out. <br/>
                        “And it’s 200% wool,” adds Shemp.<br/>
                        <br/>
                        That takes the officer by surprise. “Two-hundred percent?”<br/>
                        <br/>
                        “Yeah, these sheep led a double life,” quips Shemp as he starts laughing at his own joke. <br/>
                        <br/>
                        Moe puts the sportscoat on the officer. He screams three times: “I don’t want a coat!”<br/>
                        <br/>
                        “Oh, he don’t want a coat,” the three stooges say it in harmony. <br/>
                        <br/>
                        Larry turns to Moe. “What he wants is a pair of our slick slacks.”<br/>
                        “Why didn’t you tell me the man wants slacks. Get him slacks!” Moe slaps Larry across the face. <br/>
                        <br/>
                        Larry takes the slacks off the thief, still posing as a manikin. <br/>
                        <br/>
                        “Try them on for size,” hints Moe. Then he discovers the officer’s badge. “Gee officer, we didn’t…”<br/>
                        “Quiet! Did you birds just realize you just let Terry Hargan the bank robber slip right through my hands?” barks the officer. He turns to Shemp. “I ought’a run you in!” <br/>
                        “No, please officer,” cries Shemp, “I have six wives and two kids…”<br/>
                        “Honest, officer, there was nobody that came through here,” interrupts Moe. <br/>
                        “Well he’s in the neighborhood. Keep a sharp look out. I’ll be back.” <br/>
                        <br/>
                        The officer barges out of the store. <br/>
                        <br/>

                        Questions:<br/>
                        What does it mean: “in this store your dollars have more sense?”<br/>
                        How can a coat be 200% wool?<br/>
                        What does it mean that the sheep led a “double life?”<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 28 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story for lesson 28, titled: Sing a song of six pants. – part 6  The bank robber was here!<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate): 
                        
                        <br/>
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/486127995"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        <br/>
                        In this episode, a thief asks his girlfriend to retrieve a jacket left behind at the three stooge’s shop, because that jacket has the combination to a safe the thief will rob later that night. <br/>
                        <br/>
                        As the thief enters his house, his girlfriend laughs at him not wearing a jacket and pants. Those were taken off during the last episode by the three stooges at their tailor shop. <br/>
                        <br/>
                        “Hello sugar plumb, how are you?” asks the girlfriend laughing. <br/>
                        “Just dandy,” answers the thief. “Listen carefully. My clothes are at the Pep Boys tailor shop around the corner. Now in the watchpocket of the trousers there’s a piece of paper with the combination to a safe. I want to do that job tonight and I need that paper.” <br/>
                        “Ok, sugar. Just leave it to me,” replies the girlfriend. <br/>
                        <br/>
                        As the three stooges work, Moe finds the piece of paper in the watchpocket of the pants. <br/>
                        <br/>
                        “Hey, fellas! Look at what I found in the pocket of these pants.”  The writing is: L-R-L-R-L-R-L-1-1-2-3-2-2-1. Moe starts thinking out loud: “Hey, L-1, R-1. Left-one. Right-one.” This is the combination to a safe!” He then moves on to the sports jacket. “T.H. I wonder what that means?” <br/>
                        <br/>
                        Shemp answers: “Teddy Hoosevelt?” (US President’s name was Teddy Roosevelt.)<br/>
                        <br/>
                        “You’re wrong, quiz-kid,” answers Moe.<br/>
                        <br/>
                        Larry is thinking: “I got it, I got it. Thomas Hedison.” (Should be Thomas Edison, the inventor.)<br/>
                        <br/>
                        “Why won’t you dumbbells stop!” cries Moe. He then inspects that jacket closer: “Terry Hargard. Terry Hargard? He was here. The bank robber was here!”<br/>
                        “You’re crazy,” says Shemp, “those clothes came off one of the dummies. Now, no cracks…” <br/>
                        <br/>
                        Moe bites Shemp’s finger. <br/>
                        <br/>
                        Questions:  What do you think sugar and sugar plumb mean?  <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 29 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story for lesson 29, titled: Sing a song of six pants. – part 7  What kind of brushing are you doing around here?<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/486129962"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, the three stooges accidently shred a client’s jacket. Moe accidently drops a razor blade on a brush.  A client strides in. <br/>
                        <br/>
                        “Hi, fellas. Is my coat altered?” <br/>
                        “Just finished,” answers Larry. He places it on the client. “Try it on.” <br/>
                        <br/>
                        Moe finds a thread on the jacket. “Wait a minute. Here. Brush him off,” he orders Shemp. <br/>
                        <br/>
                        Shemp takes the brush that has the razor blade, and starts brushing the back of the jacket on the client.<br/>
                        <br/>
                        “Sleeve’s 31 inches, how about that?” asks Larry. <br/>
                        “Great! 31 inches…” responds the client.<br/>
                        “Who made that hat?” asks Shemp has he touches the rim of the hat. <br/>
                        “My mother,” responds the client.   Shemp stares at it: “I thought so.”<br/>
                        “Now your all set,” says Moe. <br/>
                        <br/>
                        The client walks away. “Ok, see you on payday!”  As he walks away, the three stooges see the shredded back. <br/>
                        <br/>
                        Moe goes crazy. “What kind of brushing are you doing around here?” He slaps Shemp all over. <br/>
                        <br/>
                        “He had a loose baste,” replies Shemp.  <br/>
                        <br/>
                        Questions: <br/>
                        Did Shemp slice through the jacket by accident? Or on purpose? <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 30 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story for lesson 30, titled: Sing a song of six pants. – part 8  There is exactly what I want!<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate):<br/> 
                        
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/486131140"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, the thief’s girlfriend obtains the clothes he left behind the three stooges’ shop. <br/>
                        <br/>
                        “What can we do for you?” asks Larry. <br/>
                        “I’d like to have this dress dyed,” responds the beautiful woman. <br/>
                        “What color?” asks Moe.<br/>
                        “Henna color,” responds the woman. <br/>
                        “You mean henna color at all?” quips Shemp.<br/>
                        “No. H-E-N-N-A, henna. It’s kind’a a Brown-reddish,” says the woman. <br/>
                        “I’ve never heard of brown-reddish,” says Shemp. <br/>
                        “I’m also interested in a sportscoat and a pair of slacks, for my brother.” Then she sees something and points to it, exclaiming: “There’s exactly what I want!”  The stooges bring her the coat and pants. <br/>
                        <br/>
                        “How much would that be?” asks the woman. <br/>
                        “Forty-two,” says Shemp. “Forty-seven,” says Larry “Thirty-nine,” chimes Moe. Moe turns to Shemp. “You better add that up.” <br/>
                        <br/>
                        Shemp becomes a cash register machine; spits out the total: “$411 dollars but there’s a sale today.” <br/>
                        “You get 10% off,” says Larry. <br/>
                        “That makes it $42.50,” says Moe. [bad math, no wonder they’re in financial problems]<br/>
                        “I’ll take them,” says the woman. Moe interrupts: “Oh, just a moment. I’ll be right back…”<br/>
                        <br/>
                        Moe leaves to remove and replace the paper with the combination to the safe from the pants. He returns and gives her the pants. “There we are.” <br/>
                        <br/>
                        She checks that a paper is inside the pants. There it is. Then she takes out money hidden inside the jacket. “You said $42.50?” she asks. Then she counts in front of them $450, not $42.50. “There you are. Keep the change.” Then she leaves. <br/>
                        <br/>
                        “Aren’t we the saps,” sighs Moe, “we had a fortune in our hand and let it slide through.” <br/>
                        “I’m gonna search all the clothes,” says Shemp. <br/>
                        “Me too,” adds Larry.  The three stooges search the other clothes for money, just in case. <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 31 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story for lesson 31, titled: Sing a song of six pants. – part 9  Ok, bud. Where’s the piece of paper in my pants’ pocket?<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate): <br/>
                        
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/486133201"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, the gang of thieves visit the three stooges’ tailor shop in search for the piece of paper with the combination number to a safe. <br/>
                        <br/>
                        The thief’s girlfriend comes inside there home. <br/>
                        <br/>
                        “Hi!”<br/>
                        “You got’em?”<br/>
                        “There you are, sugar plum,” answers the girlfriend. <br/>
                        “I knew you’d come through, baby,” smiles the thief. As he checks his pants for a piece of paper with the combination to a safe, he notices it is not it. It’s just a receipt. Then he checks the money inside his jacket. But his girlfriend reacts because she’s the one that took it, paid $450 to the three stooges and kept the rest for herself. <br/>
                        “They even too my bankroll, those dirty crooks! Come on boys, we’re going calling.” <br/>
                        <br/>
                        The gang of thieves enter the shop disguised with beards. <br/>
                        <br/>
                        “Yes, what can we do for you, sirs?” says Shemp. <br/>
                        “We’d like to have our suits cleaned and pressed while we wait,” says the thief. <br/>
                        “Why, certainly. Yes, sir,” says Shemp. <br/>
                        <br/>
                        The stooges start taking off the sportscoats, when Shemp recognizes the name of the thief on the sportscoat. He yanks off the fake beard and slaps the thief’s face with it.<br/>
                        <br/>
                        “All right, bub, where’s that piece of paper that was in my pant’s pocket?” asks the thief.<br/>
                        “You mean the one with the numbers on it?” asks Shemp. <br/>
                        “That’s it. That’s it.”<br/>
                        “And the letters L-R-L-R-L-R-L?”<br/>
                        “Yes. Yes. Where is it?”<br/>
                        “I never saw it,” replies Shemp. <br/>
                        The thief takes Shemp by his coat. “Oh, a wise guy…Ok, fellas. Make them talk!”<br/>
                        <br/>
                        One gang member runs after Larry. The other after Moe. <br/>
                        <br/>
                        The thief turns around, and Shemp has his fake beard on. <br/>
                        <br/>
                        “Where’s that guy that was here?” asks the thief. <br/>
                        <br/>
                        Shemp fakes his voice. “He went that’a way…” He points behind him. <br/>
                        <br/>
                        “Oh, I’ll get him, I’ll get him…”  The thief understands that man with the beard is Shemp, and carries him away. <br/>
                        <br/>
                        Meantime, Larry kicks one of the thieves in the stomach.<br/>
                        <br/>
                        The other thief searches Moe for the paper, tickling Moe and making him laugh. <br/>
                        <br/>
                        “Cut it out. I’m ticklish!” cries Moe.  <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 32 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story for lesson 32, titled: Sing a song of six pants. – part 10  Wait a minute. When do we get our reward? <br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 1 minute video (approximate):<br/> 
                        
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/486135210"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        <br/>
                        In this episode, the three stooges outwit the thieves and the police officer takes them away. <br/>
                        <br/>
                        One of the thieves asks Moe: “All right. Come across with that paper.” <br/>
                        “Oh, the paper?” answers Moe. <br/>
                        “Yeah, the paper.” <br/>
                        “Why didn’t you say so,” Moe places his hand in his pocket. When he takes it out, he punches the thief, sending him to the ironing press. Moe places the thief’s head inside the press. <br/>
                        <br/>
                        “Oh, his head is steaming!” says Moe. Then he hits him on the head with a brush. The thief falls.<br/>
                        <br/>
                        Meantime, the main thief hits Shemp as he hangs from a revolving clothes hanger. Shemp inadvertently kicks the thief knocking him cold to the ground. <br/>
                        <br/>
                        “Hey, Moe, Larry! Get me off here!” cries Shemp.  Moe and Larry bring him down from the revolving hanger.<br/>
                        “We got him! We got Hargan!” cries Moe. <br/>
                        <br/>
                        The officer comes in: “It’s Hargan, all right. Nice work, boys.”<br/>
                        “We’re in the chi-ching. The mula!” cries Larry. <br/>
                        “Now we can pay Skin and Flynt (their creditor)!” says Moe.<br/>
                        “Oh, a couple of more! Hold him while I phone for a wagon,” says the Officer.<br/>
                        “Wait a minute,” says Moe, “when do we get our reward?”<br/>
                        “Reward? Oh, I get the reward,” quips the Officer. “But here’s some tickets for the policemen’s ball.”<br/>
                        “Tickets to the policeman’s ball?” says Moe. <br/>
                        <br/>
                        Shemp and the boys start crying. Suddenly, Shemp goes to the thief’s pocket for a handkerchief, and discovers a lot of money. The officer comes in, and they hide the money. As they say goodbye to the Officer, they start counting the money: $550. Shemp throws away the $50 dollar bill. Then they all dive to the floor for it.   THE END.<br/>
                        <br/>
                        Questions: <br/>
                        What does “chi-ching” and “mula” mean?  Were the stooges able to pay off their creditor afterall?<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 33 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story for lesson 33, titled: Malice in the Palace. – <br/>
                        part 1  Take it off! Take it off!<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate): <br/>
                        
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/486137288"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        This is a new movie. In this episode, two shady men enter a restaurant. The three stooges are the waiters. They spill the food on the two men. <br/>
                        <br/>
                        Two shady men enter a restaurant. One man shoves a dog off the table. <br/>
                        <br/>
                        “It’s passed the hour. I wonder what delays Abudallah,” says one man. <br/>
                        “Fear not, most illustrious Hassan Ben Sober, fear not,” answers the other man. “Abudallah will get the map by hook or crook, as sure as my name is Genarrama.”<br/>
                        “Quite so!” Both men share a strong handshake. <br/>
                        <br/>
                        The men sit down. They hit the table with their hands, demanding service. <br/>
                        <br/>
                        Here come the three stooges, each with several dishes filled with food in their arms and hands. Shemp kneels down just as Larry and Moe place dishes over his head. <br/>
                        <br/>
                        “At your service,” says Moe. <br/>
                        “Boy, if I hadn’t ducked we would’ve collided for sure. What a narrow escape!” says Shemp. <br/>
                        <br/>
                        Shemp stands up, and all the food falls on the two men. <br/>
                        <br/>
                        Moe takes Shemp by the hair: “What’s the matter with you?”<br/>
                        <br/>
                        One of the men is covered in spaghetti. He starts shouting: “Take this off! Take this off!”<br/>
                        <br/>
                        Shemp takes scissors and cuts the bangs of the spaghetti’s, like a haircut. <br/>
                        <br/>
                        “Just a minute now, I’ll take it right off,” says Shemp. “I would leave it on. People don’t know if you’re coming or going with this…” Shemp finishes the haircut. “There you are sir, you look very pretty, and the spaghetti is as good as new.”<br/>
                        “Why, you…” The man takes out a knife. So does the other man. <br/>
                        “How dare you!” he shouts. The stooges are in trouble. <br/>
                        <br/>
                        Questions: What does “by hook or by crook” mean? <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 34 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story for lesson 34, titled: Malice in the Palace. – <br/>
                        part 2  All we’ve got left is rabbit and hot dog.<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate): <br/>
                        
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/486139191"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        <br/>
                        In this episode, the two shady men are joined by a third man that tells them about a diamond, a curse and hands them a map to locate it.<br/>
                        <br/>
                        Two shady customers are angry at the three stooges for having spilled food on them. <br/>
                        <br/>
                        “How dare you?” cries an angry client. <br/>
                        “A thousand pardons,” interrupts Larry, “it was unavoidable.” <br/>
                        “Wait a minute! Wait a minute!” adds Moe as the client shows him his knife. “This would degrade you.”<br/>
                        “It is true. I’m an aristocrat,” answers the client, “I am Hassam Bin Sober!”<br/>
                        “I had a few too many (drinks) myself,” quips Shemp. He starts laughing, and Moe hits him with spaghetti.<br/>
                        “Be quiet!” says Moe. “Clean up this mess!”<br/>
                        <br/>
                        Shemp lowers Moe to the ground with a hand gesture. The stooges pick up the mess under the table. <br/>
                        <br/>
                        Then, another shady man appears. The other two shady men are plotting something. <br/>
                        <br/>
                        “Greetings, greetings.”<br/>
                        “Did you bring the map, Papadallah?” asks Hassam<br/>
                        “Yes, chief,” answers Papadallah, “as I slit his throat, with his dying breath he said someone else had a duplicate map.”<br/>
                        “Tis bad. We must leave at once if we are the first to reach King Root’n Toot’n’s tomb,” adds the other man.<br/>
                        “Make preparations to depart immediately,” whispers Hassam.<br/>
                        “All right, chief. But remember the curse: ‘He who touches the jewel first is doomed to a thousand deaths.” <br/>
                        “We must secure the services of three. Then when we get them to do our bidding,” Hassam gestures cutting their throats. <br/>
                        “Yes, but we best have food before we travel,” answers the other shady man.<br/>
                        “Right. Service! Service!” shouts Hassam. <br/>
                        <br/>
                        Enter the three stooges. They hit each other as they salute their customers. <br/>
                        “What will you have?” asks Larry.<br/>
                        “We want…”<br/>
                        “We don’t have any more,” interrupts Moe. <br/>
                        “All we’ve got left is rabbit and hot dogs,” says Larry.<br/>
                        “Rabbit,” answers Hassam.<br/>
                        “Hot dog,” answers the other man.<br/>
                        “Hot dog! He’ll take rabbit!” exclaims Larry. <br/>
                        “Ok, coming right up!” shouts Moe. He and Larry take off to the kitchen. Shemp stays behind.<br/>
                        “Ok, coming right up!” says Shemp, as he takes out a magician’s handkerchief from his jacket. “Here we are, pepper and salt.” Shemp pulls out the pepper and salt shakers from a magician’s performance, and wipes the silverware with the client’s beard. The client slaps Shemp’s hand. <br/>
                        “That’s busboy’s art.”<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 35 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story for lesson 35, titled: Malice in the Palace. – <br/>
                        part 3  When it comes to cookin’, I’m the cat’s meow!<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/486141303"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, two shady clients, Moe and Shemp, believe Larry has served them a dog and a cat for food. <br/>
                        <br/>
                        A cat steals a sausage from Larry’s kitchen.<br/>
                        <br/>
                        “Scat!” Larry chases the cat through the dining room and drags him back into the kitchen. “Come here!”<br/>
                        <br/>
                        The clients, Moe and Shemp just stare in awe. Then they hear Larry cutting something, but the cat continues to meow. It seems like if Larry killed the cat. The clients seem worried. <br/>
                        <br/>
                        One of the clients is about to drink a bottle of catsup. He screams: “Catsup!” because it starts with “cat.” <br/>
                        <br/>
                        Now a dog steals some meat from Larry’s kitchen. He runs after him through the dining hall, in the eyes of the clients, Moe and Shemp. Larry takes the dog back into his kitchen. “Come here, you. Now I’ve got you. Come one!”<br/>
                        <br/>
                        “It couldn’t be…or could it?” whispers Shemp to Moe. Is Larry killing the dog and cat?<br/>
                        <br/>
                        Larry proceeds to chop some lettuce, but the guests think he’s killing the dog. A window falls on the dog’s tail, and it screams while Larry finishes the last cuts to the lettuce. The guests, Moe and Shemp believe Larry is killing the dog as well.<br/>
                        <br/>
                        Suddenly, Larry enters the dining hall with a hot dog platter and a rabbit plate. <br/>
                        <br/>
                        “Here you are gentlemen,” Larry serves the guests. “When it comes to cooking, I’m the cat’s meow.”<br/>
                        <br/>
                        That leaves the guests, Moe and Shemp very nervous. They believe Larry served them a dog and a cat, instead of hot dog and rabbit.  <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 36 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story for lesson 36, titled: Malice in the Palace. – <br/>
                        part 4  These three, they are the ones to get the jewel for us.<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 3 minute video (approximate): <br/>
                        
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/486143920"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, two shady guests force Moe and Shemp to eat what they think is a dog and a cat in the place of hot dogs and a rabbit dish. <br/>
                        <br/>
                        “Eat!” shout the two shady men to Moe and Shemp, thinking that the platters are dead dog and cat. (From the previous episode). <br/>
                        <br/>
                        Suddenly, the dog and cat appear under the table. <br/>
                        <br/>
                        As Shemp and Moe start touching or poking their food, they hear the dog and cat under the table, and think they are the dead dog and cat barking and meowing from the afterlife. <br/>
                        <br/>
                        “Did you hear anything?” whispers Shemp to Moe. <br/>
                        “Yeah, I did,” answers Moe with a knot in his throat. <br/>
                        <br/>
                        They continue poking their food, and listen to the cat and dog. They get up, but the two thieves, with knives-in-hand, force them down. “Eat,” they shout. <br/>
                        <br/>
                        Shemp and Moe continue listening to the dog and cat. Shemp even adds pepper to his meal, which makes the dog sneeze. He answers instinctively: “Gesundheit!” Then he shivers at the thought that the dog answered him. <br/>
                        <br/>
                        Moe and Shemp start crying. Again, the thieves shout: “Eat!”<br/>
                        <br/>
                        Suddenly, Larry enters with a big smile and singing. <br/>
                        <br/>
                        “I forgot the mustard,” says Larry. <br/>
                        “So you are the perpetrator of this,” exclaims one thief. “Sit down and eat!” <br/>
                        “A pleasure!” responds Larry, “thank you!”<br/>
                        <br/>
                        He eats the food and there is no barking from the dog or meowing from the cat. The thieves are convinced the food is good, push Larry to the side and get ready to eat. Shemp takes a hot dog, but one of the thieves slaps his hand. The sausage falls under the table, where the dog and cat are. <br/>
                        <br/>
                        Suddenly, as soon as both thieves take a bite of their food, the cat attacks the dog in order to eat the sausage, but both men think they bit into the dead dog and cat. As they get up, they turn the table upside-down. Now everyone sees that the cat and dog are actually alive. <br/>
                        <br/>
                        “There’s the dog and cat! Why didn’t you tell me?” asks Moe. <br/>
                        “What?” asks Larry.<br/>
                        <br/>
                        Moe slaps Larry across the face, and Shemp gets hit by Moe trying to defend Larry. <br/>
                        <br/>
                        Both thieves see the three stooges. One whispers to the other:<br/>
                        <br/>
                        “These three, they are the ones that get the jewel for us…”<br/>
                        “Right!” answers the other thief. <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 37 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story for lesson 37, titled: Malice in the Palace. – <br/>
                        part 5  Now, don't worry. We will be disguised.<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 3 minute video (approximate): <br/>
                        
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/486146181"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, Moe, Larry and Shemp discover the two shady men are not aristocrats, but regular workers posing as important people with a map to a large diamond. The stooges kick the men out of their restaurant, and embark on a journey to retrieve the diamond. <br/>
                        <br/>
                        “Gentlemen,” exclaims one of the thieves. <br/>
                        “Who came in?” asks Shemp. He doesn’t’ believe he and the stooges are gentlemen. <br/>
                        “No you,” says the thief. “How would you boys like to make $1,000 champlenas? (an invented monetary unit).” The thieves show their knives to the stooges. <br/>
                        “You’ve convinced us,” says Moe. “What do we have to do?”<br/>
                        “There is a diamond worth a king’s ransom,” says the other thief. <br/>
                        “Gentlemen, this map will lead us to the treasure,” whispers the other thief. “Now pay strict attention…”<br/>
                        <br/>
                        Suddenly, a hand appears with a knife and a piece of paper attached to it. He throws the knife and it lands on the table next to the map the thief opened. The note reads: “Hassan Ben Sober (similar to hasn’t been sober). You are late. I got the diamond. You got the gate.” Signed: Omagosh (similar to Oh my gosh), Emir of Shmo. (Shmo is slang for a stupid person). <br/>
                        <br/>
                        “Oh, ruined! Defeated!” exclaims Hassan. “That murderous cutthroat. He has the famous 100 karat diamond!”<br/>
                        “One hundred carrots? Is he sure it wasn’t onions?” teases Shemp. <br/>
                        “Quiet!” says Moe, and then he hits Shemp on the nose. <br/>
                        “This map is useless to me now,” says Hassan as he throws the map on the table. “With that diamond I could’ve quit my job as a doorman at the Oasis hotel!” <br/>
                        <br/>
                        Both thieves start crying. <br/>
                        <br/>
                        “Hey spinach-chin, do you mean to tell me you’re only a doorman?” asks Moe, indignantly. <br/>
                        “Yes,” cries the thief. <br/>
                        “Well there’s the door, man,” Shemp points to the exit. Larry and Moe hit the thief and kick him out. <br/>
                        <br/>
                        The other thief takes out the knife and cries: “Will you give me $5 for this?”<br/>
                        “No, but I’ll give you two,” says Moe.<br/>
                        “I’ll take it!” exclaims the thief. <br/>
                        <br/>
                        Moe pokes the thief’s eyes with two fingers. Larry and Moe get rid of the thief. <br/>
                        <br/>
                        “Hey,” observes Moe, “there’s only one 100-karat diamond and it comes from Root’n Toot’n’s tomb.”<br/>
                        “That’s government property,” says Shemp.<br/>
                        “Right, and if we return it, we’ll get a big reward!” says Moe.<br/>
                        “Yeah, maybe $50,000 bucks,” exclaims Larry. “Boy, with that kind of money we could rent a one-bedroom apartment in Hollywood…maybe…”<br/>
                        “But the Emir of Shmo is a cutthroat. Suppose he cuts my throat…?” opines Shemp.<br/>
                        “Well…?” as in “so what?” respond Larry and Moe.<br/>
                        “No, don’t worry,” says Moe. “We’ll be disguised. We’ll find the shortest way…Now here, study this map closely...” We see the map with funny variations of names. <br/>
                        <br/>
                        “We’ll start here at Jerkola (“jerk” is a person that treats people bad), down the Insane River (“insane” means crazy), over the Giva Dam (that sounds like “give a damn” which is a vulgar way of stating “I don’t care,” through Pushover (“pushover” means someone who lets himself be taken for a fool), across Shmoland to the stronghold of Shmo.”<br/>
                        <br/>
                        The three stooges arrive at the stronghold of Shmo. A guard at the gate let’s a merchant pass. Here come the three stooges dressed as Santa Claus on a carriage pulled by a donkey. <br/>
                        <br/>
                        “Hark! Who goes there?” cries the guard. <br/>
                        “Santa Claus!” all the stooges answer at the same time.<br/>
                        “Ah, there isn’t any Santa Claus!” replies the guard.<br/>
                        “Oh, yes there is, and we’ve got a present for you”<br/>
                        “You have? Where?”<br/>
                        “Right here…” Moe opens a large bag, while Larry hits the guard on the head with a hammer. The guard falls inside the bag. <br/>
                        “Okay, boys…” says Moe. <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 38 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2. The Audio Story for lesson 38, titled: Malice in the Palace. – <br/>
                        part 6  Give me the diamond lest evil befall you!<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 3 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/486148626"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, the three stooges find the 100-karat diamond. <br/>
                        <br/>
                        “Let’s find the diamond,” whispers Moe.<br/>
                        <br/>
                        The Emir of Shmo enters laughing. He’s reading the newspaper funnies. <br/>
                        <br/>
                        “There he is, and he’s wearing the diamond,” says Moe, “now’s our chance to get it.” <br/>
                        <br/>
                        The stooges retreat behind the curtains. A moment later, they appear as an evil spirit, as each stooge is set upon each other’s shoulders cloaked as a very tall evil spirit. <br/>
                        <br/>
                        “Fee fi fo fum, I’m the evil spirit that guards the Root’n Toot’n diamond. Give it to me lest evil befall you,” threatens Shemp, who sits on top of Larry, who sits on top of Moe. <br/>
                        <br/>
                        “Yes, sir. Yes, sir!” exclaims the Emir of Shmo. He throws the diamond to Shemp. <br/>
                        “The Spirit commands you to stand on your head in the lily-pond until further notice!” shouts Shemp. <br/>
                        <br/>
                        The Emir of Shmo does as commanded. <br/>
                        <br/>
                        Suddenly, a tall African guard enters with a sword. <br/>
                        <br/>
                        “Quick, somebody’s coming. Scram!” shouts Shemp.<br/>
                        <br/>
                        The three stooges run away, but they hit the doorway and the fall to the floor. The African guard runs after Shemp. The stooge finds a small knife on the wall, and takes it out. He discovers his knife is much smaller than the African’s sword. <br/>
                        <br/>
                        “Here, hold this,” Shemp hands the African his knife. The guard takes it, and Shemp hits him on the head fruit. <br/>
                        <br/>
                        “I’ll kill you!” shouts the guard.<br/>
                        <br/>
                        Shemp lodges a banana inside guards mouth. Moe and Larry return to the scene. They take the hat off the guard.<br/>
                        <br/>
                        “Your hat,” says Moe.<br/>
                        “Thank you,” responds the guard. He notices he’s been tricked. “Why, you…”<br/>
                        <br/>
                        Moe yanks on a piece of curtain that makes a large vase fall on the head of the African guard. Shemp takes it once more and hits him again over the head. The guard fall hard to the ground. <br/>
                        <br/>
                        “Well, that’s that…” says Moe. <br/>
                        <br/>
                        Before the leave, they notice the Emir of Shmo is still upside down inside the lily-pond. It appears the Shmo has drunk all the lily-pond water. <br/>
                        <br/>
                        “Hey, the Shmo is flooded,” cries Moe. <br/>
                        <br/>
                        They bend the Shmo, and water comes out of him as if he were a fountain. <br/>
                        <br/>
                        Moe turns to metallic fountain statue: “Sister, looks like you’re out of a job!”<br/>
                        <br/>
                        The three stooges laugh. “Where’s that diamond?”<br/>
                        <br/>
                        Suddenly, the fountain statue turns, and showers the stooges with water. They fight it off, and run away with the diamond. <br/>
                        <br/>
                        THE END.<br/>
                        </p>
                      </Paper>}




                      <button style={{fontSize: 'large', border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.user.learningProgress.level == 2 && this.state.user.learningProgress.lesson == index + 30 && this.state.user.learningProgress.step == 2){
                          this.updateProgress(index + 30, 3)
                        }
                        this.setState({dialogAudio: false, dialogExercise: true})
                      }} >Continue</button>
                      <div>

                      </div>
                    </Dialog>










                    <Dialog
                      open={this.state.dialogExercise}
                      onClose={() => this.setState({dialogExercise: false})}
                      fullScreen
                    >
                      <div style={{width: '100%', height: '100%', backgroundColor: '#eff7fa'}}>
                      <img src={logo} style={{width: 200, marginTop: -20}} />
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 20, right: 20}} onClick={() => this.setState({dialogExercise: false})} />
                      {lesson.data.title == 'Refresher Course & Grammar' ? <h1 style={{textAlign: 'center',fontSize: 'x-large'}}>Step 2. EXCERCISES:</h1> :<h1 style={{textAlign: 'center',fontSize: 'x-large'}}>Step 3. EXCERCISES:</h1> }
                      <p style={{width: '100%', textAlign: 'center'}}>Don’t forget to use Reverso Context if you don’t understand a word or question.</p>
                      <Paper style={{width: '90%', margin: '30px auto', textAlign: 'center', padding: 10}}>
                        {this.state.Index >= lesson.questions.length ? <div style={{textAlign: 'center', width: '90%', margin: 'auto'}}>
                                <H3>Your Score:</H3>
                                <H3 style={{fontSize: 'x-large', fontWeight: 'bold'}}>{lesson.questions.length - this.state.wrongQuestions.length}/{lesson.questions.length}</H3>
                                {((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) == 1 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>Awesome!</H3> : ((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) >= 0.9 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>Great!</H3> : ((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) >= 0.8 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>Good Job!</H3> : ((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) >= 0.7 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>Getting Close!</H3> : ((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) >= 0.6 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>We've got work to do</H3> : <H3>That's why we're here. Let's do it again!</H3>}
                                {((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) < 0.5 && <Button onClick={() => {
                                  this.setState({word1: '', word2: '', word3: '', word4: '',word5: '', word6: '', word7: '', result: null, Index: 0, wrongQuestions: []})
                                }} variant="contained" color="primary">
                                  Repeat Exercise
                                </Button>}
                              </div> : ''
                            
                            
                              
                          

                        }
                        
                        {lesson.questions.map((question,qIndex) => {
                          
                          if (qIndex == this.state.Index){
                           
                            if (question.type == 'ftb'){
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>Fill in the blank</h2>
                                  <div style={{display: 'flex', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, flexWrap: 'wrap', margin: '10px auto', width: '90%', justifyContent: 'center'}}>
                                  {question.question && question.question.split(' ').map((word, index) => {
                                    if (word == '__1__'){
                                      
                                      return (
                                        <TextField style={{width: 'max-content', minWidth: 100, marginTop: 5}} value={this.state.word1} onChange={( e) => {this.setState({word1: e.target.value}) }} />
                                      )
                                    }else {
                                      return (
                                        <H3 >{word}</H3>
                                        
                                      )

                                    }
                                    
                                  })

                                  }

                                  </div>
                                  {this.state.result == null && <Button variant="contained" style={{backgroundColor: '#ebebeb'}} onClick={() => {
                                    if(this.state.word1.split(" ")[0].toLowerCase() == question.ans.toLowerCase()){
                                      this.setState({result: true})
                                    } else {
                                      console.log(this.state.word1.split(" ")[0].toLowerCase())
                                      this.setState({result: false})
                                    }
                                  }}>Check</Button>}
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}
                                  <div style={{display: 'flex'}}>

                                    {this.state.result == false && <div style={{display: 'flex'}}>
                                      <H3 >Correct Answer: <span style={{color: '#000080'}}>{question.ans}</span></H3>
                                    </div>

                                    }
                                    
                                    {this.state.result != null && <div style={{width: '100%', textAlign: 'right', margin: 'auto'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                                  </div>
                                </div>

                              )
                            } else if (question.type == 'mcq'){
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>Multiple choice</h2>
                                  <div style={{display: 'flex', flexWrap: 'wrap', width: '90%', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, margin: '10px auto', justifyContent: 'center'}}>
                                  {question.question && question.question.split(' ').map((word, index) => {
                                    if (word == '____'){
                                      
                                      return (
                                        <H3 style={{backgroundColor: this.state.word1 == ''? '#bebebe' : this.state.word1 == question.ans ? '#008000' : '#c91b1b', color: 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0}}>{this.state.word1}</H3>
                                      )
                                    }else {
                                      return (
                                        <H3>{word}</H3>
                                        
                                      )

                                    }
                                    
                                  })

                                  }

                                  </div>
                                  <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                                  {question.options.map(option => {
                                    return (
                                      <div style={{margin: 10, cursor: 'pointer', padding: 2, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={() => {
                                        this.setState({word1: option})
                                        if (option == question.ans){
                                          this.setState({result: true})
                                        } else {
                                          this.setState({result: false})

                                        }
                                      }}>
                                        <H3>{option}</H3>

                                      </div>
                                    )
                                  })

                                  }

                                  </div>
                                  
                                  
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}

                                  <div style={{display: 'flex'}}>
                                  {this.state.result == false && <div style={{display: 'flex'}}>
                                      <H3 >Correct Answer: <span style={{color: '#000080'}}>{question.ans}</span></H3>
                                    </div>

                                    }
                               
                                  {this.state.result != null && <div style={{width: '100%', textAlign: 'right'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                              </div>
                                </div>

                              )
                            } else if (question.type == 'co'){
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>Correct order</h2>
                                  
                                  <div style={{display: 'flex', flexWrap: 'wrap', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, width: '90%',margin: '10px auto', justifyContent: 'center'}}>
                                  {question.question && question.question.split(' ').map((word, index) => {
                                    if (word == '__1__'){
                                      
                                      return (
                                        <H3 style={{backgroundColor: this.state.word1 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word1 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word1}</H3>

                                      )
                                    }else if (word == '__2__'){
                                      return (
                                        <H3 style={{backgroundColor: this.state.word2 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word2 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word2}</H3>

                                      )

                                    }else if (word == '__3__'){
                                      return (
                                        <H3 style={{backgroundColor: this.state.word3 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word3 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word3}</H3>

                                      )

                                    }else if (word == '__4__'){
                                      return (
                                        <H3 style={{backgroundColor: this.state.word4 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word4 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word4}</H3>

                                      )

                                    }else {
                                      return (
                                        <H3>{word}</H3>
                                        
                                      )

                                    }
                                    
                                  })

                                  }

                                  </div>
                                  <div style={{display: 'flex', flexWrap: 'wrap', width: '90%', margin: 'auto', justifyContent: 'center'}}>
                                  {question.options.map(option => {
                                    return (
                                      <div style={{margin: 10, cursor: 'pointer', padding: 2, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={() => {
                                       
                                        if (this.state.word1 == ''){
                                          this.setState({word1: option})
                                        }else if (this.state.word2 == ''){
                                          this.setState({word2: option})

                                          if (question.ans.split(' ').length == 2 && this.state.word1 != ''){
                                          

                                            if (question.ans == this.state.word1 + ' ' + option){
                                              this.setState({result: true})
                                            }else {
                                              this.setState({result: false})
                                            }
                                          }

                                        }else if (this.state.word3 == '' && this.state.word2 != ''){
                                          this.setState({word3: option})
                                          console.log(question.ans)
                                          console.log(question.ans.split(' ').length)
                                          if (question.ans.split(' ').length == 3){
                                          
                                            if (question.ans == this.state.word1 + ' ' + this.state.word2 + ' ' + option){
                                              this.setState({result: true})
                                            } else {
                                              this.setState({result: false})
                                            }
                                          }
                                        }else if (this.state.word4 == '' && this.state.word3 != ''){
                                          this.setState({word4: option})
                                          if (question.ans.split(' ').length == 4){
                                            if (question.ans == this.state.word1 + ' ' + this.state.word2 + ' ' + this.state.word3 + ' ' + option){
                                              this.setState({result: true})
                                            }else {
                                              this.setState({result: false})
                                            }
                                          }
                                        }
                                        
                                      }}>
                                        <H3>{option}</H3>

                                      </div>
                                    )
                                  })

                                  }

                                  </div>
                                  
                                  
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}

                                  <div style={{display: 'flex'}}>
                                  {this.state.result == false && <div>
                                      <H3 style={{width: 200}}>Correct Answer:</H3>
                                      <div style={{display: 'flex'}}>
                                      {question.ans.split(' ').map(word => {
                                        return (
                                          <h4 style={{ minWidth: 50, margin: 10, padding: 5, backgroundColor: '#ebebeb', borderRadius: 5}}>{word}</h4>
                                        )
                                      })

                                      }

                                      </div>
                                      
                                    </div>

                                    }
                               
                                  {this.state.result != null && <div style={{width: '100%', textAlign: 'right'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                              </div>
                                </div>

                              )
                            }else if (question.type == 'tf'){
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>True or False</h2>
                                  
                                  <div style={{display: 'flex', flexWrap: 'wrap', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, width: '90%',margin: '10px auto', justifyContent: 'center'}}>
                                  {question.question && question.question.split(' ').map((word, index) => {
                                    if (word == '____'){
                                      
                                      return (
                                        <TextField variant="outlined" value={this.state.word1} onChange={( e) => {this.setState({word1: e.target.value}) }} />
                                      )
                                    }else {
                                      return (
                                        <H3>{word}</H3>
                                        
                                      )

                                    }
                                    
                                  })

                                  }

                                  </div>
                                  <div style={{display: 'flex', width: '90%',margin: 'auto', justifyContent: 'center'}}>
                                  <H3 style={{margin: 10, cursor: 'pointer', padding: 8, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={()=> {
                                    if (question.ans == true){
                                      this.setState({result: true})
                                    } else {
                                      this.setState({result: false})

                                    }
                                  }}>True</H3>
                                  <H3 style={{margin: 10, cursor: 'pointer', padding: 8, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={()=> {
                                    if (question.ans == false){
                                      this.setState({result: true})
                                    } else {
                                      this.setState({result: false})

                                    }
                                  }}>False</H3>

                                  </div>
                                  
                                  
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}

                                  <div style={{display: 'flex'}}>

                                  {this.state.result == false && <div style={{display: 'flex'}}>
                                      <H3 >Correct Answer: <span style={{color: '#000080'}}>{question.ans == true ? 'True' : 'False'}</span></H3>
                                    </div>

                                    }
                               
                                  {this.state.result != null && <div style={{width: '100%', textAlign: 'right'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                              </div>
                                </div>

                              )
                            }else if (question.type == 'match'){
                              
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>Match the following</h2>
                                  
                                  <div style={{display: 'flex', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, flexWrap: 'wrap', width: '90%',margin: '10px auto', justifyContent: 'center'}}>
                                  {question.question.map((word, index) => {
                                    return (
                                      <div>
                                        <H3 style={{backgroundColor: '#bebebe', borderRadius: '5px 5px 0px 0px', padding: 10, marginBottom: 1}}>{word}</H3>
                                        <H3 style={{backgroundColor: '#cdeefd', borderRadius: '0px 0px 5px 5px', padding: 10, marginTop: 1}}>{index == 0? this.state.word1 : index == 1? this.state.word2 : index == 2 ? this.state.word3 : index == 3 ? this.state.word4 : index == 4 ? this.state.word5 : index == 5 ? this.state.word6 : index == 6 ? this.state.word7 : ''}</H3>

                                      </div>

                                    )
                                    
                                    
                                    
                                  })

                                  }

                                  </div>
                                  <div style={{display: 'flex', flexWrap: 'wrap', width: '90%', margin: '10px auto', justifyContent: 'center'}}>
                                  {question.shuffled.map(option => {

                                    return (
                                      
                                        <H3 style={{margin: 10, cursor: 'pointer', padding: 8, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={()=> {
                                          if (this.state.word1 == ''){
                                            this.setState({word1: option})
                                          } else if (this.state.word2 == ''){
                                            this.setState({word2: option})
                                            if (question.ans.length == 2){
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word3 == ''){
                                            this.setState({word3: option})
                                            if (question.ans.length == 3){
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word4 == ''){
                                            this.setState({word4: option})
                                            if (question.ans.length == 4){
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, this.state.word3, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word5 == ''){
                                            this.setState({word5: option})
                                            if (question.ans.length == 5){
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word6 == ''){
                                            this.setState({word6: option})
                                            if (question.ans.length == 6){
                                              console.log(question.ans)
                                              console.log(JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, this.state.word5, option]))
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, this.state.word5, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word7 == ''){
                                            this.setState({word7: option})
                                            if (question.ans.length == 7){
                                              console.log(question.ans)
                                              console.log(JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, this.state.word5, this.state.word6, option]))
                                              
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, this.state.word5, this.state.word6, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }
                                        }}>{option}</H3>

                                     
                                    )
                                  })

                                  }

                                  </div>
                                  
                                  
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}

                                  <div style={{display: 'flex'}}>
                                  {this.state.result == false && <div>
                                      <H3 style={{width: 200}}>Correct Answer:</H3>
                                      <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                      {question.ans.map(word => {
                                        return (
                                          <h4 style={{ minWidth: 50, maxWidth: 'max-content', margin: 10, padding: 5, backgroundColor: '#ebebeb', borderRadius: 5}}>{word}</h4>
                                        )
                                      })

                                      }

                                      </div>
                                      
                                    </div>

                                    }
                               
                                  {this.state.result != null && <div style={{width: '100%', textAlign: 'right'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                              </div>
                                </div>

                              )
                            } 
                          
                            
                          }
                        })

                        }


                      </Paper>


                      {this.state.Index >= lesson.questions.length && <button style={{border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.Index >= lesson.questions.length){

                          if (this.state.user.learningProgress.level == 2 && this.state.user.learningProgress.lesson == index + 30 && this.state.user.learningProgress.step == 3){
                            if (lesson.data.title == 'Refresher Course & Grammar'){
                              this.updateProgress(index + 31, 1)
                            }else {
                              this.updateProgress(index + 30, 4)
                            }
                            
                          }

                          if (lesson.data.title == 'Refresher Course & Grammar'){
                            this.setState({dialogExercise: false})
                          }else {
                            this.setState({dialogExercise: false, dialogResponse: true})
                          }
                          
                          this.setState({word1: '', word2: '', word3: '', word4: '',word5: '', word6: '', word7: '', result: null, Index: 0, wrongQuestions: []})
                        }


                        
                      }} >Continue</button>}
                      <div>

                      </div>
                      </div>
                    </Dialog>















                    <Dialog
                    open={this.state.dialogResponse}
                    onClose={() => this.setState({dialogResponse: false})}
                    fullScreen
                    >
                      <img src={logo} style={{width: 200, marginTop: -20}} />
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 20, right: 20}} onClick={() => this.setState({dialogResponse: false,recording: false,
                        audio: '',
                        isRecording: false,
                        blobURL: '',
                        recorded: false,
                        response: '',
                        responseSent: false})} />
                      <h1 style={{textAlign: 'center',fontSize: 'x-large'}}>Step 4. Track Your Progress</h1>

                      <Paper style={{width: '80%', margin: '30px auto',padding: 20, textAlign: 'center'}}>
                        <H3 style={{margin: 20}}>Please speak into the microphone the following sentence: </H3>
                        <H3><i>{lesson.data.sentence}</i></H3>
                        {/* <TextField multiline variant="outlined" style={{width: '80%', margin: 'auto'}} placeholder="type here..."  onChange={(e) => this.setState({response: e.target.value})}/> */}
                      {/* <div style={{width: '100%', textAlign: 'center', margin: 'auto'}}>
                      {this.state.response != '' && <Button variant="contained" color="primary" onClick={() => this.sendResponse(index + 1)}>Send Text</Button>}
                      </div> */}
                      

                        {/* <h3 style={{margin: '20px auto'}}>OR</h3> */}
                    {!this.state.recorded && !this.state.responseSent && !this.state.progress && <Button variant="contained" style={{backgroundColor: this.state.isRecording ? 'red' : 'green', color: 'white'}} onClick={() => {
                      if (this.state.isRecording){
                        this.stop()
                      } else {
                        this.start()
                      }
                    }}>{this.state.isRecording ? 'Stop Recording' : 'Click here to start recording'}</Button>}
                    {this.state.recorded && <div style={{width: '100%'}}>
                      <audio src={this.state.blobURL} style={{padding: 10,  }} controls="controls" /> 
                      </div>}
                      {this.state.progress && <div style={{width: '100%'}}>
                      <CircularProgress color="inherit" />
                      </div>}
                      

                      
                      {this.state.isRecording && <h3>Recording...</h3>}
                      {this.state.recorded && !this.state.responseSent && <Button variant="contained" color="primary" onClick={() => this.send(lesson.data.number)}>Send Audio</Button>}
                      
                      {this.state.responseSent && <p>Audio has been sent</p>

                      }


                      </Paper>
                      {this.state.user.adminResponses && this.state.user.adminResponses.map(response => {
                        if (response.level == 2 && response.lesson == lesson.data.number){
                          return (
                            <Paper style={{width: '80%', margin: '10px auto',padding: 20, textAlign: 'center'}}>
                              <H3>You have a response from Harold Zapata!</H3>
                              <audio src={response.audio} style={{padding: 10,  }} controls="controls" download='false' />


                            </Paper>
                          )
                        }
                      })

                      }


                      <button style={{border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.user.learningProgress.level == 2 && this.state.user.learningProgress.lesson == index + 30 && this.state.user.learningProgress.step == 4){
                          this.updateProgress(index + 30, 5)
                        }
                        this.setState({dialogResponse: false, dialogDownload: true,recording: false,
                          audio: '',
                          isRecording: false,
                          blobURL: '',
                          recorded: false,
                          response: '',
                          responseSent: false})
                      }} >Continue</button>
                      <div>

                      </div>
                    </Dialog>











                    <Dialog
                    open={this.state.dialogDownload}
                    onClose={() => this.setState({dialogDownload: false})}
                    fullScreen
                    >
                      <img src={logo} style={{width: 200, marginTop: -20}} />
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 20, right: 20}} onClick={() => this.setState({dialogDownload: false})} />
                      <h1 style={{textAlign: 'center',fontSize: 'x-large'}}>Step 5. DOWNLOAD AUDIO MP3.</h1>

                      <Paper style={{width: '80%', margin: 'auto', textAlign: 'center', padding: 10}}>
                        <audio src={lesson.data.downloadable} controls download />
                        <div style={{width: '100%', textAlign: 'center'}}>
                        <p style={{ padding: 5, borderRadius: 5,margin: '5px auto', color: 'green'}}>Click the 3 dots to download</p>
                        </div>

                     
                        <Button onClick={() => {
                          this.download(lesson.data.downloadable)
                        }}>download</Button>


                      </Paper>


                      <button style={{border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.user.learningProgress.level == 2 && this.state.user.learningProgress.lesson == index + 30 && this.state.user.learningProgress.step == 5){
                          this.updateProgress(index + 31, 1)
                        }
                        this.setState({dialogDownload: false})
                      }} >Continue</button>
                      <div>

                      </div>
                    </Dialog>

                  </div>}

                </div>
                

              </div>

            )
          } )}

          {this.state.user.learningProgress ? this.state.user.learningProgress.lesson >= 48 && <div>
            <a href="/affiliate/intermediate"><Button  variant="primary" >Proceed to Mid Term Exam</Button></a>
          </div> : <div></div>}
          
        </div>
      )
























  //     return (
  //       <div>
         
  //         <Paper style={{ margin: 20, padding: 20 }}>
  //           <Steps direction="horizontal" size="small" current={0}>
  //             {this.state.courses.map((course, index) => {
  //               return <Step title={"course" + ++index} />;
  //             })}
  //           </Steps>
  
  //           {this.state.courses.length > 0 &&
  //             this.state.courses[0].map((lesson, index) => {
  //               return (
  //                 <Paper style={{ margin: 20, padding: 20 }}>
  //                   <h2 style={{backgroundColor: '#505050', padding: 20, width: 500, color: '#e9c979', fontWeight: 'bold', borderRadius: 5, margin: '10px auto'}} >Lesson {index + 1}</h2>
  //                   <h3 className="montserrat">Lesson Video</h3>
  //                   <ReactPlayer
  //                   onContextMenu ={(e) => e.preventDefault()}
  //                     style={{ margin: "20px auto 50px auto" }}
  //                     controls={true}
  //                     playing={false}
  //                     config={{ file: { 
  //                       attributes: {
  //                         controlsList: 'nodownload'
  //                       }
  //                     }}}
  //                     url={lesson.mainVideo}
  //                   />
  
  
  //                   <h3 className="montserrat">Teaching Video</h3>
  //                   <ReactPlayer
  //                     onContextMenu ={(e) => e.preventDefault()}
  //                     style={{ margin: "20px auto 50px auto" }}
  //                     controls={true}
  //                     playing={false}
  //                     config={{ file: { 
  //                       attributes: {
  //                         controlsList: 'nodownload'
  //                       }
  //                     }}}
  //                     url={lesson.teachingVideo}
  //                   />
  
  
  //                     <h3 className="montserrat">Audio Story</h3>
  //                     {lesson.audioStory && <ReactAudioPlayer
  //                       style={{margin: '10px auto'}}
  //                         src={ lesson.audioStory }
                          
  //                         controls
  //                       />}
  
  
  
  //                   <h3 className="montserrat">Text Story</h3>
  //                   <Input.TextArea
  //                       style={{height: 120}}
  //                       value={lesson.textStory}
                  
  //                       />
  //                   <Button variant="contained" color="primary" style={{margin: 10}} onClick={()=>{
  //                     console.log(lesson.exercise)
  //                     this.setState({
  //                     exercise: lesson.exercise,
  //                     dialog: true,
  //                     index: 0
  //                     })}} >Exercises</Button>
  //                 </Paper>
  //               );
  //             })}
  //         </Paper>
  //         <Dialog
          
  //         fullScreen
  //           open={this.state.dialog}>
  //             <div style={{backgroundColor: '#e6f3f8', height: '100%', width: '100%'}}>
  
              
  //             <h2 style={{textAlign: 'center'}} >Exercise</h2>
              
  //             <div style={{margin: 30, textAlign: 'center'}}>
  //               {this.state.exercise.map((question, index) => {
        
  //                 if (index == this.state.index + 1){
  //                   if (question.type == "fill the gap text"){
  //                     let word1 = '';
  //                     let word2 = '';
  //                     let word3 = '';
  //                     let ansWord1 = '';
  //                     let ansWord2 = '';
  //                     let ansWord3 = '';
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
  //                         <div style={{display: 'flex', justifyContent: 'center', margin: 20}}>
  //                           {question.question.map(word => {
  //                             if (question.options.filter(Word => {return Word == word}).length >= 1){
  //                               word1 = word;
  //                               return (
  //                                 <TextField margin="dense" disabled style={{marginTop: 2, cursor: 'pointer', width: 'max-content'}} variant="outlined" value={this.state.word1}  onClick={()=> this.setState({word1: ''})}/>
  //                               )
  //                             }else {
  //                               return (
  //                                 <H3>{word}</H3>
  //                               )
  //                             }
                              
  //                           })
    
  //                           }
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
  //                         {question.options.map(word => {
  //                           return (
  //                             <H3 style={{cursor: 'pointer'}} onClick={()=> {
  //                               if (this.state.word1 == ''){
    
  //                                 this.setState({word1: word})
  //                                 ansWord1 = word;
  //                                 if (ansWord1 == word1){
  //                                   console.log("correct")
  //                                   this.setState({open: true})
  //                                 } else  {
  //                                   this.setState({open2: true})
  //                                 }
  //                               }
  //                             }} >{word}</H3>
  //                           )
  //                         })
    
  //                         }
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   } else if (question.type == "fill the blank audio"){
  //                     let word1 = '';
  //                     let word2 = '';
  //                     let word3 = '';
  //                     let ansWord1 = '';
  //                     let ansWord2 = '';
  //                     let ansWord3 = '';
                      
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
  //                       <ReactAudioPlayer
  //                       style={{margin: '10px auto'}}
  //                         src={ question.audio }
                          
  //                         controls
  //                       />
  //                         <div style={{display: 'flex', justifyContent: 'center', margin: 20}}>
  //                           {question.question.map(word => {
  //                             if (question.words.filter(Word => {return Word == word}).length >= 1){
  //                               word1 = word;
  //                               return (
  //                                 <TextField margin="dense" disabled style={{marginTop: 2, cursor: 'pointer', width: 'max-content'}} variant="outlined" value={this.state.word1}  onClick={()=> this.setState({word1: ''})}/>
  //                               )
  //                             }else {
  //                               return (
  //                                 <H3>{word}</H3>
  //                               )
  //                             }
                              
  //                           })
    
  //                           }
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
  //                         {question.words.map(word => {
  //                           return (
  //                             <H3 style={{cursor: 'pointer'}} onClick={()=> {
  //                               if (this.state.word1 == ''){
    
  //                                 this.setState({word1: word})
  //                                 ansWord1 = word;
  //                                 if (ansWord1 == word1){
  //                                   console.log("correct")
  //                                   this.setState({open: true})
  //                                 } else {
  //                                   this.setState({open2: true})
  //                                 }
  //                               }
  //                             }} >{word}</H3>
  //                           )
  //                         })
    
  //                         }
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   } else if (question.type == "match the pair"){
                      
  //                     const shuffled = question.pairs;
  //                     let completed = 0;
                    
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
                        
  //                         <div style={{display: 'flex', justifyContent: 'center', margin: 20}}>
  //                           {question.pairs.map((pair, index) => {
  //                             return (
  //                               <div style={{margin: '10px'}}>
  //                                 <Word>{pair.word}</Word>
  //                                 <Phrase>
  //                                   <p>{index == 0 ? this.state.phrase1 : index == 1 ? this.state.phrase2 : index == 2 ? this.state.phrase3 : index == 3 ? this.state.phrase4 : ''}</p>
  //                                 </Phrase>
                                  
  
  //                               </div>
  //                             )
                              
  //                           })
    
  //                           }
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
  //                         {shuffled.map((pair, index) => {
  //                           return (
  //                             <Phrase style={{cursor: 'pointer'}} onClick={()=>{
  //                               if (this.state.phrase1 == ''){
  //                                 this.setState({phrase1: pair.phrase})
  //                               } else if (this.state.phrase2 == ''){
  //                                 this.setState({phrase2: pair.phrase})
  //                               }else if (this.state.phrase3 == ''){
  //                                 this.setState({phrase3: pair.phrase})
  //                               }else if (this.state.phrase4 == ''){
  //                                 this.setState({phrase4: pair.phrase})
  //                               }
  //                               completed = completed + 1;
  //                               let {ans} = this.state;
  //                               ans.push(pair.phrase)
  //                               console.log(ans)
  //                               let pairs = []
  //                               question.pairs.forEach(pair => {
  //                                 pairs.push(pair.phrase)
  //                               })
  //                               console.log(pairs)
  //                               if (ans.length == pairs.length){
  //                                 if (JSON.stringify(ans)==JSON.stringify(pairs)){
  //                                   console.log('correct')
  //                                   this.setState({open: true})
  //                                 } else {
  //                                   this.setState({open: false})
  
  //                                 }
  //                               }
  
                                
  
  //                             }}>
  //                               <p>{pair.phrase}</p> 
  //                             </Phrase>
  //                           )
  //                         })
    
  //                         }
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   }else if (question.type == "correct order"){
  //                     const correct = question.sentence;
                      
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
                       
  //                         <div style={{display: 'flex', width: 400, height: 100, justifyContent: 'center', border: '1px solid #bebebe', padding: 10, backgroundColor: '#ebebeb', margin: '20px auto'}}>
  //                           {this.state.sentence.map(word => {
  //                             return (
  //                               <h4>{word}</h4> 
  //                             )
                              
                              
  //                           })
    
  //                           }
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
  //                         {question.shuffled.map(word => {
  //                           return (
  //                             <Button variant="contained" onClick={()=> {
  //                               let {sentence} = this.state;
  //                               sentence.push(word);
  //                               this.setState({sentence})
  //                               console.log(sentence)
  //                               console.log(correct)
  //                               if (sentence.length == question.sentence.length){
  //                                 if (JSON.stringify(sentence)==JSON.stringify(correct)){
  //                                   console.log('correct')
  //                                   this.setState({open: true})
  //                                 } else {
  //                                   this.setState({open2: true})
  
  //                                 }
  //                               }
  //                             }}>{word}</Button>
  //                           )
  //                         })
    
  //                         }
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   } else if (question.type == "true false"){
                      
                      
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
                       
  //                         <div style={{display: 'flex', width: 400, height: 100, justifyContent: 'center', border: '1px solid #bebebe', padding: 10, backgroundColor: '#ebebeb', margin: '20px auto'}}>
                            
  //                               <h4>{question.sentence}</h4> 
                            
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
                          
  //                             <Button variant="contained" onClick={()=> {
                   
                                
  //                               if (question.status == true){
  //                                 console.log("correct")
  //                                 this.setState({open: true})
  //                               } else {
  //                                 this.setState({open2: true})
  
  //                                 console.log("false")
  //                               }
  //                             }}>True</Button>
  
  //                             <Button variant="contained" onClick={()=> {
                       
                                
  //                               if (question.status == false){
  //                                 console.log("correct")
  //                                 this.setState({open: true})
  
  //                               } else {
  //                                 this.setState({open2: true})
                                  
  //                                 console.log("false")
  //                               }
  //                             }}>False</Button>
                          
    
                          
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   } else if (question.type == "mcq"){
                      
                      
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : question.type == "mcq" ? "Select the correct option" : "Solve"  } </h2>
                       
  //                         <div style={{display: 'flex', justifyContent: 'center', margin: 20}}>
                            
  //                               <h4>{question.question}</h4> 
                            
    
  //                         </div>
    
  //                         <div style={{}}>
  //                           {question.options.map(option => {
  //                             return (
  //                               <Button variant="contained" onClick={()=> {
                                    
                                                  
  //                                   if (question.correctOption == option){
  //                                     console.log("correct")
  //                                   this.setState({open: true})
  
  //                                   } else {
  //                                   this.setState({open2: true})
  
  //                                     console.log("false")
  //                                   }
  //                                 }}>{option}</Button>
  //                             )
  //                           })
  
  //                           }
                          
                              
        
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   }
                    
  //                 } 
  //               })
  
  //               }
  //             </div>
  
  
  
  
  
  //               {/* <div style={{position: 'fixed', bottom: 20, margin: 'auto'}}>
  //               <Alert severity="success">
  //                 <AlertTitle>Success</AlertTitle>
  //                 This is a success alert — <strong>check it out!</strong>
  //               </Alert>
  //               </div> */}
  
  // <Snackbar open={this.state.open} autoHideDuration={10000} onClose={this.handleClose}>
  //         <Alert onClose={this.handleClose} severity="success">
  //           Correct!
  //         </Alert>
  //       </Snackbar>
  //       <Snackbar open={this.state.open2} autoHideDuration={10000} >
  //         <Alert severity="error">
  //           Wrong!
  //         </Alert>
  //       </Snackbar>
  
  //             <CancelIcon  onClick={()=> this.setState({dialog: false})} style={{position: 'fixed', top: 30, left: 30, cursor: 'pointer', opacity: 0.8}} />
  //             {this.state.index != 0 && <Button variant="contained" color="primary" style={{position: 'fixed', bottom: 30, left: 80}} onClick={()=> {
  //               this.setState({index: this.state.index - 1, open: false, open2: false})
  //               this.setState({word1: '',
  //               word2: '',
  //               word3: '',
  //               word4: '',
  //               phrase1: '',
  //               phrase2: '',
  //               phrase3: '',
  //               phrase4: '',
  //               shuffled: [],
  //               ans: [],
  //               sentence: [],})
  
  //             }} >Previous</Button>}
  //             {this.state.index != (this.state.exercise.length - 2) && <Button variant="contained" color="primary" style={{position: 'fixed', bottom: 30, right: 80}} onClick={()=> {
  //               this.setState({index: this.state.index + 1, open: false, open2: false})
  //               this.setState({word1: '',
  //               word2: '',
  //               word3: '',
  //               word4: '',
  //               phrase1: '',
  //               phrase2: '',
  //               phrase3: '',
  //               phrase4: '',
  //               shuffled: [],
  //               ans: [],
  //               sentence: [],})
  //             }} >Next</Button>}
  //             </div>
  //         </Dialog>
  //       </div>
  //     );

    }

    
  }
}


const H3 = styled.h3 `
  font-weight: bold;
  margin: 5px;
  padding: 5px;

  @media(max-width: 500px ){
    font-size: medium;
  }

`

const Word = styled.h3 `
  padding: 10px;
  margin: 5px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
  border-radius: 5px 5px 0px 0px;
`

const Phrase = styled.div `
  padding: 10px;
  margin: 5px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
  text-align: center;


`