import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "../../../App.css";
import styled from "styled-components";
import { fire } from "../../../config";
import storage from "../../../config";
import "../../../config";
import * as firebase from "firebase";
import ReactPlayer from "react-player";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Input } from "antd";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import ReactAudioPlayer from 'react-audio-player';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import logo from '../../../Images/nuparla em1 .1 w.png'
import Dialog from '@material-ui/core/Dialog';
import { TextField } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import refreshIcon from '../../../Images/refresh.png'
import { makeStyles } from '@material-ui/core/styles';
import { Steps } from 'antd';
import MicRecorder from 'mic-recorder-to-mp3';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cert from '../../../Images/level 3.jpg'
const Mp3Recorder = new MicRecorder({ bitRate: 128 });


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const { Step } = Steps;

export default class level3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      dialog: false,
      exercise: [],
      index: 0,

      shuffled: [],
      ans: [],
      sentence: [],
      open: false,
      open2: false,


      level3: {
        lessons: []
      },
      selectedLesson: null,
      
      dialogVideo: false,
      dialogAudio: false,
      dialogExercise: false,
      dialogResponse: false,
      dialogDownload: false,
      dialogCert: false,
      Index: 0,
      qIndex: 0,

      user: {},

      recording: false,
      audio: '',
      isRecording: false,
      blobURL: '',
      recorded: false,
      response: '',

      progress: false,

      word1: '',
      word2: '',
      word3: '',
      word4: '',
      word5: '',
      word6: '',
      word7: '',

      shuffled: [],
      wrongQuestions: [],

      result: null,
      responseSent: false,

      audioStoryLink: '',
      audioStoryDialog: false

    };
  }

  componentDidMount() {
    firebase.firestore().collection('Course').doc('level3').get().then(res => {
  
 
      this.setState({level3: res.data()})

      // console.log(res.data())

      // console.log({
      //   data: {},
      //   lessons: [
      //     res.data().lessons[1]
      //   ]
      // })

      // firebase.firestore().collection('Course').doc('level3').set({
      //   data: {},
      //   lessons: [
      //     res.data().lessons[1]
      //   ]
      // })
      


    })

  //   firebase.firestore().collection('Course').doc('Final').set({
  //     questions: [
  //         {
  //             question: "* is that? That is an apple.",
  //             ans: "What",
  //             options: [
  //                 "What",
  //                 "How",
  //                 "Who",
  //                 "When"
  //             ]
  //         },
  //         {
  //             question: "* didn’t you call me? I’m sorry. I forgot.",
  //             ans: "Why",
  //             options: [
  //                 "What",
  //                 "How",
  //                 "Why",
  //                 "When"
  //             ]
  //         },
  //         {
  //             question: "* will you send the letter? I will send it tomorrow. ",
  //             ans: "When",
  //             options: [
  //                 "What",
  //                 "How",
  //                 "Why",
  //                 "When"
  //             ]
  //         },
  //         {
  //             question: "* difficult is it for you to put the socks in the drawer?  ",
  //             ans: "How",
  //             options: [
  //                 "What",
  //                 "How",
  //                 "Why",
  //                 "When"
  //             ]
  //         },
  //         {
  //             question: "* is taller? Greg or Gary?",
  //             ans: "Who",
  //             options: [
  //                 "What",
  //                 "When",
  //                 "Who",
  //                 "Why"
  //             ]
  //         },
  //         {
  //             question: "* can I buy some coffee around here? Over there, at Starbucks.  ",
  //             ans: "Where",
  //             options: [
  //                 "What",
  //                 "Where",
  //                 "Who",
  //                 "How"
  //             ]
  //         },
  //         {
  //             question: "The contraction for: “How is it going,” is: *",
  //             ans: "How's",
  //             options: [
  //                 "Hows",
  //                 "Hows'",
  //                 "How's",
  //                 "How s"
  //             ]
  //         },
  //         {
  //             question: "The contraction for “She is” is: *",
  //             ans: "She's",
  //             options: [
  //                 "She's",
  //                 "Shes'",
  //                 "She'll",
  //                 "He's"
  //             ]
  //         },
  //         {
  //             question: "There * a fly in my soup.",
  //             ans: "is",
  //             options: [
  //                 "is",
  //                 "are",
  //                 "a or b",
  //                 "none of above"
  //             ]
  //         },
  //         {
  //             question: "The plural form for “There is a mouse in my room,” is: *",
  //             ans: "There are two mice in my room.",
  //             options: [
  //                 "There are two mouses in my room.",
  //                 "There is two mice in room.",
  //                 "There be two mouse’s in my room.",
  //                 "There are two mice in my room."
  //             ]
  //         },
  //         {
  //             question: "The possessive form of “she” is * . (Example: “_____ cat’s name is Shasta.”) ",
  //             ans: "Her",
  //             options: [
  //                 "She's",
  //                 "Here",
  //                 "Her's",
  //                 "Her"
  //             ]
  //         },
  //         {
  //             question: "The possessive form of “they” is * .  (Example: “The Johnson’s sold _____ home.”)",
  //             ans: "their",
  //             options: [
  //                 "their",
  //                 "there",
  //                 "those",
  //                 "them"
  //             ]
  //         },
  //         {
  //             question: "The possessive form of “Lucas” is: *",
  //             ans: "Lucas'",
  //             options: [
  //                 "Lucas’s",
  //                 "Luca’s",
  //                 "Lucas’",
  //                 "Lucas’ss."
  //             ]
  //         },
  //         {
  //             question: "The possessive form of “you” is: *   (Example: “Giovanni has ______ glasses!”)",
  //             ans: "your",
  //             options: [
  //                 "you're",
  //                 "your",
  //                 "you are",
  //                 "youre"
  //             ]
  //         },
  //         {
  //             question: " * dresses are * .",
  //             ans: "Their / there",
  //             options: [
  //                 "Them / their",
  //                 "Their / Their",
  //                 "There / Their",
  //                 "Their / there"
  //             ]
  //         },
  //         {
  //             question: " * apple a * keeps the doctor away.",
  //             ans: "An / day",
  //             options: [
  //                 "An / day",
  //                 "A / day",
  //                 "An / way",
  //                 "A / grey"
  //             ]
  //         },
  //         {
  //             question: "I need to buy * umbrella.",
  //             ans: "an",
  //             options: [
  //                 "an",
  //                 "a",
  //                 "cute",
  //                 "red"
  //             ]
  //         },
  //         {
  //             question: "He wants to attend  * university. ",
  //             ans: "a",
  //             options: [
  //                 "an",
  //                 "a",
  //                 "yellow",
  //                 "small"
  //             ]
  //         },
  //         {
  //             question: "He’ll arrive at * hour you won’t expect him to.",
  //             ans: "an",
  //             options: [
  //                 "an",
  //                 "a",
  //                 "late",
  //                 "early"
  //             ]
  //         },
  //         {
  //             question: "The plural for “tiger” is: *",
  //             ans: "tigers",
  //             options: [
  //                 "tigeres",
  //                 "tigerses",
  //                 "tigers",
  //                 "tiger's"
  //             ]
  //         },
  //         {
  //             question: "The plural for “dress” is: *",
  //             ans: "dresses",
  //             options: [
  //                 "dress’s",
  //                 "dress'",
  //                 "dresses",
  //                 "dressess"
  //             ]
  //         },
  //         {
  //             question: "The plural for “hobby” is: *",
  //             ans: "hobbies",
  //             options: [
  //                 "hobbys",
  //                 "hobby's",
  //                 "hobbys'",
  //                 "hobbies"
  //             ]
  //         },
  //         {
  //             question: "The plural for “mouse” is: *",
  //             ans: "mice",
  //             options: [
  //                 "mouses",
  //                 "mouse's",
  //                 "mice",
  //                 "mices"
  //             ]
  //         },
  //         {
  //             question: "The feminine form for “uncle” is: *",
  //             ans: "aunt",
  //             options: [
  //                 "aunt",
  //                 "ant",
  //                 "Godmother",
  //                 "grandmother"
  //             ]
  //         },
  //         {
  //             question: "The collective form for a “group of dolphins” is a * of dolphins. ",
  //             ans: "pod",
  //             options: [
  //                 "pride",
  //                 "herd",
  //                 "school",
  //                 "pod"
  //             ]
  //         },
  //         {
  //             question: "Forever love * a myth.",
  //             ans: "is",
  //             options: [
  //                 "are",
  //                 "is",
  //                 "a or b",
  //                 "none of above"
  //             ]
  //         },
  //         {
  //             question: "Would * like to come upstairs for coffee?",
  //             ans: "you",
  //             options: [
  //                 "your",
  //                 "you're",
  //                 "you",
  //                 "you'll"
  //             ]
  //         },
  //         {
  //             question: "Mice * tails are shorter than rat * tails.",
  //             ans: "Mice’s / rats’",
  //             options: [
  //                 "Mices / rats",
  //                 "Mice’s / rat’s",
  //                 "Mice’s / rats’",
  //                 "Mices’ / rats’"
  //             ]
  //         },
  //         {
  //             question: "A mole ate all five gardener * carrots. ",
  //             ans: "gardener’s",
  //             options: [
  //                 "gardener’s",
  //                 "gardeners",
  //                 "gardeners’",
  //                 "gardenersess"
  //             ]
  //         },
  //         {
  //             question: "The sun is up.  * a new day!",
  //             ans: "It's",
  //             options: [
  //                 "It's",
  //                 "Its",
  //                 "a or b",
  //                 "none of above"
  //             ]
  //         },
  //         {
  //             question: "A * a dozen (A common American idiom)",
  //             ans: "dime",
  //             options: [
  //                 "quarter",
  //                 "dime",
  //                 "penny",
  //                 "nickel"
  //             ]
  //         },
  //         {
  //             question: "You can say * again. (A common American idiom)",
  //             ans: "that",
  //             options: [
  //                 "those",
  //                 "that",
  //                 "there",
  //                 "this"
  //             ]
  //         },
  //         {
  //             question: "There are plenty of * in the sea. (A common American idiom)",
  //             ans: "fish",
  //             options: [
  //                 "fish",
  //                 "sharks",
  //                 "sardines",
  //                 "whales"
  //             ]
  //         },
  //         {
  //             question: "I’m * the weather. (A common American idiom)",
  //             ans: "under",
  //             options: [
  //                 "over",
  //                 "around",
  //                 "above",
  //                 "under"
  //             ]
  //         },
  //         {
  //             question: "Jose likes dogs. * has four puppies. (The personal pronoun for Jose is: ____)",
  //             ans: "He",
  //             options: [
  //                 "It",
  //                 "He",
  //                 "His",
  //                 "Him"
  //             ]
  //         },
  //         {
  //             question: "Our cat is very clean. * always goes to the litter box. (The pronoun for cat is: ____)",
  //             ans: "It",
  //             options: [
  //                 "It",
  //                 "He",
  //                 "She",
  //                 "It's"
  //             ]
  //         },
  //         {
  //             question: "Why is Gina always late? I need to speak to * . (The personal pronoun for Gina is: ____)",
  //             ans: "her",
  //             options: [
  //                 "here",
  //                 "her",
  //                 "her's",
  //                 "she"
  //             ]
  //         },
  //         {
  //             question: "My cat licks * . (Reflexive pronoun)",
  //             ans: "itself",
  //             options: [
  //                 "themselves",
  //                 "herself",
  //                 "himself",
  //                 "itself"
  //             ]
  //         },
  //         {
  //             question: "hey built the treehouse * . (Reflexive pronoun)",
  //             ans: "themselves",
  //             options: [
  //                 "themselves",
  //                 "theirselves",
  //                 "them",
  //                 "their"
  //             ]
  //         },
  //         {
  //             question: "We built the treehouse * . (Reflexive pronoun)",
  //             ans: "ourselves",
  //             options: [
  //                 "themselves",
  //                 "usselves",
  //                 "weselves",
  //                 "ourselves"
  //             ]
  //         },
  //         {
  //             question: "It’s so easy, * can do it. (Indefinite pronouns)",
  //             ans: "",
  //             options: [
  //                 "anybody",
  //                 "any body",
  //                 "a or b",
  //                 "none of above"
  //             ]
  //         },
  //         {
  //             question: "We all live in the * submarine. (Adjectives)",
  //             ans: "all of above",
  //             options: [
  //                 "yellow",
  //                 "small",
  //                 "crowded",
  //                 "all of above"
  //             ]
  //         },
  //         {
  //             question: "An 80-year old man is a/an * man.  (Adjective of quality)",
  //             ans: "old",
  //             options: [
  //                 "old",
  //                 "young",
  //                 "childish",
  //                 "funny"
  //             ]
  //         },
  //         {
  //             question: "This * sauce is hot. (Adjective of origin)",
  //             ans: "all of above",
  //             options: [
  //                 "Indian",
  //                 "Chinese",
  //                 "Mexican",
  //                 "all of above"
  //             ]
  //         },
  //         {
  //             question: "I love her * * hair. (Order of adjectives)",
  //             ans: "long black",
  //             options: [
  //                 "black long",
  //                 "long black",
  //                 "a or b",
  //                 "none of above"
  //             ]
  //         },
  //         {
  //             question: "Far. Farther. *. (Adjective comparisons)",
  //             ans: "Farthest",
  //             options: [
  //                 "Furthest",
  //                 "Farthest",
  //                 "More farther",
  //                 "Most farther"
  //             ]
  //         },
  //         {
  //             question: "Hot. * .  Hottest.",
  //             ans: "Hotter",
  //             options: [
  //                 "Hotter",
  //                 "Hoter",
  //                 "Most hot",
  //                 "More hot"
  //             ]
  //         },
  //         {
  //             question: "Mad. Madder. *. ",
  //             ans: "Maddest",
  //             options: [
  //                 "Madest",
  //                 "Maddesst",
  //                 "Maddest",
  //                 "Most Madder"
  //             ]
  //         },
  //         {
  //             question: "Practical.  More practical. * * .  (Adjective comparisons)",
  //             ans: "Most practical",
  //             options: [
  //                 "More practicalist",
  //                 "More practicaler",
  //                 "Most practicalist",
  //                 "Most practical"
  //             ]
  //         },
  //         {
  //             question: "Much.  More.  * .   (Adjective comparisons)",
  //             ans: "Most",
  //             options: [
  //                 "Most",
  //                 "Mor'st",
  //                 "Morest",
  //                 "Morrest"
  //             ]
  //         },
  //         {
  //             question: "Meeting the president was * honor. (Articles)",
  //             ans: "an",
  //             options: [
  //                 "a",
  //                 "an",
  //                 "a or b",
  //                 "none of above"
  //             ]
  //         },
  //         {
  //             question: "Who lives in * house? (if the house is close by)",
  //             ans: "this",
  //             options: [
  //                 "that",
  //                 "this",
  //                 "those",
  //                 "them"
  //             ]
  //         },
  //         {
  //             question: "Who lives in * house? (if the house if far away)",
  //             ans: "that",
  //             options: [
  //                 "that",
  //                 "this",
  //                 "those",
  //                 "them"
  //             ]
  //         },
  //         {
  //             question: " * pants are too short. (if the pants are close by)",
  //             ans: "These",
  //             options: [
  //                 "This",
  //                 "Those",
  //                 "These",
  //                 "That"
  //             ]
  //         },
  //         {
  //             question: " * pants are too short. (if the pants are far by)",
  //             ans: "Those",
  //             options: [
  //                 "This",
  //                 "Those",
  //                 "These",
  //                 "That"
  //             ]
  //         },
  //         {
  //             question: "* people have been to the moon. ",
  //             ans: "Few",
  //             options: [
  //                 "Both",
  //                 "Few",
  //                 "Fewer",
  //                 "Nobody"
  //             ]
  //         },
  //         {
  //             question: "Drink * of water daily.",
  //             ans: "plenty",
  //             options: [
  //                 "plenty",
  //                 "half",
  //                 "most",
  //                 "other"
  //             ]
  //         },
  //         {
  //             question: "We have * time to play.",
  //             ans: "either b or c",
  //             options: [
  //                 "few",
  //                 "little",
  //                 "much",
  //                 "either b or c"
  //             ]
  //         },
  //         {
  //             question: "I need * pencil.",
  //             ans: "another",
  //             options: [
  //                 "less",
  //                 "much",
  //                 "another",
  //                 "more"
  //             ]
  //         },
  //         {
  //             question: "Are there * good books in the library?",
  //             ans: "",
  //             options: [
  //                 "either",
  //                 "neither",
  //                 "any",
  //                 "anyone"
  //             ]
  //         },
  //         {
  //             question: "There’s a huge snake in * garden. (Possessive for “we”)",
  //             ans: "our",
  //             options: [
  //                 "us",
  //                 "our",
  //                 "your",
  //                 "ours"
  //             ]
  //         },
  //         {
  //             question: "* in there, buddy! (A common American idiom)",
  //             ans: "Have",
  //             options: [
  //                 "Hung",
  //                 "Hang",
  //                 "Hold",
  //                 "Have"
  //             ]
  //         },
  //         {
  //             question: "It’s not rocket * . (A common American idiom)",
  //             ans: "science",
  //             options: [
  //                 "engineering",
  //                 "biology",
  //                 "theology",
  //                 "science"
  //             ]
  //         },
  //         {
  //             question: "No * , no * .  (A common American idiom)",
  //             ans: "pain / gain",
  //             options: [
  //                 "sweat / pain",
  //                 "gain / pain",
  //                 "pain / gain",
  //                 "grind / gain"
  //             ]
  //         },
  //         {
  //             question: "Pull someone’s * . (A common American idiom)",
  //             ans: "leg",
  //             options: [
  //                 "arm",
  //                 "wrist",
  //                 "leg",
  //                 "neck"
  //             ]
  //         },
  //         {
  //             question: "Twist someone’s * . (A common American idiom)",
  //             ans: "arm",
  //             options: [
  //                 "arm",
  //                 "wrist",
  //                 "leg",
  //                 "neck"
  //             ]
  //         },
  //         {
  //             question: "Cut me some * . (A common American idiom)",
  //             ans: "slack",
  //             options: [
  //                 "cheese",
  //                 "slack",
  //                 "cake",
  //                 "pizza"
  //             ]
  //         },
  //         {
  //             question: "Custom agents are more interested in your * .",
  //             ans: "stuff/what you carry",
  //             options: [
  //                 "stuff/what you carry",
  //                 "citizenship status",
  //                 "either a or b",
  //                 "none of the above"
  //             ]
  //         },
  //         {
  //             question: "Immigration officers are more interested in your *",
  //             ans: "citizenship status",
  //             options: [
  //                 "stuff/what you carry",
  //                 "citizenship status",
  //                 "either a or b",
  //                 "none of the above"
  //             ]
  //         },
  //         {
  //             question: "The last * is given to the Quinceañera in commemoration of her childhood.",
  //             ans: "doll",
  //             options: [
  //                 "shoe",
  //                 "dance",
  //                 "doll",
  //                 "dress"
  //             ]
  //         },
  //         {
  //             question: "Most * describe actions.",
  //             ans: "verbs",
  //             options: [
  //                 "nouns",
  //                 "verbs",
  //                 "pronouns",
  //                 "syllables"
  //             ]
  //         },
  //         {
  //             question: "He brush * his teeth. ",
  //             ans: "brushes",
  //             options: [
  //                 "brushs",
  //                 "brush’s",
  //                 "brushes",
  //                 "brushs’"
  //             ]
  //         },
  //         {
  //             question: "The preacher preach___ his heart out.",
  //             ans: "preaches",
  //             options: [
  //                 "preachs",
  //                 "preah's",
  //                 "preaches",
  //                 "preachs'"
  //             ]
  //         },
  //         {
  //             question: "The bee buzz___ around the flower. ",
  //             ans: "buzzes",
  //             options: [
  //                 "buzzs",
  //                 "buzz's",
  //                 "buzzes'",
  //                 "buzzes"
  //             ]
  //         },
  //         {
  //             question: "Mr. Wu hurr____ to work every morning.",
  //             ans: "hurries",
  //             options: [
  //                 "hurries",
  //                 "hurrys",
  //                 "hurry's",
  //                 "hurrys'"
  //             ]
  //         },
  //         {
  //             question: "The nun pra____ in church. ",
  //             ans: "prays",
  //             options: [
  //                 "prays",
  //                 "praies",
  //                 "pray's",
  //                 "preys"
  //             ]
  //         },
  //         {
  //             question: "The lion pre___ on the gazelle. ",
  //             ans: "preys",
  //             options: [
  //                 "prey's",
  //                 "prays",
  //                 "preys",
  //                 "preys'"
  //             ]
  //         },
  //         {
  //             question: "Mom and Dad lov___  us. ",
  //             ans: "love",
  //             options: [
  //                 "loves",
  //                 "love",
  //                 "love's",
  //                 "loves'"
  //             ]
  //         },
  //         {
  //             question: "She * so pretty.",
  //             ans: "is",
  //             options: [
  //                 "are",
  //                 "be",
  //                 "is",
  //                 "am"
  //             ]
  //         },
  //         {
  //             question: "You * so beautiful to me. ",
  //             ans: "are",
  //             options: [
  //                 "are",
  //                 "be",
  //                 "is",
  //                 "am"
  //             ]
  //         },
  //         {
  //             question: "The contraction for “he is,” is: *",
  //             ans: "",
  //             options: [
  //                 "he'll",
  //                 "he's",
  //                 "hes",
  //                 "hes'"
  //             ]
  //         },
  //         {
  //             question: "The contraction for “they are not,” is: *",
  //             ans: "they aren’t",
  //             options: [
  //                 "they ar’nt",
  //                 "they aren’t",
  //                 "they arent’",
  //                 "they’rnt"
  //             ]
  //         },
  //         {
  //             question: "I am watch____ television.",
  //             ans: "watching",
  //             options: [
  //                 "watching",
  //                 "watched",
  //                 "watchful",
  //                 "watchingly"
  //             ]
  //         },
  //         {
  //             question: "The * is the supreme law of the land.",
  //             ans: "Constitution",
  //             options: [
  //                 "Commission",
  //                 "Construction",
  //                 "Constitution",
  //                 "Constipation"
  //             ]
  //         },
  //         {
  //             question: "The * starts with the words: “We the people…”",
  //             ans: "Preamble",
  //             options: [
  //                 "Preambien",
  //                 "Preamble",
  //                 "Preambient",
  //                 "Predisposition"
  //             ]
  //         },
  //         {
  //             question: "Changes to the Constitution are called * . ",
  //             ans: "Amendments",
  //             options: [
  //                 "Ammendments",
  //                 "Commandments",
  //                 "Amendments",
  //                 "Traditions"
  //             ]
  //         },
  //         {
  //             question: "The first 10 Amendments to the Constitution are called the Bill * .",
  //             ans: "",
  //             options: [
  //                 "Clinton",
  //                 "of Wrights",
  //                 "of Rights",
  //                 "of Writes"
  //             ]
  //         },
  //         {
  //             question: "Freedom of * assures the government can’t stop the people from saying what they think. ",
  //             ans: "Press",
  //             options: [
  //                 "Press",
  //                 "Speach",
  //                 "Religion",
  //                 "Petition"
  //             ]
  //         },
  //         {
  //             question: "One gallon has * quarts.",
  //             ans: "four",
  //             options: [
  //                 "two",
  //                 "three",
  //                 "four",
  //                 "five"
  //             ]
  //         },
  //         {
  //             question: "We * in church all day yesterday. ",
  //             ans: "were",
  //             options: [
  //                 "where",
  //                 "were",
  //                 "was",
  //                 "whether"
  //             ]
  //         },
  //         {
  //             question: "The simple past tense for “break” is: *",
  //             ans: "broke",
  //             options: [
  //                 "braked",
  //                 "braking",
  //                 "broke",
  //                 "broked"
  //             ]
  //         },
  //         {
  //             question: "Jack has already * his homework.",
  //             ans: "done",
  //             options: [
  //                 "did",
  //                 "do",
  //                 "done",
  //                 "dun"
  //             ]
  //         },
  //         {
  //             question: "David sings * .",
  //             ans: "beautifully",
  //             options: [
  //                 "beautiful",
  //                 "beautifuly",
  //                 "beautifuler",
  //                 "beautifully"
  //             ]
  //         },
  //         {
  //             question: "The boys are playing * . ",
  //             ans: "all of above",
  //             options: [
  //                 "inside",
  //                 "outside",
  //                 "upstairs",
  //                 "all of above"
  //             ]
  //         },
  //         {
  //             question: "Who is cooking dinner, Mom * Dad?",
  //             ans: "or",
  //             options: [
  //                 "or",
  //                 "and",
  //                 "but",
  //                 "because"
  //             ]
  //         },
  //         {
  //             question: "Hiring managers make up their mind about you in the first * of the interview.",
  //             ans: "ten seconds",
  //             options: [
  //                 "ten minutes",
  //                 "twenty minutes",
  //                 "ten seconds",
  //                 "twenty seconds"
  //             ]
  //         },
  //         {
  //             question: "Maria is older than you, * she?",
  //             ans: "isn't",
  //             options: [
  //                 "isn’t",
  //                 "is",
  //                 "ain't",
  //                 "won't"
  //             ]
  //         },
  //         {
  //             question: "Could you pass me the salt * please?",
  //             ans: ",",
  //             options: [
  //                 "!",
  //                 ".",
  //                 ",",
  //                 ";"
  //             ]
  //         },
  //         {
  //             question: "I am from Colombia. I am: *",
  //             ans: "Colombian",
  //             options: [
  //                 "colombian",
  //                 "Colombian",
  //                 "colombiano",
  //                 "NuParlero"
  //             ]
  //         },
  //         {
  //             question: "I love NuParla. I am: *",
  //             ans: "both b and c",
  //             options: [
  //                 "an NuParlero",
  //                 "a NuParlero",
  //                 "an English speaker",
  //                 "both b and c"
  //             ]
  //         }
  //     ]
  // })

    

    firebase.firestore().collection('Users').doc(localStorage.getItem('email')).onSnapshot(res => {
     
      this.setState({user: res.data()})
      console.log(res.data())

      // firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
      //   learningProgress: {
      //     level: 1,
      //     lesson: 1,
      //     step: 1
      //   }
      // })
    })


  }



  start = () => {
    this.setState({recording: true})
    
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true });
        }).catch((e) => console.error(e));
    
  };

  stop = () => {
    this.setState({recording: false})
    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob)
        console.log(blob)
        this.setState({ blobURL, blob: blob, isRecording: false, recorded: true });
      }).catch((e) => console.log(e));
  };

  shuffle(array){
    var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;

  }

  send = (lesson) => {
    this.setState({recorded: false,progress: true })

    if (this.state.blobURL != ''){

      let upload = storage
        .ref(`responses/${this.state.user.userName + lesson}`)
        .put(this.state.blob);
      upload.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref(`responses/${this.state.user.userName + lesson}`)
            .getDownloadURL()
            .then(async (url) => {
              firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
                Responses: firebase.firestore.FieldValue.arrayUnion({
                  level: 3,
                  lesson: lesson,
                  audio: url
                }) 
        
              }).then((res) => {
                this.setState({responseSent: true, progress: false})
              })
              

            
        })})
    }
  }


  sendResponse = (lesson) => {

    firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
      Responses: firebase.firestore.FieldValue.arrayUnion({
        level: 3,
        lesson: lesson,
        text: this.state.response
      }) 

    }).then((res) => {
      this.setState({responseSent: true})
    })
    

  }




   handleClick = () => {
    this.setState({open: true})
    };

   handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }

    // this.setState({open: false})
  };

  getCourses() {
    firebase
      .database()
      .ref("Courses")
      .child("level3")
      .once("value")
      .then((snapshot) => {
        this.setState({ courses: snapshot.val() });
      });
  }

   shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
    }

  updateProgress(lesson, step) {
    if (lesson == 69 && step == 1){
      this.setState({dialogCert: true})
      firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
        learningProgress: {
          level: 4,
          lesson: lesson,
          step: step
        }
      })
    } else {
      firebase.firestore().collection('Users').doc(localStorage.getItem('email')).update({
        learningProgress: {
          level: 3,
          lesson: lesson,
          step: step
        }
      })
    }
    
  }

  render() {

    if (this.state.user.learningProgress && this.state.user.learningProgress.level <= 2){
      return (
        <div>
          <p>This Level becomes active once you have finished the previous levels. </p>
        </div>
      )
    }else {
      return (
        <div style={{backgroundColor: '#ebebeb', padding: 20}}>
          <h1 style={{fontSize: 'x-large'}}>Level 3</h1>
 
          {this.state.level3.lessons.map((lesson,index) => {

            return (
              <div>
                {index == 0 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#1</span> Travel 2.0</h2>
                  
                </div>}

                {index == 2 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#2</span> Dialogue 3.0</h2>
                  
                </div>}

                {index == 5 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#3</span> Health & Fitness</h2>
                  
                </div>}

                {index == 7 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#4</span> Work Life 2.0</h2>
                  
                </div>}

                {index == 10 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#5</span> Politics</h2>
                  
                </div>}

                {index == 13 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#6</span> Foods and Quantities 2.0</h2>
                  
                </div>}

                {index == 16 && <div style={{backgroundColor: '#3c3b6e', color: 'white', borderRadius: 10, padding: 15, width: '70%', margin: '20px auto'}}>
                  <h2 style={{color: '#edd490', fontSize: 'x-large', fontWeight: 'bold'}}>Course <span style={{backgroundColor: 'white', color: '#800000', borderRadius: 30, padding: 10}}>#7</span> Religion</h2>
                  
                </div>}
              

                <div style={{cursor: 'pointer', backgroundColor: 'white', width: '90%', margin: '25px auto', borderRadius: '10px', boxShadow: '0px 2px 8px rgba(0,0,0,0.2)', marginBottom: lesson.data.title == 'Refresher Course & Grammar' ? 60 : 25}} >
                  <div style={{display: 'flex'}} onClick={() => {
                    if(this.state.selectedLesson == index){
                      this.setState({selectedLesson: null})
                    } else {
                      this.setState({selectedLesson: index})
                    }
                    
                  }}>
                    {lesson.data.title != 'Refresher Course & Grammar' && <img style={{width: 80, height: 80, margin: '10px'}} src={lesson.data.avatar} alt="avatar" />}
                    {lesson.data.title == 'Refresher Course & Grammar' && <img style={{width: 80, height: 80, margin: '10px', marginLeft: 40}} src={refreshIcon} alt="avatar" />}
                    {lesson.data.title != 'Refresher Course & Grammar' && <div style={{textAlign: 'left'}}>
                      <H3>Lesson {lesson.data.number}: {lesson.data.title}</H3>
                      <p style={{marginLeft: 93}}>{lesson.data.description}</p>
                    </div>}

                    {lesson.data.title == 'Refresher Course & Grammar' && <div style={{textAlign: 'left', margin: 'auto 30px'}}>
                      <h2 style={{fontSize: 'x-large'}}>{lesson.data.title} #{lesson.data.number}</h2>
                      
                    </div>}

                  </div>
                  {this.state.selectedLesson == index && <div style={{backgroundColor: '#fafafa', borderRadius: '0px 0px 10px 10px', width: '100%',padding: 10, borderTop: '1px solid #bebebe' }}>
                    <div style={{width: '80%', margin: '10px auto'}}>
                    <Steps current={this.state.user.learningProgress.lesson < index + 49 ? null : this.state.user.learningProgress.lesson > index + 49 ? 6 : this.state.user.learningProgress.step - 1 }>
                      <Step title="Step 1" description="Video" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 48){
                            this.setState({dialogVideo: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 48 && this.state.user.learningProgress.step -1 >= 0)
                          {
                            this.setState({dialogVideo: true})
                          }
                          
                        }}/>
                      {lesson.data.title != 'Refresher Course & Grammar' && <Step title="Step 2"  description="Audio" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 48){
                            this.setState({dialogAudio: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 48 && this.state.user.learningProgress.step -1 >= 1){
                            this.setState({dialogAudio: true})
                          }

                    
                        }}/>}
                      <Step title={lesson.data.title == 'Refresher Course & Grammar' ? "Step 2" : "Step 3"} description="Exercise" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 49){
                            this.setState({dialogExercise: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 49 && this.state.user.learningProgress.step -1 >= 2){
                            this.setState({dialogExercise: true})
                          
                          }
                        
                        }}/>
                      {lesson.data.title != 'Refresher Course & Grammar' && <Step title="Step 4" description="Track" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 49){
                            this.setState({dialogResponse: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 49 && this.state.user.learningProgress.step -1 >= 3){
                            this.setState({dialogResponse: true})
                          }
                        
                        }}/>}
                      {lesson.data.title != 'Refresher Course & Grammar' && <Step title="Step 5" description="Download" onClick={() => {
                          if (this.state.user.learningProgress.lesson > index + 49){
                            this.setState({dialogDownload: true})

                          }else if (this.state.user.learningProgress.lesson >= index + 49 && this.state.user.learningProgress.step -1 >= 4){
                            this.setState({dialogDownload: true})

                          }
                    
                        }}/>}
                    </Steps>
                    </div>







                    <Dialog
                    open={this.state.dialogVideo}
                    onClose={() => this.setState({dialogVideo: false})}
                    fullScreen
                    >
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 20, right: 20}} onClick={() => this.setState({dialogVideo: false})} />
                      <img src={logo} style={{width: 200}} />
                      <h1 style={{textAlign: 'center', fontSize: 'x-large'}}>Step 1. VIDEO LESSON</h1>

                      <Paper elevation={3}  style={{padding: 10,textAlign: 'center', margin: '30px auto',}}>
                  
                        
                        <ReactPlayer
                          
                            style={{ margin: "10px auto 10px auto", border: '1px solid #bebebe' }}
                            height={window.innerWidth >= 800 ? '420px' : window.innerWidth >= 500 ? '280px' : '200px'}
                            width={window.innerWidth >= 800 ? '740px' :window.innerWidth >= 500 ? '480px' : '360px'}
                            controls={true}
                            playing={true}
                            
                            // url={lesson.data.video}
                            url={lesson.data.video}
                            
                          />

                                
                                

                      </Paper>


                      <button style={{fontSize: 'large', border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.user.learningProgress.level == 3 && this.state.user.learningProgress.lesson == index + 49 && this.state.user.learningProgress.step == 1 && lesson.data.title != 'Refresher Course & Grammar'){
                          this.updateProgress(index + 49, 2)
                        }
                        if (this.state.user.learningProgress.level == 3 && this.state.user.learningProgress.lesson == index + 49 && this.state.user.learningProgress.step == 1 && lesson.data.title == 'Refresher Course & Grammar'){
                          this.updateProgress(index + 49, 3)
                        }
                        if (lesson.data.title != 'Refresher Course & Grammar'){
                          this.setState({dialogVideo: false, dialogAudio: true})
                        } else  {
                          this.setState({dialogVideo: false, dialogExercise: true})

                        }
                        
                      }} >Continue</button>
                      <div>

                      </div>
                    </Dialog>








                    <Dialog
                    open={this.state.dialogAudio}
                    onClose={() => this.setState({dialogAudio: false})}
                    fullScreen
                    >
                      <img src={logo} style={{width: 200, marginTop: -20}} />
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 20, right: 20}} onClick={() => this.setState({dialogAudio: false})} />
                      <h1 style={{textAlign: 'center',fontSize: 'x-large'}}>Step 2. AUDIO STORY LESSON</h1>

                      <Paper elevation={3} style={{padding: 10,textAlign: 'center', margin: '30px auto',}}>
                      
                        
                        <audio src={lesson.data.audioStory} style={{padding: 10 }} controls="false" controlsList="nodownload"/>


                      </Paper>

                      <Dialog 
                      open={this.state.audioStoryDialog}
                      onClose={() => this.setState({audioStoryDialog: false})}
                      >
                        <ReactPlayer
                          
                          style={{ margin: "auto", border: '1px solid #bebebe' }}
                          height='240px'
                          width='400px'
                          controls={true}
                          playing={true}
                          
                          // url={lesson.data.video}
                          url={this.state.audioStoryLink}
                          
                        />
                        

                      </Dialog>

                      


                      {lesson.data.number == 39 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 39, titled: I think you are hiding something from me.<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498763798"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        This is a new movie that we’ll be watching throughout the remainder of our Level 3 and Level 4 audio stories. In this episode, Laurel and Hardy are in Paris. A professional cartoonist paints a picture of the pair. <br/>
                        <br/>
                        “Gee, that’s swell photography,” says Laurel. “Well, we better get back to the hotel and get our things packed, because the boat sails at 12 o’clock tonight.”<br/>
                        <br/>
                        “Not for me it doesn’t” Hardy replies with a smirk.<br/>
                        “What do you mean?”<br/>
                        “I’ve decided to stay a while. In fact, if my plans work out right, I may never go back,” says Hardy.<br/>
                        “Well, we’ve got to go back. We’ll lose our jobs at the fish market in Des Moines (Iowa).”<br/>
                        “So, what,” replies Hardy. “There are plenty  of fish markets here in Paris.”<br/>
                        <br/>
                        Hardy calls on the waiter. “Another glass of milk and two fresh straws,” he asks.  He then takes in a deep breath and sighs as if he is in love. <br/>
                        <br/>
                        “You know what?” asks Laurel, “ I think you are hiding something from me.” <br/>
                        <br/>
                        Hardy smiles, and wiggles his tie. <br/>
                          </p>
                      </Paper>}


                      {lesson.data.number == 40 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 40, titled: Roses are red.<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498764051"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, Hardy shows his affection for a young woman, unaware she is married. <br/>
                        <br/>
                        Four young women laugh as they see roses, candy and a picture of Hardy. The one to whom he sent them to reads the note:<br/>
                        <br/>
                        “Roses are red. Candy is sweet. This is something I sent you to eat. Ollie.” The ladies share the candy. <br/>
                        “Who is he?” asks one of the girls. <br/>
                        “He’s the American guest in number four. We must not ridicule his generosity. Really, he and his fellow are very kind.” <br/>
                        <br/>
                        Enter Laurel and Hardy, heading to their hotel room. <br/>
                        <br/>
                        “Mr. Hardy!” interrupts the young married woman.<br/>
                        “Yes, ma’am?” Hardy smiles in love and takes off his hat.<br/>
                        “I want to thank you for your photograph.”<br/>
                        “You are welcome.”<br/>
                        “And for your beautiful flowers.”<br/>
                        “Oh, chucks. It was just a thought.”<br/>
                        “And the delicious chocolates.”<br/>
                        “Well I thought you might want something to nibble on while you were smelling the flowers.”<br/>
                        <br/>
                        They both giggle. Hardy runs away, and comes back and wiggles his tie at the young woman.<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 41 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 41, titled: She sure is a swell dish.<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate): <br/>
                        
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498766021"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, Hardy tells Laurel his secret that he is in love.<br/>
                        <br/>
                        Hardy enters the hotel room singing.<br/>
                        <br/>
                        “What are you so happy about?” asks Laurel.<br/>
                        “Stanley, can you keep a secret?” asks Hardy.<br/>
                        “Sure.”<br/>
                        “I’m in love.”<br/>
                        “You don’t tell me. Who is it?”<br/>
                        “The most gorgeous creature in the world. Georgette, the innkeeper’s daughter.”<br/>
                        “Well I don’t blame you. She sure is a swell dish.”<br/>
                        “Don’t call her a dish. She’s the girl that I am going to marry. Get a load of this diamond ring.” Hardy shows Laurel the ring.<br/>
                        “Say, when are you going to get married?” asks Laurel.<br/>
                        “Just as soon as I ask her” replies Hardy.<br/>
                        “Why don’t you ask her now? That way we can all go out and celebrate.”<br/>
                        “That’s just what I intended to do. We’ll get cleaned up and I’ll go down and pop the question.”<br/>
                        “You sure know how to pick them…”<br/>
                        <br/>
                        Laurel hits his head on a low ceiling. Hardy stares in unbelief. <br/>
                        </p>
                      </Paper>}

                      {lesson.data.number == 42 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 42, titled: Why don’t you mind your own business.<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498765797"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, Laurel and Hardy get cleaned up to go downstairs and pop the question to the innkeeper’s daughter. <br/>
                        <br/>
                        Hardy stares at Laurel bumping his head against a low ceiling. Then he places a cushion between his head and the low ceiling. <br/>
                        <br/>
                        “What are you doing with that cushion?” asks Hardy.<br/>
                        “Well I keep on bumping my head” replies Laurel.<br/>
                        “Move it in front of the window where you can stand up.”<br/>
                        <br/>
                        Laurel places the table with the washer in front of the window, but he still continues bumping his head on the low ceiling.<br/>
                        <br/>
                        “How anyone can be so stupid as to stand there and continually bump their head is beyond me!” barks Hardy.<br/>
                        <br/>
                        Hardy sits on a water cushion and sprays water on the bed. He goes to get a towel, and now he bumps his head on the low ceiling.<br/>
                        <br/>
                        “Why don’t you stand by the window?” asks Laurel.<br/>
                        “Why don’t you mind your own business!” screams Hardy.<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 43 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 43, titled: Remember, Georgette, you are a married woman.<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/> 
                        
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498765532"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                        
                        In this episode, we discover the young lady Hardy wants to marry is already married.<br/>
                        <br/>
                        The innkeeper walks in as his daughter and her friends are laughing.<br/>
                        <br/>
                        “What is this story, darling? What is it?” asks the innkeeper to the young ladies.<br/>
                        “Papá, it’s this,” the young married woman shows her father Hardy’s picture. “The girls think he’s funny.”<br/>
                        “Yes, he is funny, but remember Georgette, you are a married woman. And Francois is a little bit jealous.”<br/>
                        “Oh, papá, he’s not serious. It’s just an American way of having fun,” replies his daughter. <br/>
                        “Well, ok,” responds the father, “ok girls, off to work, go…” The girls disperse. <br/>
                        <br/>
                        Francois walks in, in full military garb.<br/>
                        <br/>
                        “Francois!” screams the innkeeper. <br/>
                        <br/>
                        Both men salute each other. <br/>
                        <br/>
                        “It’s so good to see you,” says Francois. <br/>
                        “After such a long time,” responds the innkeeper. “Why didn’t you tell us you were coming?”<br/>
                        “I wanted to surprise Georgette. Where is she?”<br/>
                        <br/>
                        The innkeeper calls for his daughter, as Francois hides himself behind a wall. As she comes in the room, Francois jumps behind her and covers her eyes. She turns around and kisses Francois. <br/>
                        <br/>
                        “Oh, my darling Georgette. I’ve waited every day, every hour, every minute…”<br/>
                        “My poor Francois. How I’ve worried about you in the lonely post in Morocco.”<br/>
                        “I was lonely, terribly lonely, but that’s over now. I’ve got great news for you. I’ve made arrangements for you to join me when I go back.”<br/>
                        “Oh darling, I’m so happy!”<br/>
                        <br/>
                        They kiss.  <br/>
                        <br/>
                        “You must be hungry. I’ll get you something to eat.”<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 44 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 44, titled Is there someone else?<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498765099"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, Hardy finds out Georgette isn’t in love with him, but with another man.<br/>
                        <br/>
                        Laurel and Hardy are waited by Georgette at a table. <br/>
                        <br/>
                        “My friend and I would like a bottle of wine, and bring three glasses,” says Hardy.<br/>
                        “Three glasses?” asks Georgette, seeing that there are only two men.<br/>
                        “We’d thought maybe you’d like to join us.”<br/>
                        <br/>
                        Four young ladies are eavesdropping and smile.<br/>
                        <br/>
                        “You know, I feel as fidgety as a jitterbug,” says Hardy.<br/>
                        “What’s the matter?” asks Laurel.<br/>
                        “Well, I’m nervous. You know Stanley, this would be the very first time I’ve ever been married.”<br/>
                        <br/>
                        Georgette returns with the wine.<br/>
                        <br/>
                        “Go on,” whispers Laurel, “tell here. What’s the matter? You scared? I’ll tell her for you.”<br/>
                        “Not now,” whispers Hardy.<br/>
                        “I know how you feel. Let me fix it for you,” whispers Laurel. “Say, he’s got something to tell you,” Laurel tells Georgette. <br/>
                        “Something to tell me?” asks Georgette. <br/>
                        “Yeah. He’s nuts about you.” Georgette doesn’t understand and brings them nuts. “No, I don’t mean that. I mean, he’s crazy about you. He’s going to marry you. Aren’t you Ollie? Go on, start talking.”<br/>
                        “You’re going to marry me?” asks Georgette, bewildered. <br/>
                        “Well, that is, if you don’t mind…” answers Hardy.<br/>
                        “Oh, you make fun of me…” says Georgette. <br/>
                        “No, he’s not kidding. He’s on the level. Aren’t you Ollie?”<br/>
                        “Why, certainly. I was never more sincere in my life,” says Hardy.<br/>
                        “That’s very nice of you, I’m sure. But that can never be.”<br/>
                        “Oh, won’t you give me one chance, Georgette? Is there someone else?”<br/>
                        “Oh, yes. Very much so. I’m so sorry.” Georgette leaves.  Hardy sits down devastated. After a long pause…<br/>
                        “Well, that’s that,” says Laurel. “No use crying over spilled milk. We better be going.” <br/>
                        “You go ahead. I want to be alone.”<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 45 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 45, titled: You don’t understand.<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498764940"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, Laurel consoles Hardy after he is let down by Georgette. <br/>
                        <br/>
                        Francois and Georgette are saying their goodbyes, as he must report back to headquarters. <br/>
                        <br/>
                        “Don’t be long, darling,” says Georgette. <br/>
                        “No, darling. But it’s absolutely necessary that I report to headquarters,” answers Francois.<br/>
                        “You’ll be back right away?”<br/>
                        “Just as quick as I can.” He kisses Georgette. <br/>
                        <br/>
                        Meanwhile, Laurel consoles Hardy.<br/>
                        <br/>
                        “Don’t worry about it. You’ll get over it all right.”<br/>
                        “To think that this would happen to me…just at a time of my life when I needed something real, something wholesome, something tender...”<br/>
                        “Why don’t you try a nice, fat, juicy steak,” chimes Laurel, “that will make you feel better if you have something to eat.”<br/>
                        “You don’t understand,” sighs Hardy.<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 46 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 46, titled: Stanley, you have given me an idea!<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498764797"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, Laurel hints Hardy should drown himself. Hardy takes that advice to heart.<br/>
                        <br/>
                        “If I fell as bad as you I would go out and drown myself,” shouts Laurel.<br/>
                        “Stanley, you’ve given me an idea.”<br/>
                        “What?”<br/>
                        “I’m going to follow your advice, and drown myself.”<br/>
                        “Oh, wait a minute. I was only kidding,” says Laurel.<br/>
                        “This is no time for kidding!” <br/>
                        “I know, but don’t you think you ought to think it over? Have you ever been drowned before?”<br/>
                        “I have thought it over, and since Georgette has given me the air, I have nothing left to live for.”<br/>
                        “Oh, don’t be silly.”<br/>
                        “What do you mean, ‘don’t be silly?’ This is a far, far better thing I do now, than I have ever done before,” exclaims Hardy.<br/>
                        “Well…” sighs Laurel.<br/>
                        <br/>
                        He sits down on the water cushion and sprays water on Hardy.<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 47 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 47, titled: This is going to be easier than you think.<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>

                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498764594"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, Hardy tries to kill himself by drowning. <br/>

                        A newspaper article appears on the screen. “Man-eating shark escapes. Ferocious fish battles keeper in Paris aquarium. Believed at large in Seine. Boaters, swimmers, beware!”<br/>
                        <br/>
                        Laurel and Hardy appear with a rope and cinderblock in a canal by a riiver. Hardy ties a rope around himself, getting ready to throw himself into the river. <br/>
                        <br/>
                        “Well, good bye Ollie.”<br/>
                        “Good bye Stanley.”<br/>
                        “Good luck.” Laurel walks away.<br/>
                        “Where are you going?” asks Hardy.<br/>
                        “Well, I don’t want to get my name dragged into this.”  Hardy ties the rope around Laurel.<br/>
                        “Now when I count to three we’ll both jump in,” says Hardy.<br/>
                        “Why do I have to jump in there for? I’m not in love!”<br/>
                        “So that’s the kind of a guy you are. After all I’ve done for you, you let me jump in there alone,” says Hardy. “Do you realize that after I’m gone, that you’ll just go on living by yourself? People will stare at you and wonder what you are? And I wouldn’t be there to tell them? There would be no one to protect you? Do you want that to happen to you?” asks Hardy.<br/>
                        <br/>
                        Laurel starts crying. He nods his head.<br/>
                        <br/>
                        “I never thought of that. I’m sorry if I hurt your feelings, Ollie. I didn’t mean to be so dispolite.”<br/>
                        “That’s all right, Stanley. Let bygones be bygones,” responds Hardy. “This is going to be easier than you think.” <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 48 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 48, titled: Reincarnation. I guess I’d like to come back as a horse.<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>

                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498764318"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, Laurel talks Hardy out of killing himself. <br/>
                        <br/>
                        A shark’s fin brushes against Hardy’s butt as he gets ready to throw himself along with Laurel into the river.<br/> 
                        <br/>
                        “Don’t do that,” Hardy asks Laurel, who is not aware the shark fin grazed Hardy. “Are you ready?” asks Hardy. <br/>
                        “Good bye, Ollie.”<br/>
                        “Good bye, Stanley,” says Hardy. Now he readies himself to jump: “One, two…”<br/>
                        “Ollie,” Laurel interrupts. “I just thought of something,” he calls Hardy off the ledge. “Listen, do you remember once you were telling me about, when we passed away we come back to this earth on some other form, like a bird, or a dog, or a horse or something?”<br/>
                        “You mean reincarnation,” asserts Hardy.<br/>
                        “Yeah, yeah, that’s it. Well, now that we’re going to go, what would you like to be when you come back?”<br/>
                        “Well, I don’t know…I’ve never given it much thought. I like horses. I guess I’d like to come back as a horse. What would you like to be when you come back?”<br/>
                        “Oh, I’d rather come back as myself. I always got along swell with me.”<br/>
                        “You can’t come back as yourself! Now come on, and stop wasting my time. Are you ready?”<br/>
                        “Good bye, Ollie.”<br/>
                        “Good bye! One…two…”<br/>
                        “Ollie, I just thought of something. You think the water is deep enough? Maybe you might bump your head…”<br/>
                        “Well, I never thought of that.”  <br/>
                        <br/>
                        Hardy takes an oar and sticks it inside the water. Suddenly, something inside the water destroys the oar. We see the shark fin, but Laurel and Hardy don’t. <br/>
                        <br/>
                        Laurel studies the broken oar. “Huh! Termites!”<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 49 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 49, titled: Join the Foreign Legion.<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/> 
                        
                        <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498766228"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>
                     
                        In this episode, Francois suggests Laurel and Hardy join the Foreign Legion.<br/>
                        <br/>
                        Francois sees Hardy screaming at Laurel from a distance. <br/>
                        <br/>
                        “What are you trying to do? Talk me out of this? I came here to jump in this river, and that’s what we’re going to do. Now you quit this horsing around, or I’ll jump in without you!” Francois goes down the bridge to pay the boys a visit.<br/>
                        <br/>
                        “One…two…” shouts Hardy.<br/>
                        “Pardon me, gentlemen. What seems to be the trouble?” interrupts Francois. <br/>
                        “Well, if you don’t mind we are going to jump in the river,” answers Laurel.<br/>
                        “But why commit such a foolish action? Surely your troubles can’t be as bad as all that?”<br/>
                        “Well that’s what I’ve been trying to tell him,” Laurel points at Hardy. “All because he fell in love with some dame. Now isn’t that silly?”<br/>
                        “No woman is worth that!” replies Francois.<br/>
                        “I should say so,” adds Laurel. <br/>
                        “You haven’t seen her,” answers Hardy. “If you knew her as I know her…”<br/>
                        “I understand, but don’t forget there are plenty of more fish in the sea,” replies Francois.<br/>
                        “He’s not in love with a fish,” chimes Laurel. “It’s a girl, you see…”<br/>
                        “That’s just an expression, my friend. I realize perfectly how you feel, but you have too much to live for.”<br/>
                        “Sure, you’ve got your dog, and then there’s me…” says Laurel to Hardy.<br/>
                        “Life is too precious to throw away on account of some woman who probably isn’t worthy of you.”<br/>
                        “He’s right, Ollie.” <br/>
                        “She’s worthy, all right. But there was another man. And if I could meet him face-to-face, what I would do to him would be nobody’s business!” screams Hardy.<br/>
                        “Well, advice is cheap. Maybe if you listen to my suggestion, your problem would be solved.”<br/>
                        “What is your suggestion?” asks Hardy.<br/>
                        “Join the Foreign Legion,” replies Francois. <br/>
                        “What for?” asks Laurel.<br/>
                        “To forget,” says Francois.<br/>
                        “Say, that’s not a bad idea, Ollie. Better than jumping in here. You could forget and…”<br/>
                        “You’re right, Stanley. Thank you mister for telling us.”<br/>
                        “It is a pleasure. Good bye, and good luck,” replies Francois. <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 50 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 50, titled: Take command, Captain.<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498772257"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, Laurel and Hardy decide to join the Foreign Legion.<br/>

                        “Wait a minute, Ollie. Hey mister!” Francois turns around. “If we join the Foreign Legion, how long would it take him to forget?” asks Laurel.<br/>
                        “No time at all. Just a matter of a few days,” replies Francois. <br/>
                        <br/>
                        Laurel and Hardy begin to take off their ropes. Laurel takes his off first and throws the cinderblock into the river, dragging Ollie into the water.<br/>
                        <br/>
                        The water, however, is waist-deep. Laurel helps Hardy out of the water, just in time as the shark passes by and with its fin cuts Hardy’s pants at the butt. <br/>
                        <br/>
                        Now we cut to different camera shots of Foreign Legion soldiers marching in the desert, and toeing the line. A general meets with his captains. <br/>
                        <br/>
                        “Take command, Captain!” barks the general and walks away.<br/>
                        “Lieutenant, close-order drill to address the platoon,” says the captain.<br/>
                        “Very well, Sir,” replies the lieutenant.<br/>
                        <br/>
                        Out come Laurel and Hardy, always late, to join the other soldiers. <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 51 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 51, titled: Left turn!<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498771953"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, Laurel and Hardy are lost in the Foreign Legion. <br/>
                        <br/>
                        “These clothes sure don’t fit you very well, do they?” asks Laurel.<br/>
                        “Well, they’ll be all right for now. We’ll only use them for a few days, until I forget,” says Hardy. <br/>
                        “That’s right.” <br/>
                        “Left turn,” says Hardy. But Laurel goes right. “Left turn…” Now both men head left. <br/>
                        <br/>
                        Laurel and Hardy walk straight into the marching soldiers, and get lost in their midst. They ask for forgiveness and walk away. Laurel sees Francois, the commander. <br/>
                        <br/>
                        “Well, look who’s here,” exclaims Laurel. “Look, Ollie!”<br/>
                        “Well blow me down,” says Hardy, as he pats Francois on the back. “What a small world. Well, we followed your advice…”<br/>
                        “Yeah, but nothing’s happened yet, right Ollie?”<br/>
                        “Show these men to the Commandante,” commands Francois. <br/>
                        “Well, we’ll be seeing you,” says Hardy. “Don’t he look good?” <br/>
                        <br/>
                        The men are taken to see the commander. Hardy turns and screams to Francois: “Hey, hey. When are you going to have dinner?”<br/>
                        <br/>
                        All the solider laugh. Francois calls on his lieutenant. <br/>
                        <br/>
                        “Bye,” says Hardy.<br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 52 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 52, titled: We don’t work for less than 25 cents a day.<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498771671"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, Laurel and Hardy learn about their hard duties while at the Foreign Legion.<br/>
                        <br/>
                        “Good morning,” both Laurel and Hardy address the commander.<br/>
                        “I’ll now assign you to your duties,” says the commander. “Readily at 5 a.m. you dress quickly, make up your bunks and get ready for inspection. Inspection until 7 a.m. Then 10 minutes for breakfast. You’ll drill until 1 p.m. and march until 4 p.m.”<br/>
                        “What about lunch?” asks Laurel.<br/>
                        “You’ll have that while marching,” answers the commander. “You’ll have inspection until 6 p.m. Then 15 minutes for mess, kitchen duties until 10 p.m. Inspection until 11 p.m. and then taps. That is all.” <br/>
                        “If we have to do all that, you won’t have time to forget,” Laurel whispers to Hardy.<br/>
                        “Uh, how much do we all get for all this work?” asks Hardy.<br/>
                        “One hundred sundkings per day.”<br/>
                        “How much is that in American money?”<br/>
                        “American money? Well, it’s equivalent to about 3 cents a day. Of course it varies with the rate of exchange.”<br/>
                        “Well, how much do we get for overtime?” asks Laurel.<br/>
                        “There ain’t going to be any overtime,” says Hardy. He directs himself to the commander. “If you think you’re going to get that much work out of me for 3 cents a day, brother, you are crazy!”<br/>
                        “Is that so?” answers the commander.<br/>
                        “Absolutely,” barks Hardy.<br/>
                        “Yeah, and that goes for me too,” chimes Laurel. “Because we don’t work for less than 25 cents per day, do we Ollie?”<br/>
                        <br/>
                        We cut to Laurel and Hardy washing clothes by hand in a hot summer day. <br/>
                        </p>
                      </Paper>}


                      {lesson.data.number == 53 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 53, titled: How can I concentrate when you keep on talking about her all the time?<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498771354"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, Laurel reminds Hardy about Georgette while they are washing a ton of clothes by hand.<br/>
                        <br/>
                        Laurel rinses clothes by hand and wets Hardy.<br/>
                        <br/>
                        “Haven’t I got enough trouble without you making it tougher?” asks Hardy.<br/>
                        “Well it’s your own fault. If you hadn’t fallen in love with Georgette, we wouldn’t be here.”<br/>
                        “Would you stop reminding me of that? Here I am trying to forget and you keep talking about it all the time. Now here’s another day wasted.”<br/>
                        “Well, maybe you don’t try hard enough,” answers Laurel. “If you can’t forget, why don’t you try to pretend to forget?”<br/>
                        “How can anybody pretend to forget?” asks Hardy.<br/>
                        “Well, I know if it was me, I’d sit down and relax. I’d close my eyes and I’d concentrate and I’d think of nothing. Wouldn’t be long then, that’s what I’d do…”<br/>
                        “Say,” says Hardy, “I think you’ve got something there.”<br/>
                        “I know I’ve got something. Why don’t you try it out.” <br/>
                        <br/>
                        Laurel points to a chair. Hardy sits down and places his head on his hands and closes his eyes.<br/>
                        <br/>
                        “Now don’t think on anything,” says Laurel.<br/>
                        “I won’t.”<br/>
                        “I know it’s tough to forget a swell gal like that. Gorgeous hair, lovely eyes, beautiful teeth, ruby lips…gorgeous…I can see her now…”<br/>
                        “Oh, so can I…” replies Hardy. “Will you shut up? How can I concentrate when you keep talking about her all the time? Now keep quiet. Let me alone. Now sit down!” <br/>
                        <br/>
                        Laurel sits on a bucket of water. <br/>
                        </p>
                      </Paper>}




                      {lesson.data.number == 54 && lesson.data.title != 'Refresher Course & Grammar' && <Paper elevation={2} style={{width: '90%', margin: 'auto', padding: 10, paddingBottom:100, height: 400, overflow:'scroll', overflowX: 'hidden' }}>
                        <p>
                        Hello, and welcome to Step 2, the Audio Story for lesson 54, titled: We are going home now!<br/>
                        <br/>
                        Please use the Reverso Context plugin or app to highlight any word you don’t know. <br/>
                        <br/>
                        Please click below to watch a short 2 minute video (approximate):<br/>
                         
                         <button onClick={() => {
                            this.setState({audioStoryLink: "https://vimeo.com/498770954"})
                            this.setState({audioStoryDialog: true})
                          }}>Watch Video</button>

                        In this episode, Laurel and Hardy have had enough in the Foreign Legion. They are going to leave it to go back home. <br/>
                        <br/>
                        A kitchen employee pulls up in a truck full of vegetables. Laurel and Hardy are washing what seems to be a mountain of clothes. <br/>
                        <br/>
                        “Hey, men. When you get through with that washing, the cook wants you to manicure them vegetables for tomorrow’s stew,” says the rude soldier. <br/>
                        “I beg your pardon?” asks Laurel.<br/>
                        “You heard,” says the soldier. He walks away.<br/>
                        “Well, can you beat that? How do you think we’re going to do all that washing and then…”<br/>
                        “Stanley, I feel something coming over me.”<br/>
                        “What?”<br/>
                        “I’ve completely forgotten.”<br/>
                        “You mean you’ve forgotten Georgette?”<br/>
                        “Who’s Georgette?”<br/>
                        “Gee that’s swell,” says Laurel. “Now we can finish the washing and we can go home.”<br/>
                        “That is nothing. We are going home now.” Hardy moves the ironing table to a side, but the fire falls down and starts burning the clothes. <br/>
                        “Now you’re talking. Absolutely,” agrees Laurel. Both men walk away.<br/>
                        <br/>
                        A soldier tells the commander about the bad work ethic of Laurel and Hardy.<br/>
                        <br/>
                        “…and I found them asleep. And when I told them to go back to work, they threw wet laundry in my face.”<br/>
                        “Oh, they did, eh?” says the commander. “Well I’ll show them what it means to disobey my orders.”<br/>
                        <br/>
                        Laurel and Hardy are now dressed in civilian clothes, bags-in-hand and they are saying goodbye to people. <br/>
                        <br/>
                        “Gee, I’m sure glad to get out of this joint,” exclaims Laurel.<br/>
                        “Me too,” says Hardy. “Have you got everything?”<br/>
                        “Yes.”<br/>
                        “Now we’ll go to that Commander guy and tell him we are through, and at the same time I’ll give him a piece of my mind.”<br/>
                        “I don’t blame you…Three cents a day, ha!”<br/>
                        <br/>
                        The commander and two soldiers arrive to laundry area only to find it all in flames. <br/>
                        <br/>
                        “Call the guards and place them both under arrest!” commands the commander. <br/>
                        “Yes, sir!” replies the soldier. <br/>
                        </p>
                      </Paper>}




                      <button style={{fontSize: 'large', border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.user.learningProgress.level == 3 && this.state.user.learningProgress.lesson == index + 49 && this.state.user.learningProgress.step == 2){
                          this.updateProgress(index + 49, 3)
                        }
                        this.setState({dialogAudio: false, dialogExercise: true})
                      }} >Continue</button>
                      <div>

                      </div>
                    </Dialog>










                    <Dialog
                      open={this.state.dialogExercise}
                      onClose={() => this.setState({dialogExercise: false})}
                      fullScreen
                    >
                      <div style={{width: '100%', height: '100%', backgroundColor: '#eff7fa'}}>
                      <img src={logo} style={{width: 200, marginTop: -20}} />
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 20, right: 20}} onClick={() => this.setState({dialogExercise: false})} />
                      {lesson.data.title == 'Refresher Course & Grammar' ? <h1 style={{textAlign: 'center',fontSize: 'x-large'}}>Step 2. EXCERCISES:</h1> :<h1 style={{textAlign: 'center',fontSize: 'x-large'}}>Step 3. EXCERCISES:</h1> }
                      <p style={{width: '100%', textAlign: 'center'}}>Don’t forget to use Reverso Context if you don’t understand a word or question.</p>
                      <Paper style={{width: '90%', margin: '30px auto', textAlign: 'center', padding: 10}}>
                        {this.state.Index >= lesson.questions.length ? <div style={{textAlign: 'center', width: '90%', margin: 'auto'}}>
                                <H3>Your Score:</H3>
                                <H3 style={{fontSize: 'x-large', fontWeight: 'bold'}}>{lesson.questions.length - this.state.wrongQuestions.length}/{lesson.questions.length}</H3>
                                {((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) == 1 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>Awesome!</H3> : ((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) >= 0.9 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>Great!</H3> : ((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) >= 0.8 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>Good Job!</H3> : ((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) >= 0.7 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>Getting Close!</H3> : ((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) >= 0.6 ? <H3 style={{fontSize: 'x-large', color: '#008000'}}>We've got work to do</H3> : <H3>That's why we're here. Let's do it again!</H3>}
                                {((lesson.questions.length - this.state.wrongQuestions.length)/(lesson.questions.length)) < 0.5 && <Button onClick={() => {
                                  this.setState({word1: '', word2: '', word3: '', word4: '',word5: '', word6: '', word7: '', result: null, Index: 0, wrongQuestions: []})
                                }} variant="contained" color="primary">
                                  Repeat Exercise
                                </Button>}
                              </div> : ''
                            
                            
                              
                          

                        }
                        
                        {lesson.questions.map((question,qIndex) => {
                          
                          if (qIndex == this.state.Index){
                           
                            if (question.type == 'ftb'){
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>Fill in the blank</h2>
                                  <div style={{display: 'flex', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, flexWrap: 'wrap', margin: '10px auto', width: '90%', justifyContent: 'center'}}>
                                  {question.question && question.question.split(' ').map((word, index) => {
                                    if (word == '__1__'){
                                      
                                      return (
                                        <TextField style={{width: 'max-content', minWidth: 100, marginTop: 5}} value={this.state.word1} onChange={( e) => {this.setState({word1: e.target.value}) }} />
                                      )
                                    }else {
                                      return (
                                        <H3 >{word}</H3>
                                        
                                      )

                                    }
                                    
                                  })

                                  }

                                  </div>
                                  {this.state.result == null && <Button variant="contained" style={{backgroundColor: '#ebebeb'}} onClick={() => {
                                    if(this.state.word1.split(" ")[0].toLowerCase() == question.ans.toLowerCase()){
                                      this.setState({result: true})
                                    } else {
                                      console.log(this.state.word1.split(" ")[0].toLowerCase())
                                      this.setState({result: false})
                                    }
                                  }}>Check</Button>}
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}
                                  <div style={{display: 'flex'}}>

                                    {this.state.result == false && <div style={{display: 'flex'}}>
                                      <H3 >Correct Answer: <span style={{color: '#000080'}}>{question.ans}</span></H3>
                                    </div>

                                    }
                                    
                                    {this.state.result != null && <div style={{width: '100%', textAlign: 'right', margin: 'auto'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                                  </div>
                                </div>

                              )
                            } else if (question.type == 'mcq'){
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>Multiple choice</h2>
                                  <div style={{display: 'flex', flexWrap: 'wrap', width: '90%', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, margin: '10px auto', justifyContent: 'center'}}>
                                  {question.question && question.question.split(' ').map((word, index) => {
                                    if (word == '____'){
                                      
                                      return (
                                        <H3 style={{backgroundColor: this.state.word1 == ''? '#bebebe' : this.state.word1 == question.ans ? '#008000' : '#c91b1b', color: 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0}}>{this.state.word1}</H3>
                                      )
                                    }else {
                                      return (
                                        <H3>{word}</H3>
                                        
                                      )

                                    }
                                    
                                  })

                                  }

                                  </div>
                                  <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                                  {question.options.map(option => {
                                    return (
                                      <div style={{margin: 10, cursor: 'pointer', padding: 2, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={() => {
                                        this.setState({word1: option})
                                        if (option == question.ans){
                                          this.setState({result: true})
                                        } else {
                                          this.setState({result: false})

                                        }
                                      }}>
                                        <H3>{option}</H3>

                                      </div>
                                    )
                                  })

                                  }

                                  </div>
                                  
                                  
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}

                                  <div style={{display: 'flex'}}>
                                  {this.state.result == false && <div style={{display: 'flex'}}>
                                      <H3 >Correct Answer: <span style={{color: '#000080'}}>{question.ans}</span></H3>
                                    </div>

                                    }
                               
                                  {this.state.result != null && <div style={{width: '100%', textAlign: 'right'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                              </div>
                                </div>

                              )
                            } else if (question.type == 'co'){
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>Correct order</h2>
                                  
                                  <div style={{display: 'flex', flexWrap: 'wrap', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, width: '90%',margin: '10px auto', justifyContent: 'center'}}>
                                  {question.question && question.question.split(' ').map((word, index) => {
                                    if (word == '__1__'){
                                      
                                      return (
                                        <H3 style={{backgroundColor: this.state.word1 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word1 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word1}</H3>

                                      )
                                    }else if (word == '__2__'){
                                      return (
                                        <H3 style={{backgroundColor: this.state.word2 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word2 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word2}</H3>

                                      )

                                    }else if (word == '__3__'){
                                      return (
                                        <H3 style={{backgroundColor: this.state.word3 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word3 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word3}</H3>

                                      )

                                    }else if (word == '__4__'){
                                      return (
                                        <H3 style={{backgroundColor: this.state.word4 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word4 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word4}</H3>

                                      )

                                    }else if (word == '__5__'){
                                        return (
                                          <H3 style={{backgroundColor: this.state.word4 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word4 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word5}</H3>
  
                                        )
  
                                    }else if (word == '__6__'){
                                        return (
                                          <H3 style={{backgroundColor: this.state.word4 == ''? '#bebebe' : 'white', borderRadius: 5, padding: 10, minWidth: 100, marginTop: 0, boxShadow: this.state.word4 == ''? '' : '0px 2px 6px rgba(0,0,0,0.3)'}}>{this.state.word6}</H3>
  
                                        )
  
                                      }else {
                                      return (
                                        <H3>{word}</H3>
                                        
                                      )

                                    }
                                    
                                  })

                                  }

                                  </div>
                                  <div style={{display: 'flex', flexWrap: 'wrap', width: '90%', margin: 'auto', justifyContent: 'center'}}>
                                  {question.options.map(option => {
                                    return (
                                      <div style={{margin: 10, cursor: 'pointer', padding: 2, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={() => {
                                       
                                        if (this.state.word1 == ''){
                                          this.setState({word1: option})
                                        }else if (this.state.word2 == ''){
                                          this.setState({word2: option})

                                          if (question.ans.split(' ').length == 2 && this.state.word1 != ''){
                                          

                                            if (question.ans == this.state.word1 + ' ' + option){
                                              this.setState({result: true})
                                            }else {
                                              this.setState({result: false})
                                            }
                                          }

                                        }else if (this.state.word3 == '' && this.state.word2 != ''){
                                          this.setState({word3: option})
                                          console.log(question.ans)
                                          console.log(question.ans.split(' ').length)
                                          if (question.ans.split(' ').length == 3){
                                          
                                            if (question.ans == this.state.word1 + ' ' + this.state.word2 + ' ' + option){
                                              this.setState({result: true})
                                            } else {
                                              this.setState({result: false})
                                            }
                                          }
                                        }else if (this.state.word4 == '' && this.state.word3 != ''){
                                          this.setState({word4: option})
                                          if (question.ans.split(' ').length == 4){
                                            if (question.ans == this.state.word1 + ' ' + this.state.word2 + ' ' + this.state.word3 + ' ' + option){
                                              this.setState({result: true})
                                            }else {
                                              this.setState({result: false})
                                            }
                                          }
                                        }
                                        
                                      }}>
                                        <H3>{option}</H3>

                                      </div>
                                    )
                                  })

                                  }

                                  </div>
                                  
                                  
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}

                                  <div style={{display: 'flex'}}>
                                  {this.state.result == false && <div>
                                      <H3 style={{width: 200}}>Correct Answer:</H3>
                                      <div style={{display: 'flex'}}>
                                      {question.ans.split(' ').map(word => {
                                        return (
                                          <h4 style={{ minWidth: 50, margin: 10, padding: 5, backgroundColor: '#ebebeb', borderRadius: 5}}>{word}</h4>
                                        )
                                      })

                                      }

                                      </div>
                                      
                                    </div>

                                    }
                               
                                  {this.state.result != null && <div style={{width: '100%', textAlign: 'right'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                              </div>
                                </div>

                              )
                            }else if (question.type == 'tf'){
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>True or False</h2>
                                  
                                  <div style={{display: 'flex', flexWrap: 'wrap', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, width: '90%',margin: '10px auto', justifyContent: 'center'}}>
                                  {question.question && question.question.split(' ').map((word, index) => {
                                    if (word == '____'){
                                      
                                      return (
                                        <TextField variant="outlined" value={this.state.word1} onChange={( e) => {this.setState({word1: e.target.value}) }} />
                                      )
                                    }else {
                                      return (
                                        <H3>{word}</H3>
                                        
                                      )

                                    }
                                    
                                  })

                                  }

                                  </div>
                                  <div style={{display: 'flex', width: '90%',margin: 'auto', justifyContent: 'center'}}>
                                  <H3 style={{margin: 10, cursor: 'pointer', padding: 8, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={()=> {
                                    if (question.ans == true){
                                      this.setState({result: true})
                                    } else {
                                      this.setState({result: false})

                                    }
                                  }}>True</H3>
                                  <H3 style={{margin: 10, cursor: 'pointer', padding: 8, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={()=> {
                                    if (question.ans == false){
                                      this.setState({result: true})
                                    } else {
                                      this.setState({result: false})

                                    }
                                  }}>False</H3>

                                  </div>
                                  
                                  
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}

                                  <div style={{display: 'flex'}}>

                                  {this.state.result == false && <div style={{display: 'flex'}}>
                                      <H3 >Correct Answer: <span style={{color: '#000080'}}>{question.ans == true ? 'True' : 'False'}</span></H3>
                                    </div>

                                    }
                               
                                  {this.state.result != null && <div style={{width: '100%', textAlign: 'right'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                              </div>
                                </div>

                              )
                            }else if (question.type == 'match'){
                              
                              return (
                                <div style={{textAlign: 'center'}}>
                                  <h2 style={{fontSize: 'large', fontWeight: 'bold'}}>Match the following</h2>
                                  
                                  <div style={{display: 'flex', backgroundColor: '#ebebeb', borderRadius: 6, padding: 5, flexWrap: 'wrap', width: '90%',margin: '10px auto', justifyContent: 'center'}}>
                                  {question.question.map((word, index) => {
                                    return (
                                      <div>
                                        <H3 style={{backgroundColor: '#bebebe', borderRadius: '5px 5px 0px 0px', padding: 10, marginBottom: 1}}>{word}</H3>
                                        <H3 style={{backgroundColor: '#cdeefd', borderRadius: '0px 0px 5px 5px', padding: 10, marginTop: 1}}>{index == 0? this.state.word1 : index == 1? this.state.word2 : index == 2 ? this.state.word3 : index == 3 ? this.state.word4 : index == 4 ? this.state.word5 : index == 5 ? this.state.word6 : index == 6 ? this.state.word7 : ''}</H3>

                                      </div>

                                    )
                                    
                                    
                                    
                                  })

                                  }

                                  </div>
                                  <div style={{display: 'flex', flexWrap: 'wrap', width: '90%', margin: '10px auto', justifyContent: 'center'}}>
                                  {question.shuffled.map(option => {

                                    return (
                                      
                                        <H3 style={{margin: 10, cursor: 'pointer', padding: 8, borderRadius: 5, boxShadow: '0px 2px 6px rgba(0,0,0,0.3)'}} onClick={()=> {
                                          if (this.state.word1 == ''){
                                            this.setState({word1: option})
                                          } else if (this.state.word2 == ''){
                                            this.setState({word2: option})
                                            if (question.ans.length == 2){
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word3 == ''){
                                            this.setState({word3: option})
                                            if (question.ans.length == 3){
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word4 == ''){
                                            this.setState({word4: option})
                                            if (question.ans.length == 4){
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, this.state.word3, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word5 == ''){
                                            this.setState({word5: option})
                                            if (question.ans.length == 5){
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word6 == ''){
                                            this.setState({word6: option})
                                            if (question.ans.length == 6){
                                              console.log(question.ans)
                                              console.log(JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, this.state.word5, option]))
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, this.state.word5, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }else if (this.state.word7 == ''){
                                            this.setState({word7: option})
                                            if (question.ans.length == 7){
                                              console.log(question.ans)
                                              console.log(JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, this.state.word5, this.state.word6, option]))
                                              
                                              if (JSON.stringify(question.ans) == JSON.stringify([this.state.word1, this.state.word2, this.state.word3, this.state.word4, this.state.word5, this.state.word6, option])){
                                                this.setState({result: true})
                                              } else {
                                                this.setState({result: false})
                                              }
                                            }
                                          }
                                        }}>{option}</H3>

                                     
                                    )
                                  })

                                  }

                                  </div>
                                  
                                  
                                  {this.state.result == true && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CheckCircleIcon style={{color: '#008000', width: 40, height: 40}} />
                                    <H3 style={{color: '#008000', marginTop: 0}}>Correct</H3>
                                    </div>}
                                  {this.state.result == false && <div style={{margin: '10px auto', textAlign: 'center'}}>
                                    <CancelIcon style={{color: '#c91b1b', width: 40, height: 40}} />
                                    <H3 style={{color: '#c91b1b', marginTop: 0}}>Wrong</H3>
                                    </div>}

                                  <div style={{display: 'flex'}}>
                                  {this.state.result == false && <div>
                                      <H3 style={{width: 200}}>Correct Answer:</H3>
                                      <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                      {question.ans.map(word => {
                                        return (
                                          <h4 style={{ minWidth: 50, maxWidth: 'max-content', margin: 10, padding: 5, backgroundColor: '#ebebeb', borderRadius: 5}}>{word}</h4>
                                        )
                                      })

                                      }

                                      </div>
                                      
                                    </div>

                                    }
                               
                                  {this.state.result != null && <div style={{width: '100%', textAlign: 'right'}}><Button variant="contained"  style={{backgroundColor: 'rgb(30, 77, 167)', color: 'white', width: 'max-content', float: 'right', textAlign: 'right'}} onClick={()=> { 
                                      this.setState({Index: this.state.Index + 1, word1: '', word2: '', word3: '', word4: '', word5: '', word6: '', word7: '', result: null})
                                      if (this.state.result == false){
                                        let wrongQuestions = this.state.wrongQuestions;
                                        wrongQuestions.push(question)
                                        this.setState({wrongQuestion: wrongQuestions})

                                      }
                                      
                                      }}>Next Question</Button></div>}

                              </div>
                                </div>

                              )
                            } 
                          
                            
                          }
                        })

                        }


                      </Paper>


                      {this.state.Index >= lesson.questions.length && <button style={{border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.Index >= lesson.questions.length){

                          if (this.state.user.learningProgress.level == 3 && this.state.user.learningProgress.lesson == index + 49 && this.state.user.learningProgress.step == 3){
                            if (lesson.data.title == 'Refresher Course & Grammar'){
                              this.updateProgress(index + 50, 1)
                            }else {
                              this.updateProgress(index + 49, 4)
                            }
                            
                          }

                          if (lesson.data.title == 'Refresher Course & Grammar'){
                            this.setState({dialogExercise: false})
                          }else {
                            this.setState({dialogExercise: false, dialogResponse: true})
                          }
                          
                          this.setState({word1: '', word2: '', word3: '', word4: '',word5: '', word6: '', word7: '', result: null, Index: 0, wrongQuestions: []})
                        }


                        
                      }} >Continue</button>}
                      <div>

                      </div>
                      </div>
                    </Dialog>















                    <Dialog
                    open={this.state.dialogResponse}
                    onClose={() => this.setState({dialogResponse: false})}
                    fullScreen
                    >
                      <img src={logo} style={{width: 200, marginTop: -20}} />
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 20, right: 20}} onClick={() => this.setState({dialogResponse: false,recording: false,
                        audio: '',
                        isRecording: false,
                        blobURL: '',
                        recorded: false,
                        response: '',
                        responseSent: false})} />
                      <h1 style={{textAlign: 'center',fontSize: 'x-large'}}>Step 4. Track Your Progress</h1>

                      <Paper style={{width: '80%', margin: '30px auto',padding: 20, textAlign: 'center'}}>
                        <H3 style={{margin: 20}}>Please speak into the microphone the following sentence: </H3>
                        <H3><i>{lesson.data.sentence}</i></H3>
                        {/* <TextField multiline variant="outlined" style={{width: '80%', margin: 'auto'}} placeholder="type here..."  onChange={(e) => this.setState({response: e.target.value})}/> */}
                      {/* <div style={{width: '100%', textAlign: 'center', margin: 'auto'}}>
                      {this.state.response != '' && <Button variant="contained" color="primary" onClick={() => this.sendResponse(index + 1)}>Send Text</Button>}
                      </div> */}
                      

                        {/* <h3 style={{margin: '20px auto'}}>OR</h3> */}
                    {!this.state.recorded && !this.state.responseSent && !this.state.progress && <Button variant="contained" style={{backgroundColor: this.state.isRecording ? 'red' : 'green', color: 'white'}} onClick={() => {
                      if (this.state.isRecording){
                        this.stop()
                      } else {
                        this.start()
                      }
                    }}>{this.state.isRecording ? 'Stop Recording' : 'Click here to start recording'}</Button>}
                    {this.state.recorded && <div style={{width: '100%'}}>
                      <audio src={this.state.blobURL} style={{padding: 10,  }} controls="controls" /> 
                      </div>}
                      {this.state.progress && <div style={{width: '100%'}}>
                      <CircularProgress color="inherit" />
                      </div>}
                      

                      
                      {this.state.isRecording && <h3>Recording...</h3>}
                      {this.state.recorded && !this.state.responseSent && <Button variant="contained" color="primary" onClick={() => this.send(lesson.data.number)}>Send Audio</Button>}
                      
                      {this.state.responseSent && <p>Audio has been sent</p>

                      }


                      </Paper>
                      {this.state.user.adminResponses && this.state.user.adminResponses.map(response => {
                        if (response.level == 3 && response.lesson == lesson.data.number){
                          return (
                            <Paper style={{width: '80%', margin: '10px auto',padding: 20, textAlign: 'center'}}>
                              <H3>You have a response from Harold Zapata!</H3>
                              <audio src={response.audio} style={{padding: 10,  }} controls="controls" download='false' />


                            </Paper>
                          )
                        }
                      })

                      }


                      <button style={{border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.user.learningProgress.level == 3 && this.state.user.learningProgress.lesson == index + 49 && this.state.user.learningProgress.step == 4){
                          this.updateProgress(index + 49, 5)
                        }
                        this.setState({dialogResponse: false, dialogDownload: true,recording: false,
                          audio: '',
                          isRecording: false,
                          blobURL: '',
                          recorded: false,
                          response: '',
                          responseSent: false})
                      }} >Continue</button>
                      <div>

                      </div>
                    </Dialog>











                    <Dialog
                    open={this.state.dialogDownload}
                    onClose={() => this.setState({dialogDownload: false})}
                    fullScreen
                    >
                      <img src={logo} style={{width: 200, marginTop: -20}} />
                      <CancelIcon style={{width: 50, height: 50, cursor: 'pointer', position: 'fixed', top: 20, right: 20}} onClick={() => this.setState({dialogDownload: false})} />
                      <h1 style={{textAlign: 'center',fontSize: 'x-large'}}>Step 5. DOWNLOAD AUDIO MP3.</h1>

                      <Paper style={{width: '80%', margin: 'auto', textAlign: 'center', padding: 10}}>
                        <audio src={lesson.data.downloadable} controls />
                        <div style={{width: '100%', textAlign: 'center'}}>
                        <p style={{ padding: 5, borderRadius: 5,margin: '5px auto', color: 'green'}}>Click the 3 dots to download</p>
                        </div>
                        


                      </Paper>


                      <button style={{border: 'none',cursor: 'pointer', borderRadius: 50, padding: 15, backgroundColor: '#fdfd96', color: 'black', position: 'fixed', bottom: '20px', right: 20, boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'}} onClick={() => {
                        if (this.state.user.learningProgress.level == 3 && this.state.user.learningProgress.lesson == index + 49 && this.state.user.learningProgress.step == 5){
                          this.updateProgress(index + 50, 1)
                        }
                        this.setState({dialogDownload: false})
                      }} >Continue</button>
                      <div>

                      </div>
                    </Dialog>

                  </div>}

                </div>
                

              </div>

            )
          } )}

          <Dialog 
          open={this.state.dialogCert}
          onClose={() => this.setState({dialogCert: false})}
          fullScreen>
            <div style={{height: '100%', width: '100vw' , backgroundColor: '#707070' }}>
                <div style={{textAlign: 'center', maxWidth: 800, margin: 'auto', maxHeight: '100%', position: 'relative'}}>
                  <img src={Cert} style={{textAlign: 'center', width: '100%', height: '100%'}}  />
                  <p style={{position: 'absolute', top: '36%', color: '#505050', fontSize: window.innerWidth >= 500 ? 'xx-large' : 'large' , width: '100%', textAlign: 'center'}}>{localStorage.getItem('firstName')} {localStorage.getItem('lastName')}</p>

                </div>

            </div>

            
            

          </Dialog>

          
          
        </div>
      )
























  //     return (
  //       <div>
         
  //         <Paper style={{ margin: 20, padding: 20 }}>
  //           <Steps direction="horizontal" size="small" current={0}>
  //             {this.state.courses.map((course, index) => {
  //               return <Step title={"course" + ++index} />;
  //             })}
  //           </Steps>
  
  //           {this.state.courses.length > 0 &&
  //             this.state.courses[0].map((lesson, index) => {
  //               return (
  //                 <Paper style={{ margin: 20, padding: 20 }}>
  //                   <h2 style={{backgroundColor: '#505050', padding: 20, width: 500, color: '#e9c979', fontWeight: 'bold', borderRadius: 5, margin: '10px auto'}} >Lesson {index + 1}</h2>
  //                   <h3 className="montserrat">Lesson Video</h3>
  //                   <ReactPlayer
  //                   onContextMenu ={(e) => e.preventDefault()}
  //                     style={{ margin: "20px auto 50px auto" }}
  //                     controls={true}
  //                     playing={false}
  //                     config={{ file: { 
  //                       attributes: {
  //                         controlsList: 'nodownload'
  //                       }
  //                     }}}
  //                     url={lesson.mainVideo}
  //                   />
  
  
  //                   <h3 className="montserrat">Teaching Video</h3>
  //                   <ReactPlayer
  //                     onContextMenu ={(e) => e.preventDefault()}
  //                     style={{ margin: "20px auto 50px auto" }}
  //                     controls={true}
  //                     playing={false}
  //                     config={{ file: { 
  //                       attributes: {
  //                         controlsList: 'nodownload'
  //                       }
  //                     }}}
  //                     url={lesson.teachingVideo}
  //                   />
  
  
  //                     <h3 className="montserrat">Audio Story</h3>
  //                     {lesson.audioStory && <ReactAudioPlayer
  //                       style={{margin: '10px auto'}}
  //                         src={ lesson.audioStory }
                          
  //                         controls
  //                       />}
  
  
  
  //                   <h3 className="montserrat">Text Story</h3>
  //                   <Input.TextArea
  //                       style={{height: 120}}
  //                       value={lesson.textStory}
                  
  //                       />
  //                   <Button variant="contained" color="primary" style={{margin: 10}} onClick={()=>{
  //                     console.log(lesson.exercise)
  //                     this.setState({
  //                     exercise: lesson.exercise,
  //                     dialog: true,
  //                     index: 0
  //                     })}} >Exercises</Button>
  //                 </Paper>
  //               );
  //             })}
  //         </Paper>
  //         <Dialog
          
  //         fullScreen
  //           open={this.state.dialog}>
  //             <div style={{backgroundColor: '#e6f3f8', height: '100%', width: '100%'}}>
  
              
  //             <h2 style={{textAlign: 'center'}} >Exercise</h2>
              
  //             <div style={{margin: 30, textAlign: 'center'}}>
  //               {this.state.exercise.map((question, index) => {
        
  //                 if (index == this.state.index + 1){
  //                   if (question.type == "fill the gap text"){
  //                     let word1 = '';
  //                     let word2 = '';
  //                     let word3 = '';
  //                     let ansWord1 = '';
  //                     let ansWord2 = '';
  //                     let ansWord3 = '';
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
  //                         <div style={{display: 'flex', justifyContent: 'center', margin: 20}}>
  //                           {question.question.map(word => {
  //                             if (question.options.filter(Word => {return Word == word}).length >= 1){
  //                               word1 = word;
  //                               return (
  //                                 <TextField margin="dense" disabled style={{marginTop: 2, cursor: 'pointer', width: 'max-content'}} variant="outlined" value={this.state.word1}  onClick={()=> this.setState({word1: ''})}/>
  //                               )
  //                             }else {
  //                               return (
  //                                 <H3>{word}</H3>
  //                               )
  //                             }
                              
  //                           })
    
  //                           }
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
  //                         {question.options.map(word => {
  //                           return (
  //                             <H3 style={{cursor: 'pointer'}} onClick={()=> {
  //                               if (this.state.word1 == ''){
    
  //                                 this.setState({word1: word})
  //                                 ansWord1 = word;
  //                                 if (ansWord1 == word1){
  //                                   console.log("correct")
  //                                   this.setState({open: true})
  //                                 } else  {
  //                                   this.setState({open2: true})
  //                                 }
  //                               }
  //                             }} >{word}</H3>
  //                           )
  //                         })
    
  //                         }
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   } else if (question.type == "fill the blank audio"){
  //                     let word1 = '';
  //                     let word2 = '';
  //                     let word3 = '';
  //                     let ansWord1 = '';
  //                     let ansWord2 = '';
  //                     let ansWord3 = '';
                      
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
  //                       <ReactAudioPlayer
  //                       style={{margin: '10px auto'}}
  //                         src={ question.audio }
                          
  //                         controls
  //                       />
  //                         <div style={{display: 'flex', justifyContent: 'center', margin: 20}}>
  //                           {question.question.map(word => {
  //                             if (question.words.filter(Word => {return Word == word}).length >= 1){
  //                               word1 = word;
  //                               return (
  //                                 <TextField margin="dense" disabled style={{marginTop: 2, cursor: 'pointer', width: 'max-content'}} variant="outlined" value={this.state.word1}  onClick={()=> this.setState({word1: ''})}/>
  //                               )
  //                             }else {
  //                               return (
  //                                 <H3>{word}</H3>
  //                               )
  //                             }
                              
  //                           })
    
  //                           }
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
  //                         {question.words.map(word => {
  //                           return (
  //                             <H3 style={{cursor: 'pointer'}} onClick={()=> {
  //                               if (this.state.word1 == ''){
    
  //                                 this.setState({word1: word})
  //                                 ansWord1 = word;
  //                                 if (ansWord1 == word1){
  //                                   console.log("correct")
  //                                   this.setState({open: true})
  //                                 } else {
  //                                   this.setState({open2: true})
  //                                 }
  //                               }
  //                             }} >{word}</H3>
  //                           )
  //                         })
    
  //                         }
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   } else if (question.type == "match the pair"){
                      
  //                     const shuffled = question.pairs;
  //                     let completed = 0;
                    
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
                        
  //                         <div style={{display: 'flex', justifyContent: 'center', margin: 20}}>
  //                           {question.pairs.map((pair, index) => {
  //                             return (
  //                               <div style={{margin: '10px'}}>
  //                                 <Word>{pair.word}</Word>
  //                                 <Phrase>
  //                                   <p>{index == 0 ? this.state.phrase1 : index == 1 ? this.state.phrase2 : index == 2 ? this.state.phrase3 : index == 3 ? this.state.phrase4 : ''}</p>
  //                                 </Phrase>
                                  
  
  //                               </div>
  //                             )
                              
  //                           })
    
  //                           }
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
  //                         {shuffled.map((pair, index) => {
  //                           return (
  //                             <Phrase style={{cursor: 'pointer'}} onClick={()=>{
  //                               if (this.state.phrase1 == ''){
  //                                 this.setState({phrase1: pair.phrase})
  //                               } else if (this.state.phrase2 == ''){
  //                                 this.setState({phrase2: pair.phrase})
  //                               }else if (this.state.phrase3 == ''){
  //                                 this.setState({phrase3: pair.phrase})
  //                               }else if (this.state.phrase4 == ''){
  //                                 this.setState({phrase4: pair.phrase})
  //                               }
  //                               completed = completed + 1;
  //                               let {ans} = this.state;
  //                               ans.push(pair.phrase)
  //                               console.log(ans)
  //                               let pairs = []
  //                               question.pairs.forEach(pair => {
  //                                 pairs.push(pair.phrase)
  //                               })
  //                               console.log(pairs)
  //                               if (ans.length == pairs.length){
  //                                 if (JSON.stringify(ans)==JSON.stringify(pairs)){
  //                                   console.log('correct')
  //                                   this.setState({open: true})
  //                                 } else {
  //                                   this.setState({open: false})
  
  //                                 }
  //                               }
  
                                
  
  //                             }}>
  //                               <p>{pair.phrase}</p> 
  //                             </Phrase>
  //                           )
  //                         })
    
  //                         }
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   }else if (question.type == "correct order"){
  //                     const correct = question.sentence;
                      
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
                       
  //                         <div style={{display: 'flex', width: 400, height: 100, justifyContent: 'center', border: '1px solid #bebebe', padding: 10, backgroundColor: '#ebebeb', margin: '20px auto'}}>
  //                           {this.state.sentence.map(word => {
  //                             return (
  //                               <h4>{word}</h4> 
  //                             )
                              
                              
  //                           })
    
  //                           }
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
  //                         {question.shuffled.map(word => {
  //                           return (
  //                             <Button variant="contained" onClick={()=> {
  //                               let {sentence} = this.state;
  //                               sentence.push(word);
  //                               this.setState({sentence})
  //                               console.log(sentence)
  //                               console.log(correct)
  //                               if (sentence.length == question.sentence.length){
  //                                 if (JSON.stringify(sentence)==JSON.stringify(correct)){
  //                                   console.log('correct')
  //                                   this.setState({open: true})
  //                                 } else {
  //                                   this.setState({open2: true})
  
  //                                 }
  //                               }
  //                             }}>{word}</Button>
  //                           )
  //                         })
    
  //                         }
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   } else if (question.type == "true false"){
                      
                      
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : "Solve"  } </h2>
                       
  //                         <div style={{display: 'flex', width: 400, height: 100, justifyContent: 'center', border: '1px solid #bebebe', padding: 10, backgroundColor: '#ebebeb', margin: '20px auto'}}>
                            
  //                               <h4>{question.sentence}</h4> 
                            
    
  //                         </div>
    
  //                         <div style={{display: 'flex', justifyContent: 'center'}}>
                          
  //                             <Button variant="contained" onClick={()=> {
                   
                                
  //                               if (question.status == true){
  //                                 console.log("correct")
  //                                 this.setState({open: true})
  //                               } else {
  //                                 this.setState({open2: true})
  
  //                                 console.log("false")
  //                               }
  //                             }}>True</Button>
  
  //                             <Button variant="contained" onClick={()=> {
                       
                                
  //                               if (question.status == false){
  //                                 console.log("correct")
  //                                 this.setState({open: true})
  
  //                               } else {
  //                                 this.setState({open2: true})
                                  
  //                                 console.log("false")
  //                               }
  //                             }}>False</Button>
                          
    
                          
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   } else if (question.type == "mcq"){
                      
                      
  
  //                     return (
  //                       <div>
  //                         <Paper elevation={2} style={{margin: '20px auto', padding: 20}}>
  //                       <h2 className="montserrat" >{question.type == "fill the gap text" ? "Fill the gap" : question.type == "fill the blank audio" ? "Fill the blank" : question.type == "match the pair" ? "Match the pair" : question.type == "correct order" ? "Correct Order" : question.type == "true false" ? "True / False" : question.type == "mcq" ? "Select the correct option" : "Solve"  } </h2>
                       
  //                         <div style={{display: 'flex', justifyContent: 'center', margin: 20}}>
                            
  //                               <h4>{question.question}</h4> 
                            
    
  //                         </div>
    
  //                         <div style={{}}>
  //                           {question.options.map(option => {
  //                             return (
  //                               <Button variant="contained" onClick={()=> {
                                    
                                                  
  //                                   if (question.correctOption == option){
  //                                     console.log("correct")
  //                                   this.setState({open: true})
  
  //                                   } else {
  //                                   this.setState({open2: true})
  
  //                                     console.log("false")
  //                                   }
  //                                 }}>{option}</Button>
  //                             )
  //                           })
  
  //                           }
                          
                              
        
    
  //                         </div>
                          
  //                       </Paper>
    
    
  //                       </div>
  //                     )
  
  
  //                   }
                    
  //                 } 
  //               })
  
  //               }
  //             </div>
  
  
  
  
  
  //               {/* <div style={{position: 'fixed', bottom: 20, margin: 'auto'}}>
  //               <Alert severity="success">
  //                 <AlertTitle>Success</AlertTitle>
  //                 This is a success alert — <strong>check it out!</strong>
  //               </Alert>
  //               </div> */}
  
  // <Snackbar open={this.state.open} autoHideDuration={10000} onClose={this.handleClose}>
  //         <Alert onClose={this.handleClose} severity="success">
  //           Correct!
  //         </Alert>
  //       </Snackbar>
  //       <Snackbar open={this.state.open2} autoHideDuration={10000} >
  //         <Alert severity="error">
  //           Wrong!
  //         </Alert>
  //       </Snackbar>
  
  //             <CancelIcon  onClick={()=> this.setState({dialog: false})} style={{position: 'fixed', top: 30, left: 30, cursor: 'pointer', opacity: 0.8}} />
  //             {this.state.index != 0 && <Button variant="contained" color="primary" style={{position: 'fixed', bottom: 30, left: 80}} onClick={()=> {
  //               this.setState({index: this.state.index - 1, open: false, open2: false})
  //               this.setState({word1: '',
  //               word2: '',
  //               word3: '',
  //               word4: '',
  //               phrase1: '',
  //               phrase2: '',
  //               phrase3: '',
  //               phrase4: '',
  //               shuffled: [],
  //               ans: [],
  //               sentence: [],})
  
  //             }} >Previous</Button>}
  //             {this.state.index != (this.state.exercise.length - 2) && <Button variant="contained" color="primary" style={{position: 'fixed', bottom: 30, right: 80}} onClick={()=> {
  //               this.setState({index: this.state.index + 1, open: false, open2: false})
  //               this.setState({word1: '',
  //               word2: '',
  //               word3: '',
  //               word4: '',
  //               phrase1: '',
  //               phrase2: '',
  //               phrase3: '',
  //               phrase4: '',
  //               shuffled: [],
  //               ans: [],
  //               sentence: [],})
  //             }} >Next</Button>}
  //             </div>
  //         </Dialog>
  //       </div>
  //     );

    }

    
  }
}


const H3 = styled.h3 `
  font-weight: bold;
  margin: 5px;
  padding: 5px;

  @media(max-width: 500px ){
    font-size: medium;
  }

`

const Word = styled.h3 `
  padding: 10px;
  margin: 5px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
  border-radius: 5px 5px 0px 0px;
`

const Phrase = styled.div `
  padding: 10px;
  margin: 5px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
  text-align: center;


`